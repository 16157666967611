//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Vector3 from '../../component/maths/Vector3.js';
import Runtime from '../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Graphics from '../Graphics.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  canvas = null - The canvas associated with this camera, important for setting the aspect ratio
  position = new Vector3({x:0,y:0,z:10})
  lookAt = new Vector3() - By default - the perspective camera looks at the origin
  aspect = 0.5 - The aspect ratio of the camera, should be canvas width / canvas height
  fixedAspect = false - The aspect ratio is fixed to a predefined aspect ratio
  predefinedAspectRatio = Camera.ASPECT_RATIO_1_1 - The predefined aspect ratio for this camera
  near = 0.1 - The near plane at which objects will be clipped
  far = 1000 - The far plane at which objects will be clipped
  zoom = 1 - Gets or sets the zoom factor of the camera. Default is 1
  stereo = false - Whether this is a stereo camera
  eyeSeparation = 0.064 - The distance between your eyes
  viewport = null - A viewport object that is calculated when in fixed aspect mode, based on the canvas dimension
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  ASPECT_RATIO_1_1  = 1
  ASPECT_RATIO_3_2  = 3/2
  ASPECT_RATIO_2_3  = 2/3
  ASPECT_RATIO_4_3  = 4/3
  ASPECT_RATIO_3_4  = 3/4
  ASPECT_RATIO_16_9  = 16/9
  ASPECT_RATIO_9_16  = 9/16
  ASPECT_RATIO_16_10 = 16/10
  ASPECT_RATIO_10_16 = 10/16
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeGraphicsThree" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "canvas",
            "type" : "R3EventObjComponentGraphicsCanvas",
            "deep" : "true"
          },
          {
            "property" : "position",
            "type" : "R3EventObjComponentMathsVector3"
          },
          {
            "property" : "lookAt",
            "type" : "R3EventObjComponentMathsVector3"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeGraphicsThree" :
    [
      {
        "methods" : ["createInstance"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "aspect",
            "type" : "Number",
            "min" : 0,
            "max" : 16,
            "step" : 0.001
          },
          {
            "property" : "fixedAspect",
            "type" : "Boolean"
          },
          {
            "property" : "predefinedAspectRatio",
            "type" : "Number",
            "possibleValues" : [
              "Camera.ASPECT_RATIO_1_1",
              "Camera.ASPECT_RATIO_3_2",
              "Camera.ASPECT_RATIO_2_3",
              "Camera.ASPECT_RATIO_4_3",
              "Camera.ASPECT_RATIO_3_4",
              "Camera.ASPECT_RATIO_16_9",
              "Camera.ASPECT_RATIO_9_16",
              "Camera.ASPECT_RATIO_16_10",
              "Camera.ASPECT_RATIO_10_16"
            ],
            "possibleLabels" : [
              "1:1",
              "3:2",
              "2:3",
              "4:3",
              "3:4",
              "16:9",
              "9:16",
              "16:10",
              "10:16"
            ]
          },
          {
            "property" : "near",
            "type" : "Number",
            "min" : 0.1,
            "max" : 100,
            "step" : 0.1
          },
          {
            "property" : "far",
            "type" : "Number",
            "min" : 10,
            "max" : 2000,
            "step" : 0.1
          },
          {
            "property" : "zoom",
            "type" : "Number",
            "min" : 0,
            "max" : 10,
            "step" : 0.0001
          },
          {
            "property" : "stereo",
            "type" : "Boolean",
            "excluded" : true
          },
          {
            "property" : "eyeSeparation",
            "type" : "Number",
            "min" : 0,
            "max" : 2,
            "step" : 0.0001,
            "excluded" : true
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
  calculateViewport() - Calculates the viewport size
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  cloneInstance(propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance() - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance() - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Camera extends Graphics {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphicsCamera';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Camera';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param canvas
     * - The canvas associated with this camera, important for setting the aspect ratio
     */
    if (typeof options.canvas === 'undefined') {
      options.canvas = null;
    }

    /**
     * @param position
     * - No comment
     */
    if (typeof options.position === 'undefined') {
      options.position = new Vector3({x:0,y:0,z:10});
    }

    /**
     * @param lookAt
     * - By default - the perspective camera looks at the origin
     */
    if (typeof options.lookAt === 'undefined') {
      options.lookAt = new Vector3();
    }

    /**
     * @param aspect
     * - The aspect ratio of the camera, should be canvas width / canvas height
     */
    if (typeof options.aspect === 'undefined') {
      options.aspect = 0.5;
    }

    /**
     * @param fixedAspect
     * - The aspect ratio is fixed to a predefined aspect ratio
     */
    if (typeof options.fixedAspect === 'undefined') {
      options.fixedAspect = false;
    }

    /**
     * @param predefinedAspectRatio
     * - The predefined aspect ratio for this camera
     */
    if (typeof options.predefinedAspectRatio === 'undefined') {
      options.predefinedAspectRatio = Camera.ASPECT_RATIO_1_1;
    }

    /**
     * @param near
     * - The near plane at which objects will be clipped
     */
    if (typeof options.near === 'undefined') {
      options.near = 0.1;
    }

    /**
     * @param far
     * - The far plane at which objects will be clipped
     */
    if (typeof options.far === 'undefined') {
      options.far = 1000;
    }

    /**
     * @param zoom
     * - Gets or sets the zoom factor of the camera. Default is 1
     */
    if (typeof options.zoom === 'undefined') {
      options.zoom = 1;
    }

    /**
     * @param stereo
     * - Whether this is a stereo camera
     */
    if (typeof options.stereo === 'undefined') {
      options.stereo = false;
    }

    /**
     * @param eyeSeparation
     * - The distance between your eyes
     */
    if (typeof options.eyeSeparation === 'undefined') {
      options.eyeSeparation = 0.064;
    }

    /**
     * @param viewport
     * - A viewport object that is calculated when in fixed aspect mode, based on the canvas dimension
     */
    if (typeof options.viewport === 'undefined') {
      options.viewport = null;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_GRAPHICS_THREE)) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_GRAPHICS_THREE]['requirements']) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "canvas",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCanvas"),
                "deep": "true"
              },
              {
                "property": "position",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3")
              },
              {
                "property": "lookAt",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "aspect",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 16,
                "step": 0.001
              },
              {
                "property": "fixedAspect",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "predefinedAspectRatio",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Camera.ASPECT_RATIO_1_1,
                  Camera.ASPECT_RATIO_3_2,
                  Camera.ASPECT_RATIO_2_3,
                  Camera.ASPECT_RATIO_4_3,
                  Camera.ASPECT_RATIO_3_4,
                  Camera.ASPECT_RATIO_16_9,
                  Camera.ASPECT_RATIO_9_16,
                  Camera.ASPECT_RATIO_16_10,
                  Camera.ASPECT_RATIO_10_16
                ],
                "possibleLabels": [
                  "1:1",
                  "3:2",
                  "2:3",
                  "4:3",
                  "3:4",
                  "16:9",
                  "9:16",
                  "16:10",
                  "10:16"
                ]
              },
              {
                "property": "near",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0.1,
                "max": 100,
                "step": 0.1
              },
              {
                "property": "far",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 10,
                "max": 2000,
                "step": 0.1
              },
              {
                "property": "zoom",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 10,
                "step": 0.0001
              },
              {
                "property": "stereo",
                "Constructor" : this.getClassConstructor("Boolean"),
                "excluded": true
              },
              {
                "property": "eyeSeparation",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 2,
                "step": 0.0001,
                "excluded": true
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_GRAPHICS_THREE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "canvas",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCanvas"),
        "type": "R3EventObjComponentGraphicsCanvas"
      },
      {
        "property": "position",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3"
      },
      {
        "property": "lookAt",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3"
      },
      {
        "property": "aspect",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 16,
        "step": 0.001
      },
      {
        "property": "fixedAspect",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "predefinedAspectRatio",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Camera.ASPECT_RATIO_1_1,
          Camera.ASPECT_RATIO_3_2,
          Camera.ASPECT_RATIO_2_3,
          Camera.ASPECT_RATIO_4_3,
          Camera.ASPECT_RATIO_3_4,
          Camera.ASPECT_RATIO_16_9,
          Camera.ASPECT_RATIO_9_16,
          Camera.ASPECT_RATIO_16_10,
          Camera.ASPECT_RATIO_10_16
        ],
        "possibleLabels": [
          "1:1",
          "3:2",
          "2:3",
          "4:3",
          "3:4",
          "16:9",
          "9:16",
          "16:10",
          "10:16"
        ]
      },
      {
        "property": "near",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0.1,
        "max": 100,
        "step": 0.1
      },
      {
        "property": "far",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 10,
        "max": 2000,
        "step": 0.1
      },
      {
        "property": "zoom",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 10,
        "step": 0.0001
      },
      {
        "property": "stereo",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean",
        "excluded": true
      },
      {
        "property": "eyeSeparation",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 2,
        "step": 0.0001,
        "excluded": true
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * calculateViewport()
   * - Calculates the viewport size
   * - No parameters
   * - No returns
   */
  calculateViewport() {

    //CUSTOM_CALCULATE_VIEWPORT_BEFORE_START
    //CUSTOM_CALCULATE_VIEWPORT_BEFORE_END

    //GENERATED_CALCULATE_VIEWPORT_BEFORE_START
    //GENERATED_CALCULATE_VIEWPORT_BEFORE_END

    //CUSTOM_CALCULATE_VIEWPORT_BEFORE_GENERATED_START
    //CUSTOM_CALCULATE_VIEWPORT_BEFORE_GENERATED_END

    //GENERATED_CALCULATE_VIEWPORT_START
    //GENERATED_CALCULATE_VIEWPORT_END

    //CUSTOM_CALCULATE_VIEWPORT_START
    let {width: canvasWidth, height : canvasHeight} = this.canvas;

    let currentAspect = canvasWidth / canvasHeight;

    let width, height;

    if (currentAspect > 1) {
      /**
       * Width is greater than height (landscape)
       */
      if (this.aspect < 1) {

        /**
         * The required aspect is more high than wide - use the full height
         */
        width   = this.aspect * canvasHeight;
        height  = canvasHeight;

      } else {
        /**
         * The required aspect is also more wide than high - so we have another two possibilities:
         * a) The required aspect is greater than the current aspect - this means the required aspect is less high
         *    than the current aspect - we can use the full width
         *
         * b) The required aspect is less than the current aspect - this means the required aspect is higher than
         *    the current aspect - we need to determine a new width based on the current height
         */
        if (this.aspect > currentAspect) {
          /**
           * a)
           */
          width   = canvasWidth;
          height  = width / this.aspect;
        } else {
          /**
           * b)
           */
          height  = canvasHeight;
          width   = this.aspect * height;
        }

      }

    } else {
      /**
       * The height is greater than the width (portrait)
       */
      if (this.aspect > 1) {

        /**
         * The required aspect is landscape in a portrait mode - use the full width and calculate the new height
         */
        width   = canvasWidth;
        height  = width / this.aspect;

      } else {

        /**
         * The required aspect is also more high than wide (portrait) - we have again, two possibilities
         * a) The required aspect is greater than the current aspect - this means the required aspect does not fit
         *    the full width of the current aspect - use the full width of the current size and determine a new height
         *
         * b) The required aspect is less than the current aspect - this means that the required aspect is less wide
         *    than the current aspect, so we can use the full height of the current size and determine a new width
         */

        if (this.aspect > currentAspect) {
          width   = canvasWidth;
          height  = width / this.aspect;
        } else {
          height = canvasHeight;
          width   = this.aspect * height;
        }

      }

    }

    /**
     * We now have the correct width and height of of the camera
     */
    this.viewport = {
      left    : width  / -2,
      right   : width  /  2,
      top     : height /  2,
      bottom  : height / -2,
      x : (canvasWidth - width) / 2,
      y : (canvasHeight -height) / 2,
      width,
      height
    }
    //CUSTOM_CALCULATE_VIEWPORT_END

    //GENERATED_CALCULATE_VIEWPORT_AFTER_START
    //GENERATED_CALCULATE_VIEWPORT_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * cloneInstance()
   * - Clones an instance, when propagate is true, also clones the instance children
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstance(propagate = true) {

    //CUSTOM_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BEFORE_END

    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CLONE_INSTANCE_START
    /**
     * Override Template
     */
    //GENERATED_CLONE_INSTANCE_END

    //CUSTOM_CLONE_INSTANCE_START
    //CUSTOM_CLONE_INSTANCE_END

    //GENERATED_CLONE_INSTANCE_AFTER_START
    //GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  createInstance() {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  deleteInstance() {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Camera.Type = 'R3EventObjComponentGraphicsCamera';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Camera.ASPECT_RATIO_1_1
 * - No comment
 */
Camera.ASPECT_RATIO_1_1 = 1;

/**
 * @param Camera.ASPECT_RATIO_3_2
 * - No comment
 */
Camera.ASPECT_RATIO_3_2 = 3/2;

/**
 * @param Camera.ASPECT_RATIO_2_3
 * - No comment
 */
Camera.ASPECT_RATIO_2_3 = 2/3;

/**
 * @param Camera.ASPECT_RATIO_4_3
 * - No comment
 */
Camera.ASPECT_RATIO_4_3 = 4/3;

/**
 * @param Camera.ASPECT_RATIO_3_4
 * - No comment
 */
Camera.ASPECT_RATIO_3_4 = 3/4;

/**
 * @param Camera.ASPECT_RATIO_16_9
 * - No comment
 */
Camera.ASPECT_RATIO_16_9 = 16/9;

/**
 * @param Camera.ASPECT_RATIO_9_16
 * - No comment
 */
Camera.ASPECT_RATIO_9_16 = 9/16;

/**
 * @param Camera.ASPECT_RATIO_16_10
 * - No comment
 */
Camera.ASPECT_RATIO_16_10 = 16/10;

/**
 * @param Camera.ASPECT_RATIO_10_16
 * - No comment
 */
Camera.ASPECT_RATIO_10_16 = 10/16;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Camera as default};
