//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import {OrbitControls} from "three/addons/controls/OrbitControls.js";
import * as THREE from 'three';
//CUSTOM_IMPORTS_END

import Input from '../Input.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  deRegisterControl(object) - Takes an input component and removes the listener on its DOM component to stop listening for events 
  registerControl(object) - Takes an input component and registers it on a DOM component to start listening for events 
  updateControl(object) - Updates an input control (attaches to another DOM element, or sets a target, etc.) 
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  deRegisterControl(object) - Takes an input component and removes the listener on its DOM component to stop listening for events 
  registerControl(object) - Takes an input component and registers it on a DOM component to start listening for events 
  updateControl(object, property, value) - Updates an input control (attaches to another DOM element, or sets a target, etc.) 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
  deRegisterControlOrbitControls(object) - Three implementation for [R3EventObjComponentInputOrbitControls] 
  registerControlOrbitControls(object) - Three implementation for [R3EventObjComponentInputOrbitControls] 
  updateControlOrbitControls(object, property, value) - Three implementation for [R3EventObjComponentInputOrbitControls] 
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Three extends Input {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeInput';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeInputThree';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'ThreeInput';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_INPUT;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * deRegisterControl()
   * - Takes an input component and removes the listener on its DOM component to stop listening for events
   * @param object
   * - No returns
   */
  deRegisterControl(object) {

    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_END

    //GENERATED_DE_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_RETURN_GENERATED_DE_REGISTER_CONTROL_START
    super.deRegisterControl(object);
    //CUSTOM_RETURN_GENERATED_DE_REGISTER_CONTROL_END
    //GENERATED_DE_REGISTER_CONTROL_BEFORE_END

    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_GENERATED_END

    //GENERATED_DE_REGISTER_CONTROL_START
    if (
      object.type === 'R3EventObjComponentInputOrbitControls'
    ) {
      //CUSTOM_RETURN_OPTION_DE_REGISTER_CONTROL_ORBIT_CONTROLS_START
      return this.deRegisterControlOrbitControls(object);
      //CUSTOM_RETURN_OPTION_DE_REGISTER_CONTROL_ORBIT_CONTROLS_END
    }
    //GENERATED_DE_REGISTER_CONTROL_END

    //CUSTOM_DE_REGISTER_CONTROL_START
    //CUSTOM_DE_REGISTER_CONTROL_END

    //GENERATED_DE_REGISTER_CONTROL_AFTER_START
    throw new Error(`No suitable method found in deRegisterControl() in runtime R3RuntimeInputThree`);
    //GENERATED_DE_REGISTER_CONTROL_AFTER_END

  }

  /**
   * registerControl()
   * - Takes an input component and registers it on a DOM component to start listening for events
   * @param object
   * - No returns
   */
  registerControl(object) {

    //CUSTOM_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_REGISTER_CONTROL_BEFORE_END

    //GENERATED_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_RETURN_GENERATED_REGISTER_CONTROL_START
    super.registerControl(object);
    //CUSTOM_RETURN_GENERATED_REGISTER_CONTROL_END
    //GENERATED_REGISTER_CONTROL_BEFORE_END

    //CUSTOM_REGISTER_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_REGISTER_CONTROL_BEFORE_GENERATED_END

    //GENERATED_REGISTER_CONTROL_START
    if (
      object.type === 'R3EventObjComponentInputOrbitControls'
    ) {
      //CUSTOM_RETURN_OPTION_REGISTER_CONTROL_ORBIT_CONTROLS_START
      return this.registerControlOrbitControls(object);
      //CUSTOM_RETURN_OPTION_REGISTER_CONTROL_ORBIT_CONTROLS_END
    }
    //GENERATED_REGISTER_CONTROL_END

    //CUSTOM_REGISTER_CONTROL_START
    //CUSTOM_REGISTER_CONTROL_END

    //GENERATED_REGISTER_CONTROL_AFTER_START
    throw new Error(`No suitable method found in registerControl() in runtime R3RuntimeInputThree`);
    //GENERATED_REGISTER_CONTROL_AFTER_END

  }

  /**
   * updateControl()
   * - Updates an input control (attaches to another DOM element, or sets a target, etc.)
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateControl(object, property, value) {

    //CUSTOM_UPDATE_CONTROL_BEFORE_START
    //CUSTOM_UPDATE_CONTROL_BEFORE_END

    //GENERATED_UPDATE_CONTROL_BEFORE_START
    //CUSTOM_RETURN_GENERATED_UPDATE_CONTROL_START
    super.updateControl(object, property, value);
    //CUSTOM_RETURN_GENERATED_UPDATE_CONTROL_END
    //GENERATED_UPDATE_CONTROL_BEFORE_END

    //CUSTOM_UPDATE_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_CONTROL_BEFORE_GENERATED_END

    //GENERATED_UPDATE_CONTROL_START
    if (
      object.type === 'R3EventObjComponentInputOrbitControls'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_CONTROL_ORBIT_CONTROLS_START
      return this.updateControlOrbitControls(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_CONTROL_ORBIT_CONTROLS_END
    }
    //GENERATED_UPDATE_CONTROL_END

    //CUSTOM_UPDATE_CONTROL_START
    //CUSTOM_UPDATE_CONTROL_END

    //GENERATED_UPDATE_CONTROL_AFTER_START
    throw new Error(`No suitable method found in updateControl() in runtime R3RuntimeInputThree`);
    //GENERATED_UPDATE_CONTROL_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  /**
   * deRegisterControlOrbitControls()
   * - Three implementation for [R3EventObjComponentInputOrbitControls]
   * @param object
   * - No returns
   */
  deRegisterControlOrbitControls(object) {

    //GENERATED_DE_REGISTER_CONTROL_ORBIT_CONTROLS_BEFORE_START
    //GENERATED_DE_REGISTER_CONTROL_ORBIT_CONTROLS_BEFORE_END

    //GENERATED_DE_REGISTER_CONTROL_ORBIT_CONTROLS_START
    //GENERATED_DE_REGISTER_CONTROL_ORBIT_CONTROLS_END

    //CUSTOM_DE_REGISTER_CONTROL_ORBIT_CONTROLS_START
    let instance = object.getInstance(this);
    if (instance) {
      instance.dispose();
    }
    //CUSTOM_DE_REGISTER_CONTROL_ORBIT_CONTROLS_END

    //GENERATED_DE_REGISTER_CONTROL_ORBIT_CONTROLS_AFTER_START
    //GENERATED_DE_REGISTER_CONTROL_ORBIT_CONTROLS_AFTER_END

  }

  /**
   * registerControlOrbitControls()
   * - Three implementation for [R3EventObjComponentInputOrbitControls]
   * @param object
   * - No returns
   */
  registerControlOrbitControls(object) {

    //GENERATED_REGISTER_CONTROL_ORBIT_CONTROLS_BEFORE_START
    //GENERATED_REGISTER_CONTROL_ORBIT_CONTROLS_BEFORE_END

    //GENERATED_REGISTER_CONTROL_ORBIT_CONTROLS_START
    //GENERATED_REGISTER_CONTROL_ORBIT_CONTROLS_END

    //CUSTOM_REGISTER_CONTROL_ORBIT_CONTROLS_START
    let camera = object.camera.getInstance(Runtime.KEY_GRAPHICS_THREE);
    let canvas = object.domElement.getInstance(Runtime.KEY_GRAPHICS_THREE);

    let instance = new OrbitControls(camera, canvas);

    if (object.target) {
      let target = object.target;
      instance.target.x = target.position.x;
      instance.target.y = target.position.y;
      instance.target.z = target.position.z;
      instance.update();
    }

    return instance;
    //CUSTOM_REGISTER_CONTROL_ORBIT_CONTROLS_END

    //GENERATED_REGISTER_CONTROL_ORBIT_CONTROLS_AFTER_START
    //GENERATED_REGISTER_CONTROL_ORBIT_CONTROLS_AFTER_END

  }

  /**
   * updateControlOrbitControls()
   * - Three implementation for [R3EventObjComponentInputOrbitControls]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateControlOrbitControls(object, property, value) {

    //GENERATED_UPDATE_CONTROL_ORBIT_CONTROLS_BEFORE_START
    //GENERATED_UPDATE_CONTROL_ORBIT_CONTROLS_BEFORE_END

    //GENERATED_UPDATE_CONTROL_ORBIT_CONTROLS_START
    //GENERATED_UPDATE_CONTROL_ORBIT_CONTROLS_END

    //CUSTOM_UPDATE_CONTROL_ORBIT_CONTROLS_START
    if (
      property === 'id' ||
      property === 'name' ||
      property === 'project'
    ) {
      return;
    }

    if (property === 'target') {

      let instance = object.getInstance(this);
      if (!instance) {
        return;
      }

      if (value) {

        let target = value.getInstance(Runtime.KEY_GRAPHICS_THREE);

        if (!target) {
          return;
        }

        let position = target.position;

        if (value.parentMesh) {
          position = new THREE.Vector3();
          target.getWorldPosition(position);
        }

        instance.target.x = position.x;
        instance.target.y = position.y;
        instance.target.z = position.z;
        instance.update();
      } else {
        instance.target.x = 0;
        instance.target.y = 0;
        instance.target.z = 0;
        instance.update();
      }
      return;
    }

    if (property === 'domElement') {
      this.deRegisterControlOrbitControls(object);
      object.setInstance(this, this.registerControlOrbitControls(object));
      return;
    }

    throw new Error(`Please implement Three.updateControlOrbitControls(object) in R3RuntimeInputThree for property ${property}`);
    //CUSTOM_UPDATE_CONTROL_ORBIT_CONTROLS_END

    //GENERATED_UPDATE_CONTROL_ORBIT_CONTROLS_AFTER_START
    //GENERATED_UPDATE_CONTROL_ORBIT_CONTROLS_AFTER_END

  }

  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Three.Type = 'R3RuntimeInputThree';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Three as default};
