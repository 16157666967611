//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//CUSTOM_IMPORTS_START
import Event from './Event.js';
import {v4} from 'uuid';
import Md5 from 'crypto-js/md5.js';
import Hex from 'crypto-js/enc-hex.js';

//CUSTOM_IMPORTS_END

import R3 from '../R3.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  RandomId(length = 10) - Generates a 10 digit random number @returns string
  EmailId(email) - Generates a hash from an email string
  Success(results) - Looks at the results and returns whether some step failed
  GetCurrentProject() - Returns the current project or null
  GetCurrentUser() - Returns the current user or null
  GetCurrentRenderer() - Returns the current renderer or null
  GetCurrentScene() - Returns the current scene or null
  GetCurrentCamera() - Returns the current camera or null
  GetCurrentCanvas() - Returns the current canvas or null
  GetCurrentLight() - Returns the current light or null
  Emit(results) - Emits all the results as STATUS_INFO
  Errors(results) - Looks at the results and returns the error messages
  RemoveFromArray(array, object) - Removes object from array
  RemoveFromArrayById(array, id) - Removes object from the array, the object with ID
  PushUnique(array, object) - Push only if there is not an object in the array already
  PushUniqueById(array, object) - Push only if there is not an object in the array already with this Id
  LowercaseFirstLetter(string) - Turns first letter to lowercase
  CapitalizeFirstLetter(string) - Turns first letter to uppercase
  ToCamel(string) - Takes a snake_cased or snake-cased string and turns it to snakeCased
  LowerSnakeCase(string) - Turns first letter to lowercase
  UpperDotCase(string) - Turns string to Upper.Dot.Case
  UpperSnakeCase(string) - Turns string to UPPER_SNAKE_CASE
  GetConstructorEventName(classId) - Returns the event name to be used for retrieving the constructor from a classId
  GetConstructor(classId) - Returns the constructor given a classId
  RemoveFromSetWithId(set, id) - Removes the object with id from set
  ArrayOfType(array, constructor) - Checks if all items in the array are of the type of the constructor
  DeepEquals(array1, array2) - Checks if array1 matches array2
  DeepEqualsById(array1, array2) - Checks if array1 matches array2 based on the ID of items in the arrays
  SetApiUrl(url) - Sets the API URL
  GetApiUrl() - Returns the API URL
  SetWebsocketUrl(url) - Sets the Websocket URL
  GetWebsocketUrl() - Returns the Websocket URL
  Intersection(a, b) - Returns the intersection of two arrays as a new array
  Difference(a, b) - Returns the difference of two arrays as a new array
  DifferenceObj(a, b) - Returns the contents of array a without objects with matching IDs in array b
  Assign(object, key, value) - Can assign a value to a deeply nested key with '.' in the key.
  FindValue(object, key) - Returns the value of a normal or nested key, i.e. one with '.' in it, inside the object
  DeleteValue(object, key, item = null) - Deletes the value at a normal or nested key, or removes the item from the array
  ArrayContainsById(a, id) - Returns true if the passed in array 'a' contains an object with 'id', otherwise false
  UpperCaseWordsSpaces(string) - Returns a string with spaces between words and each word's first character is uppercase
  GetObjectById(id) - Returns the object with the ID from Storage cache or Null
  GetParents(object) - Returns a list of all parents, with the highest parents first in the array
  GetChildren(object) - Returns a list of all children, with the lowest children first in the array
  SortByHierarchy(objects) - Sorts the array of objects based on their parent / child relationships. Parents are first, children last.
  Status(code, message) - Emits STATUS_INFO message with code
  GetMeshes() - Returns an array of all meshes
  GetMeshInstances() - Returns an array of all mesh instances
  GetEditorControls() - Returns the current editor controls or null
  SnakeToCamel(string) - From snake case to camelCase
  GetObjectsByConstructor(Constructor) - Returns all objects which are of the Constructor type from the Linking System
  GetObjects() - Returns all objects from the Linking System, excluding Maths components
  GetComponents() - Returns all components from the Linking System, excluding Maths components
  GetEntities() - Returns all entities from the Linking System
  GetUsers() - Returns all users from the Linking System
  GetGroups() - Returns all groups from the Linking System
  GetProjects() - Returns all projects from the Linking System
  CreateProject(type) - Emits an event to create a project
  async GetUsersList(offset, count) - Returns a list of all R3js users
  async Upload(object = null, uploadType) - Creates the DOM elements required to upload a file of uploadType. If object is given, it will update the object information after the file is uploaded with file information and store this information on the object to the back-end
  async GetSourceUrls() - Returns a compound object with all the source Websocket and API Urls
  SetSourceApiUrl(url) - Sets the source API URL
  SetSourceWebsocketUrl(url) - Sets the source Websocket API URL
  GetSourceApiUrl(url) - Sets the source API URL
  GetSourceWebsocketUrl(url) - Sets the source Websocket API URL
  async GetTargetUrls() - Returns a compound object with all the source Websocket and API Urls
  SetTargetApiUrl(url) - Sets the source API URL
  SetTargetWebsocketUrl(url) - Sets the source Websocket API URL
  GetTargetApiUrl(url) - Sets the source API URL
  GetTargetWebsocketUrl(url) - Sets the source Websocket API URL
  async DeployProject(projectId, target) - Deploys the project to the target
  async GetList(type, offset, count, keywords = null) - Gets a list of count objects of type from offset that matches keywords
 CUSTOM_STATIC_METHODS_END

 **/

export class Utils extends R3 {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * RandomId()
   * - Generates a 10 digit random number
   * @param {number} [length=10]
   * @returns string
   */
  static RandomId(length = 10) {

    //GENERATED_STATIC_RANDOM_ID_START
    //GENERATED_STATIC_RANDOM_ID_END

    //CUSTOM_STATIC_RANDOM_ID_START
    return v4();
    // return Math.floor(Math.random() * 1000 * Date.now()).toString(36).substring(0, length)
    //CUSTOM_STATIC_RANDOM_ID_END

    //GENERATED_STATIC_RANDOM_ID_AFTER_START
    //GENERATED_STATIC_RANDOM_ID_AFTER_END

    //CUSTOM_STATIC_GENERATED_RANDOM_ID_AFTER_START
    //CUSTOM_STATIC_GENERATED_RANDOM_ID_AFTER_END

  }
  /**
   * EmailId()
   * - Generates a hash from an email string
   * @param email
   * - No returns
   */
  static EmailId(email) {

    //GENERATED_STATIC_EMAIL_ID_START
    //GENERATED_STATIC_EMAIL_ID_END

    //CUSTOM_STATIC_EMAIL_ID_START
    let hash = Md5(email).toString(Hex);
    return hash;
    //CUSTOM_STATIC_EMAIL_ID_END

    //GENERATED_STATIC_EMAIL_ID_AFTER_START
    //GENERATED_STATIC_EMAIL_ID_AFTER_END

    //CUSTOM_STATIC_GENERATED_EMAIL_ID_AFTER_START
    //CUSTOM_STATIC_GENERATED_EMAIL_ID_AFTER_END

  }
  /**
   * Success()
   * - Looks at the results and returns whether some step failed
   * @param results
   * - No returns
   */
  static Success(results) {

    //GENERATED_STATIC_SUCCESS_START
    for (let r of results) {
      if (r.code !== 0) {
        return false;
      }
    }
    return true;
    //GENERATED_STATIC_SUCCESS_END

    //CUSTOM_STATIC_SUCCESS_START
    //CUSTOM_STATIC_SUCCESS_END

    //GENERATED_STATIC_SUCCESS_AFTER_START
    //GENERATED_STATIC_SUCCESS_AFTER_END

    //CUSTOM_STATIC_GENERATED_SUCCESS_AFTER_START
    //CUSTOM_STATIC_GENERATED_SUCCESS_AFTER_END

  }
  /**
   * GetCurrentProject()
   * - Returns the current project or null
   * - No parameters
   * - No returns
   */
  static GetCurrentProject() {

    //GENERATED_STATIC_GET_CURRENT_PROJECT_START
    //GENERATED_STATIC_GET_CURRENT_PROJECT_END

    //CUSTOM_STATIC_GET_CURRENT_PROJECT_START
    let payload = {};

    Event.Emit(
      Event.GET_CURRENT_PROJECT,
      payload
    );

    let project = payload.results[0];
    if (project) {
      return project;
    }

    return null;
    //CUSTOM_STATIC_GET_CURRENT_PROJECT_END

    //GENERATED_STATIC_GET_CURRENT_PROJECT_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_PROJECT_AFTER_END

  }
  /**
   * GetCurrentUser()
   * - Returns the current user or null
   * - No parameters
   * - No returns
   */
  static GetCurrentUser() {

    //GENERATED_STATIC_GET_CURRENT_USER_START
    //GENERATED_STATIC_GET_CURRENT_USER_END

    //CUSTOM_STATIC_GET_CURRENT_USER_START
    let payload = {};

    Event.Emit(
      Event.GET_CURRENT_USER,
      payload
    );

    let user = payload.results[0];
    if (user) {
      return user;
    }

    return null;
    //CUSTOM_STATIC_GET_CURRENT_USER_END

    //GENERATED_STATIC_GET_CURRENT_USER_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_USER_AFTER_END

  }
  /**
   * GetCurrentRenderer()
   * - Returns the current renderer or null
   * - No parameters
   * - No returns
   */
  static GetCurrentRenderer() {

    //GENERATED_STATIC_GET_CURRENT_RENDERER_START
    //GENERATED_STATIC_GET_CURRENT_RENDERER_END

    //CUSTOM_STATIC_GET_CURRENT_RENDERER_START
    let payload = {};

    Event.Emit(
      Event.GET_CURRENT_RENDERER,
      payload
    );

    let component = payload.results[0];
    if (component) {
      return component;
    }

    return null;
    //CUSTOM_STATIC_GET_CURRENT_RENDERER_END

    //GENERATED_STATIC_GET_CURRENT_RENDERER_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_RENDERER_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_RENDERER_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_RENDERER_AFTER_END

  }
  /**
   * GetCurrentScene()
   * - Returns the current scene or null
   * - No parameters
   * - No returns
   */
  static GetCurrentScene() {

    //GENERATED_STATIC_GET_CURRENT_SCENE_START
    //GENERATED_STATIC_GET_CURRENT_SCENE_END

    //CUSTOM_STATIC_GET_CURRENT_SCENE_START
    let payload = {};

    Event.Emit(
      Event.GET_CURRENT_SCENE,
      payload
    );

    let component = payload.results[0];
    if (component) {
      return component;
    }

    return null;
    //CUSTOM_STATIC_GET_CURRENT_SCENE_END

    //GENERATED_STATIC_GET_CURRENT_SCENE_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_SCENE_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_SCENE_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_SCENE_AFTER_END

  }
  /**
   * GetCurrentCamera()
   * - Returns the current camera or null
   * - No parameters
   * - No returns
   */
  static GetCurrentCamera() {

    //GENERATED_STATIC_GET_CURRENT_CAMERA_START
    //GENERATED_STATIC_GET_CURRENT_CAMERA_END

    //CUSTOM_STATIC_GET_CURRENT_CAMERA_START
    let payload = {};

    Event.Emit(
      Event.GET_CURRENT_CAMERA,
      payload
    );

    let component = payload.results[0];
    if (component) {
      return component;
    }

    return null;
    //CUSTOM_STATIC_GET_CURRENT_CAMERA_END

    //GENERATED_STATIC_GET_CURRENT_CAMERA_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_CAMERA_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_CAMERA_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_CAMERA_AFTER_END

  }
  /**
   * GetCurrentCanvas()
   * - Returns the current canvas or null
   * - No parameters
   * - No returns
   */
  static GetCurrentCanvas() {

    //GENERATED_STATIC_GET_CURRENT_CANVAS_START
    //GENERATED_STATIC_GET_CURRENT_CANVAS_END

    //CUSTOM_STATIC_GET_CURRENT_CANVAS_START
    let payload = {};

    Event.Emit(
      Event.GET_CURRENT_CANVAS,
      payload
    );

    let component = payload.results[0];
    if (component) {
      return component;
    }

    return null;
    //CUSTOM_STATIC_GET_CURRENT_CANVAS_END

    //GENERATED_STATIC_GET_CURRENT_CANVAS_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_CANVAS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_CANVAS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_CANVAS_AFTER_END

  }
  /**
   * GetCurrentLight()
   * - Returns the current light or null
   * - No parameters
   * - No returns
   */
  static GetCurrentLight() {

    //GENERATED_STATIC_GET_CURRENT_LIGHT_START
    //GENERATED_STATIC_GET_CURRENT_LIGHT_END

    //CUSTOM_STATIC_GET_CURRENT_LIGHT_START
    let payload = {};

    Event.Emit(
      Event.GET_CURRENT_LIGHT,
      payload
    );

    let component = payload.results[0];
    if (component) {
      return component;
    }

    return null;
    //CUSTOM_STATIC_GET_CURRENT_LIGHT_END

    //GENERATED_STATIC_GET_CURRENT_LIGHT_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_LIGHT_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_LIGHT_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_LIGHT_AFTER_END

  }
  /**
   * Emit()
   * - Emits all the results as STATUS_INFO
   * @param results
   * - No returns
   */
  static Emit(results) {

    //GENERATED_STATIC_EMIT_START
    //GENERATED_STATIC_EMIT_END

    //CUSTOM_STATIC_EMIT_START
    results.map(
      (result) => {
        Event.Emit(
          Event.STATUS_INFO,
          result
        );
      }
    )
    //CUSTOM_STATIC_EMIT_END

    //GENERATED_STATIC_EMIT_AFTER_START
    //GENERATED_STATIC_EMIT_AFTER_END

    //CUSTOM_STATIC_GENERATED_EMIT_AFTER_START
    //CUSTOM_STATIC_GENERATED_EMIT_AFTER_END

  }
  /**
   * Errors()
   * - Looks at the results and returns the error messages
   * @param results
   * - No returns
   */
  static Errors(results) {

    //GENERATED_STATIC_ERRORS_START
    //GENERATED_STATIC_ERRORS_END

    //CUSTOM_STATIC_ERRORS_START
    let messages = [];

    for (let r of results) {
      if (r.code !== 0) {
        messages.push(r.message);
      }
    }

    return messages.join(',');
    //CUSTOM_STATIC_ERRORS_END

    //GENERATED_STATIC_ERRORS_AFTER_START
    //GENERATED_STATIC_ERRORS_AFTER_END

    //CUSTOM_STATIC_GENERATED_ERRORS_AFTER_START
    //CUSTOM_STATIC_GENERATED_ERRORS_AFTER_END

  }
  /**
   * RemoveFromArray()
   * - Removes object from array
   * @param array
   * @param object
   * - No returns
   */
  static RemoveFromArray(array, object) {

    //GENERATED_STATIC_REMOVE_FROM_ARRAY_START
    let index = array.indexOf(object);
    if (index !== -1) {
      array.splice(index, 1);
    }
    //GENERATED_STATIC_REMOVE_FROM_ARRAY_END

    //CUSTOM_STATIC_REMOVE_FROM_ARRAY_START
    //CUSTOM_STATIC_REMOVE_FROM_ARRAY_END

    //GENERATED_STATIC_REMOVE_FROM_ARRAY_AFTER_START
    //GENERATED_STATIC_REMOVE_FROM_ARRAY_AFTER_END

    //CUSTOM_STATIC_GENERATED_REMOVE_FROM_ARRAY_AFTER_START
    //CUSTOM_STATIC_GENERATED_REMOVE_FROM_ARRAY_AFTER_END

  }
  /**
   * RemoveFromArrayById()
   * - Removes object from the array, the object with ID
   * @param array
   * @param id
   * - No returns
   */
  static RemoveFromArrayById(array, id) {

    //GENERATED_STATIC_REMOVE_FROM_ARRAY_BY_ID_START
    let exists = true;
    while (exists) {
      let index = array.findIndex(item => item.id === id);
      if (index !== -1) {
        array.splice(index, 1);
      } else {
        exists = false;
      }
    }
    //GENERATED_STATIC_REMOVE_FROM_ARRAY_BY_ID_END

    //CUSTOM_STATIC_REMOVE_FROM_ARRAY_BY_ID_START
    //CUSTOM_STATIC_REMOVE_FROM_ARRAY_BY_ID_END

    //GENERATED_STATIC_REMOVE_FROM_ARRAY_BY_ID_AFTER_START
    //GENERATED_STATIC_REMOVE_FROM_ARRAY_BY_ID_AFTER_END

    //CUSTOM_STATIC_GENERATED_REMOVE_FROM_ARRAY_BY_ID_AFTER_START
    //CUSTOM_STATIC_GENERATED_REMOVE_FROM_ARRAY_BY_ID_AFTER_END

  }
  /**
   * PushUnique()
   * - Push only if there is not an object in the array already
   * @param array
   * @param object
   * - No returns
   */
  static PushUnique(array, object) {

    //GENERATED_STATIC_PUSH_UNIQUE_START
    if (array.indexOf(object) === -1) {
      array.push(object);
    }
    //GENERATED_STATIC_PUSH_UNIQUE_END

    //CUSTOM_STATIC_PUSH_UNIQUE_START
    if (array.indexOf(object) === -1) {
      array.push(object);
    }
    //CUSTOM_STATIC_PUSH_UNIQUE_END

    //GENERATED_STATIC_PUSH_UNIQUE_AFTER_START
    //GENERATED_STATIC_PUSH_UNIQUE_AFTER_END

    //CUSTOM_STATIC_GENERATED_PUSH_UNIQUE_AFTER_START
    //CUSTOM_STATIC_GENERATED_PUSH_UNIQUE_AFTER_END

  }
  /**
   * PushUniqueById()
   * - Push only if there is not an object in the array already with this Id
   * @param array
   * @param object
   * - No returns
   */
  static PushUniqueById(array, object) {

    //GENERATED_STATIC_PUSH_UNIQUE_BY_ID_START
    Utils.RemoveFromArrayById(array, object.id);
    array.push(object);
    //GENERATED_STATIC_PUSH_UNIQUE_BY_ID_END

    //CUSTOM_STATIC_PUSH_UNIQUE_BY_ID_START
    //CUSTOM_STATIC_PUSH_UNIQUE_BY_ID_END

    //GENERATED_STATIC_PUSH_UNIQUE_BY_ID_AFTER_START
    //GENERATED_STATIC_PUSH_UNIQUE_BY_ID_AFTER_END

    //CUSTOM_STATIC_GENERATED_PUSH_UNIQUE_BY_ID_AFTER_START
    //CUSTOM_STATIC_GENERATED_PUSH_UNIQUE_BY_ID_AFTER_END

  }
  /**
   * LowercaseFirstLetter()
   * - Turns first letter to lowercase
   * @param string
   * - No returns
   */
  static LowercaseFirstLetter(string) {

    //GENERATED_STATIC_LOWERCASE_FIRST_LETTER_START
    //GENERATED_STATIC_LOWERCASE_FIRST_LETTER_END

    //CUSTOM_STATIC_LOWERCASE_FIRST_LETTER_START
    return string.charAt(0).toLowerCase() + string.slice(1);
    //CUSTOM_STATIC_LOWERCASE_FIRST_LETTER_END

    //GENERATED_STATIC_LOWERCASE_FIRST_LETTER_AFTER_START
    //GENERATED_STATIC_LOWERCASE_FIRST_LETTER_AFTER_END

    //CUSTOM_STATIC_GENERATED_LOWERCASE_FIRST_LETTER_AFTER_START
    //CUSTOM_STATIC_GENERATED_LOWERCASE_FIRST_LETTER_AFTER_END

  }
  /**
   * CapitalizeFirstLetter()
   * - Turns first letter to uppercase
   * @param string
   * - No returns
   */
  static CapitalizeFirstLetter(string) {

    //GENERATED_STATIC_CAPITALIZE_FIRST_LETTER_START
    //GENERATED_STATIC_CAPITALIZE_FIRST_LETTER_END

    //CUSTOM_STATIC_CAPITALIZE_FIRST_LETTER_START
    return string.charAt(0).toUpperCase() + string.slice(1);
    //CUSTOM_STATIC_CAPITALIZE_FIRST_LETTER_END

    //GENERATED_STATIC_CAPITALIZE_FIRST_LETTER_AFTER_START
    //GENERATED_STATIC_CAPITALIZE_FIRST_LETTER_AFTER_END

    //CUSTOM_STATIC_GENERATED_CAPITALIZE_FIRST_LETTER_AFTER_START
    //CUSTOM_STATIC_GENERATED_CAPITALIZE_FIRST_LETTER_AFTER_END

  }
  /**
   * ToCamel()
   * - Takes a snake_cased or snake-cased string and turns it to snakeCased
   * @param string
   * - No returns
   */
  static ToCamel(string) {

    //GENERATED_STATIC_TO_CAMEL_START
    //GENERATED_STATIC_TO_CAMEL_END

    //CUSTOM_STATIC_TO_CAMEL_START
    return string.replace(/([-_][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('_', '');
    });
    //CUSTOM_STATIC_TO_CAMEL_END

    //GENERATED_STATIC_TO_CAMEL_AFTER_START
    //GENERATED_STATIC_TO_CAMEL_AFTER_END

    //CUSTOM_STATIC_GENERATED_TO_CAMEL_AFTER_START
    //CUSTOM_STATIC_GENERATED_TO_CAMEL_AFTER_END

  }
  /**
   * LowerSnakeCase()
   * - Turns first letter to lowercase
   * @param string
   * - No returns
   */
  static LowerSnakeCase(string) {

    //GENERATED_STATIC_LOWER_SNAKE_CASE_START
    //GENERATED_STATIC_LOWER_SNAKE_CASE_END

    //CUSTOM_STATIC_LOWER_SNAKE_CASE_START
    return string.replace(
      /[A-Z]/g,
      (letter, index) => {
        return (index === 0) ? letter.toLowerCase() : '_'+ letter.toLowerCase();
      }
    ).replace(/ /g,'_').replace(/_+/g,'_');
    //CUSTOM_STATIC_LOWER_SNAKE_CASE_END

    //GENERATED_STATIC_LOWER_SNAKE_CASE_AFTER_START
    //GENERATED_STATIC_LOWER_SNAKE_CASE_AFTER_END

    //CUSTOM_STATIC_GENERATED_LOWER_SNAKE_CASE_AFTER_START
    //CUSTOM_STATIC_GENERATED_LOWER_SNAKE_CASE_AFTER_END

  }
  /**
   * UpperDotCase()
   * - Turns string to Upper.Dot.Case
   * @param string
   * - No returns
   */
  static UpperDotCase(string) {

    //GENERATED_STATIC_UPPER_DOT_CASE_START
    //GENERATED_STATIC_UPPER_DOT_CASE_END

    //CUSTOM_STATIC_UPPER_DOT_CASE_START
    return string.replace(
      /[A-Z]/g,
      (letter, index) => {
        return (index === 0) ? letter : '.'+ letter;
      }
    );
    //CUSTOM_STATIC_UPPER_DOT_CASE_END

    //GENERATED_STATIC_UPPER_DOT_CASE_AFTER_START
    //GENERATED_STATIC_UPPER_DOT_CASE_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPPER_DOT_CASE_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPPER_DOT_CASE_AFTER_END

  }
  /**
   * UpperSnakeCase()
   * - Turns string to UPPER_SNAKE_CASE
   * @param string
   * - No returns
   */
  static UpperSnakeCase(string) {

    //GENERATED_STATIC_UPPER_SNAKE_CASE_START
    //GENERATED_STATIC_UPPER_SNAKE_CASE_END

    //CUSTOM_STATIC_UPPER_SNAKE_CASE_START
    return Utils.LowerSnakeCase(string).toUpperCase();
    //CUSTOM_STATIC_UPPER_SNAKE_CASE_END

    //GENERATED_STATIC_UPPER_SNAKE_CASE_AFTER_START
    //GENERATED_STATIC_UPPER_SNAKE_CASE_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPPER_SNAKE_CASE_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPPER_SNAKE_CASE_AFTER_END

  }
  /**
   * GetConstructorEventName()
   * - Returns the event name to be used for retrieving the constructor from a classId
   * @param classId
   * - No returns
   */
  static GetConstructorEventName(classId) {

    //GENERATED_STATIC_GET_CONSTRUCTOR_EVENT_NAME_START
    //GENERATED_STATIC_GET_CONSTRUCTOR_EVENT_NAME_END

    //CUSTOM_STATIC_GET_CONSTRUCTOR_EVENT_NAME_START
    return `GET_${Utils.LowerSnakeCase(classId).toUpperCase()}_CONSTRUCTOR`;
    //CUSTOM_STATIC_GET_CONSTRUCTOR_EVENT_NAME_END

    //GENERATED_STATIC_GET_CONSTRUCTOR_EVENT_NAME_AFTER_START
    //GENERATED_STATIC_GET_CONSTRUCTOR_EVENT_NAME_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CONSTRUCTOR_EVENT_NAME_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CONSTRUCTOR_EVENT_NAME_AFTER_END

  }
  /**
   * GetConstructor()
   * - Returns the constructor given a classId
   * @param classId
   * - No returns
   */
  static GetConstructor(classId) {

    //GENERATED_STATIC_GET_CONSTRUCTOR_START
    //GENERATED_STATIC_GET_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_CONSTRUCTOR_START
    let payload = {};
    Event.Emit(
      Event[Utils.GetConstructorEventName(classId)],
      payload
    );
    return payload.results[0];
    //CUSTOM_STATIC_GET_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CONSTRUCTOR_AFTER_END

  }
  /**
   * RemoveFromSetWithId()
   * - Removes the object with id from set
   * @param set
   * @param id
   * - No returns
   */
  static RemoveFromSetWithId(set, id) {

    //GENERATED_STATIC_REMOVE_FROM_SET_WITH_ID_START
    //GENERATED_STATIC_REMOVE_FROM_SET_WITH_ID_END

    //CUSTOM_STATIC_REMOVE_FROM_SET_WITH_ID_START
    for (let value of set.values()) {
      if (value.id === id) {
        set.delete(value);
        return;
      }
    }
    //CUSTOM_STATIC_REMOVE_FROM_SET_WITH_ID_END

    //GENERATED_STATIC_REMOVE_FROM_SET_WITH_ID_AFTER_START
    //GENERATED_STATIC_REMOVE_FROM_SET_WITH_ID_AFTER_END

    //CUSTOM_STATIC_GENERATED_REMOVE_FROM_SET_WITH_ID_AFTER_START
    //CUSTOM_STATIC_GENERATED_REMOVE_FROM_SET_WITH_ID_AFTER_END

  }
  /**
   * ArrayOfType()
   * - Checks if all items in the array are of the type of the constructor
   * @param array
   * @param constructor
   * - No returns
   */
  static ArrayOfType(array, constructor) {

    //GENERATED_STATIC_ARRAY_OF_TYPE_START
    //GENERATED_STATIC_ARRAY_OF_TYPE_END

    //CUSTOM_STATIC_ARRAY_OF_TYPE_START
    let test = array.filter(
      (item) => {
        if (item instanceof constructor) {
          return false;
        }

        return !(Object(item) instanceof constructor);
      }
    )
    return test.length === 0;
    //CUSTOM_STATIC_ARRAY_OF_TYPE_END

    //GENERATED_STATIC_ARRAY_OF_TYPE_AFTER_START
    //GENERATED_STATIC_ARRAY_OF_TYPE_AFTER_END

    //CUSTOM_STATIC_GENERATED_ARRAY_OF_TYPE_AFTER_START
    //CUSTOM_STATIC_GENERATED_ARRAY_OF_TYPE_AFTER_END

  }
  /**
   * DeepEquals()
   * - Checks if array1 matches array2
   * @param array1
   * @param array2
   * - No returns
   */
  static DeepEquals(array1, array2) {

    //GENERATED_STATIC_DEEP_EQUALS_START
    //GENERATED_STATIC_DEEP_EQUALS_END

    //CUSTOM_STATIC_DEEP_EQUALS_START
    if (array1.length !== array2.length) {
      return false;
    }

    array1.map(
      (item) => {
        if (array2.indexOf(item) === -1) {
          return false;
        }
      }
    );

    return true;
    //CUSTOM_STATIC_DEEP_EQUALS_END

    //GENERATED_STATIC_DEEP_EQUALS_AFTER_START
    //GENERATED_STATIC_DEEP_EQUALS_AFTER_END

    //CUSTOM_STATIC_GENERATED_DEEP_EQUALS_AFTER_START
    //CUSTOM_STATIC_GENERATED_DEEP_EQUALS_AFTER_END

  }
  /**
   * DeepEqualsById()
   * - Checks if array1 matches array2 based on the ID of items in the arrays
   * @param array1
   * @param array2
   * - No returns
   */
  static DeepEqualsById(array1, array2) {

    //GENERATED_STATIC_DEEP_EQUALS_BY_ID_START
    if (!Array.isArray(array1) || !Array.isArray(array2)) {
      throw new Error(`Not valid arrays : ${array1}, ${array2}`);
    }

    if (array1.length !== array2.length) {
      return false; // Arrays have different lengths.
    }

    const idMap = new Map();

    for (const item of array2) {
      if (item && item.id !== undefined && item.id !== null) {
        idMap.set(item.id, true);
      } else {
        throw new Error(`Invalid item id: ${item.id}`); // Handle null or undefined id values.
      }
    }

    for (const item of array1) {
      if (item && item.id !== undefined && item.id !== null) {
        if (!idMap.has(item.id)) {
          return false;
        }
      } else {
        throw new Error(`Invalid item id: ${item.id}`); // Handle null or undefined id values.
      }
    }

    return true;
    //GENERATED_STATIC_DEEP_EQUALS_BY_ID_END

    //CUSTOM_STATIC_DEEP_EQUALS_BY_ID_START
    //CUSTOM_STATIC_DEEP_EQUALS_BY_ID_END

    //GENERATED_STATIC_DEEP_EQUALS_BY_ID_AFTER_START
    //GENERATED_STATIC_DEEP_EQUALS_BY_ID_AFTER_END

    //CUSTOM_STATIC_GENERATED_DEEP_EQUALS_BY_ID_AFTER_START
    //CUSTOM_STATIC_GENERATED_DEEP_EQUALS_BY_ID_AFTER_END

  }
  /**
   * SetApiUrl()
   * - Sets the API URL
   * @param url
   * - No returns
   */
  static SetApiUrl(url) {

    //GENERATED_STATIC_SET_API_URL_START
    //GENERATED_STATIC_SET_API_URL_END

    //CUSTOM_STATIC_SET_API_URL_START
    if (typeof url === 'string') {
      Event.Emit(
        Event.SET_API_URL,
        {
          object: url

        }
      );
    } else {
      Event.Emit(
        Event.SET_API_URL,
        {
          object: {...url}
        }
      );
    }
    //CUSTOM_STATIC_SET_API_URL_END

    //GENERATED_STATIC_SET_API_URL_AFTER_START
    //GENERATED_STATIC_SET_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_API_URL_AFTER_END

  }
  /**
   * GetApiUrl()
   * - Returns the API URL
   * - No parameters
   * - No returns
   */
  static GetApiUrl() {

    //GENERATED_STATIC_GET_API_URL_START
    //GENERATED_STATIC_GET_API_URL_END

    //CUSTOM_STATIC_GET_API_URL_START
    let payload = {};
    Event.Emit(
      Event.GET_API_URL,
      payload
    );
    return payload.results[0];
    //CUSTOM_STATIC_GET_API_URL_END

    //GENERATED_STATIC_GET_API_URL_AFTER_START
    //GENERATED_STATIC_GET_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_API_URL_AFTER_END

  }
  /**
   * SetWebsocketUrl()
   * - Sets the Websocket URL
   * @param url
   * - No returns
   */
  static SetWebsocketUrl(url) {

    //GENERATED_STATIC_SET_WEBSOCKET_URL_START
    //GENERATED_STATIC_SET_WEBSOCKET_URL_END

    //CUSTOM_STATIC_SET_WEBSOCKET_URL_START
    if (typeof url === 'string') {
      Event.Emit(
        Event.SET_WEBSOCKET_URL,
        {
          object: url
        }
      );
    } else {
      Event.Emit(
        Event.SET_API_URL,
        {
          object: {...url}
        }
      );
    }
    //CUSTOM_STATIC_SET_WEBSOCKET_URL_END

    //GENERATED_STATIC_SET_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_SET_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_WEBSOCKET_URL_AFTER_END

  }
  /**
   * GetWebsocketUrl()
   * - Returns the Websocket URL
   * - No parameters
   * - No returns
   */
  static GetWebsocketUrl() {

    //GENERATED_STATIC_GET_WEBSOCKET_URL_START
    //GENERATED_STATIC_GET_WEBSOCKET_URL_END

    //CUSTOM_STATIC_GET_WEBSOCKET_URL_START
    let payload = {};
    Event.Emit(
      Event.GET_WEBSOCKET_URL,
      payload
    );
    return payload.results[0];
    //CUSTOM_STATIC_GET_WEBSOCKET_URL_END

    //GENERATED_STATIC_GET_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_GET_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_WEBSOCKET_URL_AFTER_END

  }
  /**
   * Intersection()
   * - Returns the intersection of two arrays as a new array
   * @param a
   * @param b
   * - No returns
   */
  static Intersection(a, b) {

    //GENERATED_STATIC_INTERSECTION_START
    const setA = new Set(a);
    return b.filter(value => setA.has(value));
    //GENERATED_STATIC_INTERSECTION_END

    //CUSTOM_STATIC_INTERSECTION_START
    //CUSTOM_STATIC_INTERSECTION_END

    //GENERATED_STATIC_INTERSECTION_AFTER_START
    //GENERATED_STATIC_INTERSECTION_AFTER_END

    //CUSTOM_STATIC_GENERATED_INTERSECTION_AFTER_START
    //CUSTOM_STATIC_GENERATED_INTERSECTION_AFTER_END

  }
  /**
   * Difference()
   * - Returns the difference of two arrays as a new array
   * @param a
   * @param b
   * - No returns
   */
  static Difference(a, b) {

    //GENERATED_STATIC_DIFFERENCE_START
    //GENERATED_STATIC_DIFFERENCE_END

    //CUSTOM_STATIC_DIFFERENCE_START
    //CUSTOM_STATIC_DIFFERENCE_END

    //GENERATED_STATIC_DIFFERENCE_AFTER_START
    //GENERATED_STATIC_DIFFERENCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_DIFFERENCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_DIFFERENCE_AFTER_END

  }
  /**
   * DifferenceObj()
   * - Returns the contents of array a without objects with matching IDs in array b
   * @param a
   * @param b
   * - No returns
   */
  static DifferenceObj(a, b) {

    //GENERATED_STATIC_DIFFERENCE_OBJ_START
    return a.filter(
      (aItem) => {
        return b.reduce(
          (result, bItem) => {
            if (aItem.id === bItem.id) {
              result = false;
            }
            return result;
          },
          true
        )
      }
    );
    //GENERATED_STATIC_DIFFERENCE_OBJ_END

    //CUSTOM_STATIC_DIFFERENCE_OBJ_START
    //CUSTOM_STATIC_DIFFERENCE_OBJ_END

    //GENERATED_STATIC_DIFFERENCE_OBJ_AFTER_START
    //GENERATED_STATIC_DIFFERENCE_OBJ_AFTER_END

    //CUSTOM_STATIC_GENERATED_DIFFERENCE_OBJ_AFTER_START
    //CUSTOM_STATIC_GENERATED_DIFFERENCE_OBJ_AFTER_END

  }
  /**
   * Assign()
   * - Can assign a value to a deeply nested key with '.' in the key.
   * @param object
   * @param key
   * @param value
   * - No returns
   */
  static Assign(object, key, value) {

    //GENERATED_STATIC_ASSIGN_START
    const merge = (object, subKey, subKeys) => {

      if (subKeys.length > 0) {

        subKey = subKeys[0];

        subKeys.splice(0, 1);

        if (subKeys.length) {
          if (object[subKey]) {
            if (typeof object[subKey] !== 'object') {
              throw new Error('Cannot merge if not of type object');
            }
            object[subKey] = {...object[subKey]}
          } else {
            object[subKey] = {};
          }
          merge(object[subKey], subKey, subKeys);
        } else {
          if (object[subKey] instanceof Set) {
            object[subKey] = new Set(value)
          } else if (object[subKey] instanceof Array) {
            object[subKey] = [...value];
          } else {
            object[subKey] = value;
          }
        }
      }

    }

    if (key.indexOf(".") !== -1) {
      let subKeys = key.split('.');
      merge(object, null, subKeys);
      delete object[key];
    } else {
      if (object[key] instanceof Set) {
        object[key] = new Set(value)
      } else if (object[key] instanceof Array) {
        object[key] = [...value];
      } else {
        object[key] = value;
      }
    }
    //GENERATED_STATIC_ASSIGN_END

    //CUSTOM_STATIC_ASSIGN_START
    //CUSTOM_STATIC_ASSIGN_END

    //GENERATED_STATIC_ASSIGN_AFTER_START
    //GENERATED_STATIC_ASSIGN_AFTER_END

    //CUSTOM_STATIC_GENERATED_ASSIGN_AFTER_START
    //CUSTOM_STATIC_GENERATED_ASSIGN_AFTER_END

  }
  /**
   * FindValue()
   * - Returns the value of a normal or nested key, i.e. one with '.' in it, inside the object
   * @param object
   * @param key
   * - No returns
   */
  static FindValue(object, key) {

    //GENERATED_STATIC_FIND_VALUE_START
    let value = null;

    if (key.indexOf('.') !== -1) {

      let subKeys = key.split('.');

      const findValue = (object, subKey, subKeys) => {

        if (!object) {
          return;
        }

        if (subKeys.length > 0) {

          subKey = subKeys[0];

          subKeys.splice(0, 1);

          if (subKeys.length) {
            findValue(object[subKey], subKey, subKeys);
          } else {
            value = object[subKey];
          }

        }

      }

      findValue(object, null, subKeys);

    } else {
      value = object[key];
    }

    return value;
    //GENERATED_STATIC_FIND_VALUE_END

    //CUSTOM_STATIC_FIND_VALUE_START
    //CUSTOM_STATIC_FIND_VALUE_END

    //GENERATED_STATIC_FIND_VALUE_AFTER_START
    //GENERATED_STATIC_FIND_VALUE_AFTER_END

    //CUSTOM_STATIC_GENERATED_FIND_VALUE_AFTER_START
    //CUSTOM_STATIC_GENERATED_FIND_VALUE_AFTER_END

  }
  /**
   * DeleteValue()
   * - Deletes the value at a normal or nested key, or removes the item from the array
   * @param object
   * @param key
   * @param {Object|null} [item=null]
   * - No returns
   */
  static DeleteValue(object, key, item = null) {

    //GENERATED_STATIC_DELETE_VALUE_START
    if (key.indexOf('.') !== -1) {

      let subKeys = key.split('.');

      const findValue = (object, subKey, subKeys) => {

        if (subKeys.length > 0) {

          subKey = subKeys[0];

          subKeys.splice(0, 1);

          if (subKeys.length && object[subKey]) {
            findValue(object[subKey], subKey, subKeys);
          } else {
            if ((object[subKey] instanceof Array) && item) {

              object[subKey] = object[subKey].filter(
                (subItem) => {
                  return subItem.id !== item.id
                }
              );

              return;

              /**
               * Uncomment below to delete the actual key
               */
              // if (!object[subKey].length) {
              //   delete object[subKey];
              // }

            }

            if ((object[subKey] instanceof Set) && item) {

              let newItems = [...object[subKey]].filter(
                (subItem) => {
                  return subItem.id !== item.id
                }
              );

              object[subKey] = new Set(newItems);

              return;

            }

            if (object[subKey]) {
              delete object[subKey];
            }

          }

        }

      }

      findValue(object, null, subKeys);

    } else {
      if ((object[key] instanceof Array) && item) {
        object[key] = object[key].filter(
          (objItem) => {
            return objItem.id !== item.id
          }
        )
        return;
      }

      if ((object[key] instanceof Set) && item) {
        let newItems = [...object[key]].filter(
          (objItem) => {
            return objItem.id !== item.id
          }
        )
        object[key] = new Set(newItems);
        return;
      }

      delete object[key];

    }
    //GENERATED_STATIC_DELETE_VALUE_END

    //CUSTOM_STATIC_DELETE_VALUE_START
    //CUSTOM_STATIC_DELETE_VALUE_END

    //GENERATED_STATIC_DELETE_VALUE_AFTER_START
    //GENERATED_STATIC_DELETE_VALUE_AFTER_END

    //CUSTOM_STATIC_GENERATED_DELETE_VALUE_AFTER_START
    //CUSTOM_STATIC_GENERATED_DELETE_VALUE_AFTER_END

  }
  /**
   * ArrayContainsById()
   * - Returns true if the passed in array 'a' contains an object with 'id', otherwise false
   * @param a
   * @param id
   * - No returns
   */
  static ArrayContainsById(a, id) {

    //GENERATED_STATIC_ARRAY_CONTAINS_BY_ID_START
    return a.reduce(
      (result, item) => {
        if (item.id === id) {
          result = true;
        }
        return result;
      },
      false
    );
    //GENERATED_STATIC_ARRAY_CONTAINS_BY_ID_END

    //CUSTOM_STATIC_ARRAY_CONTAINS_BY_ID_START
    //CUSTOM_STATIC_ARRAY_CONTAINS_BY_ID_END

    //GENERATED_STATIC_ARRAY_CONTAINS_BY_ID_AFTER_START
    //GENERATED_STATIC_ARRAY_CONTAINS_BY_ID_AFTER_END

    //CUSTOM_STATIC_GENERATED_ARRAY_CONTAINS_BY_ID_AFTER_START
    //CUSTOM_STATIC_GENERATED_ARRAY_CONTAINS_BY_ID_AFTER_END

  }
  /**
   * UpperCaseWordsSpaces()
   * - Returns a string with spaces between words and each word's first character is uppercase
   * @param string
   * - No returns
   */
  static UpperCaseWordsSpaces(string) {

    //GENERATED_STATIC_UPPER_CASE_WORDS_SPACES_START
    let word = string.replace(/[-_]/g, ' ');

    word = word.replace(/\s+/, ' ');

    let words = word.split(' ');

    return words.reduce(
      function(result, word) {
        result += word[0].toUpperCase() + word.substring(1);
        return result + ' ';
      },
      ''
    ).trim();
    //GENERATED_STATIC_UPPER_CASE_WORDS_SPACES_END

    //CUSTOM_STATIC_UPPER_CASE_WORDS_SPACES_START
    //CUSTOM_STATIC_UPPER_CASE_WORDS_SPACES_END

    //GENERATED_STATIC_UPPER_CASE_WORDS_SPACES_AFTER_START
    //GENERATED_STATIC_UPPER_CASE_WORDS_SPACES_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPPER_CASE_WORDS_SPACES_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPPER_CASE_WORDS_SPACES_AFTER_END

  }
  /**
   * GetObjectById()
   * - Returns the object with the ID from Storage cache or Null
   * @param id
   * - No returns
   */
  static GetObjectById(id) {

    //GENERATED_STATIC_GET_OBJECT_BY_ID_START
    //GENERATED_STATIC_GET_OBJECT_BY_ID_END

    //CUSTOM_STATIC_GET_OBJECT_BY_ID_START
    let payload = {
      id
    };
    Event.Emit(
      Event.GET_STORAGE_OBJECT_BY_ID,
      payload
    );
    return payload.results[0];
    //CUSTOM_STATIC_GET_OBJECT_BY_ID_END

    //GENERATED_STATIC_GET_OBJECT_BY_ID_AFTER_START
    //GENERATED_STATIC_GET_OBJECT_BY_ID_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_OBJECT_BY_ID_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_OBJECT_BY_ID_AFTER_END

  }
  /**
   * GetParents()
   * - Returns a list of all parents, with the highest parents first in the array
   * @param object
   * - No returns
   */
  static GetParents(object) {

    //GENERATED_STATIC_GET_PARENTS_START
    //GENERATED_STATIC_GET_PARENTS_END

    //CUSTOM_STATIC_GET_PARENTS_START
    const parents = [];

    function findParents(obj) {
      const currentObject = obj;

      if (!currentObject) {
        console.error(`Object not found.`);
        return;
      }

      const { parents: parentObjects } = currentObject;

      if (parentObjects.length === 0) {
        // Base case: no more parents
        return;
      }

      // Traverse the parent objects in reverse order to maintain the desired order
      for (let i = parentObjects.length - 1; i >= 0; i--) {
        const parentObject = parentObjects[i];
        // Recursively find parents of the current parent
        findParents(parentObject);
        // Add the parent to the array (if not already added)
        if (!parents.includes(parentObject)) {
          parents.push(parentObject);
        }
      }
    }

    findParents(object);
    return parents;
    //CUSTOM_STATIC_GET_PARENTS_END

    //GENERATED_STATIC_GET_PARENTS_AFTER_START
    //GENERATED_STATIC_GET_PARENTS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_PARENTS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_PARENTS_AFTER_END

  }
  /**
   * GetChildren()
   * - Returns a list of all children, with the lowest children first in the array
   * @param object
   * - No returns
   */
  static GetChildren(object) {

    //GENERATED_STATIC_GET_CHILDREN_START
    //GENERATED_STATIC_GET_CHILDREN_END

    //CUSTOM_STATIC_GET_CHILDREN_START
    const children = [];

    function findChildren(obj) {
      const currentObject = obj;

      if (!currentObject) {
        console.error(`Object not found.`);
        return;
      }

      const { children: childObjects } = currentObject;

      if (childObjects.length === 0) {
        // Base case: no more children
        return;
      }

      // Traverse the child objects in the original order to maintain the desired order
      for (const childObject of childObjects) {
        // Recursively find children of the current child
        findChildren(childObject);
        // Add the child to the array (if not already added)
        if (!children.includes(childObject)) {
          children.push(childObject);
        }
      }
    }

    findChildren(object);
    return children;
    //CUSTOM_STATIC_GET_CHILDREN_END

    //GENERATED_STATIC_GET_CHILDREN_AFTER_START
    //GENERATED_STATIC_GET_CHILDREN_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CHILDREN_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CHILDREN_AFTER_END

  }
  /**
   * SortByHierarchy()
   * - Sorts the array of objects based on their parent / child relationships. Parents are first, children last.
   * @param objects
   * - No returns
   */
  static SortByHierarchy(objects) {

    //GENERATED_STATIC_SORT_BY_HIERARCHY_START
    //GENERATED_STATIC_SORT_BY_HIERARCHY_END

    //CUSTOM_STATIC_SORT_BY_HIERARCHY_START
    const idToIndexMap = new Map(); // Map to store the index of each object based on its ID
    const inDegree = new Map(); // Map to store the in-degree (number of incoming edges) for each object

    // Initialize in-degree and idToIndexMap
    objects.forEach((obj, index) => {
      const { id, children } = obj;

      // Initialize in-degree
      inDegree.set(id, inDegree.get(id) || 0);

      // Initialize idToIndexMap
      idToIndexMap.set(id, index);

      // Update in-degree for children
      children.forEach(child => {
        inDegree.set(child.id, (inDegree.get(child.id) || 0) + 1);
      });
    });

    const queue = [];

    // Enqueue objects with in-degree 0 (topmost parents)
    inDegree.forEach((inDegreeCount, id) => {
      if (inDegreeCount === 0) {
        queue.push(id);
      }
    });

    const sortedObjects = [];

    while (queue.length > 0) {
      const currentId = queue.shift();
      const currentIndex = idToIndexMap.get(currentId);

      if (typeof currentIndex === 'undefined') {
        console.warn(`Could not find an object with id ${currentId}`);
        continue;
      }

      const currentObject = objects[currentIndex];

      sortedObjects.push(currentObject);

      // Update in-degree for children and enqueue if in-degree becomes 0
      currentObject.children.forEach(child => {
        inDegree.set(child.id, inDegree.get(child.id) - 1);
        if (inDegree.get(child.id) === 0) {
          queue.push(child.id);
        }
      });
    }

    return sortedObjects;
    //CUSTOM_STATIC_SORT_BY_HIERARCHY_END

    //GENERATED_STATIC_SORT_BY_HIERARCHY_AFTER_START
    //GENERATED_STATIC_SORT_BY_HIERARCHY_AFTER_END

    //CUSTOM_STATIC_GENERATED_SORT_BY_HIERARCHY_AFTER_START
    //CUSTOM_STATIC_GENERATED_SORT_BY_HIERARCHY_AFTER_END

  }
  /**
   * Status()
   * - Emits STATUS_INFO message with code
   * @param code
   * @param message
   * - No returns
   */
  static Status(code, message) {

    //GENERATED_STATIC_STATUS_START
    //GENERATED_STATIC_STATUS_END

    //CUSTOM_STATIC_STATUS_START
    Event.Emit(
      Event.STATUS_INFO,
      {
        code,
        message
      }
    );
    //CUSTOM_STATIC_STATUS_END

    //GENERATED_STATIC_STATUS_AFTER_START
    //GENERATED_STATIC_STATUS_AFTER_END

    //CUSTOM_STATIC_GENERATED_STATUS_AFTER_START
    //CUSTOM_STATIC_GENERATED_STATUS_AFTER_END

  }
  /**
   * GetMeshes()
   * - Returns an array of all meshes
   * - No parameters
   * - No returns
   */
  static GetMeshes() {

    //GENERATED_STATIC_GET_MESHES_START
    //GENERATED_STATIC_GET_MESHES_END

    //CUSTOM_STATIC_GET_MESHES_START
    let payload = {};
    Event.Emit(
      Event.GET_MESHES,
      payload
    );
    return payload.results;
    //CUSTOM_STATIC_GET_MESHES_END

    //GENERATED_STATIC_GET_MESHES_AFTER_START
    //GENERATED_STATIC_GET_MESHES_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_MESHES_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_MESHES_AFTER_END

  }
  /**
   * GetMeshInstances()
   * - Returns an array of all mesh instances
   * - No parameters
   * - No returns
   */
  static GetMeshInstances() {

    //GENERATED_STATIC_GET_MESH_INSTANCES_START
    //GENERATED_STATIC_GET_MESH_INSTANCES_END

    //CUSTOM_STATIC_GET_MESH_INSTANCES_START
    let payload = {};
    Event.Emit(
      Event.GET_MESH_INSTANCES,
      payload
    );
    return payload.results;
    //CUSTOM_STATIC_GET_MESH_INSTANCES_END

    //GENERATED_STATIC_GET_MESH_INSTANCES_AFTER_START
    //GENERATED_STATIC_GET_MESH_INSTANCES_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_MESH_INSTANCES_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_MESH_INSTANCES_AFTER_END

  }
  /**
   * GetEditorControls()
   * - Returns the current editor controls or null
   * - No parameters
   * - No returns
   */
  static GetEditorControls() {

    //GENERATED_STATIC_GET_EDITOR_CONTROLS_START
    //GENERATED_STATIC_GET_EDITOR_CONTROLS_END

    //CUSTOM_STATIC_GET_EDITOR_CONTROLS_START
    let payload = {};
    Event.Emit(
      Event.GET_EDITOR_CONTROLS,
      payload
    );
    return payload.results[0];
    //CUSTOM_STATIC_GET_EDITOR_CONTROLS_END

    //GENERATED_STATIC_GET_EDITOR_CONTROLS_AFTER_START
    //GENERATED_STATIC_GET_EDITOR_CONTROLS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_EDITOR_CONTROLS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_EDITOR_CONTROLS_AFTER_END

  }
  /**
   * SnakeToCamel()
   * - From snake case to camelCase
   * @param string
   * - No returns
   */
  static SnakeToCamel(string) {

    //GENERATED_STATIC_SNAKE_TO_CAMEL_START
    //GENERATED_STATIC_SNAKE_TO_CAMEL_END

    //CUSTOM_STATIC_SNAKE_TO_CAMEL_START
    return string.replace(/_([a-z\d])/g, function(match, group) {
      return group.toUpperCase();
    });
    //CUSTOM_STATIC_SNAKE_TO_CAMEL_END

    //GENERATED_STATIC_SNAKE_TO_CAMEL_AFTER_START
    //GENERATED_STATIC_SNAKE_TO_CAMEL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SNAKE_TO_CAMEL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SNAKE_TO_CAMEL_AFTER_END

  }
  /**
   * GetObjectsByConstructor()
   * - Returns all objects which are of the Constructor type from the Linking System
   * @param Constructor
   * - No returns
   */
  static GetObjectsByConstructor(Constructor) {

    //GENERATED_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_START
    //GENERATED_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_START
    let payload = {
      options : {
        Constructor
      }
    };

    Event.Emit(
      Event.GET_OBJECTS_BY_CONSTRUCTOR,
      payload
    );

    return payload.results;
    //CUSTOM_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_OBJECTS_BY_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_OBJECTS_BY_CONSTRUCTOR_AFTER_END

  }
  /**
   * GetObjects()
   * - Returns all objects from the Linking System, excluding Maths components
   * - No parameters
   * - No returns
   */
  static GetObjects() {

    //GENERATED_STATIC_GET_OBJECTS_START
    //GENERATED_STATIC_GET_OBJECTS_END

    //CUSTOM_STATIC_GET_OBJECTS_START
    let payload = {};

    Event.Emit(
      Event.GET_OBJECTS,
      payload
    );

    return payload.results;
    //CUSTOM_STATIC_GET_OBJECTS_END

    //GENERATED_STATIC_GET_OBJECTS_AFTER_START
    //GENERATED_STATIC_GET_OBJECTS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_OBJECTS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_OBJECTS_AFTER_END

  }
  /**
   * GetComponents()
   * - Returns all components from the Linking System, excluding Maths components
   * - No parameters
   * - No returns
   */
  static GetComponents() {

    //GENERATED_STATIC_GET_COMPONENTS_START
    //GENERATED_STATIC_GET_COMPONENTS_END

    //CUSTOM_STATIC_GET_COMPONENTS_START
    let payload = {};

    Event.Emit(
      Event.GET_COMPONENTS,
      payload
    );

    return payload.results;
    //CUSTOM_STATIC_GET_COMPONENTS_END

    //GENERATED_STATIC_GET_COMPONENTS_AFTER_START
    //GENERATED_STATIC_GET_COMPONENTS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_COMPONENTS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_COMPONENTS_AFTER_END

  }
  /**
   * GetEntities()
   * - Returns all entities from the Linking System
   * - No parameters
   * - No returns
   */
  static GetEntities() {

    //GENERATED_STATIC_GET_ENTITIES_START
    //GENERATED_STATIC_GET_ENTITIES_END

    //CUSTOM_STATIC_GET_ENTITIES_START
    let payload = {};

    Event.Emit(
      Event.GET_ENTITIES,
      payload
    );

    return payload.results;
    //CUSTOM_STATIC_GET_ENTITIES_END

    //GENERATED_STATIC_GET_ENTITIES_AFTER_START
    //GENERATED_STATIC_GET_ENTITIES_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_ENTITIES_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_ENTITIES_AFTER_END

  }
  /**
   * GetUsers()
   * - Returns all users from the Linking System
   * - No parameters
   * - No returns
   */
  static GetUsers() {

    //GENERATED_STATIC_GET_USERS_START
    //GENERATED_STATIC_GET_USERS_END

    //CUSTOM_STATIC_GET_USERS_START
    let payload = {};

    Event.Emit(
      Event.GET_USERS,
      payload
    );

    return payload.results;
    //CUSTOM_STATIC_GET_USERS_END

    //GENERATED_STATIC_GET_USERS_AFTER_START
    //GENERATED_STATIC_GET_USERS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_USERS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_USERS_AFTER_END

  }
  /**
   * GetGroups()
   * - Returns all groups from the Linking System
   * - No parameters
   * - No returns
   */
  static GetGroups() {

    //GENERATED_STATIC_GET_GROUPS_START
    //GENERATED_STATIC_GET_GROUPS_END

    //CUSTOM_STATIC_GET_GROUPS_START
    let payload = {};

    Event.Emit(
      Event.GET_GROUPS,
      payload
    );

    return payload.results;
    //CUSTOM_STATIC_GET_GROUPS_END

    //GENERATED_STATIC_GET_GROUPS_AFTER_START
    //GENERATED_STATIC_GET_GROUPS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_GROUPS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_GROUPS_AFTER_END

  }
  /**
   * GetProjects()
   * - Returns all projects from the Linking System
   * - No parameters
   * - No returns
   */
  static GetProjects() {

    //GENERATED_STATIC_GET_PROJECTS_START
    //GENERATED_STATIC_GET_PROJECTS_END

    //CUSTOM_STATIC_GET_PROJECTS_START
    let payload = {};

    Event.Emit(
      Event.GET_PROJECTS,
      payload
    );

    return payload.results;
    //CUSTOM_STATIC_GET_PROJECTS_END

    //GENERATED_STATIC_GET_PROJECTS_AFTER_START
    //GENERATED_STATIC_GET_PROJECTS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_PROJECTS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_PROJECTS_AFTER_END

  }
  /**
   * CreateProject()
   * - Emits an event to create a project
   * @param type
   * - No returns
   */
  static CreateProject(type) {

    //GENERATED_STATIC_CREATE_PROJECT_START
    //GENERATED_STATIC_CREATE_PROJECT_END

    //CUSTOM_STATIC_CREATE_PROJECT_START
    let payload = {
      type
    }

    Event.Emit(
      Event.CREATE_PROJECT,
      payload
    );

    return payload.results[0];
    //CUSTOM_STATIC_CREATE_PROJECT_END

    //GENERATED_STATIC_CREATE_PROJECT_AFTER_START
    //GENERATED_STATIC_CREATE_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_CREATE_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_CREATE_PROJECT_AFTER_END

  }
  /**
   * GetUsersList()
   * - Returns a list of all R3js users
   * @param offset
   * @param count
   * - No returns
   */
  static async GetUsersList(offset, count) {

    //GENERATED_STATIC_GET_USERS_LIST_START
    //GENERATED_STATIC_GET_USERS_LIST_END

    //CUSTOM_STATIC_GET_USERS_LIST_START
    let payload = {
      offset, count
    };

    await Event.Serialize(
      Event.GET_USERS_LIST,
      payload
    );

    return payload.results;
    //CUSTOM_STATIC_GET_USERS_LIST_END

    //GENERATED_STATIC_GET_USERS_LIST_AFTER_START
    //GENERATED_STATIC_GET_USERS_LIST_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_USERS_LIST_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_USERS_LIST_AFTER_END

  }
  /**
   * Upload()
   * - Creates the DOM elements required to upload a file of uploadType. If object is given, it will update the object
   *   information after the file is uploaded with file information and store this information on the object to the
   *   back-end
   * @param {Object|null} [object=null]
   * @param uploadType
   * - No returns
   */
  static async Upload(object = null, uploadType) {

    //GENERATED_STATIC_UPLOAD_START
    //GENERATED_STATIC_UPLOAD_END

    //CUSTOM_STATIC_UPLOAD_START
    return new Promise(
      (resolve, reject) => {

        const input = document.createElement('input');

        try {
          input.setAttribute('type', 'file');

          if (uploadType === 'image') {
            input.setAttribute('accept', 'image/*');
          }

          if (uploadType === 'audio') {
            input.setAttribute('accept', 'audio/*');
          }

          if (uploadType === 'blender') {
            input.setAttribute('accept', '.blend*');
          }

          input.style.opacity = '0';
          input.style.position = 'fixed';
          document.body.appendChild(input);
        } catch (error) {
          reject(error);
        }

        input.addEventListener('input',
          async () => {

            try {

              let selectedFile = input.files[0];
              document.body.removeChild(input);
              let Constructor = Utils.GetConstructor('R3EventObjFile');
              let file = new Constructor(
                {
                  fileType: uploadType
                }
              );
              file.body.set(`${uploadType}File`, selectedFile);
              await file.save();

              let urlPath = `/${uploadType}/${file.id}`;

              if (object === null) {

                /**
                 * Constructor
                 * @type {null|Class}
                 */
                let Constructor = null;

                if (uploadType === 'image') {
                  Constructor = Utils.GetConstructor('R3EventObjComponentGraphicsImage');
                }

                if (uploadType === 'audio') {
                  Constructor = Utils.GetConstructor('R3EventObjComponentGraphicsAudio');
                }

                if (Constructor) {
                  /**
                   * @type {R3.Obj}
                   */
                  object = new Constructor(
                    {
                      size : file.size,
                      contentType : file.contentType,
                      urlPath
                    }
                  );

                  await object.save();

                  await object.load();

                  resolve(true);

                  return;
                }
              }

              if (object) {
                object.size = file.size;
                object.contentType = file.contentType;
                object.urlPath = urlPath;

                await object.save();

                await object.load();
              }

              resolve(true);

            } catch (error) {
              reject(error);
            }
          },
          {
            once: true
          }
        );

        input.click();
      }
    );
    //CUSTOM_STATIC_UPLOAD_END

    //GENERATED_STATIC_UPLOAD_AFTER_START
    //GENERATED_STATIC_UPLOAD_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPLOAD_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPLOAD_AFTER_END

  }
  /**
   * GetSourceUrls()
   * - Returns a compound object with all the source Websocket and API Urls
   * - No parameters
   * - No returns
   */
  static async GetSourceUrls() {

    //GENERATED_STATIC_GET_SOURCE_URLS_START
    //GENERATED_STATIC_GET_SOURCE_URLS_END

    //CUSTOM_STATIC_GET_SOURCE_URLS_START
    let payload = {};

    await Event.Serialize(
      Event.GET_SOURCE_URLS,
      payload
    );

    return payload.results[0];
    //CUSTOM_STATIC_GET_SOURCE_URLS_END

    //GENERATED_STATIC_GET_SOURCE_URLS_AFTER_START
    //GENERATED_STATIC_GET_SOURCE_URLS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_SOURCE_URLS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_SOURCE_URLS_AFTER_END

  }
  /**
   * SetSourceApiUrl()
   * - Sets the source API URL
   * @param url
   * - No returns
   */
  static SetSourceApiUrl(url) {

    //GENERATED_STATIC_SET_SOURCE_API_URL_START
    //GENERATED_STATIC_SET_SOURCE_API_URL_END

    //CUSTOM_STATIC_SET_SOURCE_API_URL_START
    Event.Emit(
      Event.SET_SOURCE_API_URL,
      {url}
    );
    //CUSTOM_STATIC_SET_SOURCE_API_URL_END

    //GENERATED_STATIC_SET_SOURCE_API_URL_AFTER_START
    //GENERATED_STATIC_SET_SOURCE_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_SOURCE_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_SOURCE_API_URL_AFTER_END

  }
  /**
   * SetSourceWebsocketUrl()
   * - Sets the source Websocket API URL
   * @param url
   * - No returns
   */
  static SetSourceWebsocketUrl(url) {

    //GENERATED_STATIC_SET_SOURCE_WEBSOCKET_URL_START
    //GENERATED_STATIC_SET_SOURCE_WEBSOCKET_URL_END

    //CUSTOM_STATIC_SET_SOURCE_WEBSOCKET_URL_START
    Event.Emit(
      Event.SET_SOURCE_WEBSOCKET_URL,
      {url}
    );
    //CUSTOM_STATIC_SET_SOURCE_WEBSOCKET_URL_END

    //GENERATED_STATIC_SET_SOURCE_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_SET_SOURCE_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_SOURCE_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_SOURCE_WEBSOCKET_URL_AFTER_END

  }
  /**
   * GetSourceApiUrl()
   * - Sets the source API URL
   * @param url
   * - No returns
   */
  static GetSourceApiUrl(url) {

    //GENERATED_STATIC_GET_SOURCE_API_URL_START
    //GENERATED_STATIC_GET_SOURCE_API_URL_END

    //CUSTOM_STATIC_GET_SOURCE_API_URL_START
    //CUSTOM_STATIC_GET_SOURCE_API_URL_END

    //GENERATED_STATIC_GET_SOURCE_API_URL_AFTER_START
    //GENERATED_STATIC_GET_SOURCE_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_SOURCE_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_SOURCE_API_URL_AFTER_END

  }
  /**
   * GetSourceWebsocketUrl()
   * - Sets the source Websocket API URL
   * @param url
   * - No returns
   */
  static GetSourceWebsocketUrl(url) {

    //GENERATED_STATIC_GET_SOURCE_WEBSOCKET_URL_START
    //GENERATED_STATIC_GET_SOURCE_WEBSOCKET_URL_END

    //CUSTOM_STATIC_GET_SOURCE_WEBSOCKET_URL_START
    //CUSTOM_STATIC_GET_SOURCE_WEBSOCKET_URL_END

    //GENERATED_STATIC_GET_SOURCE_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_GET_SOURCE_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_SOURCE_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_SOURCE_WEBSOCKET_URL_AFTER_END

  }
  /**
   * GetTargetUrls()
   * - Returns a compound object with all the source Websocket and API Urls
   * - No parameters
   * - No returns
   */
  static async GetTargetUrls() {

    //GENERATED_STATIC_GET_TARGET_URLS_START
    //GENERATED_STATIC_GET_TARGET_URLS_END

    //CUSTOM_STATIC_GET_TARGET_URLS_START
    let payload = {};

    await Event.Serialize(
      Event.GET_TARGET_URLS,
      payload
    );

    return payload.results[0];
    //CUSTOM_STATIC_GET_TARGET_URLS_END

    //GENERATED_STATIC_GET_TARGET_URLS_AFTER_START
    //GENERATED_STATIC_GET_TARGET_URLS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_TARGET_URLS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_TARGET_URLS_AFTER_END

  }
  /**
   * SetTargetApiUrl()
   * - Sets the source API URL
   * @param url
   * - No returns
   */
  static SetTargetApiUrl(url) {

    //GENERATED_STATIC_SET_TARGET_API_URL_START
    //GENERATED_STATIC_SET_TARGET_API_URL_END

    //CUSTOM_STATIC_SET_TARGET_API_URL_START
    Event.Emit(
      Event.SET_TARGET_API_URL,
      {url}
    );
    //CUSTOM_STATIC_SET_TARGET_API_URL_END

    //GENERATED_STATIC_SET_TARGET_API_URL_AFTER_START
    //GENERATED_STATIC_SET_TARGET_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_TARGET_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_TARGET_API_URL_AFTER_END

  }
  /**
   * SetTargetWebsocketUrl()
   * - Sets the source Websocket API URL
   * @param url
   * - No returns
   */
  static SetTargetWebsocketUrl(url) {

    //GENERATED_STATIC_SET_TARGET_WEBSOCKET_URL_START
    //GENERATED_STATIC_SET_TARGET_WEBSOCKET_URL_END

    //CUSTOM_STATIC_SET_TARGET_WEBSOCKET_URL_START
    Event.Emit(
      Event.SET_TARGET_WEBSOCKET_URL,
      {url}
    );
    //CUSTOM_STATIC_SET_TARGET_WEBSOCKET_URL_END

    //GENERATED_STATIC_SET_TARGET_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_SET_TARGET_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_TARGET_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_TARGET_WEBSOCKET_URL_AFTER_END

  }
  /**
   * GetTargetApiUrl()
   * - Sets the source API URL
   * @param url
   * - No returns
   */
  static GetTargetApiUrl(url) {

    //GENERATED_STATIC_GET_TARGET_API_URL_START
    //GENERATED_STATIC_GET_TARGET_API_URL_END

    //CUSTOM_STATIC_GET_TARGET_API_URL_START
    //CUSTOM_STATIC_GET_TARGET_API_URL_END

    //GENERATED_STATIC_GET_TARGET_API_URL_AFTER_START
    //GENERATED_STATIC_GET_TARGET_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_TARGET_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_TARGET_API_URL_AFTER_END

  }
  /**
   * GetTargetWebsocketUrl()
   * - Sets the source Websocket API URL
   * @param url
   * - No returns
   */
  static GetTargetWebsocketUrl(url) {

    //GENERATED_STATIC_GET_TARGET_WEBSOCKET_URL_START
    //GENERATED_STATIC_GET_TARGET_WEBSOCKET_URL_END

    //CUSTOM_STATIC_GET_TARGET_WEBSOCKET_URL_START
    //CUSTOM_STATIC_GET_TARGET_WEBSOCKET_URL_END

    //GENERATED_STATIC_GET_TARGET_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_GET_TARGET_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_TARGET_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_TARGET_WEBSOCKET_URL_AFTER_END

  }
  /**
   * DeployProject()
   * - Deploys the project to the target
   * @param projectId
   * @param target
   * - No returns
   */
  static async DeployProject(projectId, target) {

    //GENERATED_STATIC_DEPLOY_PROJECT_START
    //GENERATED_STATIC_DEPLOY_PROJECT_END

    //CUSTOM_STATIC_DEPLOY_PROJECT_START
    let payload = {
      projectId,
      target
    };

    await Event.Serialize(
      Event.DEPLOY_PROJECT,
      payload
    );

    return payload.results[0];
    //CUSTOM_STATIC_DEPLOY_PROJECT_END

    //GENERATED_STATIC_DEPLOY_PROJECT_AFTER_START
    //GENERATED_STATIC_DEPLOY_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DEPLOY_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DEPLOY_PROJECT_AFTER_END

  }
  /**
   * GetList()
   * - Gets a list of count objects of type from offset that matches keywords
   * @param type
   * @param offset
   * @param count
   * @param {Object|null} [keywords=null]
   * - No returns
   */
  static async GetList(type, offset, count, keywords = null) {

    //GENERATED_STATIC_GET_LIST_START
    //GENERATED_STATIC_GET_LIST_END

    //CUSTOM_STATIC_GET_LIST_START
    let payload = {
      type,
      offset,
      count,
      keywords
    };

    await Event.Serialize(
      Event.GET_LIST,
      payload
    );

    return payload.results;
    //CUSTOM_STATIC_GET_LIST_END

    //GENERATED_STATIC_GET_LIST_AFTER_START
    //GENERATED_STATIC_GET_LIST_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_LIST_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_LIST_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

//GENERATED_EXPORTS_START
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END

export {Utils as default};
