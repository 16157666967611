//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as Torus} from './Torus.js';
import {default as Sphere} from './Sphere.js';
import {default as Plane} from './Plane.js';
import {default as Buffer} from './Buffer.js';
import {default as Box} from './Box.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
//CUSTOM_ASSIGN_TO_BASE_END

//GENERATED_EXPORTS_START
export {
  Torus,
  Sphere,
  Plane,
  Buffer,
  Box,
//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
