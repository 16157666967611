//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import {io} from 'socket.io-client';
import Event from '../../Event.js';
//CUSTOM_IMPORTS_END

import Websocket from '../Websocket.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  websocketUrl = null - The URL to which websocket requests are made
  currentUser = null - The current user
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  async send(object, message) - Sends a message to a websocket 
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  async send(object, message) - Sends a message to a websocket 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
  async sendObj(object, message) - SocketIO implementation for [R3EventObj] 
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class SocketIO extends Websocket {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeWebsocket';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeWebsocketSocketIO';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'SocketIO';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_WEBSOCKET;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param websocketUrl
     * - The URL to which websocket requests are made
     */
    if (typeof options.websocketUrl === 'undefined') {
      options.websocketUrl = null;
    }

    /**
     * @param currentUser
     * - The current user
     */
    if (typeof options.currentUser === 'undefined') {
      options.currentUser = null;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    Event.On(
      Event.SET_WEBSOCKET_URL,
      (event) => {
        this.websocketUrl = event.object;
        console.log(`SocketIO URL set to ${this.websocketUrl}`);
      },
      {
        priority : 121
      }
    );

    Event.On(
      Event.SET_CURRENT_USER,
      (event) => {
        this.currentUser = event.object;
        console.log(`SocketIO user set to ${this.currentUser.username}`);
      },
      {
        priority : 121
      }
    );
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * send()
   * - Sends a message to a websocket
   * @param object
   * @param message
   * - No returns
   */
  async send(object, message) {

    //CUSTOM_SEND_BEFORE_START
    //CUSTOM_SEND_BEFORE_END

    //GENERATED_SEND_BEFORE_START
    //CUSTOM_RETURN_GENERATED_SEND_START
    await super.send(object, message);
    //CUSTOM_RETURN_GENERATED_SEND_END
    //GENERATED_SEND_BEFORE_END

    //CUSTOM_SEND_BEFORE_GENERATED_START
    //CUSTOM_SEND_BEFORE_GENERATED_END

    //GENERATED_SEND_START
    if (
      object.type === 'R3EventObj'
   || object.type === 'R3EventObjUser'
   || object.type === 'R3EventObjProject'
   || object.type === 'R3EventObjGroup'
   || object.type === 'R3EventObjFile'
   || object.type === 'R3EventObjEntity'
   || object.type === 'R3EventObjEntityAnimation'
   || object.type === 'R3EventObjEntityAnimationRotation'
   || object.type === 'R3EventObjComponent'
   || object.type === 'R3EventObjComponentPhysics'
   || object.type === 'R3EventObjComponentPhysicsWorld'
   || object.type === 'R3EventObjComponentPhysicsRigidBody'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyFixed'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyDynamic'
   || object.type === 'R3EventObjComponentPhysicsJoint'
   || object.type === 'R3EventObjComponentPhysicsJointSpherical'
   || object.type === 'R3EventObjComponentPhysicsJointRevolute'
   || object.type === 'R3EventObjComponentPhysicsJointPrismatic'
   || object.type === 'R3EventObjComponentPhysicsJointFixed'
   || object.type === 'R3EventObjComponentPhysicsGravity'
   || object.type === 'R3EventObjComponentPhysicsCollider'
   || object.type === 'R3EventObjComponentPhysicsColliderTriMesh'
   || object.type === 'R3EventObjComponentPhysicsColliderHeightField'
   || object.type === 'R3EventObjComponentPhysicsColliderCuboid'
   || object.type === 'R3EventObjComponentPhysicsColliderCapsule'
   || object.type === 'R3EventObjComponentPhysicsColliderBall'
   || object.type === 'R3EventObjComponentMaths'
   || object.type === 'R3EventObjComponentMathsVertex'
   || object.type === 'R3EventObjComponentMathsVector4'
   || object.type === 'R3EventObjComponentMathsVector3'
   || object.type === 'R3EventObjComponentMathsVector2'
   || object.type === 'R3EventObjComponentMathsUV'
   || object.type === 'R3EventObjComponentMathsSphere'
   || object.type === 'R3EventObjComponentMathsRay'
   || object.type === 'R3EventObjComponentMathsQuaternion'
   || object.type === 'R3EventObjComponentMathsPlane'
   || object.type === 'R3EventObjComponentMathsMatrix4'
   || object.type === 'R3EventObjComponentMathsMatrix3'
   || object.type === 'R3EventObjComponentMathsFace'
   || object.type === 'R3EventObjComponentMathsColor'
   || object.type === 'R3EventObjComponentInput'
   || object.type === 'R3EventObjComponentInputTouch'
   || object.type === 'R3EventObjComponentInputOrbitControls'
   || object.type === 'R3EventObjComponentInputMouse'
   || object.type === 'R3EventObjComponentInputKeyboard'
   || object.type === 'R3EventObjComponentGraphics'
   || object.type === 'R3EventObjComponentGraphicsVideo'
   || object.type === 'R3EventObjComponentGraphicsTexture'
   || object.type === 'R3EventObjComponentGraphicsTextureVideo'
   || object.type === 'R3EventObjComponentGraphicsTextureImage'
   || object.type === 'R3EventObjComponentGraphicsTextureCube'
   || object.type === 'R3EventObjComponentGraphicsTextureCanvas'
   || object.type === 'R3EventObjComponentGraphicsSprite'
   || object.type === 'R3EventObjComponentGraphicsSkeleton'
   || object.type === 'R3EventObjComponentGraphicsShadow'
   || object.type === 'R3EventObjComponentGraphicsShadowSpot'
   || object.type === 'R3EventObjComponentGraphicsShadowPoint'
   || object.type === 'R3EventObjComponentGraphicsShadowDirectional'
   || object.type === 'R3EventObjComponentGraphicsShader'
   || object.type === 'R3EventObjComponentGraphicsScene'
   || object.type === 'R3EventObjComponentGraphicsRenderer'
   || object.type === 'R3EventObjComponentGraphicsRendererTarget'
   || object.type === 'R3EventObjComponentGraphicsRendererGl'
   || object.type === 'R3EventObjComponentGraphicsRendererCube'
   || object.type === 'R3EventObjComponentGraphicsRaycaster'
   || object.type === 'R3EventObjComponentGraphicsMesh'
   || object.type === 'R3EventObjComponentGraphicsMaterialGroup'
   || object.type === 'R3EventObjComponentGraphicsMaterial'
   || object.type === 'R3EventObjComponentGraphicsMaterialToon'
   || object.type === 'R3EventObjComponentGraphicsMaterialStandard'
   || object.type === 'R3EventObjComponentGraphicsMaterialPoints'
   || object.type === 'R3EventObjComponentGraphicsMaterialPhong'
   || object.type === 'R3EventObjComponentGraphicsMaterialLambert'
   || object.type === 'R3EventObjComponentGraphicsMaterialBasic'
   || object.type === 'R3EventObjComponentGraphicsLight'
   || object.type === 'R3EventObjComponentGraphicsLightSpot'
   || object.type === 'R3EventObjComponentGraphicsLightPoint'
   || object.type === 'R3EventObjComponentGraphicsLightDirectional'
   || object.type === 'R3EventObjComponentGraphicsLightAmbient'
   || object.type === 'R3EventObjComponentGraphicsImage'
   || object.type === 'R3EventObjComponentGraphicsGeometry'
   || object.type === 'R3EventObjComponentGraphicsGeometryTorus'
   || object.type === 'R3EventObjComponentGraphicsGeometrySphere'
   || object.type === 'R3EventObjComponentGraphicsGeometryPlane'
   || object.type === 'R3EventObjComponentGraphicsGeometryBuffer'
   || object.type === 'R3EventObjComponentGraphicsGeometryBox'
   || object.type === 'R3EventObjComponentGraphicsFog'
   || object.type === 'R3EventObjComponentGraphicsCanvas'
   || object.type === 'R3EventObjComponentGraphicsCamera'
   || object.type === 'R3EventObjComponentGraphicsCameraPerspective'
   || object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
   || object.type === 'R3EventObjComponentGraphicsCameraCube'
   || object.type === 'R3EventObjComponentCode'
   || object.type === 'R3EventObjComponentAudio'
   || object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_SEND_OBJ_START
      return await this.sendObj(object, message);
      //CUSTOM_RETURN_OPTION_SEND_OBJ_END
    }
    //GENERATED_SEND_END

    //CUSTOM_SEND_START
    //CUSTOM_SEND_END

    //GENERATED_SEND_AFTER_START
    throw new Error(`No suitable method found in send() in runtime R3RuntimeWebsocketSocketIO`);
    //GENERATED_SEND_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  /**
   * sendObj()
   * - SocketIO implementation for [R3EventObj]
   * @param object
   * @param message
   * - No returns
   */
  async sendObj(object, message) {

    //GENERATED_SEND_OBJ_BEFORE_START
    //GENERATED_SEND_OBJ_BEFORE_END

    //GENERATED_SEND_OBJ_START
    //GENERATED_SEND_OBJ_END

    //CUSTOM_SEND_OBJ_START
    if (!this.websocketUrl) {
      return;
    }

    if (!this.currentUser) {
      return;
    }

    return;
    if (!object.socket) {
      object.socket = io(
        this.websocketUrl,
        {
          extraHeaders : {
            'token' : this.currentUser.token
          }
        }
      );
    }
    object.socket.emit('message', message);
    //CUSTOM_SEND_OBJ_END

    //GENERATED_SEND_OBJ_AFTER_START
    //GENERATED_SEND_OBJ_AFTER_END

  }

  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

SocketIO.Type = 'R3RuntimeWebsocketSocketIO';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {SocketIO as default};
