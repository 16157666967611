//GENERATED_IMPORTS_START

//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START
import {default as DefaultRuntime} from '../runtime/entity/Default.js';
//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
import {default as EntityObject} from '../event/obj/Entity.js';
import {default as CodeComponent} from '../event/obj/component/Code.js';
import Component from "../event/obj/Component.js";
import Utils from '../Utils.js';
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START
  RuntimeEntityDefault = new DefaultRuntime() - Runtime implementation of R3RuntimeEntity
 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Entities = [] - A list of Entities which the system manages
  CodeComponents = [] - A list of all code components
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.AFTER_ASSIGN_PROPERTY(60)
  Event.INITIALIZE_COMPONENT(80)
  Event.DISPOSE_COMPONENT(20)
  Event.INITIALIZE_ENTITY(50)
  Event.DISPOSE_ENTITY(50)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START
  Event.ACTIVATE_ENTITY(60) [R3EventObjEntity]
  Event.DE_ACTIVATE_ENTITY(60) [R3EventObjEntity]
 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  Event.GET_RUNTIME_ENTITY_DEFAULT - Gets the Default runtime implementation of R3RuntimeEntity
 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options = {}) - Starts the system by registering subscriptions to events
  stop(options = {}) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  GetEntities(component) - Returns the entity children of a component
 CUSTOM_STATIC_METHODS_END

 **/

export class Entity extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Entity already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Entity');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Entity already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Entity');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Entity.IsStarting) {
      console.log('client Entity system is already starting...');
      return;
    }

    Entity.IsStarting = true;

    if (Entity.Started === true) {
      Entity.IsStarting = false;
      console.log('client Entity system already started');
      return;
    }

    Entity.Runtimes = new Set();
    Entity.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Entity.Subscriptions['AFTER_ASSIGN_PROPERTY'] = Event.On(
      Event.AFTER_ASSIGN_PROPERTY,
      Entity.AfterAssignProperty,
      {priority: 60}
    );

    /**
     * No comment
     */
    Entity.Subscriptions['INITIALIZE_COMPONENT'] = Event.On(
      Event.INITIALIZE_COMPONENT,
      Entity.InitializeComponent,
      {priority: 80}
    );

    /**
     * No comment
     */
    Entity.Subscriptions['DISPOSE_COMPONENT'] = Event.On(
      Event.DISPOSE_COMPONENT,
      Entity.DisposeComponent,
      {priority: 20}
    );

    /**
     * No comment
     */
    Entity.Subscriptions['INITIALIZE_ENTITY'] = Event.On(
      Event.INITIALIZE_ENTITY,
      Entity.InitializeEntity,
      {priority: 50}
    );

    /**
     * No comment
     */
    Entity.Subscriptions['DISPOSE_ENTITY'] = Event.On(
      Event.DISPOSE_ENTITY,
      Entity.DisposeEntity,
      {priority: 50}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Entity.Subscriptions['ACTIVATE_ENTITY'] = Event.On(
      Event.ACTIVATE_ENTITY,
      Entity.ActivateEntity,
      {priority: 60}
    );

    /**
     * No comment
     */
    Entity.Subscriptions['DE_ACTIVATE_ENTITY'] = Event.On(
      Event.DE_ACTIVATE_ENTITY,
      Entity.DeActivateEntity,
      {priority: 60}
    );
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    /**
     * Gets the Default runtime implementation of R3RuntimeEntity
     */
    Entity.Subscriptions['GET_RUNTIME_ENTITY_DEFAULT'] = Event.On(
      Event.GET_RUNTIME_ENTITY_DEFAULT,
      Entity.GetRuntimeEntityDefault
    );
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    Entity.Entities = Utils.GetObjectsByConstructor(EntityObject);
    for (let entity of Entity.Entities) {
      for (let runtime of [...Entity.Runtimes]) {

        let valid = runtime.validate('activate', entity);

        if (!valid) {
          continue;
        }

        let instance = runtime.activate(entity);
        entity.setInstance(runtime, instance);
        entity.active = true;
      }
    }

    Entity.Started = true;
    Entity.IsStarting = false;
    console.log('Started client system Entity');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Entity.IsStopping) {
      console.log('client Entity system is already stopping');
      return;
    }

    Entity.IsStopping = true;

    if (Entity.Started === false) {
      Entity.IsStopping = false;
      console.log('client Entity system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    Entity.Subscriptions['AFTER_ASSIGN_PROPERTY'].remove();
    delete Entity.Subscriptions['AFTER_ASSIGN_PROPERTY'];

    Entity.Subscriptions['INITIALIZE_COMPONENT'].remove();
    delete Entity.Subscriptions['INITIALIZE_COMPONENT'];

    Entity.Subscriptions['DISPOSE_COMPONENT'].remove();
    delete Entity.Subscriptions['DISPOSE_COMPONENT'];

    Entity.Subscriptions['INITIALIZE_ENTITY'].remove();
    delete Entity.Subscriptions['INITIALIZE_ENTITY'];

    Entity.Subscriptions['DISPOSE_ENTITY'].remove();
    delete Entity.Subscriptions['DISPOSE_ENTITY'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    Entity.Subscriptions['ACTIVATE_ENTITY'].remove();
    delete Entity.Subscriptions['ACTIVATE_ENTITY'];

    Entity.Subscriptions['DE_ACTIVATE_ENTITY'].remove();
    delete Entity.Subscriptions['DE_ACTIVATE_ENTITY'];
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    Entity.Subscriptions['GET_RUNTIME_ENTITY_DEFAULT'].remove();
    delete Entity.Subscriptions['GET_RUNTIME_ENTITY_DEFAULT'];
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    for (let entity of Entity.Entities) {
      for (let runtime of [...Entity.Runtimes]) {
        runtime.deActivate(entity);
        entity.setInstance(runtime, null);
        entity.active = false;
        delete runtime.validObjects[entity.id];
      }
    }
    Entity.Entitys = [];
    Entity.Runtimes.clear();
    Entity.Started = false;
    Entity.IsStopping = false;
    console.log('Stopped client system Entity');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START
    Entity.Runtimes.add(Entity.RuntimeEntityDefault);
    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * GetEntities()
   * - Returns the entity children of a component
   * @param component
   * - No returns
   */
  static GetEntities(component) {

    //GENERATED_STATIC_GET_ENTITIES_START
    //GENERATED_STATIC_GET_ENTITIES_END

    //CUSTOM_STATIC_GET_ENTITIES_START
    let children = Utils.GetChildren(component).filter(
      (child) => {
        return (child instanceof EntityObject);
      }
    );
    return children;
    //CUSTOM_STATIC_GET_ENTITIES_END

    //GENERATED_STATIC_GET_ENTITIES_AFTER_START
    //GENERATED_STATIC_GET_ENTITIES_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_ENTITIES_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_ENTITIES_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * AfterAssignProperty()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static AfterAssignProperty(event) {

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    /**
     * Override
     */
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_START
    let {object} = event;

    let {property, value} = event;

    /**
     * If it is a component name update, update all entityRefs
     */
    if (
      object instanceof Component &&
      property === 'name' &&
      value
    ) {

      let oldRef = object.entityRef;
      object.entityRef = object.getEntityReference(value);

      Entity.CodeComponents.map(
        (codeComponent) => {
          let regex = new RegExp(`\\bthis.\\b${oldRef}\\b`, 'g');
          codeComponent.code = codeComponent.code.replaceAll(regex, `this.${object.entityRef}`);
        }
      );

      Entity.Entities.map(
        (entity) => {
          entity[object.entityRef] = object;
          delete entity[oldRef];
        }
      )

      return;
    }

    /**
     * If it is a code component event update, restart all entities that belong to that component
     */
    if (
      object instanceof CodeComponent &&
      property === 'event'
    ) {

      let component = object;
      let entities = Entity.GetEntities(component);

      for (let entity of entities) {
        entity.deActivate();

        /**
         * First revalidate the entity, entity activation does not re-validate unnecessarily
         */
        for (let runtime of [...Entity.Runtimes]) {
          runtime.validate('activate', entity);
        }

        /**
         * Now try to activate
         */
        entity.activate();
      }

      return;
    }

    if (
      object instanceof CodeComponent &&
      property === 'code'
    ) {
      try {
        object.compiled = false;
        object.compiledCode = new Function('event', 'THREE', value);
        object.compiled = true;
        Utils.Status(0, `Compilation success for ${object.name}`);
      } catch (error) {
        object.compiled = false;
        Utils.Status(-1, `Compilation failed for ${object.name}`);
      }
    }

    if (!(object instanceof EntityObject)) {
      return;
    }

    /**
     * If it is a normal entity update, re-validate the entity
     */
    let entity = object;

    for (let runtime of [...Entity.Runtimes]) {

      let wasValid;
      let isValid;

      if (!runtime.validObjects.hasOwnProperty(entity.id)) {
        wasValid = false;
        isValid = runtime.validate('activate', entity);
      } else {
        wasValid = runtime.validObjects[entity.id];
        isValid = runtime.validate("activate", entity);
      }

      if (!wasValid && !isValid) {
        /**
         * Do nothing
         */
        continue;
      }

      if (!wasValid && isValid) {
        let instance = runtime.activate(entity);
        entity.setInstance(runtime, instance);
        entity.active = true;
        continue;
      }

      if (wasValid && !isValid) {
        runtime.deActivate(entity);
        entity.setInstance(runtime, null);
        entity.active = false;
        continue;
      }

      if (wasValid && isValid) {
        /**
         * Do nothing unless the code member has changed
         */
        if (value instanceof CodeComponent || property === 'components') {
          runtime.deActivate(entity);
          entity.setInstance(runtime, null);
          entity.active = false;
          let instance = runtime.activate(entity);
          entity.setInstance(runtime, instance);
          entity.active = true;
        }
      }
    }
    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_END

  }

  /**
   * InitializeComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeComponent(event) {

    //GENERATED_STATIC_INITIALIZE_COMPONENT_START
    //GENERATED_STATIC_INITIALIZE_COMPONENT_END

    //CUSTOM_STATIC_INITIALIZE_COMPONENT_START
    let {object : component} = event;

    if (component instanceof CodeComponent) {
      Entity.CodeComponents.push(component);
    }

    let entities = Entity.GetEntities(component);

    for (let entity of entities) {

      for (let runtime of [...Entity.Runtimes]) {

        if (entity.active) {
          continue;
        }

        let valid;

        if (!runtime.validObjects.hasOwnProperty(entity.id)) {
          valid = runtime.validate('activate', entity);
        } else {
          valid = runtime.validObjects[entity.id];
        }

        if (valid) {
          let instance = runtime.activate(entity);
          entity.setInstance(runtime, instance);
          entity.active = true;
        }
      }
    }
    //CUSTOM_STATIC_INITIALIZE_COMPONENT_END

    //GENERATED_STATIC_INITIALIZE_COMPONENT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_COMPONENT_AFTER_END

  }

  /**
   * DisposeComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeComponent(event) {

    //GENERATED_STATIC_DISPOSE_COMPONENT_START
    //GENERATED_STATIC_DISPOSE_COMPONENT_END

    //CUSTOM_STATIC_DISPOSE_COMPONENT_START
    let {object : component} = event;
    if (component instanceof CodeComponent) {
      Entity.CodeComponents = Entity.CodeComponents.filter((code) => {return code === component});
    }

    let entities = Entity.GetEntities(component);

    for (let entity of entities) {

      if (!entity.active) {
        continue;
      }

      for (let runtime of [...Entity.Runtimes]) {

        let valid;

        if (!runtime.validObjects.hasOwnProperty(entity.id)) {
          valid = runtime.validate('activate', entity);
        } else {
          valid = runtime.validObjects[entity.id];
        }

        if (!valid) {
          runtime.deActivate(entity);
          entity.setInstance(runtime, null);
          entity.active = false;
        }
      }
    }
    //CUSTOM_STATIC_DISPOSE_COMPONENT_END

    //GENERATED_STATIC_DISPOSE_COMPONENT_AFTER_START
    //GENERATED_STATIC_DISPOSE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_COMPONENT_AFTER_END

  }

  /**
   * InitializeEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeEntity(event) {

    //GENERATED_STATIC_INITIALIZE_ENTITY_START
    //GENERATED_STATIC_INITIALIZE_ENTITY_END

    //CUSTOM_STATIC_INITIALIZE_ENTITY_START
    let {object : entity} = event;
    Entity.Entities.push(entity);
    entity.activate();
    //CUSTOM_STATIC_INITIALIZE_ENTITY_END

    //GENERATED_STATIC_INITIALIZE_ENTITY_AFTER_START
    //GENERATED_STATIC_INITIALIZE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_ENTITY_AFTER_END

  }

  /**
   * DisposeEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeEntity(event) {

    //GENERATED_STATIC_DISPOSE_ENTITY_START
    //GENERATED_STATIC_DISPOSE_ENTITY_END

    //CUSTOM_STATIC_DISPOSE_ENTITY_START
    let {object : entity} = event;
    entity.deActivate();
    Entity.Entities = Entity.Entities.filter((current) => {return current !== entity});
    //CUSTOM_STATIC_DISPOSE_ENTITY_END

    //GENERATED_STATIC_DISPOSE_ENTITY_AFTER_START
    //GENERATED_STATIC_DISPOSE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_ENTITY_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  /**
   * ActivateEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static ActivateEntity(event) {

    //GENERATED_STATIC_ACTIVATE_ENTITY_START
    //CUSTOM_USER_MODE_ACTIVATE_ENTITY_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_ACTIVATE_ENTITY_INSTANCE_HANDLER_END
    //GENERATED_STATIC_ACTIVATE_ENTITY_END

    //CUSTOM_STATIC_ACTIVATE_ENTITY_START
    let {object : entity} = event;

    if (!(entity instanceof EntityObject)) {
      return;
    }

    if (entity.active) {
      return;
    }

    for (let runtime of [...Entity.Runtimes]) {

      let valid;

      if (runtime.validObjects.hasOwnProperty(entity.id)) {
        valid = runtime.validObjects[entity.id];
      } else {
        valid = runtime.validate('activate', entity);
      }

      if (!valid) {
        continue;
      }

      let instance = runtime.activate(entity);
      entity.setInstance(runtime, instance);

      entity.active = true;
    }
    //CUSTOM_STATIC_ACTIVATE_ENTITY_END

    //GENERATED_STATIC_ACTIVATE_ENTITY_AFTER_START
    //GENERATED_STATIC_ACTIVATE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_ACTIVATE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_ACTIVATE_ENTITY_AFTER_END

  }

  /**
   * DeActivateEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DeActivateEntity(event) {

    //GENERATED_STATIC_DE_ACTIVATE_ENTITY_START
    //CUSTOM_USER_MODE_DE_ACTIVATE_ENTITY_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_DE_ACTIVATE_ENTITY_INSTANCE_HANDLER_END
    //GENERATED_STATIC_DE_ACTIVATE_ENTITY_END

    //CUSTOM_STATIC_DE_ACTIVATE_ENTITY_START
    let {object: entity} = event;
    if (!(entity instanceof EntityObject)) {
      return;
    }

    if (!entity.active) {
      return;
    }

    for (let runtime of [...Entity.Runtimes]) {
      runtime.deActivate(entity);
      entity.setInstance(runtime, null);
    }

    entity.active = false;
    //CUSTOM_STATIC_DE_ACTIVATE_ENTITY_END

    //GENERATED_STATIC_DE_ACTIVATE_ENTITY_AFTER_START
    //GENERATED_STATIC_DE_ACTIVATE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_DE_ACTIVATE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_DE_ACTIVATE_ENTITY_AFTER_END

  }
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  /**
   * GetRuntimeEntityDefault()
   * - Gets the Default runtime implementation of R3RuntimeEntity
   * @param {Object} event
   * - No returns
   */
  static GetRuntimeEntityDefault(event) {

    //GENERATED_STATIC_GET_RUNTIME_ENTITY_DEFAULT_START
    event.results = [Entity.RuntimeEntityDefault];
    //GENERATED_STATIC_GET_RUNTIME_ENTITY_DEFAULT_END

    //CUSTOM_STATIC_GET_RUNTIME_ENTITY_DEFAULT_START
    //CUSTOM_STATIC_GET_RUNTIME_ENTITY_DEFAULT_END

    //GENERATED_STATIC_GET_RUNTIME_ENTITY_DEFAULT_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_ENTITY_DEFAULT_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_ENTITY_DEFAULT_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_ENTITY_DEFAULT_AFTER_END

  }
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
/**
 * @param Entity.RuntimeEntityDefault
 * - Runtime implementation of R3RuntimeEntity
 */
Entity.RuntimeEntityDefault = new DefaultRuntime();
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Entity.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Entity.IsStarting = false;

/**
 * @param Entity.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Entity.IsStopping = false;

/**
 * @param Entity.Started
 * - Indicates whether this system is running
 */
Entity.Started = false;

/**
 * @param Entity.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Entity.Subscriptions = {};

/**
 * @param Entity.Runtimes
 * - A set of runtimes which the system manages
 */
Entity.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Entity.Entities
 * - A list of Entities which the system manages
 */
Entity.Entities = [];

/**
 * @param Entity.CodeComponents
 * - A list of all code components
 */
Entity.CodeComponents = [];
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Entity as default};
