//GENERATED_IMPORTS_START

//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START
import {default as AxiosRuntime} from '../runtime/authentication/Axios.js';
//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
// const {DEBUG_MODE} = process.env;
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START
  RuntimeAuthenticationAxios = new AxiosRuntime() - Runtime implementation of R3RuntimeAuthentication
 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START
  async Event.CHANGE_ROLE_USER(90) [R3EventObjUser]
  async Event.GET_USER(90) [R3EventObjUser]
  async Event.INVITE_USER(90) [R3EventObjUser]
  async Event.LOGIN_USER(90) [R3EventObjUser]
  async Event.LOGOUT_USER(90) [R3EventObjUser]
  async Event.PASSWORD_RESET_USER(90) [R3EventObjUser]
  async Event.REGISTER_USER(90) [R3EventObjUser]
 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  Event.GET_RUNTIME_AUTHENTICATION_AXIOS - Gets the Axios runtime implementation of R3RuntimeAuthentication
 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options) - Starts the system by registering subscriptions to events
  stop(options) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Authentication extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Authentication already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Authentication');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Authentication already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Authentication');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Authentication.IsStarting) {
      console.log('client Authentication system is already starting...');
      return;
    }

    Authentication.IsStarting = true;

    if (Authentication.Started === true) {
      Authentication.IsStarting = false;
      console.log('client Authentication system already started');
      return;
    }

    Authentication.Runtimes = new Set();
    Authentication.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Authentication.Subscriptions['CHANGE_ROLE_USER'] = Event.On(
      Event.CHANGE_ROLE_USER,
      Authentication.ChangeRoleUser,
      {priority: 90}
    );

    /**
     * No comment
     */
    Authentication.Subscriptions['GET_USER'] = Event.On(
      Event.GET_USER,
      Authentication.GetUser,
      {priority: 90}
    );

    /**
     * No comment
     */
    Authentication.Subscriptions['INVITE_USER'] = Event.On(
      Event.INVITE_USER,
      Authentication.InviteUser,
      {priority: 90}
    );

    /**
     * No comment
     */
    Authentication.Subscriptions['LOGIN_USER'] = Event.On(
      Event.LOGIN_USER,
      Authentication.LoginUser,
      {priority: 90}
    );

    /**
     * No comment
     */
    Authentication.Subscriptions['LOGOUT_USER'] = Event.On(
      Event.LOGOUT_USER,
      Authentication.LogoutUser,
      {priority: 90}
    );

    /**
     * No comment
     */
    Authentication.Subscriptions['PASSWORD_RESET_USER'] = Event.On(
      Event.PASSWORD_RESET_USER,
      Authentication.PasswordResetUser,
      {priority: 90}
    );

    /**
     * No comment
     */
    Authentication.Subscriptions['REGISTER_USER'] = Event.On(
      Event.REGISTER_USER,
      Authentication.RegisterUser,
      {priority: 90}
    );
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    /**
     * Gets the Axios runtime implementation of R3RuntimeAuthentication
     */
    Authentication.Subscriptions['GET_RUNTIME_AUTHENTICATION_AXIOS'] = Event.On(
      Event.GET_RUNTIME_AUTHENTICATION_AXIOS,
      Authentication.GetRuntimeAuthenticationAxios
    );
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    Authentication.Started = true;
    Authentication.IsStarting = false;
    console.log('Started client system Authentication');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Authentication.IsStopping) {
      console.log('client Authentication system is already stopping');
      return;
    }

    Authentication.IsStopping = true;

    if (Authentication.Started === false) {
      Authentication.IsStopping = false;
      console.log('client Authentication system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    Authentication.Subscriptions['CHANGE_ROLE_USER'].remove();
    delete Authentication.Subscriptions['CHANGE_ROLE_USER'];

    Authentication.Subscriptions['GET_USER'].remove();
    delete Authentication.Subscriptions['GET_USER'];

    Authentication.Subscriptions['INVITE_USER'].remove();
    delete Authentication.Subscriptions['INVITE_USER'];

    Authentication.Subscriptions['LOGIN_USER'].remove();
    delete Authentication.Subscriptions['LOGIN_USER'];

    Authentication.Subscriptions['LOGOUT_USER'].remove();
    delete Authentication.Subscriptions['LOGOUT_USER'];

    Authentication.Subscriptions['PASSWORD_RESET_USER'].remove();
    delete Authentication.Subscriptions['PASSWORD_RESET_USER'];

    Authentication.Subscriptions['REGISTER_USER'].remove();
    delete Authentication.Subscriptions['REGISTER_USER'];
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    Authentication.Subscriptions['GET_RUNTIME_AUTHENTICATION_AXIOS'].remove();
    delete Authentication.Subscriptions['GET_RUNTIME_AUTHENTICATION_AXIOS'];
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    Authentication.Runtimes.clear();
    Authentication.Started = false;
    Authentication.IsStopping = false;
    console.log('Stopped client system Authentication');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START
    Authentication.Runtimes.add(Authentication.RuntimeAuthenticationAxios);
    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  /**
   * ChangeRoleUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async ChangeRoleUser(event) {

    //GENERATED_STATIC_CHANGE_ROLE_USER_START
    //CUSTOM_USER_MODE_CHANGE_ROLE_USER_INSTANCE_HANDLER_START
    let {object} = event;
    
    let {options} = event;
    let {role} = options;

    for (let runtime of Authentication.Runtimes) {
      let valid = runtime.validate('changeRole', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method changeRole`);
      }
    }

    for (let runtime of Authentication.Runtimes) {
      let instance = await runtime.changeRole(object, role);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_CHANGE_ROLE_USER_INSTANCE_HANDLER_END
    //GENERATED_STATIC_CHANGE_ROLE_USER_END

    //CUSTOM_STATIC_CHANGE_ROLE_USER_START
    //CUSTOM_STATIC_CHANGE_ROLE_USER_END

    //GENERATED_STATIC_CHANGE_ROLE_USER_AFTER_START
    //GENERATED_STATIC_CHANGE_ROLE_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_CHANGE_ROLE_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_CHANGE_ROLE_USER_AFTER_END

  }

  /**
   * GetUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async GetUser(event) {

    //GENERATED_STATIC_GET_USER_START
    //CUSTOM_USER_MODE_GET_USER_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_GET_USER_INSTANCE_HANDLER_END
    //GENERATED_STATIC_GET_USER_END

    //CUSTOM_STATIC_GET_USER_START
    let {object: user} = event;

    for (let runtime of Authentication.Runtimes) {
      let valid = runtime.validate('get', user);
      if (!valid) {
        throw new Error(`${user.uniqueName} failed to validate for method get`);
      }
    }

    for (let runtime of Authentication.Runtimes) {
      let instance = await runtime.get(user);
      user.instances[runtime.type].object = instance;
    }

    await user.load();
    //CUSTOM_STATIC_GET_USER_END

    //GENERATED_STATIC_GET_USER_AFTER_START
    //GENERATED_STATIC_GET_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_USER_AFTER_END

  }

  /**
   * InviteUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async InviteUser(event) {

    //GENERATED_STATIC_INVITE_USER_START
    //CUSTOM_USER_MODE_INVITE_USER_INSTANCE_HANDLER_START
    let {object} = event;
    
    let {options} = event;
    let {user, group} = options;

    for (let runtime of Authentication.Runtimes) {
      let valid = runtime.validate('invite', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method invite`);
      }
    }

    for (let runtime of Authentication.Runtimes) {
      let instance = await runtime.invite(object, user, group);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_INVITE_USER_INSTANCE_HANDLER_END
    //GENERATED_STATIC_INVITE_USER_END

    //CUSTOM_STATIC_INVITE_USER_START
    //CUSTOM_STATIC_INVITE_USER_END

    //GENERATED_STATIC_INVITE_USER_AFTER_START
    //GENERATED_STATIC_INVITE_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_INVITE_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_INVITE_USER_AFTER_END

  }

  /**
   * LoginUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async LoginUser(event) {

    //GENERATED_STATIC_LOGIN_USER_START
    //CUSTOM_USER_MODE_LOGIN_USER_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_LOGIN_USER_INSTANCE_HANDLER_END
    //GENERATED_STATIC_LOGIN_USER_END

    //CUSTOM_STATIC_LOGIN_USER_START
    let {object: user} = event;

    for (let runtime of Authentication.Runtimes) {
      let valid = runtime.validate('login', user);
      if (!valid) {
        throw new Error(`${user.uniqueName} failed to validate for method login`);
      }
    }

    for (let runtime of Authentication.Runtimes) {
      let instance = await runtime.login(user);
      user.setInstance(runtime, instance);
      user.updateFromInstance(runtime);
      // if (DEBUG_MODE === 'true') {
      //   process.env.AUTH_TOKEN = instance.token;
      // }
    }

    /**
     * Load the user from storage to perform any additional tasks like
     * creating references and Project objects
     */
    await user.load();
    //CUSTOM_STATIC_LOGIN_USER_END

    //GENERATED_STATIC_LOGIN_USER_AFTER_START
    //GENERATED_STATIC_LOGIN_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_LOGIN_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_LOGIN_USER_AFTER_END

  }

  /**
   * LogoutUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async LogoutUser(event) {

    //GENERATED_STATIC_LOGOUT_USER_START
    //CUSTOM_USER_MODE_LOGOUT_USER_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_LOGOUT_USER_INSTANCE_HANDLER_END
    //GENERATED_STATIC_LOGOUT_USER_END

    //CUSTOM_STATIC_LOGOUT_USER_START
    let {object: user} = event;

    for (let runtime of Authentication.Runtimes) {
      let valid = runtime.validate('logout', user);
      if (!valid) {
        throw new Error(`${user.uniqueName} failed to validate for method logout`);
      }
    }

    for (let runtime of Authentication.Runtimes) {
      let instance = await runtime.logout(user);
      user.instances[runtime.type].object = instance;
    }

    if (user.group) {
      user.group.dispose();
    } else {
      user.dispose();
    }
    //CUSTOM_STATIC_LOGOUT_USER_END

    //GENERATED_STATIC_LOGOUT_USER_AFTER_START
    //GENERATED_STATIC_LOGOUT_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_LOGOUT_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_LOGOUT_USER_AFTER_END

  }

  /**
   * PasswordResetUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async PasswordResetUser(event) {

    //GENERATED_STATIC_PASSWORD_RESET_USER_START
    //CUSTOM_USER_MODE_PASSWORD_RESET_USER_INSTANCE_HANDLER_START
    let {object} = event;
    
    for (let runtime of Authentication.Runtimes) {
      let valid = runtime.validate('passwordReset', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method passwordReset`);
      }
    }

    for (let runtime of Authentication.Runtimes) {
      let instance = await runtime.passwordReset(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_PASSWORD_RESET_USER_INSTANCE_HANDLER_END
    //GENERATED_STATIC_PASSWORD_RESET_USER_END

    //CUSTOM_STATIC_PASSWORD_RESET_USER_START
    //CUSTOM_STATIC_PASSWORD_RESET_USER_END

    //GENERATED_STATIC_PASSWORD_RESET_USER_AFTER_START
    //GENERATED_STATIC_PASSWORD_RESET_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_PASSWORD_RESET_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_PASSWORD_RESET_USER_AFTER_END

  }

  /**
   * RegisterUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async RegisterUser(event) {

    //GENERATED_STATIC_REGISTER_USER_START
    //CUSTOM_USER_MODE_REGISTER_USER_INSTANCE_HANDLER_START
    let {object} = event;
    
    for (let runtime of Authentication.Runtimes) {
      let valid = runtime.validate('register', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method register`);
      }
    }

    for (let runtime of Authentication.Runtimes) {
      let instance = await runtime.register(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_REGISTER_USER_INSTANCE_HANDLER_END
    //GENERATED_STATIC_REGISTER_USER_END

    //CUSTOM_STATIC_REGISTER_USER_START
    //CUSTOM_STATIC_REGISTER_USER_END

    //GENERATED_STATIC_REGISTER_USER_AFTER_START
    //GENERATED_STATIC_REGISTER_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_REGISTER_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_REGISTER_USER_AFTER_END

  }
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  /**
   * GetRuntimeAuthenticationAxios()
   * - Gets the Axios runtime implementation of R3RuntimeAuthentication
   * @param {Object} event
   * - No returns
   */
  static GetRuntimeAuthenticationAxios(event) {

    //GENERATED_STATIC_GET_RUNTIME_AUTHENTICATION_AXIOS_START
    event.results = [Authentication.RuntimeAuthenticationAxios];
    //GENERATED_STATIC_GET_RUNTIME_AUTHENTICATION_AXIOS_END

    //CUSTOM_STATIC_GET_RUNTIME_AUTHENTICATION_AXIOS_START
    //CUSTOM_STATIC_GET_RUNTIME_AUTHENTICATION_AXIOS_END

    //GENERATED_STATIC_GET_RUNTIME_AUTHENTICATION_AXIOS_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_AUTHENTICATION_AXIOS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_AUTHENTICATION_AXIOS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_AUTHENTICATION_AXIOS_AFTER_END

  }
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
/**
 * @param Authentication.RuntimeAuthenticationAxios
 * - Runtime implementation of R3RuntimeAuthentication
 */
Authentication.RuntimeAuthenticationAxios = new AxiosRuntime();
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Authentication.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Authentication.IsStarting = false;

/**
 * @param Authentication.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Authentication.IsStopping = false;

/**
 * @param Authentication.Started
 * - Indicates whether this system is running
 */
Authentication.Started = false;

/**
 * @param Authentication.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Authentication.Subscriptions = {};

/**
 * @param Authentication.Runtimes
 * - A set of runtimes which the system manages
 */
Authentication.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Authentication as default};
