//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Runtime from '../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Component from '../Component.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  project=this.getCurrentProject() - All base components have a project or user or both
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeGraphicsThree" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "project",
            "type" : "R3EventObjProject"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
  getCurrentScene() - Returns the last created Scene object
  emitInitializeEvent(options) - Emits a component specific initialize event
  dispose() - Emits a component specific dispose event
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Graphics extends Component {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphics';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Graphics';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param project
     * - All base components have a project or user or both
     */
    if (typeof options.project === 'undefined') {
      options.project = this.getCurrentProject();
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_GRAPHICS_THREE)) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_GRAPHICS_THREE]['requirements']) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "project",
                "Constructor" : this.getClassConstructor("R3EventObjProject")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "project",
        "Constructor" : this.getClassConstructor("R3EventObjProject"),
        "type": "R3EventObjProject"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * getCurrentScene()
   * - Returns the last created Scene object
   * - No parameters
   * - No returns
   */
  getCurrentScene() {

    //CUSTOM_GET_CURRENT_SCENE_BEFORE_START
    //CUSTOM_GET_CURRENT_SCENE_BEFORE_END

    //GENERATED_GET_CURRENT_SCENE_BEFORE_START
    //GENERATED_GET_CURRENT_SCENE_BEFORE_END

    //CUSTOM_GET_CURRENT_SCENE_BEFORE_GENERATED_START
    //CUSTOM_GET_CURRENT_SCENE_BEFORE_GENERATED_END

    //GENERATED_GET_CURRENT_SCENE_START
    //GENERATED_GET_CURRENT_SCENE_END

    //CUSTOM_GET_CURRENT_SCENE_START
    let payload = {};
    Event.Emit(
      Event.GET_CURRENT_SCENE,
      payload
    )
    return payload.results[0];
    //CUSTOM_GET_CURRENT_SCENE_END

    //GENERATED_GET_CURRENT_SCENE_AFTER_START
    //GENERATED_GET_CURRENT_SCENE_AFTER_END

  }

  /**
   * emitInitializeEvent()
   * - Emits a component specific initialize event
   * @param options
   * - No returns
   */
  emitInitializeEvent(options) {

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_END

    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_START
    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_END

    //GENERATED_EMIT_INITIALIZE_EVENT_START
    //GENERATED_EMIT_INITIALIZE_EVENT_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_START
    Event.Emit(
      Event.INITIALIZE_GRAPHICS_COMPONENT,
      {
        options,
        object : this
      }
    );
    //CUSTOM_EMIT_INITIALIZE_EVENT_END

    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_START
    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_END

  }

  /**
   * dispose()
   * - Emits a component specific dispose event
   * - No parameters
   * - No returns
   */
  dispose() {

    //CUSTOM_DISPOSE_BEFORE_START
    //CUSTOM_DISPOSE_BEFORE_END

    //GENERATED_DISPOSE_BEFORE_START
    //GENERATED_DISPOSE_BEFORE_END

    //CUSTOM_DISPOSE_BEFORE_GENERATED_START
    //CUSTOM_DISPOSE_BEFORE_GENERATED_END

    //GENERATED_DISPOSE_START
    let payload = {
      object : this
    };

    Event.Emit(
      Event.DISPOSE_GRAPHICS_COMPONENT,
      payload
    );
    //GENERATED_DISPOSE_END

    //CUSTOM_DISPOSE_START
    Event.Emit(
      Event.DISPOSE_COMPONENT,
      payload
    );
    //CUSTOM_DISPOSE_END

    //GENERATED_DISPOSE_AFTER_START
    //GENERATED_DISPOSE_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Graphics.Type = 'R3EventObjComponentGraphics';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Graphics as default};
