//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Runtime from '../Runtime.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  createInstance(object) - Creates a graphics runtime instance for this runtime based on the R3 Object
  deleteInstance(object) - Deletes a graphics runtime instance for this runtime based on the R3 Object
  updateInstance(object, property, value) - Updates the graphics runtime instance of the R3 Object based on the options
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
  step(world, delta) - Step the world physics by delta
  applyForce(rigidBody, vector) - Applies force vector to the rigidBody
  applyTorque(rigidBody, vector) - Applies torque vector to the rigidBody
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createInstance(object) - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance(object) - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(object, property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Physics extends Runtime {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimePhysics';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimePhysics';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'PhysicsRuntime';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_PHYSICS;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * step()
   * - Step the world physics by delta
   * @param world
   * @param delta
   * - No returns
   */
  step(world, delta) {

    //CUSTOM_STEP_BEFORE_START
    //CUSTOM_STEP_BEFORE_END

    //GENERATED_STEP_BEFORE_START
    //GENERATED_STEP_BEFORE_END

    //CUSTOM_STEP_BEFORE_GENERATED_START
    //CUSTOM_STEP_BEFORE_GENERATED_END

    //GENERATED_STEP_START
    //GENERATED_STEP_END

    //CUSTOM_STEP_START
    //CUSTOM_STEP_END

    //GENERATED_STEP_AFTER_START
    //GENERATED_STEP_AFTER_END

  }

  /**
   * applyForce()
   * - Applies force vector to the rigidBody
   * @param rigidBody
   * @param vector
   * - No returns
   */
  applyForce(rigidBody, vector) {

    //CUSTOM_APPLY_FORCE_BEFORE_START
    //CUSTOM_APPLY_FORCE_BEFORE_END

    //GENERATED_APPLY_FORCE_BEFORE_START
    //GENERATED_APPLY_FORCE_BEFORE_END

    //CUSTOM_APPLY_FORCE_BEFORE_GENERATED_START
    //CUSTOM_APPLY_FORCE_BEFORE_GENERATED_END

    //GENERATED_APPLY_FORCE_START
    //GENERATED_APPLY_FORCE_END

    //CUSTOM_APPLY_FORCE_START
    //CUSTOM_APPLY_FORCE_END

    //GENERATED_APPLY_FORCE_AFTER_START
    //GENERATED_APPLY_FORCE_AFTER_END

  }

  /**
   * applyTorque()
   * - Applies torque vector to the rigidBody
   * @param rigidBody
   * @param vector
   * - No returns
   */
  applyTorque(rigidBody, vector) {

    //CUSTOM_APPLY_TORQUE_BEFORE_START
    //CUSTOM_APPLY_TORQUE_BEFORE_END

    //GENERATED_APPLY_TORQUE_BEFORE_START
    //GENERATED_APPLY_TORQUE_BEFORE_END

    //CUSTOM_APPLY_TORQUE_BEFORE_GENERATED_START
    //CUSTOM_APPLY_TORQUE_BEFORE_GENERATED_END

    //GENERATED_APPLY_TORQUE_START
    //GENERATED_APPLY_TORQUE_END

    //CUSTOM_APPLY_TORQUE_START
    //CUSTOM_APPLY_TORQUE_END

    //GENERATED_APPLY_TORQUE_AFTER_START
    //GENERATED_APPLY_TORQUE_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  createInstance(object) {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  deleteInstance(object) {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(object, property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    if (
      property === 'id' ||
      property === 'name' ||
      property === 'entityRef'
    ) {
      return true;
    }
    return false;
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Physics.Type = 'R3RuntimePhysics';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Physics as default};
