//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Runtime from '../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Maths from '../Maths.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  v0 = 0
  v1 = 0
  v2 = 0
  materialIndex = null
  uvs = []
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeMathsThree" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "v0",
            "type" : "Number"
          },
          {
            "property" : "v1",
            "type" : "Number"
          },
          {
            "property" : "v2",
            "type" : "Number"
          }
        ]
      }
    ],
    "R3RuntimeGraphicsThree" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "v0",
            "type" : "Number"
          },
          {
            "property" : "v1",
            "type" : "Number"
          },
          {
            "property" : "v2",
            "type" : "Number"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeMathsThree" :
    [
      {
        "methods" : ["createInstance"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "materialIndex",
            "type" : "Number"
          },
          {
            "property" : "uvs",
            "type" : ["R3EventObjComponentMathsUV"]
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  cloneInstance(propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance(_child = null, _parent = null) - Creates a math runtime instance for this runtime based on the R3 Object 
  deleteInstance(_child = null, _parent = null) - Deletes a math runtime instance for this runtime based on the R3 Object 
  updateInstance(property, value) - Updates the math runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Face extends Maths {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentMathsFace';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Face';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param v0
     * - No comment
     */
    if (typeof options.v0 === 'undefined') {
      options.v0 = 0;
    }

    /**
     * @param v1
     * - No comment
     */
    if (typeof options.v1 === 'undefined') {
      options.v1 = 0;
    }

    /**
     * @param v2
     * - No comment
     */
    if (typeof options.v2 === 'undefined') {
      options.v2 = 0;
    }

    /**
     * @param materialIndex
     * - No comment
     */
    if (typeof options.materialIndex === 'undefined') {
      options.materialIndex = null;
    }

    /**
     * @param uvs
     * - No comment
     */
    if (typeof options.uvs === 'undefined') {
      options.uvs = [];
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_MATHS_THREE)) {
      options.runtimes[Runtime.KEY_MATHS_THREE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_MATHS_THREE]['requirements']) {
      options.runtimes[Runtime.KEY_MATHS_THREE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_GRAPHICS_THREE)) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_GRAPHICS_THREE]['requirements']) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_MATHS_THREE].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "v0",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "v1",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "v2",
                "Constructor" : this.getClassConstructor("Number")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "v0",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "v1",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "v2",
                "Constructor" : this.getClassConstructor("Number")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_MATHS_THREE].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "materialIndex",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "uvs",
                "Constructor" : [this.getClassConstructor("R3EventObjComponentMathsUV")]
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_MATHS_THREE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "v0",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "v1",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "v2",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "materialIndex",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "uvs",
        "Constructor" : [this.getClassConstructor("R3EventObjComponentMathsUV")],
        "type": [
          "R3EventObjComponentMathsUV"
        ]
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * cloneInstance()
   * - Clones an instance, when propagate is true, also clones the instance children
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstance(propagate = true) {

    //CUSTOM_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BEFORE_END

    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CLONE_INSTANCE_START
    /**
     * Override Template
     */
    //GENERATED_CLONE_INSTANCE_END

    //CUSTOM_CLONE_INSTANCE_START
    //CUSTOM_CLONE_INSTANCE_END

    //GENERATED_CLONE_INSTANCE_AFTER_START
    //GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * createInstance()
   * - Creates a math runtime instance for this runtime based on the R3 Object
   * @param {Object|null} [_child=null]
   * @param {Object|null} [_parent=null]
   * - No returns
   */
  createInstance(_child = null, _parent = null) {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a math runtime instance for this runtime based on the R3 Object
   * @param {Object|null} [_child=null]
   * @param {Object|null} [_parent=null]
   * - No returns
   */
  deleteInstance(_child = null, _parent = null) {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the math runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Face.Type = 'R3EventObjComponentMathsFace';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Face as default};
