//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Runtime from '../Runtime.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  CurrentMode = null
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  registerControl(object) - Takes an input component and registers it on a DOM component to start listening for events
  deRegisterControl(object) - Takes an input component and removes the listener on its DOM component to stop listening for events
  updateControl(object, property, value) - Updates an input control (attaches to another DOM element, or sets a target, etc.)
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  deRegisterControl(object) - Takes an input component and removes the listener on its DOM component to stop listening for events 
  registerControl(object) - Takes an input component and registers it on a DOM component to start listening for events 
  updateControl(object, property, value) - Updates an input control (attaches to another DOM element, or sets a target, etc.) 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Input extends Runtime {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeInput';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeInput';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'InputRuntime';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_INPUT;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * deRegisterControl()
   * - Takes an input component and removes the listener on its DOM component to stop listening for events
   * @param object
   * - No returns
   */
  deRegisterControl(object) {

    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_END

    //GENERATED_DE_REGISTER_CONTROL_BEFORE_START
    //GENERATED_DE_REGISTER_CONTROL_BEFORE_END

    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_GENERATED_END

    //GENERATED_DE_REGISTER_CONTROL_START
    //GENERATED_DE_REGISTER_CONTROL_END

    //CUSTOM_DE_REGISTER_CONTROL_START
    //CUSTOM_DE_REGISTER_CONTROL_END

    //GENERATED_DE_REGISTER_CONTROL_AFTER_START
    //GENERATED_DE_REGISTER_CONTROL_AFTER_END

  }

  /**
   * registerControl()
   * - Takes an input component and registers it on a DOM component to start listening for events
   * @param object
   * - No returns
   */
  registerControl(object) {

    //CUSTOM_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_REGISTER_CONTROL_BEFORE_END

    //GENERATED_REGISTER_CONTROL_BEFORE_START
    //GENERATED_REGISTER_CONTROL_BEFORE_END

    //CUSTOM_REGISTER_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_REGISTER_CONTROL_BEFORE_GENERATED_END

    //GENERATED_REGISTER_CONTROL_START
    //GENERATED_REGISTER_CONTROL_END

    //CUSTOM_REGISTER_CONTROL_START
    //CUSTOM_REGISTER_CONTROL_END

    //GENERATED_REGISTER_CONTROL_AFTER_START
    //GENERATED_REGISTER_CONTROL_AFTER_END

  }

  /**
   * updateControl()
   * - Updates an input control (attaches to another DOM element, or sets a target, etc.)
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateControl(object, property, value) {

    //CUSTOM_UPDATE_CONTROL_BEFORE_START
    //CUSTOM_UPDATE_CONTROL_BEFORE_END

    //GENERATED_UPDATE_CONTROL_BEFORE_START
    //GENERATED_UPDATE_CONTROL_BEFORE_END

    //CUSTOM_UPDATE_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_CONTROL_BEFORE_GENERATED_END

    //GENERATED_UPDATE_CONTROL_START
    //GENERATED_UPDATE_CONTROL_END

    //CUSTOM_UPDATE_CONTROL_START
    if (
      property === 'id' ||
      property === 'name' ||
      property === 'entityRef' ||
      property === 'project'
    ) {
      return true;
    }
    return false;
    //CUSTOM_UPDATE_CONTROL_END

    //GENERATED_UPDATE_CONTROL_AFTER_START
    //GENERATED_UPDATE_CONTROL_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Input.Type = 'R3RuntimeInput';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Input.CurrentMode
 * - No comment
 */
Input.CurrentMode = null;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Input as default};
