//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Event from '../../Event.js';
import Obj from '../Obj.js';
import Runtime from '../../Runtime.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  project=this.getCurrentProject() - All base entities have a project
  readOnly = false - Entities can be set to read only
  active=false - Indicates whether this entity is active (subscribing to events) or not
  subscriptions=[] - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  components=[] - A list of components that this entity manages. Each component is accessible from this entity through its entityReference (entityRef) value i.e. if the component's entityReference is 'mesh1' then it is accessible in a code component via this.mesh1
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeEntityDefault" :
    [
      {
        "methods" : ["activate"],
        "properties" : [
          {
            "property" : "project",
            "type" : "R3EventObjProject"
          }
        ]
      }
    ],
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save", "load", "clone", "remove"],
        "properties" : [
          {
            "property" : "id",
            "type" : "String"
          }
        ]
      },
      {
        "methods" : ["save"],
        "properties" : [
          {
            "property" : "project",
            "type" : "R3EventObjProject"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
 {
   "R3RuntimeStorageAxios" :
   [
     {
       "methods" : ["save"],
       "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
       "properties" : [
         {
           "property" : "components",
           "type" : ["R3EventObjComponent"]
         }
       ]
     }
   ]
 }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
  dispose() - Removes the object from cache
  initialize(initOptions) - Assigns the object to its cache
  emitInitializeEvent(options) - Emits an entity specific initialize event
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  activate() - Starts an entity or code component by subscribing to all events of the code components 
  async clone() - Loads the R3 Object from Storage using apiUrl if specified, but during construction new IDs are assigned to all objects. When deep is true it also loads all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
  deActivate() - Stops an entity or code component by unsubscribing to all events of the code components 
  async load(children=false) - Loads the R3 Object from Storage. When children is true it also includes all children of this Object. Loads as current user or throws. 
  async remove() - Removes the R3 Object from Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
  async save() - Saves the R3 Object to Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Entity extends Obj {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjEntity';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Entity';
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param project
     * - All base entities have a project
     */
    if (typeof options.project === 'undefined') {
      options.project = this.getCurrentProject();
    }

    /**
     * @param readOnly
     * - Entities can be set to read only
     */
    if (typeof options.readOnly === 'undefined') {
      options.readOnly = false;
    }

    /**
     * @param active
     * - Indicates whether this entity is active (subscribing to events) or not
     */
    if (typeof options.active === 'undefined') {
      options.active = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = [];
    }

    /**
     * @param components
     * - A list of components that this entity manages. Each component is accessible from this entity through its
     *   entityReference (entityRef) value i.e. if the component's entityReference is 'mesh1' then it is accessible in a
     *   code component via this.mesh1
     */
    if (typeof options.components === 'undefined') {
      options.components = [];
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_ENTITY_DEFAULT)) {
      options.runtimes[Runtime.KEY_ENTITY_DEFAULT] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_ENTITY_DEFAULT]['requirements']) {
      options.runtimes[Runtime.KEY_ENTITY_DEFAULT].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_ENTITY_DEFAULT].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "activate"
            ],
            "properties": [
              {
                "property": "project",
                "Constructor" : this.getClassConstructor("R3EventObjProject")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "save",
              "load",
              "clone",
              "remove"
            ],
            "properties": [
              {
                "property": "id",
                "Constructor" : this.getClassConstructor("String")
              }
            ]
          },
          {
            "methods": [
              "save"
            ],
            "properties": [
              {
                "property": "project",
                "Constructor" : this.getClassConstructor("R3EventObjProject")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "components",
                "Constructor" : [this.getClassConstructor("R3EventObjComponent")]
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_ENTITY_DEFAULT,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }

    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_STORAGE_AXIOS,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "project",
        "Constructor" : this.getClassConstructor("R3EventObjProject"),
        "type": "R3EventObjProject"
      },
      {
        "property": "id",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String"
      },
      {
        "property": "components",
        "Constructor" : [this.getClassConstructor("R3EventObjComponent")],
        "type": [
          "R3EventObjComponent"
        ]
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Event.Emit(
      Event.ENTITY_CREATED,
      {
        object: this
      }
    );

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * dispose()
   * - Removes the object from cache
   * - No parameters
   * - No returns
   */
  dispose() {

    //CUSTOM_DISPOSE_BEFORE_START
    //CUSTOM_DISPOSE_BEFORE_END

    //GENERATED_DISPOSE_BEFORE_START
    //GENERATED_DISPOSE_BEFORE_END

    //CUSTOM_DISPOSE_BEFORE_GENERATED_START
    //CUSTOM_DISPOSE_BEFORE_GENERATED_END

    //GENERATED_DISPOSE_START
    let payload = {
      object : this
    };

    Event.Emit(
      Event.DISPOSE_ENTITY,
      payload
    );
    //GENERATED_DISPOSE_END

    //CUSTOM_DISPOSE_START
    //CUSTOM_DISPOSE_END

    //GENERATED_DISPOSE_AFTER_START
    //GENERATED_DISPOSE_AFTER_END

  }

  /**
   * initialize()
   * - Assigns the object to its cache
   * @param initOptions
   * - No returns
   */
  initialize(initOptions) {

    //CUSTOM_INITIALIZE_BEFORE_START
    //CUSTOM_INITIALIZE_BEFORE_END

    //GENERATED_INITIALIZE_BEFORE_START
    //GENERATED_INITIALIZE_BEFORE_END

    //CUSTOM_INITIALIZE_BEFORE_GENERATED_START
    //CUSTOM_INITIALIZE_BEFORE_GENERATED_END

    //GENERATED_INITIALIZE_START
    this.parents = initOptions.parents;
    this.children = initOptions.children;
    this.initialized = initOptions.initialized;
    this.instances = initOptions.instances;
    this.enableUpdate = initOptions.enableUpdate;
    
    delete initOptions.parents;
    delete initOptions.children;
    delete initOptions.initialized;
    delete initOptions.instances;
    delete initOptions.enableUpdate;
    delete initOptions.requirements;

    for (let [key, value] of Object.entries(initOptions.runtimes)) {
      if (value.runtime) {
        this.instances[key] = {};
        this.instances[key].object = null;
        this.instances[key].runtime = value.runtime;
      }
    }

    if (typeof this._cache === 'undefined') {
      this._cache = {};
    }

    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    for (let key of Object.keys(this.references)) {

      let reference = this.references[key];

      this._cache[key] = null;

      Object.defineProperty(
        this,
        key,
        {
          configurable : true,
          enumerable : true,
          set: this.setProperty(reference),
          get : this.getProperty(reference)
        }
      );

    }

    this.id = initOptions.id;
    delete initOptions.id;

    Object.assign(this, initOptions);

    let payload = {
      object : this,
      options : {initOptions}
    }

    Event.Emit(Event.INITIALIZE_ENTITY, payload);

    this.initialized = true;
    //GENERATED_INITIALIZE_END

    //CUSTOM_INITIALIZE_START
    //CUSTOM_INITIALIZE_END

    //GENERATED_INITIALIZE_AFTER_START
    //GENERATED_INITIALIZE_AFTER_END

  }

  /**
   * emitInitializeEvent()
   * - Emits an entity specific initialize event
   * @param options
   * - No returns
   */
  emitInitializeEvent(options) {

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_END

    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_START
    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_END

    //GENERATED_EMIT_INITIALIZE_EVENT_START
    //GENERATED_EMIT_INITIALIZE_EVENT_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_START
    /**
     * Already dispatched an Event.INITIALIZE_ENTITY
     */
    //CUSTOM_EMIT_INITIALIZE_EVENT_END

    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_START
    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * activate()
   * - Starts an entity or code component by subscribing to all events of the code components
   * - No parameters
   * - No returns
   */
  activate() {

    //CUSTOM_ACTIVATE_BEFORE_START
    //CUSTOM_ACTIVATE_BEFORE_END

    //GENERATED_ACTIVATE_BEFORE_START
    //GENERATED_ACTIVATE_BEFORE_END

    //CUSTOM_ACTIVATE_BEFORE_GENERATED_START
    //CUSTOM_ACTIVATE_BEFORE_GENERATED_END

    //GENERATED_ACTIVATE_START
    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.ACTIVATE_ENTITY,
      payload
    );
    //GENERATED_ACTIVATE_END

    //CUSTOM_ACTIVATE_START
    //CUSTOM_ACTIVATE_END

    //GENERATED_ACTIVATE_AFTER_START
    //GENERATED_ACTIVATE_AFTER_END

  }

  /**
   * clone()
   * - Loads the R3 Object from Storage using apiUrl if specified, but during construction new IDs are assigned to all
   *   objects. When deep is true it also loads all children. When user is not specified it will attempt to discover
   *   the current user, or specify a user explicitly to perform this operation as that user.
   * - No parameters
   * - No returns
   */
  async clone() {

    //CUSTOM_CLONE_BEFORE_START
    //CUSTOM_CLONE_BEFORE_END

    //GENERATED_CLONE_BEFORE_START
    //GENERATED_CLONE_BEFORE_END

    //CUSTOM_CLONE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_BEFORE_GENERATED_END

    //GENERATED_CLONE_START
    let payload = {
      object : this
    };
    
    await Event.Serialize(
      Event.CLONE_ENTITY,
      payload
    );

    await Event.Serialize(
      Event.CLONE_ENTITY_AFTER,
      payload
    );
    //GENERATED_CLONE_END

    //CUSTOM_CLONE_START
    //CUSTOM_CLONE_END

    //GENERATED_CLONE_AFTER_START
    //GENERATED_CLONE_AFTER_END

  }

  /**
   * deActivate()
   * - Stops an entity or code component by unsubscribing to all events of the code components
   * - No parameters
   * - No returns
   */
  deActivate() {

    //CUSTOM_DE_ACTIVATE_BEFORE_START
    //CUSTOM_DE_ACTIVATE_BEFORE_END

    //GENERATED_DE_ACTIVATE_BEFORE_START
    //GENERATED_DE_ACTIVATE_BEFORE_END

    //CUSTOM_DE_ACTIVATE_BEFORE_GENERATED_START
    //CUSTOM_DE_ACTIVATE_BEFORE_GENERATED_END

    //GENERATED_DE_ACTIVATE_START
    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.DE_ACTIVATE_ENTITY,
      payload
    );
    //GENERATED_DE_ACTIVATE_END

    //CUSTOM_DE_ACTIVATE_START
    //CUSTOM_DE_ACTIVATE_END

    //GENERATED_DE_ACTIVATE_AFTER_START
    //GENERATED_DE_ACTIVATE_AFTER_END

  }

  /**
   * load()
   * - Loads the R3 Object from Storage. When children is true it also includes all children of this Object. Loads as
   *   current user or throws.
   * @param {Boolean} [children=false]
   * - No returns
   */
  async load(children=false) {

    //CUSTOM_LOAD_BEFORE_START
    //CUSTOM_LOAD_BEFORE_END

    //GENERATED_LOAD_BEFORE_START
    if (this.state === Obj.STATE_REMOVED) {
      throw new Error(`The object ${this.uniqueName} has been removed and cannot be loaded`);
    }

    if (this.state === Obj.STATE_LOADING) {
      return;
    }

    if (
      this.state !== Obj.STATE_ERRORED &&
      this.state !== Obj.STATE_LOADED &&
      this.state !== Obj.STATE_UPDATED &&
      this.state !== Obj.STATE_SAVED &&
      this.state !== Obj.STATE_SAVING &&
      this.state !== Obj.STATE_REMOVING &&
      this.state !== Obj.STATE_UPDATING &&
      this.state !== Obj.STATE_INITIALIZED
    ) {
      throw new Error(`The object ${this.uniqueName} is not in a state for loading : ${this.getStateName()}`);
    }

    this.state = Obj.STATE_LOADING;

    try {
    //GENERATED_LOAD_BEFORE_END

    //CUSTOM_LOAD_BEFORE_GENERATED_START
    //CUSTOM_LOAD_BEFORE_GENERATED_END

    //GENERATED_LOAD_START
    let payload = {
      object : this
    };
    
    payload.options = {};
    payload.options.children = children;

    await Event.Serialize(
      Event.LOAD_ENTITY,
      payload
    );

    await Event.Serialize(
      Event.LOAD_ENTITY_AFTER,
      payload
    );
    //GENERATED_LOAD_END

    //CUSTOM_LOAD_START
    //CUSTOM_LOAD_END

    //GENERATED_LOAD_AFTER_START
    this.state = Obj.STATE_LOADED;
    this.loaded = true;

    } catch (error) {
      this.state = Obj.STATE_ERRORED;
      throw error;
    } finally {
      this.resolveStateChanged();
    }
    //GENERATED_LOAD_AFTER_END

  }

  /**
   * remove()
   * - Removes the R3 Object from Storage using apiUrl if specified, when deep is true it includes all children. When
   *   user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this
   *   operation as that user.
   * - No parameters
   * - No returns
   */
  async remove() {

    //CUSTOM_REMOVE_BEFORE_START
    //CUSTOM_REMOVE_BEFORE_END

    //GENERATED_REMOVE_BEFORE_START
    if (
      this.state === Obj.STATE_REMOVED
    ) {
      return;
    }

    if (this.state === Obj.STATE_REMOVING) {
      await this.stateChangedPromise;
      if (this.state === Obj.STATE_REMOVED) {
        return;
      }
    }

    if (
      this.state === Obj.STATE_LOADING ||
      this.state === Obj.STATE_SAVING ||
      this.state === Obj.STATE_UPDATING
    ) {
      await this.stateChangedPromise;
    }

    if (
      this.state !== Obj.STATE_ERRORED &&
      this.state !== Obj.STATE_UPDATED &&
      this.state !== Obj.STATE_LOADED &&
      this.state !== Obj.STATE_SAVED &&
      this.state !== Obj.STATE_INITIALIZED
    ) {
      throw new Error(`The object ${this.uniqueName} (${this.id}) is not in a state for removing : ${this.getStateName()}`);
    }

    this.state = Obj.STATE_REMOVING;

    try {
    //GENERATED_REMOVE_BEFORE_END

    //CUSTOM_REMOVE_BEFORE_GENERATED_START
    //CUSTOM_REMOVE_BEFORE_GENERATED_END

    //GENERATED_REMOVE_START
    let payload = {
      object : this
    };
    
    await Event.Serialize(
      Event.REMOVE_ENTITY,
      payload
    );

    await Event.Serialize(
      Event.REMOVE_ENTITY_AFTER,
      payload
    );
    //GENERATED_REMOVE_END

    //CUSTOM_REMOVE_START
    //CUSTOM_REMOVE_END

    //GENERATED_REMOVE_AFTER_START
    this.state = Obj.STATE_REMOVED;
    this.exists = false;

    } catch (error) {
      this.state = Obj.STATE_ERRORED;
      throw error;
    } finally {
      this.resolveStateChanged();
    }
    //GENERATED_REMOVE_AFTER_END

  }

  /**
   * save()
   * - Saves the R3 Object to Storage using apiUrl if specified, when deep is true it includes all children. When user
   *   is not specified it will attempt to discover the current user, or specify a user explicitly to perform this
   *   operation as that user.
   * - No parameters
   * - No returns
   */
  async save() {

    //CUSTOM_SAVE_BEFORE_START
    //CUSTOM_SAVE_BEFORE_END

    //GENERATED_SAVE_BEFORE_START
    if (this.state === Obj.STATE_SAVING) {
      return;
    }

    if (
      this.state === Obj.STATE_LOADING ||
      this.state === Obj.STATE_REMOVING ||
      this.state === Obj.STATE_UPDATING
    ) {
      await this.stateChangedPromise;
    }

    if (
      this.state !== Obj.STATE_SAVED &&
      this.state !== Obj.STATE_ERRORED &&
      this.state !== Obj.STATE_UPDATED &&
      this.state !== Obj.STATE_LOADED &&
      this.state !== Obj.STATE_REMOVED &&
      this.state !== Obj.STATE_INITIALIZED
    ) {
      throw new Error(`The object ${this.uniqueName} (${this.id}) is not in a state for saving: ${this.getStateName()}`);
    }

    this.state = Obj.STATE_SAVING

    try {
    //GENERATED_SAVE_BEFORE_END

    //CUSTOM_SAVE_BEFORE_GENERATED_START
    //CUSTOM_SAVE_BEFORE_GENERATED_END

    //GENERATED_SAVE_START
    let payload = {
      object : this
    };
    
    await Event.Serialize(
      Event.SAVE_ENTITY,
      payload
    );

    await Event.Serialize(
      Event.SAVE_ENTITY_AFTER,
      payload
    );
    //GENERATED_SAVE_END

    //CUSTOM_SAVE_START
    //CUSTOM_SAVE_END

    //GENERATED_SAVE_AFTER_START
    this.state = Obj.STATE_SAVED;
    this.exists = true;

    } catch (error) {
      this.state = Obj.STATE_ERRORED;
      throw error;
    } finally {
      this.resolveStateChanged();
    }
    //GENERATED_SAVE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Entity.Type = 'R3EventObjEntity';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Entity as default};
