//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
import * as physicsImport from './physics/index.js';
import * as mathsImport from './maths/index.js';
import * as inputImport from './input/index.js';
import * as graphicsImport from './graphics/index.js';
import * as audioImport from './audio/index.js';
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as Physics} from './Physics.js';
import {default as Maths} from './Maths.js';
import {default as Input} from './Input.js';
import {default as Graphics} from './Graphics.js';
import {default as Code} from './Code.js';
import {default as Audio} from './Audio.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
const World = physicsImport.World;
const RigidBody = physicsImport.RigidBody;
const Joint = physicsImport.Joint;
const Gravity = physicsImport.Gravity;
const Collider = physicsImport.Collider;
const Vertex = mathsImport.Vertex;
const Vector4 = mathsImport.Vector4;
const Vector3 = mathsImport.Vector3;
const Vector2 = mathsImport.Vector2;
const UV = mathsImport.UV;
const SphereMaths = mathsImport.Sphere;
const Ray = mathsImport.Ray;
const Quaternion = mathsImport.Quaternion;
const PlaneMaths = mathsImport.Plane;
const Matrix4 = mathsImport.Matrix4;
const Matrix3 = mathsImport.Matrix3;
const Face = mathsImport.Face;
const Color = mathsImport.Color;
const Touch = inputImport.Touch;
const OrbitControls = inputImport.OrbitControls;
const Mouse = inputImport.Mouse;
const Keyboard = inputImport.Keyboard;
const VideoGraphics = graphicsImport.Video;
const Texture = graphicsImport.Texture;
const Sprite = graphicsImport.Sprite;
const Skeleton = graphicsImport.Skeleton;
const Shadow = graphicsImport.Shadow;
const Shader = graphicsImport.Shader;
const Scene = graphicsImport.Scene;
const Renderer = graphicsImport.Renderer;
const Raycaster = graphicsImport.Raycaster;
const Mesh = graphicsImport.Mesh;
const MaterialGroup = graphicsImport.MaterialGroup;
const Material = graphicsImport.Material;
const Light = graphicsImport.Light;
const ImageGraphics = graphicsImport.Image;
const Geometry = graphicsImport.Geometry;
const Fog = graphicsImport.Fog;
const CanvasGraphics = graphicsImport.Canvas;
const Camera = graphicsImport.Camera;
const Positional = audioImport.Positional;
Physics.World = World;
Physics.RigidBody = RigidBody;
Physics.Joint = Joint;
Physics.Gravity = Gravity;
Physics.Collider = Collider;
Maths.Vertex = Vertex;
Maths.Vector4 = Vector4;
Maths.Vector3 = Vector3;
Maths.Vector2 = Vector2;
Maths.UV = UV;
Maths.Sphere = SphereMaths;
Maths.Ray = Ray;
Maths.Quaternion = Quaternion;
Maths.Plane = PlaneMaths;
Maths.Matrix4 = Matrix4;
Maths.Matrix3 = Matrix3;
Maths.Face = Face;
Maths.Color = Color;
Input.Touch = Touch;
Input.OrbitControls = OrbitControls;
Input.Mouse = Mouse;
Input.Keyboard = Keyboard;
Graphics.Video = VideoGraphics;
Graphics.Texture = Texture;
Graphics.Sprite = Sprite;
Graphics.Skeleton = Skeleton;
Graphics.Shadow = Shadow;
Graphics.Shader = Shader;
Graphics.Scene = Scene;
Graphics.Renderer = Renderer;
Graphics.Raycaster = Raycaster;
Graphics.Mesh = Mesh;
Graphics.MaterialGroup = MaterialGroup;
Graphics.Material = Material;
Graphics.Light = Light;
Graphics.Image = ImageGraphics;
Graphics.Geometry = Geometry;
Graphics.Fog = Fog;
Graphics.Canvas = CanvasGraphics;
Graphics.Camera = Camera;
Audio.Positional = Positional;
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
//CUSTOM_ASSIGN_TO_BASE_END

//GENERATED_EXPORTS_START
export {
  Physics,
  Maths,
  Input,
  Graphics,
  Code,
  Audio,
  World,
  RigidBody,
  Joint,
  Gravity,
  Collider,
  Vertex,
  Vector4,
  Vector3,
  Vector2,
  UV,
  SphereMaths,
  Ray,
  Quaternion,
  PlaneMaths,
  Matrix4,
  Matrix3,
  Face,
  Color,
  Touch,
  OrbitControls,
  Mouse,
  Keyboard,
  VideoGraphics,
  Texture,
  Sprite,
  Skeleton,
  Shadow,
  Shader,
  Scene,
  Renderer,
  Raycaster,
  Mesh,
  MaterialGroup,
  Material,
  Light,
  ImageGraphics,
  Geometry,
  Fog,
  CanvasGraphics,
  Camera,
  Positional,
//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
