//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Utils from '../../../Utils.js';
import Runtime from '../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Component from '../Component.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  event=Event.RENDER_BEFORE
  code=this.getDefaultCode(options)
  compiled=false
  compiledCode=this.compile(options) - Compiles the current code
  project=this.getCurrentProject() - All base components have a project
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeEntityDefault" :
    [
      {
        "methods" : ["activate"],
        "properties" : [
          {
            "property" : "event",
            "type" : "Number"
          },
          {
            "property" : "code",
            "type" : "String"
          },
          {
            "property" : "project",
            "type" : "R3EventObjProject"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "code",
            "type" : "String",
            "excluded" : true
          },
          {
            "property" : "event",
            "type" : "Number",
            "isEvent" : true
          },
          {
            "property" : "project",
            "type" : "R3EventObjProject"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
  compile(options) - Compiles the code instance
  getDefaultCode(options) - Gets the default code template
  emitInitializeEvent(options) - Emits a component specific initialize event
  dispose() - Emits a component specific dispose event
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createEditor() - Creates an editor for this code component 
  deleteEditor() - Removes the editor for this code component 
  updateEditor(property, value) - Updates the editor for this code component 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Code extends Component {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentCode';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Code';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param event
     * - No comment
     */
    if (typeof options.event === 'undefined') {
      options.event = Event.RENDER_BEFORE;
    }

    /**
     * @param code
     * - No comment
     */
    if (typeof options.code === 'undefined') {
      options.code = this.getDefaultCode(options);
    }

    /**
     * @param compiled
     * - No comment
     */
    if (typeof options.compiled === 'undefined') {
      options.compiled = false;
    }

    /**
     * @param compiledCode
     * - Compiles the current code
     */
    if (typeof options.compiledCode === 'undefined') {
      options.compiledCode = this.compile(options);
    }

    /**
     * @param project
     * - All base components have a project
     */
    if (typeof options.project === 'undefined') {
      options.project = this.getCurrentProject();
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_ENTITY_DEFAULT)) {
      options.runtimes[Runtime.KEY_ENTITY_DEFAULT] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_ENTITY_DEFAULT]['requirements']) {
      options.runtimes[Runtime.KEY_ENTITY_DEFAULT].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_ENTITY_DEFAULT].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "activate"
            ],
            "properties": [
              {
                "property": "event",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "code",
                "Constructor" : this.getClassConstructor("String")
              },
              {
                "property": "project",
                "Constructor" : this.getClassConstructor("R3EventObjProject")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "code",
                "Constructor" : this.getClassConstructor("String"),
                "excluded": true
              },
              {
                "property": "event",
                "Constructor" : this.getClassConstructor("Number"),
                "isEvent": true
              },
              {
                "property": "project",
                "Constructor" : this.getClassConstructor("R3EventObjProject")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_CODE_ACE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "event",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "isEvent": true
      },
      {
        "property": "code",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String",
        "excluded": true
      },
      {
        "property": "project",
        "Constructor" : this.getClassConstructor("R3EventObjProject"),
        "type": "R3EventObjProject"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * compile()
   * - Compiles the code instance
   * @param options
   * - No returns
   */
  compile(options) {

    //CUSTOM_COMPILE_BEFORE_START
    //CUSTOM_COMPILE_BEFORE_END

    //GENERATED_COMPILE_BEFORE_START
    //GENERATED_COMPILE_BEFORE_END

    //CUSTOM_COMPILE_BEFORE_GENERATED_START
    //CUSTOM_COMPILE_BEFORE_GENERATED_END

    //GENERATED_COMPILE_START
    //GENERATED_COMPILE_END

    //CUSTOM_COMPILE_START
    options.code = options.code.replaceAll('__EVENT_NAME__', Event.GetEventName(options.event).toLowerCase().replace('event.',''));
    return new Function('event', options.code);
    //CUSTOM_COMPILE_END

    //GENERATED_COMPILE_AFTER_START
    //GENERATED_COMPILE_AFTER_END

  }

  /**
   * getDefaultCode()
   * - Gets the default code template
   * @param options
   * - No returns
   */
  getDefaultCode(options) {

    //CUSTOM_GET_DEFAULT_CODE_BEFORE_START
    //CUSTOM_GET_DEFAULT_CODE_BEFORE_END

    //GENERATED_GET_DEFAULT_CODE_BEFORE_START
    //GENERATED_GET_DEFAULT_CODE_BEFORE_END

    //CUSTOM_GET_DEFAULT_CODE_BEFORE_GENERATED_START
    //CUSTOM_GET_DEFAULT_CODE_BEFORE_GENERATED_END

    //GENERATED_GET_DEFAULT_CODE_START
    //GENERATED_GET_DEFAULT_CODE_END

    //CUSTOM_GET_DEFAULT_CODE_START
    return `//Your event information lives in a variable called 'event'
this.test = () => {
  //---start here---
  return true;
};

this.execute = () => {
  //---this code executes when your test returns true---
};

return {
  test : this.test,
  execute : this.execute
};

//@ sourceURL=${Utils.LowerSnakeCase(options.name)}.js`;
    //CUSTOM_GET_DEFAULT_CODE_END

    //GENERATED_GET_DEFAULT_CODE_AFTER_START
    //GENERATED_GET_DEFAULT_CODE_AFTER_END

  }

  /**
   * emitInitializeEvent()
   * - Emits a component specific initialize event
   * @param options
   * - No returns
   */
  emitInitializeEvent(options) {

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_END

    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_START
    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_END

    //GENERATED_EMIT_INITIALIZE_EVENT_START
    //GENERATED_EMIT_INITIALIZE_EVENT_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_START
    Event.Emit(
      Event.INITIALIZE_CODE_COMPONENT,
      {
        options,
        object : this
      }
    );
    //CUSTOM_EMIT_INITIALIZE_EVENT_END

    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_START
    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_END

  }

  /**
   * dispose()
   * - Emits a component specific dispose event
   * - No parameters
   * - No returns
   */
  dispose() {

    //CUSTOM_DISPOSE_BEFORE_START
    //CUSTOM_DISPOSE_BEFORE_END

    //GENERATED_DISPOSE_BEFORE_START
    //GENERATED_DISPOSE_BEFORE_END

    //CUSTOM_DISPOSE_BEFORE_GENERATED_START
    //CUSTOM_DISPOSE_BEFORE_GENERATED_END

    //GENERATED_DISPOSE_START
    let payload = {
      object : this
    };

    Event.Emit(
      Event.DISPOSE_CODE_COMPONENT,
      payload
    );
    //GENERATED_DISPOSE_END

    //CUSTOM_DISPOSE_START
    Event.Emit(
      Event.DISPOSE_COMPONENT,
      payload
    );
    //CUSTOM_DISPOSE_END

    //GENERATED_DISPOSE_AFTER_START
    //GENERATED_DISPOSE_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createEditor()
   * - Creates an editor for this code component
   * - No parameters
   * - No returns
   */
  createEditor() {

    //CUSTOM_CREATE_EDITOR_BEFORE_START
    //CUSTOM_CREATE_EDITOR_BEFORE_END

    //GENERATED_CREATE_EDITOR_BEFORE_START
    //GENERATED_CREATE_EDITOR_BEFORE_END

    //CUSTOM_CREATE_EDITOR_BEFORE_GENERATED_START
    //CUSTOM_CREATE_EDITOR_BEFORE_GENERATED_END

    //GENERATED_CREATE_EDITOR_START
    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.CREATE_EDITOR_CODE,
      payload
    );
    //GENERATED_CREATE_EDITOR_END

    //CUSTOM_CREATE_EDITOR_START
    //CUSTOM_CREATE_EDITOR_END

    //GENERATED_CREATE_EDITOR_AFTER_START
    //GENERATED_CREATE_EDITOR_AFTER_END

  }

  /**
   * deleteEditor()
   * - Removes the editor for this code component
   * - No parameters
   * - No returns
   */
  deleteEditor() {

    //CUSTOM_DELETE_EDITOR_BEFORE_START
    //CUSTOM_DELETE_EDITOR_BEFORE_END

    //GENERATED_DELETE_EDITOR_BEFORE_START
    //GENERATED_DELETE_EDITOR_BEFORE_END

    //CUSTOM_DELETE_EDITOR_BEFORE_GENERATED_START
    //CUSTOM_DELETE_EDITOR_BEFORE_GENERATED_END

    //GENERATED_DELETE_EDITOR_START
    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.DELETE_EDITOR_CODE,
      payload
    );
    //GENERATED_DELETE_EDITOR_END

    //CUSTOM_DELETE_EDITOR_START
    //CUSTOM_DELETE_EDITOR_END

    //GENERATED_DELETE_EDITOR_AFTER_START
    //GENERATED_DELETE_EDITOR_AFTER_END

  }

  /**
   * updateEditor()
   * - Updates the editor for this code component
   * @param property
   * @param value
   * - No returns
   */
  updateEditor(property, value) {

    //CUSTOM_UPDATE_EDITOR_BEFORE_START
    //CUSTOM_UPDATE_EDITOR_BEFORE_END

    //GENERATED_UPDATE_EDITOR_BEFORE_START
    //GENERATED_UPDATE_EDITOR_BEFORE_END

    //CUSTOM_UPDATE_EDITOR_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_EDITOR_BEFORE_GENERATED_END

    //GENERATED_UPDATE_EDITOR_START
    let payload = {
      object : this
    };
    
    payload.options = {};
    payload.options.property = property;
    payload.options.value = value;

    Event.Emit(
      Event.UPDATE_EDITOR_CODE,
      payload
    );
    //GENERATED_UPDATE_EDITOR_END

    //CUSTOM_UPDATE_EDITOR_START
    //CUSTOM_UPDATE_EDITOR_END

    //GENERATED_UPDATE_EDITOR_AFTER_START
    //GENERATED_UPDATE_EDITOR_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Code.Type = 'R3EventObjComponentCode';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Code as default};
