//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Runtime from '../Runtime.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  async load(object, children=false) - Loads the R3 Object from Storage. When children is true it also includes all children of this Object. Loads as current user or throws.
  async clone(object) - Loads the R3 Object from Storage using apiUrl if specified, but during construction new IDs are assigned to all objects. When deep is true it also loads all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user.
  async save(object) - Saves the R3 Object to Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user.
  async remove(object) - Removes the R3 Object from Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user.
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
  async getUsersList(offset, count) - Returns a list of count users starting from offset
  async getList(type, offset, count, keywords = null) - Returns a list of count objects starting from offset
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  async clone(object) - Loads the R3 Object from Storage using apiUrl if specified, but during construction new IDs are assigned to all objects. When deep is true it also loads all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
  async load(object, children=false) - Loads the R3 Object from Storage. When children is true it also includes all children of this Object. Loads as current user or throws. 
  async remove(object) - Removes the R3 Object from Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
  async save(object) - Saves the R3 Object to Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Storage extends Runtime {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeStorage';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeStorage';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'StorageRuntime';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_STORAGE;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * getUsersList()
   * - Returns a list of count users starting from offset
   * @param offset
   * @param count
   * - No returns
   */
  async getUsersList(offset, count) {

    //CUSTOM_GET_USERS_LIST_BEFORE_START
    //CUSTOM_GET_USERS_LIST_BEFORE_END

    //GENERATED_GET_USERS_LIST_BEFORE_START
    //GENERATED_GET_USERS_LIST_BEFORE_END

    //CUSTOM_GET_USERS_LIST_BEFORE_GENERATED_START
    //CUSTOM_GET_USERS_LIST_BEFORE_GENERATED_END

    //GENERATED_GET_USERS_LIST_START
    //GENERATED_GET_USERS_LIST_END

    //CUSTOM_GET_USERS_LIST_START
    //CUSTOM_GET_USERS_LIST_END

    //GENERATED_GET_USERS_LIST_AFTER_START
    //GENERATED_GET_USERS_LIST_AFTER_END

  }

  /**
   * getList()
   * - Returns a list of count objects starting from offset
   * @param type
   * @param offset
   * @param count
   * @param {Object|null} [keywords=null]
   * - No returns
   */
  async getList(type, offset, count, keywords = null) {

    //CUSTOM_GET_LIST_BEFORE_START
    //CUSTOM_GET_LIST_BEFORE_END

    //GENERATED_GET_LIST_BEFORE_START
    //GENERATED_GET_LIST_BEFORE_END

    //CUSTOM_GET_LIST_BEFORE_GENERATED_START
    //CUSTOM_GET_LIST_BEFORE_GENERATED_END

    //GENERATED_GET_LIST_START
    //GENERATED_GET_LIST_END

    //CUSTOM_GET_LIST_START
    //CUSTOM_GET_LIST_END

    //GENERATED_GET_LIST_AFTER_START
    //GENERATED_GET_LIST_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * clone()
   * - Loads the R3 Object from Storage using apiUrl if specified, but during construction new IDs are assigned to all
   *   objects. When deep is true it also loads all children. When user is not specified it will attempt to discover
   *   the current user, or specify a user explicitly to perform this operation as that user.
   * @param object
   * - No returns
   */
  async clone(object) {

    //CUSTOM_CLONE_BEFORE_START
    //CUSTOM_CLONE_BEFORE_END

    //GENERATED_CLONE_BEFORE_START
    //GENERATED_CLONE_BEFORE_END

    //CUSTOM_CLONE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_BEFORE_GENERATED_END

    //GENERATED_CLONE_START
    //GENERATED_CLONE_END

    //CUSTOM_CLONE_START
    //CUSTOM_CLONE_END

    //GENERATED_CLONE_AFTER_START
    //GENERATED_CLONE_AFTER_END

  }

  /**
   * load()
   * - Loads the R3 Object from Storage. When children is true it also includes all children of this Object. Loads as
   *   current user or throws.
   * @param object
   * @param {Boolean} [children=false]
   * - No returns
   */
  async load(object, children=false) {

    //CUSTOM_LOAD_BEFORE_START
    //CUSTOM_LOAD_BEFORE_END

    //GENERATED_LOAD_BEFORE_START
    //GENERATED_LOAD_BEFORE_END

    //CUSTOM_LOAD_BEFORE_GENERATED_START
    //CUSTOM_LOAD_BEFORE_GENERATED_END

    //GENERATED_LOAD_START
    //GENERATED_LOAD_END

    //CUSTOM_LOAD_START
    //CUSTOM_LOAD_END

    //GENERATED_LOAD_AFTER_START
    //GENERATED_LOAD_AFTER_END

  }

  /**
   * remove()
   * - Removes the R3 Object from Storage using apiUrl if specified, when deep is true it includes all children. When
   *   user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this
   *   operation as that user.
   * @param object
   * - No returns
   */
  async remove(object) {

    //CUSTOM_REMOVE_BEFORE_START
    //CUSTOM_REMOVE_BEFORE_END

    //GENERATED_REMOVE_BEFORE_START
    //GENERATED_REMOVE_BEFORE_END

    //CUSTOM_REMOVE_BEFORE_GENERATED_START
    //CUSTOM_REMOVE_BEFORE_GENERATED_END

    //GENERATED_REMOVE_START
    //GENERATED_REMOVE_END

    //CUSTOM_REMOVE_START
    //CUSTOM_REMOVE_END

    //GENERATED_REMOVE_AFTER_START
    //GENERATED_REMOVE_AFTER_END

  }

  /**
   * save()
   * - Saves the R3 Object to Storage using apiUrl if specified, when deep is true it includes all children. When user
   *   is not specified it will attempt to discover the current user, or specify a user explicitly to perform this
   *   operation as that user.
   * @param object
   * - No returns
   */
  async save(object) {

    //CUSTOM_SAVE_BEFORE_START
    //CUSTOM_SAVE_BEFORE_END

    //GENERATED_SAVE_BEFORE_START
    //GENERATED_SAVE_BEFORE_END

    //CUSTOM_SAVE_BEFORE_GENERATED_START
    //CUSTOM_SAVE_BEFORE_GENERATED_END

    //GENERATED_SAVE_START
    //GENERATED_SAVE_END

    //CUSTOM_SAVE_START
    //CUSTOM_SAVE_END

    //GENERATED_SAVE_AFTER_START
    //GENERATED_SAVE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Storage.Type = 'R3RuntimeStorage';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Storage as default};
