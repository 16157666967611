//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//GENERATED_REQUIRED_IMPORTS_START
//GENERATED_REQUIRED_IMPORTS_END

//CUSTOM_IMPORTS_START
import Utils from '../../Utils.js';
import Runtime from '../../Runtime.js';
//CUSTOM_IMPORTS_END

import Obj from '../Obj.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_SUPER_OPTIONS_START
  username = 'unknown'
  id=Utils.EmailId(options.username)
 CUSTOM_BEFORE_SUPER_OPTIONS_END

 CUSTOM_OPTIONS_START
  avatar = null
  loginType = 'normal'
  password = null
  passwordRepeat = null
  role = null
  group = this.getCurrentGroup() - Retrieves the current group
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  ROLE_USER          = 0x1
  ROLE_ADMIN         = 0x2
  ROLE_GROUP_ADMIN   = 0x4
  ROLE_PROJECT_ADMIN = 0x8
  ROLE_STANDARD      = User.ROLE_PROJECT_ADMIN
  ROLE_BUSINESS      = User.ROLE_PROJECT_ADMIN | User.ROLE_GROUP_ADMIN
  ROLE_ROOT          = User.ROLE_USER | User.ROLE_ADMIN | User.ROLE_GROUP_ADMIN | User.ROLE_PROJECT_ADMIN
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeAuthenticationAxios" :
    [
      {
        "methods" : ["register"],
        "properties" : [
          {
            "property" : "username",
            "type" : "String"
          },
          {
            "property" : "password",
            "type" : "String"
          },
          {
            "property" : "passwordRepeat",
            "type" : "String"
          }
        ]
      },
      {
        "methods" : ["login"],
        "properties" : [
          {
            "property" : "loginType",
            "type" : "String",
            "possibleValues" : ["normal", "facebook", "github", "google"]
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "avatar",
            "type" : "String"
          },
          {
            "property" : "password",
            "type" : "String",
            "excluded" : true
          },
          {
            "property" : "passwordRepeat",
            "type" : "String",
            "excluded" : true
          },
          {
            "property" : "group",
            "type" : "R3EventObjGroup"
          },
          {
            "property" : "role",
            "type" : "Number",
            "excluded" : true
          }
        ]
      }
    ],
    "R3RuntimeAuthenticationAxios" :
    [
      {
        "methods" : ["login"],
        "mode" : "Runtime.OPTIONAL_MODE_AT_LEAST_ALL",
        "properties" : [
          {
            "property" : "username",
            "type" : "String"
          },
          {
            "property" : "password",
            "type" : "String"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
  dispose() - Removes the object from cache
  initialize(initOptions) - Assigns the object to its cache
  emitInitializeEvent(options) - Emits a component specific initialize event
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  async changeRole(role) - Changes a user account to Free, Standard or Business 
  async clone() - Loads the R3 Object from Storage using apiUrl if specified, but during construction new IDs are assigned to all objects. When deep is true it also loads all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
  async get() - Makes a request to the server to get the information about the user using the locally stored token. If the token is no longer valid, we will receive an error status code from the server. 
  async invite(user, group) - Invites a user via API URL to join a group 
  async load(children=false) - Loads the R3 Object from Storage. When children is true it also includes all children of this Object. Loads as current user or throws. 
  async login() - Logs in the object (typically a User) with a username and password 
  async logout() - Logs out the object (typically a User) 
  async passwordReset() - Sets the new password for a user 
  async register() - Registers in the object (typically a User) 
  async remove() - Removes the R3 Object from Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
  async save() - Saves the R3 Object to Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 **/

export class User extends Obj {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.initialized === 'undefined') {
      options.initialized = false;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Entity
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjUser';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'User';
    }

    //GENERATED_CUSTOM_BEFORE_SUPER_OPTIONS_START
    /**
     * @param username
     * - No comment
     */
    if (typeof options.username === 'undefined') {
      options.username = 'unknown';
    }

    /**
     * @param id
     * - No comment
     */
    if (typeof options.id === 'undefined') {
      options.id = Utils.EmailId(options.username);
    }
    //GENERATED_CUSTOM_BEFORE_SUPER_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //CUSTOM_BEFORE_OPTIONS_START
    this.emit(
      Event.SET_CURRENT_USER,
      {
        object : this
      }
    );
    //CUSTOM_BEFORE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param avatar
     * - No comment
     */
    if (typeof options.avatar === 'undefined') {
      options.avatar = null;
    }

    /**
     * @param loginType
     * - No comment
     */
    if (typeof options.loginType === 'undefined') {
      options.loginType = 'normal';
    }

    /**
     * @param password
     * - No comment
     */
    if (typeof options.password === 'undefined') {
      options.password = null;
    }

    /**
     * @param passwordRepeat
     * - No comment
     */
    if (typeof options.passwordRepeat === 'undefined') {
      options.passwordRepeat = null;
    }

    /**
     * @param role
     * - No comment
     */
    if (typeof options.role === 'undefined') {
      options.role = null;
    }

    /**
     * @param group
     * - Retrieves the current group
     */
    if (typeof options.group === 'undefined') {
      options.group = this.getCurrentGroup();
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_AUTHENTICATION_AXIOS)) {
      options.runtimes[Runtime.KEY_AUTHENTICATION_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_AUTHENTICATION_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_AUTHENTICATION_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_AUTHENTICATION_AXIOS].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "register"
            ],
            "properties": [
              {
                "property": "username",
                "Constructor" : this.getClassConstructor("String")
              },
              {
                "property": "password",
                "Constructor" : this.getClassConstructor("String")
              },
              {
                "property": "passwordRepeat",
                "Constructor" : this.getClassConstructor("String")
              }
            ]
          },
          {
            "methods": [
              "login"
            ],
            "properties": [
              {
                "property": "loginType",
                "Constructor" : this.getClassConstructor("String"),
                "possibleValues": [
                  "normal",
                  "facebook",
                  "github",
                  "google"
                ]
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "avatar",
                "Constructor" : this.getClassConstructor("String")
              },
              {
                "property": "password",
                "Constructor" : this.getClassConstructor("String"),
                "excluded": true
              },
              {
                "property": "passwordRepeat",
                "Constructor" : this.getClassConstructor("String"),
                "excluded": true
              },
              {
                "property": "group",
                "Constructor" : this.getClassConstructor("R3EventObjGroup")
              },
              {
                "property": "role",
                "Constructor" : this.getClassConstructor("Number"),
                "excluded": true
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_AUTHENTICATION_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "login"
            ],
            "mode": Runtime.OPTIONAL_MODE_AT_LEAST_ALL,
            "properties": [
              {
                "property": "username",
                "Constructor" : this.getClassConstructor("String")
              },
              {
                "property": "password",
                "Constructor" : this.getClassConstructor("String")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_AUTHENTICATION_AXIOS,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }

    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_STORAGE_AXIOS,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "username",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String"
      },
      {
        "property": "password",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String",
        "excluded": true
      },
      {
        "property": "passwordRepeat",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String",
        "excluded": true
      },
      {
        "property": "loginType",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String",
        "possibleValues": [
          "normal",
          "facebook",
          "github",
          "google"
        ]
      },
      {
        "property": "avatar",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String"
      },
      {
        "property": "group",
        "Constructor" : this.getClassConstructor("R3EventObjGroup"),
        "type": "R3EventObjGroup"
      },
      {
        "property": "role",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "excluded": true
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * dispose()
   * - Removes the object from cache
   * - No parameters
   * - No returns
   */
  dispose() {

    //CUSTOM_DISPOSE_BEFORE_START
    //CUSTOM_DISPOSE_BEFORE_END

    //GENERATED_DISPOSE_BEFORE_START
    //GENERATED_DISPOSE_BEFORE_END

    //CUSTOM_DISPOSE_BEFORE_GENERATED_START
    //CUSTOM_DISPOSE_BEFORE_GENERATED_END

    //GENERATED_DISPOSE_START
    let payload = {
      object : this
    };

    Event.Emit(
      Event.DISPOSE_USER,
      payload
    );
    //GENERATED_DISPOSE_END

    //CUSTOM_DISPOSE_START
    Event.Emit(
      Event.DISPOSE_OBJECT,
      payload
    );
    //CUSTOM_DISPOSE_END

    //GENERATED_DISPOSE_AFTER_START
    //GENERATED_DISPOSE_AFTER_END

  }

  /**
   * initialize()
   * - Assigns the object to its cache
   * @param initOptions
   * - No returns
   */
  initialize(initOptions) {

    //CUSTOM_INITIALIZE_BEFORE_START
    //CUSTOM_INITIALIZE_BEFORE_END

    //GENERATED_INITIALIZE_BEFORE_START
    //GENERATED_INITIALIZE_BEFORE_END

    //CUSTOM_INITIALIZE_BEFORE_GENERATED_START
    //CUSTOM_INITIALIZE_BEFORE_GENERATED_END

    //GENERATED_INITIALIZE_START
    this.parents = initOptions.parents;
    this.children = initOptions.children;
    this.initialized = initOptions.initialized;
    this.instances = initOptions.instances;
    this.enableUpdate = initOptions.enableUpdate;
    
    delete initOptions.parents;
    delete initOptions.children;
    delete initOptions.initialized;
    delete initOptions.instances;
    delete initOptions.enableUpdate;
    delete initOptions.requirements;

    for (let [key, value] of Object.entries(initOptions.runtimes)) {
      if (value.runtime) {
        this.instances[key] = {};
        this.instances[key].object = null;
        this.instances[key].runtime = value.runtime;
      }
    }

    if (typeof this._cache === 'undefined') {
      this._cache = {};
    }

    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    for (let key of Object.keys(this.references)) {

      let reference = this.references[key];

      this._cache[key] = null;

      Object.defineProperty(
        this,
        key,
        {
          configurable : true,
          enumerable : true,
          set: this.setProperty(reference),
          get : this.getProperty(reference)
        }
      );

    }

    this.id = initOptions.id;
    delete initOptions.id;

    Object.assign(this, initOptions);

    let payload = {
      object : this,
      options : {initOptions}
    }

    Event.Emit(Event.INITIALIZE_USER, payload);

    this.initialized = true;
    //GENERATED_INITIALIZE_END

    //CUSTOM_INITIALIZE_START
    //CUSTOM_INITIALIZE_END

    //GENERATED_INITIALIZE_AFTER_START
    //GENERATED_INITIALIZE_AFTER_END

  }

  /**
   * emitInitializeEvent()
   * - Emits a component specific initialize event
   * @param options
   * - No returns
   */
  emitInitializeEvent(options) {

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_END

    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_START
    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_END

    //GENERATED_EMIT_INITIALIZE_EVENT_START
    //GENERATED_EMIT_INITIALIZE_EVENT_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_START
    Event.Emit(
      Event.INITIALIZE_USER,
      {
        options,
        object : this
      }
    );
    //CUSTOM_EMIT_INITIALIZE_EVENT_END

    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_START
    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * changeRole()
   * - Changes a user account to Free, Standard or Business
   * @param role
   * - No returns
   */
  async changeRole(role) {

    //CUSTOM_CHANGE_ROLE_BEFORE_START
    //CUSTOM_CHANGE_ROLE_BEFORE_END

    //GENERATED_CHANGE_ROLE_BEFORE_START
    //GENERATED_CHANGE_ROLE_BEFORE_END

    //CUSTOM_CHANGE_ROLE_BEFORE_GENERATED_START
    //CUSTOM_CHANGE_ROLE_BEFORE_GENERATED_END

    //GENERATED_CHANGE_ROLE_START
    let payload = {
      object : this
    };
    
    payload.options = {};
    payload.options.role = role;

    await Event.Serialize(
      Event.CHANGE_ROLE_USER,
      payload
    );

    await Event.Serialize(
      Event.CHANGE_ROLE_USER_AFTER,
      payload
    );
    //GENERATED_CHANGE_ROLE_END

    //CUSTOM_CHANGE_ROLE_START
    //CUSTOM_CHANGE_ROLE_END

    //GENERATED_CHANGE_ROLE_AFTER_START
    //GENERATED_CHANGE_ROLE_AFTER_END

  }

  /**
   * clone()
   * - Loads the R3 Object from Storage using apiUrl if specified, but during construction new IDs are assigned to all
   *   objects. When deep is true it also loads all children. When user is not specified it will attempt to discover
   *   the current user, or specify a user explicitly to perform this operation as that user.
   * - No parameters
   * - No returns
   */
  async clone() {

    //CUSTOM_CLONE_BEFORE_START
    //CUSTOM_CLONE_BEFORE_END

    //GENERATED_CLONE_BEFORE_START
    //GENERATED_CLONE_BEFORE_END

    //CUSTOM_CLONE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_BEFORE_GENERATED_END

    //GENERATED_CLONE_START
    let payload = {
      object : this
    };
    
    await Event.Serialize(
      Event.CLONE_USER,
      payload
    );

    await Event.Serialize(
      Event.CLONE_USER_AFTER,
      payload
    );
    //GENERATED_CLONE_END

    //CUSTOM_CLONE_START
    //CUSTOM_CLONE_END

    //GENERATED_CLONE_AFTER_START
    //GENERATED_CLONE_AFTER_END

  }

  /**
   * get()
   * - Makes a request to the server to get the information about the user using the locally stored token. If the token
   *   is no longer valid, we will receive an error status code from the server.
   * - No parameters
   * - No returns
   */
  async get() {

    //CUSTOM_GET_BEFORE_START
    //CUSTOM_GET_BEFORE_END

    //GENERATED_GET_BEFORE_START
    //GENERATED_GET_BEFORE_END

    //CUSTOM_GET_BEFORE_GENERATED_START
    //CUSTOM_GET_BEFORE_GENERATED_END

    //GENERATED_GET_START
    let payload = {
      object : this
    };
    
    await Event.Serialize(
      Event.GET_USER,
      payload
    );

    await Event.Serialize(
      Event.GET_USER_AFTER,
      payload
    );
    //GENERATED_GET_END

    //CUSTOM_GET_START
    //CUSTOM_GET_END

    //GENERATED_GET_AFTER_START
    //GENERATED_GET_AFTER_END

  }

  /**
   * invite()
   * - Invites a user via API URL to join a group
   * @param user
   * @param group
   * - No returns
   */
  async invite(user, group) {

    //CUSTOM_INVITE_BEFORE_START
    //CUSTOM_INVITE_BEFORE_END

    //GENERATED_INVITE_BEFORE_START
    //GENERATED_INVITE_BEFORE_END

    //CUSTOM_INVITE_BEFORE_GENERATED_START
    //CUSTOM_INVITE_BEFORE_GENERATED_END

    //GENERATED_INVITE_START
    let payload = {
      object : this
    };
    
    payload.options = {};
    payload.options.user = user;
    payload.options.group = group;

    await Event.Serialize(
      Event.INVITE_USER,
      payload
    );

    await Event.Serialize(
      Event.INVITE_USER_AFTER,
      payload
    );
    //GENERATED_INVITE_END

    //CUSTOM_INVITE_START
    //CUSTOM_INVITE_END

    //GENERATED_INVITE_AFTER_START
    //GENERATED_INVITE_AFTER_END

  }

  /**
   * load()
   * - Loads the R3 Object from Storage. When children is true it also includes all children of this Object. Loads as
   *   current user or throws.
   * @param {Boolean} [children=false]
   * - No returns
   */
  async load(children=false) {

    //CUSTOM_LOAD_BEFORE_START
    //CUSTOM_LOAD_BEFORE_END

    //GENERATED_LOAD_BEFORE_START
    if (this.state === Obj.STATE_REMOVED) {
      throw new Error(`The object ${this.uniqueName} has been removed and cannot be loaded`);
    }

    if (this.state === Obj.STATE_LOADING) {
      return;
    }

    if (
      this.state !== Obj.STATE_ERRORED &&
      this.state !== Obj.STATE_LOADED &&
      this.state !== Obj.STATE_UPDATED &&
      this.state !== Obj.STATE_SAVED &&
      this.state !== Obj.STATE_SAVING &&
      this.state !== Obj.STATE_REMOVING &&
      this.state !== Obj.STATE_UPDATING &&
      this.state !== Obj.STATE_INITIALIZED
    ) {
      throw new Error(`The object ${this.uniqueName} is not in a state for loading : ${this.getStateName()}`);
    }

    this.state = Obj.STATE_LOADING;

    try {
    //GENERATED_LOAD_BEFORE_END

    //CUSTOM_LOAD_BEFORE_GENERATED_START
    //CUSTOM_LOAD_BEFORE_GENERATED_END

    //GENERATED_LOAD_START
    let payload = {
      object : this
    };
    
    payload.options = {};
    payload.options.children = children;

    await Event.Serialize(
      Event.LOAD_USER,
      payload
    );

    await Event.Serialize(
      Event.LOAD_USER_AFTER,
      payload
    );
    //GENERATED_LOAD_END

    //CUSTOM_LOAD_START
    //CUSTOM_LOAD_END

    //GENERATED_LOAD_AFTER_START
    this.state = Obj.STATE_LOADED;
    this.loaded = true;

    } catch (error) {
      this.state = Obj.STATE_ERRORED;
      throw error;
    } finally {
      this.resolveStateChanged();
    }
    //GENERATED_LOAD_AFTER_END

  }

  /**
   * login()
   * - Logs in the object (typically a User) with a username and password
   * - No parameters
   * - No returns
   */
  async login() {

    //CUSTOM_LOGIN_BEFORE_START
    //CUSTOM_LOGIN_BEFORE_END

    //GENERATED_LOGIN_BEFORE_START
    //GENERATED_LOGIN_BEFORE_END

    //CUSTOM_LOGIN_BEFORE_GENERATED_START
    //CUSTOM_LOGIN_BEFORE_GENERATED_END

    //GENERATED_LOGIN_START
    let payload = {
      object : this
    };
    
    await Event.Serialize(
      Event.LOGIN_USER,
      payload
    );

    await Event.Serialize(
      Event.LOGIN_USER_AFTER,
      payload
    );
    //GENERATED_LOGIN_END

    //CUSTOM_LOGIN_START
    //CUSTOM_LOGIN_END

    //GENERATED_LOGIN_AFTER_START
    //GENERATED_LOGIN_AFTER_END

  }

  /**
   * logout()
   * - Logs out the object (typically a User)
   * - No parameters
   * - No returns
   */
  async logout() {

    //CUSTOM_LOGOUT_BEFORE_START
    //CUSTOM_LOGOUT_BEFORE_END

    //GENERATED_LOGOUT_BEFORE_START
    //GENERATED_LOGOUT_BEFORE_END

    //CUSTOM_LOGOUT_BEFORE_GENERATED_START
    //CUSTOM_LOGOUT_BEFORE_GENERATED_END

    //GENERATED_LOGOUT_START
    let payload = {
      object : this
    };
    
    await Event.Serialize(
      Event.LOGOUT_USER,
      payload
    );

    await Event.Serialize(
      Event.LOGOUT_USER_AFTER,
      payload
    );
    //GENERATED_LOGOUT_END

    //CUSTOM_LOGOUT_START
    //CUSTOM_LOGOUT_END

    //GENERATED_LOGOUT_AFTER_START
    //GENERATED_LOGOUT_AFTER_END

  }

  /**
   * passwordReset()
   * - Sets the new password for a user
   * - No parameters
   * - No returns
   */
  async passwordReset() {

    //CUSTOM_PASSWORD_RESET_BEFORE_START
    //CUSTOM_PASSWORD_RESET_BEFORE_END

    //GENERATED_PASSWORD_RESET_BEFORE_START
    //GENERATED_PASSWORD_RESET_BEFORE_END

    //CUSTOM_PASSWORD_RESET_BEFORE_GENERATED_START
    //CUSTOM_PASSWORD_RESET_BEFORE_GENERATED_END

    //GENERATED_PASSWORD_RESET_START
    let payload = {
      object : this
    };
    
    await Event.Serialize(
      Event.PASSWORD_RESET_USER,
      payload
    );

    await Event.Serialize(
      Event.PASSWORD_RESET_USER_AFTER,
      payload
    );
    //GENERATED_PASSWORD_RESET_END

    //CUSTOM_PASSWORD_RESET_START
    //CUSTOM_PASSWORD_RESET_END

    //GENERATED_PASSWORD_RESET_AFTER_START
    //GENERATED_PASSWORD_RESET_AFTER_END

  }

  /**
   * register()
   * - Registers in the object (typically a User)
   * - No parameters
   * - No returns
   */
  async register() {

    //CUSTOM_REGISTER_BEFORE_START
    //CUSTOM_REGISTER_BEFORE_END

    //GENERATED_REGISTER_BEFORE_START
    //GENERATED_REGISTER_BEFORE_END

    //CUSTOM_REGISTER_BEFORE_GENERATED_START
    //CUSTOM_REGISTER_BEFORE_GENERATED_END

    //GENERATED_REGISTER_START
    let payload = {
      object : this
    };
    
    await Event.Serialize(
      Event.REGISTER_USER,
      payload
    );

    await Event.Serialize(
      Event.REGISTER_USER_AFTER,
      payload
    );
    //GENERATED_REGISTER_END

    //CUSTOM_REGISTER_START
    //CUSTOM_REGISTER_END

    //GENERATED_REGISTER_AFTER_START
    //GENERATED_REGISTER_AFTER_END

  }

  /**
   * remove()
   * - Removes the R3 Object from Storage using apiUrl if specified, when deep is true it includes all children. When
   *   user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this
   *   operation as that user.
   * - No parameters
   * - No returns
   */
  async remove() {

    //CUSTOM_REMOVE_BEFORE_START
    //CUSTOM_REMOVE_BEFORE_END

    //GENERATED_REMOVE_BEFORE_START
    if (
      this.state === Obj.STATE_REMOVED
    ) {
      return;
    }

    if (this.state === Obj.STATE_REMOVING) {
      await this.stateChangedPromise;
      if (this.state === Obj.STATE_REMOVED) {
        return;
      }
    }

    if (
      this.state === Obj.STATE_LOADING ||
      this.state === Obj.STATE_SAVING ||
      this.state === Obj.STATE_UPDATING
    ) {
      await this.stateChangedPromise;
    }

    if (
      this.state !== Obj.STATE_ERRORED &&
      this.state !== Obj.STATE_UPDATED &&
      this.state !== Obj.STATE_LOADED &&
      this.state !== Obj.STATE_SAVED &&
      this.state !== Obj.STATE_INITIALIZED
    ) {
      throw new Error(`The object ${this.uniqueName} (${this.id}) is not in a state for removing : ${this.getStateName()}`);
    }

    this.state = Obj.STATE_REMOVING;

    try {
    //GENERATED_REMOVE_BEFORE_END

    //CUSTOM_REMOVE_BEFORE_GENERATED_START
    //CUSTOM_REMOVE_BEFORE_GENERATED_END

    //GENERATED_REMOVE_START
    let payload = {
      object : this
    };
    
    await Event.Serialize(
      Event.REMOVE_USER,
      payload
    );

    await Event.Serialize(
      Event.REMOVE_USER_AFTER,
      payload
    );
    //GENERATED_REMOVE_END

    //CUSTOM_REMOVE_START
    //CUSTOM_REMOVE_END

    //GENERATED_REMOVE_AFTER_START
    this.state = Obj.STATE_REMOVED;
    this.exists = false;

    } catch (error) {
      this.state = Obj.STATE_ERRORED;
      throw error;
    } finally {
      this.resolveStateChanged();
    }
    //GENERATED_REMOVE_AFTER_END

  }

  /**
   * save()
   * - Saves the R3 Object to Storage using apiUrl if specified, when deep is true it includes all children. When user
   *   is not specified it will attempt to discover the current user, or specify a user explicitly to perform this
   *   operation as that user.
   * - No parameters
   * - No returns
   */
  async save() {

    //CUSTOM_SAVE_BEFORE_START
    //CUSTOM_SAVE_BEFORE_END

    //GENERATED_SAVE_BEFORE_START
    if (this.state === Obj.STATE_SAVING) {
      return;
    }

    if (
      this.state === Obj.STATE_LOADING ||
      this.state === Obj.STATE_REMOVING ||
      this.state === Obj.STATE_UPDATING
    ) {
      await this.stateChangedPromise;
    }

    if (
      this.state !== Obj.STATE_SAVED &&
      this.state !== Obj.STATE_ERRORED &&
      this.state !== Obj.STATE_UPDATED &&
      this.state !== Obj.STATE_LOADED &&
      this.state !== Obj.STATE_REMOVED &&
      this.state !== Obj.STATE_INITIALIZED
    ) {
      throw new Error(`The object ${this.uniqueName} (${this.id}) is not in a state for saving: ${this.getStateName()}`);
    }

    this.state = Obj.STATE_SAVING

    try {
    //GENERATED_SAVE_BEFORE_END

    //CUSTOM_SAVE_BEFORE_GENERATED_START
    //CUSTOM_SAVE_BEFORE_GENERATED_END

    //GENERATED_SAVE_START
    let payload = {
      object : this
    };
    
    await Event.Serialize(
      Event.SAVE_USER,
      payload
    );

    await Event.Serialize(
      Event.SAVE_USER_AFTER,
      payload
    );
    //GENERATED_SAVE_END

    //CUSTOM_SAVE_START
    //CUSTOM_SAVE_END

    //GENERATED_SAVE_AFTER_START
    this.state = Obj.STATE_SAVED;
    this.exists = true;

    } catch (error) {
      this.state = Obj.STATE_ERRORED;
      throw error;
    } finally {
      this.resolveStateChanged();
    }
    //GENERATED_SAVE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_EVENT_LISTENERS_START
  //GENERATED_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

User.Type = 'R3EventObjUser';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param User.ROLE_USER
 * - No comment
 */
User.ROLE_USER = 0x1;

/**
 * @param User.ROLE_ADMIN
 * - No comment
 */
User.ROLE_ADMIN = 0x2;

/**
 * @param User.ROLE_GROUP_ADMIN
 * - No comment
 */
User.ROLE_GROUP_ADMIN = 0x4;

/**
 * @param User.ROLE_PROJECT_ADMIN
 * - No comment
 */
User.ROLE_PROJECT_ADMIN = 0x8;

/**
 * @param User.ROLE_STANDARD
 * - No comment
 */
User.ROLE_STANDARD = User.ROLE_PROJECT_ADMIN;

/**
 * @param User.ROLE_BUSINESS
 * - No comment
 */
User.ROLE_BUSINESS = User.ROLE_PROJECT_ADMIN | User.ROLE_GROUP_ADMIN;

/**
 * @param User.ROLE_ROOT
 * - No comment
 */
User.ROLE_ROOT = User.ROLE_USER | User.ROLE_ADMIN | User.ROLE_GROUP_ADMIN | User.ROLE_PROJECT_ADMIN;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {User as default};
