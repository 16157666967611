//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import R3 from '../R3.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  ColliderDesc = {}
  RigidBodyDesc = {}
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class MockRapier extends R3 {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param MockRapier.ColliderDesc
 * - No comment
 */
MockRapier.ColliderDesc = {};

/**
 * @param MockRapier.RigidBodyDesc
 * - No comment
 */
MockRapier.RigidBodyDesc = {};
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START

/**
 * @property MockRapier.ColliderDesc
 * - No comment
 */
MockRapier.ColliderDesc.cuboid = function (hx, hy, hz) {
  console.log(`Mock Cuboid constructed with hx : ${hx}, hy : ${hy}, hz : ${hz}`);
  return {
    name : 'mock cuboid'
  }
};

/**
 * @property MockRapier.RigidBodyDesc
 * - No comment
 */
MockRapier.RigidBodyDesc.dynamic = function () {
  console.log(`Mock dynamic rigid body constructed`);
  return {
    name : 'mock dynamic rigid body'
  }
};


class World {
  constructor(gravity) {
    this.gravity = gravity;
    let {x, y, z} = gravity;
    console.log(`Constructed mock physics world with gravity : x : ${x}, y : ${y}, z : ${z}`);
  }

  step() {
    console.log(`Stepping physics world`);
  };

  createCollider(colliderDesc, rigidBody) {
    console.log(`Creating collider with colliderDesc : ${colliderDesc}, rigidBody : ${rigidBody}`);

    return {
      position : {x:0, y:0, z:0},
      handle : 'collider_handle',
      setTranslationWrtParent : function(vector) {
        let {x, y, z} = vector;
        console.log(`Setting collider translation with respect to parent : x = ${x}, y = ${y}, z = ${z}`);
      },
      setTranslation : function(vector) {
        let {x, y, z} = vector;
        console.log(`Setting collider translation : x = ${x}, y = ${y}, z = ${z}`);
        this.position.x = x;
        this.position.y = y;
        this.position.z = z;
      },
      setRestitution : function(number) {
        console.log(`Setting collider restitution to ${number}`);
      },
      setDensity : function(number) {
        console.log(`Setting collider density to ${number}`);
      },
      setFriction : function(number) {
        console.log(`Setting collider friction to ${number}`);
      },
      setSensor : function(boolean) {
        console.log(`Setting collider sensor to ${boolean}`);
      },
      setRotationWrtParent : function(vector) {
        let {w, x, y, z} = vector;
        console.log(`Setting collider rotation with respect to parent : w = ${w}, x = ${x}, y = ${y}, z = ${z}`);
      },
      setRotation : function(vector) {
        let {w, x, y, z} = vector;
        console.log(`Setting collider rotation : w = ${w}, x = ${x}, y = ${y}, z = ${z}`);
      },
      setHalfExtents : function(vector) {
        let {x, y, z} = vector;
        console.log(`Setting collider half extents : x = ${x}, y = ${y}, z = ${z}`);
      }
    }
  }

  removeCollider(handle) {
    console.log(`Removing collider with handle : ${handle}`);
  }

  createRigidBody(rigidBodyDesc) {
    console.log(`Creating rigid body with rigidBodyDesc : ${rigidBodyDesc}`);
    return {
      handle : 'rigid_body_handle',
      wakeUp : false,
      position : {x:0, y:0, z:0},
      quaternion : {w:1, x:0, y:0, z:0},
      linearVelocity : {x:0, y:0, z:0},
      angularVelocity : {x:0, y:0, z:0},
      setTranslation : function(vector, wakeUp) {
        let {x, y, z} = vector;
        this.position.x = x;
        this.position.y = y;
        this.position.z = z;
        this.wakeUp = wakeUp;
        console.log(`Setting rigid body translation : x = ${x}, y = ${y}, z = ${z} with wakeUp : ${wakeUp}`);
      },
      setRotation : function(vector) {
        let {w, x, y, z} = vector;
        this.quaternion.w = w;
        this.quaternion.x = x;
        this.quaternion.y = y;
        this.quaternion.z = z;
        console.log(`Setting rigid body quaternion : w = ${w}, x = ${x}, y = ${y}, z = ${z}`);
      },
      setGravityScale : function(number) {
        console.log(`Setting rigid body gravity scale to ${number}`);
      },
      lockRotations : function(boolean, wakeUp) {
        console.log(`Setting rigid body lock rotations to : ${boolean}, wakeUp = ${wakeUp}`);
      },
      lockTranslations : function(boolean, wakeUp) {
        console.log(`Setting rigid body lock translations to : ${boolean}, wakeUp = ${wakeUp}`);
      },
      setEnabledRotations : function(x, y, z, wakeUp) {
        console.log(`Setting rigid body enabled rotations to : x = ${x}, y = ${y}, z = ${z}, wakeUp : ${wakeUp}`);
      },
      setLinvel : function(vector) {
        let {x, y, z} = vector;
        this.quaternion.x = x;
        this.quaternion.y = y;
        this.quaternion.z = z;
        console.log(`Setting rigid body linear velocity to : x = ${x}, y = ${y}, z = ${z}`);
      },
      setAngvel : function(vector) {
        let {x, y, z} = vector;
        this.quaternion.x = x;
        this.quaternion.y = y;
        this.quaternion.z = z;
        console.log(`Setting rigid body angular velocity to : x = ${x}, y = ${y}, z = ${z}`);
      },
      setLinearDamping : function(number) {
        console.log(`Setting rigid body linear damping to ${number}`);
      },
      setAngularDamping : function(number) {
        console.log(`Setting rigid body angular damping to ${number}`);
      },
      setDominanceGroup : function(number) {
        console.log(`Setting rigid body dominance group to ${number}`);
      },
      enableCcd : function(boolean) {
        console.log(`Setting rigid body continuous collision detection to ${boolean}`);
      },
      translation : function() {
        this.position.y -= 1;
        console.log(`Returning rigid body position with updated y : ${this.position.y}`);
        return this.position;
      },
      linvel : function() {
        this.linearVelocity.x = 0.001;
        this.linearVelocity.y = -1;
        this.linearVelocity.z = 0.001;
        console.log(`Returning rigid body linear velocity with updated x, y, z : ${this.linearVelocity.x}, ${this.linearVelocity.y}, ${this.linearVelocity.z}`);
        return this.linearVelocity;
      },
      angvel : function() {
        this.angularVelocity.x = 0.001;
        this.angularVelocity.y = -1;
        this.angularVelocity.z = 0.001;
        console.log(`Returning rigid body angular velocity with updated x, y, z : ${this.angularVelocity.x}, ${this.angularVelocity.y}, ${this.angularVelocity.z}`);
        return this.angularVelocity;
      },
      rotation : function() {
        this.quaternion.x = 0.1;
        this.quaternion.y = 0.2;
        this.quaternion.z = 0.3;
        this.quaternion.w = 0.4;
        console.log(`Returning rigid body quaternion with updated w, x, y, z : ${this.quaternion.w}, ${this.quaternion.x}, ${this.quaternion.y}, ${this.quaternion.z}`);
        return this.quaternion;
      }
    }
  }

  removeRigidBody(handle) {
    console.log(`Removing rigid body with handle : ${handle}`);
  }

  free () {
    console.log(`Freeing physics world`);
  }
}
MockRapier.World = World;
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

//GENERATED_EXPORTS_START
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END

export {MockRapier as default};
