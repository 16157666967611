//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Color from '../../maths/Color.js';
import Runtime from '../../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Renderer from '../Renderer.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  scene=this.getCurrentScene() - The scene that this component can render
  camera=null - The current camera that is used to render the scene
  canvas=null - The canvas dom element to which this renderer renders
  useCanvasSize=true - Use the canvas size to determine the renderer size
  width=800 - The width of the renderer for 2D renderer
  height=600 - The height of the renderer for 2D renderer
  color=new Color({r:0, g:0, b:0, a:1}) - The clear color of the renderer
  outputColorSpace=Renderer.SRGBColorSpace - The output color space of the renderer. If a render target has been set using .setRenderTarget then renderTarget.texture.colorSpace will be used instead.
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeGraphicsThree" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "scene",
            "type" : "R3EventObjComponentGraphicsScene",
            "deep" : true
          },
          {
            "property" : "camera",
            "type" : "R3EventObjComponentGraphicsCamera",
            "deep" : true
          },
          {
            "property" : "canvas",
            "type" : "R3EventObjComponentGraphicsCanvas",
            "deep" : true
          },
          {
            "property" : "color",
            "type" : "R3EventObjComponentMathsColor",
            "deep" : true
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "scene",
            "type" : "R3EventObjComponentGraphicsScene"
          },
          {
            "property" : "camera",
            "type" : "R3EventObjComponentGraphicsCamera"
          },
          {
            "property" : "canvas",
            "type" : "R3EventObjComponentGraphicsCanvas"
          },
          {
            "property" : "color",
            "type" : "R3EventObjComponentMathsColor"
          },
          {
            "property" : "width",
            "type" : "Number",
            "min" : 0,
            "max" : 16384,
            "step" : 1
          },
          {
            "property" : "height",
            "type" : "Number",
            "min" : 0,
            "max" : 16384,
            "step" : 1
          },
          {
            "property" : "outputColorSpace",
            "type" : "String",
            "possibleValues" : [
              "Renderer.NoColorSpace",
              "Renderer.SRGBColorSpace",
              "Renderer.LinearSRGBColorSpace",
              "Renderer.DisplayP3ColorSpace",
              "Renderer.LinearDisplayP3ColorSpace"
            ],
            "possibleLabels" : [
              "No color space",
              "SRGB",
              "SRGB-Linear",
              "Display P3",
              "Linear Display P3"
            ]
          },
          {
            "property" : "useCanvasSize",
            "type" : "Boolean"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  cloneInstance(propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance() - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance() - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Gl extends Renderer {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphicsRendererGl';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Gl';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param scene
     * - The scene that this component can render
     */
    if (typeof options.scene === 'undefined') {
      options.scene = this.getCurrentScene();
    }

    /**
     * @param camera
     * - The current camera that is used to render the scene
     */
    if (typeof options.camera === 'undefined') {
      options.camera = null;
    }

    /**
     * @param canvas
     * - The canvas dom element to which this renderer renders
     */
    if (typeof options.canvas === 'undefined') {
      options.canvas = null;
    }

    /**
     * @param useCanvasSize
     * - Use the canvas size to determine the renderer size
     */
    if (typeof options.useCanvasSize === 'undefined') {
      options.useCanvasSize = true;
    }

    /**
     * @param width
     * - The width of the renderer for 2D renderer
     */
    if (typeof options.width === 'undefined') {
      options.width = 800;
    }

    /**
     * @param height
     * - The height of the renderer for 2D renderer
     */
    if (typeof options.height === 'undefined') {
      options.height = 600;
    }

    /**
     * @param color
     * - The clear color of the renderer
     */
    if (typeof options.color === 'undefined') {
      options.color = new Color({r:0, g:0, b:0, a:1});
    }

    /**
     * @param outputColorSpace
     * - The output color space of the renderer. If a render target has been set using .setRenderTarget then
     *   renderTarget.texture.colorSpace will be used instead.
     */
    if (typeof options.outputColorSpace === 'undefined') {
      options.outputColorSpace = Renderer.SRGBColorSpace;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_GRAPHICS_THREE)) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_GRAPHICS_THREE]['requirements']) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "scene",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsScene"),
                "deep": true
              },
              {
                "property": "camera",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCamera"),
                "deep": true
              },
              {
                "property": "canvas",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCanvas"),
                "deep": true
              },
              {
                "property": "color",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsColor"),
                "deep": true
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "scene",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsScene")
              },
              {
                "property": "camera",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCamera")
              },
              {
                "property": "canvas",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCanvas")
              },
              {
                "property": "color",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsColor")
              },
              {
                "property": "width",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 16384,
                "step": 1
              },
              {
                "property": "height",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 16384,
                "step": 1
              },
              {
                "property": "outputColorSpace",
                "Constructor" : this.getClassConstructor("String"),
                "possibleValues": [
                  Renderer.NoColorSpace,
                  Renderer.SRGBColorSpace,
                  Renderer.LinearSRGBColorSpace,
                  Renderer.DisplayP3ColorSpace,
                  Renderer.LinearDisplayP3ColorSpace
                ],
                "possibleLabels": [
                  "No color space",
                  "SRGB",
                  "SRGB-Linear",
                  "Display P3",
                  "Linear Display P3"
                ]
              },
              {
                "property": "useCanvasSize",
                "Constructor" : this.getClassConstructor("Boolean")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_GRAPHICS_THREE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "scene",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsScene"),
        "type": "R3EventObjComponentGraphicsScene"
      },
      {
        "property": "camera",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCamera"),
        "type": "R3EventObjComponentGraphicsCamera"
      },
      {
        "property": "canvas",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCanvas"),
        "type": "R3EventObjComponentGraphicsCanvas"
      },
      {
        "property": "color",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsColor"),
        "type": "R3EventObjComponentMathsColor"
      },
      {
        "property": "width",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 16384,
        "step": 1
      },
      {
        "property": "height",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 16384,
        "step": 1
      },
      {
        "property": "outputColorSpace",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String",
        "possibleValues": [
          Renderer.NoColorSpace,
          Renderer.SRGBColorSpace,
          Renderer.LinearSRGBColorSpace,
          Renderer.DisplayP3ColorSpace,
          Renderer.LinearDisplayP3ColorSpace
        ],
        "possibleLabels": [
          "No color space",
          "SRGB",
          "SRGB-Linear",
          "Display P3",
          "Linear Display P3"
        ]
      },
      {
        "property": "useCanvasSize",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    if (this.canvas) {
      this.width = this.canvas.width;
      this.height = this.canvas.height;
    }
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * cloneInstance()
   * - Clones an instance, when propagate is true, also clones the instance children
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstance(propagate = true) {

    //CUSTOM_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BEFORE_END

    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CLONE_INSTANCE_START
    /**
     * Override Template
     */
    //GENERATED_CLONE_INSTANCE_END

    //CUSTOM_CLONE_INSTANCE_START
    //CUSTOM_CLONE_INSTANCE_END

    //GENERATED_CLONE_INSTANCE_AFTER_START
    //GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  createInstance() {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  deleteInstance() {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Gl.Type = 'R3EventObjComponentGraphicsRendererGl';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Gl as default};
