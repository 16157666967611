//GENERATED_IMPORTS_START

//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START
import {default as SocketIORuntime} from '../runtime/websocket/SocketIO.js';
//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START
  RuntimeWebsocketSocketIO = new SocketIORuntime() - Runtime implementation of R3RuntimeWebsocket
 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  WebsocketUrl=null
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.SET_WEBSOCKET_URL(120)
  Event.GET_WEBSOCKET_URL(120)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START
  async Event.SEND_OBJ(110) [R3EventObj]
 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  Event.GET_RUNTIME_WEBSOCKET_SOCKET_I_O - Gets the SocketIO runtime implementation of R3RuntimeWebsocket
 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options = {}) - Starts the system by registering subscriptions to events
  stop(options = {}) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Websocket extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Websocket already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Websocket');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Websocket already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Websocket');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Websocket.IsStarting) {
      console.log('client Websocket system is already starting...');
      return;
    }

    Websocket.IsStarting = true;

    if (Websocket.Started === true) {
      Websocket.IsStarting = false;
      console.log('client Websocket system already started');
      return;
    }

    Websocket.Runtimes = new Set();
    Websocket.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Websocket.Subscriptions['SET_WEBSOCKET_URL'] = Event.On(
      Event.SET_WEBSOCKET_URL,
      Websocket.SetWebsocketUrl,
      {priority: 120}
    );

    /**
     * No comment
     */
    Websocket.Subscriptions['GET_WEBSOCKET_URL'] = Event.On(
      Event.GET_WEBSOCKET_URL,
      Websocket.GetWebsocketUrl,
      {priority: 120}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Websocket.Subscriptions['SEND_OBJ'] = Event.On(
      Event.SEND_OBJ,
      Websocket.SendObj,
      {priority: 110}
    );
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    /**
     * Gets the SocketIO runtime implementation of R3RuntimeWebsocket
     */
    Websocket.Subscriptions['GET_RUNTIME_WEBSOCKET_SOCKET_I_O'] = Event.On(
      Event.GET_RUNTIME_WEBSOCKET_SOCKET_I_O,
      Websocket.GetRuntimeWebsocketSocketIO
    );
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    Websocket.Started = true;
    Websocket.IsStarting = false;
    console.log('Started client system Websocket');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Websocket.IsStopping) {
      console.log('client Websocket system is already stopping');
      return;
    }

    Websocket.IsStopping = true;

    if (Websocket.Started === false) {
      Websocket.IsStopping = false;
      console.log('client Websocket system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    Websocket.Subscriptions['SET_WEBSOCKET_URL'].remove();
    delete Websocket.Subscriptions['SET_WEBSOCKET_URL'];

    Websocket.Subscriptions['GET_WEBSOCKET_URL'].remove();
    delete Websocket.Subscriptions['GET_WEBSOCKET_URL'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    Websocket.Subscriptions['SEND_OBJ'].remove();
    delete Websocket.Subscriptions['SEND_OBJ'];
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    Websocket.Subscriptions['GET_RUNTIME_WEBSOCKET_SOCKET_I_O'].remove();
    delete Websocket.Subscriptions['GET_RUNTIME_WEBSOCKET_SOCKET_I_O'];
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    Websocket.Runtimes.clear();
    Websocket.Started = false;
    Websocket.IsStopping = false;
    console.log('Stopped client system Websocket');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START
    Websocket.Runtimes.add(Websocket.RuntimeWebsocketSocketIO);
    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * SetWebsocketUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetWebsocketUrl(event) {

    //GENERATED_STATIC_SET_WEBSOCKET_URL_START
    //GENERATED_STATIC_SET_WEBSOCKET_URL_END

    //CUSTOM_STATIC_SET_WEBSOCKET_URL_START
    let {object : url} = event;
    Websocket.WebsocketUrl = url;
    console.log(`Websocket URL set to ${Websocket.WebsocketUrl}`);
    //CUSTOM_STATIC_SET_WEBSOCKET_URL_END

    //GENERATED_STATIC_SET_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_SET_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_WEBSOCKET_URL_AFTER_END

  }

  /**
   * GetWebsocketUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetWebsocketUrl(event) {

    //GENERATED_STATIC_GET_WEBSOCKET_URL_START
    //GENERATED_STATIC_GET_WEBSOCKET_URL_END

    //CUSTOM_STATIC_GET_WEBSOCKET_URL_START
    event.results[0] = Websocket.WebsocketUrl;
    //CUSTOM_STATIC_GET_WEBSOCKET_URL_END

    //GENERATED_STATIC_GET_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_GET_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_WEBSOCKET_URL_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  /**
   * SendObj()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async SendObj(event) {

    //GENERATED_STATIC_SEND_OBJ_START
    //CUSTOM_USER_MODE_SEND_OBJ_INSTANCE_HANDLER_START
    let {object} = event;

    let {options} = event;
    let {message} = options;

    for (let runtime of Websocket.Runtimes) {

      let valid = runtime.validate('send', object);

      if (valid) {

        let instance = await runtime.send(object, message);

        if (instance) {
          object.instances[runtime.type].object = instance;
        } else {
          object.instances[runtime.type].object = null;
        }

      }
    }
    //CUSTOM_USER_MODE_SEND_OBJ_INSTANCE_HANDLER_END
    //GENERATED_STATIC_SEND_OBJ_END

    //CUSTOM_STATIC_SEND_OBJ_START
    //CUSTOM_STATIC_SEND_OBJ_END

    //GENERATED_STATIC_SEND_OBJ_AFTER_START
    //GENERATED_STATIC_SEND_OBJ_AFTER_END

    //CUSTOM_STATIC_GENERATED_SEND_OBJ_AFTER_START
    //CUSTOM_STATIC_GENERATED_SEND_OBJ_AFTER_END

  }
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  /**
   * GetRuntimeWebsocketSocketIO()
   * - Gets the SocketIO runtime implementation of R3RuntimeWebsocket
   * @param {Object} event
   * - No returns
   */
  static GetRuntimeWebsocketSocketIO(event) {

    //GENERATED_STATIC_GET_RUNTIME_WEBSOCKET_SOCKET_I_O_START
    event.results = [Websocket.RuntimeWebsocketSocketIO];
    //GENERATED_STATIC_GET_RUNTIME_WEBSOCKET_SOCKET_I_O_END

    //CUSTOM_STATIC_GET_RUNTIME_WEBSOCKET_SOCKET_I_O_START
    //CUSTOM_STATIC_GET_RUNTIME_WEBSOCKET_SOCKET_I_O_END

    //GENERATED_STATIC_GET_RUNTIME_WEBSOCKET_SOCKET_I_O_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_WEBSOCKET_SOCKET_I_O_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_WEBSOCKET_SOCKET_I_O_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_WEBSOCKET_SOCKET_I_O_AFTER_END

  }
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
/**
 * @param Websocket.RuntimeWebsocketSocketIO
 * - Runtime implementation of R3RuntimeWebsocket
 */
Websocket.RuntimeWebsocketSocketIO = new SocketIORuntime();
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Websocket.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Websocket.IsStarting = false;

/**
 * @param Websocket.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Websocket.IsStopping = false;

/**
 * @param Websocket.Started
 * - Indicates whether this system is running
 */
Websocket.Started = false;

/**
 * @param Websocket.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Websocket.Subscriptions = {};

/**
 * @param Websocket.Runtimes
 * - A set of runtimes which the system manages
 */
Websocket.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Websocket.WebsocketUrl
 * - No comment
 */
Websocket.WebsocketUrl = null;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Websocket as default};
