//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import * as THREE from 'three';
import Event from '../../Event.js';
import Obj from '../../event/Obj.js';
import Utils from "../../Utils.js";
import {Texture} from '../../event/obj/component/graphics/Texture.js';
//CUSTOM_IMPORTS_END

import Graphics from '../Graphics.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  targets=[] - A list of meshes that are currently selected
  apiUrl=null - The API URL to load graphics resources from
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
  render(renderer) - Runtime specific render function for rendering a renderer 
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  cloneInstance(object, propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance(object) - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance(object) - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(object, property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  cloneInstance(object, propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance(object) - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance(object) - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(object, property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
  cloneInstanceCamera(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsCamera] 
  createInstanceCamera(object) - Three implementation for [R3EventObjComponentGraphicsCamera] 
  deleteInstanceCamera(object) - Three implementation for [R3EventObjComponentGraphicsCamera] 
  updateInstanceCamera(object, property, value) - Three implementation for [R3EventObjComponentGraphicsCamera] 
  cloneInstanceCubeCamera(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsCameraCube] 
  createInstanceCubeCamera(object) - Three implementation for [R3EventObjComponentGraphicsCameraCube] 
  deleteInstanceCubeCamera(object) - Three implementation for [R3EventObjComponentGraphicsCameraCube] 
  updateInstanceCubeCamera(object, property, value) - Three implementation for [R3EventObjComponentGraphicsCameraCube] 
  cloneInstanceOrthographic(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsCameraOrthographic] 
  createInstanceOrthographic(object) - Three implementation for [R3EventObjComponentGraphicsCameraOrthographic] 
  deleteInstanceOrthographic(object) - Three implementation for [R3EventObjComponentGraphicsCameraOrthographic] 
  updateInstanceOrthographic(object, property, value) - Three implementation for [R3EventObjComponentGraphicsCameraOrthographic] 
  cloneInstancePerspective(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsCameraPerspective] 
  createInstancePerspective(object) - Three implementation for [R3EventObjComponentGraphicsCameraPerspective] 
  deleteInstancePerspective(object) - Three implementation for [R3EventObjComponentGraphicsCameraPerspective] 
  updateInstancePerspective(object, property, value) - Three implementation for [R3EventObjComponentGraphicsCameraPerspective] 
  cloneInstanceCanvasGraphics(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsCanvas] 
  createInstanceCanvasGraphics(object) - Three implementation for [R3EventObjComponentGraphicsCanvas] 
  deleteInstanceCanvasGraphics(object) - Three implementation for [R3EventObjComponentGraphicsCanvas] 
  updateInstanceCanvasGraphics(object, property, value) - Three implementation for [R3EventObjComponentGraphicsCanvas] 
  cloneInstanceFog(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsFog] 
  createInstanceFog(object) - Three implementation for [R3EventObjComponentGraphicsFog] 
  deleteInstanceFog(object) - Three implementation for [R3EventObjComponentGraphicsFog] 
  updateInstanceFog(object, property, value) - Three implementation for [R3EventObjComponentGraphicsFog] 
  cloneInstanceBox(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsGeometryBox] 
  createInstanceBox(object) - Three implementation for [R3EventObjComponentGraphicsGeometryBox] 
  deleteInstanceBox(object) - Three implementation for [R3EventObjComponentGraphicsGeometryBox] 
  updateInstanceBox(object, property, value) - Three implementation for [R3EventObjComponentGraphicsGeometryBox] 
  cloneInstanceBuffer(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsGeometryBuffer] 
  createInstanceBuffer(object) - Three implementation for [R3EventObjComponentGraphicsGeometryBuffer] 
  deleteInstanceBuffer(object) - Three implementation for [R3EventObjComponentGraphicsGeometryBuffer] 
  updateInstanceBuffer(object, property, value) - Three implementation for [R3EventObjComponentGraphicsGeometryBuffer] 
  cloneInstancePlaneGeometry(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsGeometryPlane] 
  createInstancePlaneGeometry(object) - Three implementation for [R3EventObjComponentGraphicsGeometryPlane] 
  deleteInstancePlaneGeometry(object) - Three implementation for [R3EventObjComponentGraphicsGeometryPlane] 
  updateInstancePlaneGeometry(object, property, value) - Three implementation for [R3EventObjComponentGraphicsGeometryPlane] 
  cloneInstanceSphereGeometry(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsGeometrySphere] 
  createInstanceSphereGeometry(object) - Three implementation for [R3EventObjComponentGraphicsGeometrySphere] 
  deleteInstanceSphereGeometry(object) - Three implementation for [R3EventObjComponentGraphicsGeometrySphere] 
  updateInstanceSphereGeometry(object, property, value) - Three implementation for [R3EventObjComponentGraphicsGeometrySphere] 
  cloneInstanceTorus(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsGeometryTorus] 
  createInstanceTorus(object) - Three implementation for [R3EventObjComponentGraphicsGeometryTorus] 
  deleteInstanceTorus(object) - Three implementation for [R3EventObjComponentGraphicsGeometryTorus] 
  updateInstanceTorus(object, property, value) - Three implementation for [R3EventObjComponentGraphicsGeometryTorus] 
  cloneInstanceImageGraphics(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsImage] 
  createInstanceImageGraphics(object) - Three implementation for [R3EventObjComponentGraphicsImage] 
  deleteInstanceImageGraphics(object) - Three implementation for [R3EventObjComponentGraphicsImage] 
  updateInstanceImageGraphics(object, property, value) - Three implementation for [R3EventObjComponentGraphicsImage] 
  cloneInstanceAmbient(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsLightAmbient] 
  createInstanceAmbient(object) - Three implementation for [R3EventObjComponentGraphicsLightAmbient] 
  deleteInstanceAmbient(object) - Three implementation for [R3EventObjComponentGraphicsLightAmbient] 
  updateInstanceAmbient(object, property, value) - Three implementation for [R3EventObjComponentGraphicsLightAmbient] 
  cloneInstanceDirectionalLight(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsLightDirectional] 
  createInstanceDirectionalLight(object) - Three implementation for [R3EventObjComponentGraphicsLightDirectional] 
  deleteInstanceDirectionalLight(object) - Three implementation for [R3EventObjComponentGraphicsLightDirectional] 
  updateInstanceDirectionalLight(object, property, value) - Three implementation for [R3EventObjComponentGraphicsLightDirectional] 
  cloneInstancePointLight(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsLightPoint] 
  createInstancePointLight(object) - Three implementation for [R3EventObjComponentGraphicsLightPoint] 
  deleteInstancePointLight(object) - Three implementation for [R3EventObjComponentGraphicsLightPoint] 
  updateInstancePointLight(object, property, value) - Three implementation for [R3EventObjComponentGraphicsLightPoint] 
  cloneInstanceSpotLight(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsLightSpot] 
  createInstanceSpotLight(object) - Three implementation for [R3EventObjComponentGraphicsLightSpot] 
  deleteInstanceSpotLight(object) - Three implementation for [R3EventObjComponentGraphicsLightSpot] 
  updateInstanceSpotLight(object, property, value) - Three implementation for [R3EventObjComponentGraphicsLightSpot] 
  cloneInstanceMaterial(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsMaterial] 
  createInstanceMaterial(object) - Three implementation for [R3EventObjComponentGraphicsMaterial] 
  deleteInstanceMaterial(object) - Three implementation for [R3EventObjComponentGraphicsMaterial] 
  updateInstanceMaterial(object, property, value) - Three implementation for [R3EventObjComponentGraphicsMaterial] 
  cloneInstanceBasic(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsMaterialBasic] 
  createInstanceBasic(object) - Three implementation for [R3EventObjComponentGraphicsMaterialBasic] 
  deleteInstanceBasic(object) - Three implementation for [R3EventObjComponentGraphicsMaterialBasic] 
  updateInstanceBasic(object, property, value) - Three implementation for [R3EventObjComponentGraphicsMaterialBasic] 
  cloneInstanceMaterialGroup(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsMaterialGroup] 
  createInstanceMaterialGroup(object) - Three implementation for [R3EventObjComponentGraphicsMaterialGroup] 
  deleteInstanceMaterialGroup(object) - Three implementation for [R3EventObjComponentGraphicsMaterialGroup] 
  updateInstanceMaterialGroup(object, property, value) - Three implementation for [R3EventObjComponentGraphicsMaterialGroup] 
  cloneInstanceLambert(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsMaterialLambert] 
  createInstanceLambert(object) - Three implementation for [R3EventObjComponentGraphicsMaterialLambert] 
  deleteInstanceLambert(object) - Three implementation for [R3EventObjComponentGraphicsMaterialLambert] 
  updateInstanceLambert(object, property, value) - Three implementation for [R3EventObjComponentGraphicsMaterialLambert] 
  cloneInstancePhong(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsMaterialPhong] 
  createInstancePhong(object) - Three implementation for [R3EventObjComponentGraphicsMaterialPhong] 
  deleteInstancePhong(object) - Three implementation for [R3EventObjComponentGraphicsMaterialPhong] 
  updateInstancePhong(object, property, value) - Three implementation for [R3EventObjComponentGraphicsMaterialPhong] 
  cloneInstancePoints(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsMaterialPoints] 
  createInstancePoints(object) - Three implementation for [R3EventObjComponentGraphicsMaterialPoints] 
  deleteInstancePoints(object) - Three implementation for [R3EventObjComponentGraphicsMaterialPoints] 
  updateInstancePoints(object, property, value) - Three implementation for [R3EventObjComponentGraphicsMaterialPoints] 
  cloneInstanceStandard(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsMaterialStandard] 
  createInstanceStandard(object) - Three implementation for [R3EventObjComponentGraphicsMaterialStandard] 
  deleteInstanceStandard(object) - Three implementation for [R3EventObjComponentGraphicsMaterialStandard] 
  updateInstanceStandard(object, property, value) - Three implementation for [R3EventObjComponentGraphicsMaterialStandard] 
  cloneInstanceToon(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsMaterialToon] 
  createInstanceToon(object) - Three implementation for [R3EventObjComponentGraphicsMaterialToon] 
  deleteInstanceToon(object) - Three implementation for [R3EventObjComponentGraphicsMaterialToon] 
  updateInstanceToon(object, property, value) - Three implementation for [R3EventObjComponentGraphicsMaterialToon] 
  cloneInstanceMesh(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsMesh] 
  createInstanceMesh(object) - Three implementation for [R3EventObjComponentGraphicsMesh] 
  deleteInstanceMesh(object) - Three implementation for [R3EventObjComponentGraphicsMesh] 
  updateInstanceMesh(object, property, value) - Three implementation for [R3EventObjComponentGraphicsMesh] 
  cloneInstanceRaycaster(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsRaycaster] 
  createInstanceRaycaster(object) - Three implementation for [R3EventObjComponentGraphicsRaycaster] 
  deleteInstanceRaycaster(object) - Three implementation for [R3EventObjComponentGraphicsRaycaster] 
  updateInstanceRaycaster(object, property, value) - Three implementation for [R3EventObjComponentGraphicsRaycaster] 
  cloneInstanceCubeRenderer(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsRendererCube] 
  createInstanceCubeRenderer(object) - Three implementation for [R3EventObjComponentGraphicsRendererCube] 
  deleteInstanceCubeRenderer(object) - Three implementation for [R3EventObjComponentGraphicsRendererCube] 
  updateInstanceCubeRenderer(object, property, value) - Three implementation for [R3EventObjComponentGraphicsRendererCube] 
  cloneInstanceGl(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsRendererGl] 
  createInstanceGl(object) - Three implementation for [R3EventObjComponentGraphicsRendererGl] 
  deleteInstanceGl(object) - Three implementation for [R3EventObjComponentGraphicsRendererGl] 
  updateInstanceGl(object, property, value) - Three implementation for [R3EventObjComponentGraphicsRendererGl] 
  cloneInstanceTarget(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsRendererTarget] 
  createInstanceTarget(object) - Three implementation for [R3EventObjComponentGraphicsRendererTarget] 
  deleteInstanceTarget(object) - Three implementation for [R3EventObjComponentGraphicsRendererTarget] 
  updateInstanceTarget(object, property, value) - Three implementation for [R3EventObjComponentGraphicsRendererTarget] 
  cloneInstanceScene(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsScene] 
  createInstanceScene(object) - Three implementation for [R3EventObjComponentGraphicsScene] 
  deleteInstanceScene(object) - Three implementation for [R3EventObjComponentGraphicsScene] 
  updateInstanceScene(object, property, value) - Three implementation for [R3EventObjComponentGraphicsScene] 
  cloneInstanceShader(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsShader] 
  createInstanceShader(object) - Three implementation for [R3EventObjComponentGraphicsShader] 
  deleteInstanceShader(object) - Three implementation for [R3EventObjComponentGraphicsShader] 
  updateInstanceShader(object, property, value) - Three implementation for [R3EventObjComponentGraphicsShader] 
  cloneInstanceSprite(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsSprite] 
  createInstanceSprite(object) - Three implementation for [R3EventObjComponentGraphicsSprite] 
  deleteInstanceSprite(object) - Three implementation for [R3EventObjComponentGraphicsSprite] 
  updateInstanceSprite(object, property, value) - Three implementation for [R3EventObjComponentGraphicsSprite] 
  cloneInstanceCanvasTexture(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsTextureCanvas] 
  createInstanceCanvasTexture(object) - Three implementation for [R3EventObjComponentGraphicsTextureCanvas] 
  deleteInstanceCanvasTexture(object) - Three implementation for [R3EventObjComponentGraphicsTextureCanvas] 
  updateInstanceCanvasTexture(object, property, value) - Three implementation for [R3EventObjComponentGraphicsTextureCanvas] 
  cloneInstanceCubeTexture(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsTextureCube] 
  createInstanceCubeTexture(object) - Three implementation for [R3EventObjComponentGraphicsTextureCube] 
  deleteInstanceCubeTexture(object) - Three implementation for [R3EventObjComponentGraphicsTextureCube] 
  updateInstanceCubeTexture(object, property, value) - Three implementation for [R3EventObjComponentGraphicsTextureCube] 
  cloneInstanceImageTexture(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsTextureImage] 
  createInstanceImageTexture(object) - Three implementation for [R3EventObjComponentGraphicsTextureImage] 
  deleteInstanceImageTexture(object) - Three implementation for [R3EventObjComponentGraphicsTextureImage] 
  updateInstanceImageTexture(object, property, value) - Three implementation for [R3EventObjComponentGraphicsTextureImage] 
  cloneInstanceVideoTexture(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsTextureVideo] 
  createInstanceVideoTexture(object) - Three implementation for [R3EventObjComponentGraphicsTextureVideo] 
  deleteInstanceVideoTexture(object) - Three implementation for [R3EventObjComponentGraphicsTextureVideo] 
  updateInstanceVideoTexture(object, property, value) - Three implementation for [R3EventObjComponentGraphicsTextureVideo] 
  cloneInstanceVideoGraphics(object, propagate = true) - Three implementation for [R3EventObjComponentGraphicsVideo] 
  createInstanceVideoGraphics(object) - Three implementation for [R3EventObjComponentGraphicsVideo] 
  deleteInstanceVideoGraphics(object) - Three implementation for [R3EventObjComponentGraphicsVideo] 
  updateInstanceVideoGraphics(object, property, value) - Three implementation for [R3EventObjComponentGraphicsVideo] 
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Three extends Graphics {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeGraphics';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeGraphicsThree';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'ThreeGraphics';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_GRAPHICS;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param targets
     * - A list of meshes that are currently selected
     */
    if (typeof options.targets === 'undefined') {
      options.targets = [];
    }

    /**
     * @param apiUrl
     * - The API URL to load graphics resources from
     */
    if (typeof options.apiUrl === 'undefined') {
      options.apiUrl = null;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    this.instance = THREE;
    THREE.ColorManagement.enabled = true;

    Event.On(
      Event.MESH_SELECTED,
      (event) => {
        let {mesh} = event;
        this.targets.push(mesh);

        let {project} = mesh;

        if (project && project.editorControls) {
          if (project.editorControls) {
            project.editorControls.target = mesh;
          }
        }

      },
      {
        priority : 41
      }
    );

    Event.On(
      Event.MESH_DESELECTED,
      (event) => {
        let {mesh} = event;
        this.targets = this.targets.filter(
          (target) => {
            return (target !== mesh);
          }
        )

        let {project} = mesh;
        if (!project) {
          throw new Error(`Mesh has no project`);
        }

        let {editorControls} = project;

        if (
          this.targets.length &&
          editorControls
        ) {
          editorControls.target = this.targets[this.targets.length - 1];
          return;
        }

        if (editorControls) {
          editorControls.target = null;
        }

      },
      {
        priority : 41
      }
    );

    Event.On(
      Event.SET_API_URL,
      (event) => {
        this.apiUrl = event.object;
        console.log(`Graphics URL set to ${this.apiUrl}`);
      },
      {
        priority : 42
      }
    );
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  /**
   * render()
   * - Runtime specific render function for rendering a renderer
   * @param renderer
   * - No returns
   */
  render(renderer) {

    //CUSTOM_RENDER_BEFORE_START
    //CUSTOM_RENDER_BEFORE_END

    //GENERATED_RENDER_BEFORE_START
    //GENERATED_RENDER_BEFORE_END

    //CUSTOM_RENDER_BEFORE_GENERATED_START
    //CUSTOM_RENDER_BEFORE_GENERATED_END

    //GENERATED_RENDER_START
    //GENERATED_RENDER_END

    //CUSTOM_RENDER_START
    let instance = renderer.getInstance(this);
    if (!instance) {
      return;
    }

    let scene = null;

    if (renderer.scene) {
      scene = renderer.scene.getInstance(this);
    }

    if (!scene) {
      return;
    }

    if (!renderer.project) {
      throw new Error(`${renderer.uniqueName} (${renderer.id}) has no project`);
    }

    let {project} = renderer;

    if (project.editorControls) {

      if (project.mode === Obj.MODE_LIVE) {
        throw new Error(`Project is in live mode but has editor controls`);
      }

      let {editorControls} = project;

      let controlsInstance = editorControls.getInstance(Runtime.KEY_INPUT_THREE);
      if (!controlsInstance) {
        return;
      }

      controlsInstance.update();

      let controlsCamera = editorControls.camera;
      let controlsCameraInstance = controlsCamera.getInstance(this);
      if (!controlsCameraInstance) {
        return;
      }

      let {x, y, z} = controlsCameraInstance.position;

      if (x === x) {
        if (x !== controlsCamera.position.x) {
          controlsCamera.position.x = x;
        }
      }

      if (y === y) {
        if (y !== controlsCamera.position.y) {
          controlsCamera.position.y = y;
        }
      }

      if (z === z) {
        if (z !== controlsCamera.position.z) {
          controlsCamera.position.z = z;
        }
      }

      if (controlsInstance.target) {

        let {x, y, z} = controlsInstance.target;

        if (x === x) {
          if (x !== controlsCamera.lookAt.x) {
            controlsCamera.lookAt.x = x;
          }
        }

        if (y === y) {
          if (y !== controlsCamera.lookAt.y) {
            controlsCamera.lookAt.y = y;
          }
        }

        if (z === z) {
          if (z !== controlsCamera.lookAt.z) {
            controlsCamera.lookAt.z = z;
          }
        }

      }

      if (controlsCamera.fixedAspect) {

        let {viewport} = controlsCamera;

        if (viewport) {
          let {x, y, width, height} = viewport;
          instance.setViewport(x, y, width, height);
        }

      } else {
        if (renderer.useCanvasSize) {
          let {canvas} = renderer;
          instance.setViewport(0, 0, canvas.width, canvas.height);
        } else {
          instance.setViewport(0, 0, renderer.width, renderer.height);
        }
      }

      instance.render(scene, controlsCameraInstance);

    } else {

      let cameraInstance = null;

      let {camera} = renderer;

      if (camera) {
        cameraInstance = camera.getInstance(this);
      }

      if (!cameraInstance) {
        return;
      }

      if (camera.fixedAspect) {

        let {viewport} = camera;

        if (viewport) {
          let {x, y, width, height} = viewport;
          instance.setViewport(x, y, width, height);
        }

      } else {
        if (renderer.useCanvasSize) {
          let {canvas} = renderer;
          instance.setViewport(0, 0, canvas.width, canvas.height);
        } else {
          instance.setViewport(0, 0, renderer.width, renderer.height);
        }
      }

      instance.render(scene, cameraInstance);

    }
    //CUSTOM_RENDER_END

    //GENERATED_RENDER_AFTER_START
    //GENERATED_RENDER_AFTER_END

  }

  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * cloneInstance()
   * - Clones an instance, when propagate is true, also clones the instance children
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstance(object, propagate = true) {

    //CUSTOM_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_CLONE_INSTANCE_START
    super.cloneInstance(object, propagate);
    //CUSTOM_RETURN_GENERATED_CLONE_INSTANCE_END
    //GENERATED_CLONE_INSTANCE_BEFORE_END

    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CLONE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentGraphicsCamera'
   || object.type === 'R3EventObjComponentGraphicsCameraPerspective'
   || object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
   || object.type === 'R3EventObjComponentGraphicsCameraCube'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CAMERA_START
      this.cloneInstanceCamera(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CAMERA_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraCube'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CUBE_CAMERA_START
      return this.cloneInstanceCubeCamera(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CUBE_CAMERA_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_ORTHOGRAPHIC_START
      return this.cloneInstanceOrthographic(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_ORTHOGRAPHIC_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraPerspective'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_PERSPECTIVE_START
      return this.cloneInstancePerspective(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_PERSPECTIVE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCanvas'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CANVAS_GRAPHICS_START
      return this.cloneInstanceCanvasGraphics(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CANVAS_GRAPHICS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsFog'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_FOG_START
      return this.cloneInstanceFog(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_FOG_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryBox'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_BOX_START
      return this.cloneInstanceBox(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_BOX_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryBuffer'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_BUFFER_START
      return this.cloneInstanceBuffer(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_BUFFER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryPlane'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_PLANE_GEOMETRY_START
      return this.cloneInstancePlaneGeometry(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_PLANE_GEOMETRY_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometrySphere'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SPHERE_GEOMETRY_START
      return this.cloneInstanceSphereGeometry(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SPHERE_GEOMETRY_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryTorus'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_TORUS_START
      return this.cloneInstanceTorus(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_TORUS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsImage'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_IMAGE_GRAPHICS_START
      return this.cloneInstanceImageGraphics(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_IMAGE_GRAPHICS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightAmbient'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_AMBIENT_START
      return this.cloneInstanceAmbient(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_AMBIENT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightDirectional'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_DIRECTIONAL_LIGHT_START
      return this.cloneInstanceDirectionalLight(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_DIRECTIONAL_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightPoint'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_POINT_LIGHT_START
      return this.cloneInstancePointLight(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_POINT_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightSpot'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SPOT_LIGHT_START
      return this.cloneInstanceSpotLight(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SPOT_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterial'
   || object.type === 'R3EventObjComponentGraphicsMaterialToon'
   || object.type === 'R3EventObjComponentGraphicsMaterialStandard'
   || object.type === 'R3EventObjComponentGraphicsMaterialPoints'
   || object.type === 'R3EventObjComponentGraphicsMaterialPhong'
   || object.type === 'R3EventObjComponentGraphicsMaterialLambert'
   || object.type === 'R3EventObjComponentGraphicsMaterialBasic'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_MATERIAL_START
      this.cloneInstanceMaterial(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_MATERIAL_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialBasic'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_BASIC_START
      return this.cloneInstanceBasic(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_BASIC_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialGroup'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_MATERIAL_GROUP_START
      return this.cloneInstanceMaterialGroup(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_MATERIAL_GROUP_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialLambert'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_LAMBERT_START
      return this.cloneInstanceLambert(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_LAMBERT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialPhong'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_PHONG_START
      return this.cloneInstancePhong(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_PHONG_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialPoints'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_POINTS_START
      return this.cloneInstancePoints(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_POINTS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialStandard'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_STANDARD_START
      return this.cloneInstanceStandard(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_STANDARD_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialToon'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_TOON_START
      return this.cloneInstanceToon(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_TOON_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMesh'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_MESH_START
      return this.cloneInstanceMesh(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_MESH_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRaycaster'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_RAYCASTER_START
      return this.cloneInstanceRaycaster(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_RAYCASTER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererCube'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CUBE_RENDERER_START
      return this.cloneInstanceCubeRenderer(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CUBE_RENDERER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererGl'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_GL_START
      return this.cloneInstanceGl(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_GL_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererTarget'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_TARGET_START
      return this.cloneInstanceTarget(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_TARGET_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsScene'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SCENE_START
      return this.cloneInstanceScene(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SCENE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsShader'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SHADER_START
      return this.cloneInstanceShader(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SHADER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsSprite'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SPRITE_START
      return this.cloneInstanceSprite(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SPRITE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureCanvas'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CANVAS_TEXTURE_START
      return this.cloneInstanceCanvasTexture(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CANVAS_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureCube'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CUBE_TEXTURE_START
      return this.cloneInstanceCubeTexture(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_CUBE_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureImage'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_IMAGE_TEXTURE_START
      return this.cloneInstanceImageTexture(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_IMAGE_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureVideo'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VIDEO_TEXTURE_START
      return this.cloneInstanceVideoTexture(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VIDEO_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsVideo'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VIDEO_GRAPHICS_START
      return this.cloneInstanceVideoGraphics(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VIDEO_GRAPHICS_END
    }
    //GENERATED_CLONE_INSTANCE_END

    //CUSTOM_CLONE_INSTANCE_START
    //CUSTOM_CLONE_INSTANCE_END

    //GENERATED_CLONE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in cloneInstance() in runtime R3RuntimeGraphicsThree`);
    //GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  createInstance(object) {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_CREATE_INSTANCE_START
    super.createInstance(object);
    //CUSTOM_RETURN_GENERATED_CREATE_INSTANCE_END
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentGraphicsCamera'
   || object.type === 'R3EventObjComponentGraphicsCameraPerspective'
   || object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
   || object.type === 'R3EventObjComponentGraphicsCameraCube'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CAMERA_START
      this.createInstanceCamera(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CAMERA_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraCube'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CUBE_CAMERA_START
      return this.createInstanceCubeCamera(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CUBE_CAMERA_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_ORTHOGRAPHIC_START
      return this.createInstanceOrthographic(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_ORTHOGRAPHIC_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraPerspective'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_PERSPECTIVE_START
      return this.createInstancePerspective(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_PERSPECTIVE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCanvas'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CANVAS_GRAPHICS_START
      return this.createInstanceCanvasGraphics(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CANVAS_GRAPHICS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsFog'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_FOG_START
      return this.createInstanceFog(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_FOG_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryBox'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_BOX_START
      return this.createInstanceBox(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_BOX_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryBuffer'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_BUFFER_START
      return this.createInstanceBuffer(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_BUFFER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryPlane'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_PLANE_GEOMETRY_START
      return this.createInstancePlaneGeometry(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_PLANE_GEOMETRY_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometrySphere'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SPHERE_GEOMETRY_START
      return this.createInstanceSphereGeometry(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SPHERE_GEOMETRY_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryTorus'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_TORUS_START
      return this.createInstanceTorus(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_TORUS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsImage'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_IMAGE_GRAPHICS_START
      return this.createInstanceImageGraphics(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_IMAGE_GRAPHICS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightAmbient'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_AMBIENT_START
      return this.createInstanceAmbient(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_AMBIENT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightDirectional'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_DIRECTIONAL_LIGHT_START
      return this.createInstanceDirectionalLight(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_DIRECTIONAL_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightPoint'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_POINT_LIGHT_START
      return this.createInstancePointLight(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_POINT_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightSpot'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SPOT_LIGHT_START
      return this.createInstanceSpotLight(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SPOT_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterial'
   || object.type === 'R3EventObjComponentGraphicsMaterialToon'
   || object.type === 'R3EventObjComponentGraphicsMaterialStandard'
   || object.type === 'R3EventObjComponentGraphicsMaterialPoints'
   || object.type === 'R3EventObjComponentGraphicsMaterialPhong'
   || object.type === 'R3EventObjComponentGraphicsMaterialLambert'
   || object.type === 'R3EventObjComponentGraphicsMaterialBasic'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_MATERIAL_START
      object.options = this.createInstanceMaterial(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_MATERIAL_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialBasic'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_BASIC_START
      return this.createInstanceBasic(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_BASIC_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialGroup'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_MATERIAL_GROUP_START
      return this.createInstanceMaterialGroup(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_MATERIAL_GROUP_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialLambert'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_LAMBERT_START
      return this.createInstanceLambert(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_LAMBERT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialPhong'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_PHONG_START
      return this.createInstancePhong(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_PHONG_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialPoints'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_POINTS_START
      return this.createInstancePoints(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_POINTS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialStandard'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_STANDARD_START
      return this.createInstanceStandard(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_STANDARD_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialToon'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_TOON_START
      return this.createInstanceToon(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_TOON_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMesh'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_MESH_START
      return this.createInstanceMesh(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_MESH_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRaycaster'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_RAYCASTER_START
      return this.createInstanceRaycaster(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_RAYCASTER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererCube'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CUBE_RENDERER_START
      return this.createInstanceCubeRenderer(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CUBE_RENDERER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererGl'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_GL_START
      return this.createInstanceGl(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_GL_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererTarget'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_TARGET_START
      return this.createInstanceTarget(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_TARGET_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsScene'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SCENE_START
      return this.createInstanceScene(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SCENE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsShader'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SHADER_START
      return this.createInstanceShader(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SHADER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsSprite'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SPRITE_START
      return this.createInstanceSprite(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SPRITE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureCanvas'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CANVAS_TEXTURE_START
      return this.createInstanceCanvasTexture(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CANVAS_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureCube'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CUBE_TEXTURE_START
      return this.createInstanceCubeTexture(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CUBE_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureImage'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_IMAGE_TEXTURE_START
      return this.createInstanceImageTexture(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_IMAGE_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureVideo'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VIDEO_TEXTURE_START
      return this.createInstanceVideoTexture(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VIDEO_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsVideo'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VIDEO_GRAPHICS_START
      return this.createInstanceVideoGraphics(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VIDEO_GRAPHICS_END
    }
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in createInstance() in runtime R3RuntimeGraphicsThree`);
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  deleteInstance(object) {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_DELETE_INSTANCE_START
    super.deleteInstance(object);
    //CUSTOM_RETURN_GENERATED_DELETE_INSTANCE_END
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentGraphicsCamera'
   || object.type === 'R3EventObjComponentGraphicsCameraPerspective'
   || object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
   || object.type === 'R3EventObjComponentGraphicsCameraCube'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CAMERA_START
      this.deleteInstanceCamera(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CAMERA_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraCube'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CUBE_CAMERA_START
      return this.deleteInstanceCubeCamera(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CUBE_CAMERA_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_ORTHOGRAPHIC_START
      return this.deleteInstanceOrthographic(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_ORTHOGRAPHIC_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraPerspective'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_PERSPECTIVE_START
      return this.deleteInstancePerspective(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_PERSPECTIVE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCanvas'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CANVAS_GRAPHICS_START
      return this.deleteInstanceCanvasGraphics(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CANVAS_GRAPHICS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsFog'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_FOG_START
      return this.deleteInstanceFog(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_FOG_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryBox'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_BOX_START
      return this.deleteInstanceBox(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_BOX_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryBuffer'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_BUFFER_START
      return this.deleteInstanceBuffer(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_BUFFER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryPlane'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_PLANE_GEOMETRY_START
      return this.deleteInstancePlaneGeometry(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_PLANE_GEOMETRY_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometrySphere'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SPHERE_GEOMETRY_START
      return this.deleteInstanceSphereGeometry(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SPHERE_GEOMETRY_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryTorus'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_TORUS_START
      return this.deleteInstanceTorus(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_TORUS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsImage'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_IMAGE_GRAPHICS_START
      return this.deleteInstanceImageGraphics(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_IMAGE_GRAPHICS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightAmbient'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_AMBIENT_START
      return this.deleteInstanceAmbient(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_AMBIENT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightDirectional'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_DIRECTIONAL_LIGHT_START
      return this.deleteInstanceDirectionalLight(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_DIRECTIONAL_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightPoint'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_POINT_LIGHT_START
      return this.deleteInstancePointLight(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_POINT_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightSpot'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SPOT_LIGHT_START
      return this.deleteInstanceSpotLight(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SPOT_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterial'
   || object.type === 'R3EventObjComponentGraphicsMaterialToon'
   || object.type === 'R3EventObjComponentGraphicsMaterialStandard'
   || object.type === 'R3EventObjComponentGraphicsMaterialPoints'
   || object.type === 'R3EventObjComponentGraphicsMaterialPhong'
   || object.type === 'R3EventObjComponentGraphicsMaterialLambert'
   || object.type === 'R3EventObjComponentGraphicsMaterialBasic'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_MATERIAL_START
      this.deleteInstanceMaterial(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_MATERIAL_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialBasic'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_BASIC_START
      return this.deleteInstanceBasic(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_BASIC_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialGroup'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_MATERIAL_GROUP_START
      return this.deleteInstanceMaterialGroup(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_MATERIAL_GROUP_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialLambert'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_LAMBERT_START
      return this.deleteInstanceLambert(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_LAMBERT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialPhong'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_PHONG_START
      return this.deleteInstancePhong(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_PHONG_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialPoints'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_POINTS_START
      return this.deleteInstancePoints(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_POINTS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialStandard'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_STANDARD_START
      return this.deleteInstanceStandard(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_STANDARD_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialToon'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_TOON_START
      return this.deleteInstanceToon(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_TOON_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMesh'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_MESH_START
      return this.deleteInstanceMesh(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_MESH_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRaycaster'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_RAYCASTER_START
      return this.deleteInstanceRaycaster(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_RAYCASTER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererCube'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CUBE_RENDERER_START
      return this.deleteInstanceCubeRenderer(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CUBE_RENDERER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererGl'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_GL_START
      return this.deleteInstanceGl(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_GL_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererTarget'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_TARGET_START
      return this.deleteInstanceTarget(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_TARGET_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsScene'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SCENE_START
      return this.deleteInstanceScene(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SCENE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsShader'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SHADER_START
      return this.deleteInstanceShader(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SHADER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsSprite'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SPRITE_START
      return this.deleteInstanceSprite(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SPRITE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureCanvas'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CANVAS_TEXTURE_START
      return this.deleteInstanceCanvasTexture(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CANVAS_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureCube'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CUBE_TEXTURE_START
      return this.deleteInstanceCubeTexture(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CUBE_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureImage'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_IMAGE_TEXTURE_START
      return this.deleteInstanceImageTexture(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_IMAGE_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureVideo'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VIDEO_TEXTURE_START
      return this.deleteInstanceVideoTexture(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VIDEO_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsVideo'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VIDEO_GRAPHICS_START
      return this.deleteInstanceVideoGraphics(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VIDEO_GRAPHICS_END
    }
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in deleteInstance() in runtime R3RuntimeGraphicsThree`);
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(object, property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    if (
      property === 'id' ||
      property === 'name' ||
      property === 'entityRef'
    ) {
      return;
    }
    
    let updated = false;
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_UPDATE_INSTANCE_START
    super.updateInstance(object, property, value);
    //CUSTOM_RETURN_GENERATED_UPDATE_INSTANCE_END
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentGraphicsCamera'
   || object.type === 'R3EventObjComponentGraphicsCameraPerspective'
   || object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
   || object.type === 'R3EventObjComponentGraphicsCameraCube'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CAMERA_START
      updated = this.updateInstanceCamera(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CAMERA_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraCube'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CUBE_CAMERA_START
      if (updated) {
        return;
      }
      return this.updateInstanceCubeCamera(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CUBE_CAMERA_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_ORTHOGRAPHIC_START
      if (updated) {
        return;
      }
      return this.updateInstanceOrthographic(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_ORTHOGRAPHIC_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCameraPerspective'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_PERSPECTIVE_START
      if (updated) {
        return;
      }
      return this.updateInstancePerspective(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_PERSPECTIVE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsCanvas'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CANVAS_GRAPHICS_START
      return this.updateInstanceCanvasGraphics(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CANVAS_GRAPHICS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsFog'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_FOG_START
      return this.updateInstanceFog(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_FOG_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryBox'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_BOX_START
      return this.updateInstanceBox(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_BOX_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryBuffer'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_BUFFER_START
      return this.updateInstanceBuffer(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_BUFFER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryPlane'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_PLANE_GEOMETRY_START
      return this.updateInstancePlaneGeometry(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_PLANE_GEOMETRY_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometrySphere'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SPHERE_GEOMETRY_START
      return this.updateInstanceSphereGeometry(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SPHERE_GEOMETRY_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsGeometryTorus'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_TORUS_START
      return this.updateInstanceTorus(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_TORUS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsImage'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_IMAGE_GRAPHICS_START
      return this.updateInstanceImageGraphics(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_IMAGE_GRAPHICS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightAmbient'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_AMBIENT_START
      return this.updateInstanceAmbient(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_AMBIENT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightDirectional'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_DIRECTIONAL_LIGHT_START
      return this.updateInstanceDirectionalLight(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_DIRECTIONAL_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightPoint'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_POINT_LIGHT_START
      return this.updateInstancePointLight(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_POINT_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsLightSpot'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SPOT_LIGHT_START
      return this.updateInstanceSpotLight(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SPOT_LIGHT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterial'
   || object.type === 'R3EventObjComponentGraphicsMaterialToon'
   || object.type === 'R3EventObjComponentGraphicsMaterialStandard'
   || object.type === 'R3EventObjComponentGraphicsMaterialPoints'
   || object.type === 'R3EventObjComponentGraphicsMaterialPhong'
   || object.type === 'R3EventObjComponentGraphicsMaterialLambert'
   || object.type === 'R3EventObjComponentGraphicsMaterialBasic'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_MATERIAL_START
      updated = this.updateInstanceMaterial(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_MATERIAL_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialBasic'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_BASIC_START
      if (updated) {
        return;
      }

      return this.updateInstanceBasic(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_BASIC_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialGroup'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_MATERIAL_GROUP_START
      return this.updateInstanceMaterialGroup(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_MATERIAL_GROUP_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialLambert'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_LAMBERT_START
      if (updated) {
        return;
      }
      return this.updateInstanceLambert(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_LAMBERT_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialPhong'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_PHONG_START
      if (updated) {
        return;
      }
      return this.updateInstancePhong(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_PHONG_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialPoints'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_POINTS_START
      if (updated) {
        return;
      }
      return this.updateInstancePoints(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_POINTS_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialStandard'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_STANDARD_START
      if (updated) {
        return;
      }
      return this.updateInstanceStandard(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_STANDARD_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMaterialToon'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_TOON_START
      if (updated) {
        return;
      }
      return this.updateInstanceToon(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_TOON_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsMesh'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_MESH_START
      return this.updateInstanceMesh(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_MESH_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRaycaster'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_RAYCASTER_START
      return this.updateInstanceRaycaster(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_RAYCASTER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererCube'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CUBE_RENDERER_START
      return this.updateInstanceCubeRenderer(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CUBE_RENDERER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererGl'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_GL_START
      return this.updateInstanceGl(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_GL_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsRendererTarget'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_TARGET_START
      return this.updateInstanceTarget(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_TARGET_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsScene'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SCENE_START
      return this.updateInstanceScene(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SCENE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsShader'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SHADER_START
      return this.updateInstanceShader(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SHADER_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsSprite'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SPRITE_START
      return this.updateInstanceSprite(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SPRITE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureCanvas'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CANVAS_TEXTURE_START
      return this.updateInstanceCanvasTexture(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CANVAS_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureCube'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CUBE_TEXTURE_START
      return this.updateInstanceCubeTexture(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CUBE_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureImage'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_IMAGE_TEXTURE_START
      return this.updateInstanceImageTexture(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_IMAGE_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsTextureVideo'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VIDEO_TEXTURE_START
      return this.updateInstanceVideoTexture(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VIDEO_TEXTURE_END
    }

    if (
      object.type === 'R3EventObjComponentGraphicsVideo'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VIDEO_GRAPHICS_START
      return this.updateInstanceVideoGraphics(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VIDEO_GRAPHICS_END
    }
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in updateInstance() in runtime R3RuntimeGraphicsThree`);
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  /**
   * cloneInstanceCamera()
   * - Three implementation for [R3EventObjComponentGraphicsCamera]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceCamera(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_CAMERA_BEFORE_START
    //GENERATED_CLONE_INSTANCE_CAMERA_BEFORE_END

    //GENERATED_CLONE_INSTANCE_CAMERA_START
    //GENERATED_CLONE_INSTANCE_CAMERA_END

    //CUSTOM_CLONE_INSTANCE_CAMERA_START
    throw new Error(`Please implement Three.cloneInstanceCamera(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_CAMERA_END

    //GENERATED_CLONE_INSTANCE_CAMERA_AFTER_START
    //GENERATED_CLONE_INSTANCE_CAMERA_AFTER_END

  }

  /**
   * createInstanceCamera()
   * - Three implementation for [R3EventObjComponentGraphicsCamera]
   * @param object
   * - No returns
   */
  createInstanceCamera(object) {

    //GENERATED_CREATE_INSTANCE_CAMERA_BEFORE_START
    //GENERATED_CREATE_INSTANCE_CAMERA_BEFORE_END

    //GENERATED_CREATE_INSTANCE_CAMERA_START
    //GENERATED_CREATE_INSTANCE_CAMERA_END

    //CUSTOM_CREATE_INSTANCE_CAMERA_START
    /**
     * Do nothing
     */
    //CUSTOM_CREATE_INSTANCE_CAMERA_END

    //GENERATED_CREATE_INSTANCE_CAMERA_AFTER_START
    //GENERATED_CREATE_INSTANCE_CAMERA_AFTER_END

  }

  /**
   * deleteInstanceCamera()
   * - Three implementation for [R3EventObjComponentGraphicsCamera]
   * @param object
   * - No returns
   */
  deleteInstanceCamera(object) {

    //GENERATED_DELETE_INSTANCE_CAMERA_BEFORE_START
    //GENERATED_DELETE_INSTANCE_CAMERA_BEFORE_END

    //GENERATED_DELETE_INSTANCE_CAMERA_START
    //GENERATED_DELETE_INSTANCE_CAMERA_END

    //CUSTOM_DELETE_INSTANCE_CAMERA_START
    /**
     * Do nothing
     */
    //CUSTOM_DELETE_INSTANCE_CAMERA_END

    //GENERATED_DELETE_INSTANCE_CAMERA_AFTER_START
    //GENERATED_DELETE_INSTANCE_CAMERA_AFTER_END

  }

  /**
   * updateInstanceCamera()
   * - Three implementation for [R3EventObjComponentGraphicsCamera]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceCamera(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_CAMERA_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_CAMERA_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_CAMERA_START
    //GENERATED_UPDATE_INSTANCE_CAMERA_END

    //CUSTOM_UPDATE_INSTANCE_CAMERA_START
    if (
      property === 'stereo' ||
      property === 'eyeSeparation'
    ) {
      return true;
    }

    if (
      property === 'position' ||
      property === 'rotation'
    ) {
      let {x, y, z} = value;

      if (isNaN(x)) {
        return true;
      }
      if (isNaN(y)) {
        return true;
      }
      if (isNaN(z)) {
        return true;
      }

      let instance = object.getInstance(this);
      instance[property]['x'] = x;
      instance[property]['y'] = y;
      instance[property]['z'] = z;
      instance.updateProjectionMatrix();
      return true;
    }


    if (
      property === 'near' ||
      property === 'far' ||
      property === 'zoom'
    ) {
      let instance = object.getInstance(this);
      instance[property] = value;
      instance.updateProjectionMatrix();
      return true;
    }

    if (property === 'lookAt') {
      let {x, y, z} = value;
      if (isNaN(x)) {
        return true;
      }
      if (isNaN(y)) {
        return true;
      }
      if (isNaN(z)) {
        return true;
      }
      let instance = object.getInstance(this);
      instance.lookAt(new THREE.Vector3(x, y, z));
      return true;
    }

    if (
      property === 'predefinedAspectRatio'
    ) {

      if (!object.fixedAspect) {
        Utils.Status(-1, "Setting a predefined aspect ratio when not in fixed aspect mode has no effect");
        return true;
      }

      object.aspect = object.predefinedAspectRatio;

      return true;
    }

    if (property === 'aspect') {

      let instance = object.getInstance(this);

      if (object.fixedAspect) {
        object.calculateViewport();
      }

      instance[property] = value;
      instance.updateProjectionMatrix();

      return true;
    }

    if (
      property === 'fixedAspect'
    ) {

      if (value === true) {
        object.aspect = object.predefinedAspectRatio;
      } else {
        /**
         * Do nothing ?
         */
        let {canvas} = object;
        let {width, height} = canvas;
        object.aspect = width / height;
        /**
         * Below triggers GL component to reset the viewport
         */
        // Event.Emit(Event.WINDOW_RESIZE);
      }

      return true;
    }

    if (property === 'canvas') {
      if (object.fixedAspect) {
        object.aspect = object.predefinedAspectRatio;
        object.calculateViewport();
      } else {
        object.aspect = value.width / value.height;
      }
      return true;
    }

    return false;
    //CUSTOM_UPDATE_INSTANCE_CAMERA_END

    //GENERATED_UPDATE_INSTANCE_CAMERA_AFTER_START
    //GENERATED_UPDATE_INSTANCE_CAMERA_AFTER_END

  }

  /**
   * cloneInstanceCubeCamera()
   * - Three implementation for [R3EventObjComponentGraphicsCameraCube]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceCubeCamera(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_CUBE_CAMERA_BEFORE_START
    //GENERATED_CLONE_INSTANCE_CUBE_CAMERA_BEFORE_END

    //GENERATED_CLONE_INSTANCE_CUBE_CAMERA_START
    //GENERATED_CLONE_INSTANCE_CUBE_CAMERA_END

    //CUSTOM_CLONE_INSTANCE_CUBE_CAMERA_START
    throw new Error(`Please implement Three.cloneInstanceCubeCamera(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_CUBE_CAMERA_END

    //GENERATED_CLONE_INSTANCE_CUBE_CAMERA_AFTER_START
    //GENERATED_CLONE_INSTANCE_CUBE_CAMERA_AFTER_END

  }

  /**
   * createInstanceCubeCamera()
   * - Three implementation for [R3EventObjComponentGraphicsCameraCube]
   * @param object
   * - No returns
   */
  createInstanceCubeCamera(object) {

    //GENERATED_CREATE_INSTANCE_CUBE_CAMERA_BEFORE_START
    //GENERATED_CREATE_INSTANCE_CUBE_CAMERA_BEFORE_END

    //GENERATED_CREATE_INSTANCE_CUBE_CAMERA_START
    //GENERATED_CREATE_INSTANCE_CUBE_CAMERA_END

    //CUSTOM_CREATE_INSTANCE_CUBE_CAMERA_START
    throw new Error(`Please implement Three.createInstanceCubeCamera(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_CUBE_CAMERA_END

    //GENERATED_CREATE_INSTANCE_CUBE_CAMERA_AFTER_START
    //GENERATED_CREATE_INSTANCE_CUBE_CAMERA_AFTER_END

  }

  /**
   * deleteInstanceCubeCamera()
   * - Three implementation for [R3EventObjComponentGraphicsCameraCube]
   * @param object
   * - No returns
   */
  deleteInstanceCubeCamera(object) {

    //GENERATED_DELETE_INSTANCE_CUBE_CAMERA_BEFORE_START
    //GENERATED_DELETE_INSTANCE_CUBE_CAMERA_BEFORE_END

    //GENERATED_DELETE_INSTANCE_CUBE_CAMERA_START
    //GENERATED_DELETE_INSTANCE_CUBE_CAMERA_END

    //CUSTOM_DELETE_INSTANCE_CUBE_CAMERA_START
    throw new Error(`Please implement Three.deleteInstanceCubeCamera(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_CUBE_CAMERA_END

    //GENERATED_DELETE_INSTANCE_CUBE_CAMERA_AFTER_START
    //GENERATED_DELETE_INSTANCE_CUBE_CAMERA_AFTER_END

  }

  /**
   * updateInstanceCubeCamera()
   * - Three implementation for [R3EventObjComponentGraphicsCameraCube]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceCubeCamera(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_CUBE_CAMERA_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_CUBE_CAMERA_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_CUBE_CAMERA_START
    //GENERATED_UPDATE_INSTANCE_CUBE_CAMERA_END

    //CUSTOM_UPDATE_INSTANCE_CUBE_CAMERA_START
    throw new Error(`Please implement Three.updateInstanceCubeCamera(object, property, value) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_CUBE_CAMERA_END

    //GENERATED_UPDATE_INSTANCE_CUBE_CAMERA_AFTER_START
    //GENERATED_UPDATE_INSTANCE_CUBE_CAMERA_AFTER_END

  }

  /**
   * cloneInstanceOrthographic()
   * - Three implementation for [R3EventObjComponentGraphicsCameraOrthographic]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceOrthographic(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_ORTHOGRAPHIC_BEFORE_START
    //GENERATED_CLONE_INSTANCE_ORTHOGRAPHIC_BEFORE_END

    //GENERATED_CLONE_INSTANCE_ORTHOGRAPHIC_START
    //GENERATED_CLONE_INSTANCE_ORTHOGRAPHIC_END

    //CUSTOM_CLONE_INSTANCE_ORTHOGRAPHIC_START
    throw new Error(`Please implement Three.cloneInstanceOrthographic(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_ORTHOGRAPHIC_END

    //GENERATED_CLONE_INSTANCE_ORTHOGRAPHIC_AFTER_START
    //GENERATED_CLONE_INSTANCE_ORTHOGRAPHIC_AFTER_END

  }

  /**
   * createInstanceOrthographic()
   * - Three implementation for [R3EventObjComponentGraphicsCameraOrthographic]
   * @param object
   * - No returns
   */
  createInstanceOrthographic(object) {

    //GENERATED_CREATE_INSTANCE_ORTHOGRAPHIC_BEFORE_START
    //GENERATED_CREATE_INSTANCE_ORTHOGRAPHIC_BEFORE_END

    //GENERATED_CREATE_INSTANCE_ORTHOGRAPHIC_START
    //GENERATED_CREATE_INSTANCE_ORTHOGRAPHIC_END

    //CUSTOM_CREATE_INSTANCE_ORTHOGRAPHIC_START
    let instance = new THREE.OrthographicCamera(
      object.left,
      object.right,
      object.top,
      object.bottom,
      object.near,
      object.far
    );
    return instance;
    //CUSTOM_CREATE_INSTANCE_ORTHOGRAPHIC_END

    //GENERATED_CREATE_INSTANCE_ORTHOGRAPHIC_AFTER_START
    //GENERATED_CREATE_INSTANCE_ORTHOGRAPHIC_AFTER_END

  }

  /**
   * deleteInstanceOrthographic()
   * - Three implementation for [R3EventObjComponentGraphicsCameraOrthographic]
   * @param object
   * - No returns
   */
  deleteInstanceOrthographic(object) {

    //GENERATED_DELETE_INSTANCE_ORTHOGRAPHIC_BEFORE_START
    //GENERATED_DELETE_INSTANCE_ORTHOGRAPHIC_BEFORE_END

    //GENERATED_DELETE_INSTANCE_ORTHOGRAPHIC_START
    //GENERATED_DELETE_INSTANCE_ORTHOGRAPHIC_END

    //CUSTOM_DELETE_INSTANCE_ORTHOGRAPHIC_START
    return null;
    //CUSTOM_DELETE_INSTANCE_ORTHOGRAPHIC_END

    //GENERATED_DELETE_INSTANCE_ORTHOGRAPHIC_AFTER_START
    //GENERATED_DELETE_INSTANCE_ORTHOGRAPHIC_AFTER_END

  }

  /**
   * updateInstanceOrthographic()
   * - Three implementation for [R3EventObjComponentGraphicsCameraOrthographic]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceOrthographic(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_ORTHOGRAPHIC_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_ORTHOGRAPHIC_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_ORTHOGRAPHIC_START
    //GENERATED_UPDATE_INSTANCE_ORTHOGRAPHIC_END

    //CUSTOM_UPDATE_INSTANCE_ORTHOGRAPHIC_START
    let instance = object.getInstance(this);

    if (
      property === 'left' ||
      property === 'right' ||
      property === 'top' ||
      property === 'bottom' ||
      property === 'near' ||
      property === 'far'
    ) {
      instance[property] = value;
      instance.updateProjectionMatrix();
      return;
    }

    throw new Error(`Please implement Three.updateInstanceOrthographic() in R3RuntimeGraphicsThree for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_ORTHOGRAPHIC_END

    //GENERATED_UPDATE_INSTANCE_ORTHOGRAPHIC_AFTER_START
    //GENERATED_UPDATE_INSTANCE_ORTHOGRAPHIC_AFTER_END

  }

  /**
   * cloneInstancePerspective()
   * - Three implementation for [R3EventObjComponentGraphicsCameraPerspective]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstancePerspective(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_PERSPECTIVE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_PERSPECTIVE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_PERSPECTIVE_START
    //GENERATED_CLONE_INSTANCE_PERSPECTIVE_END

    //CUSTOM_CLONE_INSTANCE_PERSPECTIVE_START
    throw new Error(`Please implement Three.cloneInstancePerspective(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_PERSPECTIVE_END

    //GENERATED_CLONE_INSTANCE_PERSPECTIVE_AFTER_START
    //GENERATED_CLONE_INSTANCE_PERSPECTIVE_AFTER_END

  }

  /**
   * createInstancePerspective()
   * - Three implementation for [R3EventObjComponentGraphicsCameraPerspective]
   * @param object
   * - No returns
   */
  createInstancePerspective(object) {

    //GENERATED_CREATE_INSTANCE_PERSPECTIVE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_PERSPECTIVE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_PERSPECTIVE_START
    //GENERATED_CREATE_INSTANCE_PERSPECTIVE_END

    //CUSTOM_CREATE_INSTANCE_PERSPECTIVE_START
    let canvas = object.canvas.getInstance(Runtime.KEY_GRAPHICS_THREE);

    if (!canvas) {
      console.error('no canvas!');
    }

    let width = Number(canvas.getAttribute('width'));
    let height = Number(canvas.getAttribute('height'));

    let aspect = width / height;

    if (object.fixedAspect) {
      aspect = object.predefinedAspectRatio;
    }

    let camera = new THREE.PerspectiveCamera(
      object.fov,
      aspect,
      object.near,
      object.far
    );

    camera.position.x = object.position.x;
    camera.position.y = object.position.y;
    camera.position.z = object.position.z;

    return camera;
    //CUSTOM_CREATE_INSTANCE_PERSPECTIVE_END

    //GENERATED_CREATE_INSTANCE_PERSPECTIVE_AFTER_START
    //GENERATED_CREATE_INSTANCE_PERSPECTIVE_AFTER_END

  }

  /**
   * deleteInstancePerspective()
   * - Three implementation for [R3EventObjComponentGraphicsCameraPerspective]
   * @param object
   * - No returns
   */
  deleteInstancePerspective(object) {

    //GENERATED_DELETE_INSTANCE_PERSPECTIVE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_PERSPECTIVE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_PERSPECTIVE_START
    //GENERATED_DELETE_INSTANCE_PERSPECTIVE_END

    //CUSTOM_DELETE_INSTANCE_PERSPECTIVE_START
    // object.getInstance(this).dispose();
    return null;
    //CUSTOM_DELETE_INSTANCE_PERSPECTIVE_END

    //GENERATED_DELETE_INSTANCE_PERSPECTIVE_AFTER_START
    //GENERATED_DELETE_INSTANCE_PERSPECTIVE_AFTER_END

  }

  /**
   * updateInstancePerspective()
   * - Three implementation for [R3EventObjComponentGraphicsCameraPerspective]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstancePerspective(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_PERSPECTIVE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_PERSPECTIVE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_PERSPECTIVE_START
    //GENERATED_UPDATE_INSTANCE_PERSPECTIVE_END

    //CUSTOM_UPDATE_INSTANCE_PERSPECTIVE_START
    let instance = object.getInstance(this);

    if (
      property === 'fov' ||
      property === 'filmGauge' ||
      property === 'filmOffset'
    ) {
      instance[property] = value;
      instance.updateProjectionMatrix();
      return;
    }

    throw new Error(`Please implement Three.updateInstancePerspective() in R3RuntimeGraphicsThree for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_PERSPECTIVE_END

    //GENERATED_UPDATE_INSTANCE_PERSPECTIVE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_PERSPECTIVE_AFTER_END

  }

  /**
   * cloneInstanceCanvasGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsCanvas]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceCanvasGraphics(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_CANVAS_GRAPHICS_BEFORE_START
    //GENERATED_CLONE_INSTANCE_CANVAS_GRAPHICS_BEFORE_END

    //GENERATED_CLONE_INSTANCE_CANVAS_GRAPHICS_START
    //GENERATED_CLONE_INSTANCE_CANVAS_GRAPHICS_END

    //CUSTOM_CLONE_INSTANCE_CANVAS_GRAPHICS_START
    throw new Error(`Please implement Three.cloneInstanceCanvasGraphics(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_CANVAS_GRAPHICS_END

    //GENERATED_CLONE_INSTANCE_CANVAS_GRAPHICS_AFTER_START
    //GENERATED_CLONE_INSTANCE_CANVAS_GRAPHICS_AFTER_END

  }

  /**
   * createInstanceCanvasGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsCanvas]
   * @param object
   * - No returns
   */
  createInstanceCanvasGraphics(object) {

    //GENERATED_CREATE_INSTANCE_CANVAS_GRAPHICS_BEFORE_START
    //GENERATED_CREATE_INSTANCE_CANVAS_GRAPHICS_BEFORE_END

    //GENERATED_CREATE_INSTANCE_CANVAS_GRAPHICS_START
    //GENERATED_CREATE_INSTANCE_CANVAS_GRAPHICS_END

    //CUSTOM_CREATE_INSTANCE_CANVAS_GRAPHICS_START
    let htmlCanvasElement = document.createElement('canvas');

    if (object.fullScreen === true) {
      htmlCanvasElement.setAttribute("style", `height: calc(100vh);width: calc(100vw);`);
      htmlCanvasElement.setAttribute('width', htmlCanvasElement.clientWidth);
      htmlCanvasElement.setAttribute('height', htmlCanvasElement.clientHeight);
    } else {
      htmlCanvasElement.setAttribute('width', object.width);
      htmlCanvasElement.setAttribute('height', object.height);
      htmlCanvasElement.setAttribute("style", `width: ${object.width}px, height: ${object.height}px`);
    }

    // htmlCanvasElement.setAttribute('tabindex', 0);
    htmlCanvasElement.setAttribute('tabindex', object.tabIndex);

    let container = document.getElementById(object.parentDomElementId);
    container.appendChild(htmlCanvasElement);
    return htmlCanvasElement;
    //CUSTOM_CREATE_INSTANCE_CANVAS_GRAPHICS_END

    //GENERATED_CREATE_INSTANCE_CANVAS_GRAPHICS_AFTER_START
    //GENERATED_CREATE_INSTANCE_CANVAS_GRAPHICS_AFTER_END

  }

  /**
   * deleteInstanceCanvasGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsCanvas]
   * @param object
   * - No returns
   */
  deleteInstanceCanvasGraphics(object) {

    //GENERATED_DELETE_INSTANCE_CANVAS_GRAPHICS_BEFORE_START
    //GENERATED_DELETE_INSTANCE_CANVAS_GRAPHICS_BEFORE_END

    //GENERATED_DELETE_INSTANCE_CANVAS_GRAPHICS_START
    //GENERATED_DELETE_INSTANCE_CANVAS_GRAPHICS_END

    //CUSTOM_DELETE_INSTANCE_CANVAS_GRAPHICS_START
    let container = document.getElementById(object.parentDomElementId);
    let instance = object.getInstance(this);
    if (container && instance) {
      try {
        container.removeChild(instance);
      } catch (error) {
        console.warn(error.message);
      }
    }
    return null;
    //CUSTOM_DELETE_INSTANCE_CANVAS_GRAPHICS_END

    //GENERATED_DELETE_INSTANCE_CANVAS_GRAPHICS_AFTER_START
    //GENERATED_DELETE_INSTANCE_CANVAS_GRAPHICS_AFTER_END

  }

  /**
   * updateInstanceCanvasGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsCanvas]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceCanvasGraphics(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_CANVAS_GRAPHICS_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_CANVAS_GRAPHICS_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_CANVAS_GRAPHICS_START
    //GENERATED_UPDATE_INSTANCE_CANVAS_GRAPHICS_END

    //CUSTOM_UPDATE_INSTANCE_CANVAS_GRAPHICS_START
    let instance = object.getInstance(this);

    if (
      property === 'width' ||
      property === 'height' ||
      property === 'fullScreen'
    ) {

      if (object.fullScreen === true) {
        instance.setAttribute("style", `height: calc(100%);width: calc(100%);`);
        instance.setAttribute('width', instance.clientWidth);
        instance.setAttribute('height', instance.clientHeight);
      } else {
        instance.setAttribute('width',  object.width);
        instance.setAttribute('height', object.height);
        instance.setAttribute("style", `width: ${object.width}px, height: ${object.height}px`);
      }

      object['_cache'].width  = instance.clientWidth;
      object['_cache'].height  = instance.clientHeight;

      return;
    }

    if (
      property === 'tabIndex'
    ) {
      instance.setAttribute('tabIndex', value);
      return;
    }

    if (property === 'parentDomElementId') {
      let dom = document.getElementById(object.parentDomElementId);
      if (!dom.contains(instance)) {
        dom.appendChild(instance);
      }
      return;
    }

    console.log(`Please implement canvas property update for ${property}`);

    // instance.setAttribute(property, value);
    //CUSTOM_UPDATE_INSTANCE_CANVAS_GRAPHICS_END

    //GENERATED_UPDATE_INSTANCE_CANVAS_GRAPHICS_AFTER_START
    //GENERATED_UPDATE_INSTANCE_CANVAS_GRAPHICS_AFTER_END

  }

  /**
   * cloneInstanceFog()
   * - Three implementation for [R3EventObjComponentGraphicsFog]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceFog(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_FOG_BEFORE_START
    //GENERATED_CLONE_INSTANCE_FOG_BEFORE_END

    //GENERATED_CLONE_INSTANCE_FOG_START
    //GENERATED_CLONE_INSTANCE_FOG_END

    //CUSTOM_CLONE_INSTANCE_FOG_START
    throw new Error(`Please implement Three.cloneInstanceFog(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_FOG_END

    //GENERATED_CLONE_INSTANCE_FOG_AFTER_START
    //GENERATED_CLONE_INSTANCE_FOG_AFTER_END

  }

  /**
   * createInstanceFog()
   * - Three implementation for [R3EventObjComponentGraphicsFog]
   * @param object
   * - No returns
   */
  createInstanceFog(object) {

    //GENERATED_CREATE_INSTANCE_FOG_BEFORE_START
    //GENERATED_CREATE_INSTANCE_FOG_BEFORE_END

    //GENERATED_CREATE_INSTANCE_FOG_START
    //GENERATED_CREATE_INSTANCE_FOG_END

    //CUSTOM_CREATE_INSTANCE_FOG_START
    let color = new THREE.Color();

    color.setHex(object.color.getHex());

    let instance = new THREE.Fog(
      color,
      object.near,
      object.far
    );

    let scene = object.scene.getInstance(this);
    scene.fog = instance;

    return instance;
    //CUSTOM_CREATE_INSTANCE_FOG_END

    //GENERATED_CREATE_INSTANCE_FOG_AFTER_START
    //GENERATED_CREATE_INSTANCE_FOG_AFTER_END

  }

  /**
   * deleteInstanceFog()
   * - Three implementation for [R3EventObjComponentGraphicsFog]
   * @param object
   * - No returns
   */
  deleteInstanceFog(object) {

    //GENERATED_DELETE_INSTANCE_FOG_BEFORE_START
    //GENERATED_DELETE_INSTANCE_FOG_BEFORE_END

    //GENERATED_DELETE_INSTANCE_FOG_START
    //GENERATED_DELETE_INSTANCE_FOG_END

    //CUSTOM_DELETE_INSTANCE_FOG_START
    return null;
    //CUSTOM_DELETE_INSTANCE_FOG_END

    //GENERATED_DELETE_INSTANCE_FOG_AFTER_START
    //GENERATED_DELETE_INSTANCE_FOG_AFTER_END

  }

  /**
   * updateInstanceFog()
   * - Three implementation for [R3EventObjComponentGraphicsFog]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceFog(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_FOG_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_FOG_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_FOG_START
    //GENERATED_UPDATE_INSTANCE_FOG_END

    //CUSTOM_UPDATE_INSTANCE_FOG_START
    let instance = object.getInstance(this);
    if (
      property === 'near' ||
      property === 'far'
    ) {
      instance[property] = value;
      return;
    }

    if (
      property === 'color'
    ) {
      instance[property].setHex(object.color.getHex());
      return;
    }

    if (property === 'scene') {

      if  (value === null) {
        //TODO : Before Property remove from
        console.log('TODO - remove scene from fog');
      } else {
        let scene = object.scene.getInstance(this);
        scene.fog = instance;
      }

      return;
    }

    throw new Error(`Please implement Three.updateInstanceFog() in R3RuntimeGraphicsThree for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_FOG_END

    //GENERATED_UPDATE_INSTANCE_FOG_AFTER_START
    //GENERATED_UPDATE_INSTANCE_FOG_AFTER_END

  }

  /**
   * cloneInstanceBox()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryBox]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceBox(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_BOX_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BOX_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BOX_START
    //GENERATED_CLONE_INSTANCE_BOX_END

    //CUSTOM_CLONE_INSTANCE_BOX_START
    throw new Error(`Please implement Three.cloneInstanceBox(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_BOX_END

    //GENERATED_CLONE_INSTANCE_BOX_AFTER_START
    //GENERATED_CLONE_INSTANCE_BOX_AFTER_END

  }

  /**
   * createInstanceBox()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryBox]
   * @param object
   * - No returns
   */
  createInstanceBox(object) {

    //GENERATED_CREATE_INSTANCE_BOX_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BOX_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BOX_START
    //GENERATED_CREATE_INSTANCE_BOX_END

    //CUSTOM_CREATE_INSTANCE_BOX_START
    return new THREE.BoxGeometry(
      object.width,
      object.height,
      object.depth,
      object.widthSegments,
      object.heightSegments,
      object.depthSegments
    );
    // box.graphicsInstance.setAttribute( 'position', new THREE.Float32BufferAttribute( [], 3 ) );

    //CUSTOM_CREATE_INSTANCE_BOX_END

    //GENERATED_CREATE_INSTANCE_BOX_AFTER_START
    //GENERATED_CREATE_INSTANCE_BOX_AFTER_END

  }

  /**
   * deleteInstanceBox()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryBox]
   * @param object
   * - No returns
   */
  deleteInstanceBox(object) {

    //GENERATED_DELETE_INSTANCE_BOX_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BOX_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BOX_START
    //GENERATED_DELETE_INSTANCE_BOX_END

    //CUSTOM_DELETE_INSTANCE_BOX_START
    object.getInstance(this).dispose();
    return null;
    //CUSTOM_DELETE_INSTANCE_BOX_END

    //GENERATED_DELETE_INSTANCE_BOX_AFTER_START
    //GENERATED_DELETE_INSTANCE_BOX_AFTER_END

  }

  /**
   * updateInstanceBox()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryBox]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceBox(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_BOX_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BOX_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BOX_START
    //GENERATED_UPDATE_INSTANCE_BOX_END

    //CUSTOM_UPDATE_INSTANCE_BOX_START
    if (
      property === 'widthSegments' ||
      property === 'heightSegments' ||
      property === 'depthSegments' ||
      property === 'width' ||
      property === 'height' ||
      property === 'depth'
    ) {
      let instance = object.getInstance(this);
      let newInstance = this.createInstance(object);
      object.instances[Runtime.KEY_GRAPHICS_THREE].object = newInstance;
      instance.dispose();
      return;
    }

    throw new Error(`Please implement Three.updateInstanceBox() for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_BOX_END

    //GENERATED_UPDATE_INSTANCE_BOX_AFTER_START
    //GENERATED_UPDATE_INSTANCE_BOX_AFTER_END

  }

  /**
   * cloneInstanceBuffer()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryBuffer]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceBuffer(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_BUFFER_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BUFFER_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BUFFER_START
    //GENERATED_CLONE_INSTANCE_BUFFER_END

    //CUSTOM_CLONE_INSTANCE_BUFFER_START
    throw new Error(`Please implement Three.cloneInstanceBuffer(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_BUFFER_END

    //GENERATED_CLONE_INSTANCE_BUFFER_AFTER_START
    //GENERATED_CLONE_INSTANCE_BUFFER_AFTER_END

  }

  /**
   * createInstanceBuffer()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryBuffer]
   * @param object
   * - No returns
   */
  createInstanceBuffer(object) {

    //GENERATED_CREATE_INSTANCE_BUFFER_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BUFFER_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BUFFER_START
    //GENERATED_CREATE_INSTANCE_BUFFER_END

    //CUSTOM_CREATE_INSTANCE_BUFFER_START
    let geometry = new THREE.BufferGeometry();

    // let vertices = new Float32Array(
    //   object.vertices.reduce(
    //     (result, vertex) => {
    //       result = [...result, ...vertex.getInstance(Runtime.KEY_MATH_THREE)];
    //       return result;
    //     },
    //     []
    //   )
    // );

    let vertices = [];
    let normals = [];
    let uvs = [];

    object.faces.map(
      function (face) {

        vertices.push(object.vertices[face.v0].position.x);
        vertices.push(object.vertices[face.v0].position.y);
        vertices.push(object.vertices[face.v0].position.z);
        vertices.push(object.vertices[face.v1].position.x);
        vertices.push(object.vertices[face.v1].position.y);
        vertices.push(object.vertices[face.v1].position.z);
        vertices.push(object.vertices[face.v2].position.x);
        vertices.push(object.vertices[face.v2].position.y);
        vertices.push(object.vertices[face.v2].position.z);

        normals.push(object.vertices[face.v0].normal.x);
        normals.push(object.vertices[face.v0].normal.y);
        normals.push(object.vertices[face.v0].normal.z);
        normals.push(object.vertices[face.v1].normal.x);
        normals.push(object.vertices[face.v1].normal.y);
        normals.push(object.vertices[face.v1].normal.z);
        normals.push(object.vertices[face.v2].normal.x);
        normals.push(object.vertices[face.v2].normal.y);
        normals.push(object.vertices[face.v2].normal.z);

        for  (let uv of face.uvs) {
          uv.vertices.map(
            (vertex) => {
              uvs.push(vertex.x);
              uvs.push(vertex.y);
            }
          );
        }
      }
    )


    // let indices = object.faces.reduce(
    //   (result, face) => {
    //     result = [...result, ...face.getInstance(Runtime.KEY_MATH_THREE)];
    //     return result;
    //   },
    //   []
    // );


    // geometry.setIndex(indices);
    geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3));
    geometry.setAttribute('uv', new THREE.Float32BufferAttribute(new Float32Array(uvs), 2));
    geometry.setAttribute('normal', new THREE.BufferAttribute(new Float32Array(normals), 3));

    object.vertexCount = vertices.length;

    if (object.groups && object.groups.length) {
      geometry.clearGroups();
      for (let item of object.groups) {
        let groupInstance = item.getInstance(this);
        let {start, count, materialIndex} = groupInstance;
        geometry.addGroup(start, count, materialIndex);
      }
      geometry.setDrawRange(0, object.vertexCount);
    }

    // geometry.normalizeNormals();
    // geometry.computeVertexNormals();

    return geometry;
    //CUSTOM_CREATE_INSTANCE_BUFFER_END

    //GENERATED_CREATE_INSTANCE_BUFFER_AFTER_START
    //GENERATED_CREATE_INSTANCE_BUFFER_AFTER_END

  }

  /**
   * deleteInstanceBuffer()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryBuffer]
   * @param object
   * - No returns
   */
  deleteInstanceBuffer(object) {

    //GENERATED_DELETE_INSTANCE_BUFFER_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BUFFER_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BUFFER_START
    //GENERATED_DELETE_INSTANCE_BUFFER_END

    //CUSTOM_DELETE_INSTANCE_BUFFER_START
    let instance = object.getInstance(this);
    instance.dispose();
    return null;
    //CUSTOM_DELETE_INSTANCE_BUFFER_END

    //GENERATED_DELETE_INSTANCE_BUFFER_AFTER_START
    //GENERATED_DELETE_INSTANCE_BUFFER_AFTER_END

  }

  /**
   * updateInstanceBuffer()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryBuffer]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceBuffer(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_BUFFER_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BUFFER_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BUFFER_START
    //GENERATED_UPDATE_INSTANCE_BUFFER_END

    //CUSTOM_UPDATE_INSTANCE_BUFFER_START
    if (property === 'vertexCount') {
      /**
       * Do nothing - this is just for display purposes
       */
      return;
    }

    if (property === 'groups') {

      let instance = object.getInstance(this);

      instance.clearGroups();

      if (value && value.length) {
        for (let item of value) {
          let groupInstance = item.getInstance(this);
          let {start, count, materialIndex} = groupInstance;
          instance.addGroup(start, count, materialIndex);
        }
      }

      instance.setDrawRange(0, object.vertexCount);

      return;
    }

    throw new Error(`Please implement Three.updateInstanceBuffer() in R3RuntimeGraphicsThree for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_BUFFER_END

    //GENERATED_UPDATE_INSTANCE_BUFFER_AFTER_START
    //GENERATED_UPDATE_INSTANCE_BUFFER_AFTER_END

  }

  /**
   * cloneInstancePlaneGeometry()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryPlane]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstancePlaneGeometry(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_PLANE_GEOMETRY_BEFORE_START
    //GENERATED_CLONE_INSTANCE_PLANE_GEOMETRY_BEFORE_END

    //GENERATED_CLONE_INSTANCE_PLANE_GEOMETRY_START
    //GENERATED_CLONE_INSTANCE_PLANE_GEOMETRY_END

    //CUSTOM_CLONE_INSTANCE_PLANE_GEOMETRY_START
    throw new Error(`Please implement Three.cloneInstancePlaneGeometry(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_PLANE_GEOMETRY_END

    //GENERATED_CLONE_INSTANCE_PLANE_GEOMETRY_AFTER_START
    //GENERATED_CLONE_INSTANCE_PLANE_GEOMETRY_AFTER_END

  }

  /**
   * createInstancePlaneGeometry()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryPlane]
   * @param object
   * - No returns
   */
  createInstancePlaneGeometry(object) {

    //GENERATED_CREATE_INSTANCE_PLANE_GEOMETRY_BEFORE_START
    //GENERATED_CREATE_INSTANCE_PLANE_GEOMETRY_BEFORE_END

    //GENERATED_CREATE_INSTANCE_PLANE_GEOMETRY_START
    //GENERATED_CREATE_INSTANCE_PLANE_GEOMETRY_END

    //CUSTOM_CREATE_INSTANCE_PLANE_GEOMETRY_START
    //CUSTOM_CREATE_INSTANCE_BOX_START
    return new THREE.PlaneGeometry(
      object.width,
      object.height,
      object.widthSegments,
      object.heightSegments
    );
    //CUSTOM_CREATE_INSTANCE_PLANE_GEOMETRY_END

    //GENERATED_CREATE_INSTANCE_PLANE_GEOMETRY_AFTER_START
    //GENERATED_CREATE_INSTANCE_PLANE_GEOMETRY_AFTER_END

  }

  /**
   * deleteInstancePlaneGeometry()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryPlane]
   * @param object
   * - No returns
   */
  deleteInstancePlaneGeometry(object) {

    //GENERATED_DELETE_INSTANCE_PLANE_GEOMETRY_BEFORE_START
    //GENERATED_DELETE_INSTANCE_PLANE_GEOMETRY_BEFORE_END

    //GENERATED_DELETE_INSTANCE_PLANE_GEOMETRY_START
    //GENERATED_DELETE_INSTANCE_PLANE_GEOMETRY_END

    //CUSTOM_DELETE_INSTANCE_PLANE_GEOMETRY_START
    object.getInstance(this).dispose();
    return null;
    //CUSTOM_DELETE_INSTANCE_PLANE_GEOMETRY_END

    //GENERATED_DELETE_INSTANCE_PLANE_GEOMETRY_AFTER_START
    //GENERATED_DELETE_INSTANCE_PLANE_GEOMETRY_AFTER_END

  }

  /**
   * updateInstancePlaneGeometry()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryPlane]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstancePlaneGeometry(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_PLANE_GEOMETRY_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_PLANE_GEOMETRY_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_PLANE_GEOMETRY_START
    //GENERATED_UPDATE_INSTANCE_PLANE_GEOMETRY_END

    //CUSTOM_UPDATE_INSTANCE_PLANE_GEOMETRY_START
    if (
      property === 'widthSegments' ||
      property === 'heightSegments' ||
      property === 'width' ||
      property === 'height'
    ) {
      let instance = object.getInstance(this);
      let newInstance = this.createInstance(object);
      object.instances[Runtime.KEY_GRAPHICS_THREE].object = newInstance;
      instance.dispose();
      return;
    }

    throw new Error(`Please implement Three.updateInstancePlaneGeometry() for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_PLANE_GEOMETRY_END

    //GENERATED_UPDATE_INSTANCE_PLANE_GEOMETRY_AFTER_START
    //GENERATED_UPDATE_INSTANCE_PLANE_GEOMETRY_AFTER_END

  }

  /**
   * cloneInstanceSphereGeometry()
   * - Three implementation for [R3EventObjComponentGraphicsGeometrySphere]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceSphereGeometry(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_SPHERE_GEOMETRY_BEFORE_START
    //GENERATED_CLONE_INSTANCE_SPHERE_GEOMETRY_BEFORE_END

    //GENERATED_CLONE_INSTANCE_SPHERE_GEOMETRY_START
    //GENERATED_CLONE_INSTANCE_SPHERE_GEOMETRY_END

    //CUSTOM_CLONE_INSTANCE_SPHERE_GEOMETRY_START
    throw new Error(`Please implement Three.cloneInstanceSphereGeometry(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_SPHERE_GEOMETRY_END

    //GENERATED_CLONE_INSTANCE_SPHERE_GEOMETRY_AFTER_START
    //GENERATED_CLONE_INSTANCE_SPHERE_GEOMETRY_AFTER_END

  }

  /**
   * createInstanceSphereGeometry()
   * - Three implementation for [R3EventObjComponentGraphicsGeometrySphere]
   * @param object
   * - No returns
   */
  createInstanceSphereGeometry(object) {

    //GENERATED_CREATE_INSTANCE_SPHERE_GEOMETRY_BEFORE_START
    //GENERATED_CREATE_INSTANCE_SPHERE_GEOMETRY_BEFORE_END

    //GENERATED_CREATE_INSTANCE_SPHERE_GEOMETRY_START
    //GENERATED_CREATE_INSTANCE_SPHERE_GEOMETRY_END

    //CUSTOM_CREATE_INSTANCE_SPHERE_GEOMETRY_START
    return new THREE.SphereGeometry(
      object.radius,
      object.widthSegments,
      object.heightSegments
    );
    //CUSTOM_CREATE_INSTANCE_SPHERE_GEOMETRY_END

    //GENERATED_CREATE_INSTANCE_SPHERE_GEOMETRY_AFTER_START
    //GENERATED_CREATE_INSTANCE_SPHERE_GEOMETRY_AFTER_END

  }

  /**
   * deleteInstanceSphereGeometry()
   * - Three implementation for [R3EventObjComponentGraphicsGeometrySphere]
   * @param object
   * - No returns
   */
  deleteInstanceSphereGeometry(object) {

    //GENERATED_DELETE_INSTANCE_SPHERE_GEOMETRY_BEFORE_START
    //GENERATED_DELETE_INSTANCE_SPHERE_GEOMETRY_BEFORE_END

    //GENERATED_DELETE_INSTANCE_SPHERE_GEOMETRY_START
    //GENERATED_DELETE_INSTANCE_SPHERE_GEOMETRY_END

    //CUSTOM_DELETE_INSTANCE_SPHERE_GEOMETRY_START
    let instance = object.getInstance(this);
    instance.dispose();
    return null;
    // throw new Error(`Please implement Three.deleteSphereGeometry(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_SPHERE_GEOMETRY_END

    //GENERATED_DELETE_INSTANCE_SPHERE_GEOMETRY_AFTER_START
    //GENERATED_DELETE_INSTANCE_SPHERE_GEOMETRY_AFTER_END

  }

  /**
   * updateInstanceSphereGeometry()
   * - Three implementation for [R3EventObjComponentGraphicsGeometrySphere]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceSphereGeometry(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_SPHERE_GEOMETRY_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_SPHERE_GEOMETRY_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_SPHERE_GEOMETRY_START
    //GENERATED_UPDATE_INSTANCE_SPHERE_GEOMETRY_END

    //CUSTOM_UPDATE_INSTANCE_SPHERE_GEOMETRY_START
    if (
      property === 'widthSegments' ||
      property === 'heightSegments' ||
      property === 'radius'
    ) {
      /**
       * No-op
       */
      let instance = object.getInstance(this);
      let newInstance = this.createInstance(object);
      object.instances[Runtime.KEY_GRAPHICS_THREE].object = newInstance;
      instance.dispose();
      return;
    }

    throw new Error(`Please implement Three.updateInstanceSphereGeometry() for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_SPHERE_GEOMETRY_END

    //GENERATED_UPDATE_INSTANCE_SPHERE_GEOMETRY_AFTER_START
    //GENERATED_UPDATE_INSTANCE_SPHERE_GEOMETRY_AFTER_END

  }

  /**
   * cloneInstanceTorus()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryTorus]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceTorus(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_TORUS_BEFORE_START
    //GENERATED_CLONE_INSTANCE_TORUS_BEFORE_END

    //GENERATED_CLONE_INSTANCE_TORUS_START
    //GENERATED_CLONE_INSTANCE_TORUS_END

    //CUSTOM_CLONE_INSTANCE_TORUS_START
    throw new Error(`Please implement Three.cloneInstanceTorus(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_TORUS_END

    //GENERATED_CLONE_INSTANCE_TORUS_AFTER_START
    //GENERATED_CLONE_INSTANCE_TORUS_AFTER_END

  }

  /**
   * createInstanceTorus()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryTorus]
   * @param object
   * - No returns
   */
  createInstanceTorus(object) {

    //GENERATED_CREATE_INSTANCE_TORUS_BEFORE_START
    //GENERATED_CREATE_INSTANCE_TORUS_BEFORE_END

    //GENERATED_CREATE_INSTANCE_TORUS_START
    //GENERATED_CREATE_INSTANCE_TORUS_END

    //CUSTOM_CREATE_INSTANCE_TORUS_START
    throw new Error(`Please implement Three.createGraphicsTorus(torus, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_TORUS_END

    //GENERATED_CREATE_INSTANCE_TORUS_AFTER_START
    //GENERATED_CREATE_INSTANCE_TORUS_AFTER_END

  }

  /**
   * deleteInstanceTorus()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryTorus]
   * @param object
   * - No returns
   */
  deleteInstanceTorus(object) {

    //GENERATED_DELETE_INSTANCE_TORUS_BEFORE_START
    //GENERATED_DELETE_INSTANCE_TORUS_BEFORE_END

    //GENERATED_DELETE_INSTANCE_TORUS_START
    //GENERATED_DELETE_INSTANCE_TORUS_END

    //CUSTOM_DELETE_INSTANCE_TORUS_START
    throw new Error(`Please implement Three.deleteGraphicsTorus(torus, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_TORUS_END

    //GENERATED_DELETE_INSTANCE_TORUS_AFTER_START
    //GENERATED_DELETE_INSTANCE_TORUS_AFTER_END

  }

  /**
   * updateInstanceTorus()
   * - Three implementation for [R3EventObjComponentGraphicsGeometryTorus]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceTorus(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_TORUS_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_TORUS_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_TORUS_START
    //GENERATED_UPDATE_INSTANCE_TORUS_END

    //CUSTOM_UPDATE_INSTANCE_TORUS_START
    throw new Error(`Please implement Three.updateInstanceTorus(object, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_TORUS_END

    //GENERATED_UPDATE_INSTANCE_TORUS_AFTER_START
    //GENERATED_UPDATE_INSTANCE_TORUS_AFTER_END

  }

  /**
   * cloneInstanceImageGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsImage]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceImageGraphics(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_IMAGE_GRAPHICS_BEFORE_START
    //GENERATED_CLONE_INSTANCE_IMAGE_GRAPHICS_BEFORE_END

    //GENERATED_CLONE_INSTANCE_IMAGE_GRAPHICS_START
    //GENERATED_CLONE_INSTANCE_IMAGE_GRAPHICS_END

    //CUSTOM_CLONE_INSTANCE_IMAGE_GRAPHICS_START
    throw new Error(`Please implement Three.cloneInstanceImageGraphics(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_IMAGE_GRAPHICS_END

    //GENERATED_CLONE_INSTANCE_IMAGE_GRAPHICS_AFTER_START
    //GENERATED_CLONE_INSTANCE_IMAGE_GRAPHICS_AFTER_END

  }

  /**
   * createInstanceImageGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsImage]
   * @param object
   * - No returns
   */
  createInstanceImageGraphics(object) {

    //GENERATED_CREATE_INSTANCE_IMAGE_GRAPHICS_BEFORE_START
    //GENERATED_CREATE_INSTANCE_IMAGE_GRAPHICS_BEFORE_END

    //GENERATED_CREATE_INSTANCE_IMAGE_GRAPHICS_START
    //GENERATED_CREATE_INSTANCE_IMAGE_GRAPHICS_END

    //CUSTOM_CREATE_INSTANCE_IMAGE_GRAPHICS_START
    if (object.data && object.data instanceof Buffer) {
      object.base64Data = object.data.toString('base64');
    }

    if (object.base64Data) {
      let image = document.createElement('img');
      image.src = 'data:image/png;base64,' + object.base64Data;
      object.width = image.width;
      object.height = image.height;
      return image;
    }

    if (object.urlPath) {

      if (!this.apiUrl) {
        throw new Error(`No API URL set at time for creating a texture instance of ${object.name}`);
      }

      return new Promise(
        (resolve, reject) => {

          const loader = new THREE.ImageLoader();

          let url = `${this.apiUrl}${object.urlPath}`;

          loader.load(
            url,
            // onLoad
            function (image) {
              object.setInstance(this, image);
              object.width = image.width;
              object.height = image.height;
              Event.Emit(
                Event.IMAGE_LOADED,
                {
                  image : object
                }
              );
              resolve(image);
            }.bind(this),

            // onProgress callback not supported
            undefined,

            // onError callback
            function (error) {
              reject(error);
            }
          );
        }
      );
    }

    throw new Error(`Invalid attempt to construct an image`);
    //CUSTOM_CREATE_INSTANCE_IMAGE_GRAPHICS_END

    //GENERATED_CREATE_INSTANCE_IMAGE_GRAPHICS_AFTER_START
    //GENERATED_CREATE_INSTANCE_IMAGE_GRAPHICS_AFTER_END

  }

  /**
   * deleteInstanceImageGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsImage]
   * @param object
   * - No returns
   */
  deleteInstanceImageGraphics(object) {

    //GENERATED_DELETE_INSTANCE_IMAGE_GRAPHICS_BEFORE_START
    //GENERATED_DELETE_INSTANCE_IMAGE_GRAPHICS_BEFORE_END

    //GENERATED_DELETE_INSTANCE_IMAGE_GRAPHICS_START
    //GENERATED_DELETE_INSTANCE_IMAGE_GRAPHICS_END

    //CUSTOM_DELETE_INSTANCE_IMAGE_GRAPHICS_START
    return null;
    //CUSTOM_DELETE_INSTANCE_IMAGE_GRAPHICS_END

    //GENERATED_DELETE_INSTANCE_IMAGE_GRAPHICS_AFTER_START
    //GENERATED_DELETE_INSTANCE_IMAGE_GRAPHICS_AFTER_END

  }

  /**
   * updateInstanceImageGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsImage]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceImageGraphics(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_IMAGE_GRAPHICS_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_IMAGE_GRAPHICS_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_IMAGE_GRAPHICS_START
    //GENERATED_UPDATE_INSTANCE_IMAGE_GRAPHICS_END

    //CUSTOM_UPDATE_INSTANCE_IMAGE_GRAPHICS_START
    if (property === 'urlPath') {
      // if (value !== object.url) {
        this.createInstanceImageGraphics(object).then(
          (image) => {
            object.setInstance(this, image);
          }
        )
      // }
      return;
    }

    if (
      property === 'width' ||
      property === 'height' ||
      property === 'data'
    ) {

      let instance;

      if (property === 'data' && value) {
        instance = this.createInstanceImageGraphics(object);
        object.setInstance(this, instance);
      }

      return;
    }

    if (
      property === 'size' ||
      property === 'contentType'
    ) {
      return;
    }

    throw new Error(`Please implement Three.updateInstanceImageGraphics() for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_IMAGE_GRAPHICS_END

    //GENERATED_UPDATE_INSTANCE_IMAGE_GRAPHICS_AFTER_START
    //GENERATED_UPDATE_INSTANCE_IMAGE_GRAPHICS_AFTER_END

  }

  /**
   * cloneInstanceAmbient()
   * - Three implementation for [R3EventObjComponentGraphicsLightAmbient]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceAmbient(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_AMBIENT_BEFORE_START
    //GENERATED_CLONE_INSTANCE_AMBIENT_BEFORE_END

    //GENERATED_CLONE_INSTANCE_AMBIENT_START
    //GENERATED_CLONE_INSTANCE_AMBIENT_END

    //CUSTOM_CLONE_INSTANCE_AMBIENT_START
    throw new Error(`Please implement Three.cloneInstanceAmbient(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_AMBIENT_END

    //GENERATED_CLONE_INSTANCE_AMBIENT_AFTER_START
    //GENERATED_CLONE_INSTANCE_AMBIENT_AFTER_END

  }

  /**
   * createInstanceAmbient()
   * - Three implementation for [R3EventObjComponentGraphicsLightAmbient]
   * @param object
   * - No returns
   */
  createInstanceAmbient(object) {

    //GENERATED_CREATE_INSTANCE_AMBIENT_BEFORE_START
    //GENERATED_CREATE_INSTANCE_AMBIENT_BEFORE_END

    //GENERATED_CREATE_INSTANCE_AMBIENT_START
    //GENERATED_CREATE_INSTANCE_AMBIENT_END

    //CUSTOM_CREATE_INSTANCE_AMBIENT_START
    const instance = new THREE.AmbientLight(object.color, object.intensity);
    let scene = object.scene.getInstance(this);
    scene.add(instance);
    return instance;
    //CUSTOM_CREATE_INSTANCE_AMBIENT_END

    //GENERATED_CREATE_INSTANCE_AMBIENT_AFTER_START
    //GENERATED_CREATE_INSTANCE_AMBIENT_AFTER_END

  }

  /**
   * deleteInstanceAmbient()
   * - Three implementation for [R3EventObjComponentGraphicsLightAmbient]
   * @param object
   * - No returns
   */
  deleteInstanceAmbient(object) {

    //GENERATED_DELETE_INSTANCE_AMBIENT_BEFORE_START
    //GENERATED_DELETE_INSTANCE_AMBIENT_BEFORE_END

    //GENERATED_DELETE_INSTANCE_AMBIENT_START
    //GENERATED_DELETE_INSTANCE_AMBIENT_END

    //CUSTOM_DELETE_INSTANCE_AMBIENT_START
    let instance = object.getInstance(this);
    let scene = null;

    if (object.scene) {
      scene = object.scene.getInstance(this);
    }

    if (scene) {
      scene.remove(instance);
    }

    instance.dispose;
    //CUSTOM_DELETE_INSTANCE_AMBIENT_END

    //GENERATED_DELETE_INSTANCE_AMBIENT_AFTER_START
    //GENERATED_DELETE_INSTANCE_AMBIENT_AFTER_END

  }

  /**
   * updateInstanceAmbient()
   * - Three implementation for [R3EventObjComponentGraphicsLightAmbient]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceAmbient(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_AMBIENT_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_AMBIENT_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_AMBIENT_START
    //GENERATED_UPDATE_INSTANCE_AMBIENT_END

    //CUSTOM_UPDATE_INSTANCE_AMBIENT_START
    let instance = object.getInstance(this);
    if (property === 'color') {
      let hex = value.getHex();
      instance.color.setHex(hex);
      instance.intensity = value.a;
      return;
    }

    instance[property] = value;
    //CUSTOM_UPDATE_INSTANCE_AMBIENT_END

    //GENERATED_UPDATE_INSTANCE_AMBIENT_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AMBIENT_AFTER_END

  }

  /**
   * cloneInstanceDirectionalLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightDirectional]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceDirectionalLight(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_DIRECTIONAL_LIGHT_BEFORE_START
    //GENERATED_CLONE_INSTANCE_DIRECTIONAL_LIGHT_BEFORE_END

    //GENERATED_CLONE_INSTANCE_DIRECTIONAL_LIGHT_START
    //GENERATED_CLONE_INSTANCE_DIRECTIONAL_LIGHT_END

    //CUSTOM_CLONE_INSTANCE_DIRECTIONAL_LIGHT_START
    throw new Error(`Please implement Three.cloneInstanceDirectionalLight(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_DIRECTIONAL_LIGHT_END

    //GENERATED_CLONE_INSTANCE_DIRECTIONAL_LIGHT_AFTER_START
    //GENERATED_CLONE_INSTANCE_DIRECTIONAL_LIGHT_AFTER_END

  }

  /**
   * createInstanceDirectionalLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightDirectional]
   * @param object
   * - No returns
   */
  createInstanceDirectionalLight(object) {

    //GENERATED_CREATE_INSTANCE_DIRECTIONAL_LIGHT_BEFORE_START
    //GENERATED_CREATE_INSTANCE_DIRECTIONAL_LIGHT_BEFORE_END

    //GENERATED_CREATE_INSTANCE_DIRECTIONAL_LIGHT_START
    //GENERATED_CREATE_INSTANCE_DIRECTIONAL_LIGHT_END

    //CUSTOM_CREATE_INSTANCE_DIRECTIONAL_LIGHT_START
    let hex = object.color.getHex();

    let intensity = object.color.a;

    let color = new THREE.Color();
    color.setHex(hex);

    const instance = new THREE.DirectionalLight(color, intensity);

    let target = object.target.getInstance(this);
    if (target) {
      instance.target = target;
      instance.position.x = object.position.x;
      instance.position.y = object.position.y;
      instance.position.z = object.position.z;
    }

    let scene = object.scene.getInstance(this);
    if (scene) {
      scene.add(instance);
    }
    
    return instance;
    //CUSTOM_CREATE_INSTANCE_DIRECTIONAL_LIGHT_END

    //GENERATED_CREATE_INSTANCE_DIRECTIONAL_LIGHT_AFTER_START
    //GENERATED_CREATE_INSTANCE_DIRECTIONAL_LIGHT_AFTER_END

  }

  /**
   * deleteInstanceDirectionalLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightDirectional]
   * @param object
   * - No returns
   */
  deleteInstanceDirectionalLight(object) {

    //GENERATED_DELETE_INSTANCE_DIRECTIONAL_LIGHT_BEFORE_START
    //GENERATED_DELETE_INSTANCE_DIRECTIONAL_LIGHT_BEFORE_END

    //GENERATED_DELETE_INSTANCE_DIRECTIONAL_LIGHT_START
    //GENERATED_DELETE_INSTANCE_DIRECTIONAL_LIGHT_END

    //CUSTOM_DELETE_INSTANCE_DIRECTIONAL_LIGHT_START
    Event.Emit(
      Event.DO_NOT_RENDER
    );

    let instance = object.getInstance(this);

    let scene = null;

    if (object.scene) {
      scene = object.scene.getInstance(this);
    }

    if (scene) {
      scene.remove(instance);
    }

    instance.dispose();

    Event.Emit(
      Event.RENDER_OK
    );

    return null;
    //CUSTOM_DELETE_INSTANCE_DIRECTIONAL_LIGHT_END

    //GENERATED_DELETE_INSTANCE_DIRECTIONAL_LIGHT_AFTER_START
    //GENERATED_DELETE_INSTANCE_DIRECTIONAL_LIGHT_AFTER_END

  }

  /**
   * updateInstanceDirectionalLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightDirectional]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceDirectionalLight(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_DIRECTIONAL_LIGHT_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_DIRECTIONAL_LIGHT_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_DIRECTIONAL_LIGHT_START
    //GENERATED_UPDATE_INSTANCE_DIRECTIONAL_LIGHT_END

    //CUSTOM_UPDATE_INSTANCE_DIRECTIONAL_LIGHT_START
    let instance = object.getInstance(this);

    if (property === 'position') {
      instance.position.x = object.position.x;
      instance.position.y = object.position.y;
      instance.position.z = object.position.z;
      return;
    }

    if (property === 'target') {
      instance.target = object.target.getInstance(this);
      return;
    }

    if (property === 'color') {
      let hex = value.getHex();
      instance.color.setHex(hex);
      instance.intensity = value.a;
      return;
    }

    instance[property] = value;
    //CUSTOM_UPDATE_INSTANCE_DIRECTIONAL_LIGHT_END

    //GENERATED_UPDATE_INSTANCE_DIRECTIONAL_LIGHT_AFTER_START
    //GENERATED_UPDATE_INSTANCE_DIRECTIONAL_LIGHT_AFTER_END

  }

  /**
   * cloneInstancePointLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightPoint]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstancePointLight(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_POINT_LIGHT_BEFORE_START
    //GENERATED_CLONE_INSTANCE_POINT_LIGHT_BEFORE_END

    //GENERATED_CLONE_INSTANCE_POINT_LIGHT_START
    //GENERATED_CLONE_INSTANCE_POINT_LIGHT_END

    //CUSTOM_CLONE_INSTANCE_POINT_LIGHT_START
    throw new Error(`Please implement Three.cloneInstancePointLight(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_POINT_LIGHT_END

    //GENERATED_CLONE_INSTANCE_POINT_LIGHT_AFTER_START
    //GENERATED_CLONE_INSTANCE_POINT_LIGHT_AFTER_END

  }

  /**
   * createInstancePointLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightPoint]
   * @param object
   * - No returns
   */
  createInstancePointLight(object) {

    //GENERATED_CREATE_INSTANCE_POINT_LIGHT_BEFORE_START
    //GENERATED_CREATE_INSTANCE_POINT_LIGHT_BEFORE_END

    //GENERATED_CREATE_INSTANCE_POINT_LIGHT_START
    //GENERATED_CREATE_INSTANCE_POINT_LIGHT_END

    //CUSTOM_CREATE_INSTANCE_POINT_LIGHT_START
    throw new Error(`Please implement Three.createInstancePointLight(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_POINT_LIGHT_END

    //GENERATED_CREATE_INSTANCE_POINT_LIGHT_AFTER_START
    //GENERATED_CREATE_INSTANCE_POINT_LIGHT_AFTER_END

  }

  /**
   * deleteInstancePointLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightPoint]
   * @param object
   * - No returns
   */
  deleteInstancePointLight(object) {

    //GENERATED_DELETE_INSTANCE_POINT_LIGHT_BEFORE_START
    //GENERATED_DELETE_INSTANCE_POINT_LIGHT_BEFORE_END

    //GENERATED_DELETE_INSTANCE_POINT_LIGHT_START
    //GENERATED_DELETE_INSTANCE_POINT_LIGHT_END

    //CUSTOM_DELETE_INSTANCE_POINT_LIGHT_START
    throw new Error(`Please implement Three.deleteInstancePointLight(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_POINT_LIGHT_END

    //GENERATED_DELETE_INSTANCE_POINT_LIGHT_AFTER_START
    //GENERATED_DELETE_INSTANCE_POINT_LIGHT_AFTER_END

  }

  /**
   * updateInstancePointLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightPoint]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstancePointLight(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_POINT_LIGHT_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_POINT_LIGHT_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_POINT_LIGHT_START
    //GENERATED_UPDATE_INSTANCE_POINT_LIGHT_END

    //CUSTOM_UPDATE_INSTANCE_POINT_LIGHT_START
    throw new Error(`Please implement Three.updateInstancePointLight(object, property, value) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_POINT_LIGHT_END

    //GENERATED_UPDATE_INSTANCE_POINT_LIGHT_AFTER_START
    //GENERATED_UPDATE_INSTANCE_POINT_LIGHT_AFTER_END

  }

  /**
   * cloneInstanceSpotLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightSpot]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceSpotLight(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_SPOT_LIGHT_BEFORE_START
    //GENERATED_CLONE_INSTANCE_SPOT_LIGHT_BEFORE_END

    //GENERATED_CLONE_INSTANCE_SPOT_LIGHT_START
    //GENERATED_CLONE_INSTANCE_SPOT_LIGHT_END

    //CUSTOM_CLONE_INSTANCE_SPOT_LIGHT_START
    throw new Error(`Please implement Three.cloneInstanceSpotLight(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_SPOT_LIGHT_END

    //GENERATED_CLONE_INSTANCE_SPOT_LIGHT_AFTER_START
    //GENERATED_CLONE_INSTANCE_SPOT_LIGHT_AFTER_END

  }

  /**
   * createInstanceSpotLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightSpot]
   * @param object
   * - No returns
   */
  createInstanceSpotLight(object) {

    //GENERATED_CREATE_INSTANCE_SPOT_LIGHT_BEFORE_START
    //GENERATED_CREATE_INSTANCE_SPOT_LIGHT_BEFORE_END

    //GENERATED_CREATE_INSTANCE_SPOT_LIGHT_START
    //GENERATED_CREATE_INSTANCE_SPOT_LIGHT_END

    //CUSTOM_CREATE_INSTANCE_SPOT_LIGHT_START
    throw new Error(`Please implement Three.createInstanceSpotLight(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_SPOT_LIGHT_END

    //GENERATED_CREATE_INSTANCE_SPOT_LIGHT_AFTER_START
    //GENERATED_CREATE_INSTANCE_SPOT_LIGHT_AFTER_END

  }

  /**
   * deleteInstanceSpotLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightSpot]
   * @param object
   * - No returns
   */
  deleteInstanceSpotLight(object) {

    //GENERATED_DELETE_INSTANCE_SPOT_LIGHT_BEFORE_START
    //GENERATED_DELETE_INSTANCE_SPOT_LIGHT_BEFORE_END

    //GENERATED_DELETE_INSTANCE_SPOT_LIGHT_START
    //GENERATED_DELETE_INSTANCE_SPOT_LIGHT_END

    //CUSTOM_DELETE_INSTANCE_SPOT_LIGHT_START
    throw new Error(`Please implement Three.deleteInstanceSpotLight(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_SPOT_LIGHT_END

    //GENERATED_DELETE_INSTANCE_SPOT_LIGHT_AFTER_START
    //GENERATED_DELETE_INSTANCE_SPOT_LIGHT_AFTER_END

  }

  /**
   * updateInstanceSpotLight()
   * - Three implementation for [R3EventObjComponentGraphicsLightSpot]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceSpotLight(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_SPOT_LIGHT_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_SPOT_LIGHT_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_SPOT_LIGHT_START
    //GENERATED_UPDATE_INSTANCE_SPOT_LIGHT_END

    //CUSTOM_UPDATE_INSTANCE_SPOT_LIGHT_START
    throw new Error(`Please implement Three.updateInstanceSpotLight(object, property, value) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_SPOT_LIGHT_END

    //GENERATED_UPDATE_INSTANCE_SPOT_LIGHT_AFTER_START
    //GENERATED_UPDATE_INSTANCE_SPOT_LIGHT_AFTER_END

  }

  /**
   * cloneInstanceMaterial()
   * - Three implementation for [R3EventObjComponentGraphicsMaterial]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceMaterial(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_MATERIAL_BEFORE_START
    //GENERATED_CLONE_INSTANCE_MATERIAL_BEFORE_END

    //GENERATED_CLONE_INSTANCE_MATERIAL_START
    //GENERATED_CLONE_INSTANCE_MATERIAL_END

    //CUSTOM_CLONE_INSTANCE_MATERIAL_START
    throw new Error(`Please implement Three.cloneInstanceMaterial(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_MATERIAL_END

    //GENERATED_CLONE_INSTANCE_MATERIAL_AFTER_START
    //GENERATED_CLONE_INSTANCE_MATERIAL_AFTER_END

  }

  /**
   * createInstanceMaterial()
   * - Three implementation for [R3EventObjComponentGraphicsMaterial]
   * @param object
   * - No returns
   */
  createInstanceMaterial(object) {

    //GENERATED_CREATE_INSTANCE_MATERIAL_BEFORE_START
    //GENERATED_CREATE_INSTANCE_MATERIAL_BEFORE_END

    //GENERATED_CREATE_INSTANCE_MATERIAL_START
    //GENERATED_CREATE_INSTANCE_MATERIAL_END

    //CUSTOM_CREATE_INSTANCE_MATERIAL_START
    /**
     * This is a virtual class
     */
    return {
      alphaHash : object.alphaHash,
      alphaTest : object.alphaTest,
      alphaToCoverage : object.alphaToCoverage,
      blendDst : object.blendDst,
      blendDstAlpha : object.blendDstAlpha,
      blendEquation : object.blendEquation,
      blendEquationAlpha : object.blendEquationAlpha,
      blendSrc : object.blendSrc,
      blendSrcAlpha : object.blendSrcAlpha,
      blending : object.blending,
      clipIntersection : object.clipIntersection,
      clipShadows : object.clipShadows,
      clippingPlanes : object.clippingPlanes,
      colorWrite : object.colorWrite,
      depthFunc : object.depthFunc,
      depthTest : object.depthTest,
      depthWrite : object.depthWrite,
      dithering : object.dithering,
      forceSinglePass : object.forceSinglePass,
      opacity : object.opacity,
      polygonOffset : object.polygonOffset,
      polygonOffsetFactor : object.polygonOffsetFactor,
      polygonOffsetUnits : object.polygonOffsetUnits,
      precision : object.precision,
      premultipliedAlpha : object.premultipliedAlpha,
      shadowSide : object.shadowSide,
      side : object.side,
      stencilFail : object.stencilFail,
      stencilFunc : object.stencilFunc,
      stencilFuncMask : object.stencilFuncMask,
      stencilRef : object.stencilRef,
      stencilWrite : object.stencilWrite,
      stencilWriteMask : object.stencilWriteMask,
      stencilZFail : object.stencilZFail,
      stencilZPass : object.stencilZPass,
      toneMapped : object.toneMapped,
      transparent : object.transparent,
      vertexColors : object.vertexColors,
      visible : object.visible
    }
    //CUSTOM_CREATE_INSTANCE_MATERIAL_END

    //GENERATED_CREATE_INSTANCE_MATERIAL_AFTER_START
    //GENERATED_CREATE_INSTANCE_MATERIAL_AFTER_END

  }

  /**
   * deleteInstanceMaterial()
   * - Three implementation for [R3EventObjComponentGraphicsMaterial]
   * @param object
   * - No returns
   */
  deleteInstanceMaterial(object) {

    //GENERATED_DELETE_INSTANCE_MATERIAL_BEFORE_START
    //GENERATED_DELETE_INSTANCE_MATERIAL_BEFORE_END

    //GENERATED_DELETE_INSTANCE_MATERIAL_START
    //GENERATED_DELETE_INSTANCE_MATERIAL_END

    //CUSTOM_DELETE_INSTANCE_MATERIAL_START
    /**
     * This is a virtual class
     */
    //CUSTOM_DELETE_INSTANCE_MATERIAL_END

    //GENERATED_DELETE_INSTANCE_MATERIAL_AFTER_START
    //GENERATED_DELETE_INSTANCE_MATERIAL_AFTER_END

  }

  /**
   * updateInstanceMaterial()
   * - Three implementation for [R3EventObjComponentGraphicsMaterial]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceMaterial(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_MATERIAL_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_MATERIAL_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_MATERIAL_START
    //GENERATED_UPDATE_INSTANCE_MATERIAL_END

    //CUSTOM_UPDATE_INSTANCE_MATERIAL_START
    /**
     * Handle all generic material properties here
     * @type {*|{}}
     */
    let instance = object.getInstance(this);

    if (
      property === 'blendDstAlpha' ||
      property === 'blendSrcAlpha' ||
      property === 'blendEquationAlpha'
    ) {
      /**
       * Three.js has a special way of using blendDstAlpha, if it is null, uses blendDst, otherwise blendDstAlpha.
       */
      if (value === 0) {
        instance[property] = null;
      } else {
        instance[property] = value;
      }
      return true;
    }

    if (
      property === 'alphaTest' ||
      property === 'transparent' ||
      property === 'vertexColors'

    ) {
      instance[property] = value;
      instance.needsUpdate = true;
      return true;
    }

    if (
			property === 'alphaHash' ||
			property === 'alphaToCoverage' ||
			property === 'blendDst' ||
			property === 'blendDstAlpha' ||
			property === 'blendEquation' ||
			property === 'blendEquationAlpha' ||
			property === 'blendSrc' ||
			property === 'blendSrcAlpha' ||
			property === 'blending' ||
			property === 'clipIntersection' ||
			property === 'clipShadows' ||
			property === 'clippingPlanes' ||
			property === 'colorWrite' ||
			property === 'depthFunc' ||
			property === 'depthTest' ||
			property === 'depthWrite' ||
			property === 'dithering' ||
			property === 'forceSinglePass' ||
      property === 'opacity' ||
      property === 'polygonOffset' ||
			property === 'polygonOffsetFactor' ||
			property === 'polygonOffsetUnits' ||
			property === 'precision' ||
			property === 'premultipliedAlpha' ||
			property === 'shadowSide' ||
			property === 'side' ||
			property === 'stencilFail' ||
			property === 'stencilFunc' ||
			property === 'stencilFuncMask' ||
			property === 'stencilRef' ||
			property === 'stencilWrite' ||
			property === 'stencilWriteMask' ||
			property === 'stencilZFail' ||
			property === 'stencilZPass' ||
			property === 'toneMapped' ||
			property === 'visible'
    ) {
      instance[property] = value;

      if (property === 'opacity') {
        if (typeof object.color !== 'undefined') {
          object.color.a = value;
        }
      }

      return true;
    }

    return false;
    //CUSTOM_UPDATE_INSTANCE_MATERIAL_END

    //GENERATED_UPDATE_INSTANCE_MATERIAL_AFTER_START
    //GENERATED_UPDATE_INSTANCE_MATERIAL_AFTER_END

  }

  /**
   * cloneInstanceBasic()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialBasic]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceBasic(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_BASIC_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BASIC_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BASIC_START
    //GENERATED_CLONE_INSTANCE_BASIC_END

    //CUSTOM_CLONE_INSTANCE_BASIC_START
    throw new Error(`Please implement Three.cloneInstanceBasic(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_BASIC_END

    //GENERATED_CLONE_INSTANCE_BASIC_AFTER_START
    //GENERATED_CLONE_INSTANCE_BASIC_AFTER_END

  }

  /**
   * createInstanceBasic()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialBasic]
   * @param object
   * - No returns
   */
  createInstanceBasic(object) {

    //GENERATED_CREATE_INSTANCE_BASIC_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BASIC_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BASIC_START
    //GENERATED_CREATE_INSTANCE_BASIC_END

    //CUSTOM_CREATE_INSTANCE_BASIC_START
    let options = {
      aoMapIntensity : object.aoMapIntensity,
      combine : object.combine,
      fog : object.fog,
      lightMapIntensity : object.lightMapIntensity,
      reflectivity : object.reflectivity,
      refractionRatio : object.refractionRatio,
      transparent : object.transparent,
      wireframe : object.wireframe,
      wireframeLinecap : object.wireframeLinecap,
      wireframeLinejoin : object.wireframeLinejoin,
      wireframeLinewidth : object.wireframeLinewidth
    };

    if (object.options) {
      options = {
        ...object.options,
        ...options
      };
      delete object.options;
    }

    /**
     * Math components
     */
    if (object.color) {
      options.color = object.color.getInstance(Runtime.KEY_MATHS_THREE);
      options.opacity = object.color.a;
    }

    /**
     * Texture maps
     */
    if (object.map) {
      options.map = object.map.getInstance(this);
    }
    if (object.lightMap) {
      options.lightMap = object.lightMap.getInstance(this);
    }
    if (object.aoMap) {
      options.aoMap = object.aoMap.getInstance(this);
    }
    if (object.specularMap) {
      options.specularMap = object.specularMap.getInstance(this);
    }
    if (object.alphaMap) {
      options.alphaMap = object.alphaMap.getInstance(this);
    }
    if (object.envMap) {
      options.envMap = object.envMap.getInstance(this);
    }

    return new THREE.MeshBasicMaterial(options);
    //CUSTOM_CREATE_INSTANCE_BASIC_END

    //GENERATED_CREATE_INSTANCE_BASIC_AFTER_START
    //GENERATED_CREATE_INSTANCE_BASIC_AFTER_END

  }

  /**
   * deleteInstanceBasic()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialBasic]
   * @param object
   * - No returns
   */
  deleteInstanceBasic(object) {

    //GENERATED_DELETE_INSTANCE_BASIC_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BASIC_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BASIC_START
    //GENERATED_DELETE_INSTANCE_BASIC_END

    //CUSTOM_DELETE_INSTANCE_BASIC_START
    object.getInstance(this).dispose();
    return null;
    //CUSTOM_DELETE_INSTANCE_BASIC_END

    //GENERATED_DELETE_INSTANCE_BASIC_AFTER_START
    //GENERATED_DELETE_INSTANCE_BASIC_AFTER_END

  }

  /**
   * updateInstanceBasic()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialBasic]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceBasic(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_BASIC_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BASIC_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BASIC_START
    //GENERATED_UPDATE_INSTANCE_BASIC_END

    //CUSTOM_UPDATE_INSTANCE_BASIC_START
    let instance = object.getInstance(this);

    if (property === 'color') {
      let hex = value.getHex();
      instance.color.setHex(hex);
      instance.opacity = value.a;
      instance.needsUpdate = true;
      return;
    }
    
    if (
      property === 'map' ||
      property === 'lightMap' ||
      property === 'aoMap' ||
      property === 'specularMap' ||
      property === 'alphaMap' ||
      property === 'envMap'
    ) {
      if (value) {
        instance[property] = value.getInstance(this);
      } else {
        instance[property] = null;
      }
      instance.needsUpdate = true;
      return;
    }

    if (
      property === 'lightMapIntensity' ||
      property === 'aoMapIntensity' ||
      property === 'combine' ||
      property === 'reflectivity' ||
      property === 'refractionRatio' ||
      property === 'wireframe' ||
      property === 'wireframeLinewidth' ||
      property === 'wireframeLinecap' ||
      property === 'wireframeLinejoin'
    ) {
      instance[property] = value;
      return;
    }

    if (
      property === 'fog'
    ) {
      instance[property] = value;
      instance.needsUpdate = true;
      return;
    }

    throw new Error(`Please implement Three.updateInstanceBasic(object, options) in R3RuntimeGraphicsThree for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_BASIC_END

    //GENERATED_UPDATE_INSTANCE_BASIC_AFTER_START
    //GENERATED_UPDATE_INSTANCE_BASIC_AFTER_END

  }

  /**
   * cloneInstanceMaterialGroup()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialGroup]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceMaterialGroup(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_MATERIAL_GROUP_BEFORE_START
    //GENERATED_CLONE_INSTANCE_MATERIAL_GROUP_BEFORE_END

    //GENERATED_CLONE_INSTANCE_MATERIAL_GROUP_START
    //GENERATED_CLONE_INSTANCE_MATERIAL_GROUP_END

    //CUSTOM_CLONE_INSTANCE_MATERIAL_GROUP_START
    throw new Error(`Please implement Three.cloneInstanceMaterialGroup(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_MATERIAL_GROUP_END

    //GENERATED_CLONE_INSTANCE_MATERIAL_GROUP_AFTER_START
    //GENERATED_CLONE_INSTANCE_MATERIAL_GROUP_AFTER_END

  }

  /**
   * createInstanceMaterialGroup()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialGroup]
   * @param object
   * - No returns
   */
  createInstanceMaterialGroup(object) {

    //GENERATED_CREATE_INSTANCE_MATERIAL_GROUP_BEFORE_START
    //GENERATED_CREATE_INSTANCE_MATERIAL_GROUP_BEFORE_END

    //GENERATED_CREATE_INSTANCE_MATERIAL_GROUP_START
    //GENERATED_CREATE_INSTANCE_MATERIAL_GROUP_END

    //CUSTOM_CREATE_INSTANCE_MATERIAL_GROUP_START
    let {start, count, materialIndex} = object;
    return {
      start,
      count,
      materialIndex
    }
    //CUSTOM_CREATE_INSTANCE_MATERIAL_GROUP_END

    //GENERATED_CREATE_INSTANCE_MATERIAL_GROUP_AFTER_START
    //GENERATED_CREATE_INSTANCE_MATERIAL_GROUP_AFTER_END

  }

  /**
   * deleteInstanceMaterialGroup()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialGroup]
   * @param object
   * - No returns
   */
  deleteInstanceMaterialGroup(object) {

    //GENERATED_DELETE_INSTANCE_MATERIAL_GROUP_BEFORE_START
    //GENERATED_DELETE_INSTANCE_MATERIAL_GROUP_BEFORE_END

    //GENERATED_DELETE_INSTANCE_MATERIAL_GROUP_START
    //GENERATED_DELETE_INSTANCE_MATERIAL_GROUP_END

    //CUSTOM_DELETE_INSTANCE_MATERIAL_GROUP_START
    return null;
    //CUSTOM_DELETE_INSTANCE_MATERIAL_GROUP_END

    //GENERATED_DELETE_INSTANCE_MATERIAL_GROUP_AFTER_START
    //GENERATED_DELETE_INSTANCE_MATERIAL_GROUP_AFTER_END

  }

  /**
   * updateInstanceMaterialGroup()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialGroup]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceMaterialGroup(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_MATERIAL_GROUP_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_MATERIAL_GROUP_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_MATERIAL_GROUP_START
    //GENERATED_UPDATE_INSTANCE_MATERIAL_GROUP_END

    //CUSTOM_UPDATE_INSTANCE_MATERIAL_GROUP_START
    let instance = object.getInstance(this);
    instance[property] = value;
    //CUSTOM_UPDATE_INSTANCE_MATERIAL_GROUP_END

    //GENERATED_UPDATE_INSTANCE_MATERIAL_GROUP_AFTER_START
    //GENERATED_UPDATE_INSTANCE_MATERIAL_GROUP_AFTER_END

  }

  /**
   * cloneInstanceLambert()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialLambert]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceLambert(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_LAMBERT_BEFORE_START
    //GENERATED_CLONE_INSTANCE_LAMBERT_BEFORE_END

    //GENERATED_CLONE_INSTANCE_LAMBERT_START
    //GENERATED_CLONE_INSTANCE_LAMBERT_END

    //CUSTOM_CLONE_INSTANCE_LAMBERT_START
    throw new Error(`Please implement Three.cloneInstanceLambert(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_LAMBERT_END

    //GENERATED_CLONE_INSTANCE_LAMBERT_AFTER_START
    //GENERATED_CLONE_INSTANCE_LAMBERT_AFTER_END

  }

  /**
   * createInstanceLambert()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialLambert]
   * @param object
   * - No returns
   */
  createInstanceLambert(object) {

    //GENERATED_CREATE_INSTANCE_LAMBERT_BEFORE_START
    //GENERATED_CREATE_INSTANCE_LAMBERT_BEFORE_END

    //GENERATED_CREATE_INSTANCE_LAMBERT_START
    //GENERATED_CREATE_INSTANCE_LAMBERT_END

    //CUSTOM_CREATE_INSTANCE_LAMBERT_START
    throw new Error(`Please implement Three.createGraphicsLambert(lambert, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_LAMBERT_END

    //GENERATED_CREATE_INSTANCE_LAMBERT_AFTER_START
    //GENERATED_CREATE_INSTANCE_LAMBERT_AFTER_END

  }

  /**
   * deleteInstanceLambert()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialLambert]
   * @param object
   * - No returns
   */
  deleteInstanceLambert(object) {

    //GENERATED_DELETE_INSTANCE_LAMBERT_BEFORE_START
    //GENERATED_DELETE_INSTANCE_LAMBERT_BEFORE_END

    //GENERATED_DELETE_INSTANCE_LAMBERT_START
    //GENERATED_DELETE_INSTANCE_LAMBERT_END

    //CUSTOM_DELETE_INSTANCE_LAMBERT_START
    throw new Error(`Please implement Three.deleteGraphicsLambert(lambert, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_LAMBERT_END

    //GENERATED_DELETE_INSTANCE_LAMBERT_AFTER_START
    //GENERATED_DELETE_INSTANCE_LAMBERT_AFTER_END

  }

  /**
   * updateInstanceLambert()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialLambert]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceLambert(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_LAMBERT_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_LAMBERT_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_LAMBERT_START
    //GENERATED_UPDATE_INSTANCE_LAMBERT_END

    //CUSTOM_UPDATE_INSTANCE_LAMBERT_START
    throw new Error(`Please implement Three.updateInstanceLambert(object, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_LAMBERT_END

    //GENERATED_UPDATE_INSTANCE_LAMBERT_AFTER_START
    //GENERATED_UPDATE_INSTANCE_LAMBERT_AFTER_END

  }

  /**
   * cloneInstancePhong()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialPhong]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstancePhong(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_PHONG_BEFORE_START
    //GENERATED_CLONE_INSTANCE_PHONG_BEFORE_END

    //GENERATED_CLONE_INSTANCE_PHONG_START
    //GENERATED_CLONE_INSTANCE_PHONG_END

    //CUSTOM_CLONE_INSTANCE_PHONG_START
    throw new Error(`Please implement Three.cloneInstancePhong(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_PHONG_END

    //GENERATED_CLONE_INSTANCE_PHONG_AFTER_START
    //GENERATED_CLONE_INSTANCE_PHONG_AFTER_END

  }

  /**
   * createInstancePhong()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialPhong]
   * @param object
   * - No returns
   */
  createInstancePhong(object) {

    //GENERATED_CREATE_INSTANCE_PHONG_BEFORE_START
    //GENERATED_CREATE_INSTANCE_PHONG_BEFORE_END

    //GENERATED_CREATE_INSTANCE_PHONG_START
    //GENERATED_CREATE_INSTANCE_PHONG_END

    //CUSTOM_CREATE_INSTANCE_PHONG_START
    throw new Error(`Please implement Three.createGraphicsPhong(phong, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_PHONG_END

    //GENERATED_CREATE_INSTANCE_PHONG_AFTER_START
    //GENERATED_CREATE_INSTANCE_PHONG_AFTER_END

  }

  /**
   * deleteInstancePhong()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialPhong]
   * @param object
   * - No returns
   */
  deleteInstancePhong(object) {

    //GENERATED_DELETE_INSTANCE_PHONG_BEFORE_START
    //GENERATED_DELETE_INSTANCE_PHONG_BEFORE_END

    //GENERATED_DELETE_INSTANCE_PHONG_START
    //GENERATED_DELETE_INSTANCE_PHONG_END

    //CUSTOM_DELETE_INSTANCE_PHONG_START
    throw new Error(`Please implement Three.deleteGraphicsPhong(phong, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_PHONG_END

    //GENERATED_DELETE_INSTANCE_PHONG_AFTER_START
    //GENERATED_DELETE_INSTANCE_PHONG_AFTER_END

  }

  /**
   * updateInstancePhong()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialPhong]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstancePhong(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_PHONG_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_PHONG_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_PHONG_START
    //GENERATED_UPDATE_INSTANCE_PHONG_END

    //CUSTOM_UPDATE_INSTANCE_PHONG_START
    throw new Error(`Please implement Three.updateInstancePhong(object, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_PHONG_END

    //GENERATED_UPDATE_INSTANCE_PHONG_AFTER_START
    //GENERATED_UPDATE_INSTANCE_PHONG_AFTER_END

  }

  /**
   * cloneInstancePoints()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialPoints]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstancePoints(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_POINTS_BEFORE_START
    //GENERATED_CLONE_INSTANCE_POINTS_BEFORE_END

    //GENERATED_CLONE_INSTANCE_POINTS_START
    //GENERATED_CLONE_INSTANCE_POINTS_END

    //CUSTOM_CLONE_INSTANCE_POINTS_START
    throw new Error(`Please implement Three.cloneInstancePoints(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_POINTS_END

    //GENERATED_CLONE_INSTANCE_POINTS_AFTER_START
    //GENERATED_CLONE_INSTANCE_POINTS_AFTER_END

  }

  /**
   * createInstancePoints()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialPoints]
   * @param object
   * - No returns
   */
  createInstancePoints(object) {

    //GENERATED_CREATE_INSTANCE_POINTS_BEFORE_START
    //GENERATED_CREATE_INSTANCE_POINTS_BEFORE_END

    //GENERATED_CREATE_INSTANCE_POINTS_START
    //GENERATED_CREATE_INSTANCE_POINTS_END

    //CUSTOM_CREATE_INSTANCE_POINTS_START
    throw new Error(`Please implement Three.createInstancePoints(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_POINTS_END

    //GENERATED_CREATE_INSTANCE_POINTS_AFTER_START
    //GENERATED_CREATE_INSTANCE_POINTS_AFTER_END

  }

  /**
   * deleteInstancePoints()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialPoints]
   * @param object
   * - No returns
   */
  deleteInstancePoints(object) {

    //GENERATED_DELETE_INSTANCE_POINTS_BEFORE_START
    //GENERATED_DELETE_INSTANCE_POINTS_BEFORE_END

    //GENERATED_DELETE_INSTANCE_POINTS_START
    //GENERATED_DELETE_INSTANCE_POINTS_END

    //CUSTOM_DELETE_INSTANCE_POINTS_START
    throw new Error(`Please implement Three.deleteInstancePoints(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_POINTS_END

    //GENERATED_DELETE_INSTANCE_POINTS_AFTER_START
    //GENERATED_DELETE_INSTANCE_POINTS_AFTER_END

  }

  /**
   * updateInstancePoints()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialPoints]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstancePoints(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_POINTS_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_POINTS_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_POINTS_START
    //GENERATED_UPDATE_INSTANCE_POINTS_END

    //CUSTOM_UPDATE_INSTANCE_POINTS_START
    throw new Error(`Please implement Three.updateInstancePoints(object, property, value) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_POINTS_END

    //GENERATED_UPDATE_INSTANCE_POINTS_AFTER_START
    //GENERATED_UPDATE_INSTANCE_POINTS_AFTER_END

  }

  /**
   * cloneInstanceStandard()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialStandard]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceStandard(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_STANDARD_BEFORE_START
    //GENERATED_CLONE_INSTANCE_STANDARD_BEFORE_END

    //GENERATED_CLONE_INSTANCE_STANDARD_START
    //GENERATED_CLONE_INSTANCE_STANDARD_END

    //CUSTOM_CLONE_INSTANCE_STANDARD_START
    throw new Error(`Please implement Three.cloneInstanceStandard(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_STANDARD_END

    //GENERATED_CLONE_INSTANCE_STANDARD_AFTER_START
    //GENERATED_CLONE_INSTANCE_STANDARD_AFTER_END

  }

  /**
   * createInstanceStandard()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialStandard]
   * @param object
   * - No returns
   */
  createInstanceStandard(object) {

    //GENERATED_CREATE_INSTANCE_STANDARD_BEFORE_START
    //GENERATED_CREATE_INSTANCE_STANDARD_BEFORE_END

    //GENERATED_CREATE_INSTANCE_STANDARD_START
    //GENERATED_CREATE_INSTANCE_STANDARD_END

    //CUSTOM_CREATE_INSTANCE_STANDARD_START
    let options = {
      aoMapIntensity : object.aoMapIntensity,
      bumpScale : object.bumpScale,
      color : object.color,
      displacementBias : object.displacementBias,
      displacementScale : object.displacementScale,
      emissive : object.emissive,
      emissiveIntensity : object.emissiveIntensity,
      envMapIntensity : object.envMapIntensity,
      flatShading : object.flatShading,
      fog : object.fog,
      lightMapIntensity : object.lightMapIntensity,
      map : object.map,
      metalness : object.metalness,
      normalMapType : object.normalMapType,
      normalScale : object.normalScale,
      roughness : object.roughness,
      wireframe : object.wireframe,
      wireframeLinecap : object.wireframeLinecap,
      wireframeLinejoin : object.wireframeLinejoin,
      wireframeLinewidth : object.wireframeLinewidth
    };

    if (object.options) {
      options = {
        ...object.options,
        ...options
      };
      delete object.options;
    }

    /**
     * Math components
     */
    if (object.color) {
      options.color = object.color.getInstance(Runtime.KEY_MATHS_THREE);
    }
    if (object.emissive) {
      options.emissive = object.emissive.getInstance(Runtime.KEY_MATHS_THREE);
    }
    if (object.normalScale) {
      options.normalScale = object.normalScale.getInstance(Runtime.KEY_MATHS_THREE);
    }

    /**
     * Texture maps
     */
    if (object.alphaMap) {
      options.alphaMap = object.alphaMap.getInstance(this);
    }
    if (object.aoMap) {
      options.aoMap = object.aoMap.getInstance(this);
    }
    if (object.bumpMap) {
      options.bumpMap = object.bumpMap.getInstance(this);
    }
    if (object.displacementMap) {
      options.displacementMap = object.displacementMap.getInstance(this);
    }
    if (object.emissiveMap) {
      options.emissiveMap = object.emissiveMap.getInstance(this);
    }
    if (object.envMap) {
      options.envMap = object.envMap.getInstance(this);
    }
    if (object.lightMap) {
      options.lightMap = object.lightMap.getInstance(this);
    }
    if (object.map) {
      options.map = object.map.getInstance(this);
    }
    if (object.metalnessMap) {
      options.metalnessMap = object.metalnessMap.getInstance(this);
    }
    if (object.normalMap) {
      options.normalMap = object.normalMap.getInstance(this);
    }
    if (object.roughnessMap) {
      options.roughnessMap = object.roughnessMap.getInstance(this);
    }

    return new THREE.MeshStandardMaterial(options);
    //CUSTOM_CREATE_INSTANCE_STANDARD_END

    //GENERATED_CREATE_INSTANCE_STANDARD_AFTER_START
    //GENERATED_CREATE_INSTANCE_STANDARD_AFTER_END

  }

  /**
   * deleteInstanceStandard()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialStandard]
   * @param object
   * - No returns
   */
  deleteInstanceStandard(object) {

    //GENERATED_DELETE_INSTANCE_STANDARD_BEFORE_START
    //GENERATED_DELETE_INSTANCE_STANDARD_BEFORE_END

    //GENERATED_DELETE_INSTANCE_STANDARD_START
    //GENERATED_DELETE_INSTANCE_STANDARD_END

    //CUSTOM_DELETE_INSTANCE_STANDARD_START
    let instance = object.getInstance(this);
    instance.dispose();
    return null;
    //CUSTOM_DELETE_INSTANCE_STANDARD_END

    //GENERATED_DELETE_INSTANCE_STANDARD_AFTER_START
    //GENERATED_DELETE_INSTANCE_STANDARD_AFTER_END

  }

  /**
   * updateInstanceStandard()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialStandard]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceStandard(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_STANDARD_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_STANDARD_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_STANDARD_START
    //GENERATED_UPDATE_INSTANCE_STANDARD_END

    //CUSTOM_UPDATE_INSTANCE_STANDARD_START
    let instance = object.getInstance(this);

    if (property === 'color') {
      let hex = value.getHex();
      instance.color.setHex(hex);
      instance.opacity = value.a;
      return;
    }

    if (property === 'emissive') {
      let hex = value.getHex();
      instance.emissive.setHex(hex);
      instance.emissiveIntensity = value.a;
      return;
    }

    if (property === 'normalScale') {
      instance.normalScale = value.getInstance(Runtime.KEY_MATHS_THREE);
      return;
    }

    if (
      property === 'aoMapIntensity' ||
      property === 'bumpScale' ||
      property === 'color' ||
      property === 'displacementBias' ||
      property === 'displacementScale' ||
      property === 'emissive' ||
      property === 'emissiveIntensity' ||
      property === 'envMapIntensity' ||
      property === 'lightMapIntensity' ||
      property === 'metalness' ||
      property === 'normalMapType' ||
      property === 'normalScale' ||
      property === 'roughness' ||
      property === 'wireframe' ||
      property === 'wireframeLinecap' ||
      property === 'wireframeLinejoin' ||
      property === 'wireframeLinewidth'
    )
    {
      instance[property] = value;
      return;
    }

    if (
      property === 'fog' ||
      property === 'flatShading'
    )
    {
      instance[property] = value;
      instance.needsUpdate = true;
      return;
    }

    if (
      property === 'alphaMap' ||
      property === 'aoMap' ||
      property === 'bumpMap' ||
      property === 'displacementMap' ||
      property === 'emissiveMap' ||
      property === 'envMap' ||
      property === 'lightMap' ||
      property === 'map' ||
      property === 'metalnessMap' ||
      property === 'normalMap' ||
      property === 'roughnessMap'
    )
    {
      if (value) {
        instance[property] = value.getInstance(this);
      } else {
        instance[property] = null;
      }
      instance.needsUpdate = true;
      return;
    }

    throw new Error(`Please implement Three.updateInstanceStandard(object, options) in R3RuntimeGraphicsThree for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_STANDARD_END

    //GENERATED_UPDATE_INSTANCE_STANDARD_AFTER_START
    //GENERATED_UPDATE_INSTANCE_STANDARD_AFTER_END

  }

  /**
   * cloneInstanceToon()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialToon]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceToon(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_TOON_BEFORE_START
    //GENERATED_CLONE_INSTANCE_TOON_BEFORE_END

    //GENERATED_CLONE_INSTANCE_TOON_START
    //GENERATED_CLONE_INSTANCE_TOON_END

    //CUSTOM_CLONE_INSTANCE_TOON_START
    throw new Error(`Please implement Three.cloneInstanceToon(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_TOON_END

    //GENERATED_CLONE_INSTANCE_TOON_AFTER_START
    //GENERATED_CLONE_INSTANCE_TOON_AFTER_END

  }

  /**
   * createInstanceToon()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialToon]
   * @param object
   * - No returns
   */
  createInstanceToon(object) {

    //GENERATED_CREATE_INSTANCE_TOON_BEFORE_START
    //GENERATED_CREATE_INSTANCE_TOON_BEFORE_END

    //GENERATED_CREATE_INSTANCE_TOON_START
    //GENERATED_CREATE_INSTANCE_TOON_END

    //CUSTOM_CREATE_INSTANCE_TOON_START
    throw new Error(`Please implement Three.createGraphicsToon(toon, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_TOON_END

    //GENERATED_CREATE_INSTANCE_TOON_AFTER_START
    //GENERATED_CREATE_INSTANCE_TOON_AFTER_END

  }

  /**
   * deleteInstanceToon()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialToon]
   * @param object
   * - No returns
   */
  deleteInstanceToon(object) {

    //GENERATED_DELETE_INSTANCE_TOON_BEFORE_START
    //GENERATED_DELETE_INSTANCE_TOON_BEFORE_END

    //GENERATED_DELETE_INSTANCE_TOON_START
    //GENERATED_DELETE_INSTANCE_TOON_END

    //CUSTOM_DELETE_INSTANCE_TOON_START
    throw new Error(`Please implement Three.deleteGraphicsToon(toon, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_TOON_END

    //GENERATED_DELETE_INSTANCE_TOON_AFTER_START
    //GENERATED_DELETE_INSTANCE_TOON_AFTER_END

  }

  /**
   * updateInstanceToon()
   * - Three implementation for [R3EventObjComponentGraphicsMaterialToon]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceToon(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_TOON_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_TOON_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_TOON_START
    //GENERATED_UPDATE_INSTANCE_TOON_END

    //CUSTOM_UPDATE_INSTANCE_TOON_START
    throw new Error(`Please implement Three.updateInstanceToon(object, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_TOON_END

    //GENERATED_UPDATE_INSTANCE_TOON_AFTER_START
    //GENERATED_UPDATE_INSTANCE_TOON_AFTER_END

  }

  /**
   * cloneInstanceMesh()
   * - Three implementation for [R3EventObjComponentGraphicsMesh]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceMesh(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_MESH_BEFORE_START
    //GENERATED_CLONE_INSTANCE_MESH_BEFORE_END

    //GENERATED_CLONE_INSTANCE_MESH_START
    //GENERATED_CLONE_INSTANCE_MESH_END

    //CUSTOM_CLONE_INSTANCE_MESH_START
    throw new Error(`Please implement Three.cloneInstanceMesh(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_MESH_END

    //GENERATED_CLONE_INSTANCE_MESH_AFTER_START
    //GENERATED_CLONE_INSTANCE_MESH_AFTER_END

  }

  /**
   * createInstanceMesh()
   * - Three implementation for [R3EventObjComponentGraphicsMesh]
   * @param object
   * - No returns
   */
  createInstanceMesh(object) {

    //GENERATED_CREATE_INSTANCE_MESH_BEFORE_START
    //GENERATED_CREATE_INSTANCE_MESH_BEFORE_END

    //GENERATED_CREATE_INSTANCE_MESH_START
    //GENERATED_CREATE_INSTANCE_MESH_END

    //CUSTOM_CREATE_INSTANCE_MESH_START
    let instance;

    let geometry = object.geometry.getInstance(this);

    if (!geometry) {
      console.error('no geometry!');
    }

    /**
     * Normal material takes precedence
     */
    if (object.material) {
      instance = new THREE.Mesh(
        object.geometry.getInstance(this),
        object.material.getInstance(this)
      );
    } else {
      if (object.materials && object.materials.length) {
        instance = new THREE.Mesh(
          object.geometry.getInstance(this),
          object.materials.map((material) => material.getInstance(this))
        )
      }
    }

    if (!instance) {
      throw new Error(`Mesh create instance failed`);
    }

    if (object.useQuaternion) {
      let quaternionInstance = object.quaternion.getInstance(Runtime.KEY_MATHS_THREE);
      instance.quaternion.x = object.quaternion.x;
      instance.quaternion.y = object.quaternion.y;
      instance.quaternion.z = object.quaternion.z;
      instance.quaternion.w = object.quaternion.w;
      let euler = new THREE.Euler().setFromQuaternion(quaternionInstance.clone().normalize());
      instance.rotation.x = euler.x;
      instance.rotation.y = euler.y;
      instance.rotation.z = euler.z;
    } else {
      instance.rotation.x = object.rotation.x;
      instance.rotation.y = object.rotation.y;
      instance.rotation.z = object.rotation.z;
    }

    instance.position.x = object.position.x;
    instance.position.y = object.position.y;
    instance.position.z = object.position.z;

    instance.scale.x = object.scale.x;
    instance.scale.y = object.scale.y;
    instance.scale.z = object.scale.z;

    if (object.scene) {
      let scene = object.scene.getInstance(this);
      if (scene) {
        scene.add(instance);
      }
    }

    let box = new THREE.Box3();
    instance.geometry.computeBoundingBox();
    box.copy( instance.geometry.boundingBox ).applyMatrix4( instance.matrixWorld );
    let size = new THREE.Vector3();
    box.getSize(size);
    object.width = size.x * object.scale.x;
    object.height = size.y * object.scale.y;
    object.depth = size.z * object.scale.z;

    return instance;
    //CUSTOM_CREATE_INSTANCE_MESH_END

    //GENERATED_CREATE_INSTANCE_MESH_AFTER_START
    //GENERATED_CREATE_INSTANCE_MESH_AFTER_END

  }

  /**
   * deleteInstanceMesh()
   * - Three implementation for [R3EventObjComponentGraphicsMesh]
   * @param object
   * - No returns
   */
  deleteInstanceMesh(object) {

    //GENERATED_DELETE_INSTANCE_MESH_BEFORE_START
    //GENERATED_DELETE_INSTANCE_MESH_BEFORE_END

    //GENERATED_DELETE_INSTANCE_MESH_START
    //GENERATED_DELETE_INSTANCE_MESH_END

    //CUSTOM_DELETE_INSTANCE_MESH_START
    // Event.Emit(
    //   Event.DO_NOT_RENDER
    // );

    let instance = object.getInstance(this);
    let scene = null;

    if (object.scene) {
      scene = object.scene.getInstance(this);
    }

    if (scene) {
      scene.remove(instance);
    }

    // Event.Emit(
    //   Event.RENDER_OK
    // );

    /**
     * Looked around and Three.js does not have a dispose function for meshes,
     * so no:
     * instance.dispose();
     */
    return null;
    //CUSTOM_DELETE_INSTANCE_MESH_END

    //GENERATED_DELETE_INSTANCE_MESH_AFTER_START
    //GENERATED_DELETE_INSTANCE_MESH_AFTER_END

  }

  /**
   * updateInstanceMesh()
   * - Three implementation for [R3EventObjComponentGraphicsMesh]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceMesh(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_MESH_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_MESH_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_MESH_START
    //GENERATED_UPDATE_INSTANCE_MESH_END

    //CUSTOM_UPDATE_INSTANCE_MESH_START
    if (
      property === 'width' ||
      property === 'height' ||
      property === 'depth'
    ) {
      /**
       * Do nothing - we update this from instance
       */
      return;
    }

    let instance = object.getInstance(this);

    if (
      property === 'useQuaternion' ||
      property === 'quaternion' ||
      property === 'rotation'
    ) {

      if (object.useQuaternion) {
        let quaternionInstance = object.quaternion.getInstance(Runtime.KEY_MATHS_THREE);
        quaternionInstance.x = object.quaternion.x;
        quaternionInstance.y = object.quaternion.y;
        quaternionInstance.z = object.quaternion.z;
        quaternionInstance.w = object.quaternion.w;
        instance.setRotationFromQuaternion(quaternionInstance);
        instance.updateMatrixWorld();
        // let euler = new THREE.Euler().setFromQuaternion(quaternionInstance.clone().normalize());
        // instance.rotation.x = euler.x;
        // instance.rotation.y = euler.y;
        // instance.rotation.z = euler.z;
      } else {
        instance.rotation.x = object.rotation.x;
        instance.rotation.y = object.rotation.y;
        instance.rotation.z = object.rotation.z;
      }

      if (object.helper) {
        object.helper.update();
      }

      return;
    }

    if (
      property === 'position' ||
      property === 'scale'
    ) {

      if (isNaN(value.x)) {
        console.warn('x : isNan');
        return;
      }
      if (isNaN(value.y)) {
        console.warn('y : isNan');
        return;
      }
      if (isNaN(value.z)) {
        console.warn('z : isNan');
        return;
      }

      instance[property]['x'] = value.x;
      instance[property]['y'] = value.y;
      instance[property]['z'] = value.z;

      if (object.helper) {
        object.helper.update();
      }

      if (property === 'scale') {
        let box = new THREE.Box3();
        instance.geometry.computeBoundingBox();
        box.copy( instance.geometry.boundingBox ).applyMatrix4( instance.matrixWorld );
        let size = new THREE.Vector3();
        box.getSize(size);
        object.width = size.x * object.scale.x;
        object.height = size.y * object.scale.y;
        object.depth = size.z * object.scale.z;
      }

      return;
    }

    if (property === 'geometry') {
      this.deleteInstance(object);
      let instance = this.createInstanceMesh(object);
      object.setInstance(Runtime.KEY_GRAPHICS_THREE, instance);
      let box = new THREE.Box3();
      instance.geometry.computeBoundingBox();
      box.copy( instance.geometry.boundingBox ).applyMatrix4( instance.matrixWorld );
      let size = new THREE.Vector3();
      box.getSize(size);
      object.width = size.x * object.scale.x;
      object.height = size.y * object.scale.y;
      object.depth = size.z * object.scale.z;
      return;
    }

    if (property === 'material') {
      if (object.material) {
        let material = object.material.getInstance(this);
        instance.material = material;
      } else {

        if (object.materials.length) {
          let materials = object.materials.map(
            (material) => {
              return material.getInstance(this);
            }
          );
          instance.material = materials;
          return;
        }

        instance.material = null;
      }
      return;
    }

    if (property === 'materials') {
      if (object.material) {
        return;
      }

      if (object.materials.length) {
        let materials = object.materials.map(
          (material) => {
            return material.getInstance(this);
          }
        );
        instance.material = materials;
        return;
      } else {
        instance.material = null;
      }
      return;
    }

    if (property === 'parentMesh') {
      if (object.parentMesh) {
        let parent = object.parentMesh.getInstance(this);
        instance.parent = parent;
      } else {
        instance.parent = null;
      }

      if (object.helper) {
        object.helper.update();
      }

      return;
    }

    if (property === 'selected') {
      console.log(`Selected mesh ${object.name}`);

      if (object.selected) {

        if (object.helper) {
          return;
        }

        let scene = object.scene.getInstance(this);
        if (!scene) {
          return;
        }

        object.helper = new THREE.BoxHelper(instance, 0x00ff00);
        scene.add(object.helper);
        Event.Emit(
          Event.MESH_SELECTED,
          {
            mesh : object
          }
        );
        return;
      }

      if (!object.selected) {

        if (!object.helper) {
          return;
        }

        let scene = object.scene.getInstance(this);
        if (!scene) {
          return;
        }

        object.helper.dispose();

        scene.remove(object.helper);

        object.helper = null;

        Event.Emit(
          Event.MESH_DESELECTED,
          {
            mesh : object
          }
        );
      }

      return;
    }

    console.warn(`Please implement Three.updateInstanceMesh(object, options) in R3RuntimeGraphicsThree for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_MESH_END

    //GENERATED_UPDATE_INSTANCE_MESH_AFTER_START
    //GENERATED_UPDATE_INSTANCE_MESH_AFTER_END

  }

  /**
   * cloneInstanceRaycaster()
   * - Three implementation for [R3EventObjComponentGraphicsRaycaster]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceRaycaster(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_RAYCASTER_BEFORE_START
    //GENERATED_CLONE_INSTANCE_RAYCASTER_BEFORE_END

    //GENERATED_CLONE_INSTANCE_RAYCASTER_START
    //GENERATED_CLONE_INSTANCE_RAYCASTER_END

    //CUSTOM_CLONE_INSTANCE_RAYCASTER_START
    throw new Error(`Please implement Three.cloneInstanceRaycaster(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_RAYCASTER_END

    //GENERATED_CLONE_INSTANCE_RAYCASTER_AFTER_START
    //GENERATED_CLONE_INSTANCE_RAYCASTER_AFTER_END

  }

  /**
   * createInstanceRaycaster()
   * - Three implementation for [R3EventObjComponentGraphicsRaycaster]
   * @param object
   * - No returns
   */
  createInstanceRaycaster(object) {

    //GENERATED_CREATE_INSTANCE_RAYCASTER_BEFORE_START
    //GENERATED_CREATE_INSTANCE_RAYCASTER_BEFORE_END

    //GENERATED_CREATE_INSTANCE_RAYCASTER_START
    //GENERATED_CREATE_INSTANCE_RAYCASTER_END

    //CUSTOM_CREATE_INSTANCE_RAYCASTER_START
    let position = object.position.getInstance(Runtime.KEY_MATHS_THREE);
    let direction = object.direction.getInstance(Runtime.KEY_MATHS_THREE);

    let instance = new THREE.Raycaster();
    instance.set(position, direction);
    return instance;
    //CUSTOM_CREATE_INSTANCE_RAYCASTER_END

    //GENERATED_CREATE_INSTANCE_RAYCASTER_AFTER_START
    //GENERATED_CREATE_INSTANCE_RAYCASTER_AFTER_END

  }

  /**
   * deleteInstanceRaycaster()
   * - Three implementation for [R3EventObjComponentGraphicsRaycaster]
   * @param object
   * - No returns
   */
  deleteInstanceRaycaster(object) {

    //GENERATED_DELETE_INSTANCE_RAYCASTER_BEFORE_START
    //GENERATED_DELETE_INSTANCE_RAYCASTER_BEFORE_END

    //GENERATED_DELETE_INSTANCE_RAYCASTER_START
    //GENERATED_DELETE_INSTANCE_RAYCASTER_END

    //CUSTOM_DELETE_INSTANCE_RAYCASTER_START
    return null;
    //CUSTOM_DELETE_INSTANCE_RAYCASTER_END

    //GENERATED_DELETE_INSTANCE_RAYCASTER_AFTER_START
    //GENERATED_DELETE_INSTANCE_RAYCASTER_AFTER_END

  }

  /**
   * updateInstanceRaycaster()
   * - Three implementation for [R3EventObjComponentGraphicsRaycaster]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceRaycaster(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_RAYCASTER_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_RAYCASTER_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_RAYCASTER_START
    //GENERATED_UPDATE_INSTANCE_RAYCASTER_END

    //CUSTOM_UPDATE_INSTANCE_RAYCASTER_START
    if (object.updateFromCameraInstance) {
      return;
    }

    let instance = object.getInstance(this);

    if (property === 'position') {
      let position = object.position.getInstance(Runtime.KEY_MATHS_THREE);
      instance.setPosition(position);
      return;
    }

    if (property === 'direction') {
      let direction = object.direction.getInstance(Runtime.KEY_MATHS_THREE);
      instance.setDirection(direction);
      return;
    }

    throw new Error(`Please implement Three.updateInstanceRaycaster() for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_RAYCASTER_END

    //GENERATED_UPDATE_INSTANCE_RAYCASTER_AFTER_START
    //GENERATED_UPDATE_INSTANCE_RAYCASTER_AFTER_END

  }

  /**
   * cloneInstanceCubeRenderer()
   * - Three implementation for [R3EventObjComponentGraphicsRendererCube]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceCubeRenderer(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_CUBE_RENDERER_BEFORE_START
    //GENERATED_CLONE_INSTANCE_CUBE_RENDERER_BEFORE_END

    //GENERATED_CLONE_INSTANCE_CUBE_RENDERER_START
    //GENERATED_CLONE_INSTANCE_CUBE_RENDERER_END

    //CUSTOM_CLONE_INSTANCE_CUBE_RENDERER_START
    throw new Error(`Please implement Three.cloneInstanceCubeRenderer(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_CUBE_RENDERER_END

    //GENERATED_CLONE_INSTANCE_CUBE_RENDERER_AFTER_START
    //GENERATED_CLONE_INSTANCE_CUBE_RENDERER_AFTER_END

  }

  /**
   * createInstanceCubeRenderer()
   * - Three implementation for [R3EventObjComponentGraphicsRendererCube]
   * @param object
   * - No returns
   */
  createInstanceCubeRenderer(object) {

    //GENERATED_CREATE_INSTANCE_CUBE_RENDERER_BEFORE_START
    //GENERATED_CREATE_INSTANCE_CUBE_RENDERER_BEFORE_END

    //GENERATED_CREATE_INSTANCE_CUBE_RENDERER_START
    //GENERATED_CREATE_INSTANCE_CUBE_RENDERER_END

    //CUSTOM_CREATE_INSTANCE_CUBE_RENDERER_START
    throw new Error(`Please implement Three.createInstanceCubeRenderer(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_CUBE_RENDERER_END

    //GENERATED_CREATE_INSTANCE_CUBE_RENDERER_AFTER_START
    //GENERATED_CREATE_INSTANCE_CUBE_RENDERER_AFTER_END

  }

  /**
   * deleteInstanceCubeRenderer()
   * - Three implementation for [R3EventObjComponentGraphicsRendererCube]
   * @param object
   * - No returns
   */
  deleteInstanceCubeRenderer(object) {

    //GENERATED_DELETE_INSTANCE_CUBE_RENDERER_BEFORE_START
    //GENERATED_DELETE_INSTANCE_CUBE_RENDERER_BEFORE_END

    //GENERATED_DELETE_INSTANCE_CUBE_RENDERER_START
    //GENERATED_DELETE_INSTANCE_CUBE_RENDERER_END

    //CUSTOM_DELETE_INSTANCE_CUBE_RENDERER_START
    throw new Error(`Please implement Three.deleteInstanceCubeRenderer(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_CUBE_RENDERER_END

    //GENERATED_DELETE_INSTANCE_CUBE_RENDERER_AFTER_START
    //GENERATED_DELETE_INSTANCE_CUBE_RENDERER_AFTER_END

  }

  /**
   * updateInstanceCubeRenderer()
   * - Three implementation for [R3EventObjComponentGraphicsRendererCube]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceCubeRenderer(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_CUBE_RENDERER_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_CUBE_RENDERER_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_CUBE_RENDERER_START
    //GENERATED_UPDATE_INSTANCE_CUBE_RENDERER_END

    //CUSTOM_UPDATE_INSTANCE_CUBE_RENDERER_START
    throw new Error(`Please implement Three.updateInstanceCubeRenderer(object, property, value) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_CUBE_RENDERER_END

    //GENERATED_UPDATE_INSTANCE_CUBE_RENDERER_AFTER_START
    //GENERATED_UPDATE_INSTANCE_CUBE_RENDERER_AFTER_END

  }

  /**
   * cloneInstanceGl()
   * - Three implementation for [R3EventObjComponentGraphicsRendererGl]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceGl(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_GL_BEFORE_START
    //GENERATED_CLONE_INSTANCE_GL_BEFORE_END

    //GENERATED_CLONE_INSTANCE_GL_START
    //GENERATED_CLONE_INSTANCE_GL_END

    //CUSTOM_CLONE_INSTANCE_GL_START
    throw new Error(`Please implement Three.cloneInstanceGl(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_GL_END

    //GENERATED_CLONE_INSTANCE_GL_AFTER_START
    //GENERATED_CLONE_INSTANCE_GL_AFTER_END

  }

  /**
   * createInstanceGl()
   * - Three implementation for [R3EventObjComponentGraphicsRendererGl]
   * @param object
   * - No returns
   */
  createInstanceGl(object) {

    //GENERATED_CREATE_INSTANCE_GL_BEFORE_START
    //GENERATED_CREATE_INSTANCE_GL_BEFORE_END

    //GENERATED_CREATE_INSTANCE_GL_START
    //GENERATED_CREATE_INSTANCE_GL_END

    //CUSTOM_CREATE_INSTANCE_GL_START
    let canvas = object.canvas.getInstance(this);

    let instance = null;

    try {

      if (canvas) {
        instance = new THREE.WebGLRenderer(
          {
            canvas
          }
        );
      }

      if (instance && canvas) {

        let width = canvas.width;
        let height = canvas.height;

        if (object.canvas.fullScreen) {
          width = canvas.clientWidth;
          height = canvas.clientHeight;
        }

        instance.setSize(width, height, false);
      }

      if (instance && object.color) {
        instance.setClearColor(new THREE.Color(`rgb(${parseInt(object.color.r)}, ${parseInt(object.color.g)}, ${parseInt(object.color.b)})`), object.color.a);
      }

    } catch (e) {
      instance = {
        type : 'gl-renderer',
        message : 'for testing',
        setClearColor : () => {
          console.log('setting clear color for mock gl renderer');
         },
        setSize: () =>{
          console.log('setting size for mock gl renderer');
        },
        render : () => {
          console.log('fake render');
        },
        outputColorSpace : object.outputColorSpace
      }
    }

    return instance;
    //CUSTOM_CREATE_INSTANCE_GL_END

    //GENERATED_CREATE_INSTANCE_GL_AFTER_START
    //GENERATED_CREATE_INSTANCE_GL_AFTER_END

  }

  /**
   * deleteInstanceGl()
   * - Three implementation for [R3EventObjComponentGraphicsRendererGl]
   * @param object
   * - No returns
   */
  deleteInstanceGl(object) {

    //GENERATED_DELETE_INSTANCE_GL_BEFORE_START
    //GENERATED_DELETE_INSTANCE_GL_BEFORE_END

    //GENERATED_DELETE_INSTANCE_GL_START
    //GENERATED_DELETE_INSTANCE_GL_END

    //CUSTOM_DELETE_INSTANCE_GL_START
    try {
      object.getInstance(this).dispose();
    } catch (error) {
      console.warn('Error raised during renderer dispose()');
    }

    return null;
    //CUSTOM_DELETE_INSTANCE_GL_END

    //GENERATED_DELETE_INSTANCE_GL_AFTER_START
    //GENERATED_DELETE_INSTANCE_GL_AFTER_END

  }

  /**
   * updateInstanceGl()
   * - Three implementation for [R3EventObjComponentGraphicsRendererGl]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceGl(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_GL_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_GL_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_GL_START
    //GENERATED_UPDATE_INSTANCE_GL_END

    //CUSTOM_UPDATE_INSTANCE_GL_START
    if (property === 'color') {
      let instance = object.getInstance(this);
      instance.setClearColor(new THREE.Color(`rgb(${parseInt(value.r)}, ${parseInt(value.g)}, ${parseInt(value.b)})`), value.a);
      return;
    }

    if (
      property === 'width' ||
      property === 'height' ||
      property === 'useCanvasSize'
    ) {
      let instance = object.getInstance(this);
      if (object.useCanvasSize) {
        let canvas = object.canvas.getInstance(this);
        instance.setSize(canvas.clientWidth, canvas.clientHeight, false);
      } else {
        instance.setSize(object.width, object.height, false);
      }
      return;
    }

    if (
      property === 'canvas'
    ) {
      /**
       * Below is enough to trigger an updateInstance above
       */
      let canvas = object.canvas.getInstance(this);

      let width = object.canvas.width;
      let height = object.canvas.height;

      if (object.canvas.fullScreen) {
        width = canvas.clientWidth;
        height = canvas.clientHeight;
      }

      object.width = width;
      object.height = height;
      return;
    }

    if (
      property === 'camera' ||
      property === 'scene'
    ) {
      /**
       * Camera and Scene are only required at render() function - so no need to update instance here
       */
      return;
    }

    if (
      property === 'outputColorSpace'
    ) {
      let instance = object.getInstance(this);
      instance.outputColorSpace = value;
      return;
    }

    throw new Error(`Please implement Three.updateInstanceGl(object, options) in R3RuntimeGraphicsThree for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_GL_END

    //GENERATED_UPDATE_INSTANCE_GL_AFTER_START
    //GENERATED_UPDATE_INSTANCE_GL_AFTER_END

  }

  /**
   * cloneInstanceTarget()
   * - Three implementation for [R3EventObjComponentGraphicsRendererTarget]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceTarget(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_TARGET_BEFORE_START
    //GENERATED_CLONE_INSTANCE_TARGET_BEFORE_END

    //GENERATED_CLONE_INSTANCE_TARGET_START
    //GENERATED_CLONE_INSTANCE_TARGET_END

    //CUSTOM_CLONE_INSTANCE_TARGET_START
    throw new Error(`Please implement Three.cloneInstanceTarget(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_TARGET_END

    //GENERATED_CLONE_INSTANCE_TARGET_AFTER_START
    //GENERATED_CLONE_INSTANCE_TARGET_AFTER_END

  }

  /**
   * createInstanceTarget()
   * - Three implementation for [R3EventObjComponentGraphicsRendererTarget]
   * @param object
   * - No returns
   */
  createInstanceTarget(object) {

    //GENERATED_CREATE_INSTANCE_TARGET_BEFORE_START
    //GENERATED_CREATE_INSTANCE_TARGET_BEFORE_END

    //GENERATED_CREATE_INSTANCE_TARGET_START
    //GENERATED_CREATE_INSTANCE_TARGET_END

    //CUSTOM_CREATE_INSTANCE_TARGET_START
    throw new Error(`Please implement Three.createInstanceTarget(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_TARGET_END

    //GENERATED_CREATE_INSTANCE_TARGET_AFTER_START
    //GENERATED_CREATE_INSTANCE_TARGET_AFTER_END

  }

  /**
   * deleteInstanceTarget()
   * - Three implementation for [R3EventObjComponentGraphicsRendererTarget]
   * @param object
   * - No returns
   */
  deleteInstanceTarget(object) {

    //GENERATED_DELETE_INSTANCE_TARGET_BEFORE_START
    //GENERATED_DELETE_INSTANCE_TARGET_BEFORE_END

    //GENERATED_DELETE_INSTANCE_TARGET_START
    //GENERATED_DELETE_INSTANCE_TARGET_END

    //CUSTOM_DELETE_INSTANCE_TARGET_START
    throw new Error(`Please implement Three.deleteInstanceTarget(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_TARGET_END

    //GENERATED_DELETE_INSTANCE_TARGET_AFTER_START
    //GENERATED_DELETE_INSTANCE_TARGET_AFTER_END

  }

  /**
   * updateInstanceTarget()
   * - Three implementation for [R3EventObjComponentGraphicsRendererTarget]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceTarget(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_TARGET_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_TARGET_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_TARGET_START
    //GENERATED_UPDATE_INSTANCE_TARGET_END

    //CUSTOM_UPDATE_INSTANCE_TARGET_START
    throw new Error(`Please implement Three.updateInstanceTarget(object, property, value) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_TARGET_END

    //GENERATED_UPDATE_INSTANCE_TARGET_AFTER_START
    //GENERATED_UPDATE_INSTANCE_TARGET_AFTER_END

  }

  /**
   * cloneInstanceScene()
   * - Three implementation for [R3EventObjComponentGraphicsScene]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceScene(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_SCENE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_SCENE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_SCENE_START
    //GENERATED_CLONE_INSTANCE_SCENE_END

    //CUSTOM_CLONE_INSTANCE_SCENE_START
    throw new Error(`Please implement Three.cloneInstanceScene(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_SCENE_END

    //GENERATED_CLONE_INSTANCE_SCENE_AFTER_START
    //GENERATED_CLONE_INSTANCE_SCENE_AFTER_END

  }

  /**
   * createInstanceScene()
   * - Three implementation for [R3EventObjComponentGraphicsScene]
   * @param object
   * - No returns
   */
  createInstanceScene(object) {

    //GENERATED_CREATE_INSTANCE_SCENE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_SCENE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_SCENE_START
    //GENERATED_CREATE_INSTANCE_SCENE_END

    //CUSTOM_CREATE_INSTANCE_SCENE_START
    return new THREE.Scene();
    //CUSTOM_CREATE_INSTANCE_SCENE_END

    //GENERATED_CREATE_INSTANCE_SCENE_AFTER_START
    //GENERATED_CREATE_INSTANCE_SCENE_AFTER_END

  }

  /**
   * deleteInstanceScene()
   * - Three implementation for [R3EventObjComponentGraphicsScene]
   * @param object
   * - No returns
   */
  deleteInstanceScene(object) {

    //GENERATED_DELETE_INSTANCE_SCENE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_SCENE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_SCENE_START
    //GENERATED_DELETE_INSTANCE_SCENE_END

    //CUSTOM_DELETE_INSTANCE_SCENE_START
    let instance = object.getInstance(this);
    const n = instance.children.length - 1;
    for (let i = n; i > -1; i--) {
      instance.remove(instance.children[i]);
    }
    // or
    instance.clear();
    return null;
    //CUSTOM_DELETE_INSTANCE_SCENE_END

    //GENERATED_DELETE_INSTANCE_SCENE_AFTER_START
    //GENERATED_DELETE_INSTANCE_SCENE_AFTER_END

  }

  /**
   * updateInstanceScene()
   * - Three implementation for [R3EventObjComponentGraphicsScene]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceScene(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_SCENE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_SCENE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_SCENE_START
    //GENERATED_UPDATE_INSTANCE_SCENE_END

    //CUSTOM_UPDATE_INSTANCE_SCENE_START

    throw new Error(`Please implement Three.updateInstanceScene(object, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_SCENE_END

    //GENERATED_UPDATE_INSTANCE_SCENE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_SCENE_AFTER_END

  }

  /**
   * cloneInstanceShader()
   * - Three implementation for [R3EventObjComponentGraphicsShader]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceShader(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_SHADER_BEFORE_START
    //GENERATED_CLONE_INSTANCE_SHADER_BEFORE_END

    //GENERATED_CLONE_INSTANCE_SHADER_START
    //GENERATED_CLONE_INSTANCE_SHADER_END

    //CUSTOM_CLONE_INSTANCE_SHADER_START
    throw new Error(`Please implement Three.cloneInstanceShader(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_SHADER_END

    //GENERATED_CLONE_INSTANCE_SHADER_AFTER_START
    //GENERATED_CLONE_INSTANCE_SHADER_AFTER_END

  }

  /**
   * createInstanceShader()
   * - Three implementation for [R3EventObjComponentGraphicsShader]
   * @param object
   * - No returns
   */
  createInstanceShader(object) {

    //GENERATED_CREATE_INSTANCE_SHADER_BEFORE_START
    //GENERATED_CREATE_INSTANCE_SHADER_BEFORE_END

    //GENERATED_CREATE_INSTANCE_SHADER_START
    //GENERATED_CREATE_INSTANCE_SHADER_END

    //CUSTOM_CREATE_INSTANCE_SHADER_START
    throw new Error(`Please implement Three.createShader(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_SHADER_END

    //GENERATED_CREATE_INSTANCE_SHADER_AFTER_START
    //GENERATED_CREATE_INSTANCE_SHADER_AFTER_END

  }

  /**
   * deleteInstanceShader()
   * - Three implementation for [R3EventObjComponentGraphicsShader]
   * @param object
   * - No returns
   */
  deleteInstanceShader(object) {

    //GENERATED_DELETE_INSTANCE_SHADER_BEFORE_START
    //GENERATED_DELETE_INSTANCE_SHADER_BEFORE_END

    //GENERATED_DELETE_INSTANCE_SHADER_START
    //GENERATED_DELETE_INSTANCE_SHADER_END

    //CUSTOM_DELETE_INSTANCE_SHADER_START
    throw new Error(`Please implement Three.deleteShader(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_SHADER_END

    //GENERATED_DELETE_INSTANCE_SHADER_AFTER_START
    //GENERATED_DELETE_INSTANCE_SHADER_AFTER_END

  }

  /**
   * updateInstanceShader()
   * - Three implementation for [R3EventObjComponentGraphicsShader]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceShader(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_SHADER_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_SHADER_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_SHADER_START
    //GENERATED_UPDATE_INSTANCE_SHADER_END

    //CUSTOM_UPDATE_INSTANCE_SHADER_START
    throw new Error(`Please implement Three.updateInstanceShader(object, property, value) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_SHADER_END

    //GENERATED_UPDATE_INSTANCE_SHADER_AFTER_START
    //GENERATED_UPDATE_INSTANCE_SHADER_AFTER_END

  }

  /**
   * cloneInstanceSprite()
   * - Three implementation for [R3EventObjComponentGraphicsSprite]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceSprite(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_SPRITE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_SPRITE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_SPRITE_START
    //GENERATED_CLONE_INSTANCE_SPRITE_END

    //CUSTOM_CLONE_INSTANCE_SPRITE_START
    throw new Error(`Please implement Three.cloneInstanceSprite(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_SPRITE_END

    //GENERATED_CLONE_INSTANCE_SPRITE_AFTER_START
    //GENERATED_CLONE_INSTANCE_SPRITE_AFTER_END

  }

  /**
   * createInstanceSprite()
   * - Three implementation for [R3EventObjComponentGraphicsSprite]
   * @param object
   * - No returns
   */
  createInstanceSprite(object) {

    //GENERATED_CREATE_INSTANCE_SPRITE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_SPRITE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_SPRITE_START
    //GENERATED_CREATE_INSTANCE_SPRITE_END

    //CUSTOM_CREATE_INSTANCE_SPRITE_START
    throw new Error(`Please implement Three.createGraphicsSprite(sprite, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_SPRITE_END

    //GENERATED_CREATE_INSTANCE_SPRITE_AFTER_START
    //GENERATED_CREATE_INSTANCE_SPRITE_AFTER_END

  }

  /**
   * deleteInstanceSprite()
   * - Three implementation for [R3EventObjComponentGraphicsSprite]
   * @param object
   * - No returns
   */
  deleteInstanceSprite(object) {

    //GENERATED_DELETE_INSTANCE_SPRITE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_SPRITE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_SPRITE_START
    //GENERATED_DELETE_INSTANCE_SPRITE_END

    //CUSTOM_DELETE_INSTANCE_SPRITE_START
    throw new Error(`Please implement Three.deleteGraphicsSprite(sprite, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_SPRITE_END

    //GENERATED_DELETE_INSTANCE_SPRITE_AFTER_START
    //GENERATED_DELETE_INSTANCE_SPRITE_AFTER_END

  }

  /**
   * updateInstanceSprite()
   * - Three implementation for [R3EventObjComponentGraphicsSprite]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceSprite(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_SPRITE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_SPRITE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_SPRITE_START
    //GENERATED_UPDATE_INSTANCE_SPRITE_END

    //CUSTOM_UPDATE_INSTANCE_SPRITE_START
    throw new Error(`Please implement Three.updateInstanceSprite(object, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_SPRITE_END

    //GENERATED_UPDATE_INSTANCE_SPRITE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_SPRITE_AFTER_END

  }

  /**
   * cloneInstanceCanvasTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureCanvas]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceCanvasTexture(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_CANVAS_TEXTURE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_CANVAS_TEXTURE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_CANVAS_TEXTURE_START
    //GENERATED_CLONE_INSTANCE_CANVAS_TEXTURE_END

    //CUSTOM_CLONE_INSTANCE_CANVAS_TEXTURE_START
    throw new Error(`Please implement Three.cloneInstanceCanvasTexture(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_CANVAS_TEXTURE_END

    //GENERATED_CLONE_INSTANCE_CANVAS_TEXTURE_AFTER_START
    //GENERATED_CLONE_INSTANCE_CANVAS_TEXTURE_AFTER_END

  }

  /**
   * createInstanceCanvasTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureCanvas]
   * @param object
   * - No returns
   */
  createInstanceCanvasTexture(object) {

    //GENERATED_CREATE_INSTANCE_CANVAS_TEXTURE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_CANVAS_TEXTURE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_CANVAS_TEXTURE_START
    //GENERATED_CREATE_INSTANCE_CANVAS_TEXTURE_END

    //CUSTOM_CREATE_INSTANCE_CANVAS_TEXTURE_START
    throw new Error(`Please implement Three.createInstanceCanvasTexture(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_CANVAS_TEXTURE_END

    //GENERATED_CREATE_INSTANCE_CANVAS_TEXTURE_AFTER_START
    //GENERATED_CREATE_INSTANCE_CANVAS_TEXTURE_AFTER_END

  }

  /**
   * deleteInstanceCanvasTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureCanvas]
   * @param object
   * - No returns
   */
  deleteInstanceCanvasTexture(object) {

    //GENERATED_DELETE_INSTANCE_CANVAS_TEXTURE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_CANVAS_TEXTURE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_CANVAS_TEXTURE_START
    //GENERATED_DELETE_INSTANCE_CANVAS_TEXTURE_END

    //CUSTOM_DELETE_INSTANCE_CANVAS_TEXTURE_START
    throw new Error(`Please implement Three.deleteInstanceCanvasTexture(object) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_CANVAS_TEXTURE_END

    //GENERATED_DELETE_INSTANCE_CANVAS_TEXTURE_AFTER_START
    //GENERATED_DELETE_INSTANCE_CANVAS_TEXTURE_AFTER_END

  }

  /**
   * updateInstanceCanvasTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureCanvas]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceCanvasTexture(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_CANVAS_TEXTURE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_CANVAS_TEXTURE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_CANVAS_TEXTURE_START
    //GENERATED_UPDATE_INSTANCE_CANVAS_TEXTURE_END

    //CUSTOM_UPDATE_INSTANCE_CANVAS_TEXTURE_START
    throw new Error(`Please implement Three.updateInstanceCanvasTexture(object, property, value) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_CANVAS_TEXTURE_END

    //GENERATED_UPDATE_INSTANCE_CANVAS_TEXTURE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_CANVAS_TEXTURE_AFTER_END

  }

  /**
   * cloneInstanceCubeTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureCube]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceCubeTexture(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_CUBE_TEXTURE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_CUBE_TEXTURE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_CUBE_TEXTURE_START
    //GENERATED_CLONE_INSTANCE_CUBE_TEXTURE_END

    //CUSTOM_CLONE_INSTANCE_CUBE_TEXTURE_START
    throw new Error(`Please implement Three.cloneInstanceCubeTexture(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_CUBE_TEXTURE_END

    //GENERATED_CLONE_INSTANCE_CUBE_TEXTURE_AFTER_START
    //GENERATED_CLONE_INSTANCE_CUBE_TEXTURE_AFTER_END

  }

  /**
   * createInstanceCubeTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureCube]
   * @param object
   * - No returns
   */
  createInstanceCubeTexture(object) {

    //GENERATED_CREATE_INSTANCE_CUBE_TEXTURE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_CUBE_TEXTURE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_CUBE_TEXTURE_START
    //GENERATED_CREATE_INSTANCE_CUBE_TEXTURE_END

    //CUSTOM_CREATE_INSTANCE_CUBE_TEXTURE_START
    throw new Error(`Please implement Three.createGraphicsCubeTexture(cube, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_CUBE_TEXTURE_END

    //GENERATED_CREATE_INSTANCE_CUBE_TEXTURE_AFTER_START
    //GENERATED_CREATE_INSTANCE_CUBE_TEXTURE_AFTER_END

  }

  /**
   * deleteInstanceCubeTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureCube]
   * @param object
   * - No returns
   */
  deleteInstanceCubeTexture(object) {

    //GENERATED_DELETE_INSTANCE_CUBE_TEXTURE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_CUBE_TEXTURE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_CUBE_TEXTURE_START
    //GENERATED_DELETE_INSTANCE_CUBE_TEXTURE_END

    //CUSTOM_DELETE_INSTANCE_CUBE_TEXTURE_START
    throw new Error(`Please implement Three.deleteGraphicsCubeTexture(cube, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_CUBE_TEXTURE_END

    //GENERATED_DELETE_INSTANCE_CUBE_TEXTURE_AFTER_START
    //GENERATED_DELETE_INSTANCE_CUBE_TEXTURE_AFTER_END

  }

  /**
   * updateInstanceCubeTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureCube]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceCubeTexture(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_CUBE_TEXTURE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_CUBE_TEXTURE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_CUBE_TEXTURE_START
    //GENERATED_UPDATE_INSTANCE_CUBE_TEXTURE_END

    //CUSTOM_UPDATE_INSTANCE_CUBE_TEXTURE_START
    throw new Error(`Please implement Three.updateInstanceCubeTexture(object, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_CUBE_TEXTURE_END

    //GENERATED_UPDATE_INSTANCE_CUBE_TEXTURE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_CUBE_TEXTURE_AFTER_END

  }

  /**
   * cloneInstanceImageTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureImage]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceImageTexture(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_IMAGE_TEXTURE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_IMAGE_TEXTURE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_IMAGE_TEXTURE_START
    //GENERATED_CLONE_INSTANCE_IMAGE_TEXTURE_END

    //CUSTOM_CLONE_INSTANCE_IMAGE_TEXTURE_START
    throw new Error(`Please implement Three.cloneInstanceImageTexture(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_IMAGE_TEXTURE_END

    //GENERATED_CLONE_INSTANCE_IMAGE_TEXTURE_AFTER_START
    //GENERATED_CLONE_INSTANCE_IMAGE_TEXTURE_AFTER_END

  }

  /**
   * createInstanceImageTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureImage]
   * @param object
   * - No returns
   */
  createInstanceImageTexture(object) {

    //GENERATED_CREATE_INSTANCE_IMAGE_TEXTURE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_IMAGE_TEXTURE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_IMAGE_TEXTURE_START
    //GENERATED_CREATE_INSTANCE_IMAGE_TEXTURE_END

    //CUSTOM_CREATE_INSTANCE_IMAGE_TEXTURE_START
    if (!object.image) {
      throw new Error(`Texture image went away for ${object.name}`);
    }

    let imageInstance = object.image.getInstance(this);
    if (imageInstance) {

      let textureInstance = new THREE.Texture(
        imageInstance,
        Texture.UVMapping,
        object.wrapS,
        object.wrapT
      );

      textureInstance.repeat.x = object.repeat.x;
      textureInstance.repeat.y = object.repeat.y;
      textureInstance.offset.x = object.offset.x;
      textureInstance.offset.y = object.offset.y;

      return textureInstance;
    }

    const loader = new THREE.TextureLoader();

    let apiUrl = Utils.GetApiUrl();

    let url = `${apiUrl}${object.image.urlPath}`;

    return loader.load(
      // resource URL
      url,

      // onLoad callback
      function ( texture ) {
        texture.wrapS = object.wrapS;
        texture.wrapT = object.wrapT;
        texture.repeat.x = object.repeat.x;
        texture.repeat.y = object.repeat.y;
        texture.offset.x = object.offset.x;
        texture.offset.y = object.offset.y;
        object.setInstance(this, texture);
        for (let child of object.children) {
          for (let property of Object.keys(child.references)) {
            if (child[property] === object) {
              child.updateInstance(property, object);
            }
          }
        }
      }.bind(this),

      // onProgress callback currently not supported
      undefined,

      // onError callback
      function ( err ) {
        Event.Emit(
          Event.STATUS_INFO,
          {
            code : -1,
            message : `Texture loading failed for ${object.image?.urlPath} : ${err.message}`
          }
        )
      }
    );
    //CUSTOM_CREATE_INSTANCE_IMAGE_TEXTURE_END

    //GENERATED_CREATE_INSTANCE_IMAGE_TEXTURE_AFTER_START
    //GENERATED_CREATE_INSTANCE_IMAGE_TEXTURE_AFTER_END

  }

  /**
   * deleteInstanceImageTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureImage]
   * @param object
   * - No returns
   */
  deleteInstanceImageTexture(object) {

    //GENERATED_DELETE_INSTANCE_IMAGE_TEXTURE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_IMAGE_TEXTURE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_IMAGE_TEXTURE_START
    //GENERATED_DELETE_INSTANCE_IMAGE_TEXTURE_END

    //CUSTOM_DELETE_INSTANCE_IMAGE_TEXTURE_START
    return null;
    //CUSTOM_DELETE_INSTANCE_IMAGE_TEXTURE_END

    //GENERATED_DELETE_INSTANCE_IMAGE_TEXTURE_AFTER_START
    //GENERATED_DELETE_INSTANCE_IMAGE_TEXTURE_AFTER_END

  }

  /**
   * updateInstanceImageTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureImage]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceImageTexture(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_IMAGE_TEXTURE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_IMAGE_TEXTURE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_IMAGE_TEXTURE_START
    //GENERATED_UPDATE_INSTANCE_IMAGE_TEXTURE_END

    //CUSTOM_UPDATE_INSTANCE_IMAGE_TEXTURE_START
    if (property === 'image') {
      let image = object.image.getInstance(this);
      let instance = object.getInstance(this);
      instance.image = image;
      instance.needsUpdate = true;
      return;
    }

    if (
      property === 'mapping' ||
      property === 'wrapS' ||
      property === 'wrapT' ||
      property === 'offset' ||
      property === 'repeat'
    ) {
      let instance = object.getInstance(this);
      instance[property] = value;
      instance.needsUpdate = true;
      return;
    }

    throw new Error(`Please implement Three.updateInstanceImageTexture() for ${property}`);
    //CUSTOM_UPDATE_INSTANCE_IMAGE_TEXTURE_END

    //GENERATED_UPDATE_INSTANCE_IMAGE_TEXTURE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_IMAGE_TEXTURE_AFTER_END

  }

  /**
   * cloneInstanceVideoTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureVideo]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceVideoTexture(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_VIDEO_TEXTURE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_VIDEO_TEXTURE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_VIDEO_TEXTURE_START
    //GENERATED_CLONE_INSTANCE_VIDEO_TEXTURE_END

    //CUSTOM_CLONE_INSTANCE_VIDEO_TEXTURE_START
    throw new Error(`Please implement Three.cloneInstanceVideoTexture(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_VIDEO_TEXTURE_END

    //GENERATED_CLONE_INSTANCE_VIDEO_TEXTURE_AFTER_START
    //GENERATED_CLONE_INSTANCE_VIDEO_TEXTURE_AFTER_END

  }

  /**
   * createInstanceVideoTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureVideo]
   * @param object
   * - No returns
   */
  createInstanceVideoTexture(object) {

    //GENERATED_CREATE_INSTANCE_VIDEO_TEXTURE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_VIDEO_TEXTURE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_VIDEO_TEXTURE_START
    //GENERATED_CREATE_INSTANCE_VIDEO_TEXTURE_END

    //CUSTOM_CREATE_INSTANCE_VIDEO_TEXTURE_START
    throw new Error(`Please implement Three.createGraphicsVideoTexture(video, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_VIDEO_TEXTURE_END

    //GENERATED_CREATE_INSTANCE_VIDEO_TEXTURE_AFTER_START
    //GENERATED_CREATE_INSTANCE_VIDEO_TEXTURE_AFTER_END

  }

  /**
   * deleteInstanceVideoTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureVideo]
   * @param object
   * - No returns
   */
  deleteInstanceVideoTexture(object) {

    //GENERATED_DELETE_INSTANCE_VIDEO_TEXTURE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_VIDEO_TEXTURE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_VIDEO_TEXTURE_START
    //GENERATED_DELETE_INSTANCE_VIDEO_TEXTURE_END

    //CUSTOM_DELETE_INSTANCE_VIDEO_TEXTURE_START
    throw new Error(`Please implement Three.deleteGraphicsVideoTexture(video, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_VIDEO_TEXTURE_END

    //GENERATED_DELETE_INSTANCE_VIDEO_TEXTURE_AFTER_START
    //GENERATED_DELETE_INSTANCE_VIDEO_TEXTURE_AFTER_END

  }

  /**
   * updateInstanceVideoTexture()
   * - Three implementation for [R3EventObjComponentGraphicsTextureVideo]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceVideoTexture(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_VIDEO_TEXTURE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_VIDEO_TEXTURE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_VIDEO_TEXTURE_START
    //GENERATED_UPDATE_INSTANCE_VIDEO_TEXTURE_END

    //CUSTOM_UPDATE_INSTANCE_VIDEO_TEXTURE_START
    throw new Error(`Please implement Three.updateInstanceVideoTexture(object, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_VIDEO_TEXTURE_END

    //GENERATED_UPDATE_INSTANCE_VIDEO_TEXTURE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_VIDEO_TEXTURE_AFTER_END

  }

  /**
   * cloneInstanceVideoGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsVideo]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceVideoGraphics(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_VIDEO_GRAPHICS_BEFORE_START
    //GENERATED_CLONE_INSTANCE_VIDEO_GRAPHICS_BEFORE_END

    //GENERATED_CLONE_INSTANCE_VIDEO_GRAPHICS_START
    //GENERATED_CLONE_INSTANCE_VIDEO_GRAPHICS_END

    //CUSTOM_CLONE_INSTANCE_VIDEO_GRAPHICS_START
    throw new Error(`Please implement Three.cloneInstanceVideoGraphics(object, propagate = true) in R3RuntimeGraphicsThree`);
    //CUSTOM_CLONE_INSTANCE_VIDEO_GRAPHICS_END

    //GENERATED_CLONE_INSTANCE_VIDEO_GRAPHICS_AFTER_START
    //GENERATED_CLONE_INSTANCE_VIDEO_GRAPHICS_AFTER_END

  }

  /**
   * createInstanceVideoGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsVideo]
   * @param object
   * - No returns
   */
  createInstanceVideoGraphics(object) {

    //GENERATED_CREATE_INSTANCE_VIDEO_GRAPHICS_BEFORE_START
    //GENERATED_CREATE_INSTANCE_VIDEO_GRAPHICS_BEFORE_END

    //GENERATED_CREATE_INSTANCE_VIDEO_GRAPHICS_START
    //GENERATED_CREATE_INSTANCE_VIDEO_GRAPHICS_END

    //CUSTOM_CREATE_INSTANCE_VIDEO_GRAPHICS_START
    throw new Error(`Please implement Three.createGraphicsVideoGraphics(video, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_CREATE_INSTANCE_VIDEO_GRAPHICS_END

    //GENERATED_CREATE_INSTANCE_VIDEO_GRAPHICS_AFTER_START
    //GENERATED_CREATE_INSTANCE_VIDEO_GRAPHICS_AFTER_END

  }

  /**
   * deleteInstanceVideoGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsVideo]
   * @param object
   * - No returns
   */
  deleteInstanceVideoGraphics(object) {

    //GENERATED_DELETE_INSTANCE_VIDEO_GRAPHICS_BEFORE_START
    //GENERATED_DELETE_INSTANCE_VIDEO_GRAPHICS_BEFORE_END

    //GENERATED_DELETE_INSTANCE_VIDEO_GRAPHICS_START
    //GENERATED_DELETE_INSTANCE_VIDEO_GRAPHICS_END

    //CUSTOM_DELETE_INSTANCE_VIDEO_GRAPHICS_START
    throw new Error(`Please implement Three.deleteGraphicsVideoGraphics(video, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_DELETE_INSTANCE_VIDEO_GRAPHICS_END

    //GENERATED_DELETE_INSTANCE_VIDEO_GRAPHICS_AFTER_START
    //GENERATED_DELETE_INSTANCE_VIDEO_GRAPHICS_AFTER_END

  }

  /**
   * updateInstanceVideoGraphics()
   * - Three implementation for [R3EventObjComponentGraphicsVideo]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceVideoGraphics(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_VIDEO_GRAPHICS_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_VIDEO_GRAPHICS_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_VIDEO_GRAPHICS_START
    //GENERATED_UPDATE_INSTANCE_VIDEO_GRAPHICS_END

    //CUSTOM_UPDATE_INSTANCE_VIDEO_GRAPHICS_START
    throw new Error(`Please implement Three.updateInstanceVideoGraphics(object, options) in R3RuntimeGraphicsThree`);
    //CUSTOM_UPDATE_INSTANCE_VIDEO_GRAPHICS_END

    //GENERATED_UPDATE_INSTANCE_VIDEO_GRAPHICS_AFTER_START
    //GENERATED_UPDATE_INSTANCE_VIDEO_GRAPHICS_AFTER_END

  }

  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Three.Type = 'R3RuntimeGraphicsThree';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Three as default};
