//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Runtime from '../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Component from '../Component.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  project=this.getCurrentProject() - All base components have a project
  buffer=null - The audio buffer where the raw audio data is accessed
  loop=false - When loop is true - the audio will repeat infinitely
  volume=0.5 - The volume of the audio sample
  detune=0 - Modify this value to change the pitch / playback rate.
  playbackRate=1 - Similar to detune - changing the playback rate will modify the pitch
  isPlaying=false - A flag indicating if this audio object is currently playing audio
  camera=null - The camera component is required for the audio sample to know its location in 3d space.
  overplay=false - When true, and you execute the play method of this audio component when it is already playing, a second instance of the audio will be created and played 'over' the currently playing audio. Setting this to false and then calling play while it is already playing will simply stop the current instance and restart it from the beginning. Useful for sound effects that need to be re-produced like gun shots which need to play over other existing gunshots.
  autoplay=false - When true, the audio will automatically start to play once it is loaded
  contentType=null - The mime type of the audio file
  size=0 - The size of the audio file
  urlPath=null - The path to the audio file relative to the API URL
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeAudioThree" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "project",
            "type" : "R3EventObjProject"
          },
          {
            "property" : "camera",
            "type" : "R3EventObjComponentGraphicsCamera"
          },
          {
            "property" : "urlPath",
            "type" : "String"
          }
        ]
      }
    ],
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "properties" : [
         {
            "property" : "project",
            "type" : "R3EventObjProject"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeGuiTweakPane" :
    [
      {
        "methods" : ["createGui"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "isPlaying",
            "type" : "Boolean"
          }
        ]
      }
    ],
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["createInstance"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "loop",
            "type" : "Boolean"
          },
          {
            "property" : "volume",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.01
          },
          {
            "property" : "detune",
            "type" : "Number",
            "min" : -5000,
            "max" : 5000,
            "step" : 1
          },
          {
            "property" : "playbackRate",
            "type" : "Number",
            "min" : 0,
            "max" : 4,
            "step" : 0.01
          },
          {
            "property" : "overplay",
            "type" : "Boolean"
          },
          {
            "property" : "autoplay",
            "type" : "Boolean"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
  emitInitializeEvent(options) - Emits a component specific initialize event
  dispose() - Emits a component specific dispose event
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createInstance(_child = null, _parent = null) - Creates an audio runtime instance for this runtime based on the R3 Object 
  deleteInstance(_child = null, _parent = null) - Deletes an audio runtime instance for this runtime based on the R3 Object 
  pause() - Starts the audio object 
  play() - Starts the audio object 
  stop() - Stops the audio object 
  updateInstance(property, value) - Updates the audio runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Audio extends Component {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentAudio';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Audio';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param project
     * - All base components have a project
     */
    if (typeof options.project === 'undefined') {
      options.project = this.getCurrentProject();
    }

    /**
     * @param buffer
     * - The audio buffer where the raw audio data is accessed
     */
    if (typeof options.buffer === 'undefined') {
      options.buffer = null;
    }

    /**
     * @param loop
     * - When loop is true - the audio will repeat infinitely
     */
    if (typeof options.loop === 'undefined') {
      options.loop = false;
    }

    /**
     * @param volume
     * - The volume of the audio sample
     */
    if (typeof options.volume === 'undefined') {
      options.volume = 0.5;
    }

    /**
     * @param detune
     * - Modify this value to change the pitch / playback rate.
     */
    if (typeof options.detune === 'undefined') {
      options.detune = 0;
    }

    /**
     * @param playbackRate
     * - Similar to detune - changing the playback rate will modify the pitch
     */
    if (typeof options.playbackRate === 'undefined') {
      options.playbackRate = 1;
    }

    /**
     * @param isPlaying
     * - A flag indicating if this audio object is currently playing audio
     */
    if (typeof options.isPlaying === 'undefined') {
      options.isPlaying = false;
    }

    /**
     * @param camera
     * - The camera component is required for the audio sample to know its location in 3d space.
     */
    if (typeof options.camera === 'undefined') {
      options.camera = null;
    }

    /**
     * @param overplay
     * - When true, and you execute the play method of this audio component when it is already playing, a second instance
     *   of the audio will be created and played 'over' the currently playing audio. Setting this to false and then
     *   calling play while it is already playing will simply stop the current instance and restart it from the
     *   beginning. Useful for sound effects that need to be re-produced like gun shots which need to play over other
     *   existing gunshots.
     */
    if (typeof options.overplay === 'undefined') {
      options.overplay = false;
    }

    /**
     * @param autoplay
     * - When true, the audio will automatically start to play once it is loaded
     */
    if (typeof options.autoplay === 'undefined') {
      options.autoplay = false;
    }

    /**
     * @param contentType
     * - The mime type of the audio file
     */
    if (typeof options.contentType === 'undefined') {
      options.contentType = null;
    }

    /**
     * @param size
     * - The size of the audio file
     */
    if (typeof options.size === 'undefined') {
      options.size = 0;
    }

    /**
     * @param urlPath
     * - The path to the audio file relative to the API URL
     */
    if (typeof options.urlPath === 'undefined') {
      options.urlPath = null;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_AUDIO_THREE)) {
      options.runtimes[Runtime.KEY_AUDIO_THREE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_AUDIO_THREE]['requirements']) {
      options.runtimes[Runtime.KEY_AUDIO_THREE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_GUI_TWEAK_PANE)) {
      options.runtimes[Runtime.KEY_GUI_TWEAK_PANE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_GUI_TWEAK_PANE]['requirements']) {
      options.runtimes[Runtime.KEY_GUI_TWEAK_PANE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_AUDIO_THREE].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "project",
                "Constructor" : this.getClassConstructor("R3EventObjProject")
              },
              {
                "property": "camera",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCamera")
              },
              {
                "property": "urlPath",
                "Constructor" : this.getClassConstructor("String")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "properties": [
              {
                "property": "project",
                "Constructor" : this.getClassConstructor("R3EventObjProject")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_GUI_TWEAK_PANE].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "createGui"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "isPlaying",
                "Constructor" : this.getClassConstructor("Boolean")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "loop",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "volume",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.01
              },
              {
                "property": "detune",
                "Constructor" : this.getClassConstructor("Number"),
                "min": -5000,
                "max": 5000,
                "step": 1
              },
              {
                "property": "playbackRate",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 4,
                "step": 0.01
              },
              {
                "property": "overplay",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "autoplay",
                "Constructor" : this.getClassConstructor("Boolean")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_AUDIO_THREE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "project",
        "Constructor" : this.getClassConstructor("R3EventObjProject"),
        "type": "R3EventObjProject"
      },
      {
        "property": "camera",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCamera"),
        "type": "R3EventObjComponentGraphicsCamera"
      },
      {
        "property": "urlPath",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String"
      },
      {
        "property": "isPlaying",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "loop",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "volume",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.01
      },
      {
        "property": "detune",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": -5000,
        "max": 5000,
        "step": 1
      },
      {
        "property": "playbackRate",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 4,
        "step": 0.01
      },
      {
        "property": "overplay",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "autoplay",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * emitInitializeEvent()
   * - Emits a component specific initialize event
   * @param options
   * - No returns
   */
  emitInitializeEvent(options) {

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_END

    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_START
    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_END

    //GENERATED_EMIT_INITIALIZE_EVENT_START
    //GENERATED_EMIT_INITIALIZE_EVENT_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_START
    Event.Emit(
      Event.INITIALIZE_AUDIO_COMPONENT,
      {
        options,
        object : this
      }
    );
    //CUSTOM_EMIT_INITIALIZE_EVENT_END

    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_START
    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_END

  }

  /**
   * dispose()
   * - Emits a component specific dispose event
   * - No parameters
   * - No returns
   */
  dispose() {

    //CUSTOM_DISPOSE_BEFORE_START
    //CUSTOM_DISPOSE_BEFORE_END

    //GENERATED_DISPOSE_BEFORE_START
    //GENERATED_DISPOSE_BEFORE_END

    //CUSTOM_DISPOSE_BEFORE_GENERATED_START
    //CUSTOM_DISPOSE_BEFORE_GENERATED_END

    //GENERATED_DISPOSE_START
    let payload = {
      object : this
    };

    Event.Emit(
      Event.DISPOSE_AUDIO_COMPONENT,
      payload
    );
    //GENERATED_DISPOSE_END

    //CUSTOM_DISPOSE_START
    Event.Emit(
      Event.DISPOSE_COMPONENT,
      payload
    );
    //CUSTOM_DISPOSE_END

    //GENERATED_DISPOSE_AFTER_START
    //GENERATED_DISPOSE_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createInstance()
   * - Creates an audio runtime instance for this runtime based on the R3 Object
   * @param {Object|null} [_child=null]
   * @param {Object|null} [_parent=null]
   * - No returns
   */
  createInstance(_child = null, _parent = null) {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes an audio runtime instance for this runtime based on the R3 Object
   * @param {Object|null} [_child=null]
   * @param {Object|null} [_parent=null]
   * - No returns
   */
  deleteInstance(_child = null, _parent = null) {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * pause()
   * - Starts the audio object
   * - No parameters
   * - No returns
   */
  pause() {

    //CUSTOM_PAUSE_BEFORE_START
    //CUSTOM_PAUSE_BEFORE_END

    //GENERATED_PAUSE_BEFORE_START
    //GENERATED_PAUSE_BEFORE_END

    //CUSTOM_PAUSE_BEFORE_GENERATED_START
    //CUSTOM_PAUSE_BEFORE_GENERATED_END

    //GENERATED_PAUSE_START
    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.PAUSE_AUDIO,
      payload
    );
    //GENERATED_PAUSE_END

    //CUSTOM_PAUSE_START
    //CUSTOM_PAUSE_END

    //GENERATED_PAUSE_AFTER_START
    //GENERATED_PAUSE_AFTER_END

  }

  /**
   * play()
   * - Starts the audio object
   * - No parameters
   * - No returns
   */
  play() {

    //CUSTOM_PLAY_BEFORE_START
    //CUSTOM_PLAY_BEFORE_END

    //GENERATED_PLAY_BEFORE_START
    //GENERATED_PLAY_BEFORE_END

    //CUSTOM_PLAY_BEFORE_GENERATED_START
    //CUSTOM_PLAY_BEFORE_GENERATED_END

    //GENERATED_PLAY_START
    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.PLAY_AUDIO,
      payload
    );
    //GENERATED_PLAY_END

    //CUSTOM_PLAY_START
    //CUSTOM_PLAY_END

    //GENERATED_PLAY_AFTER_START
    //GENERATED_PLAY_AFTER_END

  }

  /**
   * stop()
   * - Stops the audio object
   * - No parameters
   * - No returns
   */
  stop() {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.STOP_AUDIO,
      payload
    );
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the audio runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Audio.Type = 'R3EventObjComponentAudio';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Audio as default};
