//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
import * as objImport from './obj/index.js';
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as Obj} from './Obj.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
const User = objImport.User;
const Project = objImport.Project;
const Group = objImport.Group;
const File = objImport.File;
const Entity = objImport.Entity;
const Component = objImport.Component;
Obj.User = User;
Obj.Project = Project;
Obj.Group = Group;
Obj.File = File;
Obj.Entity = Entity;
Obj.Component = Component;
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
//CUSTOM_ASSIGN_TO_BASE_END

//GENERATED_EXPORTS_START
export {
  Obj,
  User,
  Project,
  Group,
  File,
  Entity,
  Component,
//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END