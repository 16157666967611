//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
import * as systemImport from './system/index.js';
import * as runtimeImport from './runtime/index.js';
import * as eventImport from './event/index.js';
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as Utils} from './Utils.js';
import {default as System} from './System.js';
import {default as Runtime} from './Runtime.js';
import {default as Node} from './Node.js';
import {default as MockRapier} from './MockRapier.js';
import {default as Graph} from './Graph.js';
import {default as Event} from './Event.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
const WebsocketSystem = systemImport.Websocket;
const StorageSystem = systemImport.Storage;
const PhysicsSystem = systemImport.Physics;
const MathsSystem = systemImport.Maths;
const Linking = systemImport.Linking;
const InputSystem = systemImport.Input;
const GuiSystem = systemImport.Gui;
const GraphicsSystem = systemImport.Graphics;
const EntitySystem = systemImport.Entity;
const CodeSystem = systemImport.Code;
const AuthenticationSystem = systemImport.Authentication;
const AudioSystem = systemImport.Audio;
const WebsocketRuntime = runtimeImport.Websocket;
const StorageRuntime = runtimeImport.Storage;
const PhysicsRuntime = runtimeImport.Physics;
const MathsRuntime = runtimeImport.Maths;
const InputRuntime = runtimeImport.Input;
const GuiRuntime = runtimeImport.Gui;
const GraphicsRuntime = runtimeImport.Graphics;
const EntityRuntime = runtimeImport.Entity;
const CodeRuntime = runtimeImport.Code;
const AuthenticationRuntime = runtimeImport.Authentication;
const AudioRuntime = runtimeImport.Audio;
const Obj = eventImport.Obj;
System.Websocket = WebsocketSystem;
System.Storage = StorageSystem;
System.Physics = PhysicsSystem;
System.Maths = MathsSystem;
System.Linking = Linking;
System.Input = InputSystem;
System.Gui = GuiSystem;
System.Graphics = GraphicsSystem;
System.Entity = EntitySystem;
System.Code = CodeSystem;
System.Authentication = AuthenticationSystem;
System.Audio = AudioSystem;
Runtime.Websocket = WebsocketRuntime;
Runtime.Storage = StorageRuntime;
Runtime.Physics = PhysicsRuntime;
Runtime.Maths = MathsRuntime;
Runtime.Input = InputRuntime;
Runtime.Gui = GuiRuntime;
Runtime.Graphics = GraphicsRuntime;
Runtime.Entity = EntityRuntime;
Runtime.Code = CodeRuntime;
Runtime.Authentication = AuthenticationRuntime;
Runtime.Audio = AudioRuntime;
Event.Obj = Obj;
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
//CUSTOM_ASSIGN_TO_BASE_END

//GENERATED_EXPORTS_START
export {
  Utils,
  System,
  Runtime,
  Node,
  MockRapier,
  Graph,
  Event,
  WebsocketSystem,
  StorageSystem,
  PhysicsSystem,
  MathsSystem,
  Linking,
  InputSystem,
  GuiSystem,
  GraphicsSystem,
  EntitySystem,
  CodeSystem,
  AuthenticationSystem,
  AudioSystem,
  WebsocketRuntime,
  StorageRuntime,
  PhysicsRuntime,
  MathsRuntime,
  InputRuntime,
  GuiRuntime,
  GraphicsRuntime,
  EntityRuntime,
  CodeRuntime,
  AuthenticationRuntime,
  AudioRuntime,
  Obj,
//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
