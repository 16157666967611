//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import axios from 'axios';
axios.defaults.withCredentials = true;
import Event from '../../Event.js';
// const {DEBUG_MODE} = process.env;
//CUSTOM_IMPORTS_END

import Authentication from '../Authentication.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  currentUser=null
  apiUrl=null
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  async changeRole(object, role) - Changes a user account to Free, Standard or Business 
  async get(object) - Makes a request to the server to get the information about the user using the locally stored token. If the token is no longer valid, we will receive an error status code from the server. 
  async invite(object, user, group) - Invites a user via API URL to join a group 
  async login(object) - Logs in the object (typically a User) with a username and password 
  async logout(object) - Logs out the object (typically a User) 
  async passwordReset(object) - Sets the new password for a user 
  async register(object) - Registers in the object (typically a User) 
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
  throwWithMessage(error) - Attempts to get a good message about the error and throws it
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  async changeRole(object, role) - Changes a user account to Free, Standard or Business 
  async get(object) - Makes a request to the server to get the information about the user using the locally stored token. If the token is no longer valid, we will receive an error status code from the server. 
  async invite(object, user, group) - Invites a user via API URL to join a group 
  async login(object) - Logs in the object (typically a User) with a username and password 
  async logout(object) - Logs out the object (typically a User) 
  async passwordReset(object) - Sets the new password for a user 
  async register(object) - Registers in the object (typically a User) 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
  async changeRoleUser(object, role) - Axios implementation for [R3EventObjUser] 
  async getUser(object) - Axios implementation for [R3EventObjUser] 
  async inviteUser(object, user, group) - Axios implementation for [R3EventObjUser] 
  async loginUser(object) - Axios implementation for [R3EventObjUser] 
  async logoutUser(object) - Axios implementation for [R3EventObjUser] 
  async passwordResetUser(object) - Axios implementation for [R3EventObjUser] 
  async registerUser(object) - Axios implementation for [R3EventObjUser] 
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Axios extends Authentication {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeAuthentication';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeAuthenticationAxios';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'AxiosAuthentication';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_AUTHENTICATION;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param currentUser
     * - No comment
     */
    if (typeof options.currentUser === 'undefined') {
      options.currentUser = null;
    }

    /**
     * @param apiUrl
     * - No comment
     */
    if (typeof options.apiUrl === 'undefined') {
      options.apiUrl = null;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    Event.On(
      Event.SET_API_URL,
      (event) => {
        this.apiUrl = event.object;
        console.log(`Authentication URL set to ${this.apiUrl}`);
      },
      {
        priority : 90
      }
    );

    Event.On(
      Event.SET_CURRENT_USER,
      (event) => {
        this.currentUser = event.object;
      },
      {
        priority : 90
      }
    );
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * throwWithMessage()
   * - Attempts to get a good message about the error and throws it
   * @param error
   * - No returns
   */
  throwWithMessage(error) {

    //CUSTOM_THROW_WITH_MESSAGE_BEFORE_START
    //CUSTOM_THROW_WITH_MESSAGE_BEFORE_END

    //GENERATED_THROW_WITH_MESSAGE_BEFORE_START
    //GENERATED_THROW_WITH_MESSAGE_BEFORE_END

    //CUSTOM_THROW_WITH_MESSAGE_BEFORE_GENERATED_START
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received');
      console.error('Request data:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message:', error.message);
    }
    //CUSTOM_THROW_WITH_MESSAGE_BEFORE_GENERATED_END

    //GENERATED_THROW_WITH_MESSAGE_START
    let message = error.message;

    if (error.response && error.response.data && error.response.data.message) {
      message = error.response.data.message
    }

    throw new Error(
      message
    );
    //GENERATED_THROW_WITH_MESSAGE_END

    //CUSTOM_THROW_WITH_MESSAGE_START
    //CUSTOM_THROW_WITH_MESSAGE_END

    //GENERATED_THROW_WITH_MESSAGE_AFTER_START
    //GENERATED_THROW_WITH_MESSAGE_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * changeRole()
   * - Changes a user account to Free, Standard or Business
   * @param object
   * @param role
   * - No returns
   */
  async changeRole(object, role) {

    //CUSTOM_CHANGE_ROLE_BEFORE_START
    //CUSTOM_CHANGE_ROLE_BEFORE_END

    //GENERATED_CHANGE_ROLE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_CHANGE_ROLE_START
    await super.changeRole(object, role);
    //CUSTOM_RETURN_GENERATED_CHANGE_ROLE_END
    //GENERATED_CHANGE_ROLE_BEFORE_END

    //CUSTOM_CHANGE_ROLE_BEFORE_GENERATED_START
    //CUSTOM_CHANGE_ROLE_BEFORE_GENERATED_END

    //GENERATED_CHANGE_ROLE_START
    if (
      object.type === 'R3EventObjUser'
    ) {
      //CUSTOM_RETURN_OPTION_CHANGE_ROLE_USER_START
      return await this.changeRoleUser(object, role);
      //CUSTOM_RETURN_OPTION_CHANGE_ROLE_USER_END
    }
    //GENERATED_CHANGE_ROLE_END

    //CUSTOM_CHANGE_ROLE_START
    //CUSTOM_CHANGE_ROLE_END

    //GENERATED_CHANGE_ROLE_AFTER_START
    throw new Error(`No suitable method found in changeRole() in runtime R3RuntimeAuthenticationAxios`);
    //GENERATED_CHANGE_ROLE_AFTER_END

  }

  /**
   * get()
   * - Makes a request to the server to get the information about the user using the locally stored token. If the token
   *   is no longer valid, we will receive an error status code from the server.
   * @param object
   * - No returns
   */
  async get(object) {

    //CUSTOM_GET_BEFORE_START
    //CUSTOM_GET_BEFORE_END

    //GENERATED_GET_BEFORE_START
    //CUSTOM_RETURN_GENERATED_GET_START
    await super.get(object);
    //CUSTOM_RETURN_GENERATED_GET_END
    //GENERATED_GET_BEFORE_END

    //CUSTOM_GET_BEFORE_GENERATED_START
    //CUSTOM_GET_BEFORE_GENERATED_END

    //GENERATED_GET_START
    if (
      object.type === 'R3EventObjUser'
    ) {
      //CUSTOM_RETURN_OPTION_GET_USER_START
      return await this.getUser(object);
      //CUSTOM_RETURN_OPTION_GET_USER_END
    }
    //GENERATED_GET_END

    //CUSTOM_GET_START
    //CUSTOM_GET_END

    //GENERATED_GET_AFTER_START
    throw new Error(`No suitable method found in get() in runtime R3RuntimeAuthenticationAxios`);
    //GENERATED_GET_AFTER_END

  }

  /**
   * invite()
   * - Invites a user via API URL to join a group
   * @param object
   * @param user
   * @param group
   * - No returns
   */
  async invite(object, user, group) {

    //CUSTOM_INVITE_BEFORE_START
    //CUSTOM_INVITE_BEFORE_END

    //GENERATED_INVITE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_INVITE_START
    await super.invite(object, user, group);
    //CUSTOM_RETURN_GENERATED_INVITE_END
    //GENERATED_INVITE_BEFORE_END

    //CUSTOM_INVITE_BEFORE_GENERATED_START
    //CUSTOM_INVITE_BEFORE_GENERATED_END

    //GENERATED_INVITE_START
    if (
      object.type === 'R3EventObjUser'
    ) {
      //CUSTOM_RETURN_OPTION_INVITE_USER_START
      return await this.inviteUser(object, user, group);
      //CUSTOM_RETURN_OPTION_INVITE_USER_END
    }
    //GENERATED_INVITE_END

    //CUSTOM_INVITE_START
    //CUSTOM_INVITE_END

    //GENERATED_INVITE_AFTER_START
    throw new Error(`No suitable method found in invite() in runtime R3RuntimeAuthenticationAxios`);
    //GENERATED_INVITE_AFTER_END

  }

  /**
   * login()
   * - Logs in the object (typically a User) with a username and password
   * @param object
   * - No returns
   */
  async login(object) {

    //CUSTOM_LOGIN_BEFORE_START
    //CUSTOM_LOGIN_BEFORE_END

    //GENERATED_LOGIN_BEFORE_START
    //CUSTOM_RETURN_GENERATED_LOGIN_START
    await super.login(object);
    //CUSTOM_RETURN_GENERATED_LOGIN_END
    //GENERATED_LOGIN_BEFORE_END

    //CUSTOM_LOGIN_BEFORE_GENERATED_START
    //CUSTOM_LOGIN_BEFORE_GENERATED_END

    //GENERATED_LOGIN_START
    if (
      object.type === 'R3EventObjUser'
    ) {
      //CUSTOM_RETURN_OPTION_LOGIN_USER_START
      return await this.loginUser(object);
      //CUSTOM_RETURN_OPTION_LOGIN_USER_END
    }
    //GENERATED_LOGIN_END

    //CUSTOM_LOGIN_START
    //CUSTOM_LOGIN_END

    //GENERATED_LOGIN_AFTER_START
    throw new Error(`No suitable method found in login() in runtime R3RuntimeAuthenticationAxios`);
    //GENERATED_LOGIN_AFTER_END

  }

  /**
   * logout()
   * - Logs out the object (typically a User)
   * @param object
   * - No returns
   */
  async logout(object) {

    //CUSTOM_LOGOUT_BEFORE_START
    //CUSTOM_LOGOUT_BEFORE_END

    //GENERATED_LOGOUT_BEFORE_START
    //CUSTOM_RETURN_GENERATED_LOGOUT_START
    await super.logout(object);
    //CUSTOM_RETURN_GENERATED_LOGOUT_END
    //GENERATED_LOGOUT_BEFORE_END

    //CUSTOM_LOGOUT_BEFORE_GENERATED_START
    //CUSTOM_LOGOUT_BEFORE_GENERATED_END

    //GENERATED_LOGOUT_START
    if (
      object.type === 'R3EventObjUser'
    ) {
      //CUSTOM_RETURN_OPTION_LOGOUT_USER_START
      return await this.logoutUser(object);
      //CUSTOM_RETURN_OPTION_LOGOUT_USER_END
    }
    //GENERATED_LOGOUT_END

    //CUSTOM_LOGOUT_START
    //CUSTOM_LOGOUT_END

    //GENERATED_LOGOUT_AFTER_START
    throw new Error(`No suitable method found in logout() in runtime R3RuntimeAuthenticationAxios`);
    //GENERATED_LOGOUT_AFTER_END

  }

  /**
   * passwordReset()
   * - Sets the new password for a user
   * @param object
   * - No returns
   */
  async passwordReset(object) {

    //CUSTOM_PASSWORD_RESET_BEFORE_START
    //CUSTOM_PASSWORD_RESET_BEFORE_END

    //GENERATED_PASSWORD_RESET_BEFORE_START
    //CUSTOM_RETURN_GENERATED_PASSWORD_RESET_START
    await super.passwordReset(object);
    //CUSTOM_RETURN_GENERATED_PASSWORD_RESET_END
    //GENERATED_PASSWORD_RESET_BEFORE_END

    //CUSTOM_PASSWORD_RESET_BEFORE_GENERATED_START
    //CUSTOM_PASSWORD_RESET_BEFORE_GENERATED_END

    //GENERATED_PASSWORD_RESET_START
    if (
      object.type === 'R3EventObjUser'
    ) {
      //CUSTOM_RETURN_OPTION_PASSWORD_RESET_USER_START
      return await this.passwordResetUser(object);
      //CUSTOM_RETURN_OPTION_PASSWORD_RESET_USER_END
    }
    //GENERATED_PASSWORD_RESET_END

    //CUSTOM_PASSWORD_RESET_START
    //CUSTOM_PASSWORD_RESET_END

    //GENERATED_PASSWORD_RESET_AFTER_START
    throw new Error(`No suitable method found in passwordReset() in runtime R3RuntimeAuthenticationAxios`);
    //GENERATED_PASSWORD_RESET_AFTER_END

  }

  /**
   * register()
   * - Registers in the object (typically a User)
   * @param object
   * - No returns
   */
  async register(object) {

    //CUSTOM_REGISTER_BEFORE_START
    //CUSTOM_REGISTER_BEFORE_END

    //GENERATED_REGISTER_BEFORE_START
    //CUSTOM_RETURN_GENERATED_REGISTER_START
    await super.register(object);
    //CUSTOM_RETURN_GENERATED_REGISTER_END
    //GENERATED_REGISTER_BEFORE_END

    //CUSTOM_REGISTER_BEFORE_GENERATED_START
    //CUSTOM_REGISTER_BEFORE_GENERATED_END

    //GENERATED_REGISTER_START
    if (
      object.type === 'R3EventObjUser'
    ) {
      //CUSTOM_RETURN_OPTION_REGISTER_USER_START
      return await this.registerUser(object);
      //CUSTOM_RETURN_OPTION_REGISTER_USER_END
    }
    //GENERATED_REGISTER_END

    //CUSTOM_REGISTER_START
    //CUSTOM_REGISTER_END

    //GENERATED_REGISTER_AFTER_START
    throw new Error(`No suitable method found in register() in runtime R3RuntimeAuthenticationAxios`);
    //GENERATED_REGISTER_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  /**
   * changeRoleUser()
   * - Axios implementation for [R3EventObjUser]
   * @param object
   * @param role
   * - No returns
   */
  async changeRoleUser(object, role) {

    //GENERATED_CHANGE_ROLE_USER_BEFORE_START
    //GENERATED_CHANGE_ROLE_USER_BEFORE_END

    //GENERATED_CHANGE_ROLE_USER_START
    //GENERATED_CHANGE_ROLE_USER_END

    //CUSTOM_CHANGE_ROLE_USER_START
    let payload = {};

    Event.Emit(
      Event.GET_R3_EVENT_OBJ_USER_CONSTRUCTOR,
      payload
    );

    let User = payload.results[0];

    if (
      role !== User.ROLE_USER &&
      role !== User.ROLE_STANDARD &&
      role !== User.ROLE_BUSINESS
    ) {
      throw new Error(`Invalid role specified : ${role}`);
    }

    try {

      let result;

      let apiObject = object.toApiObject();

      delete apiObject.parents;
      delete apiObject.children;
      delete apiObject.group;
      delete apiObject.groups;

      let path = null;

      if (role === User.ROLE_USER) {
        path = 'free';
      }

      if (role === User.ROLE_STANDARD) {
        path = 'standard';
      }

      if (role === User.ROLE_BUSINESS) {
        path = 'business';
      }

      result = await axios.post(
        `${this.apiUrl}/change-role/${path}`,
        apiObject
      );

      return result.data;

    } catch (err) {

      this.throwWithMessage(err);

    }
    //CUSTOM_CHANGE_ROLE_USER_END

    //GENERATED_CHANGE_ROLE_USER_AFTER_START
    //GENERATED_CHANGE_ROLE_USER_AFTER_END

  }

  /**
   * getUser()
   * - Axios implementation for [R3EventObjUser]
   * @param object
   * - No returns
   */
  async getUser(object) {

    //GENERATED_GET_USER_BEFORE_START
    //GENERATED_GET_USER_BEFORE_END

    //GENERATED_GET_USER_START
    //GENERATED_GET_USER_END

    //CUSTOM_GET_USER_START
    if (!this.apiUrl) {
      throw new Error(`Authentication URL not set`);
    }

    try {

      let options = {};

      // if (DEBUG_MODE === 'true') {
      //   options.headers = {
      //     'Cookie' : `authToken=${process.env.AUTH_TOKEN}`
      //   }
      // }

      let result = await axios.get(
        `${this.apiUrl}/get-user`,
        options
      );

      let {user:apiUser} = result.data;

      return apiUser;

    } catch (err) {

      this.throwWithMessage(err);

    }
    //CUSTOM_GET_USER_END

    //GENERATED_GET_USER_AFTER_START
    //GENERATED_GET_USER_AFTER_END

  }

  /**
   * inviteUser()
   * - Axios implementation for [R3EventObjUser]
   * @param object
   * @param user
   * @param group
   * - No returns
   */
  async inviteUser(object, user, group) {

    //GENERATED_INVITE_USER_BEFORE_START
    //GENERATED_INVITE_USER_BEFORE_END

    //GENERATED_INVITE_USER_START
    //GENERATED_INVITE_USER_END

    //CUSTOM_INVITE_USER_START
    throw new Error(`Please implement Axios.inviteUser(object, user, group) in R3RuntimeAuthenticationAxios`);
    //CUSTOM_INVITE_USER_END

    //GENERATED_INVITE_USER_AFTER_START
    //GENERATED_INVITE_USER_AFTER_END

  }

  /**
   * loginUser()
   * - Axios implementation for [R3EventObjUser]
   * @param object
   * - No returns
   */
  async loginUser(object) {

    //GENERATED_LOGIN_USER_BEFORE_START
    //GENERATED_LOGIN_USER_BEFORE_END

    //GENERATED_LOGIN_USER_START
    //GENERATED_LOGIN_USER_END

    //CUSTOM_LOGIN_USER_START
    if (!this.apiUrl) {
      throw new Error(`Authentication URL not set`);
    }

    try {

      let result;

      let apiObject = object.toApiObject();

      delete apiObject.parents;
      delete apiObject.children;
      delete apiObject.group;
      delete apiObject.groups;

      result = await axios.post(
        `${this.apiUrl}/login`,
        apiObject
      )

      let {user:apiUser} = result.data;

      return apiUser;

    } catch (err) {

      this.throwWithMessage(err);

    }
    //CUSTOM_LOGIN_USER_END

    //GENERATED_LOGIN_USER_AFTER_START
    //GENERATED_LOGIN_USER_AFTER_END

  }

  /**
   * logoutUser()
   * - Axios implementation for [R3EventObjUser]
   * @param object
   * - No returns
   */
  async logoutUser(object) {

    //GENERATED_LOGOUT_USER_BEFORE_START
    //GENERATED_LOGOUT_USER_BEFORE_END

    //GENERATED_LOGOUT_USER_START
    //GENERATED_LOGOUT_USER_END

    //CUSTOM_LOGOUT_USER_START
    if (!this.apiUrl) {
      throw new Error(`Authentication URL not set`);
    }

    try {

      await axios.get(
        `${this.apiUrl}/logout`
      );

      return null;

    } catch (err) {

      this.throwWithMessage(err);

    }
    //CUSTOM_LOGOUT_USER_END

    //GENERATED_LOGOUT_USER_AFTER_START
    //GENERATED_LOGOUT_USER_AFTER_END

  }

  /**
   * passwordResetUser()
   * - Axios implementation for [R3EventObjUser]
   * @param object
   * - No returns
   */
  async passwordResetUser(object) {

    //GENERATED_PASSWORD_RESET_USER_BEFORE_START
    //GENERATED_PASSWORD_RESET_USER_BEFORE_END

    //GENERATED_PASSWORD_RESET_USER_START
    //GENERATED_PASSWORD_RESET_USER_END

    //CUSTOM_PASSWORD_RESET_USER_START
    if (!this.apiUrl) {
      throw new Error(`Authentication URL not set`);
    }

    try {
      let result = await axios.post(
        `${this.apiUrl}/password-reset`,
        {
          username: object.username,
          newPassword: object.newPassword,
          newPasswordRepeat: object.newPasswordRepeat
        }
      );

      let {user} = result.data;

      Object.assign(object, user);

      return user;

    } catch (err) {

      this.throwWithMessage(err);

    }
    //CUSTOM_PASSWORD_RESET_USER_END

    //GENERATED_PASSWORD_RESET_USER_AFTER_START
    //GENERATED_PASSWORD_RESET_USER_AFTER_END

  }

  /**
   * registerUser()
   * - Axios implementation for [R3EventObjUser]
   * @param object
   * - No returns
   */
  async registerUser(object) {

    //GENERATED_REGISTER_USER_BEFORE_START
    //GENERATED_REGISTER_USER_BEFORE_END

    //GENERATED_REGISTER_USER_START
    //GENERATED_REGISTER_USER_END

    //CUSTOM_REGISTER_USER_START
    if (!this.apiUrl) {
      throw new Error(`Authentication URL not set`);
    }

    try {
      let apiObject = object.toApiObject();

      delete apiObject.parents;
      delete apiObject.children;
      delete apiObject.group;
      delete apiObject.groups;

      let result = await axios.post(
        `${this.apiUrl}/register`,
        apiObject
      );

      let {user} = result.data;

      Object.assign(object, user);

      return user;

    } catch (err) {

      this.throwWithMessage(err);

    }
    //CUSTOM_REGISTER_USER_END

    //GENERATED_REGISTER_USER_AFTER_START
    //GENERATED_REGISTER_USER_AFTER_END

  }

  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Axios.Type = 'R3RuntimeAuthenticationAxios';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Axios as default};
