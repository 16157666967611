//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Runtime from '../Runtime.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  activate(object) - Starts an entity or code component by subscribing to all events of the code components
  deActivate(object) - Stops an entity or code component by unsubscribing to all events of the code components
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  activate(object) - Starts an entity or code component by subscribing to all events of the code components 
  deActivate(object) - Stops an entity or code component by unsubscribing to all events of the code components 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Entity extends Runtime {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeEntity';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeEntity';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'EntityRuntime';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_ENTITY;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * activate()
   * - Starts an entity or code component by subscribing to all events of the code components
   * @param object
   * - No returns
   */
  activate(object) {

    //CUSTOM_ACTIVATE_BEFORE_START
    //CUSTOM_ACTIVATE_BEFORE_END

    //GENERATED_ACTIVATE_BEFORE_START
    //GENERATED_ACTIVATE_BEFORE_END

    //CUSTOM_ACTIVATE_BEFORE_GENERATED_START
    //CUSTOM_ACTIVATE_BEFORE_GENERATED_END

    //GENERATED_ACTIVATE_START
    //GENERATED_ACTIVATE_END

    //CUSTOM_ACTIVATE_START
    //CUSTOM_ACTIVATE_END

    //GENERATED_ACTIVATE_AFTER_START
    //GENERATED_ACTIVATE_AFTER_END

  }

  /**
   * deActivate()
   * - Stops an entity or code component by unsubscribing to all events of the code components
   * @param object
   * - No returns
   */
  deActivate(object) {

    //CUSTOM_DE_ACTIVATE_BEFORE_START
    //CUSTOM_DE_ACTIVATE_BEFORE_END

    //GENERATED_DE_ACTIVATE_BEFORE_START
    //GENERATED_DE_ACTIVATE_BEFORE_END

    //CUSTOM_DE_ACTIVATE_BEFORE_GENERATED_START
    //CUSTOM_DE_ACTIVATE_BEFORE_GENERATED_END

    //GENERATED_DE_ACTIVATE_START
    //GENERATED_DE_ACTIVATE_END

    //CUSTOM_DE_ACTIVATE_START
    //CUSTOM_DE_ACTIVATE_END

    //GENERATED_DE_ACTIVATE_AFTER_START
    //GENERATED_DE_ACTIVATE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Entity.Type = 'R3RuntimeEntity';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Entity as default};
