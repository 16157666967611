//GENERATED_IMPORTS_START
import {default as PhysicsComponent} from '../event/obj/component/Physics.js';
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START
import {default as RapierRuntime} from '../runtime/physics/Rapier.js';
//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
import World from '../event/obj/component/physics/World.js';
import RigidBody from '../event/obj/component/physics/RigidBody.js';
import {default as DynamicRigidBody} from '../event/obj/component/physics/rigidbody/Dynamic.js';
import Collider from '../event/obj/component/physics/Collider.js';
import Cuboid from '../event/obj/component/physics/collider/Cuboid.js';
import {default as MathsComponent} from '../event/obj/component/Maths.js';
import Mesh from '../event/obj/component/graphics/Mesh.js';
import Gravity from '../event/obj/component/physics/Gravity.js';
import Utils from '../Utils.js';
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START
  RuntimePhysicsRapier = new RapierRuntime() - Runtime implementation of R3RuntimePhysics
 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Components=[]
  Worlds=[]
  RigidBodies=[]
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.RAPIER_RUNTIME_READY(10)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START

 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  Event.GET_RUNTIME_PHYSICS_RAPIER - Gets the Rapier runtime implementation of R3RuntimePhysics
 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options = {}) - Starts the system by registering subscriptions to events
  stop(options = {}) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  UpdateDynamicRigidBody(rigidBody, instance) - Updates the rigidBody from the instance
  UpdateCollider(collider, instance) - Updates the collider from the instance
  InitializePhysicsComponent(event)
  DisposePhysicsComponent(event)
  BeforeAssignProperty(event)
  AfterAssignProperty(event)
  ApplyForce(event)
  ApplyTorque(event)
  RenderBefore(event)
  AddSubscriptions() - Adding subscriptions manually when Rapier is ready, because we cannot have a top-level await for loading web assemblies
 CUSTOM_STATIC_METHODS_END

 **/

export class Physics extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Physics already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Physics');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Physics already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Physics');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Physics.IsStarting) {
      console.log('client Physics system is already starting...');
      return;
    }

    Physics.IsStarting = true;

    if (Physics.Started === true) {
      Physics.IsStarting = false;
      console.log('client Physics system already started');
      return;
    }

    Physics.Runtimes = new Set();
    Physics.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Physics.Subscriptions['RAPIER_RUNTIME_READY'] = Event.On(
      Event.RAPIER_RUNTIME_READY,
      Physics.RapierRuntimeReady,
      {priority: 10}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    /**
     * Gets the Rapier runtime implementation of R3RuntimePhysics
     */
    Physics.Subscriptions['GET_RUNTIME_PHYSICS_RAPIER'] = Event.On(
      Event.GET_RUNTIME_PHYSICS_RAPIER,
      Physics.GetRuntimePhysicsRapier
    );
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    if (RapierRuntime.Ready) {
      Physics.AddSubscriptions();
    }
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Physics.IsStopping) {
      console.log('client Physics system is already stopping');
      return;
    }

    Physics.IsStopping = true;

    if (Physics.Started === false) {
      Physics.IsStopping = false;
      console.log('client Physics system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    if (RapierRuntime.Ready) {
      Physics.Subscriptions['INITIALIZE_PHYSICS_COMPONENT'].remove();
      delete Physics.Subscriptions['INITIALIZE_PHYSICS_COMPONENT'];

      Physics.Subscriptions['DISPOSE_PHYSICS_COMPONENT'].remove();
      delete Physics.Subscriptions['DISPOSE_PHYSICS_COMPONENT'];

      Physics.Subscriptions['BEFORE_ASSIGN_PROPERTY'].remove();
      delete Physics.Subscriptions['BEFORE_ASSIGN_PROPERTY'];

      Physics.Subscriptions['AFTER_ASSIGN_PROPERTY'].remove();
      delete Physics.Subscriptions['AFTER_ASSIGN_PROPERTY'];

      Physics.Subscriptions['APPLY_FORCE'].remove();
      delete Physics.Subscriptions['APPLY_FORCE'];

      Physics.Subscriptions['APPLY_TORQUE'].remove();
      delete Physics.Subscriptions['APPLY_TORQUE'];

      Physics.Subscriptions['RENDER_BEFORE'].remove();
      delete Physics.Subscriptions['RENDER_BEFORE'];
    } else {
      Event.Once(
        Event.PHYSICS_SYSTEM_STARTED,
        Physics.Stop,
        {
          priority : 10
        }
      );
    }
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    Physics.Subscriptions['RAPIER_RUNTIME_READY'].remove();
    delete Physics.Subscriptions['RAPIER_RUNTIME_READY'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    Physics.Subscriptions['GET_RUNTIME_PHYSICS_RAPIER'].remove();
    delete Physics.Subscriptions['GET_RUNTIME_PHYSICS_RAPIER'];
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    Physics.Worlds = [];
    Physics.RigidBodies = [];
    Physics.Components = [];

    /**
     * Clear the validation flags because we do not know when next this system will be started
     */
    for (let runtime of [...Physics.Runtimes]) {
      runtime.validObjects = {};
    }

    Physics.Runtimes.clear();
    Physics.Started = false;
    Physics.IsStopping = false;
    console.log('Stopped client system Physics');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START
    Physics.Runtimes.add(Physics.RuntimePhysicsRapier);
    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * UpdateDynamicRigidBody()
   * - Updates the rigidBody from the instance
   * @param rigidBody
   * @param instance
   * - No returns
   */
  static UpdateDynamicRigidBody(rigidBody, instance) {

    //GENERATED_STATIC_UPDATE_DYNAMIC_RIGID_BODY_START
    //GENERATED_STATIC_UPDATE_DYNAMIC_RIGID_BODY_END

    //CUSTOM_STATIC_UPDATE_DYNAMIC_RIGID_BODY_START
    let position = instance.translation();
    let quaternion = instance.rotation();
    let linearVelocity = instance.linvel();
    let angularVelocity = instance.angvel();

    rigidBody.updateFromSystem = true;

    if (rigidBody.position.x !== position.x) {
      rigidBody.position.x = position.x;
    }

    if (rigidBody.position.y !== position.y) {
      rigidBody.position.y = position.y;
    }

    if (rigidBody.position.z !== position.z) {
      rigidBody.position.z = position.z;
    }

    if (rigidBody.quaternion.w !== quaternion.w) {
      rigidBody.quaternion.w = quaternion.w;
    }

    if (rigidBody.quaternion.x !== quaternion.x) {
      rigidBody.quaternion.x = quaternion.x;
    }

    if (rigidBody.quaternion.y !== quaternion.y) {
      rigidBody.quaternion.y = quaternion.y;
    }

    if (rigidBody.quaternion.z !== quaternion.z) {
      rigidBody.quaternion.z = quaternion.z;
    }

    if (rigidBody.linearVelocity.x !== linearVelocity.x) {
      rigidBody.linearVelocity.x = linearVelocity.x;
    }

    if (rigidBody.linearVelocity.y !== linearVelocity.y) {
      rigidBody.linearVelocity.y = linearVelocity.y;
    }

    if (rigidBody.linearVelocity.z !== linearVelocity.z) {
      rigidBody.linearVelocity.z = linearVelocity.z;
    }

    if (rigidBody.angularVelocity.x !== angularVelocity.x) {
      rigidBody.angularVelocity.x = angularVelocity.x;
    }

    if (rigidBody.angularVelocity.y !== angularVelocity.y) {
      rigidBody.angularVelocity.y = angularVelocity.y;
    }

    if (rigidBody.angularVelocity.z !== angularVelocity.z) {
      rigidBody.angularVelocity.z = angularVelocity.z;
    }

    rigidBody.updateFromSystem = false;
    //CUSTOM_STATIC_UPDATE_DYNAMIC_RIGID_BODY_END

    //GENERATED_STATIC_UPDATE_DYNAMIC_RIGID_BODY_AFTER_START
    //GENERATED_STATIC_UPDATE_DYNAMIC_RIGID_BODY_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPDATE_DYNAMIC_RIGID_BODY_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPDATE_DYNAMIC_RIGID_BODY_AFTER_END

  }
  /**
   * UpdateCollider()
   * - Updates the collider from the instance
   * @param collider
   * @param instance
   * - No returns
   */
  static UpdateCollider(collider, instance) {

    //GENERATED_STATIC_UPDATE_COLLIDER_START
    //GENERATED_STATIC_UPDATE_COLLIDER_END

    //CUSTOM_STATIC_UPDATE_COLLIDER_START
    //CUSTOM_STATIC_UPDATE_COLLIDER_END

    //GENERATED_STATIC_UPDATE_COLLIDER_AFTER_START
    //GENERATED_STATIC_UPDATE_COLLIDER_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPDATE_COLLIDER_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPDATE_COLLIDER_AFTER_END

  }
  /**
   * InitializePhysicsComponent()
   * - No comment
   * @param event
   * - No returns
   */
  static InitializePhysicsComponent(event) {

    //GENERATED_STATIC_INITIALIZE_PHYSICS_COMPONENT_START
    //GENERATED_STATIC_INITIALIZE_PHYSICS_COMPONENT_END

    //CUSTOM_STATIC_INITIALIZE_PHYSICS_COMPONENT_START
    let {object: component} = event;

    Physics.Components.push(component);

    if (component instanceof World) {
      Physics.Worlds.push(component);
    }

    if (component instanceof RigidBody) {
      Physics.RigidBodies.push(component);
    }

    for (let runtime of [...Physics.Runtimes]) {

      let instance = component.getInstance(runtime);

      if (instance) {
        continue;
      }

      let valid;

      if (!runtime.validObjects.hasOwnProperty(component.id)) {
        valid = runtime.validate('createInstance', component);
      }

      if (valid) {
        component.setInstance(runtime, runtime.createInstance(component));
        System.CreateRuntimeChildrenInstances(component, runtime, PhysicsComponent);
      }

    }
    //CUSTOM_STATIC_INITIALIZE_PHYSICS_COMPONENT_END

    //GENERATED_STATIC_INITIALIZE_PHYSICS_COMPONENT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_PHYSICS_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_PHYSICS_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_PHYSICS_COMPONENT_AFTER_END

  }
  /**
   * DisposePhysicsComponent()
   * - No comment
   * @param event
   * - No returns
   */
  static DisposePhysicsComponent(event) {

    //GENERATED_STATIC_DISPOSE_PHYSICS_COMPONENT_START
    //GENERATED_STATIC_DISPOSE_PHYSICS_COMPONENT_END

    //CUSTOM_STATIC_DISPOSE_PHYSICS_COMPONENT_START
    let {object: component} = event;

    for (let runtime of [...Physics.Runtimes]) {

      delete runtime.validObjects[component.id];

      let instance = component.getInstance(runtime);

      if (!instance) {
        continue;
      }

      runtime.deleteInstance(component);

      component.setInstance(runtime, null);
    }

    Physics.Components = Physics.Components.filter((physics)=>{return physics !== component});

    if (component instanceof World) {
      Physics.Worlds = Physics.Worlds.filter((physics)=>{return physics !== component});
    }

    if (component instanceof RigidBody) {
      Physics.RigidBodies = Physics.RigidBodies.filter((body)=>{return body !== component});
    }
    //CUSTOM_STATIC_DISPOSE_PHYSICS_COMPONENT_END

    //GENERATED_STATIC_DISPOSE_PHYSICS_COMPONENT_AFTER_START
    //GENERATED_STATIC_DISPOSE_PHYSICS_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_PHYSICS_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_PHYSICS_COMPONENT_AFTER_END

  }
  /**
   * BeforeAssignProperty()
   * - No comment
   * @param event
   * - No returns
   */
  static BeforeAssignProperty(event) {

    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_START
    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_BEFORE_ASSIGN_PROPERTY_START
    let {object: component} = event;
    let {property, value} = event;
    if (
      (
        component instanceof Collider ||
        component instanceof RigidBody
      ) &&
      property === 'world' &&
      value === null
    ) {
      for (let runtime of [...Physics.Runtimes]) {
        System.DeleteRuntimeInstance(component, runtime, PhysicsComponent);
      }
    }

    if (
      (component instanceof RigidBody) &&
      property === 'mesh' &&
      (value instanceof Mesh)
    ) {

      /**
       * Ensure the mesh does not already belong to a collider
       */
      let children = value.children.filter(
        (child) => {
          return (child instanceof Collider);
        }
      );

      if (children.length) {
        throw new Error('A mesh cannot be part of a collider and a rigid body');
      }
    }
    //CUSTOM_STATIC_BEFORE_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_BEFORE_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_BEFORE_ASSIGN_PROPERTY_AFTER_END

  }
  /**
   * AfterAssignProperty()
   * - No comment
   * @param event
   * - No returns
   */
  static AfterAssignProperty(event) {

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    let {object: component} = event;

    let {property, value} = event;

    if (
      component instanceof Mesh
    ) {
      if (
        property === 'width' ||
        property === 'height' ||
        property === 'depth'
      ) {

        /**
         * Colliders have 'size' - so set it here
         */
        let children = component.children.filter(
          (child) => {
            return (
              (child instanceof Collider)
            );
          }
        )

        for (let child of children) {

          if (child instanceof Cuboid) {

            if (
              property === 'width'
            ) {
              child.hx = component.width / 2;
            }

            if (
              property === 'height'
            ) {
              child.hy = component.height / 2;
            }

            if (
              property === 'depth'
            ) {
              child.hz = component.depth / 2;
            }

          }


        }
      }

      return;
    }

    if (
      component instanceof World ||
      component instanceof Gravity ||
      component instanceof Collider ||
      component instanceof RigidBody
    ) {
      for (let runtime of [...Physics.Runtimes]) {

        let wasValid;
        let isValid;

        if (!runtime.validObjects.hasOwnProperty(component.id)) {
          wasValid = false;
          isValid = runtime.validate('createInstance', component);
        } else {
          wasValid = runtime.validObjects[component.id];
          isValid = runtime.validate("createInstance", component);
        }

        if (!wasValid && isValid) {
          System.CreateRuntimeInstance(component, runtime, PhysicsComponent);
        }

        if (wasValid && !isValid) {
          System.DeleteRuntimeInstance(component, runtime, PhysicsComponent);
        }

        if (wasValid && isValid) {
          let instance = component.getInstance(runtime);
          if (!instance) {
            continue;
          }

          runtime.updateInstance(component, property, value);
        }
      }

      if (
        component instanceof Gravity
      ) {

        let worlds = component.children.filter(
          (child) => {
            return (child instanceof World);
          }
        )

        for (let world of worlds) {
          for (let runtime of [...Physics.Runtimes]) {
            let instance = world.getInstance(runtime);
            if (!instance) {
              continue;
            }

            runtime.updateInstance(world, 'gravity', component);
          }
        }
      }
    }

    if (component instanceof MathsComponent) {

      let rigidBodies = component.children.filter(
        (child) => {

          /**
           * Check if a math property of a mesh which is parent to a rigidBody changed,
           * and if so, update that property of the rigidBody. But also don't update a rigidBody
           * that belongs to the mesh, when the rigidBody initiated the update
           */
          if (child instanceof Mesh) {
            let mesh = child;
            if (mesh.updateFromRigidBody) {
              return false;
            }
            child.children.map(
              (meshChild) => {
                if (meshChild instanceof RigidBody) {
                  let rigidBody = meshChild;
                  for (let tmpProperty of Object.keys(rigidBody.references)) {
                    if (rigidBody[tmpProperty] === mesh) {
                      for (let runtime of [...Physics.Runtimes]) {

                        let instance = rigidBody.getInstance(runtime);
                        if (!instance) {
                          continue;
                        }

                        runtime.updateInstance(rigidBody, tmpProperty, mesh);
                      }
                    }
                  }
                }
              }
            )
          }

          return (child instanceof RigidBody)
        }
      );

      for (let rigidBody of rigidBodies) {

        if (
          rigidBody.createInstanceUpdate
        ) {
          continue;
        }

        /**
         * We process rigid bodies first - if the math component belonged to a rigidBody - it was
         * because it was updated by the physics system, so we process the rigidBody and stop
         */
        for (let tmpProperty of Object.keys(rigidBody.references)) {

          if (rigidBody[tmpProperty] === component) {

            if (
              tmpProperty === 'position' ||
              tmpProperty === 'quaternion'
            ) {

              for (let runtime of [...Physics.Runtimes]) {
                if (rigidBody.updateFromSystem) {
                  continue;
                }

                let instance = rigidBody.getInstance(runtime);
                if (!instance) {
                  continue;
                }

                runtime.updateInstance(rigidBody, tmpProperty, rigidBody[tmpProperty]);
              }

              if (tmpProperty === 'position') {
                rigidBody.mesh.updateFromRigidBody = true;
                rigidBody.mesh.position[property] = rigidBody.position[property];
                rigidBody.mesh.updateFromRigidBody = false;
                return;
              }

              if (tmpProperty === 'quaternion') {
                rigidBody.mesh.updateFromRigidBody = true;
                rigidBody.mesh.quaternion[property] = rigidBody.quaternion[property];
                rigidBody.mesh.updateFromRigidBody = false;
                return;
              }
            }
          }
        }
      }


      // for (let collider of colliders) {
      //
      //   if (!collider.mesh) {
      //     continue;
      //   }
      //
      //   if (collider.mesh.position !== component) {
      //     continue;
      //   }
      //
      //   for (let runtime of [...Physics.Runtimes]) {
      // let instance = rigidBody.getInstance(runtime);
      // if (instance) {
        //     runtime.updateInstance(collider.rigidBody, 'position', component);
      // }
      //   }
      //
      // }

    }

    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_END

  }
  /**
   * ApplyForce()
   * - No comment
   * @param event
   * - No returns
   */
  static ApplyForce(event) {

    //GENERATED_STATIC_APPLY_FORCE_START
    //GENERATED_STATIC_APPLY_FORCE_END

    //CUSTOM_STATIC_APPLY_FORCE_START
    let {object : component, vector} = event;
    for (let runtime of [...Physics.Runtimes]) {
      runtime.applyForce(component, vector);
    }
    //CUSTOM_STATIC_APPLY_FORCE_END

    //GENERATED_STATIC_APPLY_FORCE_AFTER_START
    //GENERATED_STATIC_APPLY_FORCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_APPLY_FORCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_APPLY_FORCE_AFTER_END

  }
  /**
   * ApplyTorque()
   * - No comment
   * @param event
   * - No returns
   */
  static ApplyTorque(event) {

    //GENERATED_STATIC_APPLY_TORQUE_START
    //GENERATED_STATIC_APPLY_TORQUE_END

    //CUSTOM_STATIC_APPLY_TORQUE_START
    let {object : component, vector} = event;
    for (let runtime of [...Physics.Runtimes]) {
      runtime.applyTorque(component, vector);
    }
    //CUSTOM_STATIC_APPLY_TORQUE_END

    //GENERATED_STATIC_APPLY_TORQUE_AFTER_START
    //GENERATED_STATIC_APPLY_TORQUE_AFTER_END

    //CUSTOM_STATIC_GENERATED_APPLY_TORQUE_AFTER_START
    //CUSTOM_STATIC_GENERATED_APPLY_TORQUE_AFTER_END

  }
  /**
   * RenderBefore()
   * - No comment
   * @param event
   * - No returns
   */
  static RenderBefore(event) {

    //GENERATED_STATIC_RENDER_BEFORE_START
    //GENERATED_STATIC_RENDER_BEFORE_END

    //CUSTOM_STATIC_RENDER_BEFORE_START
    let {delta} = event;

    for (let runtime of [...Physics.Runtimes]) {
      for (let world of Physics.Worlds) {

        runtime.step(world, delta/1000);

        for (let child of world.children) {

          if (child instanceof DynamicRigidBody) {

            let rigidBody = child;

            let instance = rigidBody.getInstance(runtime);

            if (!instance) {
              return;
            }

            Physics.UpdateDynamicRigidBody(rigidBody, instance);
          }

          if (child instanceof Collider) {

            let collider = child;

            let instance = collider.getInstance(runtime);

            if (!instance) {
              return;
            }

            Physics.UpdateCollider(collider, instance);
          }
        }
      }
    }
    //CUSTOM_STATIC_RENDER_BEFORE_END

    //GENERATED_STATIC_RENDER_BEFORE_AFTER_START
    //GENERATED_STATIC_RENDER_BEFORE_AFTER_END

    //CUSTOM_STATIC_GENERATED_RENDER_BEFORE_AFTER_START
    //CUSTOM_STATIC_GENERATED_RENDER_BEFORE_AFTER_END

  }
  /**
   * AddSubscriptions()
   * - Adding subscriptions manually when Rapier is ready, because we cannot have a top-level await for loading web
   *   assemblies
   * - No parameters
   * - No returns
   */
  static AddSubscriptions() {

    //GENERATED_STATIC_ADD_SUBSCRIPTIONS_START
    //GENERATED_STATIC_ADD_SUBSCRIPTIONS_END

    //CUSTOM_STATIC_ADD_SUBSCRIPTIONS_START
    /**
     * No comment
     */
    Physics.Subscriptions['INITIALIZE_PHYSICS_COMPONENT'] = Event.On(
      Event.INITIALIZE_PHYSICS_COMPONENT,
      Physics.InitializePhysicsComponent,
      {priority: 60}
    );

    /**
     * No comment
     */
    Physics.Subscriptions['DISPOSE_PHYSICS_COMPONENT'] = Event.On(
      Event.DISPOSE_PHYSICS_COMPONENT,
      Physics.DisposePhysicsComponent,
      {priority: 40}
    );

    /**
     * No comment
     */
    Physics.Subscriptions['BEFORE_ASSIGN_PROPERTY'] = Event.On(
      Event.BEFORE_ASSIGN_PROPERTY,
      Physics.BeforeAssignProperty,
      {priority: 30}
    );

    /**
     * No comment
     */
    Physics.Subscriptions['AFTER_ASSIGN_PROPERTY'] = Event.On(
      Event.AFTER_ASSIGN_PROPERTY,
      Physics.AfterAssignProperty,
      {priority: 30}
    );

    /**
     * No comment
     */
    Physics.Subscriptions['APPLY_FORCE'] = Event.On(
      Event.APPLY_FORCE,
      Physics.ApplyForce,
      {priority: 10}
    );

    /**
     * No comment
     */
    Physics.Subscriptions['APPLY_TORQUE'] = Event.On(
      Event.APPLY_TORQUE,
      Physics.ApplyTorque,
      {priority: 10}
    );

    /**
     * No comment
     */
    Physics.Subscriptions['RENDER_BEFORE'] = Event.On(
      Event.RENDER_BEFORE,
      Physics.RenderBefore,
      {priority: 10}
    );

    Physics.Components = Utils.GetObjectsByConstructor(PhysicsComponent);
    Physics.Worlds = Utils.GetObjectsByConstructor(World);
    Physics.RigidBodies = Utils.GetObjectsByConstructor(RigidBody);

    Physics.Components = Utils.SortByHierarchy(Physics.Components);

    for (let component of Physics.Components) {
      /**
       * Could be that the physics system did not have the opportunity to create physics instances
       */
      for (let runtime of [...Physics.Runtimes]) {
        let instance = component.getInstance(runtime);

        if (instance) {
          continue;
        }

        let valid;

        if (!runtime.validObjects.hasOwnProperty(component.id)) {
          valid = runtime.validate('createInstance', component);
        }

        if (valid) {
          component.setInstance(runtime, runtime.createInstance(component));
          System.CreateRuntimeChildrenInstances(component, runtime, PhysicsComponent);
        }
      }
    }

    Physics.Started = true;
    Physics.IsStarting = false;

    console.log('Started client system Physics');

    Event.Emit(Event.PHYSICS_SYSTEM_STARTED);
    //CUSTOM_STATIC_ADD_SUBSCRIPTIONS_END

    //GENERATED_STATIC_ADD_SUBSCRIPTIONS_AFTER_START
    //GENERATED_STATIC_ADD_SUBSCRIPTIONS_AFTER_END

    //CUSTOM_STATIC_GENERATED_ADD_SUBSCRIPTIONS_AFTER_START
    //CUSTOM_STATIC_GENERATED_ADD_SUBSCRIPTIONS_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * RapierRuntimeReady()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static RapierRuntimeReady(event) {

    //GENERATED_STATIC_RAPIER_RUNTIME_READY_START
    //GENERATED_STATIC_RAPIER_RUNTIME_READY_END

    //CUSTOM_STATIC_RAPIER_RUNTIME_READY_START
    if (!Physics.Started) {
      Physics.AddSubscriptions();
    }
    //CUSTOM_STATIC_RAPIER_RUNTIME_READY_END

    //GENERATED_STATIC_RAPIER_RUNTIME_READY_AFTER_START
    //GENERATED_STATIC_RAPIER_RUNTIME_READY_AFTER_END

    //CUSTOM_STATIC_GENERATED_RAPIER_RUNTIME_READY_AFTER_START
    //CUSTOM_STATIC_GENERATED_RAPIER_RUNTIME_READY_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  /**
   * GetRuntimePhysicsRapier()
   * - Gets the Rapier runtime implementation of R3RuntimePhysics
   * @param {Object} event
   * - No returns
   */
  static GetRuntimePhysicsRapier(event) {

    //GENERATED_STATIC_GET_RUNTIME_PHYSICS_RAPIER_START
    event.results = [Physics.RuntimePhysicsRapier];
    //GENERATED_STATIC_GET_RUNTIME_PHYSICS_RAPIER_END

    //CUSTOM_STATIC_GET_RUNTIME_PHYSICS_RAPIER_START
    //CUSTOM_STATIC_GET_RUNTIME_PHYSICS_RAPIER_END

    //GENERATED_STATIC_GET_RUNTIME_PHYSICS_RAPIER_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_PHYSICS_RAPIER_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_PHYSICS_RAPIER_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_PHYSICS_RAPIER_AFTER_END

  }
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
/**
 * @param Physics.RuntimePhysicsRapier
 * - Runtime implementation of R3RuntimePhysics
 */
Physics.RuntimePhysicsRapier = new RapierRuntime();
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Physics.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Physics.IsStarting = false;

/**
 * @param Physics.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Physics.IsStopping = false;

/**
 * @param Physics.Started
 * - Indicates whether this system is running
 */
Physics.Started = false;

/**
 * @param Physics.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Physics.Subscriptions = {};

/**
 * @param Physics.Runtimes
 * - A set of runtimes which the system manages
 */
Physics.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Physics.Components
 * - No comment
 */
Physics.Components = [];

/**
 * @param Physics.Worlds
 * - No comment
 */
Physics.Worlds = [];

/**
 * @param Physics.RigidBodies
 * - No comment
 */
Physics.RigidBodies = [];
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Physics as default};
