//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import {Pane} from 'tweakpane';
import * as EssentialsPlugin from '@tweakpane/plugin-essentials';
import Utils from '../../Utils.js';
import Event from '../../Event.js';
//CUSTOM_IMPORTS_END

import Gui from '../Gui.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  createGui(object) - Creates a gui runtime instance for this runtime based on the R3 Object 
  deleteGui(object) - Deletes a gui runtime instance for this runtime based on the R3 Object 
  updateGui(object, property, value) - Updates the gui runtime instance of the R3 Object based on the options 
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
  queueUpdate(object, property = null, value) - Queues an update to an object which would cause this GUI to be re-created
  buildContainer(container) - Removes and appends children to container in the order they were created
  handleChange(_obj, _key, _sub = null) - Handles the change for an object
  handleColorChange(_obj, _key) - Handles the color change for an object
  handleObjectChange(_obj, _key, _allObjects) - Handles the object change for an object
  handleEventChange(_obj, _key, _allEvents) - Handles the event change for an object
  handleSelectChange(_obj, _key) - Handles the select change for an object
  handleDispose(_obj, type = 'single') - Triggers a dispose event after a timeout
  handleLoad(_obj) - Triggers a load event after a timeout
  handleSave(_obj) - Triggers a save event after a timeout
  handleRemove(_obj) - Triggers a remove event after a timeout
  handleAddToArray(array, object, property) - Adds an item to an array
  handleRemoveFromArray(array, object, property) - Adds an item to an array
  handleUpload(object, uploadType) - Creates an upload handler for an image or a sound file
  handleAction(object, action) - Creates an upload handler for an image or a sound file
  handleAudio(object, action) - Starts or stops an audio
  addString(folder, object, key) - Adds a string input field
  addBoolean(folder, object, key) - Adds a boolean input field
  addNumber(folder, object, key, min, max, step) - Adds a number field
  addColor(folder, object, key) - Adds a color field
  addVector2(folder, object, key, min, max, step) - Adds a vector2 field
  addVector3(folder, object, key, min, max, step) - Adds a vector3 field
  addVector4(folder, object, key, min, max, step) - Adds a vector4 field
  addSet(folder, object, key) - Adds a set field
  addEvent(folder, object, key) - Adds an event field
  addObject(folder, object, Constructor, key) - Adds an object field
  addSelect(folder, object, options, key) - Adds a select field
  addButtons(folder, object) - Creates the 'buttons' section for objects
  addArray(folder, object, key, Constructor) - Creates an array GUI object
  getOptions(possibleLabels, possibleValues) - Returns an options array from possible labels and values
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createGui(object) - Creates a gui runtime instance for this runtime based on the R3 Object 
  deleteGui(object) - Deletes a gui runtime instance for this runtime based on the R3 Object 
  updateGui(object, property, value) - Updates the gui runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class TweakPane extends Gui {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeGui';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeGuiTweakPane';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'TweakPane';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_GUI;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * queueUpdate()
   * - Queues an update to an object which would cause this GUI to be re-created
   * @param object
   * @param {Object|null} [property=null]
   * @param value
   * - No returns
   */
  queueUpdate(object, property = null, value) {

    //CUSTOM_QUEUE_UPDATE_BEFORE_START
    console.log('queuing update');
    setTimeout(
      () => {
        console.log('update executed');
        if (property) {
          object[property] = value;
          // return;
        }
        // object.setInstance(this, null, this.deleteInstance(object));
        // this.createInstance(object);
      },
      1
    );
    //CUSTOM_QUEUE_UPDATE_BEFORE_END

    //GENERATED_QUEUE_UPDATE_BEFORE_START
    //GENERATED_QUEUE_UPDATE_BEFORE_END

    //CUSTOM_QUEUE_UPDATE_BEFORE_GENERATED_START
    //CUSTOM_QUEUE_UPDATE_BEFORE_GENERATED_END

    //GENERATED_QUEUE_UPDATE_START
    //GENERATED_QUEUE_UPDATE_END

    //CUSTOM_QUEUE_UPDATE_START
    //CUSTOM_QUEUE_UPDATE_END

    //GENERATED_QUEUE_UPDATE_AFTER_START
    //GENERATED_QUEUE_UPDATE_AFTER_END

  }

  /**
   * buildContainer()
   * - Removes and appends children to container in the order they were created
   * @param container
   * - No returns
   */
  buildContainer(container) {

    //CUSTOM_BUILD_CONTAINER_BEFORE_START
    //CUSTOM_BUILD_CONTAINER_BEFORE_END

    //GENERATED_BUILD_CONTAINER_BEFORE_START
    //GENERATED_BUILD_CONTAINER_BEFORE_END

    //CUSTOM_BUILD_CONTAINER_BEFORE_GENERATED_START
    //CUSTOM_BUILD_CONTAINER_BEFORE_GENERATED_END

    //GENERATED_BUILD_CONTAINER_START
    //GENERATED_BUILD_CONTAINER_END

    //CUSTOM_BUILD_CONTAINER_START
    for (let div of this.divs) {
      if (container.contains(div)) {
        container.removeChild(div);
      }
    }

    for (let div of this.divs) {
      container.appendChild(div);
    }
    //CUSTOM_BUILD_CONTAINER_END

    //GENERATED_BUILD_CONTAINER_AFTER_START
    //GENERATED_BUILD_CONTAINER_AFTER_END

  }

  /**
   * handleChange()
   * - Handles the change for an object
   * @param _obj
   * @param _key
   * @param {Object|null} [_sub=null]
   * - No returns
   */
  handleChange(_obj, _key, _sub = null) {

    //CUSTOM_HANDLE_CHANGE_BEFORE_START
    //CUSTOM_HANDLE_CHANGE_BEFORE_END

    //GENERATED_HANDLE_CHANGE_BEFORE_START
    //GENERATED_HANDLE_CHANGE_BEFORE_END

    //CUSTOM_HANDLE_CHANGE_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_CHANGE_BEFORE_GENERATED_END

    //GENERATED_HANDLE_CHANGE_START
    //GENERATED_HANDLE_CHANGE_END

    //CUSTOM_HANDLE_CHANGE_START
    if (_sub === null) {
      return (ev) => {
        let current = _obj[_key];
        let {value} = ev;
        if (Math.abs(current - value) > 0.001) {
          _obj[_key] = ev.value;
        }
      }
    } else {
      return (ev) => {
        let current = _obj[_key][_sub];
        let {value} = ev;
        if (Math.abs(current - value) > 0.0001) {
          _obj[_key][_sub] = ev.value;
        }
      }
    }
    //CUSTOM_HANDLE_CHANGE_END

    //GENERATED_HANDLE_CHANGE_AFTER_START
    //GENERATED_HANDLE_CHANGE_AFTER_END

  }

  /**
   * handleColorChange()
   * - Handles the color change for an object
   * @param _obj
   * @param _key
   * - No returns
   */
  handleColorChange(_obj, _key) {

    //CUSTOM_HANDLE_COLOR_CHANGE_BEFORE_START
    //CUSTOM_HANDLE_COLOR_CHANGE_BEFORE_END

    //GENERATED_HANDLE_COLOR_CHANGE_BEFORE_START
    //GENERATED_HANDLE_COLOR_CHANGE_BEFORE_END

    //CUSTOM_HANDLE_COLOR_CHANGE_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_COLOR_CHANGE_BEFORE_GENERATED_END

    //GENERATED_HANDLE_COLOR_CHANGE_START
    //GENERATED_HANDLE_COLOR_CHANGE_END

    //CUSTOM_HANDLE_COLOR_CHANGE_START
    return (ev) => {
      _obj[_key].r = ev.value.r;
      _obj[_key].g = ev.value.g;
      _obj[_key].b = ev.value.b;
      _obj[_key].a = ev.value.a;
      if (_obj.opacity) {
        _obj.opacity = ev.value.a;
      }
    }
    //CUSTOM_HANDLE_COLOR_CHANGE_END

    //GENERATED_HANDLE_COLOR_CHANGE_AFTER_START
    //GENERATED_HANDLE_COLOR_CHANGE_AFTER_END

  }

  /**
   * handleObjectChange()
   * - Handles the object change for an object
   * @param _obj
   * @param _key
   * @param _allObjects
   * - No returns
   */
  handleObjectChange(_obj, _key, _allObjects) {

    //CUSTOM_HANDLE_OBJECT_CHANGE_BEFORE_START
    //CUSTOM_HANDLE_OBJECT_CHANGE_BEFORE_END

    //GENERATED_HANDLE_OBJECT_CHANGE_BEFORE_START
    //GENERATED_HANDLE_OBJECT_CHANGE_BEFORE_END

    //CUSTOM_HANDLE_OBJECT_CHANGE_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_OBJECT_CHANGE_BEFORE_GENERATED_END

    //GENERATED_HANDLE_OBJECT_CHANGE_START
    //GENERATED_HANDLE_OBJECT_CHANGE_END

    //CUSTOM_HANDLE_OBJECT_CHANGE_START
    return (ev) => {
      let value = ev.value;

      if (value !== null) {
        value = _allObjects.filter(
          (__object) => {
            return (__object.id === ev.value);
          }
        )[0];
      }

      _obj['_cache'].guiTemp[_key] = value;

      // this.queueUpdate(_obj, _key, value);
    }
    //CUSTOM_HANDLE_OBJECT_CHANGE_END

    //GENERATED_HANDLE_OBJECT_CHANGE_AFTER_START
    //GENERATED_HANDLE_OBJECT_CHANGE_AFTER_END

  }

  /**
   * handleEventChange()
   * - Handles the event change for an object
   * @param _obj
   * @param _key
   * @param _allEvents
   * - No returns
   */
  handleEventChange(_obj, _key, _allEvents) {

    //CUSTOM_HANDLE_EVENT_CHANGE_BEFORE_START
    //CUSTOM_HANDLE_EVENT_CHANGE_BEFORE_END

    //GENERATED_HANDLE_EVENT_CHANGE_BEFORE_START
    //GENERATED_HANDLE_EVENT_CHANGE_BEFORE_END

    //CUSTOM_HANDLE_EVENT_CHANGE_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_EVENT_CHANGE_BEFORE_GENERATED_END

    //GENERATED_HANDLE_EVENT_CHANGE_START
    //GENERATED_HANDLE_EVENT_CHANGE_END

    //CUSTOM_HANDLE_EVENT_CHANGE_START
    return (ev) => {
      let value = ev.value;
      this.queueUpdate(_obj, _key, value);
    }
    //CUSTOM_HANDLE_EVENT_CHANGE_END

    //GENERATED_HANDLE_EVENT_CHANGE_AFTER_START
    //GENERATED_HANDLE_EVENT_CHANGE_AFTER_END

  }

  /**
   * handleSelectChange()
   * - Handles the select change for an object
   * @param _obj
   * @param _key
   * - No returns
   */
  handleSelectChange(_obj, _key) {

    //CUSTOM_HANDLE_SELECT_CHANGE_BEFORE_START
    //CUSTOM_HANDLE_SELECT_CHANGE_BEFORE_END

    //GENERATED_HANDLE_SELECT_CHANGE_BEFORE_START
    //GENERATED_HANDLE_SELECT_CHANGE_BEFORE_END

    //CUSTOM_HANDLE_SELECT_CHANGE_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_SELECT_CHANGE_BEFORE_GENERATED_END

    //GENERATED_HANDLE_SELECT_CHANGE_START
    //GENERATED_HANDLE_SELECT_CHANGE_END

    //CUSTOM_HANDLE_SELECT_CHANGE_START
    return (ev) => {
      let value = ev.value;
      this.queueUpdate(_obj, _key, value);
    }
    //CUSTOM_HANDLE_SELECT_CHANGE_END

    //GENERATED_HANDLE_SELECT_CHANGE_AFTER_START
    //GENERATED_HANDLE_SELECT_CHANGE_AFTER_END

  }

  /**
   * handleDispose()
   * - Triggers a dispose event after a timeout
   * @param _obj
   * @param {string} [type='single']
   * - No returns
   */
  handleDispose(_obj, type = 'single') {

    //CUSTOM_HANDLE_DISPOSE_BEFORE_START
    //CUSTOM_HANDLE_DISPOSE_BEFORE_END

    //GENERATED_HANDLE_DISPOSE_BEFORE_START
    //GENERATED_HANDLE_DISPOSE_BEFORE_END

    //CUSTOM_HANDLE_DISPOSE_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_DISPOSE_BEFORE_GENERATED_END

    //GENERATED_HANDLE_DISPOSE_START
    //GENERATED_HANDLE_DISPOSE_END

    //CUSTOM_HANDLE_DISPOSE_START
    return function() {
      setTimeout(
        async () => {
          console.log('dispose executing..');
          _obj.dispose();
        },
        1
      );
    }
    //CUSTOM_HANDLE_DISPOSE_END

    //GENERATED_HANDLE_DISPOSE_AFTER_START
    //GENERATED_HANDLE_DISPOSE_AFTER_END

  }

  /**
   * handleLoad()
   * - Triggers a load event after a timeout
   * @param _obj
   * - No returns
   */
  handleLoad(_obj) {

    //CUSTOM_HANDLE_LOAD_BEFORE_START
    //CUSTOM_HANDLE_LOAD_BEFORE_END

    //GENERATED_HANDLE_LOAD_BEFORE_START
    //GENERATED_HANDLE_LOAD_BEFORE_END

    //CUSTOM_HANDLE_LOAD_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_LOAD_BEFORE_GENERATED_END

    //GENERATED_HANDLE_LOAD_START
    //GENERATED_HANDLE_LOAD_END

    //CUSTOM_HANDLE_LOAD_START
    return function() {
      setTimeout(
        () => {
          console.log('load executing..');
          let {id, type} = _obj;

          _obj.dispose();

          let Constructor = Utils.GetConstructor(type);
          let object = new Constructor({id});

          object.load();
        },
        1
      );
    }
    //CUSTOM_HANDLE_LOAD_END

    //GENERATED_HANDLE_LOAD_AFTER_START
    //GENERATED_HANDLE_LOAD_AFTER_END

  }

  /**
   * handleSave()
   * - Triggers a save event after a timeout
   * @param _obj
   * - No returns
   */
  handleSave(_obj) {

    //CUSTOM_HANDLE_SAVE_BEFORE_START
    //CUSTOM_HANDLE_SAVE_BEFORE_END

    //GENERATED_HANDLE_SAVE_BEFORE_START
    //GENERATED_HANDLE_SAVE_BEFORE_END

    //CUSTOM_HANDLE_SAVE_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_SAVE_BEFORE_GENERATED_END

    //GENERATED_HANDLE_SAVE_START
    //GENERATED_HANDLE_SAVE_END

    //CUSTOM_HANDLE_SAVE_START
    return function() {
      setTimeout(
        () => {
          console.log('save executing..');
          _obj.save();
        },
        1
      );
    }
    //CUSTOM_HANDLE_SAVE_END

    //GENERATED_HANDLE_SAVE_AFTER_START
    //GENERATED_HANDLE_SAVE_AFTER_END

  }

  /**
   * handleRemove()
   * - Triggers a remove event after a timeout
   * @param _obj
   * - No returns
   */
  handleRemove(_obj) {

    //CUSTOM_HANDLE_REMOVE_BEFORE_START
    //CUSTOM_HANDLE_REMOVE_BEFORE_END

    //GENERATED_HANDLE_REMOVE_BEFORE_START
    //GENERATED_HANDLE_REMOVE_BEFORE_END

    //CUSTOM_HANDLE_REMOVE_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_REMOVE_BEFORE_GENERATED_END

    //GENERATED_HANDLE_REMOVE_START
    //GENERATED_HANDLE_REMOVE_END

    //CUSTOM_HANDLE_REMOVE_START
    return function() {
      setTimeout(
        () => {
          console.log('remove executing..');
          _obj.remove().then(
            (result) => {
              _obj.dispose();
            }
          );
        },
        1
      );
    }
    //CUSTOM_HANDLE_REMOVE_END

    //GENERATED_HANDLE_REMOVE_AFTER_START
    //GENERATED_HANDLE_REMOVE_AFTER_END

  }

  /**
   * handleAddToArray()
   * - Adds an item to an array
   * @param array
   * @param object
   * @param property
   * - No returns
   */
  handleAddToArray(array, object, property) {

    //CUSTOM_HANDLE_ADD_TO_ARRAY_BEFORE_START
    //CUSTOM_HANDLE_ADD_TO_ARRAY_BEFORE_END

    //GENERATED_HANDLE_ADD_TO_ARRAY_BEFORE_START
    //GENERATED_HANDLE_ADD_TO_ARRAY_BEFORE_END

    //CUSTOM_HANDLE_ADD_TO_ARRAY_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_ADD_TO_ARRAY_BEFORE_GENERATED_END

    //GENERATED_HANDLE_ADD_TO_ARRAY_START
    //GENERATED_HANDLE_ADD_TO_ARRAY_END

    //CUSTOM_HANDLE_ADD_TO_ARRAY_START
    return (event) => {
      let {index} = event;
      let y = index[1];

      if (typeof y === 'undefined') {
        Utils.Status(-1, `Invalid index for ${object.uniqueName} (${object.name}) property ${property}`);
      }

      console.log(`Adding ${array[y].name} to ${property} of ${object.name}`);

      let original = [...object[property]];

      original.push(array[y]);

      object[property] = [...original];
    }
    //CUSTOM_HANDLE_ADD_TO_ARRAY_END

    //GENERATED_HANDLE_ADD_TO_ARRAY_AFTER_START
    //GENERATED_HANDLE_ADD_TO_ARRAY_AFTER_END

  }

  /**
   * handleRemoveFromArray()
   * - Adds an item to an array
   * @param array
   * @param object
   * @param property
   * - No returns
   */
  handleRemoveFromArray(array, object, property) {

    //CUSTOM_HANDLE_REMOVE_FROM_ARRAY_BEFORE_START
    //CUSTOM_HANDLE_REMOVE_FROM_ARRAY_BEFORE_END

    //GENERATED_HANDLE_REMOVE_FROM_ARRAY_BEFORE_START
    //GENERATED_HANDLE_REMOVE_FROM_ARRAY_BEFORE_END

    //CUSTOM_HANDLE_REMOVE_FROM_ARRAY_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_REMOVE_FROM_ARRAY_BEFORE_GENERATED_END

    //GENERATED_HANDLE_REMOVE_FROM_ARRAY_START
    //GENERATED_HANDLE_REMOVE_FROM_ARRAY_END

    //CUSTOM_HANDLE_REMOVE_FROM_ARRAY_START
    return (event) => {
      let {index} = event;
      let y = index[1];

      if (typeof y === 'undefined') {
        Utils.Status(-1, `Invalid index for ${object.uniqueName} (${object.name}) property ${property}`);
      }

      console.log(`Removing ${array[y].name} from ${property} of ${object.name}`);

      let modified = [...array.slice(0, y), ...array.slice(y + 1)];

      object[property] = [...modified];
    }
    //CUSTOM_HANDLE_REMOVE_FROM_ARRAY_END

    //GENERATED_HANDLE_REMOVE_FROM_ARRAY_AFTER_START
    //GENERATED_HANDLE_REMOVE_FROM_ARRAY_AFTER_END

  }

  /**
   * handleUpload()
   * - Creates an upload handler for an image or a sound file
   * @param object
   * @param uploadType
   * - No returns
   */
  handleUpload(object, uploadType) {

    //CUSTOM_HANDLE_UPLOAD_BEFORE_START
    //CUSTOM_HANDLE_UPLOAD_BEFORE_END

    //GENERATED_HANDLE_UPLOAD_BEFORE_START
    //GENERATED_HANDLE_UPLOAD_BEFORE_END

    //CUSTOM_HANDLE_UPLOAD_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_UPLOAD_BEFORE_GENERATED_END

    //GENERATED_HANDLE_UPLOAD_START
    //GENERATED_HANDLE_UPLOAD_END

    //CUSTOM_HANDLE_UPLOAD_START
    return () => {
      Utils.Upload(object, uploadType);
    }
    //CUSTOM_HANDLE_UPLOAD_END

    //GENERATED_HANDLE_UPLOAD_AFTER_START
    //GENERATED_HANDLE_UPLOAD_AFTER_END

  }

  /**
   * handleAction()
   * - Creates an upload handler for an image or a sound file
   * @param object
   * @param action
   * - No returns
   */
  handleAction(object, action) {

    //CUSTOM_HANDLE_ACTION_BEFORE_START
    //CUSTOM_HANDLE_ACTION_BEFORE_END

    //GENERATED_HANDLE_ACTION_BEFORE_START
    //GENERATED_HANDLE_ACTION_BEFORE_END

    //CUSTOM_HANDLE_ACTION_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_ACTION_BEFORE_GENERATED_END

    //GENERATED_HANDLE_ACTION_START
    //GENERATED_HANDLE_ACTION_END

    //CUSTOM_HANDLE_ACTION_START
    return () => {
      object[action]();
    }
    //CUSTOM_HANDLE_ACTION_END

    //GENERATED_HANDLE_ACTION_AFTER_START
    //GENERATED_HANDLE_ACTION_AFTER_END

  }

  /**
   * handleAudio()
   * - Starts or stops an audio
   * @param object
   * @param action
   * - No returns
   */
  handleAudio(object, action) {

    //CUSTOM_HANDLE_AUDIO_BEFORE_START
    //CUSTOM_HANDLE_AUDIO_BEFORE_END

    //GENERATED_HANDLE_AUDIO_BEFORE_START
    //GENERATED_HANDLE_AUDIO_BEFORE_END

    //CUSTOM_HANDLE_AUDIO_BEFORE_GENERATED_START
    //CUSTOM_HANDLE_AUDIO_BEFORE_GENERATED_END

    //GENERATED_HANDLE_AUDIO_START
    //GENERATED_HANDLE_AUDIO_END

    //CUSTOM_HANDLE_AUDIO_START
    return () => {
      if (action === 'play') {
        object.play();
        return;
      }

      if (action === 'stop') {
        object.stop();
      }

      if (action === 'pause') {
        object.pause();
      }
    };
    //CUSTOM_HANDLE_AUDIO_END

    //GENERATED_HANDLE_AUDIO_AFTER_START
    //GENERATED_HANDLE_AUDIO_AFTER_END

  }

  /**
   * addString()
   * - Adds a string input field
   * @param folder
   * @param object
   * @param key
   * - No returns
   */
  addString(folder, object, key) {

    //CUSTOM_ADD_STRING_BEFORE_START
    //CUSTOM_ADD_STRING_BEFORE_END

    //GENERATED_ADD_STRING_BEFORE_START
    //GENERATED_ADD_STRING_BEFORE_END

    //CUSTOM_ADD_STRING_BEFORE_GENERATED_START
    //CUSTOM_ADD_STRING_BEFORE_GENERATED_END

    //GENERATED_ADD_STRING_START
    //GENERATED_ADD_STRING_END

    //CUSTOM_ADD_STRING_START
    let params = {};

    let value = object[key];

    if (value === null) {
      value = "<unset>";
    }

    object['_cache'].guiTemp[key] = value;

    if (
      key === 'id' ||
      key === 'username'
    ) {
      params.disabled = true;
    }

    folder.addBinding(
      object['_cache'].guiTemp,
      key,
      params
    ).on(
      'change',
      function(_obj, _key) {
        return function (ev) {
          _obj[_key] = ev.value;
        }
      }(object, key)
    );
    //CUSTOM_ADD_STRING_END

    //GENERATED_ADD_STRING_AFTER_START
    //GENERATED_ADD_STRING_AFTER_END

  }

  /**
   * addBoolean()
   * - Adds a boolean input field
   * @param folder
   * @param object
   * @param key
   * - No returns
   */
  addBoolean(folder, object, key) {

    //CUSTOM_ADD_BOOLEAN_BEFORE_START
    //CUSTOM_ADD_BOOLEAN_BEFORE_END

    //GENERATED_ADD_BOOLEAN_BEFORE_START
    //GENERATED_ADD_BOOLEAN_BEFORE_END

    //CUSTOM_ADD_BOOLEAN_BEFORE_GENERATED_START
    //CUSTOM_ADD_BOOLEAN_BEFORE_GENERATED_END

    //GENERATED_ADD_BOOLEAN_START
    //GENERATED_ADD_BOOLEAN_END

    //CUSTOM_ADD_BOOLEAN_START
    object['_cache'].guiTemp[key] = object[key];

    if (object[key] === null) {
      console.warn(`Did not create a boolean component because it is null for ${object.name}[${key}]`);
      return;
    }

    folder.addBinding(
      object['_cache'].guiTemp,
      key
    ).on(
      'change',
      function(_obj, _key) {
        return function (ev) {
          if (_obj[_key] !== ev.value) {
            _obj[_key] = ev.value;
          }
        }
      }(object, key)
    );
    //CUSTOM_ADD_BOOLEAN_END

    //GENERATED_ADD_BOOLEAN_AFTER_START
    //GENERATED_ADD_BOOLEAN_AFTER_END

  }

  /**
   * addNumber()
   * - Adds a number field
   * @param folder
   * @param object
   * @param key
   * @param min
   * @param max
   * @param step
   * - No returns
   */
  addNumber(folder, object, key, min, max, step) {

    //CUSTOM_ADD_NUMBER_BEFORE_START
    //CUSTOM_ADD_NUMBER_BEFORE_END

    //GENERATED_ADD_NUMBER_BEFORE_START
    //GENERATED_ADD_NUMBER_BEFORE_END

    //CUSTOM_ADD_NUMBER_BEFORE_GENERATED_START
    //CUSTOM_ADD_NUMBER_BEFORE_GENERATED_END

    //GENERATED_ADD_NUMBER_START
    //GENERATED_ADD_NUMBER_END

    //CUSTOM_ADD_NUMBER_START
    if (object[key] === null) {
      console.warn(`Did not create a number component because it is null for ${object.name}[${key}]`);
      return;
    }

    object['_cache'].guiTemp[key] = object[key];

    folder.addBinding(
      object['_cache'].guiTemp,
      key,
      {
        min,
        max,
        step
      }
    ).on(
      'change',
      this.handleChange(object, key)
    );
    //CUSTOM_ADD_NUMBER_END

    //GENERATED_ADD_NUMBER_AFTER_START
    //GENERATED_ADD_NUMBER_AFTER_END

  }

  /**
   * addColor()
   * - Adds a color field
   * @param folder
   * @param object
   * @param key
   * - No returns
   */
  addColor(folder, object, key) {

    //CUSTOM_ADD_COLOR_BEFORE_START
    //CUSTOM_ADD_COLOR_BEFORE_END

    //GENERATED_ADD_COLOR_BEFORE_START
    //GENERATED_ADD_COLOR_BEFORE_END

    //CUSTOM_ADD_COLOR_BEFORE_GENERATED_START
    //CUSTOM_ADD_COLOR_BEFORE_GENERATED_END

    //GENERATED_ADD_COLOR_START
    //GENERATED_ADD_COLOR_END

    //CUSTOM_ADD_COLOR_START
    let temp = {};
    let params = {};

    params.view = 'color';

    params.expanded = false;

    if (object[key] === null) {
      console.warn(`Did not create a color component because it is null for ${object.name}[${key}]`);
      return;
    }

    let {r, g, b, a} = object[key];

    if (
      typeof r === 'undefined' ||
      typeof g === 'undefined' ||
      typeof b === 'undefined' ||
      typeof a === 'undefined' ||
      isNaN(r) ||
      isNaN(g) ||
      isNaN(b) ||
      isNaN(a) ||
      r === null ||
      g === null ||
      b === null ||
      a === null
    ) {
      console.error(`Invalid color for ${object.name} : ${key}`);
      return;
    }

    temp = {
      r : object[key].r,
      g : object[key].g,
      b : object[key].b,
      a : object[key].a
    };

    object['_cache'].guiTemp[key] = temp;

    folder.addBinding(
      object['_cache'].guiTemp,
      key,
      params
    ).on(
      'change',
      function(_obj, _key) {
        return (ev) => {
          let {r, g, b, a} = ev.value;

          _obj[_key].r = r;
          _obj[_key].g = g;
          _obj[_key].b = b;
          _obj[_key].a = a;

          if (_obj.opacity) {
            _obj.opacity = a;
          }
        }
      }(object, key)
    );
    //CUSTOM_ADD_COLOR_END

    //GENERATED_ADD_COLOR_AFTER_START
    //GENERATED_ADD_COLOR_AFTER_END

  }

  /**
   * addVector2()
   * - Adds a vector2 field
   * @param folder
   * @param object
   * @param key
   * @param min
   * @param max
   * @param step
   * - No returns
   */
  addVector2(folder, object, key, min, max, step) {

    //CUSTOM_ADD_VECTOR2_BEFORE_START
    //CUSTOM_ADD_VECTOR2_BEFORE_END

    //GENERATED_ADD_VECTOR2_BEFORE_START
    //GENERATED_ADD_VECTOR2_BEFORE_END

    //CUSTOM_ADD_VECTOR2_BEFORE_GENERATED_START
    //CUSTOM_ADD_VECTOR2_BEFORE_GENERATED_END

    //GENERATED_ADD_VECTOR2_START
    //GENERATED_ADD_VECTOR2_END

    //CUSTOM_ADD_VECTOR2_START
    if (!object[key]) {
      if (object['_cache'].guiTemp[key]) {
        delete object['_cache'].guiTemp[key];
      }
      return;
    }

    let temp = {};
    temp.x = object[key].x;
    temp.y = object[key].y;

    object['_cache'].guiTemp[key] = temp;

    folder.addBinding(
      temp, 'x',
      {
        min,
        max,
        step,
        label: `${key}.x`
      }
    ).on(
      'change',
      this.handleChange(object, key, 'x')
    );

    folder.addBinding(
      temp,
      'y',
      {
        min: min,
        max: max,
        step: step,
        label: `${key}.y`
      }
    ).on(
      'change',
      this.handleChange(object, key, 'y')
    );
    //CUSTOM_ADD_VECTOR2_END

    //GENERATED_ADD_VECTOR2_AFTER_START
    //GENERATED_ADD_VECTOR2_AFTER_END

  }

  /**
   * addVector3()
   * - Adds a vector3 field
   * @param folder
   * @param object
   * @param key
   * @param min
   * @param max
   * @param step
   * - No returns
   */
  addVector3(folder, object, key, min, max, step) {

    //CUSTOM_ADD_VECTOR3_BEFORE_START
    //CUSTOM_ADD_VECTOR3_BEFORE_END

    //GENERATED_ADD_VECTOR3_BEFORE_START
    //GENERATED_ADD_VECTOR3_BEFORE_END

    //CUSTOM_ADD_VECTOR3_BEFORE_GENERATED_START
    //CUSTOM_ADD_VECTOR3_BEFORE_GENERATED_END

    //GENERATED_ADD_VECTOR3_START
    //GENERATED_ADD_VECTOR3_END

    //CUSTOM_ADD_VECTOR3_START
    if (!object[key]) {
      if (object['_cache'].guiTemp[key]) {
        delete object['_cache'].guiTemp[key];
      }
      return;
    }

    let temp = {};

    temp.x = object[key].x;
    temp.y = object[key].y;
    temp.z = object[key].z;
    temp.spin = object[key].spin;

    object['_cache'].guiTemp[key] = temp;

    folder.addBlade({
      view: 'separator',
    });

    folder.addBinding(
      temp, 'x',
      {
        min,
        max,
        step,
        label: `${key}.x`
      }
    ).on(
      'change',
      this.handleChange(object, key, 'x')
    );

    folder.addBinding(
      temp,
      'y',
      {
        min,
        max,
        step,
        label: `${key}.y`
      }
    ).on(
      'change',
      this.handleChange(object, key, 'y')
    );

    folder.addBinding(
      temp,
      'z',
      {
        min,
        max,
        step,
        label: `${key}.z`
      }
    ).on(
      'change',
      this.handleChange(object, key, 'z')
    );

    if (key === 'rotation') {
      folder.addBinding(
        temp,
        'spin'
      ).on(
        'change',
        function (_obj, _key) {
          return function (ev) {
            _obj[_key] = ev.value;
          }
        }(object[key], 'spin')
      );
    }

    folder.addBlade({
      view: 'separator',
    });
    //CUSTOM_ADD_VECTOR3_END

    //GENERATED_ADD_VECTOR3_AFTER_START
    //GENERATED_ADD_VECTOR3_AFTER_END

  }

  /**
   * addVector4()
   * - Adds a vector4 field
   * @param folder
   * @param object
   * @param key
   * @param min
   * @param max
   * @param step
   * - No returns
   */
  addVector4(folder, object, key, min, max, step) {

    //CUSTOM_ADD_VECTOR4_BEFORE_START
    //CUSTOM_ADD_VECTOR4_BEFORE_END

    //GENERATED_ADD_VECTOR4_BEFORE_START
    //GENERATED_ADD_VECTOR4_BEFORE_END

    //CUSTOM_ADD_VECTOR4_BEFORE_GENERATED_START
    //CUSTOM_ADD_VECTOR4_BEFORE_GENERATED_END

    //GENERATED_ADD_VECTOR4_START
    //GENERATED_ADD_VECTOR4_END

    //CUSTOM_ADD_VECTOR4_START
    if (!object[key]) {
      if (object['_cache'].guiTemp[key]) {
        delete object['_cache'].guiTemp[key];
      }
      return;
    }

    let temp = {};

    temp.x = object[key].x;
    temp.y = object[key].y;
    temp.z = object[key].z;
    temp.w = object[key].w;

    object['_cache'].guiTemp[key] = temp;

    folder.addBinding(
      temp, 'x',
      {
        min,
        max,
        step,
        label: `${key}.x`
      }
    ).on(
      'change',
      this.handleChange(object, key, 'x')
    );

    folder.addBinding(
      temp,
      'y',
      {
        min,
        max,
        step,
        label: `${key}.y`
      }
    ).on(
      'change',
      this.handleChange(object, key, 'y')
    );

    folder.addBinding(
      temp,
      'z',
      {
        min,
        max,
        step,
        label: `${key}.z`
      }
    ).on(
      'change',
      this.handleChange(object, key, 'z')
    );

    folder.addBinding(
      temp,
      'w',
      {
        min,
        max,
        step,
        label: `${key}.w`
      }
    ).on(
      'change',
      this.handleChange(object, key, 'w')
    );
    //CUSTOM_ADD_VECTOR4_END

    //GENERATED_ADD_VECTOR4_AFTER_START
    //GENERATED_ADD_VECTOR4_AFTER_END

  }

  /**
   * addSet()
   * - Adds a set field
   * @param folder
   * @param object
   * @param key
   * - No returns
   */
  addSet(folder, object, key) {

    //CUSTOM_ADD_SET_BEFORE_START
    //CUSTOM_ADD_SET_BEFORE_END

    //GENERATED_ADD_SET_BEFORE_START
    //GENERATED_ADD_SET_BEFORE_END

    //CUSTOM_ADD_SET_BEFORE_GENERATED_START
    //CUSTOM_ADD_SET_BEFORE_GENERATED_END

    //GENERATED_ADD_SET_START
    //GENERATED_ADD_SET_END

    //CUSTOM_ADD_SET_START
    let options = [
      {
        text : `${key} ...`,
        value : `null`
      }
    ]

    if (object[key] && object[key].size) {
      options = [...object[key]].map(
        (item) => {
          return {
            text : item.name,
            value : item.id
          }
        }
      );
    }

    let value = `null`;

    if (object[key] && object[key].size) {
      value = [...object[key]][0].id;
    }

    folder.addBlade(
      {
        view : 'list',
        label : key,
        options,
        value
      }
    );
    //CUSTOM_ADD_SET_END

    //GENERATED_ADD_SET_AFTER_START
    //GENERATED_ADD_SET_AFTER_END

  }

  /**
   * addEvent()
   * - Adds an event field
   * @param folder
   * @param object
   * @param key
   * - No returns
   */
  addEvent(folder, object, key) {

    //CUSTOM_ADD_EVENT_BEFORE_START
    //CUSTOM_ADD_EVENT_BEFORE_END

    //GENERATED_ADD_EVENT_BEFORE_START
    //GENERATED_ADD_EVENT_BEFORE_END

    //CUSTOM_ADD_EVENT_BEFORE_GENERATED_START
    //CUSTOM_ADD_EVENT_BEFORE_GENERATED_END

    //GENERATED_ADD_EVENT_START
    //GENERATED_ADD_EVENT_END

    //CUSTOM_ADD_EVENT_START
    let payload = {};
    let events;

    Event.Emit(
      Event.GET_ALL_EVENTS,
      payload
    );

    if (payload.results.length) {
      events = [...payload.results];
    } else {
      throw new Error(`Could not retrieve all events`);
    }

    let initial = {
      text : `null...`,
      value : null
    };

    let options = events.map(
      (ev) => {
        return {
          text: Event.GetEventName(ev),
          value: ev
        }
      }
    );

    options.unshift(initial);

    let value = null;

    if (object[key]) {
      value = object[key];
    }

    object['_cache'].guiTemp[key] = folder.addBlade(
      {
        view : 'list',
        label : key,
        options,
        value
      }
    ).on(
      'change',
      this.handleEventChange(object, key, events)
    );
    //CUSTOM_ADD_EVENT_END

    //GENERATED_ADD_EVENT_AFTER_START
    //GENERATED_ADD_EVENT_AFTER_END

  }

  /**
   * addObject()
   * - Adds an object field
   * @param folder
   * @param object
   * @param Constructor
   * @param key
   * - No returns
   */
  addObject(folder, object, Constructor, key) {

    //CUSTOM_ADD_OBJECT_BEFORE_START
    //CUSTOM_ADD_OBJECT_BEFORE_END

    //GENERATED_ADD_OBJECT_BEFORE_START
    //GENERATED_ADD_OBJECT_BEFORE_END

    //CUSTOM_ADD_OBJECT_BEFORE_GENERATED_START
    //CUSTOM_ADD_OBJECT_BEFORE_GENERATED_END

    //GENERATED_ADD_OBJECT_START
    //GENERATED_ADD_OBJECT_END

    //CUSTOM_ADD_OBJECT_START
    let options = [];

    let allObjects = Utils.GetObjectsByConstructor(Constructor);

    if (allObjects.length) {
      options = allObjects.filter(
        (item) => {
          return (item.id !== object.id);
        }
      ).reduce(
        (result, item) => {
          let {id, name} = item;

          if (!name) {
            name = '<unknown>';
          }

          if (!id) {
            console.error(`Unknown id during gui construction`);
            return result;
          }

          result.push({
            text: name,
            value: id
          });

          return result;
        },
        []
      );
    }

    let initial = {
      text : `null...`,
      value : null
    };

    options.unshift(initial);

    let value = null;

    if (object[key]) {
      value = object[key].id;
    }

    object['_cache'].guiTemp[key] = folder.addBlade(
      {
        view : 'list',
        label : key,
        options,
        value
      }
    ).on(
      'change',
      function(_obj, _key, _allObjects) {
        return (ev) => {

          let value = ev.value;

          if (value !== null) {
            value = _allObjects.filter(
              (__object) => {
                return (__object.id === ev.value);
              }
            )[0];
          }

          setTimeout(
            () => {
              _obj[_key] = value;
            },
            1
          );

        }
      }(object, key, allObjects)
    );

    // console.log(blade);
    //CUSTOM_ADD_OBJECT_END

    //GENERATED_ADD_OBJECT_AFTER_START
    //GENERATED_ADD_OBJECT_AFTER_END

  }

  /**
   * addSelect()
   * - Adds a select field
   * @param folder
   * @param object
   * @param options
   * @param key
   * - No returns
   */
  addSelect(folder, object, options, key) {

    //CUSTOM_ADD_SELECT_BEFORE_START
    //CUSTOM_ADD_SELECT_BEFORE_END

    //GENERATED_ADD_SELECT_BEFORE_START
    //GENERATED_ADD_SELECT_BEFORE_END

    //CUSTOM_ADD_SELECT_BEFORE_GENERATED_START
    //CUSTOM_ADD_SELECT_BEFORE_GENERATED_END

    //GENERATED_ADD_SELECT_START
    //GENERATED_ADD_SELECT_END

    //CUSTOM_ADD_SELECT_START
    let value = object[key];

    /**
     * @param folder.addBlade function
     */
    object['_cache'].guiTemp[key] = folder.addBlade(
      {
        view : 'list',
        label : key,
        options,
        value
      }
    ).on(
      'change',
      this.handleSelectChange(object, key)
    );
    //CUSTOM_ADD_SELECT_END

    //GENERATED_ADD_SELECT_AFTER_START
    //GENERATED_ADD_SELECT_AFTER_END

  }

  /**
   * addButtons()
   * - Creates the 'buttons' section for objects
   * @param folder
   * @param object
   * - No returns
   */
  addButtons(folder, object) {

    //CUSTOM_ADD_BUTTONS_BEFORE_START
    //CUSTOM_ADD_BUTTONS_BEFORE_END

    //GENERATED_ADD_BUTTONS_BEFORE_START
    //GENERATED_ADD_BUTTONS_BEFORE_END

    //CUSTOM_ADD_BUTTONS_BEFORE_GENERATED_START
    //CUSTOM_ADD_BUTTONS_BEFORE_GENERATED_END

    //GENERATED_ADD_BUTTONS_START
    //GENERATED_ADD_BUTTONS_END

    //CUSTOM_ADD_BUTTONS_START
    let btn;

    if (
      !object.type.match('R3EventObjUser') ||
      !object.type.match('R3EventObjGroup')
    ) {
      btn = folder.addButton({
        title: 'Dispose',
        label: 'dispose',   // optional
      });
      btn.on('click', this.handleDispose(object));
    }

    if (
      object.type.match('R3EventObjComponent') ||
      object.type.match('R3EventObjEntity') ||
      object.type.match('R3EventObjProject')
    ) {
      btn = folder.addButton({
        title: 'Load',
        label: 'load',
      });
      btn.on('click', this.handleLoad(object));

      btn = folder.addButton({
        title: 'Save',
        label: 'save',
      });
      btn.on('click', this.handleSave(object));

      btn = folder.addButton({
        title: 'Remove',
        label: 'remove',
      });
      btn.on('click', this.handleRemove(object));
    }

    if (object.type.match('R3EventObjComponentGraphicsImage')) {
      folder.addButton({
        title: 'Upload file'
      }).on('click', this.handleUpload(object, 'image'))
    }

    if (object.type.match('R3EventObjComponentAudio')) {

      folder.addButton({
        title: 'Play'
      }).on('click', this.handleAudio(object, 'play'))

      folder.addButton({
        title: 'Pause'
      }).on('click', this.handleAudio(object, 'pause'))

      folder.addButton({
        title: 'Stop'
      }).on('click', this.handleAudio(object, 'stop'))

      folder.addButton({
        title: 'Upload file'
      }).on('click', this.handleUpload(object, 'audio'))
    }

    if (object.type.match('R3EventObjComponentPhysicsRigidBody')) {

      folder.addButton({
        title: 'Apply Force'
      }).on('click', this.handleAction(object, 'applyForce'));

      folder.addButton({
        title: 'Apply Torque'
      }).on('click', this.handleAction(object, 'applyTorque'));

    }
    //CUSTOM_ADD_BUTTONS_END

    //GENERATED_ADD_BUTTONS_AFTER_START
    //GENERATED_ADD_BUTTONS_AFTER_END

  }

  /**
   * addArray()
   * - Creates an array GUI object
   * @param folder
   * @param object
   * @param key
   * @param Constructor
   * - No returns
   */
  addArray(folder, object, key, Constructor) {

    //CUSTOM_ADD_ARRAY_BEFORE_START
    //CUSTOM_ADD_ARRAY_BEFORE_END

    //GENERATED_ADD_ARRAY_BEFORE_START
    //GENERATED_ADD_ARRAY_BEFORE_END

    //CUSTOM_ADD_ARRAY_BEFORE_GENERATED_START
    //CUSTOM_ADD_ARRAY_BEFORE_GENERATED_END

    //GENERATED_ADD_ARRAY_START
    //GENERATED_ADD_ARRAY_END

    //CUSTOM_ADD_ARRAY_START
    let allObjects = Utils.GetObjectsByConstructor(Constructor);

    let guiObject = {};

    guiObject.separator1 = folder.addBlade({
      view: 'separator',
    });

    if (allObjects.length) {
      let titles = allObjects.map(
        (item) => {
          return [`Add ${key} - ${item.name}`];
        }
      )

      guiObject.addGrid = folder.addBlade({
        view: 'buttongrid',
        size: [1, allObjects.length],
        cells: (x, y) => (
          {
            title: titles[y][x]
          }
        ),
        label: `${key} (all)`,
      }).on(
        'click',
        this.handleAddToArray(allObjects, object, key)
      );
    }

    guiObject.separator2 = folder.addBlade({
      view: 'separator',
    });

    let array = object[key];

    if (!array || !array.length) {
      object['_cache'].guiTemp[key] = guiObject;
      return;
    }

    let titles = array.map(
      (item, index) => {
        return [`Remove ${key}[${index}] - ${item.name}`];
      }
    )

    guiObject.removeGrid = folder.addBlade({
      view: 'buttongrid',
      size: [1, array.length],
      cells: (x, y) => (
        {
          title: titles[y][x]
        }
      ),
      label: `${key} (current)`,
    }).on(
      'click',
      this.handleRemoveFromArray(array, object, key)
    );

    guiObject.separator3 = folder.addBlade({
      view: 'separator',
    });

    object['_cache'].guiTemp[key] = guiObject;
    //CUSTOM_ADD_ARRAY_END

    //GENERATED_ADD_ARRAY_AFTER_START
    //GENERATED_ADD_ARRAY_AFTER_END

  }

  /**
   * getOptions()
   * - Returns an options array from possible labels and values
   * @param possibleLabels
   * @param possibleValues
   * - No returns
   */
  getOptions(possibleLabels, possibleValues) {

    //CUSTOM_GET_OPTIONS_BEFORE_START
    //CUSTOM_GET_OPTIONS_BEFORE_END

    //GENERATED_GET_OPTIONS_BEFORE_START
    //GENERATED_GET_OPTIONS_BEFORE_END

    //CUSTOM_GET_OPTIONS_BEFORE_GENERATED_START
    //CUSTOM_GET_OPTIONS_BEFORE_GENERATED_END

    //GENERATED_GET_OPTIONS_START
    //GENERATED_GET_OPTIONS_END

    //CUSTOM_GET_OPTIONS_START
    let options;

    if (possibleValues) {

      if (possibleLabels) {

        if (possibleLabels.length !== possibleValues.length) {
          throw new Error('label / value mismatch');
        }

        options = possibleValues.map(
          (value, index) => {
            return {
              text : possibleLabels[index],
              value
            }
          }
        );
      } else {
        options = possibleValues.map(
          (value) => {
            return {
              text : value,
              value
            }
          }
        )
      }
    }

    return options;
    //CUSTOM_GET_OPTIONS_END

    //GENERATED_GET_OPTIONS_AFTER_START
    //GENERATED_GET_OPTIONS_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createGui()
   * - Creates a gui runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  createGui(object) {

    //CUSTOM_CREATE_GUI_BEFORE_START
    //CUSTOM_CREATE_GUI_BEFORE_END

    //GENERATED_CREATE_GUI_BEFORE_START
    //GENERATED_CREATE_GUI_BEFORE_END

    //CUSTOM_CREATE_GUI_BEFORE_GENERATED_START
    //CUSTOM_CREATE_GUI_BEFORE_GENERATED_END

    //GENERATED_CREATE_GUI_START
    //GENERATED_CREATE_GUI_END

    //CUSTOM_CREATE_GUI_START
    let {id, name, uniqueName} = object;

    let title = `${uniqueName} : ${name}`;

    /**
     * Find the parent
     * @type {HTMLElement}
     */
    let parent = document.getElementById(this.containerId);

    if (!parent) {
      console.warn(`The parent gui container went away`);
      return;
    }

    /**
     * Create the new pane instance
     * @type {HTMLDivElement}
     */
    let container = document.createElement('div');
    container.setAttribute('id', id);

    let containerName = title;

    if (object.type.match('R3EventObjGroup')) {
      containerName = `___${containerName}`;
    }

    if (object.type.match('R3EventObjUser')) {
      containerName = `__${containerName}`;
    }

    if (object.type.match('R3EventObjProject')) {
      containerName = `_${containerName}`;
    }

    container.setAttribute('name', containerName);

    let index = 0;
    const existingDivs = parent.querySelectorAll(`#${this.containerId} > div`);
    for (let i = 0; i < existingDivs.length; i++) {
      const existingName = existingDivs[i].getAttribute('name');
      if (containerName.localeCompare(existingName) >= 0) {
        index = i + 1;
      } else {
        break;
      }
    }

    if (index < existingDivs.length) {
      parent.insertBefore(container, existingDivs[index]);
    } else {
      parent.appendChild(container);
    }

    let instance = new Pane({
      container
    });

    instance.registerPlugin(EssentialsPlugin);

    let folder = instance.addFolder(
      {
        title,
        expanded: false
      }
    );

    if (typeof object['_cache'] === 'undefined') {
      object['_cache'] = {};
    }

    if (typeof object['_cache'].guiTemp === 'undefined') {
      object['_cache'].guiTemp = {folder}
    }

    for (let [property, reference] of Object.entries(object.references)) {

      let {type, Constructor, possibleValues, possibleLabels, min, max, step, minLength, maxLength, excluded, isEvent} = reference;

      if (excluded) {
        continue;
      }

      let isArray = false;
      let isSelect = false;

      if (possibleValues) {
        isSelect = true;
      }

      if (type instanceof Array) {
        isArray = true;
        type = type[0];
        Constructor = Constructor[0];
      }

      let options = this.getOptions(possibleLabels, possibleValues);

      switch (type) {
        case 'String' :
          if (isSelect) {
            this.addSelect(folder, object, options, property);
          } else {
            this.addString(folder, object, property);
          }
          break;
        case 'Boolean' :
          this.addBoolean(folder, object, property);
          break;
        case 'Number' :
          if (isEvent) {
            this.addEvent(folder, object, property);
          } else {
            if (isSelect) {
              this.addSelect(folder, object, options, property);
            } else {
              this.addNumber(folder, object, property, min, max, step);
            }
          }
          break;
        case 'R3EventObjComponentMathsColor' :
          this.addColor(folder, object, property);
          break;
        case 'R3EventObjComponentMathsVector2' :
          this.addVector2(folder, object, property, min, max, step);
          break;
        case 'R3EventObjComponentMathsVector3' :
          this.addVector3(folder, object, property, min, max, step);
          break;
        case 'R3EventObjComponentMathsVector4' :
        case 'R3EventObjComponentMathsQuaternion' :
          this.addVector4(folder, object, property, min, max, step);
          break;
        default :
          if (isArray) {
            this.addArray(folder, object, property, Constructor);
          } else {
            if (type !== 'Buffer') {
              this.addObject(folder, object, Constructor, property);
            }
          }
      }
    }

    // let graphicsInstance =

    this.addButtons(folder, object);

    return instance;
    //CUSTOM_CREATE_GUI_END

    //GENERATED_CREATE_GUI_AFTER_START
    //GENERATED_CREATE_GUI_AFTER_END

  }

  /**
   * deleteGui()
   * - Deletes a gui runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  deleteGui(object) {

    //CUSTOM_DELETE_GUI_BEFORE_START
    //CUSTOM_DELETE_GUI_BEFORE_END

    //GENERATED_DELETE_GUI_BEFORE_START
    //GENERATED_DELETE_GUI_BEFORE_END

    //CUSTOM_DELETE_GUI_BEFORE_GENERATED_START
    //CUSTOM_DELETE_GUI_BEFORE_GENERATED_END

    //GENERATED_DELETE_GUI_START
    //GENERATED_DELETE_GUI_END

    //CUSTOM_DELETE_GUI_START
    let instance = object.getInstance(this);
    if (instance) {
      instance.dispose();
    }

    delete object['_cache'].guiTemp;

    let {id} = object;
    let container = document.getElementById(id);
    if (container) {
      container.parentElement.removeChild(container);
    }
    return null;
    //CUSTOM_DELETE_GUI_END

    //GENERATED_DELETE_GUI_AFTER_START
    //GENERATED_DELETE_GUI_AFTER_END

  }

  /**
   * updateGui()
   * - Updates the gui runtime instance of the R3 Object based on the options
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateGui(object, property, value) {

    //CUSTOM_UPDATE_GUI_BEFORE_START
    //CUSTOM_UPDATE_GUI_BEFORE_END

    //GENERATED_UPDATE_GUI_BEFORE_START
    //GENERATED_UPDATE_GUI_BEFORE_END

    //CUSTOM_UPDATE_GUI_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_GUI_BEFORE_GENERATED_END

    //GENERATED_UPDATE_GUI_START
    //GENERATED_UPDATE_GUI_END

    //CUSTOM_UPDATE_GUI_START
    let reference = object.references[property];

    let {type, Constructor, isEvent, possibleLabels, possibleValues} = reference;

    let isArray = false;
    let isSelect = false;

    if (possibleValues) {
      isSelect = true;
    }

    let options = this.getOptions(possibleLabels, possibleValues);

    if (type instanceof Array) {
      type = type[0];
      Constructor = Constructor[0];
      isArray = true;
    }

    if (property === 'name') {
      let {uniqueName, name} = object;
      object['_cache'].guiTemp.folder.title = `${uniqueName} : ${name}`;
    }

    let instance = object.getInstance(this);

    if (
      type === 'Boolean'
    ) {
      object['_cache'].guiTemp[property] = object[property];
      instance.refresh();
      return;
    }

    if (
      type === 'R3EventObjComponentMathsVector4' ||
      type === 'R3EventObjComponentMathsQuaternion'
    ) {

      let {x, y, z, w} = object[property];

      if (
        isNaN(x) ||
        isNaN(y) ||
        isNaN(z) ||
        isNaN(w)
      ) {
        return;
      }

      object['_cache'].guiTemp[property].x = x;
      object['_cache'].guiTemp[property].y = y;
      object['_cache'].guiTemp[property].z = z;
      object['_cache'].guiTemp[property].w = w;
      instance.refresh();
      return;
    }

    if (type === 'R3EventObjComponentMathsVector3') {

      let {x, y, z} = object[property];

      if (
        isNaN(x) ||
        isNaN(y) ||
        isNaN(z)
      ) {
        return;
      }

      object['_cache'].guiTemp[property].x = x;
      object['_cache'].guiTemp[property].y = y;
      object['_cache'].guiTemp[property].z = z;
      instance.refresh();
      return;
    }

    if (type === 'R3EventObjComponentMathsVector2') {
      let {x, y} = object[property];

      if (
        isNaN(x) ||
        isNaN(y)
      ) {
        return;
      }

      object['_cache'].guiTemp[property].x = x;
      object['_cache'].guiTemp[property].y = y;
      instance.refresh();
      return;
    }

    if (
      type === 'String'
    ) {
      object['_cache'].guiTemp[property] = object[property];
      instance.refresh();
      return;
    }

    if (
      type === 'R3EventObjComponentMathsColor'
    ) {
      let {r, g, b} = object[property];

      if (
        isNaN(r) ||
        isNaN(g) ||
        isNaN(b)
      ) {
        return;
      }

      object['_cache'].guiTemp[property].r = r;
      object['_cache'].guiTemp[property].g = g;
      object['_cache'].guiTemp[property].b = b;
      // instance.refresh();
      return;
    }


    if (
      type.match('R3EventObj') && !isArray
    ) {
      let blade = object['_cache'].guiTemp[property];
      let folder = object['_cache'].guiTemp.folder;
      blade.dispose();
      this.addObject(folder, object, Constructor, property);
      return;
    }

    if (
      type === 'Number'
    ) {

      if (isNaN(object[property])) {
        /**
         * the value is NaN
         */
        return;
      }

      if (isSelect) {
        let blade = object['_cache'].guiTemp[property];
        let folder = object['_cache'].guiTemp.folder;
        if (blade) {
          blade.dispose();
          this.addSelect(folder, object, options, property);
        }
        return;
      }

      if (isEvent) {
        let blade = object['_cache'].guiTemp[property];
        let folder = object['_cache'].guiTemp.folder;
        blade.dispose();
        this.addEvent(folder, object, property);
        return;
      }

      object['_cache'].guiTemp[property] = object[property];
      instance.refresh();
      return;
    }

    if (isArray) {

      if (type.match('R3EventObjComponentMaths')) {
        return;
      }

      let folder = object['_cache'].guiTemp.folder;
      let guiObject = object['_cache'].guiTemp[property];

      if (!guiObject) {
        console.warn(`No gui object for performing update : ${object.name}.${property}`);
      }

      let {addGrid, removeGrid, separator1, separator2, separator3} = guiObject;

      if (separator1) {
        separator1.dispose();
      }

      if (addGrid) {
        addGrid.dispose();
      }

      if (removeGrid) {
        removeGrid.dispose();
      }

      if (separator2) {
        separator2.dispose();
      }

      if (separator3) {
        separator3.dispose();
      }

      this.addArray(folder, object, property, Constructor);
      return;
    }

    console.warn(`TODO : Not yet tested or implemented in Tweakpane.js: ${type}`);
    //CUSTOM_UPDATE_GUI_END

    //GENERATED_UPDATE_GUI_AFTER_START
    //GENERATED_UPDATE_GUI_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

TweakPane.Type = 'R3RuntimeGuiTweakPane';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {TweakPane as default};
