//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Runtime from '../Runtime.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  async invite(object, user, group) - Invites a user via API URL to join a group
  async register(object) - Registers in the object (typically a User)
  async login(object) - Logs in the object (typically a User) with a username and password
  async get(object) - Makes a request to the server to get the information about the user using the locally stored token. If the token is no longer valid, we will receive an error status code from the server.
  async logout(object) - Logs out the object (typically a User)
  async passwordReset(object) - Sets the new password for a user
  async changeRole(object, role) - Changes a user account to Free, Standard or Business
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  async changeRole(object, role) - Changes a user account to Free, Standard or Business 
  async get(object) - Makes a request to the server to get the information about the user using the locally stored token. If the token is no longer valid, we will receive an error status code from the server. 
  async invite(object, user, group) - Invites a user via API URL to join a group 
  async login(object) - Logs in the object (typically a User) with a username and password 
  async logout(object) - Logs out the object (typically a User) 
  async passwordReset(object) - Sets the new password for a user 
  async register(object) - Registers in the object (typically a User) 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Authentication extends Runtime {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeAuthentication';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeAuthentication';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'AuthenticationRuntime';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_AUTHENTICATION;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * changeRole()
   * - Changes a user account to Free, Standard or Business
   * @param object
   * @param role
   * - No returns
   */
  async changeRole(object, role) {

    //CUSTOM_CHANGE_ROLE_BEFORE_START
    //CUSTOM_CHANGE_ROLE_BEFORE_END

    //GENERATED_CHANGE_ROLE_BEFORE_START
    //GENERATED_CHANGE_ROLE_BEFORE_END

    //CUSTOM_CHANGE_ROLE_BEFORE_GENERATED_START
    //CUSTOM_CHANGE_ROLE_BEFORE_GENERATED_END

    //GENERATED_CHANGE_ROLE_START
    //GENERATED_CHANGE_ROLE_END

    //CUSTOM_CHANGE_ROLE_START
    //CUSTOM_CHANGE_ROLE_END

    //GENERATED_CHANGE_ROLE_AFTER_START
    //GENERATED_CHANGE_ROLE_AFTER_END

  }

  /**
   * get()
   * - Makes a request to the server to get the information about the user using the locally stored token. If the token
   *   is no longer valid, we will receive an error status code from the server.
   * @param object
   * - No returns
   */
  async get(object) {

    //CUSTOM_GET_BEFORE_START
    //CUSTOM_GET_BEFORE_END

    //GENERATED_GET_BEFORE_START
    //GENERATED_GET_BEFORE_END

    //CUSTOM_GET_BEFORE_GENERATED_START
    //CUSTOM_GET_BEFORE_GENERATED_END

    //GENERATED_GET_START
    //GENERATED_GET_END

    //CUSTOM_GET_START
    //CUSTOM_GET_END

    //GENERATED_GET_AFTER_START
    //GENERATED_GET_AFTER_END

  }

  /**
   * invite()
   * - Invites a user via API URL to join a group
   * @param object
   * @param user
   * @param group
   * - No returns
   */
  async invite(object, user, group) {

    //CUSTOM_INVITE_BEFORE_START
    //CUSTOM_INVITE_BEFORE_END

    //GENERATED_INVITE_BEFORE_START
    //GENERATED_INVITE_BEFORE_END

    //CUSTOM_INVITE_BEFORE_GENERATED_START
    //CUSTOM_INVITE_BEFORE_GENERATED_END

    //GENERATED_INVITE_START
    //GENERATED_INVITE_END

    //CUSTOM_INVITE_START
    //CUSTOM_INVITE_END

    //GENERATED_INVITE_AFTER_START
    //GENERATED_INVITE_AFTER_END

  }

  /**
   * login()
   * - Logs in the object (typically a User) with a username and password
   * @param object
   * - No returns
   */
  async login(object) {

    //CUSTOM_LOGIN_BEFORE_START
    //CUSTOM_LOGIN_BEFORE_END

    //GENERATED_LOGIN_BEFORE_START
    //GENERATED_LOGIN_BEFORE_END

    //CUSTOM_LOGIN_BEFORE_GENERATED_START
    //CUSTOM_LOGIN_BEFORE_GENERATED_END

    //GENERATED_LOGIN_START
    //GENERATED_LOGIN_END

    //CUSTOM_LOGIN_START
    //CUSTOM_LOGIN_END

    //GENERATED_LOGIN_AFTER_START
    //GENERATED_LOGIN_AFTER_END

  }

  /**
   * logout()
   * - Logs out the object (typically a User)
   * @param object
   * - No returns
   */
  async logout(object) {

    //CUSTOM_LOGOUT_BEFORE_START
    //CUSTOM_LOGOUT_BEFORE_END

    //GENERATED_LOGOUT_BEFORE_START
    //GENERATED_LOGOUT_BEFORE_END

    //CUSTOM_LOGOUT_BEFORE_GENERATED_START
    //CUSTOM_LOGOUT_BEFORE_GENERATED_END

    //GENERATED_LOGOUT_START
    //GENERATED_LOGOUT_END

    //CUSTOM_LOGOUT_START
    //CUSTOM_LOGOUT_END

    //GENERATED_LOGOUT_AFTER_START
    //GENERATED_LOGOUT_AFTER_END

  }

  /**
   * passwordReset()
   * - Sets the new password for a user
   * @param object
   * - No returns
   */
  async passwordReset(object) {

    //CUSTOM_PASSWORD_RESET_BEFORE_START
    //CUSTOM_PASSWORD_RESET_BEFORE_END

    //GENERATED_PASSWORD_RESET_BEFORE_START
    //GENERATED_PASSWORD_RESET_BEFORE_END

    //CUSTOM_PASSWORD_RESET_BEFORE_GENERATED_START
    //CUSTOM_PASSWORD_RESET_BEFORE_GENERATED_END

    //GENERATED_PASSWORD_RESET_START
    //GENERATED_PASSWORD_RESET_END

    //CUSTOM_PASSWORD_RESET_START
    //CUSTOM_PASSWORD_RESET_END

    //GENERATED_PASSWORD_RESET_AFTER_START
    //GENERATED_PASSWORD_RESET_AFTER_END

  }

  /**
   * register()
   * - Registers in the object (typically a User)
   * @param object
   * - No returns
   */
  async register(object) {

    //CUSTOM_REGISTER_BEFORE_START
    //CUSTOM_REGISTER_BEFORE_END

    //GENERATED_REGISTER_BEFORE_START
    //GENERATED_REGISTER_BEFORE_END

    //CUSTOM_REGISTER_BEFORE_GENERATED_START
    //CUSTOM_REGISTER_BEFORE_GENERATED_END

    //GENERATED_REGISTER_START
    //GENERATED_REGISTER_END

    //CUSTOM_REGISTER_START
    //CUSTOM_REGISTER_END

    //GENERATED_REGISTER_AFTER_START
    //GENERATED_REGISTER_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Authentication.Type = 'R3RuntimeAuthentication';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Authentication as default};
