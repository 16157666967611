//GENERATED_IMPORTS_START
import {default as InputComponent} from '../event/obj/component/Input.js';
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START
import {default as ThreeRuntime} from '../runtime/input/Three.js';
import {default as CustomRuntime} from '../runtime/input/Custom.js';
//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
import Utils from '../Utils.js';
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START
  RuntimeInputThree = new ThreeRuntime() - Runtime implementation of R3RuntimeInput
  RuntimeInputCustom = new CustomRuntime() - Runtime implementation of R3RuntimeInput
 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.BEFORE_ASSIGN_PROPERTY(70)
  Event.AFTER_ASSIGN_PROPERTY(50)
  Event.INITIALIZE_INPUT_COMPONENT(90)
  Event.DISPOSE_INPUT_COMPONENT(10)
  Event.UPDATE_CONTROL(10)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START

 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  Event.GET_RUNTIME_INPUT_THREE - Gets the Three runtime implementation of R3RuntimeInput
  Event.GET_RUNTIME_INPUT_CUSTOM - Gets the Custom runtime implementation of R3RuntimeInput
 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options = {}) - Starts the system by registering subscriptions to events
  stop(options = {}) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Input extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Input already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Input');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Input already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Input');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Input.IsStarting) {
      console.log('client Input system is already starting...');
      return;
    }

    Input.IsStarting = true;

    if (Input.Started === true) {
      Input.IsStarting = false;
      console.log('client Input system already started');
      return;
    }

    Input.Runtimes = new Set();
    Input.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Input.Subscriptions['BEFORE_ASSIGN_PROPERTY'] = Event.On(
      Event.BEFORE_ASSIGN_PROPERTY,
      Input.BeforeAssignProperty,
      {priority: 70}
    );

    /**
     * No comment
     */
    Input.Subscriptions['AFTER_ASSIGN_PROPERTY'] = Event.On(
      Event.AFTER_ASSIGN_PROPERTY,
      Input.AfterAssignProperty,
      {priority: 50}
    );

    /**
     * No comment
     */
    Input.Subscriptions['INITIALIZE_INPUT_COMPONENT'] = Event.On(
      Event.INITIALIZE_INPUT_COMPONENT,
      Input.InitializeInputComponent,
      {priority: 90}
    );

    /**
     * No comment
     */
    Input.Subscriptions['DISPOSE_INPUT_COMPONENT'] = Event.On(
      Event.DISPOSE_INPUT_COMPONENT,
      Input.DisposeInputComponent,
      {priority: 10}
    );

    /**
     * No comment
     */
    Input.Subscriptions['UPDATE_CONTROL'] = Event.On(
      Event.UPDATE_CONTROL,
      Input.UpdateControl,
      {priority: 10}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    /**
     * Gets the Three runtime implementation of R3RuntimeInput
     */
    Input.Subscriptions['GET_RUNTIME_INPUT_THREE'] = Event.On(
      Event.GET_RUNTIME_INPUT_THREE,
      Input.GetRuntimeInputThree
    );

    /**
     * Gets the Custom runtime implementation of R3RuntimeInput
     */
    Input.Subscriptions['GET_RUNTIME_INPUT_CUSTOM'] = Event.On(
      Event.GET_RUNTIME_INPUT_CUSTOM,
      Input.GetRuntimeInputCustom
    );
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    Input.Started = true;
    Input.IsStarting = false;
    console.log('Started client system Input');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Input.IsStopping) {
      console.log('client Input system is already stopping');
      return;
    }

    Input.IsStopping = true;

    if (Input.Started === false) {
      Input.IsStopping = false;
      console.log('client Input system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    Input.Subscriptions['BEFORE_ASSIGN_PROPERTY'].remove();
    delete Input.Subscriptions['BEFORE_ASSIGN_PROPERTY'];

    Input.Subscriptions['AFTER_ASSIGN_PROPERTY'].remove();
    delete Input.Subscriptions['AFTER_ASSIGN_PROPERTY'];

    Input.Subscriptions['INITIALIZE_INPUT_COMPONENT'].remove();
    delete Input.Subscriptions['INITIALIZE_INPUT_COMPONENT'];

    Input.Subscriptions['DISPOSE_INPUT_COMPONENT'].remove();
    delete Input.Subscriptions['DISPOSE_INPUT_COMPONENT'];

    Input.Subscriptions['UPDATE_CONTROL'].remove();
    delete Input.Subscriptions['UPDATE_CONTROL'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    Input.Subscriptions['GET_RUNTIME_INPUT_THREE'].remove();
    delete Input.Subscriptions['GET_RUNTIME_INPUT_THREE'];

    Input.Subscriptions['GET_RUNTIME_INPUT_CUSTOM'].remove();
    delete Input.Subscriptions['GET_RUNTIME_INPUT_CUSTOM'];
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    Input.Runtimes.clear();
    Input.Started = false;
    Input.IsStopping = false;
    console.log('Stopped client system Input');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START
    Input.Runtimes.add(Input.RuntimeInputThree);
    Input.Runtimes.add(Input.RuntimeInputCustom);
    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * BeforeAssignProperty()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static BeforeAssignProperty(event) {

    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_START
    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_BEFORE_ASSIGN_PROPERTY_START
    let {object: component} = event;

    let {property, value} = event;

    if (!(component instanceof InputComponent)) {
      return;
    }

    if (property !== 'domElement') {
      return;
    }

    let runtimes = [];

    for (let key of Object.keys(component.runtimes)) {
      let item = component.runtimes[key];
      if (Input.Runtimes.has(item.runtime)) {
        runtimes.push(item.runtime);
      }
    }

    for (let runtime of runtimes) {
      runtime.deRegisterControl(component);
      component.instances[runtime.type].object = null;
    }
    //CUSTOM_STATIC_BEFORE_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_BEFORE_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_BEFORE_ASSIGN_PROPERTY_AFTER_END

  }

  /**
   * AfterAssignProperty()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static AfterAssignProperty(event) {

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    /**
     * Override
     */
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_START
    let {object: component} = event;

    let {property, value} = event;

    if (!(component instanceof InputComponent)) {
      return;
    }

    let runtimes = [];

    for (let key of Object.keys(component.runtimes)) {
      let item = component.runtimes[key];
      if (Input.Runtimes.has(item.runtime)) {
        runtimes.push(item.runtime);
      }
    }

    for (let runtime of runtimes) {

      let wasValid;
      let isValid;

      if (!runtime.validObjects.hasOwnProperty(component.id)) {
        wasValid = false;
        isValid = runtime.validate('registerControl', component);
      } else {
        wasValid = runtime.validObjects[component.id];
        isValid = runtime.validate("registerControl", component);
      }

      if (!wasValid && !isValid) {
        /**
         * Do nothing
         */
        continue;
      }

      if (!wasValid && isValid) {
        let instance = runtime.registerControl(component);
        component.instances[runtime.type].object = instance;
        continue;
      }

      if (wasValid && !isValid) {
        runtime.deRegisterControl(component);
        component.instances[runtime.type].object = null;
        continue;
      }

      if (wasValid && isValid) {
        let instance = component.getInstance(runtime);
        if (instance) {
          runtime.updateControl(component, property, value);
        }
        // noinspection UnnecessaryContinueJS
        continue;
      }
    }
    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_END

  }

  /**
   * InitializeInputComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeInputComponent(event) {

    //GENERATED_STATIC_INITIALIZE_INPUT_COMPONENT_START
    //GENERATED_STATIC_INITIALIZE_INPUT_COMPONENT_END

    //CUSTOM_STATIC_INITIALIZE_INPUT_COMPONENT_START
    let {object: component} = event;

    let runtimes = [];

    for (let key of Object.keys(component.runtimes)) {
      let item = component.runtimes[key];
      if (Input.Runtimes.has(item.runtime)) {
        runtimes.push(item.runtime);
      }
    }

    for (let runtime of runtimes) {

      let valid;

      if (!runtime.validObjects.hasOwnProperty(component.id)) {
        valid = runtime.validate('registerControl', component);
      }

      if (valid) {
        let instance = runtime.registerControl(component);
        component.instances[runtime.type].object = instance;
      }
    }
    //CUSTOM_STATIC_INITIALIZE_INPUT_COMPONENT_END

    //GENERATED_STATIC_INITIALIZE_INPUT_COMPONENT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_INPUT_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_INPUT_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_INPUT_COMPONENT_AFTER_END

  }

  /**
   * DisposeInputComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeInputComponent(event) {

    //GENERATED_STATIC_DISPOSE_INPUT_COMPONENT_START
    //GENERATED_STATIC_DISPOSE_INPUT_COMPONENT_END

    //CUSTOM_STATIC_DISPOSE_INPUT_COMPONENT_START
    let {object: component} = event;

    let runtimes = [];

    for (let key of Object.keys(component.runtimes)) {
      let item = component.runtimes[key];
      if (Input.Runtimes.has(item.runtime)) {
        runtimes.push(item.runtime);
      }
    }

    for (let runtime of runtimes) {
      runtime.deRegisterControl(component);
      component.instances[runtime.type].object = null;
    }
    //CUSTOM_STATIC_DISPOSE_INPUT_COMPONENT_END

    //GENERATED_STATIC_DISPOSE_INPUT_COMPONENT_AFTER_START
    //GENERATED_STATIC_DISPOSE_INPUT_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_INPUT_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_INPUT_COMPONENT_AFTER_END

  }

  /**
   * UpdateControl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static UpdateControl(event) {

    //GENERATED_STATIC_UPDATE_CONTROL_START
    //GENERATED_STATIC_UPDATE_CONTROL_END

    //CUSTOM_STATIC_UPDATE_CONTROL_START
    let {object: component} = event;

    let {property, value} = event;

    if (!(component instanceof InputComponent)) {
      return;
    }

    let runtimes = [];

    for (let key of Object.keys(component.runtimes)) {
      let item = component.runtimes[key];
      if (Input.Runtimes.has(item.runtime)) {
        runtimes.push(item.runtime);
      }
    }

    for (let runtime of runtimes) {

      let instance = component.getInstance(runtime);

      if (!instance) {
        Utils.Status(-1, `${component.name} has no instance to update`);
      }

      runtime.updateControl(component, property, value);
    }
    //CUSTOM_STATIC_UPDATE_CONTROL_END

    //GENERATED_STATIC_UPDATE_CONTROL_AFTER_START
    //GENERATED_STATIC_UPDATE_CONTROL_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPDATE_CONTROL_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPDATE_CONTROL_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  /**
   * GetRuntimeInputThree()
   * - Gets the Three runtime implementation of R3RuntimeInput
   * @param {Object} event
   * - No returns
   */
  static GetRuntimeInputThree(event) {

    //GENERATED_STATIC_GET_RUNTIME_INPUT_THREE_START
    event.results = [Input.RuntimeInputThree];
    //GENERATED_STATIC_GET_RUNTIME_INPUT_THREE_END

    //CUSTOM_STATIC_GET_RUNTIME_INPUT_THREE_START
    //CUSTOM_STATIC_GET_RUNTIME_INPUT_THREE_END

    //GENERATED_STATIC_GET_RUNTIME_INPUT_THREE_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_INPUT_THREE_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_INPUT_THREE_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_INPUT_THREE_AFTER_END

  }

  /**
   * GetRuntimeInputCustom()
   * - Gets the Custom runtime implementation of R3RuntimeInput
   * @param {Object} event
   * - No returns
   */
  static GetRuntimeInputCustom(event) {

    //GENERATED_STATIC_GET_RUNTIME_INPUT_CUSTOM_START
    event.results = [Input.RuntimeInputCustom];
    //GENERATED_STATIC_GET_RUNTIME_INPUT_CUSTOM_END

    //CUSTOM_STATIC_GET_RUNTIME_INPUT_CUSTOM_START
    //CUSTOM_STATIC_GET_RUNTIME_INPUT_CUSTOM_END

    //GENERATED_STATIC_GET_RUNTIME_INPUT_CUSTOM_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_INPUT_CUSTOM_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_INPUT_CUSTOM_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_INPUT_CUSTOM_AFTER_END

  }
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
/**
 * @param Input.RuntimeInputThree
 * - Runtime implementation of R3RuntimeInput
 */
Input.RuntimeInputThree = new ThreeRuntime();

/**
 * @param Input.RuntimeInputCustom
 * - Runtime implementation of R3RuntimeInput
 */
Input.RuntimeInputCustom = new CustomRuntime();
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Input.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Input.IsStarting = false;

/**
 * @param Input.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Input.IsStopping = false;

/**
 * @param Input.Started
 * - Indicates whether this system is running
 */
Input.Started = false;

/**
 * @param Input.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Input.Subscriptions = {};

/**
 * @param Input.Runtimes
 * - A set of runtimes which the system manages
 */
Input.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Input as default};
