//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Runtime from '../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Animation from '../Animation.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  mesh=null
  code=null
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeEntityDefault" :
    [
      {
        "methods" : ["activate"],
        "properties" : [
          {
            "property" : "mesh",
            "type" : "R3EventObjComponentGraphicsMesh"
          },
          {
            "property" : "code",
            "type" : "R3EventObjComponentCode",
            "deep" : true
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
  "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "mesh",
            "type" : "R3EventObjProject"
          },
          {
            "property" : "code",
            "type" : "R3EventObjComponentCode"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 CUSTOM_REQUIREMENTS_START
 CUSTOM_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  activate() - Starts an entity or code component by subscribing to all events of the code components 
  deActivate() - Stops an entity or code component by unsubscribing to all events of the code components 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 **/

export class Rotation extends Animation {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Entity
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjEntityAnimationRotation';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Rotation';
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param mesh
     * - No comment
     */
    if (typeof options.mesh === 'undefined') {
      options.mesh = null;
    }

    /**
     * @param code
     * - No comment
     */
    if (typeof options.code === 'undefined') {
      options.code = null;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_ENTITY_DEFAULT)) {
      options.runtimes[Runtime.KEY_ENTITY_DEFAULT] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_ENTITY_DEFAULT]['requirements']) {
      options.runtimes[Runtime.KEY_ENTITY_DEFAULT].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_ENTITY_DEFAULT].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "activate"
            ],
            "properties": [
              {
                "property": "mesh",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMesh")
              },
              {
                "property": "code",
                "Constructor" : this.getClassConstructor("R3EventObjComponentCode"),
                "deep": true
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "mesh",
                "Constructor" : this.getClassConstructor("R3EventObjProject")
              },
              {
                "property": "code",
                "Constructor" : this.getClassConstructor("R3EventObjComponentCode")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_ENTITY_DEFAULT,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "mesh",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMesh"),
        "type": "R3EventObjComponentGraphicsMesh"
      },
      {
        "property": "code",
        "Constructor" : this.getClassConstructor("R3EventObjComponentCode"),
        "type": "R3EventObjComponentCode"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * activate()
   * - Starts an entity or code component by subscribing to all events of the code components
   * - No parameters
   * - No returns
   */
  activate() {

    //CUSTOM_ACTIVATE_BEFORE_START
    //CUSTOM_ACTIVATE_BEFORE_END

    //GENERATED_ACTIVATE_BEFORE_START
    //GENERATED_ACTIVATE_BEFORE_END

    //CUSTOM_ACTIVATE_BEFORE_GENERATED_START
    //CUSTOM_ACTIVATE_BEFORE_GENERATED_END

    //GENERATED_ACTIVATE_START
    super.activate();

    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.ACTIVATE_ROTATION,
      payload
    );
    //GENERATED_ACTIVATE_END

    //CUSTOM_ACTIVATE_START
    //CUSTOM_ACTIVATE_END

    //GENERATED_ACTIVATE_AFTER_START
    //GENERATED_ACTIVATE_AFTER_END

  }

  /**
   * deActivate()
   * - Stops an entity or code component by unsubscribing to all events of the code components
   * - No parameters
   * - No returns
   */
  deActivate() {

    //CUSTOM_DE_ACTIVATE_BEFORE_START
    //CUSTOM_DE_ACTIVATE_BEFORE_END

    //GENERATED_DE_ACTIVATE_BEFORE_START
    //GENERATED_DE_ACTIVATE_BEFORE_END

    //CUSTOM_DE_ACTIVATE_BEFORE_GENERATED_START
    //CUSTOM_DE_ACTIVATE_BEFORE_GENERATED_END

    //GENERATED_DE_ACTIVATE_START
    super.deActivate();

    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.DE_ACTIVATE_ROTATION,
      payload
    );
    //GENERATED_DE_ACTIVATE_END

    //CUSTOM_DE_ACTIVATE_START
    //CUSTOM_DE_ACTIVATE_END

    //GENERATED_DE_ACTIVATE_AFTER_START
    //GENERATED_DE_ACTIVATE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_EVENT_LISTENERS_START
  //GENERATED_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Rotation.Type = 'R3EventObjEntityAnimationRotation';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Rotation as default};
