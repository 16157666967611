//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Vector3 from '../maths/Vector3.js';
import Utils from '../../../../Utils.js';
import Runtime from '../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Graphics from '../Graphics.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  position=new Vector3() - The position of the raycaster
  direction=new Vector3() - The direction the ray will be cast
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeGraphicsThree" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "position",
            "type" : "R3EventObjComponentMathsVector3",
            "deep" : true
          },
          {
            "property" : "direction",
            "type" : "R3EventObjComponentMathsVector3",
            "deep" : true
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
  setFromCamera(mouse, camera) - Sets the ray position and direction based on the mouse co-ordinates and camera position and orientation
  getIntersectedObjects() - Returns a list of objects that intersect with the ray
  getFirstIntersectedObject() - Returns a list of objects that intersect with the ray
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  cloneInstance(propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance() - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance() - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Raycaster extends Graphics {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphicsRaycaster';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Raycaster';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param position
     * - The position of the raycaster
     */
    if (typeof options.position === 'undefined') {
      options.position = new Vector3();
    }

    /**
     * @param direction
     * - The direction the ray will be cast
     */
    if (typeof options.direction === 'undefined') {
      options.direction = new Vector3();
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_GRAPHICS_THREE)) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_GRAPHICS_THREE]['requirements']) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "position",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "deep": true
              },
              {
                "property": "direction",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "deep": true
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_GRAPHICS_THREE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "position",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3"
      },
      {
        "property": "direction",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * setFromCamera()
   * - Sets the ray position and direction based on the mouse co-ordinates and camera position and orientation
   * @param mouse
   * @param camera
   * - No returns
   */
  setFromCamera(mouse, camera) {

    //CUSTOM_SET_FROM_CAMERA_BEFORE_START
    //CUSTOM_SET_FROM_CAMERA_BEFORE_END

    //GENERATED_SET_FROM_CAMERA_BEFORE_START
    //GENERATED_SET_FROM_CAMERA_BEFORE_END

    //CUSTOM_SET_FROM_CAMERA_BEFORE_GENERATED_START
    //CUSTOM_SET_FROM_CAMERA_BEFORE_GENERATED_END

    //GENERATED_SET_FROM_CAMERA_START
    //GENERATED_SET_FROM_CAMERA_END

    //CUSTOM_SET_FROM_CAMERA_START
    let instance = this.getInstance(Runtime.KEY_GRAPHICS_THREE);

    let cameraInstance = camera.getInstance(Runtime.KEY_GRAPHICS_THREE);

    instance.setFromCamera(
      mouse,
      cameraInstance
    );

    this.updateFromCameraInstance = true;

    this.position.x = instance.ray.origin.x;
    this.position.y = instance.ray.origin.y;
    this.position.z = instance.ray.origin.z;

    this.direction.x = instance.ray.direction.x;
    this.direction.y = instance.ray.direction.y;
    this.direction.z = instance.ray.direction.z;

    delete this.updateFromCameraInstance;
    //CUSTOM_SET_FROM_CAMERA_END

    //GENERATED_SET_FROM_CAMERA_AFTER_START
    //GENERATED_SET_FROM_CAMERA_AFTER_END

  }

  /**
   * getIntersectedObjects()
   * - Returns a list of objects that intersect with the ray
   * - No parameters
   * - No returns
   */
  getIntersectedObjects() {

    //CUSTOM_GET_INTERSECTED_OBJECTS_BEFORE_START
    //CUSTOM_GET_INTERSECTED_OBJECTS_BEFORE_END

    //GENERATED_GET_INTERSECTED_OBJECTS_BEFORE_START
    //GENERATED_GET_INTERSECTED_OBJECTS_BEFORE_END

    //CUSTOM_GET_INTERSECTED_OBJECTS_BEFORE_GENERATED_START
    //CUSTOM_GET_INTERSECTED_OBJECTS_BEFORE_GENERATED_END

    //GENERATED_GET_INTERSECTED_OBJECTS_START
    //GENERATED_GET_INTERSECTED_OBJECTS_END

    //CUSTOM_GET_INTERSECTED_OBJECTS_START
    let instance = this.getInstance(Runtime.KEY_GRAPHICS_THREE);

    let meshes = Utils.GetMeshes();

    let intersects = meshes.reduce(
      function (result, mesh) {

        let meshInstance = mesh.getInstance(Runtime.KEY_GRAPHICS_THREE);

        if (meshInstance) {

          let intersects = instance.intersectObject(meshInstance);

          if (intersects.length > 0) {

            let intersect = intersects[0];

            let {distance, face, faceIndex, normal, point, uv} = intersect;

            result.push(
              {
                mesh,
                distance,
                face,
                faceIndex,
                normal,
                point,
                uv
              }
            );
          }
        }

        return result;
      }.bind(this),
      []
    );

    return intersects;
    //CUSTOM_GET_INTERSECTED_OBJECTS_END

    //GENERATED_GET_INTERSECTED_OBJECTS_AFTER_START
    //GENERATED_GET_INTERSECTED_OBJECTS_AFTER_END

  }

  /**
   * getFirstIntersectedObject()
   * - Returns a list of objects that intersect with the ray
   * - No parameters
   * - No returns
   */
  getFirstIntersectedObject() {

    //CUSTOM_GET_FIRST_INTERSECTED_OBJECT_BEFORE_START
    //CUSTOM_GET_FIRST_INTERSECTED_OBJECT_BEFORE_END

    //GENERATED_GET_FIRST_INTERSECTED_OBJECT_BEFORE_START
    //GENERATED_GET_FIRST_INTERSECTED_OBJECT_BEFORE_END

    //CUSTOM_GET_FIRST_INTERSECTED_OBJECT_BEFORE_GENERATED_START
    //CUSTOM_GET_FIRST_INTERSECTED_OBJECT_BEFORE_GENERATED_END

    //GENERATED_GET_FIRST_INTERSECTED_OBJECT_START
    //GENERATED_GET_FIRST_INTERSECTED_OBJECT_END

    //CUSTOM_GET_FIRST_INTERSECTED_OBJECT_START
    let intersects = this.getIntersectedObjects();

    let intersect = null;

    let minDistance;
    intersects.map(
      (tmp) => {
        if (typeof minDistance === 'undefined') {
          minDistance = tmp.distance;
          intersect = tmp;
        } else {
          if (minDistance > tmp.distance) {
            minDistance = tmp.distance;
            intersect = tmp;
          }
        }
      }
    )

    return intersect;
    //CUSTOM_GET_FIRST_INTERSECTED_OBJECT_END

    //GENERATED_GET_FIRST_INTERSECTED_OBJECT_AFTER_START
    //GENERATED_GET_FIRST_INTERSECTED_OBJECT_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * cloneInstance()
   * - Clones an instance, when propagate is true, also clones the instance children
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstance(propagate = true) {

    //CUSTOM_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BEFORE_END

    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CLONE_INSTANCE_START
    /**
     * Override Template
     */
    //GENERATED_CLONE_INSTANCE_END

    //CUSTOM_CLONE_INSTANCE_START
    //CUSTOM_CLONE_INSTANCE_END

    //GENERATED_CLONE_INSTANCE_AFTER_START
    //GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  createInstance() {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  deleteInstance() {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Raycaster.Type = 'R3EventObjComponentGraphicsRaycaster';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Raycaster as default};
