//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Runtime from '../../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Camera from '../Camera.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  bottom = -5 - Camera frustum bottom plane.
  left = -5 - Camera frustum left plane.
  right = 5 - Camera frustum right plane.
  top = 5 - Camera frustum top plane.
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "bottom",
            "type" : "Number",
            "min" : -2500,
            "max" : 2500,
            "step" : 1
          },
          {
            "property" : "left",
            "type" : "Number",
            "min" : -2500,
            "max" : 2500,
            "step" : 1
          },
          {
            "property" : "right",
            "type" : "Number",
            "min" : -2500,
            "max" : 2500,
            "step" : 1
          },
          {
            "property" : "top",
            "type" : "Number",
            "min" : -2500,
            "max" : 2500,
            "step" : 1
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  cloneInstance(propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance() - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance() - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Orthographic extends Camera {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphicsCameraOrthographic';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Orthographic';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param bottom
     * - 5 - Camera frustum bottom plane.
     */
    if (typeof options.bottom === 'undefined') {
      options.bottom = -5;
    }

    /**
     * @param left
     * - 5 - Camera frustum left plane.
     */
    if (typeof options.left === 'undefined') {
      options.left = -5;
    }

    /**
     * @param right
     * - Camera frustum right plane.
     */
    if (typeof options.right === 'undefined') {
      options.right = 5;
    }

    /**
     * @param top
     * - Camera frustum top plane.
     */
    if (typeof options.top === 'undefined') {
      options.top = 5;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "bottom",
                "Constructor" : this.getClassConstructor("Number"),
                "min": -2500,
                "max": 2500,
                "step": 1
              },
              {
                "property": "left",
                "Constructor" : this.getClassConstructor("Number"),
                "min": -2500,
                "max": 2500,
                "step": 1
              },
              {
                "property": "right",
                "Constructor" : this.getClassConstructor("Number"),
                "min": -2500,
                "max": 2500,
                "step": 1
              },
              {
                "property": "top",
                "Constructor" : this.getClassConstructor("Number"),
                "min": -2500,
                "max": 2500,
                "step": 1
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_GRAPHICS_THREE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "bottom",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": -2500,
        "max": 2500,
        "step": 1
      },
      {
        "property": "left",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": -2500,
        "max": 2500,
        "step": 1
      },
      {
        "property": "right",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": -2500,
        "max": 2500,
        "step": 1
      },
      {
        "property": "top",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": -2500,
        "max": 2500,
        "step": 1
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * cloneInstance()
   * - Clones an instance, when propagate is true, also clones the instance children
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstance(propagate = true) {

    //CUSTOM_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BEFORE_END

    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CLONE_INSTANCE_START
    /**
     * Override Template
     */
    //GENERATED_CLONE_INSTANCE_END

    //CUSTOM_CLONE_INSTANCE_START
    //CUSTOM_CLONE_INSTANCE_END

    //GENERATED_CLONE_INSTANCE_AFTER_START
    //GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  createInstance() {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  deleteInstance() {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Orthographic.Type = 'R3EventObjComponentGraphicsCameraOrthographic';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Orthographic as default};
