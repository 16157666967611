//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
import * as rigid_bodyImport from './rigidbody/index.js';
import * as jointImport from './joint/index.js';
import * as colliderImport from './collider/index.js';
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as World} from './World.js';
import {default as RigidBody} from './RigidBody.js';
import {default as Joint} from './Joint.js';
import {default as Gravity} from './Gravity.js';
import {default as Collider} from './Collider.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
const FixedBody = rigid_bodyImport.Fixed;
const Dynamic = rigid_bodyImport.Dynamic;
const Spherical = jointImport.Spherical;
const Revolute = jointImport.Revolute;
const Prismatic = jointImport.Prismatic;
const FixedJoint = jointImport.Fixed;
const TriMesh = colliderImport.TriMesh;
const HeightField = colliderImport.HeightField;
const Cuboid = colliderImport.Cuboid;
const Capsule = colliderImport.Capsule;
const Ball = colliderImport.Ball;
RigidBody.Fixed = FixedBody;
RigidBody.Dynamic = Dynamic;
Joint.Spherical = Spherical;
Joint.Revolute = Revolute;
Joint.Prismatic = Prismatic;
Joint.Fixed = FixedJoint;
Collider.TriMesh = TriMesh;
Collider.HeightField = HeightField;
Collider.Cuboid = Cuboid;
Collider.Capsule = Capsule;
Collider.Ball = Ball;
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
//CUSTOM_ASSIGN_TO_BASE_END

//GENERATED_EXPORTS_START
export {
  World,
  RigidBody,
  Joint,
  Gravity,
  Collider,
  FixedBody,
  Dynamic,
  Spherical,
  Revolute,
  Prismatic,
  FixedJoint,
  TriMesh,
  HeightField,
  Cuboid,
  Capsule,
  Ball,
//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
