//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Vector3 from '../../maths/Vector3.js';
import Runtime from '../../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import RigidBody from '../RigidBody.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  linearVelocity=new Vector3() - The linear velocity of this rigid body
  angularVelocity=new Vector3() - The angular velocity of this rigid body
  gravityScale=1 - The scale applied to the gravity for this rigid body (multiplies the gravity vector by this scale)
  forceVector=new Vector3() - The force vector to be applied if no given force
  torqueVector=new Vector3() - The torque vector to be applied if no given torque
  impulseVector=new Vector3() - The impulse vector to be applied if no given impulse
  enabledRotations=new Vector3({x : 1, y : 1, z : 1}) - The rigid body is allowed to rotate on these axes
  enabledTranslations=new Vector3({x : 1, y : 1, z : 1}) - The rigid body is allowed to move on these axes
  linearDamping=0 - How quickly the rigid body slows down in its linear velocity. Higher number indicates quicker slow down
  angularDamping=0 - How quickly the rigid body slows down in its angular (rotating) velocity. Higher number indicates quicker slow down
  dominanceGroup=0 - How dominant this rigid body is. A more dominant rigid body will remain immobile during contact regardless of its mass
  continuousCollisionDetection=false - Set this to true to prevent very fast moving rigid bodies to tunnel 'through' objects. This is computationally expensive and should only be activated for VERY fast moving objects, at the time they are expected to move fast.
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimePhysicsRapier" :
    [
      {
        "methods" : ["createInstance"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "forceVector",
            "type" : "R3EventObjComponentMathsVector3",
            "min" : -10000,
            "max" : 10000,
            "step" : 0.00001
          },
          {
            "property" : "torqueVector",
            "type" : "R3EventObjComponentMathsVector3",
            "min" : -10000,
            "max" : 10000,
            "step" : 0.00001
          },
          {
            "property" : "impulseVector",
            "type" : "R3EventObjComponentMathsVector3",
            "min" : -10000,
            "max" : 10000,
            "step" : 0.00001
          },
          {
            "property" : "linearVelocity",
            "type" : "R3EventObjComponentMathsVector3",
            "min" : -10000000,
            "max" : 10000000,
            "step" : 0.00001
          },
          {
            "property" : "angularVelocity",
            "type" : "R3EventObjComponentMathsVector3",
            "min" : -10000000,
            "max" : 10000000,
            "step" : 0.00001
          },
          {
            "property" : "gravityScale",
            "type" : "Number",
            "min" : 1,
            "max" : 10,
            "step" : 0.001
          },
          {
            "property" : "enabledRotations",
            "type" : "R3EventObjComponentMathsVector3",
            "min" : 0,
            "max" : 1,
            "step" : 1
          },
          {
            "property" : "enabledTranslations",
            "type" : "R3EventObjComponentMathsVector3",
            "min" : 0,
            "max" : 1,
            "step" : 1
          },
          {
            "property" : "linearDamping",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.0001
          },
          {
            "property" : "angularDamping",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.0001
          },
          {
            "property" : "dominanceGroup",
            "type" : "Number",
            "min" : -127,
            "max" : 127,
            "step" : 1
          },
          {
            "property" : "continuousCollisionDetection",
            "type" : "Boolean"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createInstance() - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance() - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Dynamic extends RigidBody {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentPhysicsRigidBodyDynamic';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Dynamic';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param linearVelocity
     * - The linear velocity of this rigid body
     */
    if (typeof options.linearVelocity === 'undefined') {
      options.linearVelocity = new Vector3();
    }

    /**
     * @param angularVelocity
     * - The angular velocity of this rigid body
     */
    if (typeof options.angularVelocity === 'undefined') {
      options.angularVelocity = new Vector3();
    }

    /**
     * @param gravityScale
     * - The scale applied to the gravity for this rigid body (multiplies the gravity vector by this scale)
     */
    if (typeof options.gravityScale === 'undefined') {
      options.gravityScale = 1;
    }

    /**
     * @param forceVector
     * - The force vector to be applied if no given force
     */
    if (typeof options.forceVector === 'undefined') {
      options.forceVector = new Vector3();
    }

    /**
     * @param torqueVector
     * - The torque vector to be applied if no given torque
     */
    if (typeof options.torqueVector === 'undefined') {
      options.torqueVector = new Vector3();
    }

    /**
     * @param impulseVector
     * - The impulse vector to be applied if no given impulse
     */
    if (typeof options.impulseVector === 'undefined') {
      options.impulseVector = new Vector3();
    }

    /**
     * @param enabledRotations
     * - The rigid body is allowed to rotate on these axes
     */
    if (typeof options.enabledRotations === 'undefined') {
      options.enabledRotations = new Vector3({x : 1, y : 1, z : 1});
    }

    /**
     * @param enabledTranslations
     * - The rigid body is allowed to move on these axes
     */
    if (typeof options.enabledTranslations === 'undefined') {
      options.enabledTranslations = new Vector3({x : 1, y : 1, z : 1});
    }

    /**
     * @param linearDamping
     * - How quickly the rigid body slows down in its linear velocity. Higher number indicates quicker slow down
     */
    if (typeof options.linearDamping === 'undefined') {
      options.linearDamping = 0;
    }

    /**
     * @param angularDamping
     * - How quickly the rigid body slows down in its angular (rotating) velocity. Higher number indicates quicker slow
     *   down
     */
    if (typeof options.angularDamping === 'undefined') {
      options.angularDamping = 0;
    }

    /**
     * @param dominanceGroup
     * - How dominant this rigid body is. A more dominant rigid body will remain immobile during contact regardless of
     *   its mass
     */
    if (typeof options.dominanceGroup === 'undefined') {
      options.dominanceGroup = 0;
    }

    /**
     * @param continuousCollisionDetection
     * - Set this to true to prevent very fast moving rigid bodies to tunnel 'through' objects. This is computationally
     *   expensive and should only be activated for VERY fast moving objects, at the time they are expected to move fast.
     */
    if (typeof options.continuousCollisionDetection === 'undefined') {
      options.continuousCollisionDetection = false;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_PHYSICS_RAPIER)) {
      options.runtimes[Runtime.KEY_PHYSICS_RAPIER] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_PHYSICS_RAPIER]['requirements']) {
      options.runtimes[Runtime.KEY_PHYSICS_RAPIER].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_PHYSICS_RAPIER].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "forceVector",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "min": -10000,
                "max": 10000,
                "step": 0.00001
              },
              {
                "property": "torqueVector",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "min": -10000,
                "max": 10000,
                "step": 0.00001
              },
              {
                "property": "impulseVector",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "min": -10000,
                "max": 10000,
                "step": 0.00001
              },
              {
                "property": "linearVelocity",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "min": -10000000,
                "max": 10000000,
                "step": 0.00001
              },
              {
                "property": "angularVelocity",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "min": -10000000,
                "max": 10000000,
                "step": 0.00001
              },
              {
                "property": "gravityScale",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 1,
                "max": 10,
                "step": 0.001
              },
              {
                "property": "enabledRotations",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "min": 0,
                "max": 1,
                "step": 1
              },
              {
                "property": "enabledTranslations",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "min": 0,
                "max": 1,
                "step": 1
              },
              {
                "property": "linearDamping",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.0001
              },
              {
                "property": "angularDamping",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.0001
              },
              {
                "property": "dominanceGroup",
                "Constructor" : this.getClassConstructor("Number"),
                "min": -127,
                "max": 127,
                "step": 1
              },
              {
                "property": "continuousCollisionDetection",
                "Constructor" : this.getClassConstructor("Boolean")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_PHYSICS_RAPIER,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "forceVector",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3",
        "min": -10000,
        "max": 10000,
        "step": 0.00001
      },
      {
        "property": "torqueVector",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3",
        "min": -10000,
        "max": 10000,
        "step": 0.00001
      },
      {
        "property": "impulseVector",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3",
        "min": -10000,
        "max": 10000,
        "step": 0.00001
      },
      {
        "property": "linearVelocity",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3",
        "min": -10000000,
        "max": 10000000,
        "step": 0.00001
      },
      {
        "property": "angularVelocity",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3",
        "min": -10000000,
        "max": 10000000,
        "step": 0.00001
      },
      {
        "property": "gravityScale",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 1,
        "max": 10,
        "step": 0.001
      },
      {
        "property": "enabledRotations",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3",
        "min": 0,
        "max": 1,
        "step": 1
      },
      {
        "property": "enabledTranslations",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3",
        "min": 0,
        "max": 1,
        "step": 1
      },
      {
        "property": "linearDamping",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.0001
      },
      {
        "property": "angularDamping",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.0001
      },
      {
        "property": "dominanceGroup",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": -127,
        "max": 127,
        "step": 1
      },
      {
        "property": "continuousCollisionDetection",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  createInstance() {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  deleteInstance() {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Dynamic.Type = 'R3EventObjComponentPhysicsRigidBodyDynamic';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Dynamic as default};
