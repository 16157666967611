//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as Touch} from './Touch.js';
import {default as OrbitControls} from './OrbitControls.js';
import {default as Mouse} from './Mouse.js';
import {default as Keyboard} from './Keyboard.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
//CUSTOM_ASSIGN_TO_BASE_END

//GENERATED_EXPORTS_START
export {
  Touch,
  OrbitControls,
  Mouse,
  Keyboard,
//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
