//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Vector3 from '../maths/Vector3.js';
import Quaternion from '../maths/Quaternion.js';
import Runtime from '../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Physics from '../Physics.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  world=null - The physics world that this rigid body belongs to
  mesh=null - The mesh parent of this rigid body
  position=new Vector3() - The position of this rigid body (corresponds to the mesh position)
  quaternion=new Quaternion() - The rotation quaternion of this rigid body
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimePhysicsRapier" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "world",
            "type" : "R3EventObjComponentPhysicsWorld"
          },
          {
            "property" : "mesh",
            "type" : "R3EventObjComponentGraphicsMesh"
          },
          {
            "property" : "position",
            "type" : "R3EventObjComponentMathsVector3"
          },
          {
            "property" : "quaternion",
            "type" : "R3EventObjComponentMathsQuaternion"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
  applyForce(vector = null) - Applies a force to the rigid body instance
  applyTorque(vector = null) - Applies a force to the rigid body instance
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createInstance() - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance() - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class RigidBody extends Physics {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentPhysicsRigidBody';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'RigidBody';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param world
     * - The physics world that this rigid body belongs to
     */
    if (typeof options.world === 'undefined') {
      options.world = null;
    }

    /**
     * @param mesh
     * - The mesh parent of this rigid body
     */
    if (typeof options.mesh === 'undefined') {
      options.mesh = null;
    }

    /**
     * @param position
     * - The position of this rigid body (corresponds to the mesh position)
     */
    if (typeof options.position === 'undefined') {
      options.position = new Vector3();
    }

    /**
     * @param quaternion
     * - The rotation quaternion of this rigid body
     */
    if (typeof options.quaternion === 'undefined') {
      options.quaternion = new Quaternion();
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_PHYSICS_RAPIER)) {
      options.runtimes[Runtime.KEY_PHYSICS_RAPIER] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_PHYSICS_RAPIER]['requirements']) {
      options.runtimes[Runtime.KEY_PHYSICS_RAPIER].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_PHYSICS_RAPIER].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "world",
                "Constructor" : this.getClassConstructor("R3EventObjComponentPhysicsWorld")
              },
              {
                "property": "mesh",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMesh")
              },
              {
                "property": "position",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3")
              },
              {
                "property": "quaternion",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsQuaternion")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_PHYSICS_RAPIER,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "world",
        "Constructor" : this.getClassConstructor("R3EventObjComponentPhysicsWorld"),
        "type": "R3EventObjComponentPhysicsWorld"
      },
      {
        "property": "mesh",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMesh"),
        "type": "R3EventObjComponentGraphicsMesh"
      },
      {
        "property": "position",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3"
      },
      {
        "property": "quaternion",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsQuaternion"),
        "type": "R3EventObjComponentMathsQuaternion"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * applyForce()
   * - Applies a force to the rigid body instance
   * @param {Object|null} [vector=null]
   * - No returns
   */
  applyForce(vector = null) {

    //CUSTOM_APPLY_FORCE_BEFORE_START
    //CUSTOM_APPLY_FORCE_BEFORE_END

    //GENERATED_APPLY_FORCE_BEFORE_START
    //GENERATED_APPLY_FORCE_BEFORE_END

    //CUSTOM_APPLY_FORCE_BEFORE_GENERATED_START
    //CUSTOM_APPLY_FORCE_BEFORE_GENERATED_END

    //GENERATED_APPLY_FORCE_START
    //GENERATED_APPLY_FORCE_END

    //CUSTOM_APPLY_FORCE_START
    if (!vector) {
      vector = this.forceVector;
    }

    Event.Emit(
      Event.APPLY_FORCE,
      {
        object : this,
        vector
      }
    );
    //CUSTOM_APPLY_FORCE_END

    //GENERATED_APPLY_FORCE_AFTER_START
    //GENERATED_APPLY_FORCE_AFTER_END

  }

  /**
   * applyTorque()
   * - Applies a force to the rigid body instance
   * @param {Object|null} [vector=null]
   * - No returns
   */
  applyTorque(vector = null) {

    //CUSTOM_APPLY_TORQUE_BEFORE_START
    //CUSTOM_APPLY_TORQUE_BEFORE_END

    //GENERATED_APPLY_TORQUE_BEFORE_START
    //GENERATED_APPLY_TORQUE_BEFORE_END

    //CUSTOM_APPLY_TORQUE_BEFORE_GENERATED_START
    //CUSTOM_APPLY_TORQUE_BEFORE_GENERATED_END

    //GENERATED_APPLY_TORQUE_START
    //GENERATED_APPLY_TORQUE_END

    //CUSTOM_APPLY_TORQUE_START
    if (!vector) {
      vector = this.torqueVector;
    }

    Event.Emit(
      Event.APPLY_TORQUE,
      {
        object : this,
        vector
      }
    );
    //CUSTOM_APPLY_TORQUE_END

    //GENERATED_APPLY_TORQUE_AFTER_START
    //GENERATED_APPLY_TORQUE_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  createInstance() {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  deleteInstance() {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

RigidBody.Type = 'R3EventObjComponentPhysicsRigidBody';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {RigidBody as default};
