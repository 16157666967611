//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
import * as r3Import from './r3/index.js';
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as R3} from './R3.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
const Utils = r3Import.Utils;
const System = r3Import.System;
const Runtime = r3Import.Runtime;
const Node = r3Import.Node;
const MockRapier = r3Import.MockRapier;
const Graph = r3Import.Graph;
const Event = r3Import.Event;
R3.Utils = Utils;
R3.System = System;
R3.Runtime = Runtime;
R3.Node = Node;
R3.MockRapier = MockRapier;
R3.Graph = Graph;
R3.Event = Event;
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
const Project = Event.Obj.Project;
const Group = Event.Obj.Group;
const User = Event.Obj.User;
const File = Event.Obj.File;
R3.Object = Event.Obj;
R3.Project = Project;
R3.Group = Group;
R3.User = User;
R3.File = File;
R3.Canvas = Event.Obj.Component.Graphics.Canvas;
R3.Code = Event.Obj.Component.Code;
R3.Input = Event.Obj.Component.Input;
R3.Component = Event.Obj.Component;
R3.OrbitControls = Event.Obj.Component.Input.OrbitControls;
R3.Entity = Event.Obj.Entity;
R3.Animation = Event.Obj.Entity.Animation;
R3.Rotation = Event.Obj.Entity.Animation.Rotation;
R3.Graphics = Event.Obj.Component.Graphics;
R3.Camera = Event.Obj.Component.Graphics.Camera;
R3.Geometry = Event.Obj.Component.Graphics.Geometry;
R3.Light = Event.Obj.Component.Graphics.Light;
R3.Material = Event.Obj.Component.Graphics.Material;
R3.Mesh = Event.Obj.Component.Graphics.Mesh;
R3.Renderer = Event.Obj.Component.Graphics.Renderer;
R3.Scene = Event.Obj.Component.Graphics.Scene;
R3.Texture = Event.Obj.Component.Graphics.Texture;
R3.Image = Event.Obj.Component.Graphics.Image;
R3.Face = Event.Obj.Component.Maths.Face;
R3.Color = Event.Obj.Component.Maths.Color;
R3.Vertex = Event.Obj.Component.Maths.Vertex;
R3.Vector2 = Event.Obj.Component.Maths.Vector2;
R3.Vector3 = Event.Obj.Component.Maths.Vector3;
R3.Vector4 = Event.Obj.Component.Maths.Vector4;
R3.Quaternion = Event.Obj.Component.Maths.Quaternion;
R3.Physics = Event.Obj.Component.Physics;
R3.Physics.Cuboid = Event.Obj.Component.Physics.Collider.Cuboid;
R3.Physics.Capsule = Event.Obj.Component.Physics.Collider.Capsule;
R3.Physics.Ball = Event.Obj.Component.Physics.Collider.Ball;
R3.Physics.HeightField = Event.Obj.Component.Physics.Collider.HeightField;
R3.Physics.TriMesh = Event.Obj.Component.Physics.Collider.TriMesh;
R3.System.Linking.Systems.add(R3.System);
R3.System.Linking.Systems.add(R3.System.Linking);
R3.System.Linking.Systems.add(R3.System.Websocket);
R3.System.Linking.Systems.add(R3.System.Storage);
R3.System.Linking.Systems.add(R3.System.Maths);
R3.System.Linking.Systems.add(R3.System.Input);
R3.System.Linking.Systems.add(R3.System.Gui);
R3.System.Linking.Systems.add(R3.System.Graphics);
R3.System.Linking.Systems.add(R3.System.Entity);
R3.System.Linking.Systems.add(R3.System.Code);
R3.System.Linking.Systems.add(R3.System.Authentication);
R3.System.Linking.Systems.add(R3.System.Physics);
R3.System.Linking.Systems.add(R3.System.Audio);
//CUSTOM_ASSIGN_TO_BASE_END

export const VersionString = `@r3js/lib-client 1.0.1 compiled 2024-07-09 18:11:23`;
export const Version = `1.0.1`;
export const CompileDate = `2024-07-09 18:11:23`;
console.log(VersionString);

//GENERATED_SYSTEM_START
//GENERATED_SYSTEM_END

//GENERATED_EXPORTS_START
export {
  R3,
  Utils,
  System,
  Runtime,
  Node,
  MockRapier,
  Graph,
  Event,
//CUSTOM_EXPORTS_START
  Project,
  Group,
  User,
  File
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END