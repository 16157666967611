//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as Spot} from './Spot.js';
import {default as Point} from './Point.js';
import {default as Directional} from './Directional.js';
import {default as Ambient} from './Ambient.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
//CUSTOM_ASSIGN_TO_BASE_END

//GENERATED_EXPORTS_START
export {
  Spot,
  Point,
  Directional,
  Ambient,
//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
