//GENERATED_IMPORTS_START
import {default as AudioComponent} from '../event/obj/component/Audio.js';
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START
import {default as ThreeRuntime} from '../runtime/audio/Three.js';
//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START
  RuntimeAudioThree = new ThreeRuntime() - Runtime implementation of R3RuntimeAudio
 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Playing = [] - A list of components that were playing at the time of stopping the Audio system
  Components = []
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.AFTER_ASSIGN_PROPERTY(20)
  Event.INITIALIZE_AUDIO_COMPONENT(50)
  Event.DISPOSE_AUDIO_COMPONENT(50)
  Event.PLAY_AUDIO(40)
  Event.PAUSE_AUDIO(40)
  Event.STOP_AUDIO(40)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START

 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  Event.GET_RUNTIME_AUDIO_THREE - Gets the Three runtime implementation of R3RuntimeAudio
 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options) - Starts the system by registering subscriptions to events
  stop(options) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Audio extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Audio already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Audio');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Audio already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Audio');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Audio.IsStarting) {
      console.log('client Audio system is already starting...');
      return;
    }

    Audio.IsStarting = true;

    if (Audio.Started === true) {
      Audio.IsStarting = false;
      console.log('client Audio system already started');
      return;
    }

    Audio.Runtimes = new Set();
    Audio.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Audio.Subscriptions['AFTER_ASSIGN_PROPERTY'] = Event.On(
      Event.AFTER_ASSIGN_PROPERTY,
      Audio.AfterAssignProperty,
      {priority: 20}
    );

    /**
     * No comment
     */
    Audio.Subscriptions['INITIALIZE_AUDIO_COMPONENT'] = Event.On(
      Event.INITIALIZE_AUDIO_COMPONENT,
      Audio.InitializeAudioComponent,
      {priority: 50}
    );

    /**
     * No comment
     */
    Audio.Subscriptions['DISPOSE_AUDIO_COMPONENT'] = Event.On(
      Event.DISPOSE_AUDIO_COMPONENT,
      Audio.DisposeAudioComponent,
      {priority: 50}
    );

    /**
     * No comment
     */
    Audio.Subscriptions['PLAY_AUDIO'] = Event.On(
      Event.PLAY_AUDIO,
      Audio.PlayAudio,
      {priority: 40}
    );

    /**
     * No comment
     */
    Audio.Subscriptions['PAUSE_AUDIO'] = Event.On(
      Event.PAUSE_AUDIO,
      Audio.PauseAudio,
      {priority: 40}
    );

    /**
     * No comment
     */
    Audio.Subscriptions['STOP_AUDIO'] = Event.On(
      Event.STOP_AUDIO,
      Audio.StopAudio,
      {priority: 40}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    /**
     * Gets the Three runtime implementation of R3RuntimeAudio
     */
    Audio.Subscriptions['GET_RUNTIME_AUDIO_THREE'] = Event.On(
      Event.GET_RUNTIME_AUDIO_THREE,
      Audio.GetRuntimeAudioThree
    );
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    for (let audio of Audio.Playing) {
      audio.play();
    }

    Audio.Playing = [];

    Audio.Started = true;
    Audio.IsStarting = false;
    console.log('Started client system Audio');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Audio.IsStopping) {
      console.log('client Audio system is already stopping');
      return;
    }

    Audio.IsStopping = true;

    if (Audio.Started === false) {
      Audio.IsStopping = false;
      console.log('client Audio system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    Audio.Subscriptions['AFTER_ASSIGN_PROPERTY'].remove();
    delete Audio.Subscriptions['AFTER_ASSIGN_PROPERTY'];

    Audio.Subscriptions['INITIALIZE_AUDIO_COMPONENT'].remove();
    delete Audio.Subscriptions['INITIALIZE_AUDIO_COMPONENT'];

    Audio.Subscriptions['DISPOSE_AUDIO_COMPONENT'].remove();
    delete Audio.Subscriptions['DISPOSE_AUDIO_COMPONENT'];

    Audio.Subscriptions['PLAY_AUDIO'].remove();
    delete Audio.Subscriptions['PLAY_AUDIO'];

    Audio.Subscriptions['PAUSE_AUDIO'].remove();
    delete Audio.Subscriptions['PAUSE_AUDIO'];

    Audio.Subscriptions['STOP_AUDIO'].remove();
    delete Audio.Subscriptions['STOP_AUDIO'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    Audio.Subscriptions['GET_RUNTIME_AUDIO_THREE'].remove();
    delete Audio.Subscriptions['GET_RUNTIME_AUDIO_THREE'];
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    for (let audio of Audio.Components) {
      for (let runtime of [...Audio.Runtimes]) {
        if (audio.isPlaying) {
          runtime.pause(audio);
          Audio.Playing.push(audio);
        }
      }
    }

    Audio.Runtimes.clear();
    Audio.Started = false;
    Audio.IsStopping = false;
    console.log('Stopped client system Audio');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START
    Audio.Runtimes.add(Audio.RuntimeAudioThree);
    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * AfterAssignProperty()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static AfterAssignProperty(event) {

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    let {object: component} = event;

    if (!(component instanceof AudioComponent)) {
      return;
    }

    let {property, value} = event;

    for (let runtime of [...Audio.Runtimes]) {

      let wasValid;
      let isValid;

      if (!runtime.validObjects.hasOwnProperty(component.id)) {
        wasValid = false;
        isValid = runtime.validate('createInstance', component);
      } else {
        wasValid = runtime.validObjects[component.id];
        isValid = runtime.validate("createInstance", component);
      }

      if (!wasValid && !isValid) {
        /**
         * Do nothing
         */
        return;
      }

      if (!wasValid && isValid) {
        System.CreateRuntimeInstance(component, runtime, AudioComponent);
        return;
      }

      if (wasValid && !isValid) {
        //CUSTOM_CREATE_OR_DELETE_INSTANCES_START
        /**
         * Audio are important - so we try to create something to visualize even though
         * it may not be perfect. This is maybe a bit less important for abstract Math components
         */
        let instance = component.getInstance(runtime);

        if (!instance) {
          System.CreateRuntimeChildrenInstances(component, runtime, AudioComponent);
        } else {
          System.DeleteRuntimeInstance(component, runtime, AudioComponent);
        }
        //CUSTOM_CREATE_OR_DELETE_INSTANCES_END
        return;
      }

      if (wasValid && isValid) {
        System.UpdateRuntimeInstance(component, runtime, AudioComponent, property, value);
      }
    }
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_END

  }

  /**
   * InitializeAudioComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeAudioComponent(event) {

    //GENERATED_STATIC_INITIALIZE_AUDIO_COMPONENT_START
    //GENERATED_STATIC_INITIALIZE_AUDIO_COMPONENT_END

    //CUSTOM_STATIC_INITIALIZE_AUDIO_COMPONENT_START
    let {object : component} = event;
    Audio.Components.push(component);
    //CUSTOM_STATIC_INITIALIZE_AUDIO_COMPONENT_END

    //GENERATED_STATIC_INITIALIZE_AUDIO_COMPONENT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_AUDIO_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_AUDIO_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_AUDIO_COMPONENT_AFTER_END

  }

  /**
   * DisposeAudioComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeAudioComponent(event) {

    //GENERATED_STATIC_DISPOSE_AUDIO_COMPONENT_START
    //GENERATED_STATIC_DISPOSE_AUDIO_COMPONENT_END

    //CUSTOM_STATIC_DISPOSE_AUDIO_COMPONENT_START
    let {object: component} = event;

    for (let runtime of [...Audio.Runtimes]) {
      System.DeleteRuntimeInstance(component, runtime, AudioComponent);
      delete runtime.validObjects[component.id];
    }

    Audio.Components = Audio.Components.filter((audio) => {return (audio !== component)});
    //CUSTOM_STATIC_DISPOSE_AUDIO_COMPONENT_END

    //GENERATED_STATIC_DISPOSE_AUDIO_COMPONENT_AFTER_START
    //GENERATED_STATIC_DISPOSE_AUDIO_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_AUDIO_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_AUDIO_COMPONENT_AFTER_END

  }

  /**
   * PlayAudio()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static PlayAudio(event) {

    //GENERATED_STATIC_PLAY_AUDIO_START
    //GENERATED_STATIC_PLAY_AUDIO_END

    //CUSTOM_STATIC_PLAY_AUDIO_START
    let {object : audio} = event;
    if (!(audio instanceof AudioComponent)) {
      return;
    }

    for (let runtime of [...Audio.Runtimes]) {

      let instance = audio.getInstance(runtime);

      if (!instance) {
        continue;
      }

      runtime.play(audio);
    }
    //CUSTOM_STATIC_PLAY_AUDIO_END

    //GENERATED_STATIC_PLAY_AUDIO_AFTER_START
    //GENERATED_STATIC_PLAY_AUDIO_AFTER_END

    //CUSTOM_STATIC_GENERATED_PLAY_AUDIO_AFTER_START
    //CUSTOM_STATIC_GENERATED_PLAY_AUDIO_AFTER_END

  }

  /**
   * PauseAudio()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static PauseAudio(event) {

    //GENERATED_STATIC_PAUSE_AUDIO_START
    //GENERATED_STATIC_PAUSE_AUDIO_END

    //CUSTOM_STATIC_PAUSE_AUDIO_START
    let {object : audio} = event;
    if (!(audio instanceof AudioComponent)) {
      return;
    }

    for (let runtime of [...Audio.Runtimes]) {

      let instance = audio.getInstance(runtime);

      if (!instance) {
        continue;
      }

      runtime.pause(audio);
    }
    //CUSTOM_STATIC_PAUSE_AUDIO_END

    //GENERATED_STATIC_PAUSE_AUDIO_AFTER_START
    //GENERATED_STATIC_PAUSE_AUDIO_AFTER_END

    //CUSTOM_STATIC_GENERATED_PAUSE_AUDIO_AFTER_START
    //CUSTOM_STATIC_GENERATED_PAUSE_AUDIO_AFTER_END

  }

  /**
   * StopAudio()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static StopAudio(event) {

    //GENERATED_STATIC_STOP_AUDIO_START
    //GENERATED_STATIC_STOP_AUDIO_END

    //CUSTOM_STATIC_STOP_AUDIO_START
    let {object : audio} = event;
    if (!(audio instanceof AudioComponent)) {
      return;
    }

    for (let runtime of [...Audio.Runtimes]) {

      let instance = audio.getInstance(runtime);

      if (!instance) {
        continue;
      }

      runtime.stop(audio);
    }
    //CUSTOM_STATIC_STOP_AUDIO_END

    //GENERATED_STATIC_STOP_AUDIO_AFTER_START
    //GENERATED_STATIC_STOP_AUDIO_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AUDIO_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AUDIO_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  /**
   * GetRuntimeAudioThree()
   * - Gets the Three runtime implementation of R3RuntimeAudio
   * @param {Object} event
   * - No returns
   */
  static GetRuntimeAudioThree(event) {

    //GENERATED_STATIC_GET_RUNTIME_AUDIO_THREE_START
    event.results = [Audio.RuntimeAudioThree];
    //GENERATED_STATIC_GET_RUNTIME_AUDIO_THREE_END

    //CUSTOM_STATIC_GET_RUNTIME_AUDIO_THREE_START
    //CUSTOM_STATIC_GET_RUNTIME_AUDIO_THREE_END

    //GENERATED_STATIC_GET_RUNTIME_AUDIO_THREE_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_AUDIO_THREE_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_AUDIO_THREE_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_AUDIO_THREE_AFTER_END

  }
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
/**
 * @param Audio.RuntimeAudioThree
 * - Runtime implementation of R3RuntimeAudio
 */
Audio.RuntimeAudioThree = new ThreeRuntime();
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Audio.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Audio.IsStarting = false;

/**
 * @param Audio.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Audio.IsStopping = false;

/**
 * @param Audio.Started
 * - Indicates whether this system is running
 */
Audio.Started = false;

/**
 * @param Audio.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Audio.Subscriptions = {};

/**
 * @param Audio.Runtimes
 * - A set of runtimes which the system manages
 */
Audio.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Audio.Playing
 * - A list of components that were playing at the time of stopping the Audio system
 */
Audio.Playing = [];

/**
 * @param Audio.Components
 * - No comment
 */
Audio.Components = [];
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Audio as default};
