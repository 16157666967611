//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import Utils from '../../Utils.js';
import * as THREE from 'three';
//CUSTOM_IMPORTS_END

import Entity from '../Entity.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Subscriptions = {}
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  activate(object) - Starts an entity or code component by subscribing to all events of the code components 
  deActivate(object) - Stops an entity or code component by unsubscribing to all events of the code components
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  activate(object) - Starts an entity or code component by subscribing to all events of the code components 
  deActivate(object) - Stops an entity or code component by unsubscribing to all events of the code components 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
  activateEntity(object) - Default implementation for [R3EventObjEntity] 
  deActivateEntity(object) - Default implementation for [R3EventObjEntity] 
  activateRotation(object) - Default implementation for [R3EventObjEntityAnimationRotation] 
  deActivateRotation(object) - Default implementation for [R3EventObjEntityAnimationRotation] 
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Default extends Entity {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeEntity';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeEntityDefault';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Default';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_ENTITY;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * activate()
   * - Starts an entity or code component by subscribing to all events of the code components
   * @param object
   * - No returns
   */
  activate(object) {

    //CUSTOM_ACTIVATE_BEFORE_START
    //CUSTOM_ACTIVATE_BEFORE_END

    //GENERATED_ACTIVATE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_ACTIVATE_START
    super.activate(object);
    //CUSTOM_RETURN_GENERATED_ACTIVATE_END
    //GENERATED_ACTIVATE_BEFORE_END

    //CUSTOM_ACTIVATE_BEFORE_GENERATED_START
    let activated = false;
    //CUSTOM_ACTIVATE_BEFORE_GENERATED_END

    //GENERATED_ACTIVATE_START
    if (
      object.type === 'R3EventObjEntity'
   || object.type === 'R3EventObjEntityAnimation'
   || object.type === 'R3EventObjEntityAnimationRotation'
    ) {
      //CUSTOM_RETURN_OPTION_ACTIVATE_ENTITY_START
      activated = this.activateEntity(object);
      //CUSTOM_RETURN_OPTION_ACTIVATE_ENTITY_END
    }

    if (
      object.type === 'R3EventObjEntityAnimationRotation'
    ) {
      //CUSTOM_RETURN_OPTION_ACTIVATE_ROTATION_START
      return this.activateRotation(object);
      //CUSTOM_RETURN_OPTION_ACTIVATE_ROTATION_END
    }
    //GENERATED_ACTIVATE_END

    //CUSTOM_ACTIVATE_START
    if (activated) {
      return activated;
    }
    //CUSTOM_ACTIVATE_END

    //GENERATED_ACTIVATE_AFTER_START
    throw new Error(`No suitable method found in activate() in runtime R3RuntimeEntityDefault`);
    //GENERATED_ACTIVATE_AFTER_END

  }

  /**
   * deActivate()
   * - Stops an entity or code component by unsubscribing to all events of the code components
   * @param object
   * - No returns
   */
  deActivate(object) {

    //CUSTOM_DE_ACTIVATE_BEFORE_START
    //CUSTOM_DE_ACTIVATE_BEFORE_END

    //GENERATED_DE_ACTIVATE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_DE_ACTIVATE_START
    super.deActivate(object);
    //CUSTOM_RETURN_GENERATED_DE_ACTIVATE_END
    //GENERATED_DE_ACTIVATE_BEFORE_END

    //CUSTOM_DE_ACTIVATE_BEFORE_GENERATED_START
    let deActivated = false;
    //CUSTOM_DE_ACTIVATE_BEFORE_GENERATED_END

    //GENERATED_DE_ACTIVATE_START
    if (
      object.type === 'R3EventObjEntity'
   || object.type === 'R3EventObjEntityAnimation'
   || object.type === 'R3EventObjEntityAnimationRotation'
    ) {
      //CUSTOM_RETURN_OPTION_DE_ACTIVATE_ENTITY_START
      deActivated = this.deActivateEntity(object);
      //CUSTOM_RETURN_OPTION_DE_ACTIVATE_ENTITY_END
    }

    if (
      object.type === 'R3EventObjEntityAnimationRotation'
    ) {
      //CUSTOM_RETURN_OPTION_DE_ACTIVATE_ROTATION_START
      return this.deActivateRotation(object);
      //CUSTOM_RETURN_OPTION_DE_ACTIVATE_ROTATION_END
    }
    //GENERATED_DE_ACTIVATE_END

    //CUSTOM_DE_ACTIVATE_START
    if (deActivated) {
      return null;
    }
    //CUSTOM_DE_ACTIVATE_END

    //GENERATED_DE_ACTIVATE_AFTER_START
    throw new Error(`No suitable method found in deActivate() in runtime R3RuntimeEntityDefault`);
    //GENERATED_DE_ACTIVATE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  /**
   * activateEntity()
   * - Default implementation for [R3EventObjEntity]
   * @param object
   * - No returns
   */
  activateEntity(object) {

    //GENERATED_ACTIVATE_ENTITY_BEFORE_START
    //GENERATED_ACTIVATE_ENTITY_BEFORE_END

    //GENERATED_ACTIVATE_ENTITY_START
    //GENERATED_ACTIVATE_ENTITY_END

    //CUSTOM_ACTIVATE_ENTITY_START
    let entity = object;

    let codeComponents = [];

    let instance = {};

    for (let component of entity.components) {
      instance[component.entityRef] = component;
      if (component.type.match('R3EventObjComponentCode')) {
        codeComponents.push(component);
      }
    }

    for (let codeComponent of codeComponents) {

      // if (!codeComponent.compiled) {
      try {
        codeComponent.compiledCode = new Function('event', 'THREE', codeComponent.code);
        codeComponent.compiled = true;
        Utils.Status(0, `Compilation success for ${codeComponent.name}`);
      } catch (error) {
        codeComponent.compiled = false;
        Utils.Status(-1, `Compilation failed for ${codeComponent.name}`);
      }
      // }

      function callback(entityInstance = null) {
        return function(event) {
          try {
            let {test, execute} = this.compiledCode.bind(entityInstance)(event, THREE);
            if (test()) {
              execute();
            }
          } catch (error) {
            Utils.Status(-1, error.message);
          }
        }.bind(codeComponent);
      }

      let subscription = codeComponent.on(
        codeComponent.event,
        callback(instance),
        {
          priority : 100,
          allowMultiple: true
        }
      );

      if (!Default.Subscriptions.hasOwnProperty(entity.id)) {
        Default.Subscriptions[entity.id] = [];
      }

      Default.Subscriptions[entity.id].push(subscription);
    }

    return instance;
    //CUSTOM_ACTIVATE_ENTITY_END

    //GENERATED_ACTIVATE_ENTITY_AFTER_START
    //GENERATED_ACTIVATE_ENTITY_AFTER_END

  }

  /**
   * deActivateEntity()
   * - Default implementation for [R3EventObjEntity]
   * @param object
   * - No returns
   */
  deActivateEntity(object) {

    //GENERATED_DE_ACTIVATE_ENTITY_BEFORE_START
    //GENERATED_DE_ACTIVATE_ENTITY_BEFORE_END

    //GENERATED_DE_ACTIVATE_ENTITY_START
    //GENERATED_DE_ACTIVATE_ENTITY_END

    //CUSTOM_DE_ACTIVATE_ENTITY_START
    let entity = object;

    if (!Default.Subscriptions.hasOwnProperty(entity.id)) {
      return true;
    }

    Default.Subscriptions[entity.id].map(
      (subscription) => {
        subscription.remove();
      }
    );

    delete Default.Subscriptions[entity.id];

    return true;
    //CUSTOM_DE_ACTIVATE_ENTITY_END

    //GENERATED_DE_ACTIVATE_ENTITY_AFTER_START
    //GENERATED_DE_ACTIVATE_ENTITY_AFTER_END

  }

  /**
   * activateRotation()
   * - Default implementation for [R3EventObjEntityAnimationRotation]
   * @param object
   * - No returns
   */
  activateRotation(object) {

    //GENERATED_ACTIVATE_ROTATION_BEFORE_START
    //GENERATED_ACTIVATE_ROTATION_BEFORE_END

    //GENERATED_ACTIVATE_ROTATION_START
    //GENERATED_ACTIVATE_ROTATION_END

    //CUSTOM_ACTIVATE_ROTATION_START
    let entity = object;

    for (let [property, reference] of Object.entries(entity.references)) {

      let {type} = reference;

      let isArray = false;

      if (type instanceof Array) {
        type = type[0];
        isArray = true;
      }

      if (!type.match('R3EventObjComponentCode')) {
        continue;
      }

      let codeComponent = entity[property];

      function callback(entityInstance = null) {
        return function(event) {
          try {
            let {test, execute} = this.compiledCode.bind(entityInstance)(event);
            if (test()) {
              execute();
            }
          } catch (error) {
            Utils.Status(-1, error.message);
          }
        }.bind(codeComponent);
      }

      let instance = entity.getInstance(this);

      let subscription = codeComponent.on(
        codeComponent.event,
        callback(instance),
        {
          priority : 100,
          allowMultiple: true
        }
      );

      if (!Default.Subscriptions.hasOwnProperty(entity.id)) {
        Default.Subscriptions[entity.id] = [];
      }

      Default.Subscriptions[entity.id].push(subscription);
    }
    //CUSTOM_ACTIVATE_ROTATION_END

    //GENERATED_ACTIVATE_ROTATION_AFTER_START
    //GENERATED_ACTIVATE_ROTATION_AFTER_END

  }

  /**
   * deActivateRotation()
   * - Default implementation for [R3EventObjEntityAnimationRotation]
   * @param object
   * - No returns
   */
  deActivateRotation(object) {

    //GENERATED_DE_ACTIVATE_ROTATION_BEFORE_START
    //GENERATED_DE_ACTIVATE_ROTATION_BEFORE_END

    //GENERATED_DE_ACTIVATE_ROTATION_START
    //GENERATED_DE_ACTIVATE_ROTATION_END

    //CUSTOM_DE_ACTIVATE_ROTATION_START
    let entity = object;

    if (!Default.Subscriptions.hasOwnProperty(entity.id)) {
      console.warn(`The rotation entity ${entity.uniqueName} (${entity.id}) has no subscriptions`);
      return null;
    }

    Default.Subscriptions[entity.id].map(
      (subscription) => {
        subscription.remove();
      }
    );

    delete Default.Subscriptions[entity.id];

    return null;
    //CUSTOM_DE_ACTIVATE_ROTATION_END

    //GENERATED_DE_ACTIVATE_ROTATION_AFTER_START
    //GENERATED_DE_ACTIVATE_ROTATION_AFTER_END

  }

  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Default.Type = 'R3RuntimeEntityDefault';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Default.Subscriptions
 * - No comment
 */
Default.Subscriptions = {};
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Default as default};
