//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import axios from 'axios';
axios.defaults.withCredentials = true;
// const {DEBUG_MODE} = process.env;
import Event from '../../Event.js';
import Utils from '../../Utils.js';
//CUSTOM_IMPORTS_END

import Storage from '../Storage.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  currentUser=null
  apiUrl=null
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
  async getUsersList(offset, count) - Returns a list of count users starting from offset 
  async getList(type, offset, count, keywords = null) - Returns a list of count objects starting from offset 
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  async clone(object) - Loads the R3 Object from Storage using apiUrl if specified, but during construction new IDs are assigned to all objects. When deep is true it also loads all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
  async load(object, children=false) - Loads the R3 Object from Storage. When children is true it also includes all children of this Object. Loads as current user or throws. 
  async remove(object) - Removes the R3 Object from Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
  async save(object) - Saves the R3 Object to Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
  async deleteAtUrl(object) - Deletes the object as user at url, specify deep as true to delete all children which are dependent on this object
  async loadFromUrl(object, type, method = 'get') - Loads the type from the URL
  async saveToUrl(object, type) - Saves the object as user to the URL
  throwWithMessage(error) - Attempts to find a message inside the error object and throws a new error with this message
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  async clone(object) - Loads the R3 Object from Storage using apiUrl if specified, but during construction new IDs are assigned to all objects. When deep is true it also loads all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
  async load(object, children=false) - Loads the R3 Object from Storage. When children is true it also includes all children of this Object. Loads as current user or throws. 
  async remove(object) - Removes the R3 Object from Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
  async save(object) - Saves the R3 Object to Storage using apiUrl if specified, when deep is true it includes all children. When user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this operation as that user. 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
  async cloneComponent(object) - Axios implementation for [R3EventObjComponent] 
  async loadComponent(object, children=false) - Axios implementation for [R3EventObjComponent] 
  async removeComponent(object) - Axios implementation for [R3EventObjComponent] 
  async saveComponent(object) - Axios implementation for [R3EventObjComponent] 
  async cloneEntity(object) - Axios implementation for [R3EventObjEntity] 
  async loadEntity(object, children=false) - Axios implementation for [R3EventObjEntity] 
  async removeEntity(object) - Axios implementation for [R3EventObjEntity] 
  async saveEntity(object) - Axios implementation for [R3EventObjEntity] 
  async cloneFile(object) - Axios implementation for [R3EventObjFile] 
  async loadFile(object, children=false) - Axios implementation for [R3EventObjFile] 
  async removeFile(object) - Axios implementation for [R3EventObjFile] 
  async saveFile(object) - Axios implementation for [R3EventObjFile] 
  async cloneGroup(object) - Axios implementation for [R3EventObjGroup] 
  async loadGroup(object, children=false) - Axios implementation for [R3EventObjGroup] 
  async removeGroup(object) - Axios implementation for [R3EventObjGroup] 
  async saveGroup(object) - Axios implementation for [R3EventObjGroup] 
  async cloneProject(object) - Axios implementation for [R3EventObjProject] 
  async loadProject(object, children=false) - Axios implementation for [R3EventObjProject] 
  async removeProject(object) - Axios implementation for [R3EventObjProject] 
  async saveProject(object) - Axios implementation for [R3EventObjProject] 
  async cloneUser(object) - Axios implementation for [R3EventObjUser] 
  async loadUser(object, children=false) - Axios implementation for [R3EventObjUser] 
  async removeUser(object) - Axios implementation for [R3EventObjUser] 
  async saveUser(object) - Axios implementation for [R3EventObjUser] 
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Axios extends Storage {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeStorage';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeStorageAxios';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'AxiosStorage';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_STORAGE;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param currentUser
     * - No comment
     */
    if (typeof options.currentUser === 'undefined') {
      options.currentUser = null;
    }

    /**
     * @param apiUrl
     * - No comment
     */
    if (typeof options.apiUrl === 'undefined') {
      options.apiUrl = null;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    Event.On(
      Event.SET_API_URL,
      (event) => {
        this.apiUrl = event.object;
        console.log(`Axios Storage URL set to ${this.apiUrl}`);
      },
      {
        priority : 21
      }
    );

    Event.On(
      Event.SET_CURRENT_USER,
      (event) => {
        this.currentUser = event.object;
        console.log(`Axios user set to ${this.currentUser.username}`);
      },
      {
        priority : 21
      }
    );
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  /**
   * getUsersList()
   * - Returns a list of count users starting from offset
   * @param offset
   * @param count
   * - No returns
   */
  async getUsersList(offset, count) {

    //CUSTOM_GET_USERS_LIST_BEFORE_START
    //CUSTOM_GET_USERS_LIST_BEFORE_END

    //GENERATED_GET_USERS_LIST_BEFORE_START
    //GENERATED_GET_USERS_LIST_BEFORE_END

    //CUSTOM_GET_USERS_LIST_BEFORE_GENERATED_START
    if (!this.apiUrl) {
      throw new Error(`No API URL set`);
    }

    let result;

    try {

      let options = {
        url : `${this.apiUrl}/users/${offset}/${count}`,
        method : 'get'
      }

      result = await axios(
        options
      );

    } catch (error) {
      this.throwWithMessage(error);
    }

    if (!result.data) {
      throw new Error(`Could not interpret load result : ${result}`);
    }

    if (result.data.code !== 0) {
      throw new Error(result.data.message);
    }

    if (result.data.errors && result.data.errors.length) {
      for (let error of result.data.errors) {
        Utils.Status(-1, error);
      }
    }

    return result.data.instance;
    //CUSTOM_GET_USERS_LIST_BEFORE_GENERATED_END

    //GENERATED_GET_USERS_LIST_START
    //GENERATED_GET_USERS_LIST_END

    //CUSTOM_GET_USERS_LIST_START
    //CUSTOM_GET_USERS_LIST_END

    //GENERATED_GET_USERS_LIST_AFTER_START
    //GENERATED_GET_USERS_LIST_AFTER_END

  }

  /**
   * getList()
   * - Returns a list of count objects starting from offset
   * @param type
   * @param offset
   * @param count
   * @param {Object|null} [keywords=null]
   * - No returns
   */
  async getList(type, offset, count, keywords = null) {

    //CUSTOM_GET_LIST_BEFORE_START
    //CUSTOM_GET_LIST_BEFORE_END

    //GENERATED_GET_LIST_BEFORE_START
    //GENERATED_GET_LIST_BEFORE_END

    //CUSTOM_GET_LIST_BEFORE_GENERATED_START
    //CUSTOM_GET_LIST_BEFORE_GENERATED_END

    //GENERATED_GET_LIST_START
    //GENERATED_GET_LIST_END

    //CUSTOM_GET_LIST_START
    if (!this.apiUrl) {
      throw new Error(`No API URL set`);
    }

    let result;

    try {

      let options = {
        url : `${this.apiUrl}/list/${type}/${offset}/${count}/${keywords?encodeURIComponent(keywords):'__none__'}`,
        method : 'get'
      }

      // if (DEBUG_MODE === 'true') {
      //   options.headers = {'Cookie': `authToken=${process.env.AUTH_TOKEN}`};
      // }

      result = await axios(
        options
      );

    } catch (error) {
      this.throwWithMessage(error);
    }

    if (!result.data) {
      throw new Error(`Could not interpret load result : ${result}`);
    }

    if (result.data.code !== 0) {
      throw new Error(result.data.message);
    }

    if (result.data.errors && result.data.errors.length) {
      for (let error of result.data.errors) {
        Utils.Status(-1, error);
      }
    }

    return result.data.instances;
    //CUSTOM_GET_LIST_END

    //GENERATED_GET_LIST_AFTER_START
    //GENERATED_GET_LIST_AFTER_END

  }

  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * deleteAtUrl()
   * - Deletes the object as user at url, specify deep as true to delete all children which are dependent on this object
   * @param object
   * - No returns
   */
  async deleteAtUrl(object) {

    //CUSTOM_DELETE_AT_URL_BEFORE_START
    //CUSTOM_DELETE_AT_URL_BEFORE_END

    //GENERATED_DELETE_AT_URL_BEFORE_START
    //GENERATED_DELETE_AT_URL_BEFORE_END

    //CUSTOM_DELETE_AT_URL_BEFORE_GENERATED_START
    //CUSTOM_DELETE_AT_URL_BEFORE_GENERATED_END

    //GENERATED_DELETE_AT_URL_START
    //GENERATED_DELETE_AT_URL_END

    //CUSTOM_DELETE_AT_URL_START
    if (!this.apiUrl) {
      throw new Error(`No API URL set at time of delete for ${object.uniqueName}`);
    }

    if (!this.currentUser) {
      throw new Error(`No current user set at time of delete for ${object.uniqueName}`);
    }

    let result;

    let {id, type} = object;
    let objectType = null;

    if (type.match('R3EventObjUser')) {
      objectType = 'user';
    }

    if (type.match('R3EventObjProject')) {
      objectType = 'project';
    }

    if (type.match('R3EventObjComponent')) {
      objectType = 'component';
    }

    if (type.match('R3EventObjEntity')) {
      objectType = 'entity';
    }

    if (type.match('R3EventObjGroup')) {
      objectType = 'group';
    }

    try {

      let options = {
        data: {
          classId : type
        }
      }

      // if (DEBUG_MODE === 'true') {
      //   options.headers = {'Cookie': `authToken=${process.env.AUTH_TOKEN}`};
      // }

      result = await axios.delete(
        `${this.apiUrl}/remove/${objectType}/${id}`,
        options
      );

    } catch (error) {
      this.throwWithMessage(error);
    }

    if (!result.data) {
      throw new Error(`Could not interpret delete result : ${result}`);
    }

    if (result.data.code !== 0) {
      throw new Error(result.data.message);
    }

    return result.data.instance;
    //CUSTOM_DELETE_AT_URL_END

    //GENERATED_DELETE_AT_URL_AFTER_START
    //GENERATED_DELETE_AT_URL_AFTER_END

  }

  /**
   * loadFromUrl()
   * - Loads the type from the URL
   * @param object
   * @param type
   * @param {string} [method='get']
   * - No returns
   */
  async loadFromUrl(object, type, method = 'get') {

    //CUSTOM_LOAD_FROM_URL_BEFORE_START
    //CUSTOM_LOAD_FROM_URL_BEFORE_END

    //GENERATED_LOAD_FROM_URL_BEFORE_START
    //GENERATED_LOAD_FROM_URL_BEFORE_END

    //CUSTOM_LOAD_FROM_URL_BEFORE_GENERATED_START
    //CUSTOM_LOAD_FROM_URL_BEFORE_GENERATED_END

    //GENERATED_LOAD_FROM_URL_START
    //GENERATED_LOAD_FROM_URL_END

    //CUSTOM_LOAD_FROM_URL_START
    if (!this.apiUrl) {
      throw new Error(`No API URL set at time of load for ${object.uniqueName}`);
    }

    let result;

    
    try {

      let options = {
        url : `${this.apiUrl}/load/${type}/${object.id}`,
        method,
        validateStatus: (status) => {
          return status >= 200 && status < 500
        }
      }

      if (method === 'post') {
        options.data = {
          classId : object.type
        }
      }

      // if (DEBUG_MODE === 'true') {
      //   options.headers = {
      //     'Cookie' : `authToken=${process.env.AUTH_TOKEN}`
      //   }
      // }

      result = await axios(
        options
      );

    } catch (error) {
      this.throwWithMessage(error);
    }

    if (!result.data) {
      throw new Error(`Could not interpret load result : ${result}`);
    }

    if (result.data.code !== 0) {
      throw new Error(result.data.message);
    }

    if (result.data.errors && result.data.errors.length) {
      for (let error of result.data.errors) {
        Utils.Status(-1, error);
      }
    }

    return result.data.instance;
    //CUSTOM_LOAD_FROM_URL_END

    //GENERATED_LOAD_FROM_URL_AFTER_START
    //GENERATED_LOAD_FROM_URL_AFTER_END

  }

  /**
   * saveToUrl()
   * - Saves the object as user to the URL
   * @param object
   * @param type
   * - No returns
   */
  async saveToUrl(object, type) {

    //CUSTOM_SAVE_TO_URL_BEFORE_START
    //CUSTOM_SAVE_TO_URL_BEFORE_END

    //GENERATED_SAVE_TO_URL_BEFORE_START
    //GENERATED_SAVE_TO_URL_BEFORE_END

    //CUSTOM_SAVE_TO_URL_BEFORE_GENERATED_START
    //CUSTOM_SAVE_TO_URL_BEFORE_GENERATED_END

    //GENERATED_SAVE_TO_URL_START
    //GENERATED_SAVE_TO_URL_END

    //CUSTOM_SAVE_TO_URL_START
    if (!this.apiUrl) {
      throw new Error(`No API URL set at time of save for ${object.uniqueName}`);
    }

    if (!this.currentUser) {
      throw new Error(`No current user set at time of save for ${object.uniqueName}`);
    }

    let result;

    try {

      let data = object.toApiObject();

      if (type === 'file') {
        type = `file/${object.fileType}`;
        data = object.body;
      }

      let options;

      // if (DEBUG_MODE === 'true') {
      //   options = {
      //     headers: {'Cookie': `authToken=${process.env.AUTH_TOKEN}`}
      //   }
      // }

      result = await axios.post(
        `${this.apiUrl}/save/${type}`,
        data,
        options
      );

    } catch (error) {
      this.throwWithMessage(error);
    }

    if (!result.data) {
      throw new Error(`Could not interpret save result : ${result}`);
    }

    if (result.data.code !== 0) {
      throw new Error(result.data.message);
    }

    return result.data.instance;
    //CUSTOM_SAVE_TO_URL_END

    //GENERATED_SAVE_TO_URL_AFTER_START
    //GENERATED_SAVE_TO_URL_AFTER_END

  }

  /**
   * throwWithMessage()
   * - Attempts to find a message inside the error object and throws a new error with this message
   * @param error
   * - No returns
   */
  throwWithMessage(error) {

    //CUSTOM_THROW_WITH_MESSAGE_BEFORE_START
    //CUSTOM_THROW_WITH_MESSAGE_BEFORE_END

    //GENERATED_THROW_WITH_MESSAGE_BEFORE_START
    //GENERATED_THROW_WITH_MESSAGE_BEFORE_END

    //CUSTOM_THROW_WITH_MESSAGE_BEFORE_GENERATED_START
    //CUSTOM_THROW_WITH_MESSAGE_BEFORE_GENERATED_END

    //GENERATED_THROW_WITH_MESSAGE_START
    let message = error.message;

    if (error.response && error.response.data && error.response.data.message) {
      message = error.response.data.message
    }

    throw new Error(
      message
    );
    //GENERATED_THROW_WITH_MESSAGE_END

    //CUSTOM_THROW_WITH_MESSAGE_START
    //CUSTOM_THROW_WITH_MESSAGE_END

    //GENERATED_THROW_WITH_MESSAGE_AFTER_START
    //GENERATED_THROW_WITH_MESSAGE_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * clone()
   * - Loads the R3 Object from Storage using apiUrl if specified, but during construction new IDs are assigned to all
   *   objects. When deep is true it also loads all children. When user is not specified it will attempt to discover
   *   the current user, or specify a user explicitly to perform this operation as that user.
   * @param object
   * - No returns
   */
  async clone(object) {

    //CUSTOM_CLONE_BEFORE_START
    //CUSTOM_CLONE_BEFORE_END

    //GENERATED_CLONE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_CLONE_START
    await super.clone(object);
    //CUSTOM_RETURN_GENERATED_CLONE_END
    //GENERATED_CLONE_BEFORE_END

    //CUSTOM_CLONE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_BEFORE_GENERATED_END

    //GENERATED_CLONE_START
    if (
      object.type === 'R3EventObjComponent'
   || object.type === 'R3EventObjComponentPhysics'
   || object.type === 'R3EventObjComponentPhysicsWorld'
   || object.type === 'R3EventObjComponentPhysicsRigidBody'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyFixed'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyDynamic'
   || object.type === 'R3EventObjComponentPhysicsJoint'
   || object.type === 'R3EventObjComponentPhysicsJointSpherical'
   || object.type === 'R3EventObjComponentPhysicsJointRevolute'
   || object.type === 'R3EventObjComponentPhysicsJointPrismatic'
   || object.type === 'R3EventObjComponentPhysicsJointFixed'
   || object.type === 'R3EventObjComponentPhysicsGravity'
   || object.type === 'R3EventObjComponentPhysicsCollider'
   || object.type === 'R3EventObjComponentPhysicsColliderTriMesh'
   || object.type === 'R3EventObjComponentPhysicsColliderHeightField'
   || object.type === 'R3EventObjComponentPhysicsColliderCuboid'
   || object.type === 'R3EventObjComponentPhysicsColliderCapsule'
   || object.type === 'R3EventObjComponentPhysicsColliderBall'
   || object.type === 'R3EventObjComponentMaths'
   || object.type === 'R3EventObjComponentMathsVertex'
   || object.type === 'R3EventObjComponentMathsVector4'
   || object.type === 'R3EventObjComponentMathsVector3'
   || object.type === 'R3EventObjComponentMathsVector2'
   || object.type === 'R3EventObjComponentMathsUV'
   || object.type === 'R3EventObjComponentMathsSphere'
   || object.type === 'R3EventObjComponentMathsRay'
   || object.type === 'R3EventObjComponentMathsQuaternion'
   || object.type === 'R3EventObjComponentMathsPlane'
   || object.type === 'R3EventObjComponentMathsMatrix4'
   || object.type === 'R3EventObjComponentMathsMatrix3'
   || object.type === 'R3EventObjComponentMathsFace'
   || object.type === 'R3EventObjComponentMathsColor'
   || object.type === 'R3EventObjComponentInput'
   || object.type === 'R3EventObjComponentInputTouch'
   || object.type === 'R3EventObjComponentInputOrbitControls'
   || object.type === 'R3EventObjComponentInputMouse'
   || object.type === 'R3EventObjComponentInputKeyboard'
   || object.type === 'R3EventObjComponentGraphics'
   || object.type === 'R3EventObjComponentGraphicsVideo'
   || object.type === 'R3EventObjComponentGraphicsTexture'
   || object.type === 'R3EventObjComponentGraphicsTextureVideo'
   || object.type === 'R3EventObjComponentGraphicsTextureImage'
   || object.type === 'R3EventObjComponentGraphicsTextureCube'
   || object.type === 'R3EventObjComponentGraphicsTextureCanvas'
   || object.type === 'R3EventObjComponentGraphicsSprite'
   || object.type === 'R3EventObjComponentGraphicsSkeleton'
   || object.type === 'R3EventObjComponentGraphicsShadow'
   || object.type === 'R3EventObjComponentGraphicsShadowSpot'
   || object.type === 'R3EventObjComponentGraphicsShadowPoint'
   || object.type === 'R3EventObjComponentGraphicsShadowDirectional'
   || object.type === 'R3EventObjComponentGraphicsShader'
   || object.type === 'R3EventObjComponentGraphicsScene'
   || object.type === 'R3EventObjComponentGraphicsRenderer'
   || object.type === 'R3EventObjComponentGraphicsRendererTarget'
   || object.type === 'R3EventObjComponentGraphicsRendererGl'
   || object.type === 'R3EventObjComponentGraphicsRendererCube'
   || object.type === 'R3EventObjComponentGraphicsRaycaster'
   || object.type === 'R3EventObjComponentGraphicsMesh'
   || object.type === 'R3EventObjComponentGraphicsMaterialGroup'
   || object.type === 'R3EventObjComponentGraphicsMaterial'
   || object.type === 'R3EventObjComponentGraphicsMaterialToon'
   || object.type === 'R3EventObjComponentGraphicsMaterialStandard'
   || object.type === 'R3EventObjComponentGraphicsMaterialPoints'
   || object.type === 'R3EventObjComponentGraphicsMaterialPhong'
   || object.type === 'R3EventObjComponentGraphicsMaterialLambert'
   || object.type === 'R3EventObjComponentGraphicsMaterialBasic'
   || object.type === 'R3EventObjComponentGraphicsLight'
   || object.type === 'R3EventObjComponentGraphicsLightSpot'
   || object.type === 'R3EventObjComponentGraphicsLightPoint'
   || object.type === 'R3EventObjComponentGraphicsLightDirectional'
   || object.type === 'R3EventObjComponentGraphicsLightAmbient'
   || object.type === 'R3EventObjComponentGraphicsImage'
   || object.type === 'R3EventObjComponentGraphicsGeometry'
   || object.type === 'R3EventObjComponentGraphicsGeometryTorus'
   || object.type === 'R3EventObjComponentGraphicsGeometrySphere'
   || object.type === 'R3EventObjComponentGraphicsGeometryPlane'
   || object.type === 'R3EventObjComponentGraphicsGeometryBuffer'
   || object.type === 'R3EventObjComponentGraphicsGeometryBox'
   || object.type === 'R3EventObjComponentGraphicsFog'
   || object.type === 'R3EventObjComponentGraphicsCanvas'
   || object.type === 'R3EventObjComponentGraphicsCamera'
   || object.type === 'R3EventObjComponentGraphicsCameraPerspective'
   || object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
   || object.type === 'R3EventObjComponentGraphicsCameraCube'
   || object.type === 'R3EventObjComponentCode'
   || object.type === 'R3EventObjComponentAudio'
   || object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_COMPONENT_START
      await this.cloneComponent(object);
      //CUSTOM_RETURN_OPTION_CLONE_COMPONENT_END
    }

    if (
      object.type === 'R3EventObjEntity'
   || object.type === 'R3EventObjEntityAnimation'
   || object.type === 'R3EventObjEntityAnimationRotation'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_ENTITY_START
      await this.cloneEntity(object);
      //CUSTOM_RETURN_OPTION_CLONE_ENTITY_END
    }

    if (
      object.type === 'R3EventObjFile'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_FILE_START
      return await this.cloneFile(object);
      //CUSTOM_RETURN_OPTION_CLONE_FILE_END
    }

    if (
      object.type === 'R3EventObjGroup'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_GROUP_START
      return await this.cloneGroup(object);
      //CUSTOM_RETURN_OPTION_CLONE_GROUP_END
    }

    if (
      object.type === 'R3EventObjProject'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_PROJECT_START
      return await this.cloneProject(object);
      //CUSTOM_RETURN_OPTION_CLONE_PROJECT_END
    }

    if (
      object.type === 'R3EventObjUser'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_USER_START
      return await this.cloneUser(object);
      //CUSTOM_RETURN_OPTION_CLONE_USER_END
    }
    //GENERATED_CLONE_END

    //CUSTOM_CLONE_START
    //CUSTOM_CLONE_END

    //GENERATED_CLONE_AFTER_START
    throw new Error(`No suitable method found in clone() in runtime R3RuntimeStorageAxios`);
    //GENERATED_CLONE_AFTER_END

  }

  /**
   * load()
   * - Loads the R3 Object from Storage. When children is true it also includes all children of this Object. Loads as
   *   current user or throws.
   * @param object
   * @param {Boolean} [children=false]
   * - No returns
   */
  async load(object, children=false) {

    //CUSTOM_LOAD_BEFORE_START
    //CUSTOM_LOAD_BEFORE_END

    //GENERATED_LOAD_BEFORE_START
    //CUSTOM_RETURN_GENERATED_LOAD_START
    await super.load(object, children);
    //CUSTOM_RETURN_GENERATED_LOAD_END
    //GENERATED_LOAD_BEFORE_END

    //CUSTOM_LOAD_BEFORE_GENERATED_START
    //CUSTOM_LOAD_BEFORE_GENERATED_END

    //GENERATED_LOAD_START
    if (
      object.type === 'R3EventObjComponent'
   || object.type === 'R3EventObjComponentPhysics'
   || object.type === 'R3EventObjComponentPhysicsWorld'
   || object.type === 'R3EventObjComponentPhysicsRigidBody'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyFixed'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyDynamic'
   || object.type === 'R3EventObjComponentPhysicsJoint'
   || object.type === 'R3EventObjComponentPhysicsJointSpherical'
   || object.type === 'R3EventObjComponentPhysicsJointRevolute'
   || object.type === 'R3EventObjComponentPhysicsJointPrismatic'
   || object.type === 'R3EventObjComponentPhysicsJointFixed'
   || object.type === 'R3EventObjComponentPhysicsGravity'
   || object.type === 'R3EventObjComponentPhysicsCollider'
   || object.type === 'R3EventObjComponentPhysicsColliderTriMesh'
   || object.type === 'R3EventObjComponentPhysicsColliderHeightField'
   || object.type === 'R3EventObjComponentPhysicsColliderCuboid'
   || object.type === 'R3EventObjComponentPhysicsColliderCapsule'
   || object.type === 'R3EventObjComponentPhysicsColliderBall'
   || object.type === 'R3EventObjComponentMaths'
   || object.type === 'R3EventObjComponentMathsVertex'
   || object.type === 'R3EventObjComponentMathsVector4'
   || object.type === 'R3EventObjComponentMathsVector3'
   || object.type === 'R3EventObjComponentMathsVector2'
   || object.type === 'R3EventObjComponentMathsUV'
   || object.type === 'R3EventObjComponentMathsSphere'
   || object.type === 'R3EventObjComponentMathsRay'
   || object.type === 'R3EventObjComponentMathsQuaternion'
   || object.type === 'R3EventObjComponentMathsPlane'
   || object.type === 'R3EventObjComponentMathsMatrix4'
   || object.type === 'R3EventObjComponentMathsMatrix3'
   || object.type === 'R3EventObjComponentMathsFace'
   || object.type === 'R3EventObjComponentMathsColor'
   || object.type === 'R3EventObjComponentInput'
   || object.type === 'R3EventObjComponentInputTouch'
   || object.type === 'R3EventObjComponentInputOrbitControls'
   || object.type === 'R3EventObjComponentInputMouse'
   || object.type === 'R3EventObjComponentInputKeyboard'
   || object.type === 'R3EventObjComponentGraphics'
   || object.type === 'R3EventObjComponentGraphicsVideo'
   || object.type === 'R3EventObjComponentGraphicsTexture'
   || object.type === 'R3EventObjComponentGraphicsTextureVideo'
   || object.type === 'R3EventObjComponentGraphicsTextureImage'
   || object.type === 'R3EventObjComponentGraphicsTextureCube'
   || object.type === 'R3EventObjComponentGraphicsTextureCanvas'
   || object.type === 'R3EventObjComponentGraphicsSprite'
   || object.type === 'R3EventObjComponentGraphicsSkeleton'
   || object.type === 'R3EventObjComponentGraphicsShadow'
   || object.type === 'R3EventObjComponentGraphicsShadowSpot'
   || object.type === 'R3EventObjComponentGraphicsShadowPoint'
   || object.type === 'R3EventObjComponentGraphicsShadowDirectional'
   || object.type === 'R3EventObjComponentGraphicsShader'
   || object.type === 'R3EventObjComponentGraphicsScene'
   || object.type === 'R3EventObjComponentGraphicsRenderer'
   || object.type === 'R3EventObjComponentGraphicsRendererTarget'
   || object.type === 'R3EventObjComponentGraphicsRendererGl'
   || object.type === 'R3EventObjComponentGraphicsRendererCube'
   || object.type === 'R3EventObjComponentGraphicsRaycaster'
   || object.type === 'R3EventObjComponentGraphicsMesh'
   || object.type === 'R3EventObjComponentGraphicsMaterialGroup'
   || object.type === 'R3EventObjComponentGraphicsMaterial'
   || object.type === 'R3EventObjComponentGraphicsMaterialToon'
   || object.type === 'R3EventObjComponentGraphicsMaterialStandard'
   || object.type === 'R3EventObjComponentGraphicsMaterialPoints'
   || object.type === 'R3EventObjComponentGraphicsMaterialPhong'
   || object.type === 'R3EventObjComponentGraphicsMaterialLambert'
   || object.type === 'R3EventObjComponentGraphicsMaterialBasic'
   || object.type === 'R3EventObjComponentGraphicsLight'
   || object.type === 'R3EventObjComponentGraphicsLightSpot'
   || object.type === 'R3EventObjComponentGraphicsLightPoint'
   || object.type === 'R3EventObjComponentGraphicsLightDirectional'
   || object.type === 'R3EventObjComponentGraphicsLightAmbient'
   || object.type === 'R3EventObjComponentGraphicsImage'
   || object.type === 'R3EventObjComponentGraphicsGeometry'
   || object.type === 'R3EventObjComponentGraphicsGeometryTorus'
   || object.type === 'R3EventObjComponentGraphicsGeometrySphere'
   || object.type === 'R3EventObjComponentGraphicsGeometryPlane'
   || object.type === 'R3EventObjComponentGraphicsGeometryBuffer'
   || object.type === 'R3EventObjComponentGraphicsGeometryBox'
   || object.type === 'R3EventObjComponentGraphicsFog'
   || object.type === 'R3EventObjComponentGraphicsCanvas'
   || object.type === 'R3EventObjComponentGraphicsCamera'
   || object.type === 'R3EventObjComponentGraphicsCameraPerspective'
   || object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
   || object.type === 'R3EventObjComponentGraphicsCameraCube'
   || object.type === 'R3EventObjComponentCode'
   || object.type === 'R3EventObjComponentAudio'
   || object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_LOAD_COMPONENT_START
      return await this.loadComponent(object);
      //CUSTOM_RETURN_OPTION_LOAD_COMPONENT_END
    }

    if (
      object.type === 'R3EventObjEntity'
   || object.type === 'R3EventObjEntityAnimation'
   || object.type === 'R3EventObjEntityAnimationRotation'
    ) {
      //CUSTOM_RETURN_OPTION_LOAD_ENTITY_START
      return await this.loadEntity(object);
      //CUSTOM_RETURN_OPTION_LOAD_ENTITY_END
    }

    if (
      object.type === 'R3EventObjFile'
    ) {
      //CUSTOM_RETURN_OPTION_LOAD_FILE_START
      return await this.loadFile(object, children);
      //CUSTOM_RETURN_OPTION_LOAD_FILE_END
    }

    if (
      object.type === 'R3EventObjGroup'
    ) {
      //CUSTOM_RETURN_OPTION_LOAD_GROUP_START
      return await this.loadGroup(object);
      //CUSTOM_RETURN_OPTION_LOAD_GROUP_END
    }

    if (
      object.type === 'R3EventObjProject'
    ) {
      //CUSTOM_RETURN_OPTION_LOAD_PROJECT_START
      return await this.loadProject(object);
      //CUSTOM_RETURN_OPTION_LOAD_PROJECT_END
    }

    if (
      object.type === 'R3EventObjUser'
    ) {
      //CUSTOM_RETURN_OPTION_LOAD_USER_START
      return await this.loadUser(object);
      //CUSTOM_RETURN_OPTION_LOAD_USER_END
    }
    //GENERATED_LOAD_END

    //CUSTOM_LOAD_START
    //CUSTOM_LOAD_END

    //GENERATED_LOAD_AFTER_START
    throw new Error(`No suitable method found in load() in runtime R3RuntimeStorageAxios`);
    //GENERATED_LOAD_AFTER_END

  }

  /**
   * remove()
   * - Removes the R3 Object from Storage using apiUrl if specified, when deep is true it includes all children. When
   *   user is not specified it will attempt to discover the current user, or specify a user explicitly to perform this
   *   operation as that user.
   * @param object
   * - No returns
   */
  async remove(object) {

    //CUSTOM_REMOVE_BEFORE_START
    //CUSTOM_REMOVE_BEFORE_END

    //GENERATED_REMOVE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_REMOVE_START
    await super.remove(object);
    //CUSTOM_RETURN_GENERATED_REMOVE_END
    //GENERATED_REMOVE_BEFORE_END

    //CUSTOM_REMOVE_BEFORE_GENERATED_START
    //CUSTOM_REMOVE_BEFORE_GENERATED_END

    //GENERATED_REMOVE_START
    if (
      object.type === 'R3EventObjComponent'
   || object.type === 'R3EventObjComponentPhysics'
   || object.type === 'R3EventObjComponentPhysicsWorld'
   || object.type === 'R3EventObjComponentPhysicsRigidBody'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyFixed'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyDynamic'
   || object.type === 'R3EventObjComponentPhysicsJoint'
   || object.type === 'R3EventObjComponentPhysicsJointSpherical'
   || object.type === 'R3EventObjComponentPhysicsJointRevolute'
   || object.type === 'R3EventObjComponentPhysicsJointPrismatic'
   || object.type === 'R3EventObjComponentPhysicsJointFixed'
   || object.type === 'R3EventObjComponentPhysicsGravity'
   || object.type === 'R3EventObjComponentPhysicsCollider'
   || object.type === 'R3EventObjComponentPhysicsColliderTriMesh'
   || object.type === 'R3EventObjComponentPhysicsColliderHeightField'
   || object.type === 'R3EventObjComponentPhysicsColliderCuboid'
   || object.type === 'R3EventObjComponentPhysicsColliderCapsule'
   || object.type === 'R3EventObjComponentPhysicsColliderBall'
   || object.type === 'R3EventObjComponentMaths'
   || object.type === 'R3EventObjComponentMathsVertex'
   || object.type === 'R3EventObjComponentMathsVector4'
   || object.type === 'R3EventObjComponentMathsVector3'
   || object.type === 'R3EventObjComponentMathsVector2'
   || object.type === 'R3EventObjComponentMathsUV'
   || object.type === 'R3EventObjComponentMathsSphere'
   || object.type === 'R3EventObjComponentMathsRay'
   || object.type === 'R3EventObjComponentMathsQuaternion'
   || object.type === 'R3EventObjComponentMathsPlane'
   || object.type === 'R3EventObjComponentMathsMatrix4'
   || object.type === 'R3EventObjComponentMathsMatrix3'
   || object.type === 'R3EventObjComponentMathsFace'
   || object.type === 'R3EventObjComponentMathsColor'
   || object.type === 'R3EventObjComponentInput'
   || object.type === 'R3EventObjComponentInputTouch'
   || object.type === 'R3EventObjComponentInputOrbitControls'
   || object.type === 'R3EventObjComponentInputMouse'
   || object.type === 'R3EventObjComponentInputKeyboard'
   || object.type === 'R3EventObjComponentGraphics'
   || object.type === 'R3EventObjComponentGraphicsVideo'
   || object.type === 'R3EventObjComponentGraphicsTexture'
   || object.type === 'R3EventObjComponentGraphicsTextureVideo'
   || object.type === 'R3EventObjComponentGraphicsTextureImage'
   || object.type === 'R3EventObjComponentGraphicsTextureCube'
   || object.type === 'R3EventObjComponentGraphicsTextureCanvas'
   || object.type === 'R3EventObjComponentGraphicsSprite'
   || object.type === 'R3EventObjComponentGraphicsSkeleton'
   || object.type === 'R3EventObjComponentGraphicsShadow'
   || object.type === 'R3EventObjComponentGraphicsShadowSpot'
   || object.type === 'R3EventObjComponentGraphicsShadowPoint'
   || object.type === 'R3EventObjComponentGraphicsShadowDirectional'
   || object.type === 'R3EventObjComponentGraphicsShader'
   || object.type === 'R3EventObjComponentGraphicsScene'
   || object.type === 'R3EventObjComponentGraphicsRenderer'
   || object.type === 'R3EventObjComponentGraphicsRendererTarget'
   || object.type === 'R3EventObjComponentGraphicsRendererGl'
   || object.type === 'R3EventObjComponentGraphicsRendererCube'
   || object.type === 'R3EventObjComponentGraphicsRaycaster'
   || object.type === 'R3EventObjComponentGraphicsMesh'
   || object.type === 'R3EventObjComponentGraphicsMaterialGroup'
   || object.type === 'R3EventObjComponentGraphicsMaterial'
   || object.type === 'R3EventObjComponentGraphicsMaterialToon'
   || object.type === 'R3EventObjComponentGraphicsMaterialStandard'
   || object.type === 'R3EventObjComponentGraphicsMaterialPoints'
   || object.type === 'R3EventObjComponentGraphicsMaterialPhong'
   || object.type === 'R3EventObjComponentGraphicsMaterialLambert'
   || object.type === 'R3EventObjComponentGraphicsMaterialBasic'
   || object.type === 'R3EventObjComponentGraphicsLight'
   || object.type === 'R3EventObjComponentGraphicsLightSpot'
   || object.type === 'R3EventObjComponentGraphicsLightPoint'
   || object.type === 'R3EventObjComponentGraphicsLightDirectional'
   || object.type === 'R3EventObjComponentGraphicsLightAmbient'
   || object.type === 'R3EventObjComponentGraphicsImage'
   || object.type === 'R3EventObjComponentGraphicsGeometry'
   || object.type === 'R3EventObjComponentGraphicsGeometryTorus'
   || object.type === 'R3EventObjComponentGraphicsGeometrySphere'
   || object.type === 'R3EventObjComponentGraphicsGeometryPlane'
   || object.type === 'R3EventObjComponentGraphicsGeometryBuffer'
   || object.type === 'R3EventObjComponentGraphicsGeometryBox'
   || object.type === 'R3EventObjComponentGraphicsFog'
   || object.type === 'R3EventObjComponentGraphicsCanvas'
   || object.type === 'R3EventObjComponentGraphicsCamera'
   || object.type === 'R3EventObjComponentGraphicsCameraPerspective'
   || object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
   || object.type === 'R3EventObjComponentGraphicsCameraCube'
   || object.type === 'R3EventObjComponentCode'
   || object.type === 'R3EventObjComponentAudio'
   || object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_REMOVE_COMPONENT_START
      return await this.removeComponent(object);
      //CUSTOM_RETURN_OPTION_REMOVE_COMPONENT_END
    }

    if (
      object.type === 'R3EventObjEntity'
   || object.type === 'R3EventObjEntityAnimation'
   || object.type === 'R3EventObjEntityAnimationRotation'
    ) {
      //CUSTOM_RETURN_OPTION_REMOVE_ENTITY_START
      return await this.removeEntity(object);
      //CUSTOM_RETURN_OPTION_REMOVE_ENTITY_END
    }

    if (
      object.type === 'R3EventObjFile'
    ) {
      //CUSTOM_RETURN_OPTION_REMOVE_FILE_START
      return await this.removeFile(object);
      //CUSTOM_RETURN_OPTION_REMOVE_FILE_END
    }

    if (
      object.type === 'R3EventObjGroup'
    ) {
      //CUSTOM_RETURN_OPTION_REMOVE_GROUP_START
      return await this.removeGroup(object);
      //CUSTOM_RETURN_OPTION_REMOVE_GROUP_END
    }

    if (
      object.type === 'R3EventObjProject'
    ) {
      //CUSTOM_RETURN_OPTION_REMOVE_PROJECT_START
      return await this.removeProject(object);
      //CUSTOM_RETURN_OPTION_REMOVE_PROJECT_END
    }

    if (
      object.type === 'R3EventObjUser'
    ) {
      //CUSTOM_RETURN_OPTION_REMOVE_USER_START
      return await this.removeUser(object);
      //CUSTOM_RETURN_OPTION_REMOVE_USER_END
    }
    //GENERATED_REMOVE_END

    //CUSTOM_REMOVE_START
    //CUSTOM_REMOVE_END

    //GENERATED_REMOVE_AFTER_START
    throw new Error(`No suitable method found in remove() in runtime R3RuntimeStorageAxios`);
    //GENERATED_REMOVE_AFTER_END

  }

  /**
   * save()
   * - Saves the R3 Object to Storage using apiUrl if specified, when deep is true it includes all children. When user
   *   is not specified it will attempt to discover the current user, or specify a user explicitly to perform this
   *   operation as that user.
   * @param object
   * - No returns
   */
  async save(object) {

    //CUSTOM_SAVE_BEFORE_START
    //CUSTOM_SAVE_BEFORE_END

    //GENERATED_SAVE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_SAVE_START
    await super.save(object);
    //CUSTOM_RETURN_GENERATED_SAVE_END
    //GENERATED_SAVE_BEFORE_END

    //CUSTOM_SAVE_BEFORE_GENERATED_START
    //CUSTOM_SAVE_BEFORE_GENERATED_END

    //GENERATED_SAVE_START
    if (
      object.type === 'R3EventObjComponent'
   || object.type === 'R3EventObjComponentPhysics'
   || object.type === 'R3EventObjComponentPhysicsWorld'
   || object.type === 'R3EventObjComponentPhysicsRigidBody'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyFixed'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyDynamic'
   || object.type === 'R3EventObjComponentPhysicsJoint'
   || object.type === 'R3EventObjComponentPhysicsJointSpherical'
   || object.type === 'R3EventObjComponentPhysicsJointRevolute'
   || object.type === 'R3EventObjComponentPhysicsJointPrismatic'
   || object.type === 'R3EventObjComponentPhysicsJointFixed'
   || object.type === 'R3EventObjComponentPhysicsGravity'
   || object.type === 'R3EventObjComponentPhysicsCollider'
   || object.type === 'R3EventObjComponentPhysicsColliderTriMesh'
   || object.type === 'R3EventObjComponentPhysicsColliderHeightField'
   || object.type === 'R3EventObjComponentPhysicsColliderCuboid'
   || object.type === 'R3EventObjComponentPhysicsColliderCapsule'
   || object.type === 'R3EventObjComponentPhysicsColliderBall'
   || object.type === 'R3EventObjComponentMaths'
   || object.type === 'R3EventObjComponentMathsVertex'
   || object.type === 'R3EventObjComponentMathsVector4'
   || object.type === 'R3EventObjComponentMathsVector3'
   || object.type === 'R3EventObjComponentMathsVector2'
   || object.type === 'R3EventObjComponentMathsUV'
   || object.type === 'R3EventObjComponentMathsSphere'
   || object.type === 'R3EventObjComponentMathsRay'
   || object.type === 'R3EventObjComponentMathsQuaternion'
   || object.type === 'R3EventObjComponentMathsPlane'
   || object.type === 'R3EventObjComponentMathsMatrix4'
   || object.type === 'R3EventObjComponentMathsMatrix3'
   || object.type === 'R3EventObjComponentMathsFace'
   || object.type === 'R3EventObjComponentMathsColor'
   || object.type === 'R3EventObjComponentInput'
   || object.type === 'R3EventObjComponentInputTouch'
   || object.type === 'R3EventObjComponentInputOrbitControls'
   || object.type === 'R3EventObjComponentInputMouse'
   || object.type === 'R3EventObjComponentInputKeyboard'
   || object.type === 'R3EventObjComponentGraphics'
   || object.type === 'R3EventObjComponentGraphicsVideo'
   || object.type === 'R3EventObjComponentGraphicsTexture'
   || object.type === 'R3EventObjComponentGraphicsTextureVideo'
   || object.type === 'R3EventObjComponentGraphicsTextureImage'
   || object.type === 'R3EventObjComponentGraphicsTextureCube'
   || object.type === 'R3EventObjComponentGraphicsTextureCanvas'
   || object.type === 'R3EventObjComponentGraphicsSprite'
   || object.type === 'R3EventObjComponentGraphicsSkeleton'
   || object.type === 'R3EventObjComponentGraphicsShadow'
   || object.type === 'R3EventObjComponentGraphicsShadowSpot'
   || object.type === 'R3EventObjComponentGraphicsShadowPoint'
   || object.type === 'R3EventObjComponentGraphicsShadowDirectional'
   || object.type === 'R3EventObjComponentGraphicsShader'
   || object.type === 'R3EventObjComponentGraphicsScene'
   || object.type === 'R3EventObjComponentGraphicsRenderer'
   || object.type === 'R3EventObjComponentGraphicsRendererTarget'
   || object.type === 'R3EventObjComponentGraphicsRendererGl'
   || object.type === 'R3EventObjComponentGraphicsRendererCube'
   || object.type === 'R3EventObjComponentGraphicsRaycaster'
   || object.type === 'R3EventObjComponentGraphicsMesh'
   || object.type === 'R3EventObjComponentGraphicsMaterialGroup'
   || object.type === 'R3EventObjComponentGraphicsMaterial'
   || object.type === 'R3EventObjComponentGraphicsMaterialToon'
   || object.type === 'R3EventObjComponentGraphicsMaterialStandard'
   || object.type === 'R3EventObjComponentGraphicsMaterialPoints'
   || object.type === 'R3EventObjComponentGraphicsMaterialPhong'
   || object.type === 'R3EventObjComponentGraphicsMaterialLambert'
   || object.type === 'R3EventObjComponentGraphicsMaterialBasic'
   || object.type === 'R3EventObjComponentGraphicsLight'
   || object.type === 'R3EventObjComponentGraphicsLightSpot'
   || object.type === 'R3EventObjComponentGraphicsLightPoint'
   || object.type === 'R3EventObjComponentGraphicsLightDirectional'
   || object.type === 'R3EventObjComponentGraphicsLightAmbient'
   || object.type === 'R3EventObjComponentGraphicsImage'
   || object.type === 'R3EventObjComponentGraphicsGeometry'
   || object.type === 'R3EventObjComponentGraphicsGeometryTorus'
   || object.type === 'R3EventObjComponentGraphicsGeometrySphere'
   || object.type === 'R3EventObjComponentGraphicsGeometryPlane'
   || object.type === 'R3EventObjComponentGraphicsGeometryBuffer'
   || object.type === 'R3EventObjComponentGraphicsGeometryBox'
   || object.type === 'R3EventObjComponentGraphicsFog'
   || object.type === 'R3EventObjComponentGraphicsCanvas'
   || object.type === 'R3EventObjComponentGraphicsCamera'
   || object.type === 'R3EventObjComponentGraphicsCameraPerspective'
   || object.type === 'R3EventObjComponentGraphicsCameraOrthographic'
   || object.type === 'R3EventObjComponentGraphicsCameraCube'
   || object.type === 'R3EventObjComponentCode'
   || object.type === 'R3EventObjComponentAudio'
   || object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_SAVE_COMPONENT_START
      return await this.saveComponent(object);
      //CUSTOM_RETURN_OPTION_SAVE_COMPONENT_END
    }

    if (
      object.type === 'R3EventObjEntity'
   || object.type === 'R3EventObjEntityAnimation'
   || object.type === 'R3EventObjEntityAnimationRotation'
    ) {
      //CUSTOM_RETURN_OPTION_SAVE_ENTITY_START
      return await this.saveEntity(object);
      //CUSTOM_RETURN_OPTION_SAVE_ENTITY_END
    }

    if (
      object.type === 'R3EventObjFile'
    ) {
      //CUSTOM_RETURN_OPTION_SAVE_FILE_START
      return await this.saveFile(object);
      //CUSTOM_RETURN_OPTION_SAVE_FILE_END
    }

    if (
      object.type === 'R3EventObjGroup'
    ) {
      //CUSTOM_RETURN_OPTION_SAVE_GROUP_START
      return await this.saveGroup(object);
      //CUSTOM_RETURN_OPTION_SAVE_GROUP_END
    }

    if (
      object.type === 'R3EventObjProject'
    ) {
      //CUSTOM_RETURN_OPTION_SAVE_PROJECT_START
      return await this.saveProject(object);
      //CUSTOM_RETURN_OPTION_SAVE_PROJECT_END
    }

    if (
      object.type === 'R3EventObjUser'
    ) {
      //CUSTOM_RETURN_OPTION_SAVE_USER_START
      return await this.saveUser(object);
      //CUSTOM_RETURN_OPTION_SAVE_USER_END
    }
    //GENERATED_SAVE_END

    //CUSTOM_SAVE_START
    //CUSTOM_SAVE_END

    //GENERATED_SAVE_AFTER_START
    throw new Error(`No suitable method found in save() in runtime R3RuntimeStorageAxios`);
    //GENERATED_SAVE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  /**
   * cloneComponent()
   * - Axios implementation for [R3EventObjComponent]
   * @param object
   * - No returns
   */
  async cloneComponent(object) {

    //GENERATED_CLONE_COMPONENT_BEFORE_START
    //GENERATED_CLONE_COMPONENT_BEFORE_END

    //GENERATED_CLONE_COMPONENT_START
    //GENERATED_CLONE_COMPONENT_END

    //CUSTOM_CLONE_COMPONENT_START
    console.warn(`TODO: Implement clone component`);
    //CUSTOM_CLONE_COMPONENT_END

    //GENERATED_CLONE_COMPONENT_AFTER_START
    //GENERATED_CLONE_COMPONENT_AFTER_END

  }

  /**
   * loadComponent()
   * - Axios implementation for [R3EventObjComponent]
   * @param object
   * @param {Boolean} [children=false]
   * - No returns
   */
  async loadComponent(object, children=false) {

    //GENERATED_LOAD_COMPONENT_BEFORE_START
    //GENERATED_LOAD_COMPONENT_BEFORE_END

    //GENERATED_LOAD_COMPONENT_START
    //GENERATED_LOAD_COMPONENT_END

    //CUSTOM_LOAD_COMPONENT_START
    return await this.loadFromUrl(object, 'component', 'post');
    //CUSTOM_LOAD_COMPONENT_END

    //GENERATED_LOAD_COMPONENT_AFTER_START
    //GENERATED_LOAD_COMPONENT_AFTER_END

  }

  /**
   * removeComponent()
   * - Axios implementation for [R3EventObjComponent]
   * @param object
   * - No returns
   */
  async removeComponent(object) {

    //GENERATED_REMOVE_COMPONENT_BEFORE_START
    //GENERATED_REMOVE_COMPONENT_BEFORE_END

    //GENERATED_REMOVE_COMPONENT_START
    //GENERATED_REMOVE_COMPONENT_END

    //CUSTOM_REMOVE_COMPONENT_START
    return await this.deleteAtUrl(object);
    //CUSTOM_REMOVE_COMPONENT_END

    //GENERATED_REMOVE_COMPONENT_AFTER_START
    //GENERATED_REMOVE_COMPONENT_AFTER_END

  }

  /**
   * saveComponent()
   * - Axios implementation for [R3EventObjComponent]
   * @param object
   * - No returns
   */
  async saveComponent(object) {

    //GENERATED_SAVE_COMPONENT_BEFORE_START
    //GENERATED_SAVE_COMPONENT_BEFORE_END

    //GENERATED_SAVE_COMPONENT_START
    //GENERATED_SAVE_COMPONENT_END

    //CUSTOM_SAVE_COMPONENT_START
    return await this.saveToUrl(object, 'component');
    //CUSTOM_SAVE_COMPONENT_END

    //GENERATED_SAVE_COMPONENT_AFTER_START
    //GENERATED_SAVE_COMPONENT_AFTER_END

  }

  /**
   * cloneEntity()
   * - Axios implementation for [R3EventObjEntity]
   * @param object
   * - No returns
   */
  async cloneEntity(object) {

    //GENERATED_CLONE_ENTITY_BEFORE_START
    //GENERATED_CLONE_ENTITY_BEFORE_END

    //GENERATED_CLONE_ENTITY_START
    //GENERATED_CLONE_ENTITY_END

    //CUSTOM_CLONE_ENTITY_START
    return await this.loadEntity(object);
    //CUSTOM_CLONE_ENTITY_END

    //GENERATED_CLONE_ENTITY_AFTER_START
    //GENERATED_CLONE_ENTITY_AFTER_END

  }

  /**
   * loadEntity()
   * - Axios implementation for [R3EventObjEntity]
   * @param object
   * @param {Boolean} [children=false]
   * - No returns
   */
  async loadEntity(object, children=false) {

    //GENERATED_LOAD_ENTITY_BEFORE_START
    //GENERATED_LOAD_ENTITY_BEFORE_END

    //GENERATED_LOAD_ENTITY_START
    //GENERATED_LOAD_ENTITY_END

    //CUSTOM_LOAD_ENTITY_START
    return await this.loadFromUrl(object, 'entity', 'post');
    //CUSTOM_LOAD_ENTITY_END

    //GENERATED_LOAD_ENTITY_AFTER_START
    //GENERATED_LOAD_ENTITY_AFTER_END

  }

  /**
   * removeEntity()
   * - Axios implementation for [R3EventObjEntity]
   * @param object
   * - No returns
   */
  async removeEntity(object) {

    //GENERATED_REMOVE_ENTITY_BEFORE_START
    //GENERATED_REMOVE_ENTITY_BEFORE_END

    //GENERATED_REMOVE_ENTITY_START
    //GENERATED_REMOVE_ENTITY_END

    //CUSTOM_REMOVE_ENTITY_START
    return await this.deleteAtUrl(object);
    //CUSTOM_REMOVE_ENTITY_END

    //GENERATED_REMOVE_ENTITY_AFTER_START
    //GENERATED_REMOVE_ENTITY_AFTER_END

  }

  /**
   * saveEntity()
   * - Axios implementation for [R3EventObjEntity]
   * @param object
   * - No returns
   */
  async saveEntity(object) {

    //GENERATED_SAVE_ENTITY_BEFORE_START
    //GENERATED_SAVE_ENTITY_BEFORE_END

    //GENERATED_SAVE_ENTITY_START
    //GENERATED_SAVE_ENTITY_END

    //CUSTOM_SAVE_ENTITY_START
    return await this.saveToUrl(object, `entity`);
    //CUSTOM_SAVE_ENTITY_END

    //GENERATED_SAVE_ENTITY_AFTER_START
    //GENERATED_SAVE_ENTITY_AFTER_END

  }

  /**
   * cloneFile()
   * - Axios implementation for [R3EventObjFile]
   * @param object
   * - No returns
   */
  async cloneFile(object) {

    //GENERATED_CLONE_FILE_BEFORE_START
    //GENERATED_CLONE_FILE_BEFORE_END

    //GENERATED_CLONE_FILE_START
    //GENERATED_CLONE_FILE_END

    //CUSTOM_CLONE_FILE_START
    throw new Error(`Please implement Axios.cloneFile(object) in R3RuntimeStorageAxios`);
    //CUSTOM_CLONE_FILE_END

    //GENERATED_CLONE_FILE_AFTER_START
    //GENERATED_CLONE_FILE_AFTER_END

  }

  /**
   * loadFile()
   * - Axios implementation for [R3EventObjFile]
   * @param object
   * @param {Boolean} [children=false]
   * - No returns
   */
  async loadFile(object, children=false) {

    //GENERATED_LOAD_FILE_BEFORE_START
    //GENERATED_LOAD_FILE_BEFORE_END

    //GENERATED_LOAD_FILE_START
    //GENERATED_LOAD_FILE_END

    //CUSTOM_LOAD_FILE_START
    throw new Error(`Please implement Axios.loadFile(object, children=false) in R3RuntimeStorageAxios`);
    //CUSTOM_LOAD_FILE_END

    //GENERATED_LOAD_FILE_AFTER_START
    //GENERATED_LOAD_FILE_AFTER_END

  }

  /**
   * removeFile()
   * - Axios implementation for [R3EventObjFile]
   * @param object
   * - No returns
   */
  async removeFile(object) {

    //GENERATED_REMOVE_FILE_BEFORE_START
    //GENERATED_REMOVE_FILE_BEFORE_END

    //GENERATED_REMOVE_FILE_START
    //GENERATED_REMOVE_FILE_END

    //CUSTOM_REMOVE_FILE_START
    throw new Error(`Please implement Axios.removeFile(object) in R3RuntimeStorageAxios`);
    //CUSTOM_REMOVE_FILE_END

    //GENERATED_REMOVE_FILE_AFTER_START
    //GENERATED_REMOVE_FILE_AFTER_END

  }

  /**
   * saveFile()
   * - Axios implementation for [R3EventObjFile]
   * @param object
   * - No returns
   */
  async saveFile(object) {

    //GENERATED_SAVE_FILE_BEFORE_START
    //GENERATED_SAVE_FILE_BEFORE_END

    //GENERATED_SAVE_FILE_START
    //GENERATED_SAVE_FILE_END

    //CUSTOM_SAVE_FILE_START
    return await this.saveToUrl(object, 'file');
    //CUSTOM_SAVE_FILE_END

    //GENERATED_SAVE_FILE_AFTER_START
    //GENERATED_SAVE_FILE_AFTER_END

  }

  /**
   * cloneGroup()
   * - Axios implementation for [R3EventObjGroup]
   * @param object
   * - No returns
   */
  async cloneGroup(object) {

    //GENERATED_CLONE_GROUP_BEFORE_START
    //GENERATED_CLONE_GROUP_BEFORE_END

    //GENERATED_CLONE_GROUP_START
    //GENERATED_CLONE_GROUP_END

    //CUSTOM_CLONE_GROUP_START
    return await this.loadGroup(object);
    //CUSTOM_CLONE_GROUP_END

    //GENERATED_CLONE_GROUP_AFTER_START
    //GENERATED_CLONE_GROUP_AFTER_END

  }

  /**
   * loadGroup()
   * - Axios implementation for [R3EventObjGroup]
   * @param object
   * @param {Boolean} [children=false]
   * - No returns
   */
  async loadGroup(object, children=false) {

    //GENERATED_LOAD_GROUP_BEFORE_START
    //GENERATED_LOAD_GROUP_BEFORE_END

    //GENERATED_LOAD_GROUP_START
    //GENERATED_LOAD_GROUP_END

    //CUSTOM_LOAD_GROUP_START
    return await this.loadFromUrl(object, 'group', 'post');
    //CUSTOM_LOAD_GROUP_END

    //GENERATED_LOAD_GROUP_AFTER_START
    //GENERATED_LOAD_GROUP_AFTER_END

  }

  /**
   * removeGroup()
   * - Axios implementation for [R3EventObjGroup]
   * @param object
   * - No returns
   */
  async removeGroup(object) {

    //GENERATED_REMOVE_GROUP_BEFORE_START
    //GENERATED_REMOVE_GROUP_BEFORE_END

    //GENERATED_REMOVE_GROUP_START
    //GENERATED_REMOVE_GROUP_END

    //CUSTOM_REMOVE_GROUP_START
    return await this.deleteAtUrl(object);
    //CUSTOM_REMOVE_GROUP_END

    //GENERATED_REMOVE_GROUP_AFTER_START
    //GENERATED_REMOVE_GROUP_AFTER_END

  }

  /**
   * saveGroup()
   * - Axios implementation for [R3EventObjGroup]
   * @param object
   * - No returns
   */
  async saveGroup(object) {

    //GENERATED_SAVE_GROUP_BEFORE_START
    //GENERATED_SAVE_GROUP_BEFORE_END

    //GENERATED_SAVE_GROUP_START
    //GENERATED_SAVE_GROUP_END

    //CUSTOM_SAVE_GROUP_START
    return await this.saveToUrl(object, `group`);
    //CUSTOM_SAVE_GROUP_END

    //GENERATED_SAVE_GROUP_AFTER_START
    //GENERATED_SAVE_GROUP_AFTER_END

  }

  /**
   * cloneProject()
   * - Axios implementation for [R3EventObjProject]
   * @param object
   * - No returns
   */
  async cloneProject(object) {

    //GENERATED_CLONE_PROJECT_BEFORE_START
    //GENERATED_CLONE_PROJECT_BEFORE_END

    //GENERATED_CLONE_PROJECT_START
    //GENERATED_CLONE_PROJECT_END

    //CUSTOM_CLONE_PROJECT_START
    return await this.loadProject(object);
    //CUSTOM_CLONE_PROJECT_END

    //GENERATED_CLONE_PROJECT_AFTER_START
    //GENERATED_CLONE_PROJECT_AFTER_END

  }

  /**
   * loadProject()
   * - Axios implementation for [R3EventObjProject]
   * @param object
   * @param {Boolean} [children=false]
   * - No returns
   */
  async loadProject(object, children=false) {

    //GENERATED_LOAD_PROJECT_BEFORE_START
    //GENERATED_LOAD_PROJECT_BEFORE_END

    //GENERATED_LOAD_PROJECT_START
    //GENERATED_LOAD_PROJECT_END

    //CUSTOM_LOAD_PROJECT_START
    return await this.loadFromUrl(object, 'project', 'post');
    //CUSTOM_LOAD_PROJECT_END

    //GENERATED_LOAD_PROJECT_AFTER_START
    //GENERATED_LOAD_PROJECT_AFTER_END

  }

  /**
   * removeProject()
   * - Axios implementation for [R3EventObjProject]
   * @param object
   * - No returns
   */
  async removeProject(object) {

    //GENERATED_REMOVE_PROJECT_BEFORE_START
    //GENERATED_REMOVE_PROJECT_BEFORE_END

    //GENERATED_REMOVE_PROJECT_START
    //GENERATED_REMOVE_PROJECT_END

    //CUSTOM_REMOVE_PROJECT_START
    return await this.deleteAtUrl(object);
    //CUSTOM_REMOVE_PROJECT_END

    //GENERATED_REMOVE_PROJECT_AFTER_START
    //GENERATED_REMOVE_PROJECT_AFTER_END

  }

  /**
   * saveProject()
   * - Axios implementation for [R3EventObjProject]
   * @param object
   * - No returns
   */
  async saveProject(object) {

    //GENERATED_SAVE_PROJECT_BEFORE_START
    //GENERATED_SAVE_PROJECT_BEFORE_END

    //GENERATED_SAVE_PROJECT_START
    //GENERATED_SAVE_PROJECT_END

    //CUSTOM_SAVE_PROJECT_START
    return await this.saveToUrl(object, `project`);
    //CUSTOM_SAVE_PROJECT_END

    //GENERATED_SAVE_PROJECT_AFTER_START
    //GENERATED_SAVE_PROJECT_AFTER_END

  }

  /**
   * cloneUser()
   * - Axios implementation for [R3EventObjUser]
   * @param object
   * - No returns
   */
  async cloneUser(object) {

    //GENERATED_CLONE_USER_BEFORE_START
    //GENERATED_CLONE_USER_BEFORE_END

    //GENERATED_CLONE_USER_START
    //GENERATED_CLONE_USER_END

    //CUSTOM_CLONE_USER_START
    return await this.loadUser(object);
    //CUSTOM_CLONE_USER_END

    //GENERATED_CLONE_USER_AFTER_START
    //GENERATED_CLONE_USER_AFTER_END

  }

  /**
   * loadUser()
   * - Axios implementation for [R3EventObjUser]
   * @param object
   * @param {Boolean} [children=false]
   * - No returns
   */
  async loadUser(object, children=false) {

    //GENERATED_LOAD_USER_BEFORE_START
    //GENERATED_LOAD_USER_BEFORE_END

    //GENERATED_LOAD_USER_START
    //GENERATED_LOAD_USER_END

    //CUSTOM_LOAD_USER_START
    return await this.loadFromUrl(object, 'user', 'post');
    //CUSTOM_LOAD_USER_END

    //GENERATED_LOAD_USER_AFTER_START
    //GENERATED_LOAD_USER_AFTER_END

  }

  /**
   * removeUser()
   * - Axios implementation for [R3EventObjUser]
   * @param object
   * - No returns
   */
  async removeUser(object) {

    //GENERATED_REMOVE_USER_BEFORE_START
    //GENERATED_REMOVE_USER_BEFORE_END

    //GENERATED_REMOVE_USER_START
    //GENERATED_REMOVE_USER_END

    //CUSTOM_REMOVE_USER_START
    return await this.deleteAtUrl(object);
    //CUSTOM_REMOVE_USER_END

    //GENERATED_REMOVE_USER_AFTER_START
    //GENERATED_REMOVE_USER_AFTER_END

  }

  /**
   * saveUser()
   * - Axios implementation for [R3EventObjUser]
   * @param object
   * - No returns
   */
  async saveUser(object) {

    //GENERATED_SAVE_USER_BEFORE_START
    //GENERATED_SAVE_USER_BEFORE_END

    //GENERATED_SAVE_USER_START
    //GENERATED_SAVE_USER_END

    //CUSTOM_SAVE_USER_START
    return await this.saveToUrl(object, `user`);
    //CUSTOM_SAVE_USER_END

    //GENERATED_SAVE_USER_AFTER_START
    //GENERATED_SAVE_USER_AFTER_END

  }

  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Axios.Type = 'R3RuntimeStorageAxios';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Axios as default};
