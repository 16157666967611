//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import R3 from '../R3.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  object=null
  pointer=null
  type='start-node'
  successors=[]
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Node extends R3 {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param object
     * - No comment
     */
    if (typeof options.object === 'undefined') {
      options.object = null;
    }

    /**
     * @param pointer
     * - No comment
     */
    if (typeof options.pointer === 'undefined') {
      options.pointer = null;
    }

    /**
     * @param type
     * - node'
     */
    if (typeof options.type === 'undefined') {
      options.type = 'start-node';
    }

    /**
     * @param successors
     * - No comment
     */
    if (typeof options.successors === 'undefined') {
      options.successors = [];
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

//GENERATED_EXPORTS_START
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END

export {Node as default};
