//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Color from '../../maths/Color.js';
import Vector2 from '../../maths/Vector2.js';
import Runtime from '../../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Material from '../Material.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  alphaMap = null
  aoMap = null - The ambient occlusion texture map.
  aoMapIntensity = 1.0 - The intensity of the ambient occlusion map. Zero is no occlusion effect.
  bumpMap = null - The bump texture map
  bumpScale = 1
  color=new Color({r:255,g:255,b:255,a:1}) - The main color of the material.
  displacementBias = 0 - The bias of the displacement map. A higher value will push the surface in the direction of the normal.
  displacementMap = null
  displacementScale = 1
  emissive = new Color({r:0,g:0,b:0,a:0})
  emissiveIntensity = 0.0
  emissiveMap = null
  envMap = null
  envMapIntensity = 1.0
  flatShading = false
  fog = true
  lightMap = null
  lightMapIntensity = 1.0
  map = null
  metalness = 0.5
  metalnessMap = null
  normalMap = null
  normalMapType = Material.TangentSpaceNormalMap
  normalScale = new Vector2( {x:1, y:1} )
  roughness = 1.0
  roughnessMap = null
  wireframe = false
  wireframeLinecap = 'round'
  wireframeLinejoin = 'round'
  wireframeLinewidth = 1
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeGraphicsThree" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "color",
            "type" : "R3EventObjComponentMathsColor"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "alphaMap",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "aoMap",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "aoMapIntensity",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.001
          },
          {
            "property" : "bumpMap",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "bumpScale",
            "type" : "Number",
            "min" : 0,
            "max" : 10,
            "step" : 0.01
          },
          {
            "property" : "color",
            "type" : "R3EventObjComponentMathsColor"
          },
          {
            "property" : "displacementBias",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.0001
          },
          {
            "property" : "displacementMap",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "displacementScale",
            "type" : "Number",
            "min" : 1,
            "max" : 10,
            "step" : 0.01
          },
          {
            "property" : "emissive",
            "type" : "R3EventObjComponentMathsColor"
          },
          {
            "property" : "emissiveIntensity",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.001
          },
          {
            "property" : "emissiveMap",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "envMap",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "envMapIntensity",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.001
          },
          {
            "property" : "flatShading",
            "type" : "Boolean"
          },
          {
            "property" : "fog",
            "type" : "Boolean"
          },
          {
            "property" : "lightMap",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "lightMapIntensity",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.001
          },
          {
            "property" : "map",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "metalness",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.001
          },
          {
            "property" : "metalnessMap",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "normalMap",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "normalMapType",
            "type" : "Number",
            "possibleValues" : [
              "Material.TangentSpaceNormalMap",
              "Material.ObjectSpaceNormalMap"
            ],
            "possibleLabels" : [
              "Tangent Space",
              "Object Space"
            ]
          },
          {
            "property" : "normalScale",
            "type" : "R3EventObjComponentMathsVector2"
          },
          {
            "property" : "roughness",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.001
          },
          {
            "property" : "roughnessMap",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "wireframe",
            "type" : "Boolean"
          },
          {
            "property" : "wireframeLinecap",
            "type" : "String",
            "possibleValues" : [
              "Material.Butt",
              "Material.Round",
              "Material.Square"
            ],
            "possibleLabels" : [
              "Butt",
              "Round",
              "Square"
            ]
          },
          {
            "property" : "wireframeLinejoin",
            "type" : "String",
            "possibleValues" : [
              "Material.Round",
              "Material.Bevel",
              "Material.Miter"
            ],
            "possibleLabels" : [
              "Round",
              "Bevel",
              "Miter"
            ]
          },
          {
            "property" : "wireframeLinewidth",
            "type" : "Number",
            "min" : 1,
            "max" : 10,
            "step" : 1
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  cloneInstance(propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance() - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance() - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Standard extends Material {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphicsMaterialStandard';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Standard';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param alphaMap
     * - No comment
     */
    if (typeof options.alphaMap === 'undefined') {
      options.alphaMap = null;
    }

    /**
     * @param aoMap
     * - The ambient occlusion texture map.
     */
    if (typeof options.aoMap === 'undefined') {
      options.aoMap = null;
    }

    /**
     * @param aoMapIntensity
     * - The intensity of the ambient occlusion map. Zero is no occlusion effect.
     */
    if (typeof options.aoMapIntensity === 'undefined') {
      options.aoMapIntensity = 1.0;
    }

    /**
     * @param bumpMap
     * - The bump texture map
     */
    if (typeof options.bumpMap === 'undefined') {
      options.bumpMap = null;
    }

    /**
     * @param bumpScale
     * - No comment
     */
    if (typeof options.bumpScale === 'undefined') {
      options.bumpScale = 1;
    }

    /**
     * @param color
     * - The main color of the material.
     */
    if (typeof options.color === 'undefined') {
      options.color = new Color({r:255,g:255,b:255,a:1});
    }

    /**
     * @param displacementBias
     * - The bias of the displacement map. A higher value will push the surface in the direction of the normal.
     */
    if (typeof options.displacementBias === 'undefined') {
      options.displacementBias = 0;
    }

    /**
     * @param displacementMap
     * - No comment
     */
    if (typeof options.displacementMap === 'undefined') {
      options.displacementMap = null;
    }

    /**
     * @param displacementScale
     * - No comment
     */
    if (typeof options.displacementScale === 'undefined') {
      options.displacementScale = 1;
    }

    /**
     * @param emissive
     * - No comment
     */
    if (typeof options.emissive === 'undefined') {
      options.emissive = new Color({r:0,g:0,b:0,a:0});
    }

    /**
     * @param emissiveIntensity
     * - No comment
     */
    if (typeof options.emissiveIntensity === 'undefined') {
      options.emissiveIntensity = 0.0;
    }

    /**
     * @param emissiveMap
     * - No comment
     */
    if (typeof options.emissiveMap === 'undefined') {
      options.emissiveMap = null;
    }

    /**
     * @param envMap
     * - No comment
     */
    if (typeof options.envMap === 'undefined') {
      options.envMap = null;
    }

    /**
     * @param envMapIntensity
     * - No comment
     */
    if (typeof options.envMapIntensity === 'undefined') {
      options.envMapIntensity = 1.0;
    }

    /**
     * @param flatShading
     * - No comment
     */
    if (typeof options.flatShading === 'undefined') {
      options.flatShading = false;
    }

    /**
     * @param fog
     * - No comment
     */
    if (typeof options.fog === 'undefined') {
      options.fog = true;
    }

    /**
     * @param lightMap
     * - No comment
     */
    if (typeof options.lightMap === 'undefined') {
      options.lightMap = null;
    }

    /**
     * @param lightMapIntensity
     * - No comment
     */
    if (typeof options.lightMapIntensity === 'undefined') {
      options.lightMapIntensity = 1.0;
    }

    /**
     * @param map
     * - No comment
     */
    if (typeof options.map === 'undefined') {
      options.map = null;
    }

    /**
     * @param metalness
     * - No comment
     */
    if (typeof options.metalness === 'undefined') {
      options.metalness = 0.5;
    }

    /**
     * @param metalnessMap
     * - No comment
     */
    if (typeof options.metalnessMap === 'undefined') {
      options.metalnessMap = null;
    }

    /**
     * @param normalMap
     * - No comment
     */
    if (typeof options.normalMap === 'undefined') {
      options.normalMap = null;
    }

    /**
     * @param normalMapType
     * - No comment
     */
    if (typeof options.normalMapType === 'undefined') {
      options.normalMapType = Material.TangentSpaceNormalMap;
    }

    /**
     * @param normalScale
     * - No comment
     */
    if (typeof options.normalScale === 'undefined') {
      options.normalScale = new Vector2( {x:1, y:1} );
    }

    /**
     * @param roughness
     * - No comment
     */
    if (typeof options.roughness === 'undefined') {
      options.roughness = 1.0;
    }

    /**
     * @param roughnessMap
     * - No comment
     */
    if (typeof options.roughnessMap === 'undefined') {
      options.roughnessMap = null;
    }

    /**
     * @param wireframe
     * - No comment
     */
    if (typeof options.wireframe === 'undefined') {
      options.wireframe = false;
    }

    /**
     * @param wireframeLinecap
     * - No comment
     */
    if (typeof options.wireframeLinecap === 'undefined') {
      options.wireframeLinecap = 'round';
    }

    /**
     * @param wireframeLinejoin
     * - No comment
     */
    if (typeof options.wireframeLinejoin === 'undefined') {
      options.wireframeLinejoin = 'round';
    }

    /**
     * @param wireframeLinewidth
     * - No comment
     */
    if (typeof options.wireframeLinewidth === 'undefined') {
      options.wireframeLinewidth = 1;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_GRAPHICS_THREE)) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_GRAPHICS_THREE]['requirements']) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "color",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsColor")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "alphaMap",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "aoMap",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "aoMapIntensity",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.001
              },
              {
                "property": "bumpMap",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "bumpScale",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 10,
                "step": 0.01
              },
              {
                "property": "color",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsColor")
              },
              {
                "property": "displacementBias",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.0001
              },
              {
                "property": "displacementMap",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "displacementScale",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 1,
                "max": 10,
                "step": 0.01
              },
              {
                "property": "emissive",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsColor")
              },
              {
                "property": "emissiveIntensity",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.001
              },
              {
                "property": "emissiveMap",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "envMap",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "envMapIntensity",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.001
              },
              {
                "property": "flatShading",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "fog",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "lightMap",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "lightMapIntensity",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.001
              },
              {
                "property": "map",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "metalness",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.001
              },
              {
                "property": "metalnessMap",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "normalMap",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "normalMapType",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Material.TangentSpaceNormalMap,
                  Material.ObjectSpaceNormalMap
                ],
                "possibleLabels": [
                  "Tangent Space",
                  "Object Space"
                ]
              },
              {
                "property": "normalScale",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector2")
              },
              {
                "property": "roughness",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.001
              },
              {
                "property": "roughnessMap",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "wireframe",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "wireframeLinecap",
                "Constructor" : this.getClassConstructor("String"),
                "possibleValues": [
                  Material.Butt,
                  Material.Round,
                  Material.Square
                ],
                "possibleLabels": [
                  "Butt",
                  "Round",
                  "Square"
                ]
              },
              {
                "property": "wireframeLinejoin",
                "Constructor" : this.getClassConstructor("String"),
                "possibleValues": [
                  Material.Round,
                  Material.Bevel,
                  Material.Miter
                ],
                "possibleLabels": [
                  "Round",
                  "Bevel",
                  "Miter"
                ]
              },
              {
                "property": "wireframeLinewidth",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 1,
                "max": 10,
                "step": 1
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_GRAPHICS_THREE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "color",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsColor"),
        "type": "R3EventObjComponentMathsColor"
      },
      {
        "property": "alphaMap",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "aoMap",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "aoMapIntensity",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.001
      },
      {
        "property": "bumpMap",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "bumpScale",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 10,
        "step": 0.01
      },
      {
        "property": "displacementBias",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.0001
      },
      {
        "property": "displacementMap",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "displacementScale",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 1,
        "max": 10,
        "step": 0.01
      },
      {
        "property": "emissive",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsColor"),
        "type": "R3EventObjComponentMathsColor"
      },
      {
        "property": "emissiveIntensity",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.001
      },
      {
        "property": "emissiveMap",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "envMap",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "envMapIntensity",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.001
      },
      {
        "property": "flatShading",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "fog",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "lightMap",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "lightMapIntensity",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.001
      },
      {
        "property": "map",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "metalness",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.001
      },
      {
        "property": "metalnessMap",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "normalMap",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "normalMapType",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Material.TangentSpaceNormalMap,
          Material.ObjectSpaceNormalMap
        ],
        "possibleLabels": [
          "Tangent Space",
          "Object Space"
        ]
      },
      {
        "property": "normalScale",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector2"),
        "type": "R3EventObjComponentMathsVector2"
      },
      {
        "property": "roughness",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.001
      },
      {
        "property": "roughnessMap",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "wireframe",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "wireframeLinecap",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String",
        "possibleValues": [
          Material.Butt,
          Material.Round,
          Material.Square
        ],
        "possibleLabels": [
          "Butt",
          "Round",
          "Square"
        ]
      },
      {
        "property": "wireframeLinejoin",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String",
        "possibleValues": [
          Material.Round,
          Material.Bevel,
          Material.Miter
        ],
        "possibleLabels": [
          "Round",
          "Bevel",
          "Miter"
        ]
      },
      {
        "property": "wireframeLinewidth",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 1,
        "max": 10,
        "step": 1
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * cloneInstance()
   * - Clones an instance, when propagate is true, also clones the instance children
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstance(propagate = true) {

    //CUSTOM_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BEFORE_END

    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CLONE_INSTANCE_START
    /**
     * Override Template
     */
    //GENERATED_CLONE_INSTANCE_END

    //CUSTOM_CLONE_INSTANCE_START
    //CUSTOM_CLONE_INSTANCE_END

    //GENERATED_CLONE_INSTANCE_AFTER_START
    //GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  createInstance() {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  deleteInstance() {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Standard.Type = 'R3EventObjComponentGraphicsMaterialStandard';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Standard as default};
