//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import Event from '../../Event.js';
// const {NODE_ENV} = process.env;
let RAPIER;

// if (NODE_ENV === 'debug' || typeof window === 'undefined') {
//   let loader = import('../../MockRapier.js')
//   loader.then(
//     (module) => {
//       RAPIER = module.MockRapier;
//       Rapier.Ready = true;
//       Event.Emit(Event.RAPIER_RUNTIME_READY);
//     }
//   )
// } else {
  let loader = import('@dimforge/rapier3d')
  loader.then(
    (module) => {
      RAPIER = module;
      Rapier.Ready = true;
      Event.Emit(Event.RAPIER_RUNTIME_READY);
    }
  )
// }

import Utils from '../../Utils.js';
//CUSTOM_IMPORTS_END

import Physics from '../Physics.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Ready=false
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
  step(world, delta) - Step the world physics by delta 
  applyForce(rigidBody, vector) - Applies force vector to the rigidBody 
  applyTorque(rigidBody, vector) - Applies torque vector to the rigidBody 
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  createInstance(object) - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance(object) - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(object, property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createInstance(object) - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance(object) - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(object, property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
  createInstanceBall(object) - Rapier implementation for [R3EventObjComponentPhysicsColliderBall] 
  deleteInstanceBall(object) - Rapier implementation for [R3EventObjComponentPhysicsColliderBall] 
  updateInstanceBall(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsColliderBall] 
  createInstanceCapsule(object) - Rapier implementation for [R3EventObjComponentPhysicsColliderCapsule] 
  deleteInstanceCapsule(object) - Rapier implementation for [R3EventObjComponentPhysicsColliderCapsule] 
  updateInstanceCapsule(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsColliderCapsule] 
  createInstanceCuboid(object) - Rapier implementation for [R3EventObjComponentPhysicsColliderCuboid] 
  deleteInstanceCuboid(object) - Rapier implementation for [R3EventObjComponentPhysicsColliderCuboid] 
  updateInstanceCuboid(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsColliderCuboid] 
  createInstanceHeightField(object) - Rapier implementation for [R3EventObjComponentPhysicsColliderHeightField] 
  deleteInstanceHeightField(object) - Rapier implementation for [R3EventObjComponentPhysicsColliderHeightField] 
  updateInstanceHeightField(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsColliderHeightField] 
  createInstanceTriMesh(object) - Rapier implementation for [R3EventObjComponentPhysicsColliderTriMesh] 
  deleteInstanceTriMesh(object) - Rapier implementation for [R3EventObjComponentPhysicsColliderTriMesh] 
  updateInstanceTriMesh(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsColliderTriMesh] 
  createInstanceGravity(object) - Rapier implementation for [R3EventObjComponentPhysicsGravity] 
  deleteInstanceGravity(object) - Rapier implementation for [R3EventObjComponentPhysicsGravity] 
  updateInstanceGravity(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsGravity] 
  createInstanceJoint(object) - Rapier implementation for [R3EventObjComponentPhysicsJoint] 
  deleteInstanceJoint(object) - Rapier implementation for [R3EventObjComponentPhysicsJoint] 
  updateInstanceJoint(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsJoint] 
  createInstanceFixedJoint(object) - Rapier implementation for [R3EventObjComponentPhysicsJointFixed] 
  deleteInstanceFixedJoint(object) - Rapier implementation for [R3EventObjComponentPhysicsJointFixed] 
  updateInstanceFixedJoint(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsJointFixed] 
  createInstancePrismatic(object) - Rapier implementation for [R3EventObjComponentPhysicsJointPrismatic] 
  deleteInstancePrismatic(object) - Rapier implementation for [R3EventObjComponentPhysicsJointPrismatic] 
  updateInstancePrismatic(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsJointPrismatic] 
  createInstanceRevolute(object) - Rapier implementation for [R3EventObjComponentPhysicsJointRevolute] 
  deleteInstanceRevolute(object) - Rapier implementation for [R3EventObjComponentPhysicsJointRevolute] 
  updateInstanceRevolute(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsJointRevolute] 
  createInstanceSpherical(object) - Rapier implementation for [R3EventObjComponentPhysicsJointSpherical] 
  deleteInstanceSpherical(object) - Rapier implementation for [R3EventObjComponentPhysicsJointSpherical] 
  updateInstanceSpherical(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsJointSpherical] 
  createInstanceRigidBody(object) - Rapier implementation for [R3EventObjComponentPhysicsRigidBody] 
  deleteInstanceRigidBody(object) - Rapier implementation for [R3EventObjComponentPhysicsRigidBody] 
  updateInstanceRigidBody(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsRigidBody] 
  createInstanceDynamic(object) - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyDynamic] 
  deleteInstanceDynamic(object) - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyDynamic] 
  updateInstanceDynamic(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyDynamic] 
  createInstanceFixedBody(object) - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyFixed] 
  deleteInstanceFixedBody(object) - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyFixed] 
  updateInstanceFixedBody(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyFixed] 
  createInstanceWorld(object) - Rapier implementation for [R3EventObjComponentPhysicsWorld] 
  deleteInstanceWorld(object) - Rapier implementation for [R3EventObjComponentPhysicsWorld] 
  updateInstanceWorld(object, property, value) - Rapier implementation for [R3EventObjComponentPhysicsWorld] 
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Rapier extends Physics {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimePhysics';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimePhysicsRapier';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Rapier';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_PHYSICS;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  /**
   * step()
   * - Step the world physics by delta
   * @param world
   * @param delta
   * - No returns
   */
  step(world, delta) {

    //CUSTOM_STEP_BEFORE_START
    //CUSTOM_STEP_BEFORE_END

    //GENERATED_STEP_BEFORE_START
    //GENERATED_STEP_BEFORE_END

    //CUSTOM_STEP_BEFORE_GENERATED_START
    //CUSTOM_STEP_BEFORE_GENERATED_END

    //GENERATED_STEP_START
    //GENERATED_STEP_END

    //CUSTOM_STEP_START
    let instance = world.getInstance(this);
    if (!instance) {
      return;
    }
    instance.timestep = delta;
    instance.step();
    //CUSTOM_STEP_END

    //GENERATED_STEP_AFTER_START
    //GENERATED_STEP_AFTER_END

  }

  /**
   * applyForce()
   * - Applies force vector to the rigidBody
   * @param rigidBody
   * @param vector
   * - No returns
   */
  applyForce(rigidBody, vector) {

    //CUSTOM_APPLY_FORCE_BEFORE_START
    //CUSTOM_APPLY_FORCE_BEFORE_END

    //GENERATED_APPLY_FORCE_BEFORE_START
    //GENERATED_APPLY_FORCE_BEFORE_END

    //CUSTOM_APPLY_FORCE_BEFORE_GENERATED_START
    //CUSTOM_APPLY_FORCE_BEFORE_GENERATED_END

    //GENERATED_APPLY_FORCE_START
    //GENERATED_APPLY_FORCE_END

    //CUSTOM_APPLY_FORCE_START
    let instance = rigidBody.getInstance(this);
    if (!instance) {
      Utils.Status(-1, `No rigid body instance to apply force`);
    }

    // let vectorInstance = vector.getInstance(Runtime.KEY_MATHS_THREE);
    // vectorInstance.normalize();

    instance.addForce(vector, true);
    //CUSTOM_APPLY_FORCE_END

    //GENERATED_APPLY_FORCE_AFTER_START
    //GENERATED_APPLY_FORCE_AFTER_END

  }

  /**
   * applyTorque()
   * - Applies torque vector to the rigidBody
   * @param rigidBody
   * @param vector
   * - No returns
   */
  applyTorque(rigidBody, vector) {

    //CUSTOM_APPLY_TORQUE_BEFORE_START
    //CUSTOM_APPLY_TORQUE_BEFORE_END

    //GENERATED_APPLY_TORQUE_BEFORE_START
    //GENERATED_APPLY_TORQUE_BEFORE_END

    //CUSTOM_APPLY_TORQUE_BEFORE_GENERATED_START
    //CUSTOM_APPLY_TORQUE_BEFORE_GENERATED_END

    //GENERATED_APPLY_TORQUE_START
    //GENERATED_APPLY_TORQUE_END

    //CUSTOM_APPLY_TORQUE_START
    let instance = rigidBody.getInstance(this);
    if (!instance) {
      Utils.Status(-1, `No rigid body instance to apply torque`);
    }

    // let vectorInstance = vector.getInstance(Runtime.KEY_MATHS_THREE);
    // vectorInstance.normalize();

    instance.addTorque(vector, true);
    //CUSTOM_APPLY_TORQUE_END

    //GENERATED_APPLY_TORQUE_AFTER_START
    //GENERATED_APPLY_TORQUE_AFTER_END

  }

  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  createInstance(object) {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_CREATE_INSTANCE_START
    super.createInstance(object);
    //CUSTOM_RETURN_GENERATED_CREATE_INSTANCE_END
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentPhysicsColliderBall'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_BALL_START
      return this.createInstanceBall(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_BALL_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderCapsule'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CAPSULE_START
      return this.createInstanceCapsule(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CAPSULE_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderCuboid'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CUBOID_START
      return this.createInstanceCuboid(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_CUBOID_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderHeightField'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_HEIGHT_FIELD_START
      return this.createInstanceHeightField(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_HEIGHT_FIELD_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderTriMesh'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_TRI_MESH_START
      return this.createInstanceTriMesh(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_TRI_MESH_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsGravity'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_GRAVITY_START
      return this.createInstanceGravity(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_GRAVITY_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJoint'
   || object.type === 'R3EventObjComponentPhysicsJointSpherical'
   || object.type === 'R3EventObjComponentPhysicsJointRevolute'
   || object.type === 'R3EventObjComponentPhysicsJointPrismatic'
   || object.type === 'R3EventObjComponentPhysicsJointFixed'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_JOINT_START
      return this.createInstanceJoint(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_JOINT_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointFixed'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_FIXED_JOINT_START
      return this.createInstanceFixedJoint(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_FIXED_JOINT_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointPrismatic'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_PRISMATIC_START
      return this.createInstancePrismatic(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_PRISMATIC_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointRevolute'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_REVOLUTE_START
      return this.createInstanceRevolute(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_REVOLUTE_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointSpherical'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SPHERICAL_START
      return this.createInstanceSpherical(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SPHERICAL_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsRigidBody'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyFixed'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyDynamic'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_RIGID_BODY_START
      this.createInstanceRigidBody(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_RIGID_BODY_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsRigidBodyDynamic'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_DYNAMIC_START
      return this.createInstanceDynamic(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_DYNAMIC_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsRigidBodyFixed'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_FIXED_BODY_START
      return this.createInstanceFixedBody(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_FIXED_BODY_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsWorld'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_WORLD_START
      return this.createInstanceWorld(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_WORLD_END
    }
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in createInstance() in runtime R3RuntimePhysicsRapier`);
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  deleteInstance(object) {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_DELETE_INSTANCE_START
    super.deleteInstance(object);
    //CUSTOM_RETURN_GENERATED_DELETE_INSTANCE_END
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentPhysicsColliderBall'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_BALL_START
      return this.deleteInstanceBall(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_BALL_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderCapsule'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CAPSULE_START
      return this.deleteInstanceCapsule(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CAPSULE_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderCuboid'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CUBOID_START
      return this.deleteInstanceCuboid(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_CUBOID_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderHeightField'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_HEIGHT_FIELD_START
      return this.deleteInstanceHeightField(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_HEIGHT_FIELD_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderTriMesh'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_TRI_MESH_START
      return this.deleteInstanceTriMesh(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_TRI_MESH_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsGravity'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_GRAVITY_START
      return this.deleteInstanceGravity(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_GRAVITY_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJoint'
   || object.type === 'R3EventObjComponentPhysicsJointSpherical'
   || object.type === 'R3EventObjComponentPhysicsJointRevolute'
   || object.type === 'R3EventObjComponentPhysicsJointPrismatic'
   || object.type === 'R3EventObjComponentPhysicsJointFixed'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_JOINT_START
      return this.deleteInstanceJoint(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_JOINT_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointFixed'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_FIXED_JOINT_START
      return this.deleteInstanceFixedJoint(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_FIXED_JOINT_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointPrismatic'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_PRISMATIC_START
      return this.deleteInstancePrismatic(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_PRISMATIC_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointRevolute'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_REVOLUTE_START
      return this.deleteInstanceRevolute(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_REVOLUTE_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointSpherical'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SPHERICAL_START
      return this.deleteInstanceSpherical(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SPHERICAL_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsRigidBody'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyFixed'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyDynamic'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_RIGID_BODY_START
      this.deleteInstanceRigidBody(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_RIGID_BODY_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsRigidBodyDynamic'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_DYNAMIC_START
      return this.deleteInstanceDynamic(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_DYNAMIC_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsRigidBodyFixed'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_FIXED_BODY_START
      return this.deleteInstanceFixedBody(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_FIXED_BODY_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsWorld'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_WORLD_START
      return this.deleteInstanceWorld(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_WORLD_END
    }
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in deleteInstance() in runtime R3RuntimePhysicsRapier`);
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(object, property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_UPDATE_INSTANCE_START
    let updated = super.updateInstance(object, property, value);
    if (updated) {
      return;
    }
    //CUSTOM_RETURN_GENERATED_UPDATE_INSTANCE_END
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentPhysicsColliderBall'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_BALL_START
      return this.updateInstanceBall(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_BALL_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderCapsule'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CAPSULE_START
      return this.updateInstanceCapsule(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CAPSULE_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderCuboid'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CUBOID_START
      return this.updateInstanceCuboid(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_CUBOID_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderHeightField'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_HEIGHT_FIELD_START
      return this.updateInstanceHeightField(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_HEIGHT_FIELD_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsColliderTriMesh'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_TRI_MESH_START
      return this.updateInstanceTriMesh(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_TRI_MESH_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsGravity'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_GRAVITY_START
      return this.updateInstanceGravity(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_GRAVITY_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJoint'
   || object.type === 'R3EventObjComponentPhysicsJointSpherical'
   || object.type === 'R3EventObjComponentPhysicsJointRevolute'
   || object.type === 'R3EventObjComponentPhysicsJointPrismatic'
   || object.type === 'R3EventObjComponentPhysicsJointFixed'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_JOINT_START
      return this.updateInstanceJoint(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_JOINT_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointFixed'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_FIXED_JOINT_START
      return this.updateInstanceFixedJoint(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_FIXED_JOINT_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointPrismatic'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_PRISMATIC_START
      return this.updateInstancePrismatic(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_PRISMATIC_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointRevolute'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_REVOLUTE_START
      return this.updateInstanceRevolute(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_REVOLUTE_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsJointSpherical'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SPHERICAL_START
      return this.updateInstanceSpherical(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SPHERICAL_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsRigidBody'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyFixed'
   || object.type === 'R3EventObjComponentPhysicsRigidBodyDynamic'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_RIGID_BODY_START
      updated = this.updateInstanceRigidBody(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_RIGID_BODY_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsRigidBodyDynamic'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_DYNAMIC_START
      if (updated) {
        return;
      }
      return this.updateInstanceDynamic(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_DYNAMIC_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsRigidBodyFixed'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_FIXED_BODY_START
      if (updated) {
        return;
      }
      return this.updateInstanceFixedBody(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_FIXED_BODY_END
    }

    if (
      object.type === 'R3EventObjComponentPhysicsWorld'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_WORLD_START
      return this.updateInstanceWorld(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_WORLD_END
    }
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in updateInstance() in runtime R3RuntimePhysicsRapier`);
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  /**
   * createInstanceBall()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderBall]
   * @param object
   * - No returns
   */
  createInstanceBall(object) {

    //GENERATED_CREATE_INSTANCE_BALL_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BALL_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BALL_START
    //GENERATED_CREATE_INSTANCE_BALL_END

    //CUSTOM_CREATE_INSTANCE_BALL_START
    throw new Error(`Please implement Rapier.createInstanceBall(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_CREATE_INSTANCE_BALL_END

    //GENERATED_CREATE_INSTANCE_BALL_AFTER_START
    //GENERATED_CREATE_INSTANCE_BALL_AFTER_END

  }

  /**
   * deleteInstanceBall()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderBall]
   * @param object
   * - No returns
   */
  deleteInstanceBall(object) {

    //GENERATED_DELETE_INSTANCE_BALL_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BALL_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BALL_START
    //GENERATED_DELETE_INSTANCE_BALL_END

    //CUSTOM_DELETE_INSTANCE_BALL_START
    throw new Error(`Please implement Rapier.deleteInstanceBall(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_DELETE_INSTANCE_BALL_END

    //GENERATED_DELETE_INSTANCE_BALL_AFTER_START
    //GENERATED_DELETE_INSTANCE_BALL_AFTER_END

  }

  /**
   * updateInstanceBall()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderBall]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceBall(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_BALL_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BALL_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BALL_START
    //GENERATED_UPDATE_INSTANCE_BALL_END

    //CUSTOM_UPDATE_INSTANCE_BALL_START
    throw new Error(`Please implement Rapier.updateInstanceBall(object, property, value) in R3RuntimePhysicsRapier`);
    //CUSTOM_UPDATE_INSTANCE_BALL_END

    //GENERATED_UPDATE_INSTANCE_BALL_AFTER_START
    //GENERATED_UPDATE_INSTANCE_BALL_AFTER_END

  }

  /**
   * createInstanceCapsule()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderCapsule]
   * @param object
   * - No returns
   */
  createInstanceCapsule(object) {

    //GENERATED_CREATE_INSTANCE_CAPSULE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_CAPSULE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_CAPSULE_START
    //GENERATED_CREATE_INSTANCE_CAPSULE_END

    //CUSTOM_CREATE_INSTANCE_CAPSULE_START
    throw new Error(`Please implement Rapier.createInstanceCapsule(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_CREATE_INSTANCE_CAPSULE_END

    //GENERATED_CREATE_INSTANCE_CAPSULE_AFTER_START
    //GENERATED_CREATE_INSTANCE_CAPSULE_AFTER_END

  }

  /**
   * deleteInstanceCapsule()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderCapsule]
   * @param object
   * - No returns
   */
  deleteInstanceCapsule(object) {

    //GENERATED_DELETE_INSTANCE_CAPSULE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_CAPSULE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_CAPSULE_START
    //GENERATED_DELETE_INSTANCE_CAPSULE_END

    //CUSTOM_DELETE_INSTANCE_CAPSULE_START
    throw new Error(`Please implement Rapier.deleteInstanceCapsule(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_DELETE_INSTANCE_CAPSULE_END

    //GENERATED_DELETE_INSTANCE_CAPSULE_AFTER_START
    //GENERATED_DELETE_INSTANCE_CAPSULE_AFTER_END

  }

  /**
   * updateInstanceCapsule()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderCapsule]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceCapsule(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_CAPSULE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_CAPSULE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_CAPSULE_START
    //GENERATED_UPDATE_INSTANCE_CAPSULE_END

    //CUSTOM_UPDATE_INSTANCE_CAPSULE_START
    throw new Error(`Please implement Rapier.updateInstanceCapsule(object, property, value) in R3RuntimePhysicsRapier`);
    //CUSTOM_UPDATE_INSTANCE_CAPSULE_END

    //GENERATED_UPDATE_INSTANCE_CAPSULE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_CAPSULE_AFTER_END

  }

  /**
   * createInstanceCuboid()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderCuboid]
   * @param object
   * - No returns
   */
  createInstanceCuboid(object) {

    //GENERATED_CREATE_INSTANCE_CUBOID_BEFORE_START
    //GENERATED_CREATE_INSTANCE_CUBOID_BEFORE_END

    //GENERATED_CREATE_INSTANCE_CUBOID_START
    //GENERATED_CREATE_INSTANCE_CUBOID_END

    //CUSTOM_CREATE_INSTANCE_CUBOID_START
    let {rigidBody} = object;

    let mesh;

    if (rigidBody) {
      mesh = rigidBody.mesh;
    } else {
      mesh = object.mesh;
      mesh.useQuaternion = true;
    }

    let {width : x, height : y, depth : z} = mesh;

    let hx = x/2;
    let hy = y/2;
    let hz = z/2;

    let colliderDesc = RAPIER.ColliderDesc.cuboid(hx, hy, hz);

    let world = object.world.getInstance(this);

    let instance;

    let position;

    // let quaternion;

    if (rigidBody) {

      rigidBody = object.rigidBody.getInstance(this);

      instance = world.createCollider(colliderDesc, rigidBody);

      position = object.position;

      instance.setTranslationWrtParent(
        {
          x : position.x,
          y : position.y,
          z : position.z
        }
      );

    } else {

      position = mesh.position;

      instance = world.createCollider(colliderDesc);

      instance.setTranslation(
        {
          x : position.x,
          y : position.y,
          z : position.z
        }
      );

    }

    instance.setRotation({w:1, x:0, y:0, z:0});

    instance.setRestitution(object.restitution);

    instance.setDensity(object.density);

    instance.setFriction(object.friction);

    instance.setSensor(object.sensor);

    object.updateFromCreateCuboidInstance = true;
    object.hx = hx;
    object.hy = hy;
    object.hz = hz;
    object.position.x = position.x;
    object.position.y = position.y;
    object.position.z = position.z;
    object.updateFromCreateCuboidInstance = false;

    return instance;
    //CUSTOM_CREATE_INSTANCE_CUBOID_END

    //GENERATED_CREATE_INSTANCE_CUBOID_AFTER_START
    //GENERATED_CREATE_INSTANCE_CUBOID_AFTER_END

  }

  /**
   * deleteInstanceCuboid()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderCuboid]
   * @param object
   * - No returns
   */
  deleteInstanceCuboid(object) {

    //GENERATED_DELETE_INSTANCE_CUBOID_BEFORE_START
    //GENERATED_DELETE_INSTANCE_CUBOID_BEFORE_END

    //GENERATED_DELETE_INSTANCE_CUBOID_START
    //GENERATED_DELETE_INSTANCE_CUBOID_END

    //CUSTOM_DELETE_INSTANCE_CUBOID_START
    let world = object.world.getInstance(this);
    let instance = object.getInstance(this);
    world.removeCollider(instance.handle);
    return null;
    //CUSTOM_DELETE_INSTANCE_CUBOID_END

    //GENERATED_DELETE_INSTANCE_CUBOID_AFTER_START
    //GENERATED_DELETE_INSTANCE_CUBOID_AFTER_END

  }

  /**
   * updateInstanceCuboid()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderCuboid]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceCuboid(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_CUBOID_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_CUBOID_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_CUBOID_START
    //GENERATED_UPDATE_INSTANCE_CUBOID_END

    //CUSTOM_UPDATE_INSTANCE_CUBOID_START
    if (object.updateFromCreateCuboidInstance) {
      return;
    }

    let instance = object.getInstance(this);

    if (!instance) {
      Utils.Status(-1, "Physics instance went away");
      return;
    }

    if (
      property === 'hx' ||
      property === 'hy' ||
      property === 'hz'
    ) {
      instance.setHalfExtents({x : object.hx, y : object.hy, z : object.hz});
      return;
    }

    if (property === 'restitution') {
      instance.setRestitution(value);
      return;
    }

    if (property === 'density') {
      instance.setDensity(value);
      return;
    }

    if (property === 'friction') {
      instance.setFriction(value);
      return;
    }

    if (property === 'sensor') {
      instance.setSensor(value);
      return;
    }


    if (property === 'mesh') {

      /**
       * Destroy and re-create the instance
       */
      console.warn(`Please implement Rapier.updateInstanceCuboid() in R3RuntimePhysicsRapier for ${property}`);
      return;
      // let {mesh} = object;
      // if (mesh.updateFromCuboid) {
      //   return;
      // }
      //
      // let {width : x, height : y, depth : z, position} = mesh;
      //
      // object.hx = x/2;
      // object.hy = y/2;
      // object.hz = z/2;
      //
      // object.position.x = position.x;
      // object.position.y = position.y;
      // object.position.z = position.z;

      // return;
    }

    if (property === 'rigidBody') {
      /**
       * Not in use
       */
      console.warn(`Please implement Rapier.updateInstanceCuboid() in R3RuntimePhysicsRapier for ${property}`);
      return;
      //
      // object.mesh.updateFromCuboid = true;
      // object.mesh.position.x = object.rigidBody.position.x;
      // object.mesh.position.y = object.rigidBody.position.y;
      // object.mesh.position.z = object.rigidBody.position.z;
      //
      // let quaternionInstance = object.rigidBody.rotation.getInstance(Runtime.KEY_MATHS_THREE);
      //
      // quaternionInstance.normalize();
      //
      // // this.x = 0;
      // // this.y = 0;
      // // this.z = 1;
      //
      // // let vectorInstance = this.getInstance(Runtime.KEY_MATHS_THREE).clone();
      //
      // // vectorInstance.applyQuaternion( quaternionInstance.normalize() );
      //
      // // this.x = vectorInstance.x;
      // // this.y = vectorInstance.y;
      // // this.z = vectorInstance.z;
      //
      // object.mesh.getInstance(Runtime.KEY_GRAPHICS_THREE).setRotationFromQuaternion(quaternionInstance);
      //
      // delete object.mesh.updateFromCuboid;
      // return;
    }

    /**
     * A collider position is relative to its parent - so it should be modified as its own property
     */
    if (property === 'position') {
      let {x, y, z} = value;
      let {rigidBody} = object;

      if (rigidBody) {
        instance.setTranslationWrtParent({x, y, z});
      } else {
        instance.setTranslation({x, y, z});
      }
      return;
    }

    if (property === 'rotation') {
      let {x, y, z, w} = value;
      let {rigidBody} = object;
      if (rigidBody) {
        instance.setRotationWrtParent({w, x, y, z});
      } else {
        instance.setRotation({w, x, y, z});
      }
      return;

    }

    throw new Error(`Please implement Rapier.updateInstanceCuboid() in R3RuntimePhysicsRapier for ${property}`);
    //CUSTOM_UPDATE_INSTANCE_CUBOID_END

    //GENERATED_UPDATE_INSTANCE_CUBOID_AFTER_START
    //GENERATED_UPDATE_INSTANCE_CUBOID_AFTER_END

  }

  /**
   * createInstanceHeightField()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderHeightField]
   * @param object
   * - No returns
   */
  createInstanceHeightField(object) {

    //GENERATED_CREATE_INSTANCE_HEIGHT_FIELD_BEFORE_START
    //GENERATED_CREATE_INSTANCE_HEIGHT_FIELD_BEFORE_END

    //GENERATED_CREATE_INSTANCE_HEIGHT_FIELD_START
    //GENERATED_CREATE_INSTANCE_HEIGHT_FIELD_END

    //CUSTOM_CREATE_INSTANCE_HEIGHT_FIELD_START
    throw new Error(`Please implement Rapier.createInstanceHeightField(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_CREATE_INSTANCE_HEIGHT_FIELD_END

    //GENERATED_CREATE_INSTANCE_HEIGHT_FIELD_AFTER_START
    //GENERATED_CREATE_INSTANCE_HEIGHT_FIELD_AFTER_END

  }

  /**
   * deleteInstanceHeightField()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderHeightField]
   * @param object
   * - No returns
   */
  deleteInstanceHeightField(object) {

    //GENERATED_DELETE_INSTANCE_HEIGHT_FIELD_BEFORE_START
    //GENERATED_DELETE_INSTANCE_HEIGHT_FIELD_BEFORE_END

    //GENERATED_DELETE_INSTANCE_HEIGHT_FIELD_START
    //GENERATED_DELETE_INSTANCE_HEIGHT_FIELD_END

    //CUSTOM_DELETE_INSTANCE_HEIGHT_FIELD_START
    throw new Error(`Please implement Rapier.deleteInstanceHeightField(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_DELETE_INSTANCE_HEIGHT_FIELD_END

    //GENERATED_DELETE_INSTANCE_HEIGHT_FIELD_AFTER_START
    //GENERATED_DELETE_INSTANCE_HEIGHT_FIELD_AFTER_END

  }

  /**
   * updateInstanceHeightField()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderHeightField]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceHeightField(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_HEIGHT_FIELD_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_HEIGHT_FIELD_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_HEIGHT_FIELD_START
    //GENERATED_UPDATE_INSTANCE_HEIGHT_FIELD_END

    //CUSTOM_UPDATE_INSTANCE_HEIGHT_FIELD_START
    throw new Error(`Please implement Rapier.updateInstanceHeightField(object, property, value) in R3RuntimePhysicsRapier`);
    //CUSTOM_UPDATE_INSTANCE_HEIGHT_FIELD_END

    //GENERATED_UPDATE_INSTANCE_HEIGHT_FIELD_AFTER_START
    //GENERATED_UPDATE_INSTANCE_HEIGHT_FIELD_AFTER_END

  }

  /**
   * createInstanceTriMesh()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderTriMesh]
   * @param object
   * - No returns
   */
  createInstanceTriMesh(object) {

    //GENERATED_CREATE_INSTANCE_TRI_MESH_BEFORE_START
    //GENERATED_CREATE_INSTANCE_TRI_MESH_BEFORE_END

    //GENERATED_CREATE_INSTANCE_TRI_MESH_START
    //GENERATED_CREATE_INSTANCE_TRI_MESH_END

    //CUSTOM_CREATE_INSTANCE_TRI_MESH_START
    throw new Error(`Please implement Rapier.createInstanceTriMesh(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_CREATE_INSTANCE_TRI_MESH_END

    //GENERATED_CREATE_INSTANCE_TRI_MESH_AFTER_START
    //GENERATED_CREATE_INSTANCE_TRI_MESH_AFTER_END

  }

  /**
   * deleteInstanceTriMesh()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderTriMesh]
   * @param object
   * - No returns
   */
  deleteInstanceTriMesh(object) {

    //GENERATED_DELETE_INSTANCE_TRI_MESH_BEFORE_START
    //GENERATED_DELETE_INSTANCE_TRI_MESH_BEFORE_END

    //GENERATED_DELETE_INSTANCE_TRI_MESH_START
    //GENERATED_DELETE_INSTANCE_TRI_MESH_END

    //CUSTOM_DELETE_INSTANCE_TRI_MESH_START
    throw new Error(`Please implement Rapier.deleteInstanceTriMesh(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_DELETE_INSTANCE_TRI_MESH_END

    //GENERATED_DELETE_INSTANCE_TRI_MESH_AFTER_START
    //GENERATED_DELETE_INSTANCE_TRI_MESH_AFTER_END

  }

  /**
   * updateInstanceTriMesh()
   * - Rapier implementation for [R3EventObjComponentPhysicsColliderTriMesh]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceTriMesh(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_TRI_MESH_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_TRI_MESH_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_TRI_MESH_START
    //GENERATED_UPDATE_INSTANCE_TRI_MESH_END

    //CUSTOM_UPDATE_INSTANCE_TRI_MESH_START
    throw new Error(`Please implement Rapier.updateInstanceTriMesh(object, property, value) in R3RuntimePhysicsRapier`);
    //CUSTOM_UPDATE_INSTANCE_TRI_MESH_END

    //GENERATED_UPDATE_INSTANCE_TRI_MESH_AFTER_START
    //GENERATED_UPDATE_INSTANCE_TRI_MESH_AFTER_END

  }

  /**
   * createInstanceGravity()
   * - Rapier implementation for [R3EventObjComponentPhysicsGravity]
   * @param object
   * - No returns
   */
  createInstanceGravity(object) {

    //GENERATED_CREATE_INSTANCE_GRAVITY_BEFORE_START
    //GENERATED_CREATE_INSTANCE_GRAVITY_BEFORE_END

    //GENERATED_CREATE_INSTANCE_GRAVITY_START
    //GENERATED_CREATE_INSTANCE_GRAVITY_END

    //CUSTOM_CREATE_INSTANCE_GRAVITY_START
    let {x, y, z} = object;

    if (isNaN(x)) {
      console.warn(`Gravity x is NaN`);
      return null;
    }

    if (isNaN(y)) {
      console.warn(`Gravity y is NaN`);
      return null;
    }

    if (isNaN(z)) {
      console.warn(`Gravity z is NaN`);
      return null;
    }

    return { x, y, z };
    //CUSTOM_CREATE_INSTANCE_GRAVITY_END

    //GENERATED_CREATE_INSTANCE_GRAVITY_AFTER_START
    //GENERATED_CREATE_INSTANCE_GRAVITY_AFTER_END

  }

  /**
   * deleteInstanceGravity()
   * - Rapier implementation for [R3EventObjComponentPhysicsGravity]
   * @param object
   * - No returns
   */
  deleteInstanceGravity(object) {

    //GENERATED_DELETE_INSTANCE_GRAVITY_BEFORE_START
    //GENERATED_DELETE_INSTANCE_GRAVITY_BEFORE_END

    //GENERATED_DELETE_INSTANCE_GRAVITY_START
    //GENERATED_DELETE_INSTANCE_GRAVITY_END

    //CUSTOM_DELETE_INSTANCE_GRAVITY_START
    return null;
    //CUSTOM_DELETE_INSTANCE_GRAVITY_END

    //GENERATED_DELETE_INSTANCE_GRAVITY_AFTER_START
    //GENERATED_DELETE_INSTANCE_GRAVITY_AFTER_END

  }

  /**
   * updateInstanceGravity()
   * - Rapier implementation for [R3EventObjComponentPhysicsGravity]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceGravity(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_GRAVITY_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_GRAVITY_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_GRAVITY_START
    //GENERATED_UPDATE_INSTANCE_GRAVITY_END

    //CUSTOM_UPDATE_INSTANCE_GRAVITY_START
    let instance = object.getInstance(this);
    instance[property] = value;
    //CUSTOM_UPDATE_INSTANCE_GRAVITY_END

    //GENERATED_UPDATE_INSTANCE_GRAVITY_AFTER_START
    //GENERATED_UPDATE_INSTANCE_GRAVITY_AFTER_END

  }

  /**
   * createInstanceJoint()
   * - Rapier implementation for [R3EventObjComponentPhysicsJoint]
   * @param object
   * - No returns
   */
  createInstanceJoint(object) {

    //GENERATED_CREATE_INSTANCE_JOINT_BEFORE_START
    //GENERATED_CREATE_INSTANCE_JOINT_BEFORE_END

    //GENERATED_CREATE_INSTANCE_JOINT_START
    //GENERATED_CREATE_INSTANCE_JOINT_END

    //CUSTOM_CREATE_INSTANCE_JOINT_START
    throw new Error(`Please implement Rapier.createInstanceJoint(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_CREATE_INSTANCE_JOINT_END

    //GENERATED_CREATE_INSTANCE_JOINT_AFTER_START
    //GENERATED_CREATE_INSTANCE_JOINT_AFTER_END

  }

  /**
   * deleteInstanceJoint()
   * - Rapier implementation for [R3EventObjComponentPhysicsJoint]
   * @param object
   * - No returns
   */
  deleteInstanceJoint(object) {

    //GENERATED_DELETE_INSTANCE_JOINT_BEFORE_START
    //GENERATED_DELETE_INSTANCE_JOINT_BEFORE_END

    //GENERATED_DELETE_INSTANCE_JOINT_START
    //GENERATED_DELETE_INSTANCE_JOINT_END

    //CUSTOM_DELETE_INSTANCE_JOINT_START
    throw new Error(`Please implement Rapier.deleteInstanceJoint(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_DELETE_INSTANCE_JOINT_END

    //GENERATED_DELETE_INSTANCE_JOINT_AFTER_START
    //GENERATED_DELETE_INSTANCE_JOINT_AFTER_END

  }

  /**
   * updateInstanceJoint()
   * - Rapier implementation for [R3EventObjComponentPhysicsJoint]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceJoint(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_JOINT_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_JOINT_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_JOINT_START
    //GENERATED_UPDATE_INSTANCE_JOINT_END

    //CUSTOM_UPDATE_INSTANCE_JOINT_START
    throw new Error(`Please implement Rapier.updateInstanceJoint(object, property, value) in R3RuntimePhysicsRapier`);
    //CUSTOM_UPDATE_INSTANCE_JOINT_END

    //GENERATED_UPDATE_INSTANCE_JOINT_AFTER_START
    //GENERATED_UPDATE_INSTANCE_JOINT_AFTER_END

  }

  /**
   * createInstanceFixedJoint()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointFixed]
   * @param object
   * - No returns
   */
  createInstanceFixedJoint(object) {

    //GENERATED_CREATE_INSTANCE_FIXED_JOINT_BEFORE_START
    //GENERATED_CREATE_INSTANCE_FIXED_JOINT_BEFORE_END

    //GENERATED_CREATE_INSTANCE_FIXED_JOINT_START
    //GENERATED_CREATE_INSTANCE_FIXED_JOINT_END

    //CUSTOM_CREATE_INSTANCE_FIXED_JOINT_START
    throw new Error(`Please implement Rapier.createInstanceFixedJoint(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_CREATE_INSTANCE_FIXED_JOINT_END

    //GENERATED_CREATE_INSTANCE_FIXED_JOINT_AFTER_START
    //GENERATED_CREATE_INSTANCE_FIXED_JOINT_AFTER_END

  }

  /**
   * deleteInstanceFixedJoint()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointFixed]
   * @param object
   * - No returns
   */
  deleteInstanceFixedJoint(object) {

    //GENERATED_DELETE_INSTANCE_FIXED_JOINT_BEFORE_START
    //GENERATED_DELETE_INSTANCE_FIXED_JOINT_BEFORE_END

    //GENERATED_DELETE_INSTANCE_FIXED_JOINT_START
    //GENERATED_DELETE_INSTANCE_FIXED_JOINT_END

    //CUSTOM_DELETE_INSTANCE_FIXED_JOINT_START
    throw new Error(`Please implement Rapier.deleteInstanceFixedJoint(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_DELETE_INSTANCE_FIXED_JOINT_END

    //GENERATED_DELETE_INSTANCE_FIXED_JOINT_AFTER_START
    //GENERATED_DELETE_INSTANCE_FIXED_JOINT_AFTER_END

  }

  /**
   * updateInstanceFixedJoint()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointFixed]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceFixedJoint(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_FIXED_JOINT_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_FIXED_JOINT_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_FIXED_JOINT_START
    //GENERATED_UPDATE_INSTANCE_FIXED_JOINT_END

    //CUSTOM_UPDATE_INSTANCE_FIXED_JOINT_START
    throw new Error(`Please implement Rapier.updateInstanceFixedJoint(object, property, value) in R3RuntimePhysicsRapier`);
    //CUSTOM_UPDATE_INSTANCE_FIXED_JOINT_END

    //GENERATED_UPDATE_INSTANCE_FIXED_JOINT_AFTER_START
    //GENERATED_UPDATE_INSTANCE_FIXED_JOINT_AFTER_END

  }

  /**
   * createInstancePrismatic()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointPrismatic]
   * @param object
   * - No returns
   */
  createInstancePrismatic(object) {

    //GENERATED_CREATE_INSTANCE_PRISMATIC_BEFORE_START
    //GENERATED_CREATE_INSTANCE_PRISMATIC_BEFORE_END

    //GENERATED_CREATE_INSTANCE_PRISMATIC_START
    //GENERATED_CREATE_INSTANCE_PRISMATIC_END

    //CUSTOM_CREATE_INSTANCE_PRISMATIC_START
    throw new Error(`Please implement Rapier.createInstancePrismatic(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_CREATE_INSTANCE_PRISMATIC_END

    //GENERATED_CREATE_INSTANCE_PRISMATIC_AFTER_START
    //GENERATED_CREATE_INSTANCE_PRISMATIC_AFTER_END

  }

  /**
   * deleteInstancePrismatic()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointPrismatic]
   * @param object
   * - No returns
   */
  deleteInstancePrismatic(object) {

    //GENERATED_DELETE_INSTANCE_PRISMATIC_BEFORE_START
    //GENERATED_DELETE_INSTANCE_PRISMATIC_BEFORE_END

    //GENERATED_DELETE_INSTANCE_PRISMATIC_START
    //GENERATED_DELETE_INSTANCE_PRISMATIC_END

    //CUSTOM_DELETE_INSTANCE_PRISMATIC_START
    throw new Error(`Please implement Rapier.deleteInstancePrismatic(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_DELETE_INSTANCE_PRISMATIC_END

    //GENERATED_DELETE_INSTANCE_PRISMATIC_AFTER_START
    //GENERATED_DELETE_INSTANCE_PRISMATIC_AFTER_END

  }

  /**
   * updateInstancePrismatic()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointPrismatic]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstancePrismatic(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_PRISMATIC_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_PRISMATIC_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_PRISMATIC_START
    //GENERATED_UPDATE_INSTANCE_PRISMATIC_END

    //CUSTOM_UPDATE_INSTANCE_PRISMATIC_START
    throw new Error(`Please implement Rapier.updateInstancePrismatic(object, property, value) in R3RuntimePhysicsRapier`);
    //CUSTOM_UPDATE_INSTANCE_PRISMATIC_END

    //GENERATED_UPDATE_INSTANCE_PRISMATIC_AFTER_START
    //GENERATED_UPDATE_INSTANCE_PRISMATIC_AFTER_END

  }

  /**
   * createInstanceRevolute()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointRevolute]
   * @param object
   * - No returns
   */
  createInstanceRevolute(object) {

    //GENERATED_CREATE_INSTANCE_REVOLUTE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_REVOLUTE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_REVOLUTE_START
    //GENERATED_CREATE_INSTANCE_REVOLUTE_END

    //CUSTOM_CREATE_INSTANCE_REVOLUTE_START
    throw new Error(`Please implement Rapier.createInstanceRevolute(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_CREATE_INSTANCE_REVOLUTE_END

    //GENERATED_CREATE_INSTANCE_REVOLUTE_AFTER_START
    //GENERATED_CREATE_INSTANCE_REVOLUTE_AFTER_END

  }

  /**
   * deleteInstanceRevolute()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointRevolute]
   * @param object
   * - No returns
   */
  deleteInstanceRevolute(object) {

    //GENERATED_DELETE_INSTANCE_REVOLUTE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_REVOLUTE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_REVOLUTE_START
    //GENERATED_DELETE_INSTANCE_REVOLUTE_END

    //CUSTOM_DELETE_INSTANCE_REVOLUTE_START
    throw new Error(`Please implement Rapier.deleteInstanceRevolute(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_DELETE_INSTANCE_REVOLUTE_END

    //GENERATED_DELETE_INSTANCE_REVOLUTE_AFTER_START
    //GENERATED_DELETE_INSTANCE_REVOLUTE_AFTER_END

  }

  /**
   * updateInstanceRevolute()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointRevolute]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceRevolute(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_REVOLUTE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_REVOLUTE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_REVOLUTE_START
    //GENERATED_UPDATE_INSTANCE_REVOLUTE_END

    //CUSTOM_UPDATE_INSTANCE_REVOLUTE_START
    throw new Error(`Please implement Rapier.updateInstanceRevolute(object, property, value) in R3RuntimePhysicsRapier`);
    //CUSTOM_UPDATE_INSTANCE_REVOLUTE_END

    //GENERATED_UPDATE_INSTANCE_REVOLUTE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_REVOLUTE_AFTER_END

  }

  /**
   * createInstanceSpherical()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointSpherical]
   * @param object
   * - No returns
   */
  createInstanceSpherical(object) {

    //GENERATED_CREATE_INSTANCE_SPHERICAL_BEFORE_START
    //GENERATED_CREATE_INSTANCE_SPHERICAL_BEFORE_END

    //GENERATED_CREATE_INSTANCE_SPHERICAL_START
    //GENERATED_CREATE_INSTANCE_SPHERICAL_END

    //CUSTOM_CREATE_INSTANCE_SPHERICAL_START
    throw new Error(`Please implement Rapier.createInstanceSpherical(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_CREATE_INSTANCE_SPHERICAL_END

    //GENERATED_CREATE_INSTANCE_SPHERICAL_AFTER_START
    //GENERATED_CREATE_INSTANCE_SPHERICAL_AFTER_END

  }

  /**
   * deleteInstanceSpherical()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointSpherical]
   * @param object
   * - No returns
   */
  deleteInstanceSpherical(object) {

    //GENERATED_DELETE_INSTANCE_SPHERICAL_BEFORE_START
    //GENERATED_DELETE_INSTANCE_SPHERICAL_BEFORE_END

    //GENERATED_DELETE_INSTANCE_SPHERICAL_START
    //GENERATED_DELETE_INSTANCE_SPHERICAL_END

    //CUSTOM_DELETE_INSTANCE_SPHERICAL_START
    throw new Error(`Please implement Rapier.deleteInstanceSpherical(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_DELETE_INSTANCE_SPHERICAL_END

    //GENERATED_DELETE_INSTANCE_SPHERICAL_AFTER_START
    //GENERATED_DELETE_INSTANCE_SPHERICAL_AFTER_END

  }

  /**
   * updateInstanceSpherical()
   * - Rapier implementation for [R3EventObjComponentPhysicsJointSpherical]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceSpherical(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_SPHERICAL_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_SPHERICAL_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_SPHERICAL_START
    //GENERATED_UPDATE_INSTANCE_SPHERICAL_END

    //CUSTOM_UPDATE_INSTANCE_SPHERICAL_START
    throw new Error(`Please implement Rapier.updateInstanceSpherical(object, property, value) in R3RuntimePhysicsRapier`);
    //CUSTOM_UPDATE_INSTANCE_SPHERICAL_END

    //GENERATED_UPDATE_INSTANCE_SPHERICAL_AFTER_START
    //GENERATED_UPDATE_INSTANCE_SPHERICAL_AFTER_END

  }

  /**
   * createInstanceRigidBody()
   * - Rapier implementation for [R3EventObjComponentPhysicsRigidBody]
   * @param object
   * - No returns
   */
  createInstanceRigidBody(object) {

    //GENERATED_CREATE_INSTANCE_RIGID_BODY_BEFORE_START
    //GENERATED_CREATE_INSTANCE_RIGID_BODY_BEFORE_END

    //GENERATED_CREATE_INSTANCE_RIGID_BODY_START
    //GENERATED_CREATE_INSTANCE_RIGID_BODY_END

    //CUSTOM_CREATE_INSTANCE_RIGID_BODY_START
    /**
     * Do nothing
     */
    //CUSTOM_CREATE_INSTANCE_RIGID_BODY_END

    //GENERATED_CREATE_INSTANCE_RIGID_BODY_AFTER_START
    //GENERATED_CREATE_INSTANCE_RIGID_BODY_AFTER_END

  }

  /**
   * deleteInstanceRigidBody()
   * - Rapier implementation for [R3EventObjComponentPhysicsRigidBody]
   * @param object
   * - No returns
   */
  deleteInstanceRigidBody(object) {

    //GENERATED_DELETE_INSTANCE_RIGID_BODY_BEFORE_START
    //GENERATED_DELETE_INSTANCE_RIGID_BODY_BEFORE_END

    //GENERATED_DELETE_INSTANCE_RIGID_BODY_START
    //GENERATED_DELETE_INSTANCE_RIGID_BODY_END

    //CUSTOM_DELETE_INSTANCE_RIGID_BODY_START
    /**
     * Do Nothing
     */
    //CUSTOM_DELETE_INSTANCE_RIGID_BODY_END

    //GENERATED_DELETE_INSTANCE_RIGID_BODY_AFTER_START
    //GENERATED_DELETE_INSTANCE_RIGID_BODY_AFTER_END

  }

  /**
   * updateInstanceRigidBody()
   * - Rapier implementation for [R3EventObjComponentPhysicsRigidBody]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceRigidBody(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_RIGID_BODY_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_RIGID_BODY_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_RIGID_BODY_START
    //GENERATED_UPDATE_INSTANCE_RIGID_BODY_END

    //CUSTOM_UPDATE_INSTANCE_RIGID_BODY_START
    if (
      object.createInstanceUpdate ||
      object.updateInstanceUpdate ||
      object.updateFromSystem
    ) {
      return true;
    }

    let instance = object.getInstance(this);

    if (
      property === 'position'
    ) {
      let {x, y, z} = value;
      instance.setTranslation({x, y, z}, true);
      return true;
    }

    if (
      property === 'quaternion'
    ) {
      let {x, y, z, w} = value;
      instance.setRotation({w, x, y, z});
      return true;
    }

    if (
      property === 'mesh'
    ) {

      let {position, quaternion} = object.mesh;

      instance.setTranslation(
        {
          x : position.x,
          y : position.y,
          z : position.z,
        },
        true
      );

      instance.setRotation(
        {
          w : quaternion.w,
          x : quaternion.x,
          y : quaternion.y,
          z : quaternion.z,
        }
      );

      object.updateInstanceUpdate = true;
      if (object.position.x !== position.x) {
        object.position.x = position.x;
      }

      if (object.position.y !== position.y) {
        object.position.y = position.y;
      }

      if (object.position.z !== position.z) {
        object.position.z = position.z;
      }

      if (object.quaternion.w !== quaternion.w) {
        object.quaternion.w = quaternion.w;
      }

      if (object.quaternion.x !== quaternion.x) {
        object.quaternion.x = quaternion.x;
      }

      if (object.quaternion.y !== quaternion.y) {
        object.quaternion.y = quaternion.y;
      }

      if (object.quaternion.z !== quaternion.z) {
        object.quaternion.z = quaternion.z;
      }
      object.updateInstanceUpdate = false;

      return true;
    }

    return false;
    //CUSTOM_UPDATE_INSTANCE_RIGID_BODY_END

    //GENERATED_UPDATE_INSTANCE_RIGID_BODY_AFTER_START
    //GENERATED_UPDATE_INSTANCE_RIGID_BODY_AFTER_END

  }

  /**
   * createInstanceDynamic()
   * - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyDynamic]
   * @param object
   * - No returns
   */
  createInstanceDynamic(object) {

    //GENERATED_CREATE_INSTANCE_DYNAMIC_BEFORE_START
    //GENERATED_CREATE_INSTANCE_DYNAMIC_BEFORE_END

    //GENERATED_CREATE_INSTANCE_DYNAMIC_START
    //GENERATED_CREATE_INSTANCE_DYNAMIC_END

    //CUSTOM_CREATE_INSTANCE_DYNAMIC_START
    let rigidBodyDesc = RAPIER.RigidBodyDesc.dynamic();
    let world = object.world.getInstance(this);
    let rigidBodyInstance = world.createRigidBody(rigidBodyDesc);

    let rigidBody = object;

    let {mesh} = rigidBody;

    mesh.useQuaternion = true;

    let {position, quaternion} = mesh;

    rigidBodyInstance.setTranslation(
      {
        x : position.x,
        y : position.y,
        z : position.z,
      },
      true
    );

    rigidBodyInstance.setRotation(
      {
        w : quaternion.w,
        x : quaternion.x,
        y : quaternion.y,
        z : quaternion.z,
      }
    );

    rigidBodyInstance.setGravityScale(object.gravityScale);

    if (
      object.enabledRotations.x === 0 ||
      object.enabledRotations.y === 0 ||
      object.enabledRotations.z === 0
    ) {
      rigidBodyInstance.lockRotations(true);
      rigidBodyInstance.setEnabledRotations(object.enabledRotations.x, object.enabledRotations.y, object.enabledRotations.z, true);
    } else {
      rigidBodyInstance.lockRotations(false, true);
    }

    if (
      object.enabledTranslations.x === 0 ||
      object.enabledTranslations.y === 0 ||
      object.enabledTranslations.z === 0
    ) {
      rigidBodyInstance.lockTranslations(true);
      rigidBodyInstance.setEnabledTranslations(object.enabledTranslations.x, object.enabledTranslations.y, object.enabledTranslations.z, true);
    } else {
      rigidBodyInstance.lockTranslations(false, true);
    }

    rigidBodyInstance.setLinvel(
      {
        x: object.linearVelocity.x,
        y: object.linearVelocity.y,
        z: object.linearVelocity.z
      },
      true
    );

    rigidBodyInstance.setAngvel(
      {
        x : object.angularVelocity.x,
        y : object.angularVelocity.y,
        z : object.angularVelocity.z
      },
      true
    );

    rigidBodyInstance.setLinearDamping(object.linearDamping);
    rigidBodyInstance.setAngularDamping(object.angularDamping);
    rigidBodyInstance.setDominanceGroup(object.dominanceGroup);
    rigidBodyInstance.enableCcd(object.continuousCollisionDetection);

    rigidBody.createInstanceUpdate = true;
    if (rigidBody.position.x !== position.x) {
      rigidBody.position.x = position.x;
    }

    if (rigidBody.position.y !== position.y) {
      rigidBody.position.y = position.y;
    }

    if (rigidBody.position.z !== position.z) {
      rigidBody.position.z = position.z;
    }

    if (rigidBody.quaternion.w !== quaternion.w) {
      rigidBody.quaternion.w = quaternion.w;
    }

    if (rigidBody.quaternion.x !== quaternion.x) {
      rigidBody.quaternion.x = quaternion.x;
    }

    if (rigidBody.quaternion.y !== quaternion.y) {
      rigidBody.quaternion.y = quaternion.y;
    }

    if (rigidBody.quaternion.z !== quaternion.z) {
      rigidBody.quaternion.z = quaternion.z;
    }
    rigidBody.createInstanceUpdate = false;

    return rigidBodyInstance;
    //CUSTOM_CREATE_INSTANCE_DYNAMIC_END

    //GENERATED_CREATE_INSTANCE_DYNAMIC_AFTER_START
    //GENERATED_CREATE_INSTANCE_DYNAMIC_AFTER_END

  }

  /**
   * deleteInstanceDynamic()
   * - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyDynamic]
   * @param object
   * - No returns
   */
  deleteInstanceDynamic(object) {

    //GENERATED_DELETE_INSTANCE_DYNAMIC_BEFORE_START
    //GENERATED_DELETE_INSTANCE_DYNAMIC_BEFORE_END

    //GENERATED_DELETE_INSTANCE_DYNAMIC_START
    //GENERATED_DELETE_INSTANCE_DYNAMIC_END

    //CUSTOM_DELETE_INSTANCE_DYNAMIC_START
    let world = object.world.getInstance(this);
    let instance = object.getInstance(this);
    world.removeRigidBody(instance);
    return null;
    //CUSTOM_DELETE_INSTANCE_DYNAMIC_END

    //GENERATED_DELETE_INSTANCE_DYNAMIC_AFTER_START
    //GENERATED_DELETE_INSTANCE_DYNAMIC_AFTER_END

  }

  /**
   * updateInstanceDynamic()
   * - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyDynamic]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceDynamic(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_DYNAMIC_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_DYNAMIC_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_DYNAMIC_START
    //GENERATED_UPDATE_INSTANCE_DYNAMIC_END

    //CUSTOM_UPDATE_INSTANCE_DYNAMIC_START
    if (
      property === 'forceVector' ||
      property === 'torqueVector' ||
      property === 'impulseVector'
    ) {
      return;
    }

    let instance = object.getInstance(this);

    if (!instance) {
      Utils.Status(-1, 'No Dynamic RigidBody instance to update');
      return;
    }

    if (property === 'gravityScale') {
      instance.setGravityScale(value);
      return;
    }

    if (property === 'enabledRotations') {
      let {x, y, z} = value;
      if (
        x === 0 ||
        y === 0 ||
        z === 0
      ) {
        instance.lockRotations(true);
        instance.setEnabledRotations(x, y, z, true);
      } else {
        instance.lockRotations(false, true);
      }
      return;
    }

    if (property === 'enabledTranslations') {
      let {x, y, z} = value;
      if (
        x === 0 ||
        y === 0 ||
        z === 0
      ) {
        instance.lockTranslations(true);
        instance.setEnabledTranslations(x, y, z, true);
      } else {
        instance.lockRotations(false, true);
      }
      return;
    }

    if (property === 'linearDamping') {
      instance.setLinearDamping(value);
      return;
    }

    if (property === 'angularDamping') {
      instance.setAngularDamping(value);
      return;
    }

    if (property === 'linearVelocity') {
      let {x, y, z} = value;
      instance.setLinvel({x, y, z});
      return;
    }

    if (property === 'angularVelocity') {
      let {x, y, z} = value;
      instance.setAngvel({x, y, z});
      return;
    }

    if (property === 'dominanceGroup') {
      instance.setDominanceGroup(value);
      return;
    }

    if (property === 'continuousCollisionDetection') {
      instance.enableCcd(value);
      return;
    }

    throw new Error(`Please implement Rapier.updateInstanceDynamic() in R3RuntimePhysicsRapier for ${property}`);
    //CUSTOM_UPDATE_INSTANCE_DYNAMIC_END

    //GENERATED_UPDATE_INSTANCE_DYNAMIC_AFTER_START
    //GENERATED_UPDATE_INSTANCE_DYNAMIC_AFTER_END

  }

  /**
   * createInstanceFixedBody()
   * - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyFixed]
   * @param object
   * - No returns
   */
  createInstanceFixedBody(object) {

    //GENERATED_CREATE_INSTANCE_FIXED_BODY_BEFORE_START
    //GENERATED_CREATE_INSTANCE_FIXED_BODY_BEFORE_END

    //GENERATED_CREATE_INSTANCE_FIXED_BODY_START
    //GENERATED_CREATE_INSTANCE_FIXED_BODY_END

    //CUSTOM_CREATE_INSTANCE_FIXED_BODY_START
    throw new Error(`Please implement Rapier.createInstanceFixedBody(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_CREATE_INSTANCE_FIXED_BODY_END

    //GENERATED_CREATE_INSTANCE_FIXED_BODY_AFTER_START
    //GENERATED_CREATE_INSTANCE_FIXED_BODY_AFTER_END

  }

  /**
   * deleteInstanceFixedBody()
   * - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyFixed]
   * @param object
   * - No returns
   */
  deleteInstanceFixedBody(object) {

    //GENERATED_DELETE_INSTANCE_FIXED_BODY_BEFORE_START
    //GENERATED_DELETE_INSTANCE_FIXED_BODY_BEFORE_END

    //GENERATED_DELETE_INSTANCE_FIXED_BODY_START
    //GENERATED_DELETE_INSTANCE_FIXED_BODY_END

    //CUSTOM_DELETE_INSTANCE_FIXED_BODY_START
    throw new Error(`Please implement Rapier.deleteInstanceFixedBody(object) in R3RuntimePhysicsRapier`);
    //CUSTOM_DELETE_INSTANCE_FIXED_BODY_END

    //GENERATED_DELETE_INSTANCE_FIXED_BODY_AFTER_START
    //GENERATED_DELETE_INSTANCE_FIXED_BODY_AFTER_END

  }

  /**
   * updateInstanceFixedBody()
   * - Rapier implementation for [R3EventObjComponentPhysicsRigidBodyFixed]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceFixedBody(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_FIXED_BODY_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_FIXED_BODY_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_FIXED_BODY_START
    //GENERATED_UPDATE_INSTANCE_FIXED_BODY_END

    //CUSTOM_UPDATE_INSTANCE_FIXED_BODY_START
    throw new Error(`Please implement Rapier.updateInstanceFixedBody(object, property, value) in R3RuntimePhysicsRapier`);
    //CUSTOM_UPDATE_INSTANCE_FIXED_BODY_END

    //GENERATED_UPDATE_INSTANCE_FIXED_BODY_AFTER_START
    //GENERATED_UPDATE_INSTANCE_FIXED_BODY_AFTER_END

  }

  /**
   * createInstanceWorld()
   * - Rapier implementation for [R3EventObjComponentPhysicsWorld]
   * @param object
   * - No returns
   */
  createInstanceWorld(object) {

    //GENERATED_CREATE_INSTANCE_WORLD_BEFORE_START
    //GENERATED_CREATE_INSTANCE_WORLD_BEFORE_END

    //GENERATED_CREATE_INSTANCE_WORLD_START
    //GENERATED_CREATE_INSTANCE_WORLD_END

    //CUSTOM_CREATE_INSTANCE_WORLD_START
    let gravity = object.gravity.getInstance(this);
    let world = new RAPIER.World(gravity);
    return world;
    //CUSTOM_CREATE_INSTANCE_WORLD_END

    //GENERATED_CREATE_INSTANCE_WORLD_AFTER_START
    //GENERATED_CREATE_INSTANCE_WORLD_AFTER_END

  }

  /**
   * deleteInstanceWorld()
   * - Rapier implementation for [R3EventObjComponentPhysicsWorld]
   * @param object
   * - No returns
   */
  deleteInstanceWorld(object) {

    //GENERATED_DELETE_INSTANCE_WORLD_BEFORE_START
    //GENERATED_DELETE_INSTANCE_WORLD_BEFORE_END

    //GENERATED_DELETE_INSTANCE_WORLD_START
    //GENERATED_DELETE_INSTANCE_WORLD_END

    //CUSTOM_DELETE_INSTANCE_WORLD_START
    let instance = object.getInstance(this);
    instance.free();
    return null;
    //CUSTOM_DELETE_INSTANCE_WORLD_END

    //GENERATED_DELETE_INSTANCE_WORLD_AFTER_START
    //GENERATED_DELETE_INSTANCE_WORLD_AFTER_END

  }

  /**
   * updateInstanceWorld()
   * - Rapier implementation for [R3EventObjComponentPhysicsWorld]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceWorld(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_WORLD_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_WORLD_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_WORLD_START
    //GENERATED_UPDATE_INSTANCE_WORLD_END

    //CUSTOM_UPDATE_INSTANCE_WORLD_START
    let instance = object.getInstance(this);
    if (!instance) {
      Utils.Status(-1, `No World instance to update`);
    }


    if (property === 'gravity') {
      let {x, y, z} = value;
      instance.gravity = {x, y, z};
      return;
    }

    throw new Error(`Please implement Rapier.updateInstanceWorld() in R3RuntimePhysicsRapier for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_WORLD_END

    //GENERATED_UPDATE_INSTANCE_WORLD_AFTER_START
    //GENERATED_UPDATE_INSTANCE_WORLD_AFTER_END

  }

  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Rapier.Type = 'R3RuntimePhysicsRapier';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Rapier.Ready
 * - No comment
 */
Rapier.Ready = false;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Rapier as default};
