//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Color from '../maths/Color.js';
import Runtime from '../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Graphics from '../Graphics.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  scene=this.getCurrentScene()
  color=new Color({r:255, g:255, b:255, a:1})
  intensity=1.0
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeGraphicsThree" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "scene",
            "type" : "R3EventObjComponentGraphicsScene"
          }
        ]
      }
    ],
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "properties" : [
          {
            "property" : "scene",
            "type" : "R3EventObjComponentGraphicsScene"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "color",
            "type" : "R3EventObjComponentMathsColor"
          },
          {
            "property" : "intensity",
            "type" : "Number",
            "min" : 0,
            "max" : 2,
            "step" : 0.001
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Light extends Graphics {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphicsLight';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Light';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param scene
     * - No comment
     */
    if (typeof options.scene === 'undefined') {
      options.scene = this.getCurrentScene();
    }

    /**
     * @param color
     * - No comment
     */
    if (typeof options.color === 'undefined') {
      options.color = new Color({r:255, g:255, b:255, a:1});
    }

    /**
     * @param intensity
     * - No comment
     */
    if (typeof options.intensity === 'undefined') {
      options.intensity = 1.0;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_GRAPHICS_THREE)) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_GRAPHICS_THREE]['requirements']) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "scene",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsScene")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "properties": [
              {
                "property": "scene",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsScene")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "color",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsColor")
              },
              {
                "property": "intensity",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 2,
                "step": 0.001
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "scene",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsScene"),
        "type": "R3EventObjComponentGraphicsScene"
      },
      {
        "property": "color",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsColor"),
        "type": "R3EventObjComponentMathsColor"
      },
      {
        "property": "intensity",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 2,
        "step": 0.001
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Light.Type = 'R3EventObjComponentGraphicsLight';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Light as default};
