//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//CUSTOM_IMPORTS_START
import Node from './Node.js';
//CUSTOM_IMPORTS_END

import R3 from '../R3.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  open=[] - Internal structure required for traversing the graph
  closed=[] - Internal structure required for traversing the graph
  relevant=['parents', 'children'] - By default we traverse all parents and children of a node when performing a search
  solutions=[] - Internal structure for holding solutions from n0 to n. n passed the goalFunction() test.
  terminateOnFirstGoal=false - By default we want to collect all goal nodes which pass the goalFunction(). Set this to true to terminate immediately after discovering the first goal node.
  startNode=null - The start node from which the graph will build
  goalFunction=()=>{return false;} - By having no goal as a default, on construction of a Graph object we will traverse and build the entire graph starting from the startNode.
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
  build() - Uses A* algorithm to build a graph of all nodes which represents the dependency structure of this graph.
  processGraph(callDepth = 0) - Repeats steps 3 to 8. Should indicate whether a goal node was found.
  expand(node, callDepth) - Expands a node generating a set M of its successors which are not already ancestors of the node (n) in the graph (G). i.e. *NOT* already in either OPEN or CLOSED. Install those members of M as successors of n in G.
  establishPointers(M, node) - Establish a pointer to n from each of those members of M that were not already in G. Add these members of M to OPEN. For each member M that was already on OPEN or CLOSED, update the pointer to point to n if the best path so far is through n. For each member of M that was already on CLOSED, redirect the pointers of each of its descendants in G so that they point backward along the best paths found so far to these descendants.
  reOrder() - Re-Order nodes in OPEN in order of increasing ~f values. Ties among minimal ~f values are resolved in favor of the deepest node in the search tree.
  buildSolution(node, solution=[]) - Traverses node pointers from n (goal node) to n0 (start node) and stores the path in a solution list.
  isGoal(callback)
 CUSTOM_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  Traverse(object, type) - Traverses the object and returns a list of all objects of type ('parents' or 'children') for this object and sorts them to order.
  LeafNodes(object, type) - Returns the leaf nodes of an object by traversing its type ('children' or 'parents')
  List(object, type) - Returns a list of all type ('children' or 'parents') including each object's depth
 CUSTOM_STATIC_METHODS_END

 **/

export class Graph extends R3 {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param open
     * - Internal structure required for traversing the graph
     */
    if (typeof options.open === 'undefined') {
      options.open = [];
    }

    /**
     * @param closed
     * - Internal structure required for traversing the graph
     */
    if (typeof options.closed === 'undefined') {
      options.closed = [];
    }

    /**
     * @param relevant
     * - By default we traverse all parents and children of a node when performing a search
     */
    if (typeof options.relevant === 'undefined') {
      options.relevant = ['parents', 'children'];
    }

    /**
     * @param solutions
     * - Internal structure for holding solutions from n0 to n. n passed the goalFunction() test.
     */
    if (typeof options.solutions === 'undefined') {
      options.solutions = [];
    }

    /**
     * @param terminateOnFirstGoal
     * - By default we want to collect all goal nodes which pass the goalFunction(). Set this to true to terminate
     *   immediately after discovering the first goal node.
     */
    if (typeof options.terminateOnFirstGoal === 'undefined') {
      options.terminateOnFirstGoal = false;
    }

    /**
     * @param startNode
     * - The start node from which the graph will build
     */
    if (typeof options.startNode === 'undefined') {
      options.startNode = null;
    }

    /**
     * @param goalFunction
     * - By having no goal as a default, on construction of a Graph object we will traverse and build the entire graph
     *   starting from the startNode.
     */
    if (typeof options.goalFunction === 'undefined') {
      options.goalFunction = ()=>{return false;};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    if (this.startNode) {
      this.startNode.callDepth = 0;
      this.build();
    }
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * build()
   * - Uses A* algorithm to build a graph of all nodes which represents the dependency structure of this graph.
   * - No parameters
   * - No returns
   */
  build() {

    //CUSTOM_BUILD_BEFORE_START
    //CUSTOM_BUILD_BEFORE_END

    //GENERATED_BUILD_BEFORE_START
    //GENERATED_BUILD_BEFORE_END

    //CUSTOM_BUILD_BEFORE_GENERATED_START
    //CUSTOM_BUILD_BEFORE_GENERATED_END

    //GENERATED_BUILD_START
    //GENERATED_BUILD_END

    //CUSTOM_BUILD_START
    if (this.startNode === null) {
      throw new Error('startNode cannot be null');
    }

    /**
     * Step 1. Push start node on open[]
     */
    this.open.push(this.startNode);

    /**
     * Step 2. Clear the closed[] list (we also clear the solutions[] list because we maintain it this way)
     */
    this.closed = [];
    this.solutions = [];

    /**
     * Start with step 3 to 8
     */
    this.processGraph();
    //CUSTOM_BUILD_END

    //GENERATED_BUILD_AFTER_START
    //GENERATED_BUILD_AFTER_END

  }

  /**
   * processGraph()
   * - Repeats steps 3 to 8. Should indicate whether a goal node was found.
   * @param {number} [callDepth=0]
   * - No returns
   */
  processGraph(callDepth = 0) {

    //CUSTOM_PROCESS_BEFORE_START
    //CUSTOM_PROCESS_BEFORE_END

    //GENERATED_PROCESS_BEFORE_START
    //GENERATED_PROCESS_BEFORE_END

    //CUSTOM_PROCESS_BEFORE_GENERATED_START
    //CUSTOM_PROCESS_BEFORE_GENERATED_END

    //GENERATED_PROCESS_START
    //GENERATED_PROCESS_END

    //CUSTOM_PROCESS_START
    /**
     * Step 3. If open[] is empty - exit with failure
     */
    if (this.open.length === 0) {
      // console.log('open[] is empty');
      return false;
    }

    /**
     * Step 4. Select first node on open[] - remove it from open[] and put it on closed[]
     */
    let node = this.open.pop();
    this.closed.push(node);

    /**
     * Step 5. If node is a goal function - exit successfully (or continue if we do not terminate on first goal)
     */
    if (this.goalFunction(node)) {

      this.solutions.push(this.buildSolution(node));

      if (this.terminateOnFirstGoal) {
        return this.solutions[0];
      }

    }

    /**
     * Step 6.a Expand node n, generating the set M of its successors that are not already ancestors of n in G.
     */
    let M = this.expand(node, callDepth);

    /**
     * Step 7 - Establish pointers to node
     */
    this.establishPointers(M, node);

    /**
     * Step 8 - Re-Order nodes in OPEN in order of increasing ~f values. Ties among minimal ~f values are resolved
     * in favor of the deepest node in the search tree.
     */
    this.reOrder();

    /**
     * Continue back to step 3
     */
    this.processGraph(callDepth + 1);
    //CUSTOM_PROCESS_END

    //GENERATED_PROCESS_AFTER_START
    //GENERATED_PROCESS_AFTER_END

  }

  /**
   * expand()
   * - Expands a node generating a set M of its successors which are not already ancestors of the node (n) in the graph
   *   (G). i.e. *NOT* already in either OPEN or CLOSED. Install those members of M as successors of n in G.
   * @param node
   * @param callDepth
   * - No returns
   */
  expand(node, callDepth) {

    //CUSTOM_EXPAND_BEFORE_START
    //CUSTOM_EXPAND_BEFORE_END

    //GENERATED_EXPAND_BEFORE_START
    //GENERATED_EXPAND_BEFORE_END

    //CUSTOM_EXPAND_BEFORE_GENERATED_START
    //CUSTOM_EXPAND_BEFORE_GENERATED_END

    //GENERATED_EXPAND_START
    //GENERATED_EXPAND_END

    //CUSTOM_EXPAND_START
    let successors = {
      onOpen : [],
      onClosed : [],
      notInG : []
    };

    for (let r = 0; r < this.relevant.length; r++) {

      let relevant = this.relevant[r];

      let objects = [...node.object[relevant]];

      for (let object of objects) {

        let notInG = true;

        /**
         * Check if this child is part of an open[] node
         */
        let onOpen = this.open.reduce(
          (onOpen, openNode) => {
            if (openNode.object === object) {
              onOpen = true;
              notInG = false;
            }
            return onOpen;
          },
          false
        );

        let onClosed = this.closed.reduce(
          (onClosed, closedNode) => {
            if (closedNode.object === object) {
              onClosed = true;
              notInG = false;
            }
            return onClosed;
          },
          false
        );

        let newNode;

        let type = 'child';

        if (relevant === 'parents') {
          type = 'parent';
          newNode = new Node(
            {
              object,
              type: type,
              parent: node.object,
              callDepth
            }
          );
        } else {
          newNode = new Node(
            {
              object,
              type: type,
              child: node.object,
              callDepth
            }
          );
        }

        if (onOpen) {
          successors.onOpen.push(newNode);
        }

        if (onClosed) {
          successors.onClosed.push(newNode);
        }

        if (notInG) {
          successors.notInG.push(newNode);
        }
      }
    }

    /**
     * Return the set of successors (M) to be used later in processGraphing
     */
    return successors;
    //CUSTOM_EXPAND_END

    //GENERATED_EXPAND_AFTER_START
    //GENERATED_EXPAND_AFTER_END

  }

  /**
   * establishPointers()
   * - Establish a pointer to n from each of those members of M that were not already in G. Add these members of M to
   *   OPEN. For each member M that was already on OPEN or CLOSED, update the pointer to point to n if the best path so
   *   far is through n. For each member of M that was already on CLOSED, redirect the pointers of each of its
   *   descendants in G so that they point backward along the best paths found so far to these descendants.
   * @param M
   * @param node
   * - No returns
   */
  establishPointers(M, node) {

    //CUSTOM_ESTABLISH_POINTERS_BEFORE_START
    //CUSTOM_ESTABLISH_POINTERS_BEFORE_END

    //GENERATED_ESTABLISH_POINTERS_BEFORE_START
    //GENERATED_ESTABLISH_POINTERS_BEFORE_END

    //CUSTOM_ESTABLISH_POINTERS_BEFORE_GENERATED_START
    //CUSTOM_ESTABLISH_POINTERS_BEFORE_GENERATED_END

    //GENERATED_ESTABLISH_POINTERS_START
    //GENERATED_ESTABLISH_POINTERS_END

    //CUSTOM_ESTABLISH_POINTERS_START
    /**
     * We now have a list of successors (M) which is not already an ancestor of n in G.
     * Install them as successors of n in G. We do part of step 7 here to save some processGraphing steps:
     * We additionally set the pointer of the successor to n, and we push the successor to OPEN.
     */
    M.notInG.map(
      (successor) => {
        successor.pointer = node;
        node.successors.push(successor);
        this.open.push(successor);
      }
    );

    /**
     * Redirect pointers of successors that were in OPEN to n
     */
    M.onOpen.map(
      (successor) => {
        successor.pointer = node;
      }
    );

    /**
     * Redirect pointers of successors that were in CLOSED to n
     */
    M.onClosed.map(
      (successor) => {
        successor.pointer = node;
        /**
         * We skip a part of 7 here - we could alternatively redirect the pointers of each descendant of the successor
         * in G to point backward along the best paths found so far to these descendants - but we don't do this because
         * we are not really interested in the shortest paths -
         */
      }
    )
    //CUSTOM_ESTABLISH_POINTERS_END

    //GENERATED_ESTABLISH_POINTERS_AFTER_START
    //GENERATED_ESTABLISH_POINTERS_AFTER_END

  }

  /**
   * reOrder()
   * - Re-Order nodes in OPEN in order of increasing ~f values. Ties among minimal ~f values are resolved in favor of
   *   the deepest node in the search tree.
   * - No parameters
   * - No returns
   */
  reOrder() {

    //CUSTOM_RE_ORDER_BEFORE_START
    //CUSTOM_RE_ORDER_BEFORE_END

    //GENERATED_RE_ORDER_BEFORE_START
    //GENERATED_RE_ORDER_BEFORE_END

    //CUSTOM_RE_ORDER_BEFORE_GENERATED_START
    //CUSTOM_RE_ORDER_BEFORE_GENERATED_END

    //GENERATED_RE_ORDER_START
    //GENERATED_RE_ORDER_END

    //CUSTOM_RE_ORDER_START
    //CUSTOM_RE_ORDER_END

    //GENERATED_RE_ORDER_AFTER_START
    //GENERATED_RE_ORDER_AFTER_END

  }

  /**
   * buildSolution()
   * - Traverses node pointers from n (goal node) to n0 (start node) and stores the path in a solution list.
   * @param node
   * @param {Array} [solution=[]]
   * - No returns
   */
  buildSolution(node, solution=[]) {

    //CUSTOM_BUILD_SOLUTION_BEFORE_START
    //CUSTOM_BUILD_SOLUTION_BEFORE_END

    //GENERATED_BUILD_SOLUTION_BEFORE_START
    //GENERATED_BUILD_SOLUTION_BEFORE_END

    //CUSTOM_BUILD_SOLUTION_BEFORE_GENERATED_START
    //CUSTOM_BUILD_SOLUTION_BEFORE_GENERATED_END

    //GENERATED_BUILD_SOLUTION_START
    //GENERATED_BUILD_SOLUTION_END

    //CUSTOM_BUILD_SOLUTION_START
    if (this.inverse) {
      solution.unshift(node);
    } else {
      solution.push(node);
    }

    if (node.pointer) {
      this.buildSolution(node.pointer, solution);
    }

    return solution;
    //CUSTOM_BUILD_SOLUTION_END

    //GENERATED_BUILD_SOLUTION_AFTER_START
    //GENERATED_BUILD_SOLUTION_AFTER_END

  }

  /**
   * isGoal()
   * - No comment
   * @param callback
   * - No returns
   */
  isGoal(callback) {

    //CUSTOM_IS_GOAL_BEFORE_START
    //CUSTOM_IS_GOAL_BEFORE_END

    //GENERATED_IS_GOAL_BEFORE_START
    //GENERATED_IS_GOAL_BEFORE_END

    //CUSTOM_IS_GOAL_BEFORE_GENERATED_START
    //CUSTOM_IS_GOAL_BEFORE_GENERATED_END

    //GENERATED_IS_GOAL_START
    //GENERATED_IS_GOAL_END

    //CUSTOM_IS_GOAL_START
    return callback();
    //CUSTOM_IS_GOAL_END

    //GENERATED_IS_GOAL_AFTER_START
    //GENERATED_IS_GOAL_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * Traverse()
   * - Traverses the object and returns a list of all objects of type ('parents' or 'children') for this object and
   *   sorts them to order.
   * @param object
   * @param type
   * - No returns
   */
  static Traverse(object, type) {

    //GENERATED_STATIC_TRAVERSE_START
    //GENERATED_STATIC_TRAVERSE_END

    //CUSTOM_STATIC_TRAVERSE_START
    let startNode = new Node(
      {
        object
      }
    );

    let graph = new Graph(
      {
        startNode,
        goalFunction : (node) => {
          if (node.object[type].length === 0) {
            return true;
          }
        },
        relevant : [type],
        inverse : true
      }
    )

    let leafNodes = [];

    for (let leafNodeChain of graph.solutions) {
      leafNodeChain.map(
        (leafNode, index) =>{
          if ((`${leafNode.type}s` === type) || (`${leafNode.type}ren` === type)) {
            leafNode.depth = index;
            leafNodes.push(leafNode.object);
          }
        }
      )
    }

    return leafNodes;
    //CUSTOM_STATIC_TRAVERSE_END

    //GENERATED_STATIC_TRAVERSE_AFTER_START
    //GENERATED_STATIC_TRAVERSE_AFTER_END

    //CUSTOM_STATIC_GENERATED_TRAVERSE_AFTER_START
    //CUSTOM_STATIC_GENERATED_TRAVERSE_AFTER_END

  }
  /**
   * LeafNodes()
   * - Returns the leaf nodes of an object by traversing its type ('children' or 'parents')
   * @param object
   * @param type
   * - No returns
   */
  static LeafNodes(object, type) {

    //GENERATED_STATIC_LEAF_NODES_START
    //GENERATED_STATIC_LEAF_NODES_END

    //CUSTOM_STATIC_LEAF_NODES_START
    let startNode = new Node(
      {
        object
      }
    );

    let graph = new Graph(
      {
        startNode,
        goalFunction : (node) => {
          if (node.object[type].length === 0) {
            return true;
          }
        },
        relevant : [type],
        inverse : true
      }
    )

    let leafNodes = [];

    for (let leafNodeChain of graph.solutions) {
      leafNodes.push(leafNodeChain[leafNodeChain.length - 1]);
    }

    return leafNodes;
    //CUSTOM_STATIC_LEAF_NODES_END

    //GENERATED_STATIC_LEAF_NODES_AFTER_START
    //GENERATED_STATIC_LEAF_NODES_AFTER_END

    //CUSTOM_STATIC_GENERATED_LEAF_NODES_AFTER_START
    //CUSTOM_STATIC_GENERATED_LEAF_NODES_AFTER_END

  }
  /**
   * List()
   * - Returns a list of all type ('children' or 'parents') including each object's depth
   * @param object
   * @param type
   * - No returns
   */
  static List(object, type) {

    //GENERATED_STATIC_LIST_START
    //GENERATED_STATIC_LIST_END

    //CUSTOM_STATIC_LIST_START
    let startNode = new Node(
      {
        object
      }
    );

    let graph = new Graph(
      {
        startNode,
        goalFunction : () => {
          return true;
        },
        relevant : [type],
        inverse : true
      }
    )

    let leafNodes = [];

    for (let leafNodeChain of graph.solutions) {
      leafNodeChain.map(
        (leafNode, index) =>{
          if ((`${leafNode.type}s` === type) || (`${leafNode.type}ren` === type)) {
            leafNode.depth = index;
            leafNodes.push(leafNode);
          }
        }
      )
    }

    return leafNodes;
    //CUSTOM_STATIC_LIST_END

    //GENERATED_STATIC_LIST_AFTER_START
    //GENERATED_STATIC_LIST_AFTER_END

    //CUSTOM_STATIC_GENERATED_LIST_AFTER_START
    //CUSTOM_STATIC_GENERATED_LIST_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

//GENERATED_EXPORTS_START
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END

export {Graph as default};
