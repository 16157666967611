//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
import * as websocketImport from './websocket/index.js';
import * as storageImport from './storage/index.js';
import * as physicsImport from './physics/index.js';
import * as mathsImport from './maths/index.js';
import * as inputImport from './input/index.js';
import * as guiImport from './gui/index.js';
import * as graphicsImport from './graphics/index.js';
import * as entityImport from './entity/index.js';
import * as codeImport from './code/index.js';
import * as authenticationImport from './authentication/index.js';
import * as audioImport from './audio/index.js';
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as Websocket} from './Websocket.js';
import {default as Storage} from './Storage.js';
import {default as Physics} from './Physics.js';
import {default as Maths} from './Maths.js';
import {default as Input} from './Input.js';
import {default as Gui} from './Gui.js';
import {default as Graphics} from './Graphics.js';
import {default as Entity} from './Entity.js';
import {default as Code} from './Code.js';
import {default as Authentication} from './Authentication.js';
import {default as Audio} from './Audio.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
const SocketIO = websocketImport.SocketIO;
const AxiosStorage = storageImport.Axios;
const Rapier = physicsImport.Rapier;
const ThreeMaths = mathsImport.Three;
const ThreeInput = inputImport.Three;
const Custom = inputImport.Custom;
const TweakPane = guiImport.TweakPane;
const ThreeGraphics = graphicsImport.Three;
const Default = entityImport.Default;
const Ace = codeImport.Ace;
const AxiosAuthentication = authenticationImport.Axios;
const ThreeAudio = audioImport.Three;
Websocket.SocketIO = SocketIO;
Storage.Axios = AxiosStorage;
Physics.Rapier = Rapier;
Maths.Three = ThreeMaths;
Input.Three = ThreeInput;
Input.Custom = Custom;
Gui.TweakPane = TweakPane;
Graphics.Three = ThreeGraphics;
Entity.Default = Default;
Code.Ace = Ace;
Authentication.Axios = AxiosAuthentication;
Audio.Three = ThreeAudio;
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
//CUSTOM_ASSIGN_TO_BASE_END

//GENERATED_EXPORTS_START
export {
  Websocket,
  Storage,
  Physics,
  Maths,
  Input,
  Gui,
  Graphics,
  Entity,
  Code,
  Authentication,
  Audio,
  SocketIO,
  AxiosStorage,
  Rapier,
  ThreeMaths,
  ThreeInput,
  Custom,
  TweakPane,
  ThreeGraphics,
  Default,
  Ace,
  AxiosAuthentication,
  ThreeAudio,
//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
