//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Runtime from '../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Graphics from '../Graphics.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  alphaHash = false - Enables alpha hashed transparency, an alternative to .transparent or .alphaTest. The material will not be rendered if opacity is lower than a random threshold. Randomization introduces some grain or noise, but approximates alpha blending without the associated problems of sorting. Using TAARenderPass can reduce the resulting noise.
  alphaTest = 0 - Sets the alpha value to be used when running an alpha test. The material will not be rendered if the opacity is lower than this value. Default is 0
  alphaToCoverage = false - Enables alpha to coverage. Can only be used with MSAA-enabled contexts (meaning when the renderer was created with antialias parameter set to true). Default is false
  blendDst = Material.OneMinusSrcAlphaFactor - Blending destination. Default is OneMinusSrcAlphaFactor. See the destination factors constants for all possible values. The material's blending must be set to CustomBlending for this to have any effect.
  blendDstAlpha = 0 - The transparency of the .blendDst. Uses .blendDst value if null. Default is null
  blendEquation = Material.AddEquation - Blending equation to use when applying blending. Default is AddEquation. See the blending equation constants for all possible values. The material's blending must be set to CustomBlending for this to have any effect.
  blendEquationAlpha = 0 - The transparency of the .blendEquation. Uses .blendEquation value if null. Default is null
  blendSrc = Material.SrcAlphaFactor - Blending source. Default is SrcAlphaFactor. See the source factors constants for all possible values. The material's blending must be set to CustomBlending for this to have any effect.
  blendSrcAlpha = 0 - The transparency of the .blendSrc. Uses .blendSrc value if null. Default is null
  blending = Material.NormalBlending - Which blending to use when displaying objects with this material. This must be set to CustomBlending to use custom blendSrc, blendDst or blendEquation. See the blending mode constants for all possible values. Default is NormalBlending.
  clipIntersection = false - Changes the behavior of clipping planes so that only their intersection is clipped, rather than their union. Default is false
  clipShadows = false
  clippingPlanes = null
  colorWrite = true
  depthFunc = Material.LessEqualDepth
  depthTest = true
  depthWrite = true
  dithering = false
  forceSinglePass = false
  opacity = 1
  polygonOffset = false
  polygonOffsetFactor = 0
  polygonOffsetUnits = 0
  precision = null - Override the renderer's default precision for this material
  premultipliedAlpha = false
  shadowSide = null
  side = Material.FrontSide
  stencilFail = Material.KeepStencilOp
  stencilFunc = Material.AlwaysStencilFunc
  stencilFuncMask = 0xff
  stencilRef = 0
  stencilWrite = false
  stencilWriteMask = 0xff
  stencilZFail = Material.KeepStencilOp
  stencilZPass = Material.KeepStencilOp
  toneMapped = true
  transparent = false
  vertexColors = false
  visible = true
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  AddEquation = 100
  SubtractEquation = 101
  ReverseSubtractEquation = 102
  MinEquation = 103
  MaxEquation = 104
  FrontSide = 0
  BackSide = 1
  DoubleSide = 2
  TwoPassDoubleSide = 2
  NoBlending = 0
  NormalBlending = 1
  AdditiveBlending = 2
  SubtractiveBlending = 3
  MultiplyBlending = 4
  CustomBlending = 5
  ZeroFactor = 200
  OneFactor = 201
  SrcColorFactor = 202
  OneMinusSrcColorFactor = 203
  SrcAlphaFactor = 204
  OneMinusSrcAlphaFactor = 205
  DstAlphaFactor = 206
  OneMinusDstAlphaFactor = 207
  DstColorFactor = 208
  OneMinusDstColorFactor = 209
  SrcAlphaSaturateFactor = 210
  NeverDepth = 0
  AlwaysDepth = 1
  LessDepth = 2
  LessEqualDepth = 3
  EqualDepth = 4
  GreaterEqualDepth = 5
  GreaterDepth = 6
  NotEqualDepth = 7
  NeverStencilFunc = 512
  LessStencilFunc = 513
  EqualStencilFunc = 514
  LessEqualStencilFunc = 515
  GreaterStencilFunc = 516
  NotEqualStencilFunc = 517
  GreaterEqualStencilFunc = 518
  AlwaysStencilFunc = 519
  ZeroStencilOp = 0
  KeepStencilOp = 7680
  ReplaceStencilOp = 7681
  IncrementStencilOp = 7682
  DecrementStencilOp = 7683
  IncrementWrapStencilOp = 34055
  DecrementWrapStencilOp = 34056
  InvertStencilOp = 5386
  PrecisionHigh = 'highp'
  PrecisionMedium = 'mediump'
  PrecisionLow = 'lowp'
  Butt = 'butt'
  Round = 'round'
  Square = 'square'
  Bevel = 'bevel'
  Miter = 'miter'
  MultiplyOperation = 0
  MixOperation = 1
  AddOperation = 2
  TangentSpaceNormalMap = 0
  ObjectSpaceNormalMap = 1
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "alphaHash",
            "type" : "Boolean"
          },
          {
            "property" : "alphaToCoverage",
            "type" : "Boolean"
          },
          {
            "property" : "blendDst",
            "type" : "Number",
            "possibleValues" : [
              "Material.OneMinusSrcAlphaFactor",
              "Material.DstAlphaFactor",
              "Material.OneMinusDstAlphaFactor",
              "Material.DstColorFactor",
              "Material.OneMinusDstColorFactor",
              "Material.SrcAlphaSaturateFactor"
            ],
            "possibleLabels" : [
              "OneMinusSrcAlphaFactor",
              "DstAlphaFactor",
              "OneMinusDstAlphaFactor",
              "DstColorFactor",
              "OneMinusDstColorFactor",
              "SrcAlphaSaturateFactor"
            ]
          },
          {
            "property" : "blendDstAlpha",
            "property" : "blendDstAlpha",
            "type" : "Number"
          },
          {
            "property" : "blendEquation",
            "type" : "Number",
            "possibleValues" : [
              "Material.AddEquation",
              "Material.SubtractEquation",
              "Material.ReverseSubtractEquation",
              "Material.MinEquation",
              "Material.MaxEquation"
            ],
            "possibleLabels" : [
              "AddEquation",
              "SubtractEquation",
              "ReverseSubtractEquation",
              "MinEquation",
              "MaxEquation"
            ]
          },
          {
            "property" : "blendEquationAlpha",
            "type" : "Number"
          },
          {
            "property" : "blendSrc",
            "type" : "Number",
            "possibleValues" : [
              "Material.ZeroFactor",
              "Material.OneFactor",
              "Material.SrcColorFactor",
              "Material.OneMinusSrcColorFactor",
              "Material.SrcAlphaFactor",
              "Material.OneMinusSrcAlphaFactor",
              "Material.DstAlphaFactor",
              "Material.OneMinusDstAlphaFactor",
              "Material.DstColorFactor",
              "Material.OneMinusDstColorFactor",
              "Material.SrcAlphaSaturateFactor"
            ],
            "possibleLabels" : [
              "ZeroFactor",
              "OneFactor",
              "SrcColorFactor",
              "OneMinusSrcColorFactor",
              "SrcAlphaFactor",
              "OneMinusSrcAlphaFactor",
              "DstAlphaFactor",
              "OneMinusDstAlphaFactor",
              "DstColorFactor",
              "OneMinusDstColorFactor",
              "SrcAlphaSaturateFactor"
            ]
          },
          {
            "property" : "blendSrcAlpha",
            "type" : "Number"
          },
          {
            "property" : "blending",
            "type" : "Number",
            "possibleValues" : [
              "Material.NoBlending",
              "Material.NormalBlending",
              "Material.AdditiveBlending",
              "Material.SubtractiveBlending",
              "Material.MultiplyBlending",
              "Material.CustomBlending"
            ],
            "possibleLabels" : [
              "NoBlending",
              "NormalBlending",
              "AdditiveBlending",
              "SubtractiveBlending",
              "MultiplyBlending",
              "CustomBlending"
            ]
          },
          {
            "property" : "clipIntersection",
            "type" : "Boolean"
          },
          {
            "property" : "clipShadows",
            "type" : "Boolean"
          },
          {
            "property" : "clippingPlanes",
            "type" : ["R3EventObjComponentMathsPlane"]
          },
          {
            "property" : "colorWrite",
            "type" : "Boolean"
          },
          {
            "property" : "depthFunc",
            "type" : "Number",
            "possibleValues" : [
              "Material.NeverDepth",
              "Material.AlwaysDepth",
              "Material.LessDepth",
              "Material.LessEqualDepth",
              "Material.EqualDepth",
              "Material.GreaterEqualDepth",
              "Material.GreaterDepth",
              "Material.NotEqualDepth"
            ],
            "possibleLabels" : [
              "NeverDepth",
              "AlwaysDepth",
              "LessDepth",
              "LessEqualDepth",
              "EqualDepth",
              "GreaterEqualDepth",
              "GreaterDepth",
              "NotEqualDepth"
            ]
          },
          {
            "property" : "depthTest",
            "type" : "Boolean"
          },
          {
            "property" : "depthWrite",
            "type" : "Boolean"
          },
          {
            "property" : "dithering",
            "type" : "Boolean"
          },
          {
            "property" : "forceSinglePass",
            "type" : "Boolean"
          },
          {
            "property" : "opacity",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.0001
          },
          {
            "property" : "polygonOffset",
            "type" : "Boolean"
          },
          {
            "property" : "polygonOffsetFactor",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.0001
          },
          {
            "property" : "polygonOffsetUnits",
            "type" : "Number"
          },
          {
            "property" : "precision",
            "type" : "String",
            "possibleValues" : [
              "null",
              "Material.PrecisionHigh",
              "Material.PrecisionMedium",
              "Material.PrecisionLow"
            ],
            "possibleLabels" : [
              "Default",
              "High",
              "Medium",
              "Low"
            ]
          },
          {
            "property" : "premultipliedAlpha",
            "type" : "Boolean"
          },
          {
            "property" : "side",
            "type" : "Number",
            "possibleValues" : [
              "Material.FrontSide",
              "Material.BackSide",
              "Material.DoubleSide"
            ],
            "possibleLabels" : [
              "FrontSide",
              "BackSide",
              "DoubleSide"
            ]
          },
          {
            "property" : "shadowSide",
            "type" : "Number",
            "possibleValues" : [
              "null",
              "Material.FrontSide",
              "Material.BackSide",
              "Material.DoubleSide",
              "Material.TwoPassDoubleSide"
            ],
            "possibleLabels" : [
              "None",
              "FrontSide",
              "BackSide",
              "DoubleSide",
              "TwoPassDoubleSide"
            ]
          },
          {
            "property" : "stencilFail",
            "type" : "Number",
            "possibleValues" : [
              "Material.ZeroStencilOp",
              "Material.KeepStencilOp",
              "Material.ReplaceStencilOp",
              "Material.IncrementStencilOp",
              "Material.DecrementStencilOp",
              "Material.IncrementWrapStencilOp",
              "Material.DecrementWrapStencilOp",
              "Material.InvertStencilOp"
            ],
            "possibleLabels" : [
              "ZeroStencilOp",
              "KeepStencilOp",
              "ReplaceStencilOp",
              "IncrementStencilOp",
              "DecrementStencilOp",
              "IncrementWrapStencilOp",
              "DecrementWrapStencilOp",
              "InvertStencilOp"
            ]
          },
          {
            "property" : "stencilFunc",
            "type" : "Number",
            "possibleValues" : [
              "Material.NeverStencilFunc",
              "Material.LessStencilFunc",
              "Material.EqualStencilFunc",
              "Material.LessEqualStencilFunc",
              "Material.GreaterStencilFunc",
              "Material.NotEqualStencilFunc",
              "Material.GreaterEqualStencilFunc",
              "Material.AlwaysStencilFunc"
            ],
            "possibleLabels" : [
              "NeverStencilFunc",
              "LessStencilFunc",
              "EqualStencilFunc",
              "LessEqualStencilFunc",
              "GreaterStencilFunc",
              "NotEqualStencilFunc",
              "GreaterEqualStencilFunc",
              "AlwaysStencilFunc"
            ]
          },
          {
            "property" : "stencilFuncMask",
            "type" : "Number"
          },
          {
            "property" : "stencilRef",
            "type" : "Number"
          },
          {
            "property" : "stencilWrite",
            "type" : "Boolean"
          },
          {
            "property" : "stencilWriteMask",
            "type" : "Number"
          },
          {
            "property" : "stencilZFail",
            "type" : "Number",
            "possibleValues" : [
              "Material.ZeroStencilOp",
              "Material.KeepStencilOp",
              "Material.ReplaceStencilOp",
              "Material.IncrementStencilOp",
              "Material.DecrementStencilOp",
              "Material.IncrementWrapStencilOp",
              "Material.DecrementWrapStencilOp",
              "Material.InvertStencilOp"
            ],
            "possibleLabels" : [
              "ZeroStencilOp",
              "KeepStencilOp",
              "ReplaceStencilOp",
              "IncrementStencilOp",
              "DecrementStencilOp",
              "IncrementWrapStencilOp",
              "DecrementWrapStencilOp",
              "InvertStencilOp"
            ]
          },
          {
            "property" : "stencilZPass",
            "type" : "Number",
            "possibleValues" : [
              "Material.ZeroStencilOp",
              "Material.KeepStencilOp",
              "Material.ReplaceStencilOp",
              "Material.IncrementStencilOp",
              "Material.DecrementStencilOp",
              "Material.IncrementWrapStencilOp",
              "Material.DecrementWrapStencilOp",
              "Material.InvertStencilOp"
            ],
            "possibleLabels" : [
              "ZeroStencilOp",
              "KeepStencilOp",
              "ReplaceStencilOp",
              "IncrementStencilOp",
              "DecrementStencilOp",
              "IncrementWrapStencilOp",
              "DecrementWrapStencilOp",
              "InvertStencilOp"
            ]
          },
          {
            "property" : "toneMapped",
            "type" : "Boolean"
          },
          {
            "property" : "transparent",
            "type" : "Boolean"
          },
          {
            "property" : "vertexColors",
            "type" : "Boolean"
          },
          {
            "property" : "visible",
            "type" : "Boolean"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  cloneInstance(propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance() - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance() - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Material extends Graphics {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphicsMaterial';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Material';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param alphaHash
     * - Enables alpha hashed transparency, an alternative to .transparent or .alphaTest. The material will not be
     *   rendered if opacity is lower than a random threshold. Randomization introduces some grain or noise, but
     *   approximates alpha blending without the associated problems of sorting. Using TAARenderPass can reduce the
     *   resulting noise.
     */
    if (typeof options.alphaHash === 'undefined') {
      options.alphaHash = false;
    }

    /**
     * @param alphaTest
     * - Sets the alpha value to be used when running an alpha test. The material will not be rendered if the opacity is
     *   lower than this value. Default is 0
     */
    if (typeof options.alphaTest === 'undefined') {
      options.alphaTest = 0;
    }

    /**
     * @param alphaToCoverage
     * - Enables alpha to coverage. Can only be used with MSAA-enabled contexts (meaning when the renderer was created
     *   with antialias parameter set to true). Default is false
     */
    if (typeof options.alphaToCoverage === 'undefined') {
      options.alphaToCoverage = false;
    }

    /**
     * @param blendDst
     * - Blending destination. Default is OneMinusSrcAlphaFactor. See the destination factors constants for all possible
     *   values. The material's blending must be set to CustomBlending for this to have any effect.
     */
    if (typeof options.blendDst === 'undefined') {
      options.blendDst = Material.OneMinusSrcAlphaFactor;
    }

    /**
     * @param blendDstAlpha
     * - The transparency of the .blendDst. Uses .blendDst value if null. Default is null
     */
    if (typeof options.blendDstAlpha === 'undefined') {
      options.blendDstAlpha = 0;
    }

    /**
     * @param blendEquation
     * - Blending equation to use when applying blending. Default is AddEquation. See the blending equation constants for
     *   all possible values. The material's blending must be set to CustomBlending for this to have any effect.
     */
    if (typeof options.blendEquation === 'undefined') {
      options.blendEquation = Material.AddEquation;
    }

    /**
     * @param blendEquationAlpha
     * - The transparency of the .blendEquation. Uses .blendEquation value if null. Default is null
     */
    if (typeof options.blendEquationAlpha === 'undefined') {
      options.blendEquationAlpha = 0;
    }

    /**
     * @param blendSrc
     * - Blending source. Default is SrcAlphaFactor. See the source factors constants for all possible values. The
     *   material's blending must be set to CustomBlending for this to have any effect.
     */
    if (typeof options.blendSrc === 'undefined') {
      options.blendSrc = Material.SrcAlphaFactor;
    }

    /**
     * @param blendSrcAlpha
     * - The transparency of the .blendSrc. Uses .blendSrc value if null. Default is null
     */
    if (typeof options.blendSrcAlpha === 'undefined') {
      options.blendSrcAlpha = 0;
    }

    /**
     * @param blending
     * - Which blending to use when displaying objects with this material. This must be set to CustomBlending to use
     *   custom blendSrc, blendDst or blendEquation. See the blending mode constants for all possible values. Default is
     *   NormalBlending.
     */
    if (typeof options.blending === 'undefined') {
      options.blending = Material.NormalBlending;
    }

    /**
     * @param clipIntersection
     * - Changes the behavior of clipping planes so that only their intersection is clipped, rather than their union.
     *   Default is false
     */
    if (typeof options.clipIntersection === 'undefined') {
      options.clipIntersection = false;
    }

    /**
     * @param clipShadows
     * - No comment
     */
    if (typeof options.clipShadows === 'undefined') {
      options.clipShadows = false;
    }

    /**
     * @param clippingPlanes
     * - No comment
     */
    if (typeof options.clippingPlanes === 'undefined') {
      options.clippingPlanes = null;
    }

    /**
     * @param colorWrite
     * - No comment
     */
    if (typeof options.colorWrite === 'undefined') {
      options.colorWrite = true;
    }

    /**
     * @param depthFunc
     * - No comment
     */
    if (typeof options.depthFunc === 'undefined') {
      options.depthFunc = Material.LessEqualDepth;
    }

    /**
     * @param depthTest
     * - No comment
     */
    if (typeof options.depthTest === 'undefined') {
      options.depthTest = true;
    }

    /**
     * @param depthWrite
     * - No comment
     */
    if (typeof options.depthWrite === 'undefined') {
      options.depthWrite = true;
    }

    /**
     * @param dithering
     * - No comment
     */
    if (typeof options.dithering === 'undefined') {
      options.dithering = false;
    }

    /**
     * @param forceSinglePass
     * - No comment
     */
    if (typeof options.forceSinglePass === 'undefined') {
      options.forceSinglePass = false;
    }

    /**
     * @param opacity
     * - No comment
     */
    if (typeof options.opacity === 'undefined') {
      options.opacity = 1;
    }

    /**
     * @param polygonOffset
     * - No comment
     */
    if (typeof options.polygonOffset === 'undefined') {
      options.polygonOffset = false;
    }

    /**
     * @param polygonOffsetFactor
     * - No comment
     */
    if (typeof options.polygonOffsetFactor === 'undefined') {
      options.polygonOffsetFactor = 0;
    }

    /**
     * @param polygonOffsetUnits
     * - No comment
     */
    if (typeof options.polygonOffsetUnits === 'undefined') {
      options.polygonOffsetUnits = 0;
    }

    /**
     * @param precision
     * - Override the renderer's default precision for this material
     */
    if (typeof options.precision === 'undefined') {
      options.precision = null;
    }

    /**
     * @param premultipliedAlpha
     * - No comment
     */
    if (typeof options.premultipliedAlpha === 'undefined') {
      options.premultipliedAlpha = false;
    }

    /**
     * @param shadowSide
     * - No comment
     */
    if (typeof options.shadowSide === 'undefined') {
      options.shadowSide = null;
    }

    /**
     * @param side
     * - No comment
     */
    if (typeof options.side === 'undefined') {
      options.side = Material.FrontSide;
    }

    /**
     * @param stencilFail
     * - No comment
     */
    if (typeof options.stencilFail === 'undefined') {
      options.stencilFail = Material.KeepStencilOp;
    }

    /**
     * @param stencilFunc
     * - No comment
     */
    if (typeof options.stencilFunc === 'undefined') {
      options.stencilFunc = Material.AlwaysStencilFunc;
    }

    /**
     * @param stencilFuncMask
     * - No comment
     */
    if (typeof options.stencilFuncMask === 'undefined') {
      options.stencilFuncMask = 0xff;
    }

    /**
     * @param stencilRef
     * - No comment
     */
    if (typeof options.stencilRef === 'undefined') {
      options.stencilRef = 0;
    }

    /**
     * @param stencilWrite
     * - No comment
     */
    if (typeof options.stencilWrite === 'undefined') {
      options.stencilWrite = false;
    }

    /**
     * @param stencilWriteMask
     * - No comment
     */
    if (typeof options.stencilWriteMask === 'undefined') {
      options.stencilWriteMask = 0xff;
    }

    /**
     * @param stencilZFail
     * - No comment
     */
    if (typeof options.stencilZFail === 'undefined') {
      options.stencilZFail = Material.KeepStencilOp;
    }

    /**
     * @param stencilZPass
     * - No comment
     */
    if (typeof options.stencilZPass === 'undefined') {
      options.stencilZPass = Material.KeepStencilOp;
    }

    /**
     * @param toneMapped
     * - No comment
     */
    if (typeof options.toneMapped === 'undefined') {
      options.toneMapped = true;
    }

    /**
     * @param transparent
     * - No comment
     */
    if (typeof options.transparent === 'undefined') {
      options.transparent = false;
    }

    /**
     * @param vertexColors
     * - No comment
     */
    if (typeof options.vertexColors === 'undefined') {
      options.vertexColors = false;
    }

    /**
     * @param visible
     * - No comment
     */
    if (typeof options.visible === 'undefined') {
      options.visible = true;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "alphaHash",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "alphaToCoverage",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "blendDst",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Material.OneMinusSrcAlphaFactor,
                  Material.DstAlphaFactor,
                  Material.OneMinusDstAlphaFactor,
                  Material.DstColorFactor,
                  Material.OneMinusDstColorFactor,
                  Material.SrcAlphaSaturateFactor
                ],
                "possibleLabels": [
                  "OneMinusSrcAlphaFactor",
                  "DstAlphaFactor",
                  "OneMinusDstAlphaFactor",
                  "DstColorFactor",
                  "OneMinusDstColorFactor",
                  "SrcAlphaSaturateFactor"
                ]
              },
              {
                "property": "blendDstAlpha",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "blendEquation",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Material.AddEquation,
                  Material.SubtractEquation,
                  Material.ReverseSubtractEquation,
                  Material.MinEquation,
                  Material.MaxEquation
                ],
                "possibleLabels": [
                  "AddEquation",
                  "SubtractEquation",
                  "ReverseSubtractEquation",
                  "MinEquation",
                  "MaxEquation"
                ]
              },
              {
                "property": "blendEquationAlpha",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "blendSrc",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Material.ZeroFactor,
                  Material.OneFactor,
                  Material.SrcColorFactor,
                  Material.OneMinusSrcColorFactor,
                  Material.SrcAlphaFactor,
                  Material.OneMinusSrcAlphaFactor,
                  Material.DstAlphaFactor,
                  Material.OneMinusDstAlphaFactor,
                  Material.DstColorFactor,
                  Material.OneMinusDstColorFactor,
                  Material.SrcAlphaSaturateFactor
                ],
                "possibleLabels": [
                  "ZeroFactor",
                  "OneFactor",
                  "SrcColorFactor",
                  "OneMinusSrcColorFactor",
                  "SrcAlphaFactor",
                  "OneMinusSrcAlphaFactor",
                  "DstAlphaFactor",
                  "OneMinusDstAlphaFactor",
                  "DstColorFactor",
                  "OneMinusDstColorFactor",
                  "SrcAlphaSaturateFactor"
                ]
              },
              {
                "property": "blendSrcAlpha",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "blending",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Material.NoBlending,
                  Material.NormalBlending,
                  Material.AdditiveBlending,
                  Material.SubtractiveBlending,
                  Material.MultiplyBlending,
                  Material.CustomBlending
                ],
                "possibleLabels": [
                  "NoBlending",
                  "NormalBlending",
                  "AdditiveBlending",
                  "SubtractiveBlending",
                  "MultiplyBlending",
                  "CustomBlending"
                ]
              },
              {
                "property": "clipIntersection",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "clipShadows",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "clippingPlanes",
                "Constructor" : [this.getClassConstructor("R3EventObjComponentMathsPlane")]
              },
              {
                "property": "colorWrite",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "depthFunc",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Material.NeverDepth,
                  Material.AlwaysDepth,
                  Material.LessDepth,
                  Material.LessEqualDepth,
                  Material.EqualDepth,
                  Material.GreaterEqualDepth,
                  Material.GreaterDepth,
                  Material.NotEqualDepth
                ],
                "possibleLabels": [
                  "NeverDepth",
                  "AlwaysDepth",
                  "LessDepth",
                  "LessEqualDepth",
                  "EqualDepth",
                  "GreaterEqualDepth",
                  "GreaterDepth",
                  "NotEqualDepth"
                ]
              },
              {
                "property": "depthTest",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "depthWrite",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "dithering",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "forceSinglePass",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "opacity",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.0001
              },
              {
                "property": "polygonOffset",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "polygonOffsetFactor",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.0001
              },
              {
                "property": "polygonOffsetUnits",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "precision",
                "Constructor" : this.getClassConstructor("String"),
                "possibleValues": [
                  null,
                  Material.PrecisionHigh,
                  Material.PrecisionMedium,
                  Material.PrecisionLow
                ],
                "possibleLabels": [
                  "Default",
                  "High",
                  "Medium",
                  "Low"
                ]
              },
              {
                "property": "premultipliedAlpha",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "side",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Material.FrontSide,
                  Material.BackSide,
                  Material.DoubleSide
                ],
                "possibleLabels": [
                  "FrontSide",
                  "BackSide",
                  "DoubleSide"
                ]
              },
              {
                "property": "shadowSide",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  null,
                  Material.FrontSide,
                  Material.BackSide,
                  Material.DoubleSide,
                  Material.TwoPassDoubleSide
                ],
                "possibleLabels": [
                  "None",
                  "FrontSide",
                  "BackSide",
                  "DoubleSide",
                  "TwoPassDoubleSide"
                ]
              },
              {
                "property": "stencilFail",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Material.ZeroStencilOp,
                  Material.KeepStencilOp,
                  Material.ReplaceStencilOp,
                  Material.IncrementStencilOp,
                  Material.DecrementStencilOp,
                  Material.IncrementWrapStencilOp,
                  Material.DecrementWrapStencilOp,
                  Material.InvertStencilOp
                ],
                "possibleLabels": [
                  "ZeroStencilOp",
                  "KeepStencilOp",
                  "ReplaceStencilOp",
                  "IncrementStencilOp",
                  "DecrementStencilOp",
                  "IncrementWrapStencilOp",
                  "DecrementWrapStencilOp",
                  "InvertStencilOp"
                ]
              },
              {
                "property": "stencilFunc",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Material.NeverStencilFunc,
                  Material.LessStencilFunc,
                  Material.EqualStencilFunc,
                  Material.LessEqualStencilFunc,
                  Material.GreaterStencilFunc,
                  Material.NotEqualStencilFunc,
                  Material.GreaterEqualStencilFunc,
                  Material.AlwaysStencilFunc
                ],
                "possibleLabels": [
                  "NeverStencilFunc",
                  "LessStencilFunc",
                  "EqualStencilFunc",
                  "LessEqualStencilFunc",
                  "GreaterStencilFunc",
                  "NotEqualStencilFunc",
                  "GreaterEqualStencilFunc",
                  "AlwaysStencilFunc"
                ]
              },
              {
                "property": "stencilFuncMask",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "stencilRef",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "stencilWrite",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "stencilWriteMask",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "stencilZFail",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Material.ZeroStencilOp,
                  Material.KeepStencilOp,
                  Material.ReplaceStencilOp,
                  Material.IncrementStencilOp,
                  Material.DecrementStencilOp,
                  Material.IncrementWrapStencilOp,
                  Material.DecrementWrapStencilOp,
                  Material.InvertStencilOp
                ],
                "possibleLabels": [
                  "ZeroStencilOp",
                  "KeepStencilOp",
                  "ReplaceStencilOp",
                  "IncrementStencilOp",
                  "DecrementStencilOp",
                  "IncrementWrapStencilOp",
                  "DecrementWrapStencilOp",
                  "InvertStencilOp"
                ]
              },
              {
                "property": "stencilZPass",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Material.ZeroStencilOp,
                  Material.KeepStencilOp,
                  Material.ReplaceStencilOp,
                  Material.IncrementStencilOp,
                  Material.DecrementStencilOp,
                  Material.IncrementWrapStencilOp,
                  Material.DecrementWrapStencilOp,
                  Material.InvertStencilOp
                ],
                "possibleLabels": [
                  "ZeroStencilOp",
                  "KeepStencilOp",
                  "ReplaceStencilOp",
                  "IncrementStencilOp",
                  "DecrementStencilOp",
                  "IncrementWrapStencilOp",
                  "DecrementWrapStencilOp",
                  "InvertStencilOp"
                ]
              },
              {
                "property": "toneMapped",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "transparent",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "vertexColors",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "visible",
                "Constructor" : this.getClassConstructor("Boolean")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_GRAPHICS_THREE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "alphaHash",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "alphaToCoverage",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "blendDst",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Material.OneMinusSrcAlphaFactor,
          Material.DstAlphaFactor,
          Material.OneMinusDstAlphaFactor,
          Material.DstColorFactor,
          Material.OneMinusDstColorFactor,
          Material.SrcAlphaSaturateFactor
        ],
        "possibleLabels": [
          "OneMinusSrcAlphaFactor",
          "DstAlphaFactor",
          "OneMinusDstAlphaFactor",
          "DstColorFactor",
          "OneMinusDstColorFactor",
          "SrcAlphaSaturateFactor"
        ]
      },
      {
        "property": "blendDstAlpha",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "blendEquation",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Material.AddEquation,
          Material.SubtractEquation,
          Material.ReverseSubtractEquation,
          Material.MinEquation,
          Material.MaxEquation
        ],
        "possibleLabels": [
          "AddEquation",
          "SubtractEquation",
          "ReverseSubtractEquation",
          "MinEquation",
          "MaxEquation"
        ]
      },
      {
        "property": "blendEquationAlpha",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "blendSrc",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Material.ZeroFactor,
          Material.OneFactor,
          Material.SrcColorFactor,
          Material.OneMinusSrcColorFactor,
          Material.SrcAlphaFactor,
          Material.OneMinusSrcAlphaFactor,
          Material.DstAlphaFactor,
          Material.OneMinusDstAlphaFactor,
          Material.DstColorFactor,
          Material.OneMinusDstColorFactor,
          Material.SrcAlphaSaturateFactor
        ],
        "possibleLabels": [
          "ZeroFactor",
          "OneFactor",
          "SrcColorFactor",
          "OneMinusSrcColorFactor",
          "SrcAlphaFactor",
          "OneMinusSrcAlphaFactor",
          "DstAlphaFactor",
          "OneMinusDstAlphaFactor",
          "DstColorFactor",
          "OneMinusDstColorFactor",
          "SrcAlphaSaturateFactor"
        ]
      },
      {
        "property": "blendSrcAlpha",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "blending",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Material.NoBlending,
          Material.NormalBlending,
          Material.AdditiveBlending,
          Material.SubtractiveBlending,
          Material.MultiplyBlending,
          Material.CustomBlending
        ],
        "possibleLabels": [
          "NoBlending",
          "NormalBlending",
          "AdditiveBlending",
          "SubtractiveBlending",
          "MultiplyBlending",
          "CustomBlending"
        ]
      },
      {
        "property": "clipIntersection",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "clipShadows",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "clippingPlanes",
        "Constructor" : [this.getClassConstructor("R3EventObjComponentMathsPlane")],
        "type": [
          "R3EventObjComponentMathsPlane"
        ]
      },
      {
        "property": "colorWrite",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "depthFunc",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Material.NeverDepth,
          Material.AlwaysDepth,
          Material.LessDepth,
          Material.LessEqualDepth,
          Material.EqualDepth,
          Material.GreaterEqualDepth,
          Material.GreaterDepth,
          Material.NotEqualDepth
        ],
        "possibleLabels": [
          "NeverDepth",
          "AlwaysDepth",
          "LessDepth",
          "LessEqualDepth",
          "EqualDepth",
          "GreaterEqualDepth",
          "GreaterDepth",
          "NotEqualDepth"
        ]
      },
      {
        "property": "depthTest",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "depthWrite",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "dithering",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "forceSinglePass",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "opacity",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.0001
      },
      {
        "property": "polygonOffset",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "polygonOffsetFactor",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.0001
      },
      {
        "property": "polygonOffsetUnits",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "precision",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String",
        "possibleValues": [
          null,
          Material.PrecisionHigh,
          Material.PrecisionMedium,
          Material.PrecisionLow
        ],
        "possibleLabels": [
          "Default",
          "High",
          "Medium",
          "Low"
        ]
      },
      {
        "property": "premultipliedAlpha",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "side",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Material.FrontSide,
          Material.BackSide,
          Material.DoubleSide
        ],
        "possibleLabels": [
          "FrontSide",
          "BackSide",
          "DoubleSide"
        ]
      },
      {
        "property": "shadowSide",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          null,
          Material.FrontSide,
          Material.BackSide,
          Material.DoubleSide,
          Material.TwoPassDoubleSide
        ],
        "possibleLabels": [
          "None",
          "FrontSide",
          "BackSide",
          "DoubleSide",
          "TwoPassDoubleSide"
        ]
      },
      {
        "property": "stencilFail",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Material.ZeroStencilOp,
          Material.KeepStencilOp,
          Material.ReplaceStencilOp,
          Material.IncrementStencilOp,
          Material.DecrementStencilOp,
          Material.IncrementWrapStencilOp,
          Material.DecrementWrapStencilOp,
          Material.InvertStencilOp
        ],
        "possibleLabels": [
          "ZeroStencilOp",
          "KeepStencilOp",
          "ReplaceStencilOp",
          "IncrementStencilOp",
          "DecrementStencilOp",
          "IncrementWrapStencilOp",
          "DecrementWrapStencilOp",
          "InvertStencilOp"
        ]
      },
      {
        "property": "stencilFunc",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Material.NeverStencilFunc,
          Material.LessStencilFunc,
          Material.EqualStencilFunc,
          Material.LessEqualStencilFunc,
          Material.GreaterStencilFunc,
          Material.NotEqualStencilFunc,
          Material.GreaterEqualStencilFunc,
          Material.AlwaysStencilFunc
        ],
        "possibleLabels": [
          "NeverStencilFunc",
          "LessStencilFunc",
          "EqualStencilFunc",
          "LessEqualStencilFunc",
          "GreaterStencilFunc",
          "NotEqualStencilFunc",
          "GreaterEqualStencilFunc",
          "AlwaysStencilFunc"
        ]
      },
      {
        "property": "stencilFuncMask",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "stencilRef",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "stencilWrite",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "stencilWriteMask",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "stencilZFail",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Material.ZeroStencilOp,
          Material.KeepStencilOp,
          Material.ReplaceStencilOp,
          Material.IncrementStencilOp,
          Material.DecrementStencilOp,
          Material.IncrementWrapStencilOp,
          Material.DecrementWrapStencilOp,
          Material.InvertStencilOp
        ],
        "possibleLabels": [
          "ZeroStencilOp",
          "KeepStencilOp",
          "ReplaceStencilOp",
          "IncrementStencilOp",
          "DecrementStencilOp",
          "IncrementWrapStencilOp",
          "DecrementWrapStencilOp",
          "InvertStencilOp"
        ]
      },
      {
        "property": "stencilZPass",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Material.ZeroStencilOp,
          Material.KeepStencilOp,
          Material.ReplaceStencilOp,
          Material.IncrementStencilOp,
          Material.DecrementStencilOp,
          Material.IncrementWrapStencilOp,
          Material.DecrementWrapStencilOp,
          Material.InvertStencilOp
        ],
        "possibleLabels": [
          "ZeroStencilOp",
          "KeepStencilOp",
          "ReplaceStencilOp",
          "IncrementStencilOp",
          "DecrementStencilOp",
          "IncrementWrapStencilOp",
          "DecrementWrapStencilOp",
          "InvertStencilOp"
        ]
      },
      {
        "property": "toneMapped",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "transparent",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "vertexColors",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "visible",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * cloneInstance()
   * - Clones an instance, when propagate is true, also clones the instance children
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstance(propagate = true) {

    //CUSTOM_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BEFORE_END

    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CLONE_INSTANCE_START
    /**
     * Override Template
     */
    //GENERATED_CLONE_INSTANCE_END

    //CUSTOM_CLONE_INSTANCE_START
    //CUSTOM_CLONE_INSTANCE_END

    //GENERATED_CLONE_INSTANCE_AFTER_START
    //GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  createInstance() {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  deleteInstance() {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Material.Type = 'R3EventObjComponentGraphicsMaterial';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Material.AddEquation
 * - No comment
 */
Material.AddEquation = 100;

/**
 * @param Material.SubtractEquation
 * - No comment
 */
Material.SubtractEquation = 101;

/**
 * @param Material.ReverseSubtractEquation
 * - No comment
 */
Material.ReverseSubtractEquation = 102;

/**
 * @param Material.MinEquation
 * - No comment
 */
Material.MinEquation = 103;

/**
 * @param Material.MaxEquation
 * - No comment
 */
Material.MaxEquation = 104;

/**
 * @param Material.FrontSide
 * - No comment
 */
Material.FrontSide = 0;

/**
 * @param Material.BackSide
 * - No comment
 */
Material.BackSide = 1;

/**
 * @param Material.DoubleSide
 * - No comment
 */
Material.DoubleSide = 2;

/**
 * @param Material.TwoPassDoubleSide
 * - No comment
 */
Material.TwoPassDoubleSide = 2;

/**
 * @param Material.NoBlending
 * - No comment
 */
Material.NoBlending = 0;

/**
 * @param Material.NormalBlending
 * - No comment
 */
Material.NormalBlending = 1;

/**
 * @param Material.AdditiveBlending
 * - No comment
 */
Material.AdditiveBlending = 2;

/**
 * @param Material.SubtractiveBlending
 * - No comment
 */
Material.SubtractiveBlending = 3;

/**
 * @param Material.MultiplyBlending
 * - No comment
 */
Material.MultiplyBlending = 4;

/**
 * @param Material.CustomBlending
 * - No comment
 */
Material.CustomBlending = 5;

/**
 * @param Material.ZeroFactor
 * - No comment
 */
Material.ZeroFactor = 200;

/**
 * @param Material.OneFactor
 * - No comment
 */
Material.OneFactor = 201;

/**
 * @param Material.SrcColorFactor
 * - No comment
 */
Material.SrcColorFactor = 202;

/**
 * @param Material.OneMinusSrcColorFactor
 * - No comment
 */
Material.OneMinusSrcColorFactor = 203;

/**
 * @param Material.SrcAlphaFactor
 * - No comment
 */
Material.SrcAlphaFactor = 204;

/**
 * @param Material.OneMinusSrcAlphaFactor
 * - No comment
 */
Material.OneMinusSrcAlphaFactor = 205;

/**
 * @param Material.DstAlphaFactor
 * - No comment
 */
Material.DstAlphaFactor = 206;

/**
 * @param Material.OneMinusDstAlphaFactor
 * - No comment
 */
Material.OneMinusDstAlphaFactor = 207;

/**
 * @param Material.DstColorFactor
 * - No comment
 */
Material.DstColorFactor = 208;

/**
 * @param Material.OneMinusDstColorFactor
 * - No comment
 */
Material.OneMinusDstColorFactor = 209;

/**
 * @param Material.SrcAlphaSaturateFactor
 * - No comment
 */
Material.SrcAlphaSaturateFactor = 210;

/**
 * @param Material.NeverDepth
 * - No comment
 */
Material.NeverDepth = 0;

/**
 * @param Material.AlwaysDepth
 * - No comment
 */
Material.AlwaysDepth = 1;

/**
 * @param Material.LessDepth
 * - No comment
 */
Material.LessDepth = 2;

/**
 * @param Material.LessEqualDepth
 * - No comment
 */
Material.LessEqualDepth = 3;

/**
 * @param Material.EqualDepth
 * - No comment
 */
Material.EqualDepth = 4;

/**
 * @param Material.GreaterEqualDepth
 * - No comment
 */
Material.GreaterEqualDepth = 5;

/**
 * @param Material.GreaterDepth
 * - No comment
 */
Material.GreaterDepth = 6;

/**
 * @param Material.NotEqualDepth
 * - No comment
 */
Material.NotEqualDepth = 7;

/**
 * @param Material.NeverStencilFunc
 * - No comment
 */
Material.NeverStencilFunc = 512;

/**
 * @param Material.LessStencilFunc
 * - No comment
 */
Material.LessStencilFunc = 513;

/**
 * @param Material.EqualStencilFunc
 * - No comment
 */
Material.EqualStencilFunc = 514;

/**
 * @param Material.LessEqualStencilFunc
 * - No comment
 */
Material.LessEqualStencilFunc = 515;

/**
 * @param Material.GreaterStencilFunc
 * - No comment
 */
Material.GreaterStencilFunc = 516;

/**
 * @param Material.NotEqualStencilFunc
 * - No comment
 */
Material.NotEqualStencilFunc = 517;

/**
 * @param Material.GreaterEqualStencilFunc
 * - No comment
 */
Material.GreaterEqualStencilFunc = 518;

/**
 * @param Material.AlwaysStencilFunc
 * - No comment
 */
Material.AlwaysStencilFunc = 519;

/**
 * @param Material.ZeroStencilOp
 * - No comment
 */
Material.ZeroStencilOp = 0;

/**
 * @param Material.KeepStencilOp
 * - No comment
 */
Material.KeepStencilOp = 7680;

/**
 * @param Material.ReplaceStencilOp
 * - No comment
 */
Material.ReplaceStencilOp = 7681;

/**
 * @param Material.IncrementStencilOp
 * - No comment
 */
Material.IncrementStencilOp = 7682;

/**
 * @param Material.DecrementStencilOp
 * - No comment
 */
Material.DecrementStencilOp = 7683;

/**
 * @param Material.IncrementWrapStencilOp
 * - No comment
 */
Material.IncrementWrapStencilOp = 34055;

/**
 * @param Material.DecrementWrapStencilOp
 * - No comment
 */
Material.DecrementWrapStencilOp = 34056;

/**
 * @param Material.InvertStencilOp
 * - No comment
 */
Material.InvertStencilOp = 5386;

/**
 * @param Material.PrecisionHigh
 * - No comment
 */
Material.PrecisionHigh = 'highp';

/**
 * @param Material.PrecisionMedium
 * - No comment
 */
Material.PrecisionMedium = 'mediump';

/**
 * @param Material.PrecisionLow
 * - No comment
 */
Material.PrecisionLow = 'lowp';

/**
 * @param Material.Butt
 * - No comment
 */
Material.Butt = 'butt';

/**
 * @param Material.Round
 * - No comment
 */
Material.Round = 'round';

/**
 * @param Material.Square
 * - No comment
 */
Material.Square = 'square';

/**
 * @param Material.Bevel
 * - No comment
 */
Material.Bevel = 'bevel';

/**
 * @param Material.Miter
 * - No comment
 */
Material.Miter = 'miter';

/**
 * @param Material.MultiplyOperation
 * - No comment
 */
Material.MultiplyOperation = 0;

/**
 * @param Material.MixOperation
 * - No comment
 */
Material.MixOperation = 1;

/**
 * @param Material.AddOperation
 * - No comment
 */
Material.AddOperation = 2;

/**
 * @param Material.TangentSpaceNormalMap
 * - No comment
 */
Material.TangentSpaceNormalMap = 0;

/**
 * @param Material.ObjectSpaceNormalMap
 * - No comment
 */
Material.ObjectSpaceNormalMap = 1;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Material as default};
