//GENERATED_IMPORTS_START
import {default as R3Utils} from '../Utils.js';
import {default as R3System} from '../System.js';
import {default as R3Runtime} from '../Runtime.js';
import {default as R3Node} from '../Node.js';
import {default as R3MockRapier} from '../MockRapier.js';
import {default as R3Graph} from '../Graph.js';
import {default as R3Event} from '../Event.js';
import {default as R3SystemWebsocket} from './Websocket.js';
import {default as R3SystemStorage} from './Storage.js';
import {default as R3SystemPhysics} from './Physics.js';
import {default as R3SystemMaths} from './Maths.js';
import {default as R3SystemInput} from './Input.js';
import {default as R3SystemGui} from './Gui.js';
import {default as R3SystemGraphics} from './Graphics.js';
import {default as R3SystemEntity} from './Entity.js';
import {default as R3SystemCode} from './Code.js';
import {default as R3SystemAuthentication} from './Authentication.js';
import {default as R3SystemAudio} from './Audio.js';
import {default as R3RuntimeWebsocket} from '../runtime/Websocket.js';
import {default as R3RuntimeStorage} from '../runtime/Storage.js';
import {default as R3RuntimePhysics} from '../runtime/Physics.js';
import {default as R3RuntimeMaths} from '../runtime/Maths.js';
import {default as R3RuntimeInput} from '../runtime/Input.js';
import {default as R3RuntimeGui} from '../runtime/Gui.js';
import {default as R3RuntimeGraphics} from '../runtime/Graphics.js';
import {default as R3RuntimeEntity} from '../runtime/Entity.js';
import {default as R3RuntimeCode} from '../runtime/Code.js';
import {default as R3RuntimeAuthentication} from '../runtime/Authentication.js';
import {default as R3RuntimeAudio} from '../runtime/Audio.js';
import {default as R3EventObj} from '../event/Obj.js';
import {default as R3RuntimeWebsocketSocketIO} from '../runtime/websocket/SocketIO.js';
import {default as R3RuntimeStorageAxios} from '../runtime/storage/Axios.js';
import {default as R3RuntimePhysicsRapier} from '../runtime/physics/Rapier.js';
import {default as R3RuntimeMathsThree} from '../runtime/maths/Three.js';
import {default as R3RuntimeInputThree} from '../runtime/input/Three.js';
import {default as R3RuntimeInputCustom} from '../runtime/input/Custom.js';
import {default as R3RuntimeGuiTweakPane} from '../runtime/gui/TweakPane.js';
import {default as R3RuntimeGraphicsThree} from '../runtime/graphics/Three.js';
import {default as R3RuntimeEntityDefault} from '../runtime/entity/Default.js';
import {default as R3RuntimeCodeAce} from '../runtime/code/Ace.js';
import {default as R3RuntimeAuthenticationAxios} from '../runtime/authentication/Axios.js';
import {default as R3RuntimeAudioThree} from '../runtime/audio/Three.js';
import {default as R3EventObjUser} from '../event/obj/User.js';
import {default as R3EventObjProject} from '../event/obj/Project.js';
import {default as R3EventObjGroup} from '../event/obj/Group.js';
import {default as R3EventObjFile} from '../event/obj/File.js';
import {default as R3EventObjEntity} from '../event/obj/Entity.js';
import {default as R3EventObjComponent} from '../event/obj/Component.js';
import {default as R3EventObjEntityAnimation} from '../event/obj/entity/Animation.js';
import {default as R3EventObjComponentPhysics} from '../event/obj/component/Physics.js';
import {default as R3EventObjComponentMaths} from '../event/obj/component/Maths.js';
import {default as R3EventObjComponentInput} from '../event/obj/component/Input.js';
import {default as R3EventObjComponentGraphics} from '../event/obj/component/Graphics.js';
import {default as R3EventObjComponentCode} from '../event/obj/component/Code.js';
import {default as R3EventObjComponentAudio} from '../event/obj/component/Audio.js';
import {default as R3EventObjEntityAnimationRotation} from '../event/obj/entity/animation/Rotation.js';
import {default as R3EventObjComponentPhysicsWorld} from '../event/obj/component/physics/World.js';
import {default as R3EventObjComponentPhysicsRigidBody} from '../event/obj/component/physics/RigidBody.js';
import {default as R3EventObjComponentPhysicsJoint} from '../event/obj/component/physics/Joint.js';
import {default as R3EventObjComponentPhysicsGravity} from '../event/obj/component/physics/Gravity.js';
import {default as R3EventObjComponentPhysicsCollider} from '../event/obj/component/physics/Collider.js';
import {default as R3EventObjComponentMathsVertex} from '../event/obj/component/maths/Vertex.js';
import {default as R3EventObjComponentMathsVector4} from '../event/obj/component/maths/Vector4.js';
import {default as R3EventObjComponentMathsVector3} from '../event/obj/component/maths/Vector3.js';
import {default as R3EventObjComponentMathsVector2} from '../event/obj/component/maths/Vector2.js';
import {default as R3EventObjComponentMathsUV} from '../event/obj/component/maths/UV.js';
import {default as R3EventObjComponentMathsSphere} from '../event/obj/component/maths/Sphere.js';
import {default as R3EventObjComponentMathsRay} from '../event/obj/component/maths/Ray.js';
import {default as R3EventObjComponentMathsQuaternion} from '../event/obj/component/maths/Quaternion.js';
import {default as R3EventObjComponentMathsPlane} from '../event/obj/component/maths/Plane.js';
import {default as R3EventObjComponentMathsMatrix4} from '../event/obj/component/maths/Matrix4.js';
import {default as R3EventObjComponentMathsMatrix3} from '../event/obj/component/maths/Matrix3.js';
import {default as R3EventObjComponentMathsFace} from '../event/obj/component/maths/Face.js';
import {default as R3EventObjComponentMathsColor} from '../event/obj/component/maths/Color.js';
import {default as R3EventObjComponentInputTouch} from '../event/obj/component/input/Touch.js';
import {default as R3EventObjComponentInputOrbitControls} from '../event/obj/component/input/OrbitControls.js';
import {default as R3EventObjComponentInputMouse} from '../event/obj/component/input/Mouse.js';
import {default as R3EventObjComponentInputKeyboard} from '../event/obj/component/input/Keyboard.js';
import {default as R3EventObjComponentGraphicsVideo} from '../event/obj/component/graphics/Video.js';
import {default as R3EventObjComponentGraphicsTexture} from '../event/obj/component/graphics/Texture.js';
import {default as R3EventObjComponentGraphicsSprite} from '../event/obj/component/graphics/Sprite.js';
import {default as R3EventObjComponentGraphicsSkeleton} from '../event/obj/component/graphics/Skeleton.js';
import {default as R3EventObjComponentGraphicsShadow} from '../event/obj/component/graphics/Shadow.js';
import {default as R3EventObjComponentGraphicsShader} from '../event/obj/component/graphics/Shader.js';
import {default as R3EventObjComponentGraphicsScene} from '../event/obj/component/graphics/Scene.js';
import {default as R3EventObjComponentGraphicsRenderer} from '../event/obj/component/graphics/Renderer.js';
import {default as R3EventObjComponentGraphicsRaycaster} from '../event/obj/component/graphics/Raycaster.js';
import {default as R3EventObjComponentGraphicsMesh} from '../event/obj/component/graphics/Mesh.js';
import {default as R3EventObjComponentGraphicsMaterialGroup} from '../event/obj/component/graphics/MaterialGroup.js';
import {default as R3EventObjComponentGraphicsMaterial} from '../event/obj/component/graphics/Material.js';
import {default as R3EventObjComponentGraphicsLight} from '../event/obj/component/graphics/Light.js';
import {default as R3EventObjComponentGraphicsImage} from '../event/obj/component/graphics/Image.js';
import {default as R3EventObjComponentGraphicsGeometry} from '../event/obj/component/graphics/Geometry.js';
import {default as R3EventObjComponentGraphicsFog} from '../event/obj/component/graphics/Fog.js';
import {default as R3EventObjComponentGraphicsCanvas} from '../event/obj/component/graphics/Canvas.js';
import {default as R3EventObjComponentGraphicsCamera} from '../event/obj/component/graphics/Camera.js';
import {default as R3EventObjComponentAudioPositional} from '../event/obj/component/audio/Positional.js';
import {default as R3EventObjComponentPhysicsRigidBodyFixed} from '../event/obj/component/physics/rigidbody/Fixed.js';
import {default as R3EventObjComponentPhysicsRigidBodyDynamic} from '../event/obj/component/physics/rigidbody/Dynamic.js';
import {default as R3EventObjComponentPhysicsJointSpherical} from '../event/obj/component/physics/joint/Spherical.js';
import {default as R3EventObjComponentPhysicsJointRevolute} from '../event/obj/component/physics/joint/Revolute.js';
import {default as R3EventObjComponentPhysicsJointPrismatic} from '../event/obj/component/physics/joint/Prismatic.js';
import {default as R3EventObjComponentPhysicsJointFixed} from '../event/obj/component/physics/joint/Fixed.js';
import {default as R3EventObjComponentPhysicsColliderTriMesh} from '../event/obj/component/physics/collider/TriMesh.js';
import {default as R3EventObjComponentPhysicsColliderHeightField} from '../event/obj/component/physics/collider/HeightField.js';
import {default as R3EventObjComponentPhysicsColliderCuboid} from '../event/obj/component/physics/collider/Cuboid.js';
import {default as R3EventObjComponentPhysicsColliderCapsule} from '../event/obj/component/physics/collider/Capsule.js';
import {default as R3EventObjComponentPhysicsColliderBall} from '../event/obj/component/physics/collider/Ball.js';
import {default as R3EventObjComponentGraphicsTextureVideo} from '../event/obj/component/graphics/texture/Video.js';
import {default as R3EventObjComponentGraphicsTextureImage} from '../event/obj/component/graphics/texture/Image.js';
import {default as R3EventObjComponentGraphicsTextureCube} from '../event/obj/component/graphics/texture/Cube.js';
import {default as R3EventObjComponentGraphicsTextureCanvas} from '../event/obj/component/graphics/texture/Canvas.js';
import {default as R3EventObjComponentGraphicsShadowSpot} from '../event/obj/component/graphics/shadow/Spot.js';
import {default as R3EventObjComponentGraphicsShadowPoint} from '../event/obj/component/graphics/shadow/Point.js';
import {default as R3EventObjComponentGraphicsShadowDirectional} from '../event/obj/component/graphics/shadow/Directional.js';
import {default as R3EventObjComponentGraphicsRendererTarget} from '../event/obj/component/graphics/renderer/Target.js';
import {default as R3EventObjComponentGraphicsRendererGl} from '../event/obj/component/graphics/renderer/Gl.js';
import {default as R3EventObjComponentGraphicsRendererCube} from '../event/obj/component/graphics/renderer/Cube.js';
import {default as R3EventObjComponentGraphicsMaterialToon} from '../event/obj/component/graphics/material/Toon.js';
import {default as R3EventObjComponentGraphicsMaterialStandard} from '../event/obj/component/graphics/material/Standard.js';
import {default as R3EventObjComponentGraphicsMaterialPoints} from '../event/obj/component/graphics/material/Points.js';
import {default as R3EventObjComponentGraphicsMaterialPhong} from '../event/obj/component/graphics/material/Phong.js';
import {default as R3EventObjComponentGraphicsMaterialLambert} from '../event/obj/component/graphics/material/Lambert.js';
import {default as R3EventObjComponentGraphicsMaterialBasic} from '../event/obj/component/graphics/material/Basic.js';
import {default as R3EventObjComponentGraphicsLightSpot} from '../event/obj/component/graphics/light/Spot.js';
import {default as R3EventObjComponentGraphicsLightPoint} from '../event/obj/component/graphics/light/Point.js';
import {default as R3EventObjComponentGraphicsLightDirectional} from '../event/obj/component/graphics/light/Directional.js';
import {default as R3EventObjComponentGraphicsLightAmbient} from '../event/obj/component/graphics/light/Ambient.js';
import {default as R3EventObjComponentGraphicsGeometryTorus} from '../event/obj/component/graphics/geometry/Torus.js';
import {default as R3EventObjComponentGraphicsGeometrySphere} from '../event/obj/component/graphics/geometry/Sphere.js';
import {default as R3EventObjComponentGraphicsGeometryPlane} from '../event/obj/component/graphics/geometry/Plane.js';
import {default as R3EventObjComponentGraphicsGeometryBuffer} from '../event/obj/component/graphics/geometry/Buffer.js';
import {default as R3EventObjComponentGraphicsGeometryBox} from '../event/obj/component/graphics/geometry/Box.js';
import {default as R3EventObjComponentGraphicsCameraPerspective} from '../event/obj/component/graphics/camera/Perspective.js';
import {default as R3EventObjComponentGraphicsCameraOrthographic} from '../event/obj/component/graphics/camera/Orthographic.js';
import {default as R3EventObjComponentGraphicsCameraCube} from '../event/obj/component/graphics/camera/Cube.js';
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START

//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
const Utils = R3Utils;
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START

 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Users={} - References to all Scenes
  Groups={} - References to all Groups
  Projects={} - References to all Projects
  Components={} - References to all Components
  Entities={} - References to all Entities
  CurrentProject=null
  CurrentUser=null
  CurrentGroup=null
  Events=[] - A list of all events
  Systems=new Set()
  Objects=new Set()
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.INITIALIZE_USER(10)
  Event.INITIALIZE_GROUP(10)
  Event.INITIALIZE_PROJECT(10)
  Event.INITIALIZE_COMPONENT(10)
  Event.INITIALIZE_ENTITY(10)
  Event.DISPOSE_USER(10)
  Event.DISPOSE_GROUP(10)
  Event.DISPOSE_PROJECT(10)
  Event.DISPOSE_COMPONENT(100)
  Event.DISPOSE_ENTITY(10)
  Event.GET_OBJECT_WITH_ID(10)
  Event.GET_OBJECTS_BY_CONSTRUCTOR(10)
  Event.GET_OBJECT_COUNT(10)
  Event.GET_ALL_EVENTS(10)
  Event.GET_CURRENT_USER(10)
  Event.GET_CURRENT_GROUP(10)
  Event.SET_CURRENT_USER(10)
  Event.SET_CURRENT_GROUP(10)
  Event.GET_CURRENT_PROJECT(10)
  Event.SET_CURRENT_PROJECT(10)
  Event.APP_CREATE_COMPONENT(10)
  Event.CREATE_PROJECT(10)
  Event.SET_CURRENT_MODE(10)
  Event.GET_OBJECTS(10)
  Event.GET_COMPONENTS(10)
  Event.GET_ENTITIES(10)
  Event.GET_USERS(10)
  Event.GET_GROUPS(10)
  Event.GET_PROJECTS(10)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START
 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START

 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  Event.GET_R3_EVENT_CONSTRUCTOR - Returns the Event constructor (R3Event)
  Event.GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR - Returns the Audio constructor (R3EventObjComponentAudio)
  Event.GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR - Returns the Positional constructor (R3EventObjComponentAudioPositional)
  Event.GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR - Returns the Code constructor (R3EventObjComponentCode)
  Event.GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR - Returns the Component constructor (R3EventObjComponent)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR - Returns the Camera constructor (R3EventObjComponentGraphicsCamera)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR - Returns the Cube constructor (R3EventObjComponentGraphicsCameraCube)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR - Returns the Orthographic constructor (R3EventObjComponentGraphicsCameraOrthographic)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR - Returns the Perspective constructor (R3EventObjComponentGraphicsCameraPerspective)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR - Returns the Canvas constructor (R3EventObjComponentGraphicsCanvas)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR - Returns the Graphics constructor (R3EventObjComponentGraphics)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR - Returns the Fog constructor (R3EventObjComponentGraphicsFog)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR - Returns the Box constructor (R3EventObjComponentGraphicsGeometryBox)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR - Returns the Buffer constructor (R3EventObjComponentGraphicsGeometryBuffer)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR - Returns the Geometry constructor (R3EventObjComponentGraphicsGeometry)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR - Returns the Plane constructor (R3EventObjComponentGraphicsGeometryPlane)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR - Returns the Sphere constructor (R3EventObjComponentGraphicsGeometrySphere)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR - Returns the Torus constructor (R3EventObjComponentGraphicsGeometryTorus)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR - Returns the Image constructor (R3EventObjComponentGraphicsImage)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR - Returns the Ambient constructor (R3EventObjComponentGraphicsLightAmbient)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR - Returns the Light constructor (R3EventObjComponentGraphicsLight)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR - Returns the Directional constructor (R3EventObjComponentGraphicsLightDirectional)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR - Returns the Point constructor (R3EventObjComponentGraphicsLightPoint)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR - Returns the Spot constructor (R3EventObjComponentGraphicsLightSpot)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR - Returns the Basic constructor (R3EventObjComponentGraphicsMaterialBasic)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR - Returns the Material constructor (R3EventObjComponentGraphicsMaterial)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR - Returns the MaterialGroup constructor (R3EventObjComponentGraphicsMaterialGroup)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR - Returns the Lambert constructor (R3EventObjComponentGraphicsMaterialLambert)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR - Returns the Phong constructor (R3EventObjComponentGraphicsMaterialPhong)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR - Returns the Points constructor (R3EventObjComponentGraphicsMaterialPoints)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR - Returns the Standard constructor (R3EventObjComponentGraphicsMaterialStandard)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR - Returns the Toon constructor (R3EventObjComponentGraphicsMaterialToon)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR - Returns the Mesh constructor (R3EventObjComponentGraphicsMesh)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR - Returns the Raycaster constructor (R3EventObjComponentGraphicsRaycaster)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR - Returns the Renderer constructor (R3EventObjComponentGraphicsRenderer)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR - Returns the Cube constructor (R3EventObjComponentGraphicsRendererCube)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR - Returns the Gl constructor (R3EventObjComponentGraphicsRendererGl)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR - Returns the Target constructor (R3EventObjComponentGraphicsRendererTarget)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR - Returns the Scene constructor (R3EventObjComponentGraphicsScene)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR - Returns the Shader constructor (R3EventObjComponentGraphicsShader)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR - Returns the Shadow constructor (R3EventObjComponentGraphicsShadow)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR - Returns the Directional constructor (R3EventObjComponentGraphicsShadowDirectional)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR - Returns the Point constructor (R3EventObjComponentGraphicsShadowPoint)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR - Returns the Spot constructor (R3EventObjComponentGraphicsShadowSpot)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR - Returns the Skeleton constructor (R3EventObjComponentGraphicsSkeleton)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR - Returns the Sprite constructor (R3EventObjComponentGraphicsSprite)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR - Returns the Canvas constructor (R3EventObjComponentGraphicsTextureCanvas)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR - Returns the Texture constructor (R3EventObjComponentGraphicsTexture)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR - Returns the Cube constructor (R3EventObjComponentGraphicsTextureCube)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR - Returns the Image constructor (R3EventObjComponentGraphicsTextureImage)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR - Returns the Video constructor (R3EventObjComponentGraphicsTextureVideo)
  Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR - Returns the Video constructor (R3EventObjComponentGraphicsVideo)
  Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR - Returns the Input constructor (R3EventObjComponentInput)
  Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR - Returns the Keyboard constructor (R3EventObjComponentInputKeyboard)
  Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR - Returns the Mouse constructor (R3EventObjComponentInputMouse)
  Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR - Returns the OrbitControls constructor (R3EventObjComponentInputOrbitControls)
  Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR - Returns the Touch constructor (R3EventObjComponentInputTouch)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR - Returns the Color constructor (R3EventObjComponentMathsColor)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR - Returns the Maths constructor (R3EventObjComponentMaths)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR - Returns the Face constructor (R3EventObjComponentMathsFace)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR - Returns the Matrix3 constructor (R3EventObjComponentMathsMatrix3)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR - Returns the Matrix4 constructor (R3EventObjComponentMathsMatrix4)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR - Returns the Plane constructor (R3EventObjComponentMathsPlane)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR - Returns the Quaternion constructor (R3EventObjComponentMathsQuaternion)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR - Returns the Ray constructor (R3EventObjComponentMathsRay)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR - Returns the Sphere constructor (R3EventObjComponentMathsSphere)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR - Returns the UV constructor (R3EventObjComponentMathsUV)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR - Returns the Vector2 constructor (R3EventObjComponentMathsVector2)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR - Returns the Vector3 constructor (R3EventObjComponentMathsVector3)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR - Returns the Vector4 constructor (R3EventObjComponentMathsVector4)
  Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR - Returns the Vertex constructor (R3EventObjComponentMathsVertex)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR - Returns the Ball constructor (R3EventObjComponentPhysicsColliderBall)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR - Returns the Capsule constructor (R3EventObjComponentPhysicsColliderCapsule)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR - Returns the Collider constructor (R3EventObjComponentPhysicsCollider)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR - Returns the Cuboid constructor (R3EventObjComponentPhysicsColliderCuboid)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR - Returns the HeightField constructor (R3EventObjComponentPhysicsColliderHeightField)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR - Returns the TriMesh constructor (R3EventObjComponentPhysicsColliderTriMesh)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR - Returns the Physics constructor (R3EventObjComponentPhysics)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR - Returns the Gravity constructor (R3EventObjComponentPhysicsGravity)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR - Returns the Joint constructor (R3EventObjComponentPhysicsJoint)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR - Returns the Fixed constructor (R3EventObjComponentPhysicsJointFixed)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR - Returns the Prismatic constructor (R3EventObjComponentPhysicsJointPrismatic)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR - Returns the Revolute constructor (R3EventObjComponentPhysicsJointRevolute)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR - Returns the Spherical constructor (R3EventObjComponentPhysicsJointSpherical)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR - Returns the RigidBody constructor (R3EventObjComponentPhysicsRigidBody)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR - Returns the Dynamic constructor (R3EventObjComponentPhysicsRigidBodyDynamic)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR - Returns the Fixed constructor (R3EventObjComponentPhysicsRigidBodyFixed)
  Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR - Returns the World constructor (R3EventObjComponentPhysicsWorld)
  Event.GET_R3_EVENT_OBJ_CONSTRUCTOR - Returns the Obj constructor (R3EventObj)
  Event.GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR - Returns the Animation constructor (R3EventObjEntityAnimation)
  Event.GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR - Returns the Rotation constructor (R3EventObjEntityAnimationRotation)
  Event.GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR - Returns the Entity constructor (R3EventObjEntity)
  Event.GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR - Returns the File constructor (R3EventObjFile)
  Event.GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR - Returns the Group constructor (R3EventObjGroup)
  Event.GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR - Returns the Project constructor (R3EventObjProject)
  Event.GET_R3_EVENT_OBJ_USER_CONSTRUCTOR - Returns the User constructor (R3EventObjUser)
  Event.GET_R3_GRAPH_CONSTRUCTOR - Returns the Graph constructor (R3Graph)
  Event.GET_R3_MOCK_RAPIER_CONSTRUCTOR - Returns the MockRapier constructor (R3MockRapier)
  Event.GET_R3_NODE_CONSTRUCTOR - Returns the Node constructor (R3Node)
  Event.GET_R3_RUNTIME_AUDIO_CONSTRUCTOR - Returns the Audio constructor (R3RuntimeAudio)
  Event.GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR - Returns the Three constructor (R3RuntimeAudioThree)
  Event.GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR - Returns the Axios constructor (R3RuntimeAuthenticationAxios)
  Event.GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR - Returns the Authentication constructor (R3RuntimeAuthentication)
  Event.GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR - Returns the Ace constructor (R3RuntimeCodeAce)
  Event.GET_R3_RUNTIME_CODE_CONSTRUCTOR - Returns the Code constructor (R3RuntimeCode)
  Event.GET_R3_RUNTIME_CONSTRUCTOR - Returns the Runtime constructor (R3Runtime)
  Event.GET_R3_RUNTIME_ENTITY_CONSTRUCTOR - Returns the Entity constructor (R3RuntimeEntity)
  Event.GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR - Returns the Default constructor (R3RuntimeEntityDefault)
  Event.GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR - Returns the Graphics constructor (R3RuntimeGraphics)
  Event.GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR - Returns the Three constructor (R3RuntimeGraphicsThree)
  Event.GET_R3_RUNTIME_GUI_CONSTRUCTOR - Returns the Gui constructor (R3RuntimeGui)
  Event.GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR - Returns the TweakPane constructor (R3RuntimeGuiTweakPane)
  Event.GET_R3_RUNTIME_INPUT_CONSTRUCTOR - Returns the Input constructor (R3RuntimeInput)
  Event.GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR - Returns the Custom constructor (R3RuntimeInputCustom)
  Event.GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR - Returns the Three constructor (R3RuntimeInputThree)
  Event.GET_R3_RUNTIME_MATHS_CONSTRUCTOR - Returns the Maths constructor (R3RuntimeMaths)
  Event.GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR - Returns the Three constructor (R3RuntimeMathsThree)
  Event.GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR - Returns the Physics constructor (R3RuntimePhysics)
  Event.GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR - Returns the Rapier constructor (R3RuntimePhysicsRapier)
  Event.GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR - Returns the Axios constructor (R3RuntimeStorageAxios)
  Event.GET_R3_RUNTIME_STORAGE_CONSTRUCTOR - Returns the Storage constructor (R3RuntimeStorage)
  Event.GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR - Returns the Websocket constructor (R3RuntimeWebsocket)
  Event.GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR - Returns the SocketIO constructor (R3RuntimeWebsocketSocketIO)
  Event.GET_R3_SYSTEM_AUDIO_CONSTRUCTOR - Returns the Audio constructor (R3SystemAudio)
  Event.GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR - Returns the Authentication constructor (R3SystemAuthentication)
  Event.GET_R3_SYSTEM_CODE_CONSTRUCTOR - Returns the Code constructor (R3SystemCode)
  Event.GET_R3_SYSTEM_CONSTRUCTOR - Returns the System constructor (R3System)
  Event.GET_R3_SYSTEM_ENTITY_CONSTRUCTOR - Returns the Entity constructor (R3SystemEntity)
  Event.GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR - Returns the Graphics constructor (R3SystemGraphics)
  Event.GET_R3_SYSTEM_GUI_CONSTRUCTOR - Returns the Gui constructor (R3SystemGui)
  Event.GET_R3_SYSTEM_INPUT_CONSTRUCTOR - Returns the Input constructor (R3SystemInput)
  Event.GET_R3_SYSTEM_MATHS_CONSTRUCTOR - Returns the Maths constructor (R3SystemMaths)
  Event.GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR - Returns the Physics constructor (R3SystemPhysics)
  Event.GET_R3_SYSTEM_STORAGE_CONSTRUCTOR - Returns the Storage constructor (R3SystemStorage)
  Event.GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR - Returns the Websocket constructor (R3SystemWebsocket)
  Event.GET_R3_UTILS_CONSTRUCTOR - Returns the Utils constructor (R3Utils)
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options) - Starts the system by registering subscriptions to events
  stop(options) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  SetParentChildRelationships(child, parent, oldReference = null) - Sets parent and child relationships on the passed in child and parent
  GetConstructor(referenceType) - Gets the constructor based on the reference type ID
 CUSTOM_STATIC_METHODS_END

 **/

export class Linking extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Linking already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Linking');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Linking already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Linking');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Linking.IsStarting) {
      console.log('client Linking system is already starting...');
      return;
    }

    Linking.IsStarting = true;

    if (Linking.Started === true) {
      Linking.IsStarting = false;
      console.log('client Linking system already started');
      return;
    }

    Linking.Runtimes = new Set();
    Linking.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Linking.Subscriptions['INITIALIZE_USER'] = Event.On(
      Event.INITIALIZE_USER,
      Linking.InitializeUser,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['INITIALIZE_GROUP'] = Event.On(
      Event.INITIALIZE_GROUP,
      Linking.InitializeGroup,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['INITIALIZE_PROJECT'] = Event.On(
      Event.INITIALIZE_PROJECT,
      Linking.InitializeProject,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['INITIALIZE_COMPONENT'] = Event.On(
      Event.INITIALIZE_COMPONENT,
      Linking.InitializeComponent,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['INITIALIZE_ENTITY'] = Event.On(
      Event.INITIALIZE_ENTITY,
      Linking.InitializeEntity,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['DISPOSE_USER'] = Event.On(
      Event.DISPOSE_USER,
      Linking.DisposeUser,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['DISPOSE_GROUP'] = Event.On(
      Event.DISPOSE_GROUP,
      Linking.DisposeGroup,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['DISPOSE_PROJECT'] = Event.On(
      Event.DISPOSE_PROJECT,
      Linking.DisposeProject,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['DISPOSE_COMPONENT'] = Event.On(
      Event.DISPOSE_COMPONENT,
      Linking.DisposeComponent,
      {priority: 100}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['DISPOSE_ENTITY'] = Event.On(
      Event.DISPOSE_ENTITY,
      Linking.DisposeEntity,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_OBJECT_WITH_ID'] = Event.On(
      Event.GET_OBJECT_WITH_ID,
      Linking.GetObjectWithId,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_OBJECTS_BY_CONSTRUCTOR'] = Event.On(
      Event.GET_OBJECTS_BY_CONSTRUCTOR,
      Linking.GetObjectsByConstructor,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_OBJECT_COUNT'] = Event.On(
      Event.GET_OBJECT_COUNT,
      Linking.GetObjectCount,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_ALL_EVENTS'] = Event.On(
      Event.GET_ALL_EVENTS,
      Linking.GetAllEvents,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_CURRENT_USER'] = Event.On(
      Event.GET_CURRENT_USER,
      Linking.GetCurrentUser,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_CURRENT_GROUP'] = Event.On(
      Event.GET_CURRENT_GROUP,
      Linking.GetCurrentGroup,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['SET_CURRENT_USER'] = Event.On(
      Event.SET_CURRENT_USER,
      Linking.SetCurrentUser,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['SET_CURRENT_GROUP'] = Event.On(
      Event.SET_CURRENT_GROUP,
      Linking.SetCurrentGroup,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_CURRENT_PROJECT'] = Event.On(
      Event.GET_CURRENT_PROJECT,
      Linking.GetCurrentProject,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['SET_CURRENT_PROJECT'] = Event.On(
      Event.SET_CURRENT_PROJECT,
      Linking.SetCurrentProject,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['APP_CREATE_COMPONENT'] = Event.On(
      Event.APP_CREATE_COMPONENT,
      Linking.AppCreateComponent,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['CREATE_PROJECT'] = Event.On(
      Event.CREATE_PROJECT,
      Linking.CreateProject,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['SET_CURRENT_MODE'] = Event.On(
      Event.SET_CURRENT_MODE,
      Linking.SetCurrentMode,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_OBJECTS'] = Event.On(
      Event.GET_OBJECTS,
      Linking.GetObjects,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_COMPONENTS'] = Event.On(
      Event.GET_COMPONENTS,
      Linking.GetComponents,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_ENTITIES'] = Event.On(
      Event.GET_ENTITIES,
      Linking.GetEntities,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_USERS'] = Event.On(
      Event.GET_USERS,
      Linking.GetUsers,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_GROUPS'] = Event.On(
      Event.GET_GROUPS,
      Linking.GetGroups,
      {priority: 10}
    );

    /**
     * No comment
     */
    Linking.Subscriptions['GET_PROJECTS'] = Event.On(
      Event.GET_PROJECTS,
      Linking.GetProjects,
      {priority: 10}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    /**
     * Returns the Event constructor (R3Event)
     */
    Linking.Subscriptions['GET_R3_EVENT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_CONSTRUCTOR,
      Linking.GetR3EventConstructor
    );

    /**
     * Returns the Audio constructor (R3EventObjComponentAudio)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR,
      Linking.GetR3EventObjComponentAudioConstructor
    );

    /**
     * Returns the Positional constructor (R3EventObjComponentAudioPositional)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR,
      Linking.GetR3EventObjComponentAudioPositionalConstructor
    );

    /**
     * Returns the Code constructor (R3EventObjComponentCode)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentCodeConstructor
    );

    /**
     * Returns the Component constructor (R3EventObjComponent)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR,
      Linking.GetR3EventObjComponentConstructor
    );

    /**
     * Returns the Camera constructor (R3EventObjComponentGraphicsCamera)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsCameraConstructor
    );

    /**
     * Returns the Cube constructor (R3EventObjComponentGraphicsCameraCube)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsCameraCubeConstructor
    );

    /**
     * Returns the Orthographic constructor (R3EventObjComponentGraphicsCameraOrthographic)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsCameraOrthographicConstructor
    );

    /**
     * Returns the Perspective constructor (R3EventObjComponentGraphicsCameraPerspective)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsCameraPerspectiveConstructor
    );

    /**
     * Returns the Canvas constructor (R3EventObjComponentGraphicsCanvas)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsCanvasConstructor
    );

    /**
     * Returns the Graphics constructor (R3EventObjComponentGraphics)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsConstructor
    );

    /**
     * Returns the Fog constructor (R3EventObjComponentGraphicsFog)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsFogConstructor
    );

    /**
     * Returns the Box constructor (R3EventObjComponentGraphicsGeometryBox)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsGeometryBoxConstructor
    );

    /**
     * Returns the Buffer constructor (R3EventObjComponentGraphicsGeometryBuffer)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsGeometryBufferConstructor
    );

    /**
     * Returns the Geometry constructor (R3EventObjComponentGraphicsGeometry)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsGeometryConstructor
    );

    /**
     * Returns the Plane constructor (R3EventObjComponentGraphicsGeometryPlane)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsGeometryPlaneConstructor
    );

    /**
     * Returns the Sphere constructor (R3EventObjComponentGraphicsGeometrySphere)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsGeometrySphereConstructor
    );

    /**
     * Returns the Torus constructor (R3EventObjComponentGraphicsGeometryTorus)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsGeometryTorusConstructor
    );

    /**
     * Returns the Image constructor (R3EventObjComponentGraphicsImage)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsImageConstructor
    );

    /**
     * Returns the Ambient constructor (R3EventObjComponentGraphicsLightAmbient)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsLightAmbientConstructor
    );

    /**
     * Returns the Light constructor (R3EventObjComponentGraphicsLight)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsLightConstructor
    );

    /**
     * Returns the Directional constructor (R3EventObjComponentGraphicsLightDirectional)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsLightDirectionalConstructor
    );

    /**
     * Returns the Point constructor (R3EventObjComponentGraphicsLightPoint)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsLightPointConstructor
    );

    /**
     * Returns the Spot constructor (R3EventObjComponentGraphicsLightSpot)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsLightSpotConstructor
    );

    /**
     * Returns the Basic constructor (R3EventObjComponentGraphicsMaterialBasic)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsMaterialBasicConstructor
    );

    /**
     * Returns the Material constructor (R3EventObjComponentGraphicsMaterial)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsMaterialConstructor
    );

    /**
     * Returns the MaterialGroup constructor (R3EventObjComponentGraphicsMaterialGroup)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsMaterialGroupConstructor
    );

    /**
     * Returns the Lambert constructor (R3EventObjComponentGraphicsMaterialLambert)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsMaterialLambertConstructor
    );

    /**
     * Returns the Phong constructor (R3EventObjComponentGraphicsMaterialPhong)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsMaterialPhongConstructor
    );

    /**
     * Returns the Points constructor (R3EventObjComponentGraphicsMaterialPoints)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsMaterialPointsConstructor
    );

    /**
     * Returns the Standard constructor (R3EventObjComponentGraphicsMaterialStandard)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsMaterialStandardConstructor
    );

    /**
     * Returns the Toon constructor (R3EventObjComponentGraphicsMaterialToon)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsMaterialToonConstructor
    );

    /**
     * Returns the Mesh constructor (R3EventObjComponentGraphicsMesh)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsMeshConstructor
    );

    /**
     * Returns the Raycaster constructor (R3EventObjComponentGraphicsRaycaster)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsRaycasterConstructor
    );

    /**
     * Returns the Renderer constructor (R3EventObjComponentGraphicsRenderer)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsRendererConstructor
    );

    /**
     * Returns the Cube constructor (R3EventObjComponentGraphicsRendererCube)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsRendererCubeConstructor
    );

    /**
     * Returns the Gl constructor (R3EventObjComponentGraphicsRendererGl)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsRendererGlConstructor
    );

    /**
     * Returns the Target constructor (R3EventObjComponentGraphicsRendererTarget)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsRendererTargetConstructor
    );

    /**
     * Returns the Scene constructor (R3EventObjComponentGraphicsScene)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsSceneConstructor
    );

    /**
     * Returns the Shader constructor (R3EventObjComponentGraphicsShader)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsShaderConstructor
    );

    /**
     * Returns the Shadow constructor (R3EventObjComponentGraphicsShadow)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsShadowConstructor
    );

    /**
     * Returns the Directional constructor (R3EventObjComponentGraphicsShadowDirectional)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsShadowDirectionalConstructor
    );

    /**
     * Returns the Point constructor (R3EventObjComponentGraphicsShadowPoint)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsShadowPointConstructor
    );

    /**
     * Returns the Spot constructor (R3EventObjComponentGraphicsShadowSpot)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsShadowSpotConstructor
    );

    /**
     * Returns the Skeleton constructor (R3EventObjComponentGraphicsSkeleton)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsSkeletonConstructor
    );

    /**
     * Returns the Sprite constructor (R3EventObjComponentGraphicsSprite)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsSpriteConstructor
    );

    /**
     * Returns the Canvas constructor (R3EventObjComponentGraphicsTextureCanvas)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsTextureCanvasConstructor
    );

    /**
     * Returns the Texture constructor (R3EventObjComponentGraphicsTexture)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsTextureConstructor
    );

    /**
     * Returns the Cube constructor (R3EventObjComponentGraphicsTextureCube)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsTextureCubeConstructor
    );

    /**
     * Returns the Image constructor (R3EventObjComponentGraphicsTextureImage)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsTextureImageConstructor
    );

    /**
     * Returns the Video constructor (R3EventObjComponentGraphicsTextureVideo)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsTextureVideoConstructor
    );

    /**
     * Returns the Video constructor (R3EventObjComponentGraphicsVideo)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR,
      Linking.GetR3EventObjComponentGraphicsVideoConstructor
    );

    /**
     * Returns the Input constructor (R3EventObjComponentInput)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR,
      Linking.GetR3EventObjComponentInputConstructor
    );

    /**
     * Returns the Keyboard constructor (R3EventObjComponentInputKeyboard)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR,
      Linking.GetR3EventObjComponentInputKeyboardConstructor
    );

    /**
     * Returns the Mouse constructor (R3EventObjComponentInputMouse)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentInputMouseConstructor
    );

    /**
     * Returns the OrbitControls constructor (R3EventObjComponentInputOrbitControls)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR,
      Linking.GetR3EventObjComponentInputOrbitControlsConstructor
    );

    /**
     * Returns the Touch constructor (R3EventObjComponentInputTouch)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR,
      Linking.GetR3EventObjComponentInputTouchConstructor
    );

    /**
     * Returns the Color constructor (R3EventObjComponentMathsColor)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsColorConstructor
    );

    /**
     * Returns the Maths constructor (R3EventObjComponentMaths)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsConstructor
    );

    /**
     * Returns the Face constructor (R3EventObjComponentMathsFace)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsFaceConstructor
    );

    /**
     * Returns the Matrix3 constructor (R3EventObjComponentMathsMatrix3)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsMatrix3Constructor
    );

    /**
     * Returns the Matrix4 constructor (R3EventObjComponentMathsMatrix4)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsMatrix4Constructor
    );

    /**
     * Returns the Plane constructor (R3EventObjComponentMathsPlane)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsPlaneConstructor
    );

    /**
     * Returns the Quaternion constructor (R3EventObjComponentMathsQuaternion)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsQuaternionConstructor
    );

    /**
     * Returns the Ray constructor (R3EventObjComponentMathsRay)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsRayConstructor
    );

    /**
     * Returns the Sphere constructor (R3EventObjComponentMathsSphere)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsSphereConstructor
    );

    /**
     * Returns the UV constructor (R3EventObjComponentMathsUV)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsUVConstructor
    );

    /**
     * Returns the Vector2 constructor (R3EventObjComponentMathsVector2)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsVector2Constructor
    );

    /**
     * Returns the Vector3 constructor (R3EventObjComponentMathsVector3)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsVector3Constructor
    );

    /**
     * Returns the Vector4 constructor (R3EventObjComponentMathsVector4)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsVector4Constructor
    );

    /**
     * Returns the Vertex constructor (R3EventObjComponentMathsVertex)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR,
      Linking.GetR3EventObjComponentMathsVertexConstructor
    );

    /**
     * Returns the Ball constructor (R3EventObjComponentPhysicsColliderBall)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsColliderBallConstructor
    );

    /**
     * Returns the Capsule constructor (R3EventObjComponentPhysicsColliderCapsule)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsColliderCapsuleConstructor
    );

    /**
     * Returns the Collider constructor (R3EventObjComponentPhysicsCollider)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsColliderConstructor
    );

    /**
     * Returns the Cuboid constructor (R3EventObjComponentPhysicsColliderCuboid)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsColliderCuboidConstructor
    );

    /**
     * Returns the HeightField constructor (R3EventObjComponentPhysicsColliderHeightField)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsColliderHeightFieldConstructor
    );

    /**
     * Returns the TriMesh constructor (R3EventObjComponentPhysicsColliderTriMesh)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsColliderTriMeshConstructor
    );

    /**
     * Returns the Physics constructor (R3EventObjComponentPhysics)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsConstructor
    );

    /**
     * Returns the Gravity constructor (R3EventObjComponentPhysicsGravity)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsGravityConstructor
    );

    /**
     * Returns the Joint constructor (R3EventObjComponentPhysicsJoint)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsJointConstructor
    );

    /**
     * Returns the Fixed constructor (R3EventObjComponentPhysicsJointFixed)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsJointFixedConstructor
    );

    /**
     * Returns the Prismatic constructor (R3EventObjComponentPhysicsJointPrismatic)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsJointPrismaticConstructor
    );

    /**
     * Returns the Revolute constructor (R3EventObjComponentPhysicsJointRevolute)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsJointRevoluteConstructor
    );

    /**
     * Returns the Spherical constructor (R3EventObjComponentPhysicsJointSpherical)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsJointSphericalConstructor
    );

    /**
     * Returns the RigidBody constructor (R3EventObjComponentPhysicsRigidBody)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsRigidBodyConstructor
    );

    /**
     * Returns the Dynamic constructor (R3EventObjComponentPhysicsRigidBodyDynamic)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsRigidBodyDynamicConstructor
    );

    /**
     * Returns the Fixed constructor (R3EventObjComponentPhysicsRigidBodyFixed)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsRigidBodyFixedConstructor
    );

    /**
     * Returns the World constructor (R3EventObjComponentPhysicsWorld)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR,
      Linking.GetR3EventObjComponentPhysicsWorldConstructor
    );

    /**
     * Returns the Obj constructor (R3EventObj)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_CONSTRUCTOR,
      Linking.GetR3EventObjConstructor
    );

    /**
     * Returns the Animation constructor (R3EventObjEntityAnimation)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR,
      Linking.GetR3EventObjEntityAnimationConstructor
    );

    /**
     * Returns the Rotation constructor (R3EventObjEntityAnimationRotation)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR,
      Linking.GetR3EventObjEntityAnimationRotationConstructor
    );

    /**
     * Returns the Entity constructor (R3EventObjEntity)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR,
      Linking.GetR3EventObjEntityConstructor
    );

    /**
     * Returns the File constructor (R3EventObjFile)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR,
      Linking.GetR3EventObjFileConstructor
    );

    /**
     * Returns the Group constructor (R3EventObjGroup)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR,
      Linking.GetR3EventObjGroupConstructor
    );

    /**
     * Returns the Project constructor (R3EventObjProject)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR,
      Linking.GetR3EventObjProjectConstructor
    );

    /**
     * Returns the User constructor (R3EventObjUser)
     */
    Linking.Subscriptions['GET_R3_EVENT_OBJ_USER_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_EVENT_OBJ_USER_CONSTRUCTOR,
      Linking.GetR3EventObjUserConstructor
    );

    /**
     * Returns the Graph constructor (R3Graph)
     */
    Linking.Subscriptions['GET_R3_GRAPH_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_GRAPH_CONSTRUCTOR,
      Linking.GetR3GraphConstructor
    );

    /**
     * Returns the MockRapier constructor (R3MockRapier)
     */
    Linking.Subscriptions['GET_R3_MOCK_RAPIER_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_MOCK_RAPIER_CONSTRUCTOR,
      Linking.GetR3MockRapierConstructor
    );

    /**
     * Returns the Node constructor (R3Node)
     */
    Linking.Subscriptions['GET_R3_NODE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_NODE_CONSTRUCTOR,
      Linking.GetR3NodeConstructor
    );

    /**
     * Returns the Audio constructor (R3RuntimeAudio)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_AUDIO_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_AUDIO_CONSTRUCTOR,
      Linking.GetR3RuntimeAudioConstructor
    );

    /**
     * Returns the Three constructor (R3RuntimeAudioThree)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR,
      Linking.GetR3RuntimeAudioThreeConstructor
    );

    /**
     * Returns the Axios constructor (R3RuntimeAuthenticationAxios)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR,
      Linking.GetR3RuntimeAuthenticationAxiosConstructor
    );

    /**
     * Returns the Authentication constructor (R3RuntimeAuthentication)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR,
      Linking.GetR3RuntimeAuthenticationConstructor
    );

    /**
     * Returns the Ace constructor (R3RuntimeCodeAce)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR,
      Linking.GetR3RuntimeCodeAceConstructor
    );

    /**
     * Returns the Code constructor (R3RuntimeCode)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_CODE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_CODE_CONSTRUCTOR,
      Linking.GetR3RuntimeCodeConstructor
    );

    /**
     * Returns the Runtime constructor (R3Runtime)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_CONSTRUCTOR,
      Linking.GetR3RuntimeConstructor
    );

    /**
     * Returns the Entity constructor (R3RuntimeEntity)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_ENTITY_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_ENTITY_CONSTRUCTOR,
      Linking.GetR3RuntimeEntityConstructor
    );

    /**
     * Returns the Default constructor (R3RuntimeEntityDefault)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR,
      Linking.GetR3RuntimeEntityDefaultConstructor
    );

    /**
     * Returns the Graphics constructor (R3RuntimeGraphics)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR,
      Linking.GetR3RuntimeGraphicsConstructor
    );

    /**
     * Returns the Three constructor (R3RuntimeGraphicsThree)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR,
      Linking.GetR3RuntimeGraphicsThreeConstructor
    );

    /**
     * Returns the Gui constructor (R3RuntimeGui)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_GUI_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_GUI_CONSTRUCTOR,
      Linking.GetR3RuntimeGuiConstructor
    );

    /**
     * Returns the TweakPane constructor (R3RuntimeGuiTweakPane)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR,
      Linking.GetR3RuntimeGuiTweakPaneConstructor
    );

    /**
     * Returns the Input constructor (R3RuntimeInput)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_INPUT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_INPUT_CONSTRUCTOR,
      Linking.GetR3RuntimeInputConstructor
    );

    /**
     * Returns the Custom constructor (R3RuntimeInputCustom)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR,
      Linking.GetR3RuntimeInputCustomConstructor
    );

    /**
     * Returns the Three constructor (R3RuntimeInputThree)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR,
      Linking.GetR3RuntimeInputThreeConstructor
    );

    /**
     * Returns the Maths constructor (R3RuntimeMaths)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_MATHS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_MATHS_CONSTRUCTOR,
      Linking.GetR3RuntimeMathsConstructor
    );

    /**
     * Returns the Three constructor (R3RuntimeMathsThree)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR,
      Linking.GetR3RuntimeMathsThreeConstructor
    );

    /**
     * Returns the Physics constructor (R3RuntimePhysics)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR,
      Linking.GetR3RuntimePhysicsConstructor
    );

    /**
     * Returns the Rapier constructor (R3RuntimePhysicsRapier)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR,
      Linking.GetR3RuntimePhysicsRapierConstructor
    );

    /**
     * Returns the Axios constructor (R3RuntimeStorageAxios)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR,
      Linking.GetR3RuntimeStorageAxiosConstructor
    );

    /**
     * Returns the Storage constructor (R3RuntimeStorage)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_STORAGE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_STORAGE_CONSTRUCTOR,
      Linking.GetR3RuntimeStorageConstructor
    );

    /**
     * Returns the Websocket constructor (R3RuntimeWebsocket)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR,
      Linking.GetR3RuntimeWebsocketConstructor
    );

    /**
     * Returns the SocketIO constructor (R3RuntimeWebsocketSocketIO)
     */
    Linking.Subscriptions['GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR,
      Linking.GetR3RuntimeWebsocketSocketIOConstructor
    );

    /**
     * Returns the Audio constructor (R3SystemAudio)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_AUDIO_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_AUDIO_CONSTRUCTOR,
      Linking.GetR3SystemAudioConstructor
    );

    /**
     * Returns the Authentication constructor (R3SystemAuthentication)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR,
      Linking.GetR3SystemAuthenticationConstructor
    );

    /**
     * Returns the Code constructor (R3SystemCode)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_CODE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_CODE_CONSTRUCTOR,
      Linking.GetR3SystemCodeConstructor
    );

    /**
     * Returns the System constructor (R3System)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_CONSTRUCTOR,
      Linking.GetR3SystemConstructor
    );

    /**
     * Returns the Entity constructor (R3SystemEntity)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_ENTITY_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_ENTITY_CONSTRUCTOR,
      Linking.GetR3SystemEntityConstructor
    );

    /**
     * Returns the Graphics constructor (R3SystemGraphics)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR,
      Linking.GetR3SystemGraphicsConstructor
    );

    /**
     * Returns the Gui constructor (R3SystemGui)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_GUI_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_GUI_CONSTRUCTOR,
      Linking.GetR3SystemGuiConstructor
    );

    /**
     * Returns the Input constructor (R3SystemInput)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_INPUT_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_INPUT_CONSTRUCTOR,
      Linking.GetR3SystemInputConstructor
    );

    /**
     * Returns the Maths constructor (R3SystemMaths)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_MATHS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_MATHS_CONSTRUCTOR,
      Linking.GetR3SystemMathsConstructor
    );

    /**
     * Returns the Physics constructor (R3SystemPhysics)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR,
      Linking.GetR3SystemPhysicsConstructor
    );

    /**
     * Returns the Storage constructor (R3SystemStorage)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_STORAGE_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_STORAGE_CONSTRUCTOR,
      Linking.GetR3SystemStorageConstructor
    );

    /**
     * Returns the Websocket constructor (R3SystemWebsocket)
     */
    Linking.Subscriptions['GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR,
      Linking.GetR3SystemWebsocketConstructor
    );

    /**
     * Returns the Utils constructor (R3Utils)
     */
    Linking.Subscriptions['GET_R3_UTILS_CONSTRUCTOR'] = Event.On(
      Event.GET_R3_UTILS_CONSTRUCTOR,
      Linking.GetR3UtilsConstructor
    );
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    Linking.Started = true;
    Linking.IsStarting = false;
    console.log('Started client system Linking');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Linking.IsStopping) {
      console.log('client Linking system is already stopping');
      return;
    }

    Linking.IsStopping = true;

    if (Linking.Started === false) {
      Linking.IsStopping = false;
      console.log('client Linking system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    Linking.Subscriptions['INITIALIZE_USER'].remove();
    delete Linking.Subscriptions['INITIALIZE_USER'];

    Linking.Subscriptions['INITIALIZE_GROUP'].remove();
    delete Linking.Subscriptions['INITIALIZE_GROUP'];

    Linking.Subscriptions['INITIALIZE_PROJECT'].remove();
    delete Linking.Subscriptions['INITIALIZE_PROJECT'];

    Linking.Subscriptions['INITIALIZE_COMPONENT'].remove();
    delete Linking.Subscriptions['INITIALIZE_COMPONENT'];

    Linking.Subscriptions['INITIALIZE_ENTITY'].remove();
    delete Linking.Subscriptions['INITIALIZE_ENTITY'];

    Linking.Subscriptions['DISPOSE_USER'].remove();
    delete Linking.Subscriptions['DISPOSE_USER'];

    Linking.Subscriptions['DISPOSE_GROUP'].remove();
    delete Linking.Subscriptions['DISPOSE_GROUP'];

    Linking.Subscriptions['DISPOSE_PROJECT'].remove();
    delete Linking.Subscriptions['DISPOSE_PROJECT'];

    Linking.Subscriptions['DISPOSE_COMPONENT'].remove();
    delete Linking.Subscriptions['DISPOSE_COMPONENT'];

    Linking.Subscriptions['DISPOSE_ENTITY'].remove();
    delete Linking.Subscriptions['DISPOSE_ENTITY'];

    Linking.Subscriptions['GET_OBJECT_WITH_ID'].remove();
    delete Linking.Subscriptions['GET_OBJECT_WITH_ID'];

    Linking.Subscriptions['GET_OBJECTS_BY_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_OBJECTS_BY_CONSTRUCTOR'];

    Linking.Subscriptions['GET_OBJECT_COUNT'].remove();
    delete Linking.Subscriptions['GET_OBJECT_COUNT'];

    Linking.Subscriptions['GET_ALL_EVENTS'].remove();
    delete Linking.Subscriptions['GET_ALL_EVENTS'];

    Linking.Subscriptions['GET_CURRENT_USER'].remove();
    delete Linking.Subscriptions['GET_CURRENT_USER'];

    Linking.Subscriptions['GET_CURRENT_GROUP'].remove();
    delete Linking.Subscriptions['GET_CURRENT_GROUP'];

    Linking.Subscriptions['SET_CURRENT_USER'].remove();
    delete Linking.Subscriptions['SET_CURRENT_USER'];

    Linking.Subscriptions['SET_CURRENT_GROUP'].remove();
    delete Linking.Subscriptions['SET_CURRENT_GROUP'];

    Linking.Subscriptions['GET_CURRENT_PROJECT'].remove();
    delete Linking.Subscriptions['GET_CURRENT_PROJECT'];

    Linking.Subscriptions['SET_CURRENT_PROJECT'].remove();
    delete Linking.Subscriptions['SET_CURRENT_PROJECT'];

    Linking.Subscriptions['APP_CREATE_COMPONENT'].remove();
    delete Linking.Subscriptions['APP_CREATE_COMPONENT'];

    Linking.Subscriptions['CREATE_PROJECT'].remove();
    delete Linking.Subscriptions['CREATE_PROJECT'];

    Linking.Subscriptions['SET_CURRENT_MODE'].remove();
    delete Linking.Subscriptions['SET_CURRENT_MODE'];

    Linking.Subscriptions['GET_OBJECTS'].remove();
    delete Linking.Subscriptions['GET_OBJECTS'];

    Linking.Subscriptions['GET_COMPONENTS'].remove();
    delete Linking.Subscriptions['GET_COMPONENTS'];

    Linking.Subscriptions['GET_ENTITIES'].remove();
    delete Linking.Subscriptions['GET_ENTITIES'];

    Linking.Subscriptions['GET_USERS'].remove();
    delete Linking.Subscriptions['GET_USERS'];

    Linking.Subscriptions['GET_GROUPS'].remove();
    delete Linking.Subscriptions['GET_GROUPS'];

    Linking.Subscriptions['GET_PROJECTS'].remove();
    delete Linking.Subscriptions['GET_PROJECTS'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    Linking.Subscriptions['GET_R3_EVENT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_EVENT_OBJ_USER_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_EVENT_OBJ_USER_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_GRAPH_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_GRAPH_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_MOCK_RAPIER_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_MOCK_RAPIER_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_NODE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_NODE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_AUDIO_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_AUDIO_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_CODE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_CODE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_ENTITY_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_ENTITY_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_GUI_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_GUI_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_INPUT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_INPUT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_MATHS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_MATHS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_STORAGE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_STORAGE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_AUDIO_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_AUDIO_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_CODE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_CODE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_ENTITY_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_ENTITY_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_GUI_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_GUI_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_INPUT_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_INPUT_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_MATHS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_MATHS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_STORAGE_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_STORAGE_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR'];

    Linking.Subscriptions['GET_R3_UTILS_CONSTRUCTOR'].remove();
    delete Linking.Subscriptions['GET_R3_UTILS_CONSTRUCTOR'];
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    Linking.Objects.clear();
    Linking.Users = {};
    Linking.Groups = {};
    Linking.Projects = {};
    Linking.Components = {};
    Linking.Entities = {};
    Linking.CurrentProject=null
    Linking.CurrentUser=null
    Linking.CurrentGroup=null

    Linking.Runtimes.clear();
    Linking.Started = false;
    Linking.IsStopping = false;
    console.log('Stopped client system Linking');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START

    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * SetParentChildRelationships()
   * - Sets parent and child relationships on the passed in child and parent
   * @param child
   * @param parent
   * @param {Object|null} [oldReference=null]
   * - No returns
   */
  static SetParentChildRelationships(child, parent, oldReference = null) {

    //GENERATED_STATIC_SET_PARENT_CHILD_RELATIONSHIPS_START
    //GENERATED_STATIC_SET_PARENT_CHILD_RELATIONSHIPS_END

    //CUSTOM_STATIC_SET_PARENT_CHILD_RELATIONSHIPS_START
    if (child && parent) {
      if (parent instanceof Array) {
        child.parents = [...new Set([...child.parents, ...parent])];
        for (let _parent of parent) {
          Utils.PushUnique(_parent.children, child);
        }
      } else {
        child.parents = [...new Set([...child.parents, parent])];
        Utils.PushUnique(parent.children, child);
      }
    }

    if (child && !parent && oldReference) {

      if (oldReference instanceof Array) {
        oldReference.map(
          (old) => {
            Utils.RemoveFromArray(child.parents, old);
            Utils.RemoveFromArray(old.children, child);
          }
        )
      } else {
        Utils.RemoveFromArray(child.parents, oldReference);
        Utils.RemoveFromArray(oldReference.children, child);
      }
    }

    return [
      {
        code: 0,
        message: `Set parent and child relationships on ${child.name}`
      }
    ];
    //CUSTOM_STATIC_SET_PARENT_CHILD_RELATIONSHIPS_END

    //GENERATED_STATIC_SET_PARENT_CHILD_RELATIONSHIPS_AFTER_START
    //GENERATED_STATIC_SET_PARENT_CHILD_RELATIONSHIPS_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_PARENT_CHILD_RELATIONSHIPS_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_PARENT_CHILD_RELATIONSHIPS_AFTER_END

  }
  /**
   * GetConstructor()
   * - Gets the constructor based on the reference type ID
   * @param referenceType
   * - No returns
   */
  static GetConstructor(referenceType) {

    //GENERATED_STATIC_GET_CONSTRUCTOR_START
    //GENERATED_STATIC_GET_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_CONSTRUCTOR_START
    let eventName = `GET_${Utils.UpperSnakeCase(referenceType)}_CONSTRUCTOR`;

    let payload = {};

    Event.Emit(
      Event[eventName],
      payload
    );

    return payload.results[0];
    //CUSTOM_STATIC_GET_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * InitializeUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeUser(event) {

    //GENERATED_STATIC_INITIALIZE_USER_START
    //GENERATED_STATIC_INITIALIZE_USER_END

    //CUSTOM_STATIC_INITIALIZE_USER_START
    let {object: user} = event;
    Linking.Users[user.id] = user;
    Linking.Objects.add(user);
    //CUSTOM_STATIC_INITIALIZE_USER_END

    //GENERATED_STATIC_INITIALIZE_USER_AFTER_START
    //GENERATED_STATIC_INITIALIZE_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_USER_AFTER_END

  }

  /**
   * InitializeGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeGroup(event) {

    //GENERATED_STATIC_INITIALIZE_GROUP_START
    //GENERATED_STATIC_INITIALIZE_GROUP_END

    //CUSTOM_STATIC_INITIALIZE_GROUP_START
    let {object: group} = event;
    Linking.Groups[group.id] = group;
    Linking.CurrentGroup = group;
    Linking.Objects.add(group);
    //CUSTOM_STATIC_INITIALIZE_GROUP_END

    //GENERATED_STATIC_INITIALIZE_GROUP_AFTER_START
    //GENERATED_STATIC_INITIALIZE_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_GROUP_AFTER_END

  }

  /**
   * InitializeProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeProject(event) {

    //GENERATED_STATIC_INITIALIZE_PROJECT_START
    //GENERATED_STATIC_INITIALIZE_PROJECT_END

    //CUSTOM_STATIC_INITIALIZE_PROJECT_START
    let {object: project} = event;
    Linking.Projects[project.id] = project;
    Linking.CurrentProject = project;
    Linking.Objects.add(project);
    //CUSTOM_STATIC_INITIALIZE_PROJECT_END

    //GENERATED_STATIC_INITIALIZE_PROJECT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_PROJECT_AFTER_END

  }

  /**
   * InitializeComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeComponent(event) {

    //GENERATED_STATIC_INITIALIZE_COMPONENT_START
    //GENERATED_STATIC_INITIALIZE_COMPONENT_END

    //CUSTOM_STATIC_INITIALIZE_COMPONENT_START
    let {object: component} = event;
    if (component instanceof R3EventObjComponentMaths) {
      return;
    }
    Linking.Components[component.id] = component;
    Linking.Objects.add(component);
    //CUSTOM_STATIC_INITIALIZE_COMPONENT_END

    //GENERATED_STATIC_INITIALIZE_COMPONENT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_COMPONENT_AFTER_END

  }

  /**
   * InitializeEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeEntity(event) {

    //GENERATED_STATIC_INITIALIZE_ENTITY_START
    //GENERATED_STATIC_INITIALIZE_ENTITY_END

    //CUSTOM_STATIC_INITIALIZE_ENTITY_START
    let {object: entity} = event;
    Linking.Entities[entity.id] = entity;
    Linking.Objects.add(entity);
    //CUSTOM_STATIC_INITIALIZE_ENTITY_END

    //GENERATED_STATIC_INITIALIZE_ENTITY_AFTER_START
    //GENERATED_STATIC_INITIALIZE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_ENTITY_AFTER_END

  }

  /**
   * DisposeUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeUser(event) {

    //GENERATED_STATIC_DISPOSE_USER_START
    //GENERATED_STATIC_DISPOSE_USER_END

    //CUSTOM_STATIC_DISPOSE_USER_START
    let {object:user} = event;

    for (let child of [...user.children]) {
      child.dispose();
    }

    delete Linking.Users[user.id];

    let keys = Object.keys(Linking.Users);
    if (keys.length) {
      Linking.CurrentUser = Linking.Users[keys[keys.length - 1]];
    } else {
      Linking.CurrentUser = null;
    }

    if (user.group) {
      Linking.Objects.delete(user.group);
      delete Linking.Groups[user.group.id];
      user.group = null;
    }

    Linking.Objects.delete(user);

    for (let key of Object.keys(Linking.Projects)) {
      let project = Linking.Projects[key];
      if (project.user === user) {
        Linking.Objects.delete(project);
        delete Linking.Projects[key];
        project.user = null;
      }
    }
    //CUSTOM_STATIC_DISPOSE_USER_END

    //GENERATED_STATIC_DISPOSE_USER_AFTER_START
    //GENERATED_STATIC_DISPOSE_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_USER_AFTER_END

  }

  /**
   * DisposeGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeGroup(event) {

    //GENERATED_STATIC_DISPOSE_GROUP_START
    //GENERATED_STATIC_DISPOSE_GROUP_END

    //CUSTOM_STATIC_DISPOSE_GROUP_START
    let {object: group} = event;

    for (let child of [...group.children]) {
      child.dispose();
    }

    delete Linking.Groups[group.id];

    let keys = Object.keys(Linking.Groups);
    if (keys.length) {
      Linking.CurrentGroup = Linking.Groups[keys[keys.length - 1]];
    } else {
      Linking.CurrentGroup = null;
    }

    Linking.Objects.delete(group);
    //CUSTOM_STATIC_DISPOSE_GROUP_END

    //GENERATED_STATIC_DISPOSE_GROUP_AFTER_START
    //GENERATED_STATIC_DISPOSE_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_GROUP_AFTER_END

  }

  /**
   * DisposeProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeProject(event) {

    //GENERATED_STATIC_DISPOSE_PROJECT_START
    //GENERATED_STATIC_DISPOSE_PROJECT_END

    //CUSTOM_STATIC_DISPOSE_PROJECT_START
    let {object: project} = event;

    for (let child of [...project.children]) {
      child.dispose();
    }

    let keys = Object.keys(Linking.Projects);
    if (keys.length) {
      Linking.CurrentProject = Linking.Projects[keys[keys.length - 1]];
    } else {
      Linking.CurrentProject = null;
    }

    project.user = null;

    Linking.Objects.delete(project);

    delete Linking.Projects[project.id];
    //CUSTOM_STATIC_DISPOSE_PROJECT_END

    //GENERATED_STATIC_DISPOSE_PROJECT_AFTER_START
    //GENERATED_STATIC_DISPOSE_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_PROJECT_AFTER_END

  }

  /**
   * DisposeComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeComponent(event) {

    //GENERATED_STATIC_DISPOSE_COMPONENT_START
    //GENERATED_STATIC_DISPOSE_COMPONENT_END

    //CUSTOM_STATIC_DISPOSE_COMPONENT_START
    let {object: component} = event;
    if (component instanceof R3EventObjComponentMaths) {
      return;
    }

    delete Linking.Components[component.id];
    Linking.Objects.delete(component);

    for (let [property, reference] of Object.entries(component.references)) {
      /**
       * Unset parent / child relationships to parent objects, but avoid unnecessary
       * updates to primitive values
       */
      let referenceType;

      if (reference.type instanceof Array) {
        referenceType = reference.type[0];
      } else {
        referenceType = reference.type;
      }

      if (referenceType.match('R3EventObj')) {
        component[property] = null;
      }
    }

    for (let child of [...component.children]) {
      for (let property of Object.keys(child.references)) {

        if (child[property] === component) {
          child[property] = null;
          continue;
        }

        if (child[property] instanceof Array) {
          Utils.RemoveFromArray(child[property], component);
        }
      }
    }

    component.project = null;
    //CUSTOM_STATIC_DISPOSE_COMPONENT_END

    //GENERATED_STATIC_DISPOSE_COMPONENT_AFTER_START
    //GENERATED_STATIC_DISPOSE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_COMPONENT_AFTER_END

  }

  /**
   * DisposeEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeEntity(event) {

    //GENERATED_STATIC_DISPOSE_ENTITY_START
    //GENERATED_STATIC_DISPOSE_ENTITY_END

    //CUSTOM_STATIC_DISPOSE_ENTITY_START
    let {object: entity} = event;

    delete Linking.Entities[entity.id];
    Linking.Objects.delete(entity);

    entity.project = null;
    //CUSTOM_STATIC_DISPOSE_ENTITY_END

    //GENERATED_STATIC_DISPOSE_ENTITY_AFTER_START
    //GENERATED_STATIC_DISPOSE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_ENTITY_AFTER_END

  }

  /**
   * GetObjectWithId()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetObjectWithId(event) {

    //GENERATED_STATIC_GET_OBJECT_WITH_ID_START
    let {object} = event;
    let {id} = object;
    for (let object of Linking.Objects.values()) {
      if (object.id === id) {
        event.results = [object];
        return;
      }
    }
    event.results = [null];
    //GENERATED_STATIC_GET_OBJECT_WITH_ID_END

    //CUSTOM_STATIC_GET_OBJECT_WITH_ID_START
    //CUSTOM_STATIC_GET_OBJECT_WITH_ID_END

    //GENERATED_STATIC_GET_OBJECT_WITH_ID_AFTER_START
    //GENERATED_STATIC_GET_OBJECT_WITH_ID_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_OBJECT_WITH_ID_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_OBJECT_WITH_ID_AFTER_END

  }

  /**
   * GetObjectsByConstructor()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetObjectsByConstructor(event) {

    //GENERATED_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_START
    //GENERATED_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_START
    let {options} = event;

    let {Constructor} = options;

    event.results = [...Linking.Objects].filter(
      (object) => {
        return (object instanceof Constructor);
      }
    )
    //CUSTOM_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_OBJECTS_BY_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_OBJECTS_BY_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_OBJECTS_BY_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetObjectCount()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetObjectCount(event) {

    //GENERATED_STATIC_GET_OBJECT_COUNT_START
    //GENERATED_STATIC_GET_OBJECT_COUNT_END

    //CUSTOM_STATIC_GET_OBJECT_COUNT_START
    let objects = [...Linking.Objects].filter(
      (object) => {
        return object instanceof event.object.constructor;
      }
    )
    return [objects.length];
    //CUSTOM_STATIC_GET_OBJECT_COUNT_END

    //GENERATED_STATIC_GET_OBJECT_COUNT_AFTER_START
    //GENERATED_STATIC_GET_OBJECT_COUNT_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_OBJECT_COUNT_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_OBJECT_COUNT_AFTER_END

  }

  /**
   * GetAllEvents()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetAllEvents(event) {

    //GENERATED_STATIC_GET_ALL_EVENTS_START
    //GENERATED_STATIC_GET_ALL_EVENTS_END

    //CUSTOM_STATIC_GET_ALL_EVENTS_START
    if (!Linking.Events.length) {
      Linking.Events = Event.GetEvents();
    }

    event.results = Linking.Events;
    //CUSTOM_STATIC_GET_ALL_EVENTS_END

    //GENERATED_STATIC_GET_ALL_EVENTS_AFTER_START
    //GENERATED_STATIC_GET_ALL_EVENTS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_ALL_EVENTS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_ALL_EVENTS_AFTER_END

  }

  /**
   * GetCurrentUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetCurrentUser(event) {

    //GENERATED_STATIC_GET_CURRENT_USER_START
    //GENERATED_STATIC_GET_CURRENT_USER_END

    //CUSTOM_STATIC_GET_CURRENT_USER_START
    event.results = [Linking.CurrentUser];
    //CUSTOM_STATIC_GET_CURRENT_USER_END

    //GENERATED_STATIC_GET_CURRENT_USER_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_USER_AFTER_END

  }

  /**
   * GetCurrentGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetCurrentGroup(event) {

    //GENERATED_STATIC_GET_CURRENT_GROUP_START
    //GENERATED_STATIC_GET_CURRENT_GROUP_END

    //CUSTOM_STATIC_GET_CURRENT_GROUP_START
    event.results = [Linking.CurrentGroup];
    //CUSTOM_STATIC_GET_CURRENT_GROUP_END

    //GENERATED_STATIC_GET_CURRENT_GROUP_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_GROUP_AFTER_END

  }

  /**
   * SetCurrentUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetCurrentUser(event) {

    //GENERATED_STATIC_SET_CURRENT_USER_START
    //GENERATED_STATIC_SET_CURRENT_USER_END

    //CUSTOM_STATIC_SET_CURRENT_USER_START
    Linking.CurrentUser = event.object;
    console.log('User set...');
    //CUSTOM_STATIC_SET_CURRENT_USER_END

    //GENERATED_STATIC_SET_CURRENT_USER_AFTER_START
    //GENERATED_STATIC_SET_CURRENT_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_CURRENT_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_CURRENT_USER_AFTER_END

  }

  /**
   * SetCurrentGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetCurrentGroup(event) {

    //GENERATED_STATIC_SET_CURRENT_GROUP_START
    //GENERATED_STATIC_SET_CURRENT_GROUP_END

    //CUSTOM_STATIC_SET_CURRENT_GROUP_START
    Linking.CurrentGroup = event.object;
    //CUSTOM_STATIC_SET_CURRENT_GROUP_END

    //GENERATED_STATIC_SET_CURRENT_GROUP_AFTER_START
    //GENERATED_STATIC_SET_CURRENT_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_CURRENT_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_CURRENT_GROUP_AFTER_END

  }

  /**
   * GetCurrentProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetCurrentProject(event) {

    //GENERATED_STATIC_GET_CURRENT_PROJECT_START
    //GENERATED_STATIC_GET_CURRENT_PROJECT_END

    //CUSTOM_STATIC_GET_CURRENT_PROJECT_START
    event.results = [Linking.CurrentProject];
    //CUSTOM_STATIC_GET_CURRENT_PROJECT_END

    //GENERATED_STATIC_GET_CURRENT_PROJECT_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_PROJECT_AFTER_END

  }

  /**
   * SetCurrentProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetCurrentProject(event) {

    //GENERATED_STATIC_SET_CURRENT_PROJECT_START
    //GENERATED_STATIC_SET_CURRENT_PROJECT_END

    //CUSTOM_STATIC_SET_CURRENT_PROJECT_START
    Linking.CurrentProject = event.object;
    //CUSTOM_STATIC_SET_CURRENT_PROJECT_END

    //GENERATED_STATIC_SET_CURRENT_PROJECT_AFTER_START
    //GENERATED_STATIC_SET_CURRENT_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_CURRENT_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_CURRENT_PROJECT_AFTER_END

  }

  /**
   * AppCreateComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static AppCreateComponent(event) {

    //GENERATED_STATIC_APP_CREATE_COMPONENT_START
    //GENERATED_STATIC_APP_CREATE_COMPONENT_END

    //CUSTOM_STATIC_APP_CREATE_COMPONENT_START
    let {object} = event;

    let {type} = object;

    let constructor = Linking.GetConstructor(type);

    new constructor();
    //CUSTOM_STATIC_APP_CREATE_COMPONENT_END

    //GENERATED_STATIC_APP_CREATE_COMPONENT_AFTER_START
    //GENERATED_STATIC_APP_CREATE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_APP_CREATE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_APP_CREATE_COMPONENT_AFTER_END

  }

  /**
   * CreateProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static CreateProject(event) {

    //GENERATED_STATIC_CREATE_PROJECT_START
    //GENERATED_STATIC_CREATE_PROJECT_END

    //CUSTOM_STATIC_CREATE_PROJECT_START
    let {id, type} = event;

    let project = new R3EventObjProject(
      {
        id
      }
    );

    let width = 1280;
    let height = 768;

    let cameraOptions = {
      position : new R3EventObjComponentMathsVector3(
        {
          x : 0,
          y : 0,
          z : -1
        }
      )
    };

    switch (type) {
      case R3EventObjProject.TYPE_DESKTOP :
        break;
      case R3EventObjProject.TYPE_MOBILE :
        width = 422;
        height = 748;
        cameraOptions.fixedAspect = true;
        cameraOptions.predefinedAspectRatio = R3EventObjComponentGraphicsCamera.ASPECT_RATIO_9_16;
        break;
      case R3EventObjProject.TYPE_MOBILE_LANDSCAPE :
        width = 748;
        height = 422;
        cameraOptions.fixedAspect = true;
        cameraOptions.predefinedAspectRatio = R3EventObjComponentGraphicsCamera.ASPECT_RATIO_16_9;
        break;
      default :
        throw new Error(`Unknown project type : ${type}`);
    }

    let canvas = new R3EventObjComponentGraphicsCanvas(
      {
        width,
        height
      }
    );

    cameraOptions.canvas = canvas;

    Linking.CurrentScene = new R3EventObjComponentGraphicsScene();

    let perspective = new R3EventObjComponentGraphicsCameraPerspective(cameraOptions);

    new R3EventObjComponentGraphicsRendererGl(
      {
        camera : perspective,
        canvas,
      }
    );

    let groundBox = new R3EventObjComponentGraphicsGeometryBox(
      {
        name : 'Ground Geometry',
        width : 1000,
        height : 10,
        depth : 1000,
        widthSegments: 30,
        depthSegments: 30
      }
    );

    let basic = new R3EventObjComponentGraphicsMaterialBasic(
      {
        name : 'Ground Material',
        wireframe : true,
      }
    );

    let ground = new R3EventObjComponentGraphicsMesh(
      {
        name : 'Ground Mesh',
        geometry : groundBox,
        material : basic,
        position : new R3EventObjComponentMathsVector3(
          {
            x : 0,
            y : 0,
            z : 0
          }
        )
      }
    )

    let box = new R3EventObjComponentGraphicsGeometryBox(
      {
        name : 'Box Geometry',
        width: 0.5,
        height: 0.5,
        depth: 0.5,
        widthSegments: 2,
        heightSegments: 2,
        depthSegments: 2
      }
    );

    let code = new R3EventObjComponentCode(
      {
        executionTemplate : 'let {options} = event;\n  let {delta} = options;\n  this.mesh.rotation.x += delta * 0.00003;\n  this.mesh.rotation.y += delta * 0.00003;\n  this.mesh.rotation.z += delta * 0.00003;'
      }
    );

    let standard = new R3EventObjComponentGraphicsMaterialStandard(
      {
        name : 'Box Material'
      }
    );

    let mesh = new R3EventObjComponentGraphicsMesh(
      {
        name : 'Box Mesh',
        geometry: box,
        material: standard,
        position: new R3EventObjComponentMathsVector3(
          {
            x : 0,
            y : 200,
            z : 0
          }
        ),
        scale : new R3EventObjComponentMathsVector3(
          {
            x : 3,
            y : 1,
            z : 1
          }
        )
      }
    );

    new R3EventObjEntityAnimationRotation(
      {
        mesh,
        code
      }
    );

    new R3EventObjComponentInputTouch(
      {
        domElement : canvas
      }
    );

    new R3EventObjComponentInputKeyboard(
      {
        domElement : canvas
      }
    );

    new R3EventObjComponentInputMouse(
      {
        domElement : canvas
      }
    );

    new R3EventObjComponentGraphicsLightAmbient();

    /**
     * --- Physics Start
     */
    let world = new R3EventObjComponentPhysicsWorld(
      {
        gravity : new R3EventObjComponentPhysicsGravity()
      }
    );

    new R3EventObjComponentPhysicsColliderCuboid(
      {
        name : 'Ground Cuboid Collider',
        world,
        mesh : ground
      }
    );

    let rigidBody = new R3EventObjComponentPhysicsRigidBodyDynamic(
      {
        name : 'Dynamic Rigid Body',
        world,
        mesh
      }
    );

    new R3EventObjComponentPhysicsColliderCuboid(
      {
        name : 'Box Cube Collider',
        world,
        rigidBody
      }
    );

    let directional = new R3EventObjComponentGraphicsLightDirectional(
      {
        target : mesh
      }
    );

    perspective.position.z = 100;

    mesh.material = standard;

    directional.position.x = 100;

    // project.mode = R3EventObj.MODE_EDIT;

    Event.Emit(
      Event.STATUS_INFO,
      {
        code : 0,
        message : 'think inside the box...'
      }
    );

    event.results = [project];
    //
    // new R3EventObjComponentCode();
    // new R3EventObjComponentCode();
    // new R3EventObjComponentCode();
    //CUSTOM_STATIC_CREATE_PROJECT_END

    //GENERATED_STATIC_CREATE_PROJECT_AFTER_START
    //GENERATED_STATIC_CREATE_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_CREATE_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_CREATE_PROJECT_AFTER_END

  }

  /**
   * SetCurrentMode()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetCurrentMode(event) {

    //GENERATED_STATIC_SET_CURRENT_MODE_START
    //GENERATED_STATIC_SET_CURRENT_MODE_END

    //CUSTOM_STATIC_SET_CURRENT_MODE_START
    //CUSTOM_STATIC_SET_CURRENT_MODE_END

    //GENERATED_STATIC_SET_CURRENT_MODE_AFTER_START
    //GENERATED_STATIC_SET_CURRENT_MODE_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_CURRENT_MODE_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_CURRENT_MODE_AFTER_END

  }

  /**
   * GetObjects()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetObjects(event) {

    //GENERATED_STATIC_GET_OBJECTS_START
    //GENERATED_STATIC_GET_OBJECTS_END

    //CUSTOM_STATIC_GET_OBJECTS_START
    event.results = [...Linking.Objects];
    //CUSTOM_STATIC_GET_OBJECTS_END

    //GENERATED_STATIC_GET_OBJECTS_AFTER_START
    //GENERATED_STATIC_GET_OBJECTS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_OBJECTS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_OBJECTS_AFTER_END

  }

  /**
   * GetComponents()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetComponents(event) {

    //GENERATED_STATIC_GET_COMPONENTS_START
    //GENERATED_STATIC_GET_COMPONENTS_END

    //CUSTOM_STATIC_GET_COMPONENTS_START
    event.results = Object.keys(Linking.Components).map(
      (key) => {
        return Linking.Components[key];
      }
    );
    //CUSTOM_STATIC_GET_COMPONENTS_END

    //GENERATED_STATIC_GET_COMPONENTS_AFTER_START
    //GENERATED_STATIC_GET_COMPONENTS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_COMPONENTS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_COMPONENTS_AFTER_END

  }

  /**
   * GetEntities()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetEntities(event) {

    //GENERATED_STATIC_GET_ENTITIES_START
    //GENERATED_STATIC_GET_ENTITIES_END

    //CUSTOM_STATIC_GET_ENTITIES_START
    event.results = Object.keys(Linking.Entities).map(
      (key) => {
        return Linking.Entities[key];
      }
    );
    //CUSTOM_STATIC_GET_ENTITIES_END

    //GENERATED_STATIC_GET_ENTITIES_AFTER_START
    //GENERATED_STATIC_GET_ENTITIES_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_ENTITIES_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_ENTITIES_AFTER_END

  }

  /**
   * GetUsers()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetUsers(event) {

    //GENERATED_STATIC_GET_USERS_START
    //GENERATED_STATIC_GET_USERS_END

    //CUSTOM_STATIC_GET_USERS_START
    event.results = Object.keys(Linking.Users).map(
      (key) => {
        return Linking.Users[key];
      }
    );
    //CUSTOM_STATIC_GET_USERS_END

    //GENERATED_STATIC_GET_USERS_AFTER_START
    //GENERATED_STATIC_GET_USERS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_USERS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_USERS_AFTER_END

  }

  /**
   * GetGroups()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetGroups(event) {

    //GENERATED_STATIC_GET_GROUPS_START
    //GENERATED_STATIC_GET_GROUPS_END

    //CUSTOM_STATIC_GET_GROUPS_START
    event.results = Object.keys(Linking.Groups).map(
      (key) => {
        return Linking.Groups[key];
      }
    );
    //CUSTOM_STATIC_GET_GROUPS_END

    //GENERATED_STATIC_GET_GROUPS_AFTER_START
    //GENERATED_STATIC_GET_GROUPS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_GROUPS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_GROUPS_AFTER_END

  }

  /**
   * GetProjects()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetProjects(event) {

    //GENERATED_STATIC_GET_PROJECTS_START
    //GENERATED_STATIC_GET_PROJECTS_END

    //CUSTOM_STATIC_GET_PROJECTS_START
    event.results = Object.keys(Linking.Projects).map(
      (key) => {
        return Linking.Projects[key];
      }
    );
    //CUSTOM_STATIC_GET_PROJECTS_END

    //GENERATED_STATIC_GET_PROJECTS_AFTER_START
    //GENERATED_STATIC_GET_PROJECTS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_PROJECTS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_PROJECTS_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  /**
   * GetR3EventConstructor()
   * - Returns the Event constructor (R3Event)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_CONSTRUCTOR_START
    event.results = [R3Event];
    //GENERATED_STATIC_GET_R3_EVENT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentAudioConstructor()
   * - Returns the Audio constructor (R3EventObjComponentAudio)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentAudioConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR_START
    event.results = [R3EventObjComponentAudio];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentAudioPositionalConstructor()
   * - Returns the Positional constructor (R3EventObjComponentAudioPositional)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentAudioPositionalConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR_START
    event.results = [R3EventObjComponentAudioPositional];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentCodeConstructor()
   * - Returns the Code constructor (R3EventObjComponentCode)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentCodeConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentCode];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentConstructor()
   * - Returns the Component constructor (R3EventObjComponent)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR_START
    event.results = [R3EventObjComponent];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsCameraConstructor()
   * - Returns the Camera constructor (R3EventObjComponentGraphicsCamera)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsCameraConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsCamera];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsCameraCubeConstructor()
   * - Returns the Cube constructor (R3EventObjComponentGraphicsCameraCube)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsCameraCubeConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsCameraCube];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsCameraOrthographicConstructor()
   * - Returns the Orthographic constructor (R3EventObjComponentGraphicsCameraOrthographic)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsCameraOrthographicConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsCameraOrthographic];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsCameraPerspectiveConstructor()
   * - Returns the Perspective constructor (R3EventObjComponentGraphicsCameraPerspective)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsCameraPerspectiveConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsCameraPerspective];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsCanvasConstructor()
   * - Returns the Canvas constructor (R3EventObjComponentGraphicsCanvas)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsCanvasConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsCanvas];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsConstructor()
   * - Returns the Graphics constructor (R3EventObjComponentGraphics)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphics];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsFogConstructor()
   * - Returns the Fog constructor (R3EventObjComponentGraphicsFog)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsFogConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsFog];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsGeometryBoxConstructor()
   * - Returns the Box constructor (R3EventObjComponentGraphicsGeometryBox)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsGeometryBoxConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsGeometryBox];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsGeometryBufferConstructor()
   * - Returns the Buffer constructor (R3EventObjComponentGraphicsGeometryBuffer)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsGeometryBufferConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsGeometryBuffer];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsGeometryConstructor()
   * - Returns the Geometry constructor (R3EventObjComponentGraphicsGeometry)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsGeometryConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsGeometry];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsGeometryPlaneConstructor()
   * - Returns the Plane constructor (R3EventObjComponentGraphicsGeometryPlane)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsGeometryPlaneConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsGeometryPlane];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsGeometrySphereConstructor()
   * - Returns the Sphere constructor (R3EventObjComponentGraphicsGeometrySphere)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsGeometrySphereConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsGeometrySphere];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsGeometryTorusConstructor()
   * - Returns the Torus constructor (R3EventObjComponentGraphicsGeometryTorus)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsGeometryTorusConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsGeometryTorus];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsImageConstructor()
   * - Returns the Image constructor (R3EventObjComponentGraphicsImage)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsImageConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsImage];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsLightAmbientConstructor()
   * - Returns the Ambient constructor (R3EventObjComponentGraphicsLightAmbient)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsLightAmbientConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsLightAmbient];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsLightConstructor()
   * - Returns the Light constructor (R3EventObjComponentGraphicsLight)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsLightConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsLight];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsLightDirectionalConstructor()
   * - Returns the Directional constructor (R3EventObjComponentGraphicsLightDirectional)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsLightDirectionalConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsLightDirectional];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsLightPointConstructor()
   * - Returns the Point constructor (R3EventObjComponentGraphicsLightPoint)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsLightPointConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsLightPoint];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsLightSpotConstructor()
   * - Returns the Spot constructor (R3EventObjComponentGraphicsLightSpot)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsLightSpotConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsLightSpot];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsMaterialBasicConstructor()
   * - Returns the Basic constructor (R3EventObjComponentGraphicsMaterialBasic)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsMaterialBasicConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsMaterialBasic];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsMaterialConstructor()
   * - Returns the Material constructor (R3EventObjComponentGraphicsMaterial)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsMaterialConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsMaterial];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsMaterialGroupConstructor()
   * - Returns the MaterialGroup constructor (R3EventObjComponentGraphicsMaterialGroup)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsMaterialGroupConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsMaterialGroup];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsMaterialLambertConstructor()
   * - Returns the Lambert constructor (R3EventObjComponentGraphicsMaterialLambert)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsMaterialLambertConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsMaterialLambert];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsMaterialPhongConstructor()
   * - Returns the Phong constructor (R3EventObjComponentGraphicsMaterialPhong)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsMaterialPhongConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsMaterialPhong];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsMaterialPointsConstructor()
   * - Returns the Points constructor (R3EventObjComponentGraphicsMaterialPoints)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsMaterialPointsConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsMaterialPoints];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsMaterialStandardConstructor()
   * - Returns the Standard constructor (R3EventObjComponentGraphicsMaterialStandard)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsMaterialStandardConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsMaterialStandard];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsMaterialToonConstructor()
   * - Returns the Toon constructor (R3EventObjComponentGraphicsMaterialToon)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsMaterialToonConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsMaterialToon];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsMeshConstructor()
   * - Returns the Mesh constructor (R3EventObjComponentGraphicsMesh)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsMeshConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsMesh];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsRaycasterConstructor()
   * - Returns the Raycaster constructor (R3EventObjComponentGraphicsRaycaster)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsRaycasterConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsRaycaster];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsRendererConstructor()
   * - Returns the Renderer constructor (R3EventObjComponentGraphicsRenderer)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsRendererConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsRenderer];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsRendererCubeConstructor()
   * - Returns the Cube constructor (R3EventObjComponentGraphicsRendererCube)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsRendererCubeConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsRendererCube];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsRendererGlConstructor()
   * - Returns the Gl constructor (R3EventObjComponentGraphicsRendererGl)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsRendererGlConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsRendererGl];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsRendererTargetConstructor()
   * - Returns the Target constructor (R3EventObjComponentGraphicsRendererTarget)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsRendererTargetConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsRendererTarget];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsSceneConstructor()
   * - Returns the Scene constructor (R3EventObjComponentGraphicsScene)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsSceneConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsScene];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsShaderConstructor()
   * - Returns the Shader constructor (R3EventObjComponentGraphicsShader)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsShaderConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsShader];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsShadowConstructor()
   * - Returns the Shadow constructor (R3EventObjComponentGraphicsShadow)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsShadowConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsShadow];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsShadowDirectionalConstructor()
   * - Returns the Directional constructor (R3EventObjComponentGraphicsShadowDirectional)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsShadowDirectionalConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsShadowDirectional];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsShadowPointConstructor()
   * - Returns the Point constructor (R3EventObjComponentGraphicsShadowPoint)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsShadowPointConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsShadowPoint];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsShadowSpotConstructor()
   * - Returns the Spot constructor (R3EventObjComponentGraphicsShadowSpot)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsShadowSpotConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsShadowSpot];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsSkeletonConstructor()
   * - Returns the Skeleton constructor (R3EventObjComponentGraphicsSkeleton)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsSkeletonConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsSkeleton];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsSpriteConstructor()
   * - Returns the Sprite constructor (R3EventObjComponentGraphicsSprite)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsSpriteConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsSprite];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsTextureCanvasConstructor()
   * - Returns the Canvas constructor (R3EventObjComponentGraphicsTextureCanvas)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsTextureCanvasConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsTextureCanvas];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsTextureConstructor()
   * - Returns the Texture constructor (R3EventObjComponentGraphicsTexture)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsTextureConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsTexture];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsTextureCubeConstructor()
   * - Returns the Cube constructor (R3EventObjComponentGraphicsTextureCube)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsTextureCubeConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsTextureCube];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsTextureImageConstructor()
   * - Returns the Image constructor (R3EventObjComponentGraphicsTextureImage)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsTextureImageConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsTextureImage];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsTextureVideoConstructor()
   * - Returns the Video constructor (R3EventObjComponentGraphicsTextureVideo)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsTextureVideoConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsTextureVideo];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentGraphicsVideoConstructor()
   * - Returns the Video constructor (R3EventObjComponentGraphicsVideo)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentGraphicsVideoConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR_START
    event.results = [R3EventObjComponentGraphicsVideo];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentInputConstructor()
   * - Returns the Input constructor (R3EventObjComponentInput)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentInputConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR_START
    event.results = [R3EventObjComponentInput];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentInputKeyboardConstructor()
   * - Returns the Keyboard constructor (R3EventObjComponentInputKeyboard)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentInputKeyboardConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR_START
    event.results = [R3EventObjComponentInputKeyboard];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentInputMouseConstructor()
   * - Returns the Mouse constructor (R3EventObjComponentInputMouse)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentInputMouseConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentInputMouse];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentInputOrbitControlsConstructor()
   * - Returns the OrbitControls constructor (R3EventObjComponentInputOrbitControls)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentInputOrbitControlsConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR_START
    event.results = [R3EventObjComponentInputOrbitControls];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentInputTouchConstructor()
   * - Returns the Touch constructor (R3EventObjComponentInputTouch)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentInputTouchConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR_START
    event.results = [R3EventObjComponentInputTouch];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsColorConstructor()
   * - Returns the Color constructor (R3EventObjComponentMathsColor)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsColorConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsColor];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsConstructor()
   * - Returns the Maths constructor (R3EventObjComponentMaths)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMaths];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsFaceConstructor()
   * - Returns the Face constructor (R3EventObjComponentMathsFace)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsFaceConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsFace];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsMatrix3Constructor()
   * - Returns the Matrix3 constructor (R3EventObjComponentMathsMatrix3)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsMatrix3Constructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsMatrix3];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsMatrix4Constructor()
   * - Returns the Matrix4 constructor (R3EventObjComponentMathsMatrix4)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsMatrix4Constructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsMatrix4];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsPlaneConstructor()
   * - Returns the Plane constructor (R3EventObjComponentMathsPlane)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsPlaneConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsPlane];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsQuaternionConstructor()
   * - Returns the Quaternion constructor (R3EventObjComponentMathsQuaternion)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsQuaternionConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsQuaternion];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsRayConstructor()
   * - Returns the Ray constructor (R3EventObjComponentMathsRay)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsRayConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsRay];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsSphereConstructor()
   * - Returns the Sphere constructor (R3EventObjComponentMathsSphere)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsSphereConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsSphere];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsUVConstructor()
   * - Returns the UV constructor (R3EventObjComponentMathsUV)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsUVConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsUV];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsVector2Constructor()
   * - Returns the Vector2 constructor (R3EventObjComponentMathsVector2)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsVector2Constructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsVector2];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsVector3Constructor()
   * - Returns the Vector3 constructor (R3EventObjComponentMathsVector3)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsVector3Constructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsVector3];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsVector4Constructor()
   * - Returns the Vector4 constructor (R3EventObjComponentMathsVector4)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsVector4Constructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsVector4];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentMathsVertexConstructor()
   * - Returns the Vertex constructor (R3EventObjComponentMathsVertex)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentMathsVertexConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR_START
    event.results = [R3EventObjComponentMathsVertex];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsColliderBallConstructor()
   * - Returns the Ball constructor (R3EventObjComponentPhysicsColliderBall)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsColliderBallConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsColliderBall];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsColliderCapsuleConstructor()
   * - Returns the Capsule constructor (R3EventObjComponentPhysicsColliderCapsule)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsColliderCapsuleConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsColliderCapsule];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsColliderConstructor()
   * - Returns the Collider constructor (R3EventObjComponentPhysicsCollider)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsColliderConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsCollider];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsColliderCuboidConstructor()
   * - Returns the Cuboid constructor (R3EventObjComponentPhysicsColliderCuboid)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsColliderCuboidConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsColliderCuboid];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsColliderHeightFieldConstructor()
   * - Returns the HeightField constructor (R3EventObjComponentPhysicsColliderHeightField)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsColliderHeightFieldConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsColliderHeightField];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsColliderTriMeshConstructor()
   * - Returns the TriMesh constructor (R3EventObjComponentPhysicsColliderTriMesh)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsColliderTriMeshConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsColliderTriMesh];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsConstructor()
   * - Returns the Physics constructor (R3EventObjComponentPhysics)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysics];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsGravityConstructor()
   * - Returns the Gravity constructor (R3EventObjComponentPhysicsGravity)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsGravityConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsGravity];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsJointConstructor()
   * - Returns the Joint constructor (R3EventObjComponentPhysicsJoint)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsJointConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsJoint];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsJointFixedConstructor()
   * - Returns the Fixed constructor (R3EventObjComponentPhysicsJointFixed)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsJointFixedConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsJointFixed];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsJointPrismaticConstructor()
   * - Returns the Prismatic constructor (R3EventObjComponentPhysicsJointPrismatic)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsJointPrismaticConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsJointPrismatic];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsJointRevoluteConstructor()
   * - Returns the Revolute constructor (R3EventObjComponentPhysicsJointRevolute)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsJointRevoluteConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsJointRevolute];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsJointSphericalConstructor()
   * - Returns the Spherical constructor (R3EventObjComponentPhysicsJointSpherical)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsJointSphericalConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsJointSpherical];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsRigidBodyConstructor()
   * - Returns the RigidBody constructor (R3EventObjComponentPhysicsRigidBody)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsRigidBodyConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsRigidBody];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsRigidBodyDynamicConstructor()
   * - Returns the Dynamic constructor (R3EventObjComponentPhysicsRigidBodyDynamic)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsRigidBodyDynamicConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsRigidBodyDynamic];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsRigidBodyFixedConstructor()
   * - Returns the Fixed constructor (R3EventObjComponentPhysicsRigidBodyFixed)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsRigidBodyFixedConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsRigidBodyFixed];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjComponentPhysicsWorldConstructor()
   * - Returns the World constructor (R3EventObjComponentPhysicsWorld)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjComponentPhysicsWorldConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR_START
    event.results = [R3EventObjComponentPhysicsWorld];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjConstructor()
   * - Returns the Obj constructor (R3EventObj)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_CONSTRUCTOR_START
    event.results = [R3EventObj];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjEntityAnimationConstructor()
   * - Returns the Animation constructor (R3EventObjEntityAnimation)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjEntityAnimationConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR_START
    event.results = [R3EventObjEntityAnimation];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjEntityAnimationRotationConstructor()
   * - Returns the Rotation constructor (R3EventObjEntityAnimationRotation)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjEntityAnimationRotationConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR_START
    event.results = [R3EventObjEntityAnimationRotation];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjEntityConstructor()
   * - Returns the Entity constructor (R3EventObjEntity)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjEntityConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR_START
    event.results = [R3EventObjEntity];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjFileConstructor()
   * - Returns the File constructor (R3EventObjFile)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjFileConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR_START
    event.results = [R3EventObjFile];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjGroupConstructor()
   * - Returns the Group constructor (R3EventObjGroup)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjGroupConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR_START
    event.results = [R3EventObjGroup];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjProjectConstructor()
   * - Returns the Project constructor (R3EventObjProject)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjProjectConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR_START
    event.results = [R3EventObjProject];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3EventObjUserConstructor()
   * - Returns the User constructor (R3EventObjUser)
   * @param {Object} event
   * - No returns
   */
  static GetR3EventObjUserConstructor(event) {

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_USER_CONSTRUCTOR_START
    event.results = [R3EventObjUser];
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_USER_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_USER_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_EVENT_OBJ_USER_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_EVENT_OBJ_USER_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_EVENT_OBJ_USER_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_USER_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_EVENT_OBJ_USER_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3GraphConstructor()
   * - Returns the Graph constructor (R3Graph)
   * @param {Object} event
   * - No returns
   */
  static GetR3GraphConstructor(event) {

    //GENERATED_STATIC_GET_R3_GRAPH_CONSTRUCTOR_START
    event.results = [R3Graph];
    //GENERATED_STATIC_GET_R3_GRAPH_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_GRAPH_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_GRAPH_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_GRAPH_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_GRAPH_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_GRAPH_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_GRAPH_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3MockRapierConstructor()
   * - Returns the MockRapier constructor (R3MockRapier)
   * @param {Object} event
   * - No returns
   */
  static GetR3MockRapierConstructor(event) {

    //GENERATED_STATIC_GET_R3_MOCK_RAPIER_CONSTRUCTOR_START
    event.results = [R3MockRapier];
    //GENERATED_STATIC_GET_R3_MOCK_RAPIER_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_MOCK_RAPIER_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_MOCK_RAPIER_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_MOCK_RAPIER_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_MOCK_RAPIER_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_MOCK_RAPIER_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_MOCK_RAPIER_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3NodeConstructor()
   * - Returns the Node constructor (R3Node)
   * @param {Object} event
   * - No returns
   */
  static GetR3NodeConstructor(event) {

    //GENERATED_STATIC_GET_R3_NODE_CONSTRUCTOR_START
    event.results = [R3Node];
    //GENERATED_STATIC_GET_R3_NODE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_NODE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_NODE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_NODE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_NODE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_NODE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_NODE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeAudioConstructor()
   * - Returns the Audio constructor (R3RuntimeAudio)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeAudioConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_AUDIO_CONSTRUCTOR_START
    event.results = [R3RuntimeAudio];
    //GENERATED_STATIC_GET_R3_RUNTIME_AUDIO_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_AUDIO_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_AUDIO_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_AUDIO_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_AUDIO_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_AUDIO_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_AUDIO_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeAudioThreeConstructor()
   * - Returns the Three constructor (R3RuntimeAudioThree)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeAudioThreeConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR_START
    event.results = [R3RuntimeAudioThree];
    //GENERATED_STATIC_GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeAuthenticationAxiosConstructor()
   * - Returns the Axios constructor (R3RuntimeAuthenticationAxios)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeAuthenticationAxiosConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR_START
    event.results = [R3RuntimeAuthenticationAxios];
    //GENERATED_STATIC_GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeAuthenticationConstructor()
   * - Returns the Authentication constructor (R3RuntimeAuthentication)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeAuthenticationConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR_START
    event.results = [R3RuntimeAuthentication];
    //GENERATED_STATIC_GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeCodeAceConstructor()
   * - Returns the Ace constructor (R3RuntimeCodeAce)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeCodeAceConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR_START
    event.results = [R3RuntimeCodeAce];
    //GENERATED_STATIC_GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeCodeConstructor()
   * - Returns the Code constructor (R3RuntimeCode)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeCodeConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_CODE_CONSTRUCTOR_START
    event.results = [R3RuntimeCode];
    //GENERATED_STATIC_GET_R3_RUNTIME_CODE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_CODE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_CODE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_CODE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_CODE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_CODE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_CODE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeConstructor()
   * - Returns the Runtime constructor (R3Runtime)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_CONSTRUCTOR_START
    event.results = [R3Runtime];
    //GENERATED_STATIC_GET_R3_RUNTIME_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeEntityConstructor()
   * - Returns the Entity constructor (R3RuntimeEntity)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeEntityConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_ENTITY_CONSTRUCTOR_START
    event.results = [R3RuntimeEntity];
    //GENERATED_STATIC_GET_R3_RUNTIME_ENTITY_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_ENTITY_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_ENTITY_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_ENTITY_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_ENTITY_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_ENTITY_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_ENTITY_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeEntityDefaultConstructor()
   * - Returns the Default constructor (R3RuntimeEntityDefault)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeEntityDefaultConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR_START
    event.results = [R3RuntimeEntityDefault];
    //GENERATED_STATIC_GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeGraphicsConstructor()
   * - Returns the Graphics constructor (R3RuntimeGraphics)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeGraphicsConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR_START
    event.results = [R3RuntimeGraphics];
    //GENERATED_STATIC_GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeGraphicsThreeConstructor()
   * - Returns the Three constructor (R3RuntimeGraphicsThree)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeGraphicsThreeConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR_START
    event.results = [R3RuntimeGraphicsThree];
    //GENERATED_STATIC_GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeGuiConstructor()
   * - Returns the Gui constructor (R3RuntimeGui)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeGuiConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_GUI_CONSTRUCTOR_START
    event.results = [R3RuntimeGui];
    //GENERATED_STATIC_GET_R3_RUNTIME_GUI_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_GUI_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_GUI_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_GUI_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_GUI_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_GUI_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_GUI_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeGuiTweakPaneConstructor()
   * - Returns the TweakPane constructor (R3RuntimeGuiTweakPane)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeGuiTweakPaneConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR_START
    event.results = [R3RuntimeGuiTweakPane];
    //GENERATED_STATIC_GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeInputConstructor()
   * - Returns the Input constructor (R3RuntimeInput)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeInputConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_CONSTRUCTOR_START
    event.results = [R3RuntimeInput];
    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_INPUT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_INPUT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_INPUT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_INPUT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeInputCustomConstructor()
   * - Returns the Custom constructor (R3RuntimeInputCustom)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeInputCustomConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR_START
    event.results = [R3RuntimeInputCustom];
    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeInputThreeConstructor()
   * - Returns the Three constructor (R3RuntimeInputThree)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeInputThreeConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR_START
    event.results = [R3RuntimeInputThree];
    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeMathsConstructor()
   * - Returns the Maths constructor (R3RuntimeMaths)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeMathsConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_MATHS_CONSTRUCTOR_START
    event.results = [R3RuntimeMaths];
    //GENERATED_STATIC_GET_R3_RUNTIME_MATHS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_MATHS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_MATHS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_MATHS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_MATHS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_MATHS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_MATHS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeMathsThreeConstructor()
   * - Returns the Three constructor (R3RuntimeMathsThree)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeMathsThreeConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR_START
    event.results = [R3RuntimeMathsThree];
    //GENERATED_STATIC_GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimePhysicsConstructor()
   * - Returns the Physics constructor (R3RuntimePhysics)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimePhysicsConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR_START
    event.results = [R3RuntimePhysics];
    //GENERATED_STATIC_GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimePhysicsRapierConstructor()
   * - Returns the Rapier constructor (R3RuntimePhysicsRapier)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimePhysicsRapierConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR_START
    event.results = [R3RuntimePhysicsRapier];
    //GENERATED_STATIC_GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeStorageAxiosConstructor()
   * - Returns the Axios constructor (R3RuntimeStorageAxios)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeStorageAxiosConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR_START
    event.results = [R3RuntimeStorageAxios];
    //GENERATED_STATIC_GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeStorageConstructor()
   * - Returns the Storage constructor (R3RuntimeStorage)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeStorageConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_STORAGE_CONSTRUCTOR_START
    event.results = [R3RuntimeStorage];
    //GENERATED_STATIC_GET_R3_RUNTIME_STORAGE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_STORAGE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_STORAGE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_STORAGE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_STORAGE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_STORAGE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_STORAGE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeWebsocketConstructor()
   * - Returns the Websocket constructor (R3RuntimeWebsocket)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeWebsocketConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR_START
    event.results = [R3RuntimeWebsocket];
    //GENERATED_STATIC_GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3RuntimeWebsocketSocketIOConstructor()
   * - Returns the SocketIO constructor (R3RuntimeWebsocketSocketIO)
   * @param {Object} event
   * - No returns
   */
  static GetR3RuntimeWebsocketSocketIOConstructor(event) {

    //GENERATED_STATIC_GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR_START
    event.results = [R3RuntimeWebsocketSocketIO];
    //GENERATED_STATIC_GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemAudioConstructor()
   * - Returns the Audio constructor (R3SystemAudio)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemAudioConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_AUDIO_CONSTRUCTOR_START
    event.results = [R3SystemAudio];
    //GENERATED_STATIC_GET_R3_SYSTEM_AUDIO_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_AUDIO_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_AUDIO_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_AUDIO_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_AUDIO_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_AUDIO_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_AUDIO_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemAuthenticationConstructor()
   * - Returns the Authentication constructor (R3SystemAuthentication)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemAuthenticationConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR_START
    event.results = [R3SystemAuthentication];
    //GENERATED_STATIC_GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemCodeConstructor()
   * - Returns the Code constructor (R3SystemCode)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemCodeConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_CODE_CONSTRUCTOR_START
    event.results = [R3SystemCode];
    //GENERATED_STATIC_GET_R3_SYSTEM_CODE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_CODE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_CODE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_CODE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_CODE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_CODE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_CODE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemConstructor()
   * - Returns the System constructor (R3System)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_CONSTRUCTOR_START
    event.results = [R3System];
    //GENERATED_STATIC_GET_R3_SYSTEM_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemEntityConstructor()
   * - Returns the Entity constructor (R3SystemEntity)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemEntityConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_ENTITY_CONSTRUCTOR_START
    event.results = [R3SystemEntity];
    //GENERATED_STATIC_GET_R3_SYSTEM_ENTITY_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_ENTITY_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_ENTITY_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_ENTITY_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_ENTITY_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_ENTITY_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_ENTITY_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemGraphicsConstructor()
   * - Returns the Graphics constructor (R3SystemGraphics)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemGraphicsConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR_START
    event.results = [R3SystemGraphics];
    //GENERATED_STATIC_GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemGuiConstructor()
   * - Returns the Gui constructor (R3SystemGui)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemGuiConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_GUI_CONSTRUCTOR_START
    event.results = [R3SystemGui];
    //GENERATED_STATIC_GET_R3_SYSTEM_GUI_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_GUI_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_GUI_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_GUI_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_GUI_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_GUI_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_GUI_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemInputConstructor()
   * - Returns the Input constructor (R3SystemInput)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemInputConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_INPUT_CONSTRUCTOR_START
    event.results = [R3SystemInput];
    //GENERATED_STATIC_GET_R3_SYSTEM_INPUT_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_INPUT_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_INPUT_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_INPUT_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_INPUT_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_INPUT_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_INPUT_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemMathsConstructor()
   * - Returns the Maths constructor (R3SystemMaths)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemMathsConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_MATHS_CONSTRUCTOR_START
    event.results = [R3SystemMaths];
    //GENERATED_STATIC_GET_R3_SYSTEM_MATHS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_MATHS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_MATHS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_MATHS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_MATHS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_MATHS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_MATHS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemPhysicsConstructor()
   * - Returns the Physics constructor (R3SystemPhysics)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemPhysicsConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR_START
    event.results = [R3SystemPhysics];
    //GENERATED_STATIC_GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemStorageConstructor()
   * - Returns the Storage constructor (R3SystemStorage)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemStorageConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_STORAGE_CONSTRUCTOR_START
    event.results = [R3SystemStorage];
    //GENERATED_STATIC_GET_R3_SYSTEM_STORAGE_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_STORAGE_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_STORAGE_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_STORAGE_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_STORAGE_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_STORAGE_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_STORAGE_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3SystemWebsocketConstructor()
   * - Returns the Websocket constructor (R3SystemWebsocket)
   * @param {Object} event
   * - No returns
   */
  static GetR3SystemWebsocketConstructor(event) {

    //GENERATED_STATIC_GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR_START
    event.results = [R3SystemWebsocket];
    //GENERATED_STATIC_GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR_AFTER_END

  }

  /**
   * GetR3UtilsConstructor()
   * - Returns the Utils constructor (R3Utils)
   * @param {Object} event
   * - No returns
   */
  static GetR3UtilsConstructor(event) {

    //GENERATED_STATIC_GET_R3_UTILS_CONSTRUCTOR_START
    event.results = [R3Utils];
    //GENERATED_STATIC_GET_R3_UTILS_CONSTRUCTOR_END

    //CUSTOM_STATIC_GET_R3_UTILS_CONSTRUCTOR_START
    //CUSTOM_STATIC_GET_R3_UTILS_CONSTRUCTOR_END

    //GENERATED_STATIC_GET_R3_UTILS_CONSTRUCTOR_AFTER_START
    //GENERATED_STATIC_GET_R3_UTILS_CONSTRUCTOR_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_R3_UTILS_CONSTRUCTOR_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_R3_UTILS_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Linking.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Linking.IsStarting = false;

/**
 * @param Linking.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Linking.IsStopping = false;

/**
 * @param Linking.Started
 * - Indicates whether this system is running
 */
Linking.Started = false;

/**
 * @param Linking.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Linking.Subscriptions = {};

/**
 * @param Linking.Runtimes
 * - A set of runtimes which the system manages
 */
Linking.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Linking.Users
 * - References to all Scenes
 */
Linking.Users = {};

/**
 * @param Linking.Groups
 * - References to all Groups
 */
Linking.Groups = {};

/**
 * @param Linking.Projects
 * - References to all Projects
 */
Linking.Projects = {};

/**
 * @param Linking.Components
 * - References to all Components
 */
Linking.Components = {};

/**
 * @param Linking.Entities
 * - References to all Entities
 */
Linking.Entities = {};

/**
 * @param Linking.CurrentProject
 * - No comment
 */
Linking.CurrentProject = null;

/**
 * @param Linking.CurrentUser
 * - No comment
 */
Linking.CurrentUser = null;

/**
 * @param Linking.CurrentGroup
 * - No comment
 */
Linking.CurrentGroup = null;

/**
 * @param Linking.Events
 * - A list of all events
 */
Linking.Events = [];

/**
 * @param Linking.Systems
 * - No comment
 */
Linking.Systems = new Set();

/**
 * @param Linking.Objects
 * - No comment
 */
Linking.Objects = new Set();
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Linking as default};
