//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import Event from '../../Event.js';
//CUSTOM_IMPORTS_END

import Code from '../Code.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  aceImplementation=this.setupAce()
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  createEditor(object) - Creates an editor for this code component 
  deleteEditor(object) - Removes the editor for this code component 
  updateEditor(object, property, value) - Updates the editor for this code component 
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
  setupAce() - Sets up ace if it is loaded
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createEditor(object) - Creates an editor for this code component 
  deleteEditor(object) - Removes the editor for this code component 
  updateEditor(object, property, value) - Updates the editor for this code component 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
  createEditorCode(object) - Ace implementation for [R3EventObjComponentCode] 
  deleteEditorCode(object) - Ace implementation for [R3EventObjComponentCode] 
  updateEditorCode(object, property, value) - Ace implementation for [R3EventObjComponentCode] 
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Ace extends Code {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeCode';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeCodeAce';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Ace';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_CODE;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param aceImplementation
     * - No comment
     */
    if (typeof options.aceImplementation === 'undefined') {
      options.aceImplementation = this.setupAce();
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * setupAce()
   * - Sets up ace if it is loaded
   * - No parameters
   * - No returns
   */
  setupAce() {

    //CUSTOM_SETUP_ACE_BEFORE_START
    //CUSTOM_SETUP_ACE_BEFORE_END

    //GENERATED_SETUP_ACE_BEFORE_START
    //GENERATED_SETUP_ACE_BEFORE_END

    //CUSTOM_SETUP_ACE_BEFORE_GENERATED_START
    //CUSTOM_SETUP_ACE_BEFORE_GENERATED_END

    //GENERATED_SETUP_ACE_START
    //GENERATED_SETUP_ACE_END

    //CUSTOM_SETUP_ACE_START
    if (typeof ace === 'undefined') {
      return {
        edit : () => {
          console.log('mock ace edit()');
          return {
            setValue : () => {
              console.log('mock ace setValue()');
            },
            setTheme : () => {
              console.log('mock set theme');
            },
            resize : () => {
              console.log('mock ace resize');
            },
            destroy : () => {
              console.log('mock ace destroy()');
            },
            session : {
              setTabSize : () => {
                console.log('mock set tab size');
              },
              on : () => {
                console.log('mock set session on()');
              },
              selection : {
                clearSelection : () => {
                  console.log('mock clear selection');
                }
              }
            }
          }
        }
      }
    } else {
      return ace;
    }
    //CUSTOM_SETUP_ACE_END

    //GENERATED_SETUP_ACE_AFTER_START
    //GENERATED_SETUP_ACE_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createEditor()
   * - Creates an editor for this code component
   * @param object
   * - No returns
   */
  createEditor(object) {

    //CUSTOM_CREATE_EDITOR_BEFORE_START
    //CUSTOM_CREATE_EDITOR_BEFORE_END

    //GENERATED_CREATE_EDITOR_BEFORE_START
    //CUSTOM_RETURN_GENERATED_CREATE_EDITOR_START
    super.createEditor(object);
    //CUSTOM_RETURN_GENERATED_CREATE_EDITOR_END
    //GENERATED_CREATE_EDITOR_BEFORE_END

    //CUSTOM_CREATE_EDITOR_BEFORE_GENERATED_START
    //CUSTOM_CREATE_EDITOR_BEFORE_GENERATED_END

    //GENERATED_CREATE_EDITOR_START
    if (
      object.type === 'R3EventObjComponentCode'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_EDITOR_CODE_START
      return this.createEditorCode(object);
      //CUSTOM_RETURN_OPTION_CREATE_EDITOR_CODE_END
    }
    //GENERATED_CREATE_EDITOR_END

    //CUSTOM_CREATE_EDITOR_START
    //CUSTOM_CREATE_EDITOR_END

    //GENERATED_CREATE_EDITOR_AFTER_START
    throw new Error(`No suitable method found in createEditor() in runtime R3RuntimeCodeAce`);
    //GENERATED_CREATE_EDITOR_AFTER_END

  }

  /**
   * deleteEditor()
   * - Removes the editor for this code component
   * @param object
   * - No returns
   */
  deleteEditor(object) {

    //CUSTOM_DELETE_EDITOR_BEFORE_START
    //CUSTOM_DELETE_EDITOR_BEFORE_END

    //GENERATED_DELETE_EDITOR_BEFORE_START
    //CUSTOM_RETURN_GENERATED_DELETE_EDITOR_START
    super.deleteEditor(object);
    //CUSTOM_RETURN_GENERATED_DELETE_EDITOR_END
    //GENERATED_DELETE_EDITOR_BEFORE_END

    //CUSTOM_DELETE_EDITOR_BEFORE_GENERATED_START
    //CUSTOM_DELETE_EDITOR_BEFORE_GENERATED_END

    //GENERATED_DELETE_EDITOR_START
    if (
      object.type === 'R3EventObjComponentCode'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_EDITOR_CODE_START
      return this.deleteEditorCode(object);
      //CUSTOM_RETURN_OPTION_DELETE_EDITOR_CODE_END
    }
    //GENERATED_DELETE_EDITOR_END

    //CUSTOM_DELETE_EDITOR_START
    //CUSTOM_DELETE_EDITOR_END

    //GENERATED_DELETE_EDITOR_AFTER_START
    throw new Error(`No suitable method found in deleteEditor() in runtime R3RuntimeCodeAce`);
    //GENERATED_DELETE_EDITOR_AFTER_END

  }

  /**
   * updateEditor()
   * - Updates the editor for this code component
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateEditor(object, property, value) {

    //CUSTOM_UPDATE_EDITOR_BEFORE_START
    //CUSTOM_UPDATE_EDITOR_BEFORE_END

    //GENERATED_UPDATE_EDITOR_BEFORE_START
    //CUSTOM_RETURN_GENERATED_UPDATE_EDITOR_START
    super.updateEditor(object, property, value);
    //CUSTOM_RETURN_GENERATED_UPDATE_EDITOR_END
    //GENERATED_UPDATE_EDITOR_BEFORE_END

    //CUSTOM_UPDATE_EDITOR_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_EDITOR_BEFORE_GENERATED_END

    //GENERATED_UPDATE_EDITOR_START
    if (
      object.type === 'R3EventObjComponentCode'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_EDITOR_CODE_START
      return this.updateEditorCode(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_EDITOR_CODE_END
    }
    //GENERATED_UPDATE_EDITOR_END

    //CUSTOM_UPDATE_EDITOR_START
    //CUSTOM_UPDATE_EDITOR_END

    //GENERATED_UPDATE_EDITOR_AFTER_START
    throw new Error(`No suitable method found in updateEditor() in runtime R3RuntimeCodeAce`);
    //GENERATED_UPDATE_EDITOR_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  /**
   * createEditorCode()
   * - Ace implementation for [R3EventObjComponentCode]
   * @param object
   * - No returns
   */
  createEditorCode(object) {

    //GENERATED_CREATE_EDITOR_CODE_BEFORE_START
    //GENERATED_CREATE_EDITOR_CODE_BEFORE_END

    //GENERATED_CREATE_EDITOR_CODE_START
    //GENERATED_CREATE_EDITOR_CODE_END

    //CUSTOM_CREATE_EDITOR_CODE_START
    let div = document.createElement('div');
    div.setAttribute('id', `code-${object.id}`);
    div.setAttribute('data-open', 'true');
    div.setAttribute('class', 'codeComponent');

    let p = document.createElement('p');
    p.setAttribute('id', `p-code-${object.id}`);
    p.innerText = `${object.name} - ${object.event?Event.GetEventName(object.event):'Not registered'}`;
    p.setAttribute('class', 'codeHeader');
    div.appendChild(p);

    let content = document.createElement('div');
    content.setAttribute('id', `ace-code-${object.id}`);
    content.setAttribute('class', `codeEditor`);
    div.appendChild(content);

    p.addEventListener(
      'click',
      function (_div, _content) {
        return function() {
          let open = _div.getAttribute('data-open');
          if (open === 'true') {
            _div.setAttribute('data-open', 'false');
            content.classList.add('hidden');
            div.classList.add('hidden');
          } else {
            _div.setAttribute('data-open', 'true');
            content.classList.remove('hidden');
            div.classList.remove('hidden');
          }
        }
      }(div, content)
    );

    let items = document.getElementById('code-items');
    items.appendChild(div);

    let instance = this.aceImplementation.edit(
      `ace-code-${object.id}`,
      {
        mode: "ace/mode/javascript",
        selectionStyle: "text"
      }
    );

    instance.setValue(object.code);

    instance.resize();

    instance.setTheme("ace/theme/twilight");

    instance.session.setTabSize(2);

    instance.session.selection.clearSelection();

    instance.session.on(
      'change',
      function(component) {
        return function(delta) {
          let code = this.getValue();
          component.fromUser = true;
          component.code = code;
        }.bind(instance)
      }(object)
    );

    return instance;
    //CUSTOM_CREATE_EDITOR_CODE_END

    //GENERATED_CREATE_EDITOR_CODE_AFTER_START
    //GENERATED_CREATE_EDITOR_CODE_AFTER_END

  }

  /**
   * deleteEditorCode()
   * - Ace implementation for [R3EventObjComponentCode]
   * @param object
   * - No returns
   */
  deleteEditorCode(object) {

    //GENERATED_DELETE_EDITOR_CODE_BEFORE_START
    //GENERATED_DELETE_EDITOR_CODE_BEFORE_END

    //GENERATED_DELETE_EDITOR_CODE_START
    //GENERATED_DELETE_EDITOR_CODE_END

    //CUSTOM_DELETE_EDITOR_CODE_START
    let items = document.getElementById('code-items');
    let div = document.getElementById(`code-${object.id}`);
    let instance = object.getInstance(this);
    instance.destroy();
    if (items && div) {
      items.removeChild(div);
    }
    //CUSTOM_DELETE_EDITOR_CODE_END

    //GENERATED_DELETE_EDITOR_CODE_AFTER_START
    //GENERATED_DELETE_EDITOR_CODE_AFTER_END

  }

  /**
   * updateEditorCode()
   * - Ace implementation for [R3EventObjComponentCode]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateEditorCode(object, property, value) {

    //GENERATED_UPDATE_EDITOR_CODE_BEFORE_START
    //GENERATED_UPDATE_EDITOR_CODE_BEFORE_END

    //GENERATED_UPDATE_EDITOR_CODE_START
    //GENERATED_UPDATE_EDITOR_CODE_END

    //CUSTOM_UPDATE_EDITOR_CODE_START
    if (
      property === 'name' ||
      property === 'event'
    ) {
      document.getElementById(`p-code-${object.id}`).innerText = `${object.name} - ${object.event?Event.GetEventName(object.event):'Not registered'}`
      return;
    }

    if (property === 'code') {
      let instance = object.getInstance(this);
      instance.setValue(value);
    }
    //CUSTOM_UPDATE_EDITOR_CODE_END

    //GENERATED_UPDATE_EDITOR_CODE_AFTER_START
    //GENERATED_UPDATE_EDITOR_CODE_AFTER_END

  }

  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Ace.Type = 'R3RuntimeCodeAce';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Ace as default};
