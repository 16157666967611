//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Runtime from '../Runtime.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  createEditor(object) - Creates an editor for this code component
  deleteEditor(object) - Removes the editor for this code component
  updateEditor(object, property, value) - Updates the editor for this code component
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createEditor(object) - Creates an editor for this code component 
  deleteEditor(object) - Removes the editor for this code component 
  updateEditor(object, property, value) - Updates the editor for this code component 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Code extends Runtime {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeCode';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeCode';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'CodeRuntime';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_CODE;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createEditor()
   * - Creates an editor for this code component
   * @param object
   * - No returns
   */
  createEditor(object) {

    //CUSTOM_CREATE_EDITOR_BEFORE_START
    //CUSTOM_CREATE_EDITOR_BEFORE_END

    //GENERATED_CREATE_EDITOR_BEFORE_START
    //GENERATED_CREATE_EDITOR_BEFORE_END

    //CUSTOM_CREATE_EDITOR_BEFORE_GENERATED_START
    //CUSTOM_CREATE_EDITOR_BEFORE_GENERATED_END

    //GENERATED_CREATE_EDITOR_START
    //GENERATED_CREATE_EDITOR_END

    //CUSTOM_CREATE_EDITOR_START
    //CUSTOM_CREATE_EDITOR_END

    //GENERATED_CREATE_EDITOR_AFTER_START
    //GENERATED_CREATE_EDITOR_AFTER_END

  }

  /**
   * deleteEditor()
   * - Removes the editor for this code component
   * @param object
   * - No returns
   */
  deleteEditor(object) {

    //CUSTOM_DELETE_EDITOR_BEFORE_START
    //CUSTOM_DELETE_EDITOR_BEFORE_END

    //GENERATED_DELETE_EDITOR_BEFORE_START
    //GENERATED_DELETE_EDITOR_BEFORE_END

    //CUSTOM_DELETE_EDITOR_BEFORE_GENERATED_START
    //CUSTOM_DELETE_EDITOR_BEFORE_GENERATED_END

    //GENERATED_DELETE_EDITOR_START
    //GENERATED_DELETE_EDITOR_END

    //CUSTOM_DELETE_EDITOR_START
    //CUSTOM_DELETE_EDITOR_END

    //GENERATED_DELETE_EDITOR_AFTER_START
    //GENERATED_DELETE_EDITOR_AFTER_END

  }

  /**
   * updateEditor()
   * - Updates the editor for this code component
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateEditor(object, property, value) {

    //CUSTOM_UPDATE_EDITOR_BEFORE_START
    //CUSTOM_UPDATE_EDITOR_BEFORE_END

    //GENERATED_UPDATE_EDITOR_BEFORE_START
    //GENERATED_UPDATE_EDITOR_BEFORE_END

    //CUSTOM_UPDATE_EDITOR_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_EDITOR_BEFORE_GENERATED_END

    //GENERATED_UPDATE_EDITOR_START
    //GENERATED_UPDATE_EDITOR_END

    //CUSTOM_UPDATE_EDITOR_START
    //CUSTOM_UPDATE_EDITOR_END

    //GENERATED_UPDATE_EDITOR_AFTER_START
    //GENERATED_UPDATE_EDITOR_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Code.Type = 'R3RuntimeCode';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Code as default};
