//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Input from '../Input.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  deRegisterControl() - Takes an input component and removes the listener on its DOM component to stop listening for events 
  registerControl() - Takes an input component and registers it on a DOM component to start listening for events 
  updateControl(property, value) - Updates an input control (attaches to another DOM element, or sets a target, etc.) 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Keyboard extends Input {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentInputKeyboard';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Keyboard';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_INPUT_CUSTOM,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * deRegisterControl()
   * - Takes an input component and removes the listener on its DOM component to stop listening for events
   * - No parameters
   * - No returns
   */
  deRegisterControl() {

    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_END

    //GENERATED_DE_REGISTER_CONTROL_BEFORE_START
    //GENERATED_DE_REGISTER_CONTROL_BEFORE_END

    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_GENERATED_END

    //GENERATED_DE_REGISTER_CONTROL_START
    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.DE_REGISTER_CONTROL_KEYBOARD,
      payload
    );
    //GENERATED_DE_REGISTER_CONTROL_END

    //CUSTOM_DE_REGISTER_CONTROL_START
    //CUSTOM_DE_REGISTER_CONTROL_END

    //GENERATED_DE_REGISTER_CONTROL_AFTER_START
    //GENERATED_DE_REGISTER_CONTROL_AFTER_END

  }

  /**
   * registerControl()
   * - Takes an input component and registers it on a DOM component to start listening for events
   * - No parameters
   * - No returns
   */
  registerControl() {

    //CUSTOM_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_REGISTER_CONTROL_BEFORE_END

    //GENERATED_REGISTER_CONTROL_BEFORE_START
    //GENERATED_REGISTER_CONTROL_BEFORE_END

    //CUSTOM_REGISTER_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_REGISTER_CONTROL_BEFORE_GENERATED_END

    //GENERATED_REGISTER_CONTROL_START
    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.REGISTER_CONTROL_KEYBOARD,
      payload
    );
    //GENERATED_REGISTER_CONTROL_END

    //CUSTOM_REGISTER_CONTROL_START
    //CUSTOM_REGISTER_CONTROL_END

    //GENERATED_REGISTER_CONTROL_AFTER_START
    //GENERATED_REGISTER_CONTROL_AFTER_END

  }

  /**
   * updateControl()
   * - Updates an input control (attaches to another DOM element, or sets a target, etc.)
   * @param property
   * @param value
   * - No returns
   */
  updateControl(property, value) {

    //CUSTOM_UPDATE_CONTROL_BEFORE_START
    //CUSTOM_UPDATE_CONTROL_BEFORE_END

    //GENERATED_UPDATE_CONTROL_BEFORE_START
    //GENERATED_UPDATE_CONTROL_BEFORE_END

    //CUSTOM_UPDATE_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_CONTROL_BEFORE_GENERATED_END

    //GENERATED_UPDATE_CONTROL_START
    Event.Emit(
      Event.UPDATE_CONTROL,
      {
        object : this,
        property,
        value
      }
    );
    //GENERATED_UPDATE_CONTROL_END

    //CUSTOM_UPDATE_CONTROL_START
    //CUSTOM_UPDATE_CONTROL_END

    //GENERATED_UPDATE_CONTROL_AFTER_START
    //GENERATED_UPDATE_CONTROL_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Keyboard.Type = 'R3EventObjComponentInputKeyboard';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Keyboard as default};
