//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import Event from '../../Event.js';
import Obj from '../../event/Obj.js';
//CUSTOM_IMPORTS_END

import Input from '../Input.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  deRegisterControl(object) - Takes an input component and removes the listener on its DOM component to stop listening for events 
  registerControl(object) - Takes an input component and registers it on a DOM component to start listening for events 
  updateControl(object, property, value) - Updates an input control (attaches to another DOM element, or sets a target, etc.) 
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  deRegisterControl(object) - Takes an input component and removes the listener on its DOM component to stop listening for events 
  registerControl(object) - Takes an input component and registers it on a DOM component to start listening for events 
  updateControl(object, property, value) - Updates an input control (attaches to another DOM element, or sets a target, etc.) 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
  deRegisterControlKeyboard(object) - Custom implementation for [R3EventObjComponentInputKeyboard] 
  registerControlKeyboard(object) - Custom implementation for [R3EventObjComponentInputKeyboard] 
  updateControlKeyboard(object, property, value) - Custom implementation for [R3EventObjComponentInputKeyboard] 
  deRegisterControlMouse(object) - Custom implementation for [R3EventObjComponentInputMouse] 
  registerControlMouse(object) - Custom implementation for [R3EventObjComponentInputMouse] 
  updateControlMouse(object, property, value) - Custom implementation for [R3EventObjComponentInputMouse] 
  deRegisterControlTouch(object) - Custom implementation for [R3EventObjComponentInputTouch] 
  registerControlTouch(object) - Custom implementation for [R3EventObjComponentInputTouch] 
  updateControlTouch(object, property, value) - Custom implementation for [R3EventObjComponentInputTouch] 
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  DoubleClick(object)
  MouseUp(event)
  MouseDown(event)
  MouseWheel(event)
  MouseMove(event)
  KeyUp(event)
  KeyDown(event)
  TouchStart(object)
  TouchEnd(object)
  TouchMove(object)
  TouchCancel(object)
 CUSTOM_STATIC_METHODS_END

 **/

export class Custom extends Input {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeInput';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeInputCustom';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Custom';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_INPUT;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * deRegisterControl()
   * - Takes an input component and removes the listener on its DOM component to stop listening for events
   * @param object
   * - No returns
   */
  deRegisterControl(object) {

    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_END

    //GENERATED_DE_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_RETURN_GENERATED_DE_REGISTER_CONTROL_START
    super.deRegisterControl(object);
    //CUSTOM_RETURN_GENERATED_DE_REGISTER_CONTROL_END
    //GENERATED_DE_REGISTER_CONTROL_BEFORE_END

    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_DE_REGISTER_CONTROL_BEFORE_GENERATED_END

    //GENERATED_DE_REGISTER_CONTROL_START
    if (
      object.type === 'R3EventObjComponentInputKeyboard'
    ) {
      //CUSTOM_RETURN_OPTION_DE_REGISTER_CONTROL_KEYBOARD_START
      return this.deRegisterControlKeyboard(object);
      //CUSTOM_RETURN_OPTION_DE_REGISTER_CONTROL_KEYBOARD_END
    }

    if (
      object.type === 'R3EventObjComponentInputMouse'
    ) {
      //CUSTOM_RETURN_OPTION_DE_REGISTER_CONTROL_MOUSE_START
      return this.deRegisterControlMouse(object);
      //CUSTOM_RETURN_OPTION_DE_REGISTER_CONTROL_MOUSE_END
    }

    if (
      object.type === 'R3EventObjComponentInputTouch'
    ) {
      //CUSTOM_RETURN_OPTION_DE_REGISTER_CONTROL_TOUCH_START
      return this.deRegisterControlTouch(object);
      //CUSTOM_RETURN_OPTION_DE_REGISTER_CONTROL_TOUCH_END
    }
    //GENERATED_DE_REGISTER_CONTROL_END

    //CUSTOM_DE_REGISTER_CONTROL_START
    //CUSTOM_DE_REGISTER_CONTROL_END

    //GENERATED_DE_REGISTER_CONTROL_AFTER_START
    throw new Error(`No suitable method found in deRegisterControl() in runtime R3RuntimeInputCustom`);
    //GENERATED_DE_REGISTER_CONTROL_AFTER_END

  }

  /**
   * registerControl()
   * - Takes an input component and registers it on a DOM component to start listening for events
   * @param object
   * - No returns
   */
  registerControl(object) {

    //CUSTOM_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_REGISTER_CONTROL_BEFORE_END

    //GENERATED_REGISTER_CONTROL_BEFORE_START
    //CUSTOM_RETURN_GENERATED_REGISTER_CONTROL_START
    super.registerControl(object);
    //CUSTOM_RETURN_GENERATED_REGISTER_CONTROL_END
    //GENERATED_REGISTER_CONTROL_BEFORE_END

    //CUSTOM_REGISTER_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_REGISTER_CONTROL_BEFORE_GENERATED_END

    //GENERATED_REGISTER_CONTROL_START
    if (
      object.type === 'R3EventObjComponentInputKeyboard'
    ) {
      //CUSTOM_RETURN_OPTION_REGISTER_CONTROL_KEYBOARD_START
      return this.registerControlKeyboard(object);
      //CUSTOM_RETURN_OPTION_REGISTER_CONTROL_KEYBOARD_END
    }

    if (
      object.type === 'R3EventObjComponentInputMouse'
    ) {
      //CUSTOM_RETURN_OPTION_REGISTER_CONTROL_MOUSE_START
      return this.registerControlMouse(object);
      //CUSTOM_RETURN_OPTION_REGISTER_CONTROL_MOUSE_END
    }

    if (
      object.type === 'R3EventObjComponentInputTouch'
    ) {
      //CUSTOM_RETURN_OPTION_REGISTER_CONTROL_TOUCH_START
      return this.registerControlTouch(object);
      //CUSTOM_RETURN_OPTION_REGISTER_CONTROL_TOUCH_END
    }
    //GENERATED_REGISTER_CONTROL_END

    //CUSTOM_REGISTER_CONTROL_START
    //CUSTOM_REGISTER_CONTROL_END

    //GENERATED_REGISTER_CONTROL_AFTER_START
    throw new Error(`No suitable method found in registerControl() in runtime R3RuntimeInputCustom`);
    //GENERATED_REGISTER_CONTROL_AFTER_END

  }

  /**
   * updateControl()
   * - Updates an input control (attaches to another DOM element, or sets a target, etc.)
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateControl(object, property, value) {

    //CUSTOM_UPDATE_CONTROL_BEFORE_START
    let updated;
    //CUSTOM_UPDATE_CONTROL_BEFORE_END

    //GENERATED_UPDATE_CONTROL_BEFORE_START
    //CUSTOM_RETURN_GENERATED_UPDATE_CONTROL_START
    updated = super.updateControl(object, property, value);
    if (updated) {
      return;
    }
    //CUSTOM_RETURN_GENERATED_UPDATE_CONTROL_END
    //GENERATED_UPDATE_CONTROL_BEFORE_END

    //CUSTOM_UPDATE_CONTROL_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_CONTROL_BEFORE_GENERATED_END

    //GENERATED_UPDATE_CONTROL_START
    if (
      object.type === 'R3EventObjComponentInputKeyboard'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_CONTROL_KEYBOARD_START
      return this.updateControlKeyboard(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_CONTROL_KEYBOARD_END
    }

    if (
      object.type === 'R3EventObjComponentInputMouse'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_CONTROL_MOUSE_START
      return this.updateControlMouse(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_CONTROL_MOUSE_END
    }

    if (
      object.type === 'R3EventObjComponentInputTouch'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_CONTROL_TOUCH_START
      return this.updateControlTouch(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_CONTROL_TOUCH_END
    }
    //GENERATED_UPDATE_CONTROL_END

    //CUSTOM_UPDATE_CONTROL_START
    //CUSTOM_UPDATE_CONTROL_END

    //GENERATED_UPDATE_CONTROL_AFTER_START
    throw new Error(`No suitable method found in updateControl() in runtime R3RuntimeInputCustom`);
    //GENERATED_UPDATE_CONTROL_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  /**
   * deRegisterControlKeyboard()
   * - Custom implementation for [R3EventObjComponentInputKeyboard]
   * @param object
   * - No returns
   */
  deRegisterControlKeyboard(object) {

    //GENERATED_DE_REGISTER_CONTROL_KEYBOARD_BEFORE_START
    //GENERATED_DE_REGISTER_CONTROL_KEYBOARD_BEFORE_END

    //GENERATED_DE_REGISTER_CONTROL_KEYBOARD_START
    //GENERATED_DE_REGISTER_CONTROL_KEYBOARD_END

    //CUSTOM_DE_REGISTER_CONTROL_KEYBOARD_START
    if (!object.registered) {
      return;
    }

    let {domElement : canvas} = object;

    if (!canvas) {
      return;
    }

    let instance = canvas.getInstance(Runtime.KEY_GRAPHICS_THREE);

    if (!instance) {
      return;
    }

    instance.removeEventListener(
      'keyup',
      Custom.KeyUp,
      {capture : false}
    );

    instance.removeEventListener(
      'keydown',
      Custom.KeyDown,
      {capture : false}
    );

    object.registered = false;
    //CUSTOM_DE_REGISTER_CONTROL_KEYBOARD_END

    //GENERATED_DE_REGISTER_CONTROL_KEYBOARD_AFTER_START
    //GENERATED_DE_REGISTER_CONTROL_KEYBOARD_AFTER_END

  }

  /**
   * registerControlKeyboard()
   * - Custom implementation for [R3EventObjComponentInputKeyboard]
   * @param object
   * - No returns
   */
  registerControlKeyboard(object) {

    //GENERATED_REGISTER_CONTROL_KEYBOARD_BEFORE_START
    //GENERATED_REGISTER_CONTROL_KEYBOARD_BEFORE_END

    //GENERATED_REGISTER_CONTROL_KEYBOARD_START
    //GENERATED_REGISTER_CONTROL_KEYBOARD_END

    //CUSTOM_REGISTER_CONTROL_KEYBOARD_START
    if (object.registered) {
      return;
    }

    let {domElement : canvas} = object;

    if (!canvas) {
      return;
    }

    let instance = canvas.getInstance(Runtime.KEY_GRAPHICS_THREE);

    if (!instance) {
      return;
    }

    instance.addEventListener(
      'keydown',
      Custom.KeyDown,
      {capture : false}
    );


    instance.addEventListener(
      'keyup',
      Custom.KeyUp,
      {capture : false}
    );

    object.registered = true;

    return {
      code : 0,
      message : 'Registered keyboard controls'
    };
    //CUSTOM_REGISTER_CONTROL_KEYBOARD_END

    //GENERATED_REGISTER_CONTROL_KEYBOARD_AFTER_START
    //GENERATED_REGISTER_CONTROL_KEYBOARD_AFTER_END

  }

  /**
   * updateControlKeyboard()
   * - Custom implementation for [R3EventObjComponentInputKeyboard]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateControlKeyboard(object, property, value) {

    //GENERATED_UPDATE_CONTROL_KEYBOARD_BEFORE_START
    //GENERATED_UPDATE_CONTROL_KEYBOARD_BEFORE_END

    //GENERATED_UPDATE_CONTROL_KEYBOARD_START
    //GENERATED_UPDATE_CONTROL_KEYBOARD_END

    //CUSTOM_UPDATE_CONTROL_KEYBOARD_START
    throw new Error(`Please implement Custom.updateControlKeyboard(object) in R3RuntimeInputCustom`);
    //CUSTOM_UPDATE_CONTROL_KEYBOARD_END

    //GENERATED_UPDATE_CONTROL_KEYBOARD_AFTER_START
    //GENERATED_UPDATE_CONTROL_KEYBOARD_AFTER_END

  }

  /**
   * deRegisterControlMouse()
   * - Custom implementation for [R3EventObjComponentInputMouse]
   * @param object
   * - No returns
   */
  deRegisterControlMouse(object) {

    //GENERATED_DE_REGISTER_CONTROL_MOUSE_BEFORE_START
    //GENERATED_DE_REGISTER_CONTROL_MOUSE_BEFORE_END

    //GENERATED_DE_REGISTER_CONTROL_MOUSE_START
    //GENERATED_DE_REGISTER_CONTROL_MOUSE_END

    //CUSTOM_DE_REGISTER_CONTROL_MOUSE_START
    if (!object.registered) {
      return;
    }

    let {domElement : canvas} = object;

    if (!canvas) {
      return;
    }

    let instance = canvas.getInstance(Runtime.KEY_GRAPHICS_THREE);

    if (!instance) {
      return;
    }


    instance.removeEventListener(
      'dblclick',
      Custom.DoubleClickRef
    );

    instance.removeEventListener(
      'mousedown',
      Custom.MouseDown
    );

    instance.removeEventListener(
      'mousemove',
      Custom.MouseMove,
      {
        passive : true
      }
    );

    instance.removeEventListener(
      'wheel',
      Custom.MouseWheel,
      {
        passive : true
      }
    );

    instance.removeEventListener(
      'mouseup',
      Custom.MouseUp
    );

    object.registered = false;
    //CUSTOM_DE_REGISTER_CONTROL_MOUSE_END

    //GENERATED_DE_REGISTER_CONTROL_MOUSE_AFTER_START
    //GENERATED_DE_REGISTER_CONTROL_MOUSE_AFTER_END

  }

  /**
   * registerControlMouse()
   * - Custom implementation for [R3EventObjComponentInputMouse]
   * @param object
   * - No returns
   */
  registerControlMouse(object) {

    //GENERATED_REGISTER_CONTROL_MOUSE_BEFORE_START
    //GENERATED_REGISTER_CONTROL_MOUSE_BEFORE_END

    //GENERATED_REGISTER_CONTROL_MOUSE_START
    //GENERATED_REGISTER_CONTROL_MOUSE_END

    //CUSTOM_REGISTER_CONTROL_MOUSE_START
    if (object.registered) {
      return;
    }

    let {domElement : canvas} = object;

    if (!canvas) {
      return;
    }

    let instance = canvas.getInstance(Runtime.KEY_GRAPHICS_THREE);

    if (!instance) {
      return;
    }

    Custom.DoubleClickRef = Custom.DoubleClick(object);

    instance.addEventListener(
      'dblclick',
      Custom.DoubleClickRef
    );

    instance.addEventListener(
      'mousedown',
      Custom.MouseDown
    );

    instance.addEventListener(
      'mousemove',
      Custom.MouseMove,
      {
        passive : true
      }
    );

    instance.addEventListener(
      'wheel',
      Custom.MouseWheel,
      {
        passive : true
      }
    );

    instance.addEventListener(
      'mouseup',
      Custom.MouseUp
    );

    object.registered = true;

    return {
      code : 0,
      message : 'Registered mouse controls'
    };
    //CUSTOM_REGISTER_CONTROL_MOUSE_END

    //GENERATED_REGISTER_CONTROL_MOUSE_AFTER_START
    //GENERATED_REGISTER_CONTROL_MOUSE_AFTER_END

  }

  /**
   * updateControlMouse()
   * - Custom implementation for [R3EventObjComponentInputMouse]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateControlMouse(object, property, value) {

    //GENERATED_UPDATE_CONTROL_MOUSE_BEFORE_START
    //GENERATED_UPDATE_CONTROL_MOUSE_BEFORE_END

    //GENERATED_UPDATE_CONTROL_MOUSE_START
    //GENERATED_UPDATE_CONTROL_MOUSE_END

    //CUSTOM_UPDATE_CONTROL_MOUSE_START
    throw new Error(`Please implement Custom.updateControlMouse(object) in R3RuntimeInputCustom for ${property}`);
    //CUSTOM_UPDATE_CONTROL_MOUSE_END

    //GENERATED_UPDATE_CONTROL_MOUSE_AFTER_START
    //GENERATED_UPDATE_CONTROL_MOUSE_AFTER_END

  }

  /**
   * deRegisterControlTouch()
   * - Custom implementation for [R3EventObjComponentInputTouch]
   * @param object
   * - No returns
   */
  deRegisterControlTouch(object) {

    //GENERATED_DE_REGISTER_CONTROL_TOUCH_BEFORE_START
    //GENERATED_DE_REGISTER_CONTROL_TOUCH_BEFORE_END

    //GENERATED_DE_REGISTER_CONTROL_TOUCH_START
    //GENERATED_DE_REGISTER_CONTROL_TOUCH_END

    //CUSTOM_DE_REGISTER_CONTROL_TOUCH_START
    if (!object.registered) {
      return;
    }

    let {domElement : canvas} = object;

    if (!canvas) {
      return;
    }

    let instance = canvas.getInstance(Runtime.KEY_GRAPHICS_THREE);

    if (!instance) {
      return;
    }

    instance.removeEventListener(
      'touchstart',
      Custom.TouchStartRef,
      {
        passive : true
      }
    );
    instance.removeEventListener(
      'touchmove',
      Custom.TouchMoveRef,
      {
        passive : true
      }
    );
    instance.removeEventListener(
      'touchend',
      Custom.TouchEndRef,
      {
        passive : true
      }
    );
    instance.removeEventListener(
      'touchcancel',
      Custom.TouchCancelRef,
      {
        passive : true
      }
    );

    object.registered = false;
    //CUSTOM_DE_REGISTER_CONTROL_TOUCH_END

    //GENERATED_DE_REGISTER_CONTROL_TOUCH_AFTER_START
    //GENERATED_DE_REGISTER_CONTROL_TOUCH_AFTER_END

  }

  /**
   * registerControlTouch()
   * - Custom implementation for [R3EventObjComponentInputTouch]
   * @param object
   * - No returns
   */
  registerControlTouch(object) {

    //GENERATED_REGISTER_CONTROL_TOUCH_BEFORE_START
    //GENERATED_REGISTER_CONTROL_TOUCH_BEFORE_END

    //GENERATED_REGISTER_CONTROL_TOUCH_START
    //GENERATED_REGISTER_CONTROL_TOUCH_END

    //CUSTOM_REGISTER_CONTROL_TOUCH_START
    if (object.registered) {
      return;
    }

    let {domElement : canvas} = object;

    if (!canvas) {
      return;
    }

    let instance = canvas.getInstance(Runtime.KEY_GRAPHICS_THREE);

    if (!instance) {
      return;
    }

    Custom.TouchStartRef = Custom.TouchStart(object);
    Custom.TouchMoveRef = Custom.TouchMove(object);
    Custom.TouchEndRef = Custom.TouchEnd(object);
    Custom.TouchCancelRef = Custom.TouchCancel(object);
    
    instance.addEventListener(
      'touchstart',
      Custom.TouchStartRef,
      {
        passive : true
      }
    );

    instance.addEventListener(
      'touchmove',
      Custom.TouchMoveRef,
      {
        passive : true
      }
    );
    instance.addEventListener(
      'touchend',
      Custom.TouchEndRef,
      {
        passive : true
      }
    );
    instance.addEventListener(
      'touchcancel',
      Custom.TouchCancelRef,
      {
        passive : true
      }
    );
    
    object.registered = true;

    return {
      code : 0,
      message : 'Registered touch controls'
    };
    //CUSTOM_REGISTER_CONTROL_TOUCH_END

    //GENERATED_REGISTER_CONTROL_TOUCH_AFTER_START
    //GENERATED_REGISTER_CONTROL_TOUCH_AFTER_END

  }

  /**
   * updateControlTouch()
   * - Custom implementation for [R3EventObjComponentInputTouch]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateControlTouch(object, property, value) {

    //GENERATED_UPDATE_CONTROL_TOUCH_BEFORE_START
    //GENERATED_UPDATE_CONTROL_TOUCH_BEFORE_END

    //GENERATED_UPDATE_CONTROL_TOUCH_START
    //GENERATED_UPDATE_CONTROL_TOUCH_END

    //CUSTOM_UPDATE_CONTROL_TOUCH_START
    throw new Error(`Please implement Custom.updateControlTouch(object) in R3RuntimeInputCustom`);
    //CUSTOM_UPDATE_CONTROL_TOUCH_END

    //GENERATED_UPDATE_CONTROL_TOUCH_AFTER_START
    //GENERATED_UPDATE_CONTROL_TOUCH_AFTER_END

  }

  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * DoubleClick()
   * - No comment
   * @param object
   * - No returns
   */
  static DoubleClick(object) {

    //GENERATED_STATIC_DOUBLE_CLICK_START
    //GENERATED_STATIC_DOUBLE_CLICK_END

    //CUSTOM_STATIC_DOUBLE_CLICK_START
    return function(event) {

      event.preventDefault();
      event.stopImmediatePropagation();

      let {which: buttonId} = event;
      let buttonName = Event.GetMouseButtonName(buttonId);
      if (buttonId === Event.Mouse.LEFT_BUTTON) {

        let {project} = object;

        if (!project) {
          console.warn(`No project present controls present for casting`);
          return;
        }

        if (project.mode === Obj.MODE_EDIT) {

          let {editorControls} = project;
          if (!editorControls) {
            console.warn(`No editor controls present for casting`);
            return;
          }

          let {camera, rayCaster} = editorControls;
          if (!rayCaster) {
            console.warn(`No rayCaster present for casting`);
            return;
          }

          if (!camera) {
            console.warn(`No camera present for casting`);
            return;
          }

          let {offsetX, offsetY} = event;

          let {width, height} = event.target;

          if (camera.fixedAspect) {
            offsetX -= camera.viewport.x;
            width = camera.viewport.width;
            offsetY -= camera.viewport.y;
            height = camera.viewport.height;
          }

          let x =  (offsetX / width) * 2 - 1;
          let y = -(offsetY / height) * 2 + 1;

          let mouse = {
            x,
            y
          };

          rayCaster.setFromCamera(
            mouse,
            camera
          );

          let intersect = rayCaster.getFirstIntersectedObject();

          if (intersect) {
            intersect.mesh.selected = !(intersect.mesh.selected);
          }
        }
      }
      Event.Emit(
        Event.DOUBLE_CLICK,
        {
          buttonId,
          buttonName,
          event: event
        }
      )
    }
    //CUSTOM_STATIC_DOUBLE_CLICK_END

    //GENERATED_STATIC_DOUBLE_CLICK_AFTER_START
    //GENERATED_STATIC_DOUBLE_CLICK_AFTER_END

    //CUSTOM_STATIC_GENERATED_DOUBLE_CLICK_AFTER_START
    //CUSTOM_STATIC_GENERATED_DOUBLE_CLICK_AFTER_END

  }
  /**
   * MouseUp()
   * - No comment
   * @param event
   * - No returns
   */
  static MouseUp(event) {

    //GENERATED_STATIC_MOUSE_UP_START
    //GENERATED_STATIC_MOUSE_UP_END

    //CUSTOM_STATIC_MOUSE_UP_START
    let {which: buttonId} = event;
    let buttonName = Event.GetMouseButtonName(buttonId);
    Event.Emit(
      Event.MOUSE_UP,
      {
        buttonId,
        buttonName,
        event : event
      }
    );
    //CUSTOM_STATIC_MOUSE_UP_END

    //GENERATED_STATIC_MOUSE_UP_AFTER_START
    //GENERATED_STATIC_MOUSE_UP_AFTER_END

    //CUSTOM_STATIC_GENERATED_MOUSE_UP_AFTER_START
    //CUSTOM_STATIC_GENERATED_MOUSE_UP_AFTER_END

  }
  /**
   * MouseDown()
   * - No comment
   * @param event
   * - No returns
   */
  static MouseDown(event) {

    //GENERATED_STATIC_MOUSE_DOWN_START
    //GENERATED_STATIC_MOUSE_DOWN_END

    //CUSTOM_STATIC_MOUSE_DOWN_START
    let {which: buttonId} = event;

    let buttonName = Event.GetMouseButtonName(buttonId);

    event.preventDefault();

    event.target.focus();

    Event.Emit(
      Event.MOUSE_DOWN,
      {
        buttonId,
        buttonName,
        event : event
      }
    );
    //CUSTOM_STATIC_MOUSE_DOWN_END

    //GENERATED_STATIC_MOUSE_DOWN_AFTER_START
    //GENERATED_STATIC_MOUSE_DOWN_AFTER_END

    //CUSTOM_STATIC_GENERATED_MOUSE_DOWN_AFTER_START
    //CUSTOM_STATIC_GENERATED_MOUSE_DOWN_AFTER_END

  }
  /**
   * MouseWheel()
   * - No comment
   * @param event
   * - No returns
   */
  static MouseWheel(event) {

    //GENERATED_STATIC_MOUSE_WHEEL_START
    //GENERATED_STATIC_MOUSE_WHEEL_END

    //CUSTOM_STATIC_MOUSE_WHEEL_START
    event.target.focus();

    Event.Emit(
      Event.MOUSE_WHEEL,
      {
        event : event
      }
    );
    //CUSTOM_STATIC_MOUSE_WHEEL_END

    //GENERATED_STATIC_MOUSE_WHEEL_AFTER_START
    //GENERATED_STATIC_MOUSE_WHEEL_AFTER_END

    //CUSTOM_STATIC_GENERATED_MOUSE_WHEEL_AFTER_START
    //CUSTOM_STATIC_GENERATED_MOUSE_WHEEL_AFTER_END

  }
  /**
   * MouseMove()
   * - No comment
   * @param event
   * - No returns
   */
  static MouseMove(event) {

    //GENERATED_STATIC_MOUSE_MOVE_START
    //GENERATED_STATIC_MOUSE_MOVE_END

    //CUSTOM_STATIC_MOUSE_MOVE_START
    Event.Emit(
      Event.MOUSE_MOVE,
      {
        event : event
      }
    );
    //CUSTOM_STATIC_MOUSE_MOVE_END

    //GENERATED_STATIC_MOUSE_MOVE_AFTER_START
    //GENERATED_STATIC_MOUSE_MOVE_AFTER_END

    //CUSTOM_STATIC_GENERATED_MOUSE_MOVE_AFTER_START
    //CUSTOM_STATIC_GENERATED_MOUSE_MOVE_AFTER_END

  }
  /**
   * KeyUp()
   * - No comment
   * @param event
   * - No returns
   */
  static KeyUp(event) {

    //GENERATED_STATIC_KEY_UP_START
    //GENERATED_STATIC_KEY_UP_END

    //CUSTOM_STATIC_KEY_UP_START
    event.preventDefault();

    event.stopPropagation();

    event.stopImmediatePropagation();

    if (event.target !== document.activeElement) {
      return;
    }

    let keyName = Event.GetKeyName(event.keyCode);
    Event.Emit(
      Event.KEY_UP,
      {
        code : event.code || event.key,
        keyCode : event.keyCode,
        keyName
      }
    );
    //CUSTOM_STATIC_KEY_UP_END

    //GENERATED_STATIC_KEY_UP_AFTER_START
    //GENERATED_STATIC_KEY_UP_AFTER_END

    //CUSTOM_STATIC_GENERATED_KEY_UP_AFTER_START
    //CUSTOM_STATIC_GENERATED_KEY_UP_AFTER_END

  }
  /**
   * KeyDown()
   * - No comment
   * @param event
   * - No returns
   */
  static KeyDown(event) {

    //GENERATED_STATIC_KEY_DOWN_START
    //GENERATED_STATIC_KEY_DOWN_END

    //CUSTOM_STATIC_KEY_DOWN_START
    event.preventDefault();

    event.stopPropagation();

    event.stopImmediatePropagation();

    if (event.target !== document.activeElement) {
      return;
    }

    let keyName = Event.GetKeyName(Number(event.keyCode));
    Event.Emit(
      Event.KEY_DOWN,
      {
        code : event.code || event.key,
        keyCode : event.keyCode,
        keyName
      }
    );
    //CUSTOM_STATIC_KEY_DOWN_END

    //GENERATED_STATIC_KEY_DOWN_AFTER_START
    //GENERATED_STATIC_KEY_DOWN_AFTER_END

    //CUSTOM_STATIC_GENERATED_KEY_DOWN_AFTER_START
    //CUSTOM_STATIC_GENERATED_KEY_DOWN_AFTER_END

  }
  /**
   * TouchStart()
   * - No comment
   * @param object
   * - No returns
   */
  static TouchStart(object) {

    //GENERATED_STATIC_TOUCH_START_START
    //GENERATED_STATIC_TOUCH_START_END

    //CUSTOM_STATIC_TOUCH_START_START
    object.touches = {};

    return function(event) {
      
      for (let t = 0; t < event.touches.length; t++) {
        this.touches[event.touches[t].identifier] = {
          left: 0,
          right: 0,
          up: 0,
          down: 0,
          lastTouchX: event.touches[t].pageX,
          lastTouchY: event.touches[t].pageY,
          pageX: event.touches[t].pageX,
          pageY: event.touches[t].pageY,
          cancelled: false,
          ended: false
        };
      }

      this.touches.event = event;

      Event.Emit(
        Event.TOUCH_START,
        this.touches
      );
      
    }.bind(object);
    //CUSTOM_STATIC_TOUCH_START_END

    //GENERATED_STATIC_TOUCH_START_AFTER_START
    //GENERATED_STATIC_TOUCH_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_TOUCH_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_TOUCH_START_AFTER_END

  }
  /**
   * TouchEnd()
   * - No comment
   * @param object
   * - No returns
   */
  static TouchEnd(object) {

    //GENERATED_STATIC_TOUCH_END_START
    //GENERATED_STATIC_TOUCH_END_END

    //CUSTOM_STATIC_TOUCH_END_START
    return function(event) {

      for (let t = 0; t < event.changedTouches.length; t++) {
        this.touches[event.changedTouches[t].identifier].ended = true;
        this.touches[event.changedTouches[t].identifier].event = event;
        Event.Emit(
          Event.TOUCH_END,
          this.touches[event.changedTouches[t].identifier]
        );
        delete this.touches[event.changedTouches[t].identifier];
      }

    }.bind(object);
    //CUSTOM_STATIC_TOUCH_END_END

    //GENERATED_STATIC_TOUCH_END_AFTER_START
    //GENERATED_STATIC_TOUCH_END_AFTER_END

    //CUSTOM_STATIC_GENERATED_TOUCH_END_AFTER_START
    //CUSTOM_STATIC_GENERATED_TOUCH_END_AFTER_END

  }
  /**
   * TouchMove()
   * - No comment
   * @param object
   * - No returns
   */
  static TouchMove(object) {

    //GENERATED_STATIC_TOUCH_MOVE_START
    //GENERATED_STATIC_TOUCH_MOVE_END

    //CUSTOM_STATIC_TOUCH_MOVE_START
    return function(event) {

      let id = null;

      let leftTouch = null;
      let rightTouch = null;
      let bottomTouch = null;
      let topTouch = null;

      let inward = false;
      let outward = false;
      let pinch = false;
      let zoom = false;

      let totalUp = 0;
      let totalDown = 0;
      let totalLeft = 0;
      let totalRight = 0;

      for (let t = 0; t < event.changedTouches.length; t++) {

        id = event.changedTouches[t].identifier;

        if (this.touches[id]) {

          let diffX = Math.abs(this.touches[id].lastTouchX - event.changedTouches[t].pageX);
          let diffY = Math.abs(this.touches[id].lastTouchY - event.changedTouches[t].pageY);

          let left = 0;
          let right = 0;
          let up = 0;
          let down = 0;

          if (this.touches[id].lastTouchX < event.changedTouches[t].pageX) {
            right += diffX;
          }

          if (this.touches[id].lastTouchX > event.changedTouches[t].pageX) {
            left += diffX;
          }

          if (this.touches[id].lastTouchY > event.changedTouches[t].pageY) {
            up += diffY;
          }

          if (this.touches[id].lastTouchY < event.changedTouches[t].pageY) {
            down += diffY;
          }

          this.touches[id].right = right;
          this.touches[id].left = left;
          this.touches[id].up = up;
          this.touches[id].down = down;
          this.touches[id].lastTouchX = event.changedTouches[t].pageX;
          this.touches[id].lastTouchY = event.changedTouches[t].pageY;
          this.touches[id].pageX = event.changedTouches[t].pageX;
          this.touches[id].pageY = event.changedTouches[t].pageY;

          totalLeft += left;
          totalRight += right;
          totalUp += up;
          totalDown += down;
        }
      }

      if (event.changedTouches.length === 2) {
        if (event.changedTouches[0].pageX < event.changedTouches[1].pageX) {
          leftTouch = this.touches[event.changedTouches[0].identifier];
          rightTouch = this.touches[event.changedTouches[1].identifier];
        } else {
          leftTouch = this.touches[event.changedTouches[1].identifier];
          rightTouch = this.touches[event.changedTouches[0].identifier];
        }

        if (event.changedTouches[0].pageY < event.changedTouches[1].pageY) {
          bottomTouch = this.touches[event.changedTouches[1].identifier];
          topTouch = this.touches[event.changedTouches[0].identifier];
        } else {
          bottomTouch = this.touches[event.changedTouches[0].identifier];
          topTouch = this.touches[event.changedTouches[1].identifier];
        }
      }

      if (leftTouch && leftTouch.left && rightTouch && rightTouch.right) {
        outward = true;
      }

      if (leftTouch && leftTouch.right && rightTouch && rightTouch.left) {
        inward = true;
      }

      if (bottomTouch && bottomTouch.up && topTouch && topTouch.down) {
        pinch = true;
      }

      if (bottomTouch && bottomTouch.down && topTouch && topTouch.up) {
        zoom = true;
      }

      this.touches.event = event;

      this.touches.meta = {
        inward : inward,
        outward : outward,
        pinch : pinch,
        zoom : zoom,
        totalLeft : totalLeft,
        totalRight : totalRight,
        totalUp : totalUp,
        totalDown : totalDown,
        movementX : totalRight - totalLeft,
        movementY : totalDown - totalUp
      };
      
      Event.Emit(
        Event.TOUCH_MOVE,
        this.touches
      );

    }.bind(object);
    //CUSTOM_STATIC_TOUCH_MOVE_END

    //GENERATED_STATIC_TOUCH_MOVE_AFTER_START
    //GENERATED_STATIC_TOUCH_MOVE_AFTER_END

    //CUSTOM_STATIC_GENERATED_TOUCH_MOVE_AFTER_START
    //CUSTOM_STATIC_GENERATED_TOUCH_MOVE_AFTER_END

  }
  /**
   * TouchCancel()
   * - No comment
   * @param object
   * - No returns
   */
  static TouchCancel(object) {

    //GENERATED_STATIC_TOUCH_CANCEL_START
    //GENERATED_STATIC_TOUCH_CANCEL_END

    //CUSTOM_STATIC_TOUCH_CANCEL_START
    return function(event) {

      for (let t = 0; t < event.changedTouches.length; t++) {
        this.touches[event.changedTouches[t].identifier].cancelled = true;
        this.touches[event.changedTouches[t].identifier].event = event;
        Event.Emit(
          Event.TOUCH_CANCEL,
          this.touches[event.changedTouches[t].identifier]
        );
        delete this.touches[event.changedTouches[t].identifier];
      }

    }.bind(object);
    //CUSTOM_STATIC_TOUCH_CANCEL_END

    //GENERATED_STATIC_TOUCH_CANCEL_AFTER_START
    //GENERATED_STATIC_TOUCH_CANCEL_AFTER_END

    //CUSTOM_STATIC_GENERATED_TOUCH_CANCEL_AFTER_START
    //CUSTOM_STATIC_GENERATED_TOUCH_CANCEL_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Custom.Type = 'R3RuntimeInputCustom';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Custom as default};
