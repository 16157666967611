//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Runtime from '../Runtime.js';
//CUSTOM_IMPORTS_END

import Utils from '../Utils.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
  id=Utils.RandomId() - Each Object receives a UUID which uniquely identifies it everywhere (client and server side)
  name=this.getName() - Each Object has a name
  initialized=false - A boolean which indicates whether this Object has initialized
  referenceCount=0 - A count indicating how many objects reference this object
  runtimes={} - This generic runtime map which holds references to all associated runtimes for this Object, Component or Entity including all its requirements for validating this object before executing its runtime method.
  instances={} - An object which stores all instances to its runtimes
  parents=[] - All Objects could have parents
  children=[] - All Objects could have children
  enableUpdate=true - By default all the get and set functions manages createInstance, updateInstance and deleteInstance. Disable this to do a minimal amount of work like setting child and parent relationships, but all instance related events are not triggered.
  state=Obj.STATE_INITIALIZED - When this object initializes - it will be initialized
  stateChangedPromise=this.setupStateChanged(options)
  resolveStateChanged=null
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  isLoading=false - Set this to true to add the object to the storage system to indicate that this object is loading
  connected=false
  mode = Obj.MODE_LIVE
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  MODE_LIVE=1 - Flag indicating this object is currently in live mode
  MODE_EDIT=2 - Flag indicating this object is currently in edit mode
  STATE_INITIALIZED = 0x1 - Flag indicating that this object is initialized
  STATE_LOADING     = 0x2 - Flag indicating that this object is loading
  STATE_SAVING      = 0x3 - Flag indicating that this object is saving
  STATE_UPDATING    = 0x4 - Flag indicating that this object is updating
  STATE_REMOVING    = 0x5 - Flag indicating that this object is removing
  STATE_LOADED      = 0x6 - Flag indicating that this object has loaded
  STATE_SAVED       = 0x7 - Flag indicating that this object has saved
  STATE_UPDATED     = 0x8 - Flag indicating that this object has updated
  STATE_REMOVED     = 0x9 - Flag indicating that this object has been removed
  STATE_ERRORED     = 0xa - Flag indicating that this object has errored in some state
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_METHODS_START
  getInstance(runtime) - Returns the instance based on the runtime
  setInstance(runtime, instance) - Sets the instance based on the runtime
  updateFromInstance(runtime) - Updates the object from the runtime instance
  setParentChildRelationships(child, parent, oldParent = null) - Sets the parent / child relationships. If the parent is no longer existing, pass in the old parent as oldReference to remove the relationship.
  setProperty(reference) - This callback executes during assignment of reference properties @returns function
  getProperty(reference) - This callback executes during retrieval of reference properties @returns function
  getClassConstructor(classType) - Gets the class constructor based on classType @returns {NumberConstructor|*}
  getName() - Sets a proper name for this object
  getCurrentUser() - Gets the current user from the Linking System
  getCurrentGroup() - Gets the current group from the Linking System
  getCurrentProject() - Gets the current project from the Linking System
  toApiObject() - Strips all properties except its data into an object ready to be serialized to storage
  parseObjToApiObject(value) - Parses the value to an API Object
  setupStateChanged(options) - Sets up the state changed promise
  getStateName() - Returns the state name based on the current state
 TEMPLATE_METHODS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save", "load", "clone", "remove"],
        "properties" : [
          {
            "property" : "id",
            "type" : "String"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "name",
            "type" : "String"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 CUSTOM_METHODS_START
  disconnect() - Disconnect from the websocket service
  initialize(initOptions) - Emits initialize event
  emitInitializeEvent(options) - Emits an object specific initialize event
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createGui() - Creates a gui runtime instance for this runtime based on the R3 Object 
  deleteGui() - Deletes a gui runtime instance for this runtime based on the R3 Object 
  async send(message) - Sends a message to a websocket 
  updateGui(property, value) - Updates the gui runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Obj extends Event {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObj';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Obj';
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    /**
     * @param id
     * - Each Object receives a UUID which uniquely identifies it everywhere (client and server side)
     */
    if (typeof options.id === 'undefined') {
      options.id = Utils.RandomId();
    }

    /**
     * @param name
     * - Each Object has a name
     */
    if (typeof options.name === 'undefined') {
      options.name = this.getName();
    }

    /**
     * @param initialized
     * - A boolean which indicates whether this Object has initialized
     */
    if (typeof options.initialized === 'undefined') {
      options.initialized = false;
    }

    /**
     * @param referenceCount
     * - A count indicating how many objects reference this object
     */
    if (typeof options.referenceCount === 'undefined') {
      options.referenceCount = 0;
    }

    /**
     * @param runtimes
     * - This generic runtime map which holds references to all associated runtimes for this Object, Component or Entity
     *   including all its requirements for validating this object before executing its runtime method.
     */
    if (typeof options.runtimes === 'undefined') {
      options.runtimes = {};
    }

    /**
     * @param instances
     * - An object which stores all instances to its runtimes
     */
    if (typeof options.instances === 'undefined') {
      options.instances = {};
    }

    /**
     * @param parents
     * - All Objects could have parents
     */
    if (typeof options.parents === 'undefined') {
      options.parents = [];
    }

    /**
     * @param children
     * - All Objects could have children
     */
    if (typeof options.children === 'undefined') {
      options.children = [];
    }

    /**
     * @param enableUpdate
     * - By default all the get and set functions manages createInstance, updateInstance and deleteInstance. Disable this
     *   to do a minimal amount of work like setting child and parent relationships, but all instance related events are
     *   not triggered.
     */
    if (typeof options.enableUpdate === 'undefined') {
      options.enableUpdate = true;
    }

    /**
     * @param state
     * - When this object initializes - it will be initialized
     */
    if (typeof options.state === 'undefined') {
      options.state = Obj.STATE_INITIALIZED;
    }

    /**
     * @param stateChangedPromise
     * - No comment
     */
    if (typeof options.stateChangedPromise === 'undefined') {
      options.stateChangedPromise = this.setupStateChanged(options);
    }

    /**
     * @param resolveStateChanged
     * - No comment
     */
    if (typeof options.resolveStateChanged === 'undefined') {
      options.resolveStateChanged = null;
    }
    //GENERATED_TEMPLATE_OPTIONS_END

    //CUSTOM_AFTER_TEMPLATE_OPTIONS_START
    //CUSTOM_AFTER_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param isLoading
     * - Set this to true to add the object to the storage system to indicate that this object is loading
     */
    if (typeof options.isLoading === 'undefined') {
      options.isLoading = false;
    }

    /**
     * @param connected
     * - No comment
     */
    if (typeof options.connected === 'undefined') {
      options.connected = false;
    }

    /**
     * @param mode
     * - No comment
     */
    if (typeof options.mode === 'undefined') {
      options.mode = Obj.MODE_LIVE;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "save",
              "load",
              "clone",
              "remove"
            ],
            "properties": [
              {
                "property": "id",
                "Constructor" : this.getClassConstructor("String")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "name",
                "Constructor" : this.getClassConstructor("String")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_CUSTOM_REQUIREMENTS_START
    //GENERATED_CUSTOM_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_GUI_TWEAK_PANE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }

    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_WEBSOCKET_SOCKET_I_O,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "id",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String"
      },
      {
        "property": "name",
        "Constructor" : this.getClassConstructor("String"),
        "type": "String"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  /**
   * getInstance()
   * - Returns the instance based on the runtime
   * @param runtime
   * - No returns
   */
  getInstance(runtime) {

    //CUSTOM_GET_INSTANCE_BEFORE_START
    //CUSTOM_GET_INSTANCE_BEFORE_END

    //GENERATED_GET_INSTANCE_BEFORE_START
    //GENERATED_GET_INSTANCE_BEFORE_END

    //CUSTOM_GET_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_GET_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_GET_INSTANCE_START
    let runtimeKey = null;

    if (runtime instanceof Runtime) {
      runtimeKey = runtime.type;
    }

    if (Object(runtime) instanceof String) {
      runtimeKey = runtime;
    }

    let instance = {};

    if (runtimeKey) {

      let keys = Object.keys(this.instances);

      let assigned = false;

      for (let key of keys) {

        if (key === runtimeKey) {
          return this.instances[key].object;
        }

        if (this.instances[key].runtime.key === runtimeKey) {
          Object.assign(instance, this.instances[key].object);
          assigned = true;
        }

      }

      if (!assigned) {
        return null;
      }

    } else {
      let keys = Object.keys(this.instances);
      for (let key of keys) {
        if (this.instances[key].object) {
          Object.assign(instance, this.instances[key].object);
        }
      }
    }

    return instance;
    //GENERATED_GET_INSTANCE_END

    //CUSTOM_GET_INSTANCE_START
    //CUSTOM_GET_INSTANCE_END

    //GENERATED_GET_INSTANCE_AFTER_START
    //GENERATED_GET_INSTANCE_AFTER_END

  }

  /**
   * setInstance()
   * - Sets the instance based on the runtime
   * @param runtime
   * @param instance
   * - No returns
   */
  setInstance(runtime, instance) {

    //CUSTOM_SET_INSTANCE_BEFORE_START
    //CUSTOM_SET_INSTANCE_BEFORE_END

    //GENERATED_SET_INSTANCE_BEFORE_START
    //GENERATED_SET_INSTANCE_BEFORE_END

    //CUSTOM_SET_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_SET_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_SET_INSTANCE_START
    let key = null;

    if (runtime instanceof Runtime) {
      key = runtime.type;
    }

    if (Object(runtime) instanceof String) {
      key = runtime;
    }

    if (!key) {
      throw new Error(`You did not specify a runtime to set the instance for`);
    }

    if (typeof this.instances[key] === 'undefined') {
      this.instances[key] = {};
    }

    this.instances[key].object = instance;
    //GENERATED_SET_INSTANCE_END

    //CUSTOM_SET_INSTANCE_START
    //CUSTOM_SET_INSTANCE_END

    //GENERATED_SET_INSTANCE_AFTER_START
    //GENERATED_SET_INSTANCE_AFTER_END

  }

  /**
   * updateFromInstance()
   * - Updates the object from the runtime instance
   * @param runtime
   * - No returns
   */
  updateFromInstance(runtime) {

    //CUSTOM_UPDATE_FROM_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_FROM_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_FROM_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_FROM_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_FROM_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_FROM_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_FROM_INSTANCE_START
    //GENERATED_UPDATE_FROM_INSTANCE_END

    //CUSTOM_UPDATE_FROM_INSTANCE_START
    let instance = this.getInstance(runtime);
    for (let key of Object.keys(instance)) {
      this._cache[key] = instance[key];
    }
    // let instance = this.getInstance(runtime);
    // for (let [key, value] of Object.entries(instance)) {
    //
    //   if (this[key]
    //
    //
    // }
    //CUSTOM_UPDATE_FROM_INSTANCE_END

    //GENERATED_UPDATE_FROM_INSTANCE_AFTER_START
    //GENERATED_UPDATE_FROM_INSTANCE_AFTER_END

  }

  /**
   * setParentChildRelationships()
   * - Sets the parent / child relationships. If the parent is no longer existing, pass in the old parent as
   *   oldReference to remove the relationship.
   * @param child
   * @param parent
   * @param {Object|null} [oldParent=null]
   * - No returns
   */
  setParentChildRelationships(child, parent, oldParent = null) {

    //CUSTOM_SET_PARENT_CHILD_RELATIONSHIPS_BEFORE_START
    //CUSTOM_SET_PARENT_CHILD_RELATIONSHIPS_BEFORE_END

    //GENERATED_SET_PARENT_CHILD_RELATIONSHIPS_BEFORE_START
    //GENERATED_SET_PARENT_CHILD_RELATIONSHIPS_BEFORE_END

    //CUSTOM_SET_PARENT_CHILD_RELATIONSHIPS_BEFORE_GENERATED_START
    //CUSTOM_SET_PARENT_CHILD_RELATIONSHIPS_BEFORE_GENERATED_END

    //GENERATED_SET_PARENT_CHILD_RELATIONSHIPS_START
    //GENERATED_SET_PARENT_CHILD_RELATIONSHIPS_END

    //CUSTOM_SET_PARENT_CHILD_RELATIONSHIPS_START
    if (child && parent) {
      if (parent instanceof Array) {
        parent.map(
          (_parent) => {

            Utils.PushUnique(
              child.parents,
              _parent
            );

            Utils.PushUnique(
              _parent.children,
              child
            );

            _parent.referenceCount = _parent.children.length;
          }
        )
      } else {

        Utils.PushUnique(
          child.parents,
          parent
        );

        parent.referenceCount++;

        Utils.PushUnique(
          parent.children,
          child
        );
      }
    }

    if (child && !parent && oldParent) {
      if (oldParent instanceof Array) {
        oldParent.map(
          (_oldParent) => {
            _oldParent.referenceCount--;
            Utils.RemoveFromArray(child.parents, _oldParent);
            Utils.RemoveFromArray(_oldParent.children, child);
          }
        )
      } else {
        oldParent.referenceCount--;
        Utils.RemoveFromArray(child.parents, oldParent);
        Utils.RemoveFromArray(oldParent.children, child);
      }
    }
    //CUSTOM_SET_PARENT_CHILD_RELATIONSHIPS_END

    //GENERATED_SET_PARENT_CHILD_RELATIONSHIPS_AFTER_START
    //GENERATED_SET_PARENT_CHILD_RELATIONSHIPS_AFTER_END

  }

  /**
   * setProperty()
   * - This callback executes during assignment of reference properties
   * @param reference
   * @returns function
   */
  setProperty(reference) {

    //CUSTOM_SET_PROPERTY_BEFORE_START
    //CUSTOM_SET_PROPERTY_BEFORE_END

    //GENERATED_SET_PROPERTY_BEFORE_START
    //GENERATED_SET_PROPERTY_BEFORE_END

    //CUSTOM_SET_PROPERTY_BEFORE_GENERATED_START
    //CUSTOM_SET_PROPERTY_BEFORE_GENERATED_END

    //GENERATED_SET_PROPERTY_START
    //GENERATED_SET_PROPERTY_END

    //CUSTOM_SET_PROPERTY_START
    return (x) => {

      let {property, Constructor} = reference;

      let isPrimitive = false;

      if (Constructor instanceof Array) {
        Constructor = Constructor[0];
        if (x instanceof Array && x.length && !Utils.ArrayOfType(x, Constructor)) {
          throw new Error(`The value being assigned is not of the correct type`);
        }
      }

      if (
        Constructor === Number ||
        Constructor === String ||
        Constructor === Boolean
      ) {
        isPrimitive = true;
      }

      // if (x instanceof Array) {
      //   console.log(`${this.uniqueName}.${property} is changing to [${[x.map(p => p.type).join(', ')]}]`);
      // } else {
      //   console.log(`${this.uniqueName}.${property} is changing to ${x && x.type?x.type:x}`);
      // }

      if (!this.initialized) {
        if (x instanceof Obj || (x instanceof Array && x.length) && !isPrimitive && Utils.ArrayOfType(x, Obj)) {
          this.setParentChildRelationships(this, x);
        }
        this._cache[reference.property] = x;
        return x;
      }

      if (!this.enableUpdate) {
        this._cache[reference.property] = x;
        return x;
      }

      let old = this._cache[reference.property];

      if (x instanceof Obj && old === x) {
        return x;
      }

      if (x instanceof Array && old instanceof Array && Utils.DeepEquals(old, x)) {
        return x;
      }

      Event.Emit(
        Event.BEFORE_ASSIGN_PROPERTY,
        {
          object : this,
          property,
          isPrimitive,
          value : x
        }
      );

      if (old instanceof Obj || (old instanceof Array && old.length && !isPrimitive && Utils.ArrayOfType(old, Obj))) {
        this.setParentChildRelationships(this, null, old);
      }

      if (x instanceof Obj || (x instanceof Array && x.length && !isPrimitive && Utils.ArrayOfType(x, Obj))) {
        this.setParentChildRelationships(this, x);
      }

      if (this.spin) {
        if (x > this.max) {
          let diff = Math.abs(x - this.max);
          x = this.min + diff;
        }

        if (x < this.min) {
          let diff = Math.abs(this.min - x);
          x = this.max - diff;
        }
      }

      this._cache[reference.property] = x;

      Event.Emit(
        Event.AFTER_ASSIGN_PROPERTY,
        {
          object : this,
          property,
          isPrimitive,
          value : x,
          previous : old
        }
      );

      return x;
    }
    //CUSTOM_SET_PROPERTY_END

    //GENERATED_SET_PROPERTY_AFTER_START
    //GENERATED_SET_PROPERTY_AFTER_END

  }

  /**
   * getProperty()
   * - This callback executes during retrieval of reference properties
   * @param reference
   * @returns function
   */
  getProperty(reference) {

    //CUSTOM_GET_PROPERTY_BEFORE_START
    //CUSTOM_GET_PROPERTY_BEFORE_END

    //GENERATED_GET_PROPERTY_BEFORE_START
    //GENERATED_GET_PROPERTY_BEFORE_END

    //CUSTOM_GET_PROPERTY_BEFORE_GENERATED_START
    //CUSTOM_GET_PROPERTY_BEFORE_GENERATED_END

    //GENERATED_GET_PROPERTY_START
    return () => {
      return this._cache[reference.property];
    }
    //GENERATED_GET_PROPERTY_END

    //CUSTOM_GET_PROPERTY_START
    //CUSTOM_GET_PROPERTY_END

    //GENERATED_GET_PROPERTY_AFTER_START
    //GENERATED_GET_PROPERTY_AFTER_END

  }

  /**
   * getClassConstructor()
   * - Gets the class constructor based on classType
   * @param classType
   * @returns {NumberConstructor|*}
   */
  getClassConstructor(classType) {

    //CUSTOM_GET_CLASS_CONSTRUCTOR_BEFORE_START
    //CUSTOM_GET_CLASS_CONSTRUCTOR_BEFORE_END

    //GENERATED_GET_CLASS_CONSTRUCTOR_BEFORE_START
    //GENERATED_GET_CLASS_CONSTRUCTOR_BEFORE_END

    //CUSTOM_GET_CLASS_CONSTRUCTOR_BEFORE_GENERATED_START
    //CUSTOM_GET_CLASS_CONSTRUCTOR_BEFORE_GENERATED_END

    //GENERATED_GET_CLASS_CONSTRUCTOR_START
    if (classType === 'Number') {
      return Number;
    }

    if (classType === 'String') {
      return String;
    }

    if (classType === 'Boolean') {
      return Boolean;
    }

    if (classType === 'Object') {
      return Object;
    }

    if (classType === 'ArrayBuffer') {
      return ArrayBuffer;
    }

    if (classType === 'Buffer') {
      return Buffer;
    }

    if (classType === 'FormData') {
      return FormData;
    }

    let eventName = Utils.UpperSnakeCase(classType);
    eventName = `GET_${eventName}_CONSTRUCTOR`;

    let payload = {};

    Event.Emit(
      Event[eventName],
      payload
    );

    if (!payload.results || payload.results.length !== 1) {
      throw new Error(`Could not determine the class constructor based on ${classType}`);
    }

    return payload.results[0];
    //GENERATED_GET_CLASS_CONSTRUCTOR_END

    //CUSTOM_GET_CLASS_CONSTRUCTOR_START
    //CUSTOM_GET_CLASS_CONSTRUCTOR_END

    //GENERATED_GET_CLASS_CONSTRUCTOR_AFTER_START
    //GENERATED_GET_CLASS_CONSTRUCTOR_AFTER_END

  }

  /**
   * getName()
   * - Sets a proper name for this object
   * - No parameters
   * - No returns
   */
  getName() {

    //CUSTOM_GET_NAME_BEFORE_START
    //CUSTOM_GET_NAME_BEFORE_END

    //GENERATED_GET_NAME_BEFORE_START
    //GENERATED_GET_NAME_BEFORE_END

    //CUSTOM_GET_NAME_BEFORE_GENERATED_START
    //CUSTOM_GET_NAME_BEFORE_GENERATED_END

    //GENERATED_GET_NAME_START
    let payload = {
      object : this
    };

    Event.Emit(
      Event.GET_OBJECT_COUNT,
      payload
    );

    let count = payload.results[0];

    let name = this.uniqueName;

    if (!name) {
      name = this.type;
    }

    if (count === 0) {
      return `${name}`;
    } else {
      return `${name} ${count + 1}`;
    }
    //GENERATED_GET_NAME_END

    //CUSTOM_GET_NAME_START
    //CUSTOM_GET_NAME_END

    //GENERATED_GET_NAME_AFTER_START
    //GENERATED_GET_NAME_AFTER_END

  }

  /**
   * getCurrentUser()
   * - Gets the current user from the Linking System
   * - No parameters
   * - No returns
   */
  getCurrentUser() {

    //CUSTOM_GET_CURRENT_USER_BEFORE_START
    //CUSTOM_GET_CURRENT_USER_BEFORE_END

    //GENERATED_GET_CURRENT_USER_BEFORE_START
    //GENERATED_GET_CURRENT_USER_BEFORE_END

    //CUSTOM_GET_CURRENT_USER_BEFORE_GENERATED_START
    //CUSTOM_GET_CURRENT_USER_BEFORE_GENERATED_END

    //GENERATED_GET_CURRENT_USER_START
    //GENERATED_GET_CURRENT_USER_END

    //CUSTOM_GET_CURRENT_USER_START
    let payload = {};
    Event.Emit(
      Event.GET_CURRENT_USER,
      payload
    )
    return payload.results[0];
    //CUSTOM_GET_CURRENT_USER_END

    //GENERATED_GET_CURRENT_USER_AFTER_START
    //GENERATED_GET_CURRENT_USER_AFTER_END

  }

  /**
   * getCurrentGroup()
   * - Gets the current group from the Linking System
   * - No parameters
   * - No returns
   */
  getCurrentGroup() {

    //CUSTOM_GET_CURRENT_GROUP_BEFORE_START
    //CUSTOM_GET_CURRENT_GROUP_BEFORE_END

    //GENERATED_GET_CURRENT_GROUP_BEFORE_START
    //GENERATED_GET_CURRENT_GROUP_BEFORE_END

    //CUSTOM_GET_CURRENT_GROUP_BEFORE_GENERATED_START
    //CUSTOM_GET_CURRENT_GROUP_BEFORE_GENERATED_END

    //GENERATED_GET_CURRENT_GROUP_START
    //GENERATED_GET_CURRENT_GROUP_END

    //CUSTOM_GET_CURRENT_GROUP_START
    let payload = {}
    Event.Emit(
      Event.GET_CURRENT_GROUP,
      payload
    );
    return payload.results[0];
    //CUSTOM_GET_CURRENT_GROUP_END

    //GENERATED_GET_CURRENT_GROUP_AFTER_START
    //GENERATED_GET_CURRENT_GROUP_AFTER_END

  }

  /**
   * getCurrentProject()
   * - Gets the current project from the Linking System
   * - No parameters
   * - No returns
   */
  getCurrentProject() {

    //CUSTOM_GET_CURRENT_PROJECT_BEFORE_START
    //CUSTOM_GET_CURRENT_PROJECT_BEFORE_END

    //GENERATED_GET_CURRENT_PROJECT_BEFORE_START
    //GENERATED_GET_CURRENT_PROJECT_BEFORE_END

    //CUSTOM_GET_CURRENT_PROJECT_BEFORE_GENERATED_START
    //CUSTOM_GET_CURRENT_PROJECT_BEFORE_GENERATED_END

    //GENERATED_GET_CURRENT_PROJECT_START
    //GENERATED_GET_CURRENT_PROJECT_END

    //CUSTOM_GET_CURRENT_PROJECT_START
    let payload = {};
    Event.Emit(
      Event.GET_CURRENT_PROJECT,
      payload
    )
    return payload.results[0];
    //CUSTOM_GET_CURRENT_PROJECT_END

    //GENERATED_GET_CURRENT_PROJECT_AFTER_START
    //GENERATED_GET_CURRENT_PROJECT_AFTER_END

  }

  /**
   * toApiObject()
   * - Strips all properties except its data into an object ready to be serialized to storage
   * - No parameters
   * - No returns
   */
  toApiObject() {

    //CUSTOM_TO_API_OBJECT_BEFORE_START
    //CUSTOM_TO_API_OBJECT_BEFORE_END

    //GENERATED_TO_API_OBJECT_BEFORE_START
    //GENERATED_TO_API_OBJECT_BEFORE_END

    //CUSTOM_TO_API_OBJECT_BEFORE_GENERATED_START
    //CUSTOM_TO_API_OBJECT_BEFORE_GENERATED_END

    //GENERATED_TO_API_OBJECT_START
    try {

      let apiObject = {};
      for (let [property, value] of Object.entries(this)) {

        if (
          property === '_cache' ||
          property === 'callDepth' ||
          property === 'children' ||
          property === 'childrenIds' ||
          property === 'connected' ||
          property === 'enableUpdate' ||
          property === 'gui' ||
          property === 'initialized' ||
          property === 'instances' ||
          property === 'isLoading' ||
          property === 'maxDepth' ||
          property === 'mode' ||
          property === 'parents' ||
          property === 'projectIds' ||
          property === 'referenceCount' ||
          property === 'references' ||
          property === 'resolveStateChanged' ||
          property === 'role' ||
          property === 'runtimes' ||
          property === 'state' ||
          property === 'stateChangedPromise' ||
          property === 'subscriptions' ||
          property === 'token' ||
          property === 'uniqueName'
        ) {
          continue;
        }

        if (value instanceof Set || value instanceof Array) {

          let apiObjects = [];

          for (let item of [...value]) {
            if (item instanceof Obj) {
              apiObjects.push(this.parseObjToApiObject(item));
            } else {
              apiObjects.push(item);
            }
          }

          apiObject[property] = [...apiObjects];
          continue;
        }

        if (value instanceof Obj) {
          apiObject[property] = this.parseObjToApiObject(value);
          continue;
        }

        apiObject[property] = this[property];
      }

      apiObject.parents = [...this.parents].reduce(
        (result, parent) => {
          if (
            !parent.type.match('R3EventObjComponentMaths') &&
            parent.mode !== Obj.MODE_EDIT
          ) {

            let {id, type} = parent;

            result.push(
              {
                id,
                type
              }
            );
          }
          
          return result;
        },
        []
      );

      apiObject.children = [...this.children].reduce(
        (result, child) => {

          if (child.mode !== Obj.MODE_EDIT) {

            let {id, type} = child;

            result.push(
              {
                id,
                type
              }
            );

          }

          return result;
        },
        []
      );

      return apiObject;
    } catch (error) {
      console.error(error.message);
      throw error;
    }
    //GENERATED_TO_API_OBJECT_END

    //CUSTOM_TO_API_OBJECT_START
    //CUSTOM_TO_API_OBJECT_END

    //GENERATED_TO_API_OBJECT_AFTER_START
    //GENERATED_TO_API_OBJECT_AFTER_END

  }

  /**
   * parseObjToApiObject()
   * - Parses the value to an API Object
   * @param value
   * - No returns
   */
  parseObjToApiObject(value) {

    //CUSTOM_PARSE_OBJ_TO_API_OBJECT_BEFORE_START
    //CUSTOM_PARSE_OBJ_TO_API_OBJECT_BEFORE_END

    //GENERATED_PARSE_OBJ_TO_API_OBJECT_BEFORE_START
    //GENERATED_PARSE_OBJ_TO_API_OBJECT_BEFORE_END

    //CUSTOM_PARSE_OBJ_TO_API_OBJECT_BEFORE_GENERATED_START
    //CUSTOM_PARSE_OBJ_TO_API_OBJECT_BEFORE_GENERATED_END

    //GENERATED_PARSE_OBJ_TO_API_OBJECT_START
    let {id, type} = value;

    if (type === 'R3EventObjComponentMathsColor') {
      let {r, g, b, a} = value;
      return {
        type,
        r,
        g,
        b,
        a
      }
    }

    if (type === 'R3EventObjComponentMathsVector2') {
      let {x, y} = value;
      return {
        type,
        x,
        y
      }
    }

    if (type === 'R3EventObjComponentMathsVector3') {
      let {x, y, z, spin, min, max} = value;

      let obj = {
        type,
        x,
        y,
        z
      }

      if (spin) {
        obj.spin = true;
        obj.min = min;
        obj.max = max;
      }

      return obj;
    }

    if (type === 'R3EventObjComponentMathsVector4') {
      let {x, y, z, w} = value;
      return {
        type,
        x,
        y,
        z,
        w
      }
    }

    if (type === 'R3EventObjComponentMathsFace') {
      let {v0, v1, v2, materialIndex, uvs} = value;

      uvs = uvs.map(
        (uv) => {
          return uv.vertices.map(
            (vertex) => {
              return this.parseObjToApiObject(vertex);
            }
          );
        }
      );

      return {
        type,
        v0,
        v1,
        v2,
        materialIndex,
        uvs
      }
    }

    if (type === 'R3EventObjComponentMathsVertex') {
      let {position, normal, boneWeights} = value;
      let parsed = null;
      if (normal) {
        parsed = this.parseObjToApiObject(normal);
      }

      return {
        type,
        position : this.parseObjToApiObject(position),
        normal : parsed,
        boneWeights : boneWeights.map((boneWeight) => {return this.parseObjToApiObject(boneWeight)})
      }
    }

    return {
      id,
      type
    };
    //GENERATED_PARSE_OBJ_TO_API_OBJECT_END

    //CUSTOM_PARSE_OBJ_TO_API_OBJECT_START
    //CUSTOM_PARSE_OBJ_TO_API_OBJECT_END

    //GENERATED_PARSE_OBJ_TO_API_OBJECT_AFTER_START
    //GENERATED_PARSE_OBJ_TO_API_OBJECT_AFTER_END

  }

  /**
   * setupStateChanged()
   * - Sets up the state changed promise
   * @param options
   * - No returns
   */
  setupStateChanged(options) {

    //CUSTOM_SETUP_STATE_CHANGED_BEFORE_START
    //CUSTOM_SETUP_STATE_CHANGED_BEFORE_END

    //GENERATED_SETUP_STATE_CHANGED_BEFORE_START
    //GENERATED_SETUP_STATE_CHANGED_BEFORE_END

    //CUSTOM_SETUP_STATE_CHANGED_BEFORE_GENERATED_START
    //CUSTOM_SETUP_STATE_CHANGED_BEFORE_GENERATED_END

    //GENERATED_SETUP_STATE_CHANGED_START
    return new Promise(
      (resolve) => {
        options.resolveStateChanged = resolve;
      }
    );
    //GENERATED_SETUP_STATE_CHANGED_END

    //CUSTOM_SETUP_STATE_CHANGED_START
    //CUSTOM_SETUP_STATE_CHANGED_END

    //GENERATED_SETUP_STATE_CHANGED_AFTER_START
    //GENERATED_SETUP_STATE_CHANGED_AFTER_END

  }

  /**
   * getStateName()
   * - Returns the state name based on the current state
   * - No parameters
   * - No returns
   */
  getStateName() {

    //CUSTOM_GET_STATE_NAME_BEFORE_START
    //CUSTOM_GET_STATE_NAME_BEFORE_END

    //GENERATED_GET_STATE_NAME_BEFORE_START
    //GENERATED_GET_STATE_NAME_BEFORE_END

    //CUSTOM_GET_STATE_NAME_BEFORE_GENERATED_START
    //CUSTOM_GET_STATE_NAME_BEFORE_GENERATED_END

    //GENERATED_GET_STATE_NAME_START
    switch (this.state) {
      case (Obj.STATE_INITIALIZED) :
        return 'INITIALIZED';
      case (Obj.STATE_LOADING) :
        return 'STATE_LOADING';
      case (Obj.STATE_SAVING) :
        return 'STATE_SAVING';
      case (Obj.STATE_UPDATING) :
        return 'STATE_UPDATING';
      case (Obj.STATE_REMOVING) :
        return 'STATE_REMOVING';
      case (Obj.STATE_LOADED) :
        return 'STATE_LOADED';
      case (Obj.STATE_SAVED) :
        return 'STATE_SAVED';
      case (Obj.STATE_UPDATED) :
        return 'STATE_UPDATED';
      case (Obj.STATE_REMOVED) :
        return 'STATE_REMOVED';
      default :
        throw new Error(`Unknown state : ${this.state}`);
    }
    //GENERATED_GET_STATE_NAME_END

    //CUSTOM_GET_STATE_NAME_START
    //CUSTOM_GET_STATE_NAME_END

    //GENERATED_GET_STATE_NAME_AFTER_START
    //GENERATED_GET_STATE_NAME_AFTER_END

  }

  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * disconnect()
   * - Disconnect from the websocket service
   * - No parameters
   * - No returns
   */
  disconnect() {

    //CUSTOM_DISCONNECT_BEFORE_START
    //CUSTOM_DISCONNECT_BEFORE_END

    //GENERATED_DISCONNECT_BEFORE_START
    //GENERATED_DISCONNECT_BEFORE_END

    //CUSTOM_DISCONNECT_BEFORE_GENERATED_START
    //CUSTOM_DISCONNECT_BEFORE_GENERATED_END

    //GENERATED_DISCONNECT_START
    //GENERATED_DISCONNECT_END

    //CUSTOM_DISCONNECT_START
    let payload = {
      object : this
    }

    Event.Emit(
      Event.STOP_WEBSOCKET_SERVICE,
      payload
    );
    //CUSTOM_DISCONNECT_END

    //GENERATED_DISCONNECT_AFTER_START
    //GENERATED_DISCONNECT_AFTER_END

  }

  /**
   * initialize()
   * - Emits initialize event
   * @param initOptions
   * - No returns
   */
  initialize(initOptions) {

    //CUSTOM_INITIALIZE_BEFORE_START
    //CUSTOM_INITIALIZE_BEFORE_END

    //GENERATED_INITIALIZE_BEFORE_START
    //GENERATED_INITIALIZE_BEFORE_END

    //CUSTOM_INITIALIZE_BEFORE_GENERATED_START
    //CUSTOM_INITIALIZE_BEFORE_GENERATED_END

    //GENERATED_INITIALIZE_START
    this.parents = initOptions.parents;
    this.children = initOptions.children;
    this.initialized = initOptions.initialized;
    this.instances = initOptions.instances;
    this.enableUpdate = initOptions.enableUpdate;
    
    delete initOptions.parents;
    delete initOptions.children;
    delete initOptions.initialized;
    delete initOptions.instances;
    delete initOptions.enableUpdate;
    delete initOptions.requirements;

    for (let [key, value] of Object.entries(initOptions.runtimes)) {
      if (value.runtime) {
        this.instances[key] = {};
        this.instances[key].object = null;
        this.instances[key].runtime = value.runtime;
      }
    }

    if (typeof this._cache === 'undefined') {
      this._cache = {};
    }

    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    for (let key of Object.keys(this.references)) {

      let reference = this.references[key];

      this._cache[key] = null;

      Object.defineProperty(
        this,
        key,
        {
          configurable : true,
          enumerable : true,
          set: this.setProperty(reference),
          get : this.getProperty(reference)
        }
      );

    }

    this.id = initOptions.id;
    delete initOptions.id;

    Object.assign(this, initOptions);

    let payload = {
      object : this,
      options : {initOptions}
    }

    Event.Emit(Event.INITIALIZE_OBJ, payload);

    this.initialized = true;
    //GENERATED_INITIALIZE_END

    //CUSTOM_INITIALIZE_START
    //CUSTOM_INITIALIZE_END

    //GENERATED_INITIALIZE_AFTER_START
    //GENERATED_INITIALIZE_AFTER_END

  }

  /**
   * emitInitializeEvent()
   * - Emits an object specific initialize event
   * @param options
   * - No returns
   */
  emitInitializeEvent(options) {

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_END

    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_START
    //GENERATED_EMIT_INITIALIZE_EVENT_BEFORE_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_START
    //CUSTOM_EMIT_INITIALIZE_EVENT_BEFORE_GENERATED_END

    //GENERATED_EMIT_INITIALIZE_EVENT_START
    //GENERATED_EMIT_INITIALIZE_EVENT_END

    //CUSTOM_EMIT_INITIALIZE_EVENT_START
    Event.Emit(
      Event.INITIALIZE_OBJECT,
      {
        object : this,
        options
      }
    );
    //CUSTOM_EMIT_INITIALIZE_EVENT_END

    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_START
    //GENERATED_EMIT_INITIALIZE_EVENT_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createGui()
   * - Creates a gui runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  createGui() {

    //CUSTOM_CREATE_GUI_BEFORE_START
    //CUSTOM_CREATE_GUI_BEFORE_END

    //GENERATED_CREATE_GUI_BEFORE_START
    //GENERATED_CREATE_GUI_BEFORE_END

    //CUSTOM_CREATE_GUI_BEFORE_GENERATED_START
    //CUSTOM_CREATE_GUI_BEFORE_GENERATED_END

    //GENERATED_CREATE_GUI_START
    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.CREATE_GUI_OBJ,
      payload
    );
    //GENERATED_CREATE_GUI_END

    //CUSTOM_CREATE_GUI_START
    //CUSTOM_CREATE_GUI_END

    //GENERATED_CREATE_GUI_AFTER_START
    //GENERATED_CREATE_GUI_AFTER_END

  }

  /**
   * deleteGui()
   * - Deletes a gui runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  deleteGui() {

    //CUSTOM_DELETE_GUI_BEFORE_START
    //CUSTOM_DELETE_GUI_BEFORE_END

    //GENERATED_DELETE_GUI_BEFORE_START
    //GENERATED_DELETE_GUI_BEFORE_END

    //CUSTOM_DELETE_GUI_BEFORE_GENERATED_START
    //CUSTOM_DELETE_GUI_BEFORE_GENERATED_END

    //GENERATED_DELETE_GUI_START
    let payload = {
      object : this
    };
    
    Event.Emit(
      Event.DELETE_GUI_OBJ,
      payload
    );
    //GENERATED_DELETE_GUI_END

    //CUSTOM_DELETE_GUI_START
    //CUSTOM_DELETE_GUI_END

    //GENERATED_DELETE_GUI_AFTER_START
    //GENERATED_DELETE_GUI_AFTER_END

  }

  /**
   * send()
   * - Sends a message to a websocket
   * @param message
   * - No returns
   */
  async send(message) {

    //CUSTOM_SEND_BEFORE_START
    //CUSTOM_SEND_BEFORE_END

    //GENERATED_SEND_BEFORE_START
    //GENERATED_SEND_BEFORE_END

    //CUSTOM_SEND_BEFORE_GENERATED_START
    //CUSTOM_SEND_BEFORE_GENERATED_END

    //GENERATED_SEND_START
    let payload = {
      object : this
    };
    
    payload.options = {};
    payload.options.message = message;

    await Event.Serialize(
      Event.SEND_OBJ,
      payload
    );

    await Event.Serialize(
      Event.SEND_OBJ_AFTER,
      payload
    );
    //GENERATED_SEND_END

    //CUSTOM_SEND_START
    //CUSTOM_SEND_END

    //GENERATED_SEND_AFTER_START
    //GENERATED_SEND_AFTER_END

  }

  /**
   * updateGui()
   * - Updates the gui runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateGui(property, value) {

    //CUSTOM_UPDATE_GUI_BEFORE_START
    //CUSTOM_UPDATE_GUI_BEFORE_END

    //GENERATED_UPDATE_GUI_BEFORE_START
    //GENERATED_UPDATE_GUI_BEFORE_END

    //CUSTOM_UPDATE_GUI_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_GUI_BEFORE_GENERATED_END

    //GENERATED_UPDATE_GUI_START
    let payload = {
      object : this
    };
    
    payload.options = {};
    payload.options.property = property;
    payload.options.value = value;

    Event.Emit(
      Event.UPDATE_GUI_OBJ,
      payload
    );
    //GENERATED_UPDATE_GUI_END

    //CUSTOM_UPDATE_GUI_START
    //CUSTOM_UPDATE_GUI_END

    //GENERATED_UPDATE_GUI_AFTER_START
    //GENERATED_UPDATE_GUI_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Obj.Type = 'R3EventObj';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Obj.MODE_LIVE
 * - Flag indicating this object is currently in live mode
 */
Obj.MODE_LIVE = 1;

/**
 * @param Obj.MODE_EDIT
 * - Flag indicating this object is currently in edit mode
 */
Obj.MODE_EDIT = 2;

/**
 * @param Obj.STATE_INITIALIZED
 * - Flag indicating that this object is initialized
 */
Obj.STATE_INITIALIZED = 0x1;

/**
 * @param Obj.STATE_LOADING
 * - Flag indicating that this object is loading
 */
Obj.STATE_LOADING = 0x2;

/**
 * @param Obj.STATE_SAVING
 * - Flag indicating that this object is saving
 */
Obj.STATE_SAVING = 0x3;

/**
 * @param Obj.STATE_UPDATING
 * - Flag indicating that this object is updating
 */
Obj.STATE_UPDATING = 0x4;

/**
 * @param Obj.STATE_REMOVING
 * - Flag indicating that this object is removing
 */
Obj.STATE_REMOVING = 0x5;

/**
 * @param Obj.STATE_LOADED
 * - Flag indicating that this object has loaded
 */
Obj.STATE_LOADED = 0x6;

/**
 * @param Obj.STATE_SAVED
 * - Flag indicating that this object has saved
 */
Obj.STATE_SAVED = 0x7;

/**
 * @param Obj.STATE_UPDATED
 * - Flag indicating that this object has updated
 */
Obj.STATE_UPDATED = 0x8;

/**
 * @param Obj.STATE_REMOVED
 * - Flag indicating that this object has been removed
 */
Obj.STATE_REMOVED = 0x9;

/**
 * @param Obj.STATE_ERRORED
 * - Flag indicating that this object has errored in some state
 */
Obj.STATE_ERRORED = 0xa;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Obj as default};
