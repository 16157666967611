//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import * as THREE from 'three';
import Utils from '../../Utils.js';
import Event from '../../Event.js';
//CUSTOM_IMPORTS_END

import Audio from '../Audio.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  apiUrl = null - The API URL to load audio files from
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  createInstance(object, _child = null, _parent = null) - Creates an audio runtime instance for this runtime based on the R3 Object 
  deleteInstance(object, _child = null, _parent = null) - Deletes an audio runtime instance for this runtime based on the R3 Object 
  pause(object) - Starts the audio object 
  play(object) - Starts the audio object 
  stop(object) - Stops the audio object 
  updateInstance(object, property, value) - Updates the audio runtime instance of the R3 Object based on the options 
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createInstance(object) - Creates an audio runtime instance for this runtime based on the R3 Object 
  deleteInstance(object) - Deletes an audio runtime instance for this runtime based on the R3 Object 
  pause(object) - Starts the audio object 
  play(object) - Starts the audio object 
  stop(object) - Stops the audio object 
  updateInstance(object, property, value) - Updates the audio runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
  createInstanceAudio(object) - Three implementation for [R3EventObjComponentAudio] 
  deleteInstanceAudio(object) - Three implementation for [R3EventObjComponentAudio] 
  pauseAudio(object) - Three implementation for [R3EventObjComponentAudio] 
  playAudio(object) - Three implementation for [R3EventObjComponentAudio] 
  stopAudio(object) - Three implementation for [R3EventObjComponentAudio] 
  updateInstanceAudio(object, property, value) - Three implementation for [R3EventObjComponentAudio] 
  createInstancePositional(object) - Three implementation for [R3EventObjComponentAudioPositional] 
  deleteInstancePositional(object) - Three implementation for [R3EventObjComponentAudioPositional] 
  pausePositional(object) - Three implementation for [R3EventObjComponentAudioPositional] 
  playPositional(object) - Three implementation for [R3EventObjComponentAudioPositional] 
  stopPositional(object) - Three implementation for [R3EventObjComponentAudioPositional] 
  updateInstancePositional(object, property, value) - Three implementation for [R3EventObjComponentAudioPositional] 
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Three extends Audio {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeAudio';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeAudioThree';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'ThreeAudio';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_AUDIO;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param apiUrl
     * - The API URL to load audio files from
     */
    if (typeof options.apiUrl === 'undefined') {
      options.apiUrl = null;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    Event.On(
      Event.SET_API_URL,
      (event) => {
        this.apiUrl = event.object;
        console.log(`Audio URL set to ${this.apiUrl}`);
      },
      {
        priority : 40
      }
    );
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createInstance()
   * - Creates an audio runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  createInstance(object) {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_CREATE_INSTANCE_START
    super.createInstance(object);
    //CUSTOM_RETURN_GENERATED_CREATE_INSTANCE_END
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentAudio'
   || object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_AUDIO_START
      return this.createInstanceAudio(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_AUDIO_END
    }

    if (
      object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_POSITIONAL_START
      return this.createInstancePositional(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_POSITIONAL_END
    }
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in createInstance() in runtime R3RuntimeAudioThree`);
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes an audio runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  deleteInstance(object) {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_DELETE_INSTANCE_START
    super.deleteInstance(object);
    //CUSTOM_RETURN_GENERATED_DELETE_INSTANCE_END
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentAudio'
   || object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_AUDIO_START
      return this.deleteInstanceAudio(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_AUDIO_END
    }

    if (
      object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_POSITIONAL_START
      return this.deleteInstancePositional(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_POSITIONAL_END
    }
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in deleteInstance() in runtime R3RuntimeAudioThree`);
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * pause()
   * - Starts the audio object
   * @param object
   * - No returns
   */
  pause(object) {

    //CUSTOM_PAUSE_BEFORE_START
    //CUSTOM_PAUSE_BEFORE_END

    //GENERATED_PAUSE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_PAUSE_START
    super.pause(object);
    //CUSTOM_RETURN_GENERATED_PAUSE_END
    //GENERATED_PAUSE_BEFORE_END

    //CUSTOM_PAUSE_BEFORE_GENERATED_START
    //CUSTOM_PAUSE_BEFORE_GENERATED_END

    //GENERATED_PAUSE_START
    if (
      object.type === 'R3EventObjComponentAudio'
   || object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_PAUSE_AUDIO_START
      return this.pauseAudio(object);
      //CUSTOM_RETURN_OPTION_PAUSE_AUDIO_END
    }

    if (
      object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_PAUSE_POSITIONAL_START
      return this.pausePositional(object);
      //CUSTOM_RETURN_OPTION_PAUSE_POSITIONAL_END
    }
    //GENERATED_PAUSE_END

    //CUSTOM_PAUSE_START
    //CUSTOM_PAUSE_END

    //GENERATED_PAUSE_AFTER_START
    throw new Error(`No suitable method found in pause() in runtime R3RuntimeAudioThree`);
    //GENERATED_PAUSE_AFTER_END

  }

  /**
   * play()
   * - Starts the audio object
   * @param object
   * - No returns
   */
  play(object) {

    //CUSTOM_PLAY_BEFORE_START
    //CUSTOM_PLAY_BEFORE_END

    //GENERATED_PLAY_BEFORE_START
    //CUSTOM_RETURN_GENERATED_PLAY_START
    super.play(object);
    //CUSTOM_RETURN_GENERATED_PLAY_END
    //GENERATED_PLAY_BEFORE_END

    //CUSTOM_PLAY_BEFORE_GENERATED_START
    //CUSTOM_PLAY_BEFORE_GENERATED_END

    //GENERATED_PLAY_START
    if (
      object.type === 'R3EventObjComponentAudio'
   || object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_PLAY_AUDIO_START
      return this.playAudio(object);
      //CUSTOM_RETURN_OPTION_PLAY_AUDIO_END
    }

    if (
      object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_PLAY_POSITIONAL_START
      return this.playPositional(object);
      //CUSTOM_RETURN_OPTION_PLAY_POSITIONAL_END
    }
    //GENERATED_PLAY_END

    //CUSTOM_PLAY_START
    //CUSTOM_PLAY_END

    //GENERATED_PLAY_AFTER_START
    throw new Error(`No suitable method found in play() in runtime R3RuntimeAudioThree`);
    //GENERATED_PLAY_AFTER_END

  }

  /**
   * stop()
   * - Stops the audio object
   * @param object
   * - No returns
   */
  stop(object) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //CUSTOM_RETURN_GENERATED_STOP_START
    super.stop(object);
    //CUSTOM_RETURN_GENERATED_STOP_END
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (
      object.type === 'R3EventObjComponentAudio'
   || object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_STOP_AUDIO_START
      return this.stopAudio(object);
      //CUSTOM_RETURN_OPTION_STOP_AUDIO_END
    }

    if (
      object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_STOP_POSITIONAL_START
      return this.stopPositional(object);
      //CUSTOM_RETURN_OPTION_STOP_POSITIONAL_END
    }
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    throw new Error(`No suitable method found in stop() in runtime R3RuntimeAudioThree`);
    //GENERATED_STOP_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the audio runtime instance of the R3 Object based on the options
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(object, property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_UPDATE_INSTANCE_START
    let updated = super.updateInstance(object, property, value);
    if (updated) {
      return;
    }
    //CUSTOM_RETURN_GENERATED_UPDATE_INSTANCE_END
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentAudio'
   || object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_AUDIO_START
      return this.updateInstanceAudio(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_AUDIO_END
    }

    if (
      object.type === 'R3EventObjComponentAudioPositional'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_POSITIONAL_START
      return this.updateInstancePositional(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_POSITIONAL_END
    }
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in updateInstance() in runtime R3RuntimeAudioThree`);
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  /**
   * createInstanceAudio()
   * - Three implementation for [R3EventObjComponentAudio]
   * @param object
   * - No returns
   */
  createInstanceAudio(object) {

    //GENERATED_CREATE_INSTANCE_AUDIO_BEFORE_START
    //GENERATED_CREATE_INSTANCE_AUDIO_BEFORE_END

    //GENERATED_CREATE_INSTANCE_AUDIO_START
    //GENERATED_CREATE_INSTANCE_AUDIO_END

    //CUSTOM_CREATE_INSTANCE_AUDIO_START
    const listener = new THREE.AudioListener();

    if (!object.camera) {
      Utils.Status(-1, 'Camera disappeared');
      return;
    }

    if (!object.urlPath) {
      Utils.Status(-1, 'URL disappeared');
      return;
    }

    if (!this.apiUrl) {
      Utils.Status(-1, 'Audio URL not set');
      return;
    }

    let camera = object.camera.getInstance(Runtime.KEY_GRAPHICS_THREE);

    camera.add( listener );

    // create a global audio source
    const sound = new THREE.Audio( listener );

    let url = `${this.apiUrl}${object.urlPath}`

    // load a sound and set it as the Audio object's buffer
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load( url, function( buffer ) {
      sound.setBuffer( buffer );
      sound.setLoop( object.loop );
      sound.setVolume( object.volume );
      sound.setPlaybackRate( object.playbackRate );
      sound.onEnded(
        () => {
          object.isPlaying = false;
        }
      )
      if (object.autoplay) {
        object.play();
      }
    });

    return sound;
    //CUSTOM_CREATE_INSTANCE_AUDIO_END

    //GENERATED_CREATE_INSTANCE_AUDIO_AFTER_START
    //GENERATED_CREATE_INSTANCE_AUDIO_AFTER_END

  }

  /**
   * deleteInstanceAudio()
   * - Three implementation for [R3EventObjComponentAudio]
   * @param object
   * - No returns
   */
  deleteInstanceAudio(object) {

    //GENERATED_DELETE_INSTANCE_AUDIO_BEFORE_START
    //GENERATED_DELETE_INSTANCE_AUDIO_BEFORE_END

    //GENERATED_DELETE_INSTANCE_AUDIO_START
    //GENERATED_DELETE_INSTANCE_AUDIO_END

    //CUSTOM_DELETE_INSTANCE_AUDIO_START
    let instance = object.getInstance(this);
    if (!instance) {
      console.warn(`The audio instance went away`);
      return null;
    }
    instance.stop();

    if (object.camera) {
      let cameraInstance = object.camera.getInstance(Runtime.KEY_GRAPHICS_THREE);
      if (!cameraInstance) {
        console.warn(`The camera of audio sample ${object.name} went away`);
        return null;
      }
      cameraInstance.remove(instance);
    }

    return null;
    //CUSTOM_DELETE_INSTANCE_AUDIO_END

    //GENERATED_DELETE_INSTANCE_AUDIO_AFTER_START
    //GENERATED_DELETE_INSTANCE_AUDIO_AFTER_END

  }

  /**
   * pauseAudio()
   * - Three implementation for [R3EventObjComponentAudio]
   * @param object
   * - No returns
   */
  pauseAudio(object) {

    //GENERATED_PAUSE_AUDIO_BEFORE_START
    //GENERATED_PAUSE_AUDIO_BEFORE_END

    //GENERATED_PAUSE_AUDIO_START
    //GENERATED_PAUSE_AUDIO_END

    //CUSTOM_PAUSE_AUDIO_START
    let instance = object.getInstance(this);
    instance.pause();
    object.isPlaying = false;
    //CUSTOM_PAUSE_AUDIO_END

    //GENERATED_PAUSE_AUDIO_AFTER_START
    //GENERATED_PAUSE_AUDIO_AFTER_END

  }

  /**
   * playAudio()
   * - Three implementation for [R3EventObjComponentAudio]
   * @param object
   * - No returns
   */
  playAudio(object) {

    //GENERATED_PLAY_AUDIO_BEFORE_START
    //GENERATED_PLAY_AUDIO_BEFORE_END

    //GENERATED_PLAY_AUDIO_START
    //GENERATED_PLAY_AUDIO_END

    //CUSTOM_PLAY_AUDIO_START
    let instance = object.getInstance(this);
    instance.play();
    object.isPlaying = true;
    instance.setDetune(object.detune);
    //CUSTOM_PLAY_AUDIO_END

    //GENERATED_PLAY_AUDIO_AFTER_START
    //GENERATED_PLAY_AUDIO_AFTER_END

  }

  /**
   * stopAudio()
   * - Three implementation for [R3EventObjComponentAudio]
   * @param object
   * - No returns
   */
  stopAudio(object) {

    //GENERATED_STOP_AUDIO_BEFORE_START
    //GENERATED_STOP_AUDIO_BEFORE_END

    //GENERATED_STOP_AUDIO_START
    //GENERATED_STOP_AUDIO_END

    //CUSTOM_STOP_AUDIO_START
    let instance = object.getInstance(this);
    instance.stop();
    object.isPlaying = false;
    //CUSTOM_STOP_AUDIO_END

    //GENERATED_STOP_AUDIO_AFTER_START
    //GENERATED_STOP_AUDIO_AFTER_END

  }

  /**
   * updateInstanceAudio()
   * - Three implementation for [R3EventObjComponentAudio]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceAudio(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_AUDIO_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_AUDIO_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_AUDIO_START
    //GENERATED_UPDATE_INSTANCE_AUDIO_END

    //CUSTOM_UPDATE_INSTANCE_AUDIO_START
    let instance = object.getInstance(this);

    if (property === 'loop') {
      instance.setLoop(value);
      return;
    }

    if (property === 'volume') {
      instance.setVolume(value);
      return;
    }

    if (property === 'detune') {
      if (object.isPlaying) {
        instance.setDetune(value);
      }
      return;
    }

    if (property === 'playbackRate') {
      instance.setPlaybackRate(value);
      return;
    }

    if (property === 'urlPath') {
      this.deleteInstance(object);
      object.setInstance(this, this.createInstance(object));
      return;
    }

    if (property === 'overplay') {
      console.warn(`Implement overplay change for audio`);
      return;
    }

    throw new Error(`Please implement Three.updateInstanceAudio() in R3RuntimeAudioThree for property ${property}`);
    //CUSTOM_UPDATE_INSTANCE_AUDIO_END

    //GENERATED_UPDATE_INSTANCE_AUDIO_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AUDIO_AFTER_END

  }

  /**
   * createInstancePositional()
   * - Three implementation for [R3EventObjComponentAudioPositional]
   * @param object
   * - No returns
   */
  createInstancePositional(object) {

    //GENERATED_CREATE_INSTANCE_POSITIONAL_BEFORE_START
    //GENERATED_CREATE_INSTANCE_POSITIONAL_BEFORE_END

    //GENERATED_CREATE_INSTANCE_POSITIONAL_START
    //GENERATED_CREATE_INSTANCE_POSITIONAL_END

    //CUSTOM_CREATE_INSTANCE_POSITIONAL_START
    throw new Error(`Please implement Three.createInstancePositional(object) in R3RuntimeAudioThree`);
    //CUSTOM_CREATE_INSTANCE_POSITIONAL_END

    //GENERATED_CREATE_INSTANCE_POSITIONAL_AFTER_START
    //GENERATED_CREATE_INSTANCE_POSITIONAL_AFTER_END

  }

  /**
   * deleteInstancePositional()
   * - Three implementation for [R3EventObjComponentAudioPositional]
   * @param object
   * - No returns
   */
  deleteInstancePositional(object) {

    //GENERATED_DELETE_INSTANCE_POSITIONAL_BEFORE_START
    //GENERATED_DELETE_INSTANCE_POSITIONAL_BEFORE_END

    //GENERATED_DELETE_INSTANCE_POSITIONAL_START
    //GENERATED_DELETE_INSTANCE_POSITIONAL_END

    //CUSTOM_DELETE_INSTANCE_POSITIONAL_START
    throw new Error(`Please implement Three.deleteInstancePositional(object) in R3RuntimeAudioThree`);
    //CUSTOM_DELETE_INSTANCE_POSITIONAL_END

    //GENERATED_DELETE_INSTANCE_POSITIONAL_AFTER_START
    //GENERATED_DELETE_INSTANCE_POSITIONAL_AFTER_END

  }

  /**
   * pausePositional()
   * - Three implementation for [R3EventObjComponentAudioPositional]
   * @param object
   * - No returns
   */
  pausePositional(object) {

    //GENERATED_PAUSE_POSITIONAL_BEFORE_START
    //GENERATED_PAUSE_POSITIONAL_BEFORE_END

    //GENERATED_PAUSE_POSITIONAL_START
    //GENERATED_PAUSE_POSITIONAL_END

    //CUSTOM_PAUSE_POSITIONAL_START
    throw new Error(`Please implement Three.pausePositional(object) in R3RuntimeAudioThree`);
    //CUSTOM_PAUSE_POSITIONAL_END

    //GENERATED_PAUSE_POSITIONAL_AFTER_START
    //GENERATED_PAUSE_POSITIONAL_AFTER_END

  }

  /**
   * playPositional()
   * - Three implementation for [R3EventObjComponentAudioPositional]
   * @param object
   * - No returns
   */
  playPositional(object) {

    //GENERATED_PLAY_POSITIONAL_BEFORE_START
    //GENERATED_PLAY_POSITIONAL_BEFORE_END

    //GENERATED_PLAY_POSITIONAL_START
    //GENERATED_PLAY_POSITIONAL_END

    //CUSTOM_PLAY_POSITIONAL_START
    throw new Error(`Please implement Three.playPositional(object) in R3RuntimeAudioThree`);
    //CUSTOM_PLAY_POSITIONAL_END

    //GENERATED_PLAY_POSITIONAL_AFTER_START
    //GENERATED_PLAY_POSITIONAL_AFTER_END

  }

  /**
   * stopPositional()
   * - Three implementation for [R3EventObjComponentAudioPositional]
   * @param object
   * - No returns
   */
  stopPositional(object) {

    //GENERATED_STOP_POSITIONAL_BEFORE_START
    //GENERATED_STOP_POSITIONAL_BEFORE_END

    //GENERATED_STOP_POSITIONAL_START
    //GENERATED_STOP_POSITIONAL_END

    //CUSTOM_STOP_POSITIONAL_START
    throw new Error(`Please implement Three.stopPositional(object) in R3RuntimeAudioThree`);
    //CUSTOM_STOP_POSITIONAL_END

    //GENERATED_STOP_POSITIONAL_AFTER_START
    //GENERATED_STOP_POSITIONAL_AFTER_END

  }

  /**
   * updateInstancePositional()
   * - Three implementation for [R3EventObjComponentAudioPositional]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstancePositional(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_POSITIONAL_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_POSITIONAL_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_POSITIONAL_START
    //GENERATED_UPDATE_INSTANCE_POSITIONAL_END

    //CUSTOM_UPDATE_INSTANCE_POSITIONAL_START
    throw new Error(`Please implement Three.updateInstancePositional(object, property, value) in R3RuntimeAudioThree`);
    //CUSTOM_UPDATE_INSTANCE_POSITIONAL_END

    //GENERATED_UPDATE_INSTANCE_POSITIONAL_AFTER_START
    //GENERATED_UPDATE_INSTANCE_POSITIONAL_AFTER_END

  }

  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Three.Type = 'R3RuntimeAudioThree';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Three as default};
