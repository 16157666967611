//GENERATED_IMPORTS_START

//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START
import {default as AxiosRuntime} from '../runtime/storage/Axios.js';
//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
import {default as MathsComponent} from '../event/obj/component/Maths.js';
import Vertex from '../event/obj/component/maths/Vertex.js';
import Face from '../event/obj/component/maths/Face.js';
import Vector3 from '../event/obj/component/maths/Vector3.js';
import Vector2 from '../event/obj/component/maths/Vector2.js';
import UV from '../event/obj/component/maths/UV.js';
import Utils from "../Utils.js";
import Project from "../event/obj/Project.js";
import Group from "../event/obj/Group.js";
import Obj from '../event/Obj.js';
import Component from '../event/obj/Component.js';
import Entity from '../event/obj/Entity.js';
import User from '../event/obj/User.js';
import Runtime from '../Runtime.js';
import Gl from '../event/obj/component/graphics/renderer/Gl.js';
import Scene from '../event/obj/component/graphics/Scene.js';
import Perspective from '../event/obj/component/graphics/camera/Perspective.js';
import Ambient from '../event/obj/component/graphics/light/Ambient.js';
import Canvas from '../event/obj/component/graphics/Canvas.js';
import Image from '../event/obj/component/graphics/Image.js';
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START
  RuntimeStorageAxios = new AxiosRuntime() - Runtime implementation of R3RuntimeStorage
 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Groups={}
  Users={}
  Projects={}
  Components={}
  Entities={}
  Files={}
  CurrentUser=null
  CurrentGroup=null
  ApiUrl=null
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.GET_STORAGE_OBJECT_BY_ID(20)
  Event.SET_CURRENT_USER(20)
  Event.SET_CURRENT_GROUP(20)
  Event.SET_API_URL(20)
  Event.GET_API_URL(20)
  Event.DISPOSE_GROUP(20)
  Event.INITIALIZE_GROUP(20)
  Event.DISPOSE_FILE(20)
  Event.INITIALIZE_FILE(20)
  Event.DISPOSE_ENTITY(20)
  Event.INITIALIZE_ENTITY(20)
  Event.DISPOSE_COMPONENT(90)
  Event.INITIALIZE_COMPONENT(20)
  Event.DISPOSE_PROJECT(20)
  Event.INITIALIZE_PROJECT(20)
  Event.DISPOSE_USER(20)
  Event.INITIALIZE_USER(20)
  async Event.GET_USERS_LIST(10)
  async Event.GET_SOURCE_URLS(10)
  async Event.GET_TARGET_URLS(10)
  Event.SET_SOURCE_API_URL(20)
  Event.GET_SOURCE_API_URL(20)
  Event.SET_SOURCE_WEBSOCKET_URL(20)
  Event.GET_SOURCE_WEBSOCKET_URL(20)
  Event.SET_TARGET_API_URL(20)
  Event.GET_TARGET_API_URL(20)
  Event.SET_TARGET_WEBSOCKET_URL(20)
  Event.GET_TARGET_WEBSOCKET_URL(20)
  async Event.DEPLOY_PROJECT(20)
  async Event.GET_LIST(20)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START
  async Event.CLONE_USER(20) [R3EventObjUser]
  async Event.LOAD_USER(20) [R3EventObjUser]
  async Event.REMOVE_USER(20) [R3EventObjUser]
  async Event.SAVE_USER(20) [R3EventObjUser]
  async Event.CLONE_PROJECT(20) [R3EventObjProject]
  async Event.LOAD_PROJECT(20) [R3EventObjProject]
  async Event.REMOVE_PROJECT(20) [R3EventObjProject]
  async Event.SAVE_PROJECT(20) [R3EventObjProject]
  async Event.CLONE_GROUP(20) [R3EventObjGroup]
  async Event.LOAD_GROUP(20) [R3EventObjGroup]
  async Event.REMOVE_GROUP(20) [R3EventObjGroup]
  async Event.SAVE_GROUP(20) [R3EventObjGroup]
  async Event.CLONE_FILE(20) [R3EventObjFile]
  async Event.LOAD_FILE(20) [R3EventObjFile]
  async Event.REMOVE_FILE(20) [R3EventObjFile]
  async Event.SAVE_FILE(20) [R3EventObjFile]
  async Event.CLONE_ENTITY(20) [R3EventObjEntity]
  async Event.LOAD_ENTITY(20) [R3EventObjEntity]
  async Event.REMOVE_ENTITY(20) [R3EventObjEntity]
  async Event.SAVE_ENTITY(20) [R3EventObjEntity]
  async Event.CLONE_COMPONENT(20) [R3EventObjComponent]
  async Event.LOAD_COMPONENT(20) [R3EventObjComponent]
  async Event.REMOVE_COMPONENT(20) [R3EventObjComponent]
  async Event.SAVE_COMPONENT(20) [R3EventObjComponent]
 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  Event.GET_RUNTIME_STORAGE_AXIOS - Gets the Axios runtime implementation of R3RuntimeStorage
 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options = {}) - Starts the system by registering subscriptions to events
  stop(options = {}) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  ParseReference(Constructor, value) - Calls Constructor on value
  GetOrConstruct(Constructor, value) - Gets the object from cache or constructs it right away
  GetObjectById(id) - Gets the object directly from in-memory cache
  async ProcessGenericReferences(object, instance) - Defines how object references are processed. Instance holds the updated information
  async ProcessComponentReferences(component, instance) - Defines how component references are processed. Instance holds the updated information
  async ProcessComponentChildren(component, instance) - Defines how component children are processed. Instance holds the updated information
  async ProcessEntityReferences(entity, instance) - Defines how entity references are processed. Instance holds the updated information
  async ProcessProjectChildren(project, instance) - Loads all project children. Instance holds the updated information
  async ProcessUserReferences(user, instance) - Defines how user references are processed. Instance holds the updated information
  async ProcessBlenderChildren(instance) - Processes the blender children
 CUSTOM_STATIC_METHODS_END

 **/

export class Storage extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Storage already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Storage');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Storage already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Storage');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Storage.IsStarting) {
      console.log('client Storage system is already starting...');
      return;
    }

    Storage.IsStarting = true;

    if (Storage.Started === true) {
      Storage.IsStarting = false;
      console.log('client Storage system already started');
      return;
    }

    Storage.Runtimes = new Set();
    Storage.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Storage.Subscriptions['GET_STORAGE_OBJECT_BY_ID'] = Event.On(
      Event.GET_STORAGE_OBJECT_BY_ID,
      Storage.GetStorageObjectById,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SET_CURRENT_USER'] = Event.On(
      Event.SET_CURRENT_USER,
      Storage.SetCurrentUser,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SET_CURRENT_GROUP'] = Event.On(
      Event.SET_CURRENT_GROUP,
      Storage.SetCurrentGroup,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SET_API_URL'] = Event.On(
      Event.SET_API_URL,
      Storage.SetApiUrl,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['GET_API_URL'] = Event.On(
      Event.GET_API_URL,
      Storage.GetApiUrl,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['DISPOSE_GROUP'] = Event.On(
      Event.DISPOSE_GROUP,
      Storage.DisposeGroup,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['INITIALIZE_GROUP'] = Event.On(
      Event.INITIALIZE_GROUP,
      Storage.InitializeGroup,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['DISPOSE_FILE'] = Event.On(
      Event.DISPOSE_FILE,
      Storage.DisposeFile,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['INITIALIZE_FILE'] = Event.On(
      Event.INITIALIZE_FILE,
      Storage.InitializeFile,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['DISPOSE_ENTITY'] = Event.On(
      Event.DISPOSE_ENTITY,
      Storage.DisposeEntity,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['INITIALIZE_ENTITY'] = Event.On(
      Event.INITIALIZE_ENTITY,
      Storage.InitializeEntity,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['DISPOSE_COMPONENT'] = Event.On(
      Event.DISPOSE_COMPONENT,
      Storage.DisposeComponent,
      {priority: 90}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['INITIALIZE_COMPONENT'] = Event.On(
      Event.INITIALIZE_COMPONENT,
      Storage.InitializeComponent,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['DISPOSE_PROJECT'] = Event.On(
      Event.DISPOSE_PROJECT,
      Storage.DisposeProject,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['INITIALIZE_PROJECT'] = Event.On(
      Event.INITIALIZE_PROJECT,
      Storage.InitializeProject,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['DISPOSE_USER'] = Event.On(
      Event.DISPOSE_USER,
      Storage.DisposeUser,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['INITIALIZE_USER'] = Event.On(
      Event.INITIALIZE_USER,
      Storage.InitializeUser,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['GET_USERS_LIST'] = Event.On(
      Event.GET_USERS_LIST,
      Storage.GetUsersList,
      {priority: 10}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['GET_SOURCE_URLS'] = Event.On(
      Event.GET_SOURCE_URLS,
      Storage.GetSourceUrls,
      {priority: 10}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['GET_TARGET_URLS'] = Event.On(
      Event.GET_TARGET_URLS,
      Storage.GetTargetUrls,
      {priority: 10}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SET_SOURCE_API_URL'] = Event.On(
      Event.SET_SOURCE_API_URL,
      Storage.SetSourceApiUrl,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['GET_SOURCE_API_URL'] = Event.On(
      Event.GET_SOURCE_API_URL,
      Storage.GetSourceApiUrl,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SET_SOURCE_WEBSOCKET_URL'] = Event.On(
      Event.SET_SOURCE_WEBSOCKET_URL,
      Storage.SetSourceWebsocketUrl,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['GET_SOURCE_WEBSOCKET_URL'] = Event.On(
      Event.GET_SOURCE_WEBSOCKET_URL,
      Storage.GetSourceWebsocketUrl,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SET_TARGET_API_URL'] = Event.On(
      Event.SET_TARGET_API_URL,
      Storage.SetTargetApiUrl,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['GET_TARGET_API_URL'] = Event.On(
      Event.GET_TARGET_API_URL,
      Storage.GetTargetApiUrl,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SET_TARGET_WEBSOCKET_URL'] = Event.On(
      Event.SET_TARGET_WEBSOCKET_URL,
      Storage.SetTargetWebsocketUrl,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['GET_TARGET_WEBSOCKET_URL'] = Event.On(
      Event.GET_TARGET_WEBSOCKET_URL,
      Storage.GetTargetWebsocketUrl,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['DEPLOY_PROJECT'] = Event.On(
      Event.DEPLOY_PROJECT,
      Storage.DeployProject,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['GET_LIST'] = Event.On(
      Event.GET_LIST,
      Storage.GetList,
      {priority: 20}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Storage.Subscriptions['CLONE_USER'] = Event.On(
      Event.CLONE_USER,
      Storage.CloneUser,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['LOAD_USER'] = Event.On(
      Event.LOAD_USER,
      Storage.LoadUser,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['REMOVE_USER'] = Event.On(
      Event.REMOVE_USER,
      Storage.RemoveUser,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SAVE_USER'] = Event.On(
      Event.SAVE_USER,
      Storage.SaveUser,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['CLONE_PROJECT'] = Event.On(
      Event.CLONE_PROJECT,
      Storage.CloneProject,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['LOAD_PROJECT'] = Event.On(
      Event.LOAD_PROJECT,
      Storage.LoadProject,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['REMOVE_PROJECT'] = Event.On(
      Event.REMOVE_PROJECT,
      Storage.RemoveProject,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SAVE_PROJECT'] = Event.On(
      Event.SAVE_PROJECT,
      Storage.SaveProject,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['CLONE_GROUP'] = Event.On(
      Event.CLONE_GROUP,
      Storage.CloneGroup,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['LOAD_GROUP'] = Event.On(
      Event.LOAD_GROUP,
      Storage.LoadGroup,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['REMOVE_GROUP'] = Event.On(
      Event.REMOVE_GROUP,
      Storage.RemoveGroup,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SAVE_GROUP'] = Event.On(
      Event.SAVE_GROUP,
      Storage.SaveGroup,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['CLONE_FILE'] = Event.On(
      Event.CLONE_FILE,
      Storage.CloneFile,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['LOAD_FILE'] = Event.On(
      Event.LOAD_FILE,
      Storage.LoadFile,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['REMOVE_FILE'] = Event.On(
      Event.REMOVE_FILE,
      Storage.RemoveFile,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SAVE_FILE'] = Event.On(
      Event.SAVE_FILE,
      Storage.SaveFile,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['CLONE_ENTITY'] = Event.On(
      Event.CLONE_ENTITY,
      Storage.CloneEntity,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['LOAD_ENTITY'] = Event.On(
      Event.LOAD_ENTITY,
      Storage.LoadEntity,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['REMOVE_ENTITY'] = Event.On(
      Event.REMOVE_ENTITY,
      Storage.RemoveEntity,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SAVE_ENTITY'] = Event.On(
      Event.SAVE_ENTITY,
      Storage.SaveEntity,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['CLONE_COMPONENT'] = Event.On(
      Event.CLONE_COMPONENT,
      Storage.CloneComponent,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['LOAD_COMPONENT'] = Event.On(
      Event.LOAD_COMPONENT,
      Storage.LoadComponent,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['REMOVE_COMPONENT'] = Event.On(
      Event.REMOVE_COMPONENT,
      Storage.RemoveComponent,
      {priority: 20}
    );

    /**
     * No comment
     */
    Storage.Subscriptions['SAVE_COMPONENT'] = Event.On(
      Event.SAVE_COMPONENT,
      Storage.SaveComponent,
      {priority: 20}
    );
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    /**
     * Gets the Axios runtime implementation of R3RuntimeStorage
     */
    Storage.Subscriptions['GET_RUNTIME_STORAGE_AXIOS'] = Event.On(
      Event.GET_RUNTIME_STORAGE_AXIOS,
      Storage.GetRuntimeStorageAxios
    );
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    Storage.Started = true;
    Storage.IsStarting = false;
    console.log('Started client system Storage');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Storage.IsStopping) {
      console.log('client Storage system is already stopping');
      return;
    }

    Storage.IsStopping = true;

    if (Storage.Started === false) {
      Storage.IsStopping = false;
      console.log('client Storage system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    Storage.Subscriptions['GET_STORAGE_OBJECT_BY_ID'].remove();
    delete Storage.Subscriptions['GET_STORAGE_OBJECT_BY_ID'];

    Storage.Subscriptions['SET_CURRENT_USER'].remove();
    delete Storage.Subscriptions['SET_CURRENT_USER'];

    Storage.Subscriptions['SET_CURRENT_GROUP'].remove();
    delete Storage.Subscriptions['SET_CURRENT_GROUP'];

    Storage.Subscriptions['SET_API_URL'].remove();
    delete Storage.Subscriptions['SET_API_URL'];

    Storage.Subscriptions['GET_API_URL'].remove();
    delete Storage.Subscriptions['GET_API_URL'];

    Storage.Subscriptions['DISPOSE_GROUP'].remove();
    delete Storage.Subscriptions['DISPOSE_GROUP'];

    Storage.Subscriptions['INITIALIZE_GROUP'].remove();
    delete Storage.Subscriptions['INITIALIZE_GROUP'];

    Storage.Subscriptions['DISPOSE_FILE'].remove();
    delete Storage.Subscriptions['DISPOSE_FILE'];

    Storage.Subscriptions['INITIALIZE_FILE'].remove();
    delete Storage.Subscriptions['INITIALIZE_FILE'];

    Storage.Subscriptions['DISPOSE_ENTITY'].remove();
    delete Storage.Subscriptions['DISPOSE_ENTITY'];

    Storage.Subscriptions['INITIALIZE_ENTITY'].remove();
    delete Storage.Subscriptions['INITIALIZE_ENTITY'];

    Storage.Subscriptions['DISPOSE_COMPONENT'].remove();
    delete Storage.Subscriptions['DISPOSE_COMPONENT'];

    Storage.Subscriptions['INITIALIZE_COMPONENT'].remove();
    delete Storage.Subscriptions['INITIALIZE_COMPONENT'];

    Storage.Subscriptions['DISPOSE_PROJECT'].remove();
    delete Storage.Subscriptions['DISPOSE_PROJECT'];

    Storage.Subscriptions['INITIALIZE_PROJECT'].remove();
    delete Storage.Subscriptions['INITIALIZE_PROJECT'];

    Storage.Subscriptions['DISPOSE_USER'].remove();
    delete Storage.Subscriptions['DISPOSE_USER'];

    Storage.Subscriptions['INITIALIZE_USER'].remove();
    delete Storage.Subscriptions['INITIALIZE_USER'];

    Storage.Subscriptions['GET_USERS_LIST'].remove();
    delete Storage.Subscriptions['GET_USERS_LIST'];

    Storage.Subscriptions['GET_SOURCE_URLS'].remove();
    delete Storage.Subscriptions['GET_SOURCE_URLS'];

    Storage.Subscriptions['GET_TARGET_URLS'].remove();
    delete Storage.Subscriptions['GET_TARGET_URLS'];

    Storage.Subscriptions['SET_SOURCE_API_URL'].remove();
    delete Storage.Subscriptions['SET_SOURCE_API_URL'];

    Storage.Subscriptions['GET_SOURCE_API_URL'].remove();
    delete Storage.Subscriptions['GET_SOURCE_API_URL'];

    Storage.Subscriptions['SET_SOURCE_WEBSOCKET_URL'].remove();
    delete Storage.Subscriptions['SET_SOURCE_WEBSOCKET_URL'];

    Storage.Subscriptions['GET_SOURCE_WEBSOCKET_URL'].remove();
    delete Storage.Subscriptions['GET_SOURCE_WEBSOCKET_URL'];

    Storage.Subscriptions['SET_TARGET_API_URL'].remove();
    delete Storage.Subscriptions['SET_TARGET_API_URL'];

    Storage.Subscriptions['GET_TARGET_API_URL'].remove();
    delete Storage.Subscriptions['GET_TARGET_API_URL'];

    Storage.Subscriptions['SET_TARGET_WEBSOCKET_URL'].remove();
    delete Storage.Subscriptions['SET_TARGET_WEBSOCKET_URL'];

    Storage.Subscriptions['GET_TARGET_WEBSOCKET_URL'].remove();
    delete Storage.Subscriptions['GET_TARGET_WEBSOCKET_URL'];

    Storage.Subscriptions['DEPLOY_PROJECT'].remove();
    delete Storage.Subscriptions['DEPLOY_PROJECT'];

    Storage.Subscriptions['GET_LIST'].remove();
    delete Storage.Subscriptions['GET_LIST'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    Storage.Subscriptions['CLONE_USER'].remove();
    delete Storage.Subscriptions['CLONE_USER'];

    Storage.Subscriptions['LOAD_USER'].remove();
    delete Storage.Subscriptions['LOAD_USER'];

    Storage.Subscriptions['REMOVE_USER'].remove();
    delete Storage.Subscriptions['REMOVE_USER'];

    Storage.Subscriptions['SAVE_USER'].remove();
    delete Storage.Subscriptions['SAVE_USER'];

    Storage.Subscriptions['CLONE_PROJECT'].remove();
    delete Storage.Subscriptions['CLONE_PROJECT'];

    Storage.Subscriptions['LOAD_PROJECT'].remove();
    delete Storage.Subscriptions['LOAD_PROJECT'];

    Storage.Subscriptions['REMOVE_PROJECT'].remove();
    delete Storage.Subscriptions['REMOVE_PROJECT'];

    Storage.Subscriptions['SAVE_PROJECT'].remove();
    delete Storage.Subscriptions['SAVE_PROJECT'];

    Storage.Subscriptions['CLONE_GROUP'].remove();
    delete Storage.Subscriptions['CLONE_GROUP'];

    Storage.Subscriptions['LOAD_GROUP'].remove();
    delete Storage.Subscriptions['LOAD_GROUP'];

    Storage.Subscriptions['REMOVE_GROUP'].remove();
    delete Storage.Subscriptions['REMOVE_GROUP'];

    Storage.Subscriptions['SAVE_GROUP'].remove();
    delete Storage.Subscriptions['SAVE_GROUP'];

    Storage.Subscriptions['CLONE_FILE'].remove();
    delete Storage.Subscriptions['CLONE_FILE'];

    Storage.Subscriptions['LOAD_FILE'].remove();
    delete Storage.Subscriptions['LOAD_FILE'];

    Storage.Subscriptions['REMOVE_FILE'].remove();
    delete Storage.Subscriptions['REMOVE_FILE'];

    Storage.Subscriptions['SAVE_FILE'].remove();
    delete Storage.Subscriptions['SAVE_FILE'];

    Storage.Subscriptions['CLONE_ENTITY'].remove();
    delete Storage.Subscriptions['CLONE_ENTITY'];

    Storage.Subscriptions['LOAD_ENTITY'].remove();
    delete Storage.Subscriptions['LOAD_ENTITY'];

    Storage.Subscriptions['REMOVE_ENTITY'].remove();
    delete Storage.Subscriptions['REMOVE_ENTITY'];

    Storage.Subscriptions['SAVE_ENTITY'].remove();
    delete Storage.Subscriptions['SAVE_ENTITY'];

    Storage.Subscriptions['CLONE_COMPONENT'].remove();
    delete Storage.Subscriptions['CLONE_COMPONENT'];

    Storage.Subscriptions['LOAD_COMPONENT'].remove();
    delete Storage.Subscriptions['LOAD_COMPONENT'];

    Storage.Subscriptions['REMOVE_COMPONENT'].remove();
    delete Storage.Subscriptions['REMOVE_COMPONENT'];

    Storage.Subscriptions['SAVE_COMPONENT'].remove();
    delete Storage.Subscriptions['SAVE_COMPONENT'];
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    Storage.Subscriptions['GET_RUNTIME_STORAGE_AXIOS'].remove();
    delete Storage.Subscriptions['GET_RUNTIME_STORAGE_AXIOS'];
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    Storage.Runtimes.clear();
    Storage.Groups={};
    Storage.Users={};
    Storage.Projects={};
    Storage.Components={};
    Storage.Entities={};
    Storage.Files={};
    Storage.CurrentUser=null;
    Storage.CurrentGroup=null;
    Storage.ApiUrl=null;
    Storage.Started = false;
    Storage.IsStopping = false;
    console.log('Stopped client system Storage');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START
    Storage.Runtimes.add(Storage.RuntimeStorageAxios);
    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * ParseReference()
   * - Calls Constructor on value
   * @param Constructor
   * @param value
   * - No returns
   */
  static ParseReference(Constructor, value) {

    //GENERATED_STATIC_PARSE_REFERENCE_START
    //GENERATED_STATIC_PARSE_REFERENCE_END

    //CUSTOM_STATIC_PARSE_REFERENCE_START
    if (!value) {
      return undefined;
    }

    if (Constructor.prototype instanceof MathsComponent) {
      /**
       * Update the constructor to its more specific type
       */
      let {type} = value;
      Constructor = Utils.GetConstructor(type);

      if (Constructor === Vertex) {
        value.position = new Vector3(value.position);
        value.normal = new Vector3(value.normal);
      }

      if (Constructor === Face) {
        value.uvs = value.uvs.map(
          (uvIndex, index) => {
            return new UV(
              {
                uvIndex : index,
                vertices : uvIndex.map(
                  (uv) => {
                    if (uv) {
                      return new Vector2(uv);
                    } else {
                      return new Vector2();
                    }
                  }
                )
              }
            )
          }
        )
      }

      return new Constructor(value);
    }

    if (Constructor === Number) {
      return Number(value);
    }

    if (
      Constructor === String ||
      Constructor === Boolean ||
      Constructor === Object ||
      Constructor === Buffer ||
      Constructor === ArrayBuffer
    ) {
      return value;
    }

    return undefined;
    //CUSTOM_STATIC_PARSE_REFERENCE_END

    //GENERATED_STATIC_PARSE_REFERENCE_AFTER_START
    //GENERATED_STATIC_PARSE_REFERENCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_PARSE_REFERENCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_PARSE_REFERENCE_AFTER_END

  }
  /**
   * GetOrConstruct()
   * - Gets the object from cache or constructs it right away
   * @param Constructor
   * @param value
   * - No returns
   */
  static GetOrConstruct(Constructor, value) {

    //GENERATED_STATIC_GET_OR_CONSTRUCT_START
    //GENERATED_STATIC_GET_OR_CONSTRUCT_END

    //CUSTOM_STATIC_GET_OR_CONSTRUCT_START
    if (!value || !value.id) {
      throw new Error(`The value needs exist and have a proper id : ${value}`);
    }

    let object = null;

    if (
      Constructor.prototype instanceof Component &&
      !(Constructor.prototype instanceof MathsComponent)
    ) {
      if (Storage.Components.hasOwnProperty(value.id)) {
        object = Storage.Components[value.id];
      } else {
        object = new Constructor(value);
      }
      return object;
    }

    if (Constructor.prototype instanceof Entity || Constructor === Entity) {
      if (Storage.Entities.hasOwnProperty(value.id)) {
        object = Storage.Entities[value.id];
      } else {
        object = new Constructor(value);
      }
      return object;
    }

    if (Constructor === Project) {
      if (Storage.Projects.hasOwnProperty(value.id)) {
        object = Storage.Projects[value.id];
      } else {
        object = new Constructor(value);
      }
      return object;
    }

    if (Constructor === Group) {
      if (Storage.Groups.hasOwnProperty(value.id)) {
        object = Storage.Groups[value.id];
      } else {
        object = new Constructor(value);
      }
      return object;
    }

    if (Constructor === User) {
      if (Storage.Users.hasOwnProperty(value.id)) {
        object = Storage.Users[value.id];
      } else {
        object = new Constructor(value);
      }
      return object;
    }

    throw new Error(`Unhandled constructor type : ${Constructor.name}`);
    //CUSTOM_STATIC_GET_OR_CONSTRUCT_END

    //GENERATED_STATIC_GET_OR_CONSTRUCT_AFTER_START
    //GENERATED_STATIC_GET_OR_CONSTRUCT_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_OR_CONSTRUCT_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_OR_CONSTRUCT_AFTER_END

  }
  /**
   * GetObjectById()
   * - Gets the object directly from in-memory cache
   * @param id
   * - No returns
   */
  static GetObjectById(id) {

    //GENERATED_STATIC_GET_OBJECT_BY_ID_START
    //GENERATED_STATIC_GET_OBJECT_BY_ID_END

    //CUSTOM_STATIC_GET_OBJECT_BY_ID_START
    let object = null;

    if (Storage.Components.hasOwnProperty(id)) {
      object = Storage.Components[id];
      object.fromCache = true;
      return object;
    }

    if (Storage.Entities.hasOwnProperty(id)) {
      object = Storage.Entities[id];
      object.fromCache = true;
      return object;
    }

    if (Storage.Projects.hasOwnProperty(id)) {
      object = Storage.Projects[id];
      object.fromCache = true;
      return object;
    }

    if (Storage.Users.hasOwnProperty(id)) {
      object = Storage.Users[id];
      object.fromCache = true;
      return object;
    }

    if (Storage.Groups.hasOwnProperty(id)) {
      object = Storage.Groups[id];
      object.fromCache = true;
      return object;
    }

    return object;
    //CUSTOM_STATIC_GET_OBJECT_BY_ID_END

    //GENERATED_STATIC_GET_OBJECT_BY_ID_AFTER_START
    //GENERATED_STATIC_GET_OBJECT_BY_ID_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_OBJECT_BY_ID_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_OBJECT_BY_ID_AFTER_END

  }
  /**
   * ProcessGenericReferences()
   * - Defines how object references are processed. Instance holds the updated information
   * @param object
   * @param instance
   * - No returns
   */
  static async ProcessGenericReferences(object, instance) {

    //GENERATED_STATIC_PROCESS_GENERIC_REFERENCES_START
    //GENERATED_STATIC_PROCESS_GENERIC_REFERENCES_END

    //CUSTOM_STATIC_PROCESS_GENERIC_REFERENCES_START
    for (let [property, reference] of Object.entries(object.references)) {

      if (typeof instance[property] === 'undefined') {
        continue;
      }

      let {Constructor} = reference;

      let isArray = false;

      if (instance[property] instanceof Array) {
        isArray = true;
      }

      if (isArray && !(Constructor instanceof Array)) {
        throw new Error(`${object.uniqueName} instance ${property} is of a wrong type : ${instance[property]}`);
      }

      if (isArray) {
        Constructor = Constructor[0];
      }

      if (isArray) {

        let array = [];
        let broken = false;

        for (let item of instance[property]) {
          let value = Storage.ParseReference(Constructor, item);
          if (typeof value === 'undefined') {
            // console.log(`Skipping array item of ${object.uniqueName}.${property}`);
            broken = true;
            break;
          }
          array.push(value);
        }

        if (broken) {
          continue;
        }

        object[property] = array;

      } else {
        let value = Storage.ParseReference(Constructor, instance[property]);
        if (typeof value !== 'undefined') {
          object[property] = value;
        } else {
          // console.log(`Skipping ${object.uniqueName}.${property}`);
        }
      }
    }
    //CUSTOM_STATIC_PROCESS_GENERIC_REFERENCES_END

    //GENERATED_STATIC_PROCESS_GENERIC_REFERENCES_AFTER_START
    //GENERATED_STATIC_PROCESS_GENERIC_REFERENCES_AFTER_END

    //CUSTOM_STATIC_GENERATED_PROCESS_GENERIC_REFERENCES_AFTER_START
    //CUSTOM_STATIC_GENERATED_PROCESS_GENERIC_REFERENCES_AFTER_END

  }
  /**
   * ProcessComponentReferences()
   * - Defines how component references are processed. Instance holds the updated information
   * @param component
   * @param instance
   * - No returns
   */
  static async ProcessComponentReferences(component, instance) {

    //GENERATED_STATIC_PROCESS_COMPONENT_REFERENCES_START
    //GENERATED_STATIC_PROCESS_COMPONENT_REFERENCES_END

    //CUSTOM_STATIC_PROCESS_COMPONENT_REFERENCES_START
    /**
     * Load our parents, but ignore projects because they load all components
     */
    for (let property of Object.keys(component.references)) {

      if (!instance[property]) {
        continue;
      }

      let isArray = false;

      if (instance[property] instanceof Array) {
        isArray = true;
      }

      let constructorOk = (Constructor) => {
        if (
          Constructor === Project ||
          Constructor === User ||
          Constructor === Group ||
          Constructor.prototype instanceof MathsComponent
        ) {
          return false;
        }

        if (Constructor.prototype instanceof Obj) {
          return true;
        }
      }

      if (isArray) {

        let array = [];
        for (let item of instance[property]) {
          let {type} = item;

          if (!type) {
            continue;
          }

          let Constructor = Utils.GetConstructor(type);

          if (!constructorOk(Constructor)) {
            continue;
          }

          let object = Storage.GetOrConstruct(Constructor, item);

          if (!object.loaded && object.state !== Obj.STATE_LOADING) {
            await object.load();
          }

          array.push(object);
        }
        component[property] = array;

      } else {

        let {type} = instance[property];

        if (!type) {
          continue;
        }

        let Constructor = Utils.GetConstructor(type);

        if (!constructorOk(Constructor)) {
          continue;
        }

        let object = Storage.GetOrConstruct(Constructor, instance[property]);

        if (!object.loaded && object.state !== Obj.STATE_LOADING) {
          await object.load();
        }

        component[property] = object;
      }

    }
    //CUSTOM_STATIC_PROCESS_COMPONENT_REFERENCES_END

    //GENERATED_STATIC_PROCESS_COMPONENT_REFERENCES_AFTER_START
    //GENERATED_STATIC_PROCESS_COMPONENT_REFERENCES_AFTER_END

    //CUSTOM_STATIC_GENERATED_PROCESS_COMPONENT_REFERENCES_AFTER_START
    //CUSTOM_STATIC_GENERATED_PROCESS_COMPONENT_REFERENCES_AFTER_END

  }
  /**
   * ProcessComponentChildren()
   * - Defines how component children are processed. Instance holds the updated information
   * @param component
   * @param instance
   * - No returns
   */
  static async ProcessComponentChildren(component, instance) {

    //GENERATED_STATIC_PROCESS_COMPONENT_CHILDREN_START
    //GENERATED_STATIC_PROCESS_COMPONENT_CHILDREN_END

    //CUSTOM_STATIC_PROCESS_COMPONENT_CHILDREN_START
    let children = [...instance.children];

    for (let child of children) {

      let {id, type} = child;

      let object = Storage.GetObjectById(id);
      if (!object) {
        let Constructor = Utils.GetConstructor(type);
        object = new Constructor({id});
      }

      if (!object.loaded) {
        await object.load(true);
      }
    }
    //CUSTOM_STATIC_PROCESS_COMPONENT_CHILDREN_END

    //GENERATED_STATIC_PROCESS_COMPONENT_CHILDREN_AFTER_START
    //GENERATED_STATIC_PROCESS_COMPONENT_CHILDREN_AFTER_END

    //CUSTOM_STATIC_GENERATED_PROCESS_COMPONENT_CHILDREN_AFTER_START
    //CUSTOM_STATIC_GENERATED_PROCESS_COMPONENT_CHILDREN_AFTER_END

  }
  /**
   * ProcessEntityReferences()
   * - Defines how entity references are processed. Instance holds the updated information
   * @param entity
   * @param instance
   * - No returns
   */
  static async ProcessEntityReferences(entity, instance) {

    //GENERATED_STATIC_PROCESS_ENTITY_REFERENCES_START
    //GENERATED_STATIC_PROCESS_ENTITY_REFERENCES_END

    //CUSTOM_STATIC_PROCESS_ENTITY_REFERENCES_START
    /**
     * Handle it the same way as we handle components - we load
     * all parents which are not projects or generic references
     */
    await Storage.ProcessComponentReferences(entity, instance);
    //CUSTOM_STATIC_PROCESS_ENTITY_REFERENCES_END

    //GENERATED_STATIC_PROCESS_ENTITY_REFERENCES_AFTER_START
    //GENERATED_STATIC_PROCESS_ENTITY_REFERENCES_AFTER_END

    //CUSTOM_STATIC_GENERATED_PROCESS_ENTITY_REFERENCES_AFTER_START
    //CUSTOM_STATIC_GENERATED_PROCESS_ENTITY_REFERENCES_AFTER_END

  }
  /**
   * ProcessProjectChildren()
   * - Loads all project children. Instance holds the updated information
   * @param project
   * @param instance
   * - No returns
   */
  static async ProcessProjectChildren(project, instance) {

    //GENERATED_STATIC_PROCESS_PROJECT_CHILDREN_START
    //GENERATED_STATIC_PROCESS_PROJECT_CHILDREN_END

    //CUSTOM_STATIC_PROCESS_PROJECT_CHILDREN_START
    /**
     * Dispose of all current project children
     */
    let sorted = Utils.SortByHierarchy(project.children);
    for (let child of [...sorted].reverse()) {
      child.dispose();
    }

    /**
     * The instance children should come sorted from the DB
     */
    for (let child of [...instance.children].reverse()) {
      let {id} = child;
      let object = Storage.GetObjectById(id);
      if (object) {
        object.dispose();
      }
    }

    /**
     * Look at the instance children and load all of them
     */
    for (let child of instance.children) {
      let object = null;
      try {
        let {id, type} = child;
        let Constructor = Utils.GetConstructor(type);
        object = Storage.GetOrConstruct(Constructor, {id, project});
        Utils.Status(0, `Loading ${object.id}...`);
        await object.load(true);
      } catch (error) {
        if (object) {
          console.error(`Skipping load of ${object.uniqueName} (${object.id}) because of error: ${error.message}`);
          object.dispose();
        } else {
          console.error(`Skipping load because of error: ${error.message}`);
        }
      }
    }

    Utils.Status(0, `Loaded project ${project.name}`);
    //CUSTOM_STATIC_PROCESS_PROJECT_CHILDREN_END

    //GENERATED_STATIC_PROCESS_PROJECT_CHILDREN_AFTER_START
    //GENERATED_STATIC_PROCESS_PROJECT_CHILDREN_AFTER_END

    //CUSTOM_STATIC_GENERATED_PROCESS_PROJECT_CHILDREN_AFTER_START
    //CUSTOM_STATIC_GENERATED_PROCESS_PROJECT_CHILDREN_AFTER_END

  }
  /**
   * ProcessUserReferences()
   * - Defines how user references are processed. Instance holds the updated information
   * @param user
   * @param instance
   * - No returns
   */
  static async ProcessUserReferences(user, instance) {

    //GENERATED_STATIC_PROCESS_USER_REFERENCES_START
    //GENERATED_STATIC_PROCESS_USER_REFERENCES_END

    //CUSTOM_STATIC_PROCESS_USER_REFERENCES_START
    await Storage.ProcessGenericReferences(user, instance);
    //CUSTOM_STATIC_PROCESS_USER_REFERENCES_END

    //GENERATED_STATIC_PROCESS_USER_REFERENCES_AFTER_START
    //GENERATED_STATIC_PROCESS_USER_REFERENCES_AFTER_END

    //CUSTOM_STATIC_GENERATED_PROCESS_USER_REFERENCES_AFTER_START
    //CUSTOM_STATIC_GENERATED_PROCESS_USER_REFERENCES_AFTER_END

  }
  /**
   * ProcessBlenderChildren()
   * - Processes the blender children
   * @param instance
   * - No returns
   */
  static async ProcessBlenderChildren(instance) {

    //GENERATED_STATIC_PROCESS_BLENDER_CHILDREN_START
    //GENERATED_STATIC_PROCESS_BLENDER_CHILDREN_END

    //CUSTOM_STATIC_PROCESS_BLENDER_CHILDREN_START
    let project = Utils.GetCurrentProject();
    if (!project || !project.loaded) {
      new Project();
    }

    let canvas = Utils.GetCurrentCanvas();
    if (!canvas) {
      canvas = new Canvas();
    }

    let camera = Utils.GetCurrentCamera();
    if (!camera) {
      camera = new Perspective({canvas});
    }

    let scene = Utils.GetCurrentScene();
    if (!scene) {
      scene = new Scene();
    }

    let light = Utils.GetCurrentLight();
    if (!light) {
      new Ambient({scene});
    }

    let renderer = Utils.GetCurrentRenderer();
    if (!renderer) {
      new Gl({camera, scene, canvas});
    }


    let {children} = instance;

    for (let component of children) {
      let object = Storage.GetObjectById(component.id);
      if (object) {
        object.dispose();
      }
    }

    for (let component of children) {
      let {id, type} = component;
      let Constructor = Utils.GetConstructor(type);
      let object = new Constructor({id});
      await object.load();
    }
    //CUSTOM_STATIC_PROCESS_BLENDER_CHILDREN_END

    //GENERATED_STATIC_PROCESS_BLENDER_CHILDREN_AFTER_START
    //GENERATED_STATIC_PROCESS_BLENDER_CHILDREN_AFTER_END

    //CUSTOM_STATIC_GENERATED_PROCESS_BLENDER_CHILDREN_AFTER_START
    //CUSTOM_STATIC_GENERATED_PROCESS_BLENDER_CHILDREN_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * GetStorageObjectById()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetStorageObjectById(event) {

    //GENERATED_STATIC_GET_STORAGE_OBJECT_BY_ID_START
    //GENERATED_STATIC_GET_STORAGE_OBJECT_BY_ID_END

    //CUSTOM_STATIC_GET_STORAGE_OBJECT_BY_ID_START
    let {id} = event;

    let object = Storage.GetObjectById(id);

    event.results = [object];
    //CUSTOM_STATIC_GET_STORAGE_OBJECT_BY_ID_END

    //GENERATED_STATIC_GET_STORAGE_OBJECT_BY_ID_AFTER_START
    //GENERATED_STATIC_GET_STORAGE_OBJECT_BY_ID_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_STORAGE_OBJECT_BY_ID_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_STORAGE_OBJECT_BY_ID_AFTER_END

  }

  /**
   * SetCurrentUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetCurrentUser(event) {

    //GENERATED_STATIC_SET_CURRENT_USER_START
    //GENERATED_STATIC_SET_CURRENT_USER_END

    //CUSTOM_STATIC_SET_CURRENT_USER_START
    Storage.CurrentUser = event.object;
    console.log(`Storage user set to ${Storage.CurrentUser.username}`);
    //CUSTOM_STATIC_SET_CURRENT_USER_END

    //GENERATED_STATIC_SET_CURRENT_USER_AFTER_START
    //GENERATED_STATIC_SET_CURRENT_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_CURRENT_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_CURRENT_USER_AFTER_END

  }

  /**
   * SetCurrentGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetCurrentGroup(event) {

    //GENERATED_STATIC_SET_CURRENT_GROUP_START
    //GENERATED_STATIC_SET_CURRENT_GROUP_END

    //CUSTOM_STATIC_SET_CURRENT_GROUP_START
    Storage.CurrentGroup = event.object;
    console.log(`Storage group set to ${Storage.CurrentGroup.name}`);
    //CUSTOM_STATIC_SET_CURRENT_GROUP_END

    //GENERATED_STATIC_SET_CURRENT_GROUP_AFTER_START
    //GENERATED_STATIC_SET_CURRENT_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_CURRENT_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_CURRENT_GROUP_AFTER_END

  }

  /**
   * SetApiUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetApiUrl(event) {

    //GENERATED_STATIC_SET_API_URL_START
    //GENERATED_STATIC_SET_API_URL_END

    //CUSTOM_STATIC_SET_API_URL_START
    Storage.ApiUrl = event.object;
    console.log(`Storage system API URL set to ${Storage.ApiUrl}`);
    //CUSTOM_STATIC_SET_API_URL_END

    //GENERATED_STATIC_SET_API_URL_AFTER_START
    //GENERATED_STATIC_SET_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_API_URL_AFTER_END

  }

  /**
   * GetApiUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetApiUrl(event) {

    //GENERATED_STATIC_GET_API_URL_START
    //GENERATED_STATIC_GET_API_URL_END

    //CUSTOM_STATIC_GET_API_URL_START
    event.results = [Storage.ApiUrl];
    //CUSTOM_STATIC_GET_API_URL_END

    //GENERATED_STATIC_GET_API_URL_AFTER_START
    //GENERATED_STATIC_GET_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_API_URL_AFTER_END

  }

  /**
   * DisposeGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeGroup(event) {

    //GENERATED_STATIC_DISPOSE_GROUP_START
    //GENERATED_STATIC_DISPOSE_GROUP_END

    //CUSTOM_STATIC_DISPOSE_GROUP_START
    let {object:group} = event;
    delete Storage.Groups[group.id];
    //CUSTOM_STATIC_DISPOSE_GROUP_END

    //GENERATED_STATIC_DISPOSE_GROUP_AFTER_START
    //GENERATED_STATIC_DISPOSE_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_GROUP_AFTER_END

  }

  /**
   * InitializeGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeGroup(event) {

    //GENERATED_STATIC_INITIALIZE_GROUP_START
    //GENERATED_STATIC_INITIALIZE_GROUP_END

    //CUSTOM_STATIC_INITIALIZE_GROUP_START
    let {object:group} = event;
    Storage.Groups[group.id] = group;
    //CUSTOM_STATIC_INITIALIZE_GROUP_END

    //GENERATED_STATIC_INITIALIZE_GROUP_AFTER_START
    //GENERATED_STATIC_INITIALIZE_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_GROUP_AFTER_END

  }

  /**
   * DisposeFile()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeFile(event) {

    //GENERATED_STATIC_DISPOSE_FILE_START
    //GENERATED_STATIC_DISPOSE_FILE_END

    //CUSTOM_STATIC_DISPOSE_FILE_START
    //CUSTOM_STATIC_DISPOSE_FILE_END

    //GENERATED_STATIC_DISPOSE_FILE_AFTER_START
    //GENERATED_STATIC_DISPOSE_FILE_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_FILE_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_FILE_AFTER_END

  }

  /**
   * InitializeFile()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeFile(event) {

    //GENERATED_STATIC_INITIALIZE_FILE_START
    //GENERATED_STATIC_INITIALIZE_FILE_END

    //CUSTOM_STATIC_INITIALIZE_FILE_START
    //CUSTOM_STATIC_INITIALIZE_FILE_END

    //GENERATED_STATIC_INITIALIZE_FILE_AFTER_START
    //GENERATED_STATIC_INITIALIZE_FILE_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_FILE_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_FILE_AFTER_END

  }

  /**
   * DisposeEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeEntity(event) {

    //GENERATED_STATIC_DISPOSE_ENTITY_START
    //GENERATED_STATIC_DISPOSE_ENTITY_END

    //CUSTOM_STATIC_DISPOSE_ENTITY_START
    let {object:entity} = event;
    delete Storage.Entities[entity.id];
    //CUSTOM_STATIC_DISPOSE_ENTITY_END

    //GENERATED_STATIC_DISPOSE_ENTITY_AFTER_START
    //GENERATED_STATIC_DISPOSE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_ENTITY_AFTER_END

  }

  /**
   * InitializeEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeEntity(event) {

    //GENERATED_STATIC_INITIALIZE_ENTITY_START
    //GENERATED_STATIC_INITIALIZE_ENTITY_END

    //CUSTOM_STATIC_INITIALIZE_ENTITY_START
    let {object:entity} = event;
    Storage.Entities[entity.id] = entity;
    //CUSTOM_STATIC_INITIALIZE_ENTITY_END

    //GENERATED_STATIC_INITIALIZE_ENTITY_AFTER_START
    //GENERATED_STATIC_INITIALIZE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_ENTITY_AFTER_END

  }

  /**
   * DisposeComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeComponent(event) {

    //GENERATED_STATIC_DISPOSE_COMPONENT_START
    //GENERATED_STATIC_DISPOSE_COMPONENT_END

    //CUSTOM_STATIC_DISPOSE_COMPONENT_START
    let {object:component} = event;

    if (component instanceof MathsComponent) {
      return;
    }

    delete Storage.Components[component.id];
    //CUSTOM_STATIC_DISPOSE_COMPONENT_END

    //GENERATED_STATIC_DISPOSE_COMPONENT_AFTER_START
    //GENERATED_STATIC_DISPOSE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_COMPONENT_AFTER_END

  }

  /**
   * InitializeComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeComponent(event) {

    //GENERATED_STATIC_INITIALIZE_COMPONENT_START
    //GENERATED_STATIC_INITIALIZE_COMPONENT_END

    //CUSTOM_STATIC_INITIALIZE_COMPONENT_START
    let {object:component} = event;

    if (component instanceof MathsComponent) {
      return;
    }

    Storage.Components[component.id] = component;
    //CUSTOM_STATIC_INITIALIZE_COMPONENT_END

    //GENERATED_STATIC_INITIALIZE_COMPONENT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_COMPONENT_AFTER_END

  }

  /**
   * DisposeProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeProject(event) {

    //GENERATED_STATIC_DISPOSE_PROJECT_START
    //GENERATED_STATIC_DISPOSE_PROJECT_END

    //CUSTOM_STATIC_DISPOSE_PROJECT_START
    let {object:project} = event;
    delete Storage.Projects[project.id];
    project.loaded = false;
    //CUSTOM_STATIC_DISPOSE_PROJECT_END

    //GENERATED_STATIC_DISPOSE_PROJECT_AFTER_START
    //GENERATED_STATIC_DISPOSE_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_PROJECT_AFTER_END

  }

  /**
   * InitializeProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeProject(event) {

    //GENERATED_STATIC_INITIALIZE_PROJECT_START
    //GENERATED_STATIC_INITIALIZE_PROJECT_END

    //CUSTOM_STATIC_INITIALIZE_PROJECT_START
    let {object:project} = event;
    Storage.Projects[project.id] = project;
    //CUSTOM_STATIC_INITIALIZE_PROJECT_END

    //GENERATED_STATIC_INITIALIZE_PROJECT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_PROJECT_AFTER_END

  }

  /**
   * DisposeUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeUser(event) {

    //GENERATED_STATIC_DISPOSE_USER_START
    //GENERATED_STATIC_DISPOSE_USER_END

    //CUSTOM_STATIC_DISPOSE_USER_START
    let {object:user} = event;
    delete Storage.Users[user.id];

    for (let key of Object.keys(Storage.Projects)) {
      let project = Storage.Projects[key];
      if (project.user === user) {
        delete Storage.Projects[project.id];
      }
    }
    //CUSTOM_STATIC_DISPOSE_USER_END

    //GENERATED_STATIC_DISPOSE_USER_AFTER_START
    //GENERATED_STATIC_DISPOSE_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_USER_AFTER_END

  }

  /**
   * InitializeUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeUser(event) {

    //GENERATED_STATIC_INITIALIZE_USER_START
    //GENERATED_STATIC_INITIALIZE_USER_END

    //CUSTOM_STATIC_INITIALIZE_USER_START
    let {object:user} = event;
    Storage.Users[user.id] = user;
    //CUSTOM_STATIC_INITIALIZE_USER_END

    //GENERATED_STATIC_INITIALIZE_USER_AFTER_START
    //GENERATED_STATIC_INITIALIZE_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_USER_AFTER_END

  }

  /**
   * GetUsersList()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async GetUsersList(event) {

    //GENERATED_STATIC_GET_USERS_LIST_START
    //GENERATED_STATIC_GET_USERS_LIST_END

    //CUSTOM_STATIC_GET_USERS_LIST_START
    let {offset, count} = event;

    let results = [];

    for (let runtime of Storage.Runtimes) {
      results = [ ...await runtime.getUsersList(offset, count)];
    }

    event.results = results;
    //CUSTOM_STATIC_GET_USERS_LIST_END

    //GENERATED_STATIC_GET_USERS_LIST_AFTER_START
    //GENERATED_STATIC_GET_USERS_LIST_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_USERS_LIST_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_USERS_LIST_AFTER_END

  }

  /**
   * GetSourceUrls()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async GetSourceUrls(event) {

    //GENERATED_STATIC_GET_SOURCE_URLS_START
    //GENERATED_STATIC_GET_SOURCE_URLS_END

    //CUSTOM_STATIC_GET_SOURCE_URLS_START
    event.results = [
      [
        {
          handle : 'development',
          api : 'http://api.dev.r3js.org:3000',
          websocket : 'http://api.dev.r3js.org:3030'
        },
        {
          handle : 'beta',
          api : 'https://api.beta.r3js.org',
          websocket : 'https://websocket.beta.r3js.org'
        },
        {
          handle : 'production',
          api : 'https://api.r3js.org',
          websocket : 'https://websocket.r3js.org'
        }
      ]
    ];
    //CUSTOM_STATIC_GET_SOURCE_URLS_END

    //GENERATED_STATIC_GET_SOURCE_URLS_AFTER_START
    //GENERATED_STATIC_GET_SOURCE_URLS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_SOURCE_URLS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_SOURCE_URLS_AFTER_END

  }

  /**
   * GetTargetUrls()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async GetTargetUrls(event) {

    //GENERATED_STATIC_GET_TARGET_URLS_START
    //GENERATED_STATIC_GET_TARGET_URLS_END

    //CUSTOM_STATIC_GET_TARGET_URLS_START
    event.results = [
      [
        {
          handle : 'development',
          api : 'http://api.dev.r3js.org:3000',
          websocket : 'http://api.dev.r3js.org:3030',
          portal : 'http://portal.dev.r3js.org:3006'
        },
        {
          handle : 'beta',
          api : 'https://api.beta.r3js.org',
          websocket : 'https://websocket.beta.r3js.org',
          portal : 'https://portal.beta.r3js.org'
        },
        {
          handle : 'production',
          api : 'https://api.r3js.org',
          websocket : 'https://websocket.r3js.org',
          portal : 'https://portal.r3js.org'
        }
      ]
    ];
    //CUSTOM_STATIC_GET_TARGET_URLS_END

    //GENERATED_STATIC_GET_TARGET_URLS_AFTER_START
    //GENERATED_STATIC_GET_TARGET_URLS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_TARGET_URLS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_TARGET_URLS_AFTER_END

  }

  /**
   * SetSourceApiUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetSourceApiUrl(event) {

    //GENERATED_STATIC_SET_SOURCE_API_URL_START
    //GENERATED_STATIC_SET_SOURCE_API_URL_END

    //CUSTOM_STATIC_SET_SOURCE_API_URL_START
    //CUSTOM_STATIC_SET_SOURCE_API_URL_END

    //GENERATED_STATIC_SET_SOURCE_API_URL_AFTER_START
    //GENERATED_STATIC_SET_SOURCE_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_SOURCE_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_SOURCE_API_URL_AFTER_END

  }

  /**
   * GetSourceApiUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetSourceApiUrl(event) {

    //GENERATED_STATIC_GET_SOURCE_API_URL_START
    //GENERATED_STATIC_GET_SOURCE_API_URL_END

    //CUSTOM_STATIC_GET_SOURCE_API_URL_START
    //CUSTOM_STATIC_GET_SOURCE_API_URL_END

    //GENERATED_STATIC_GET_SOURCE_API_URL_AFTER_START
    //GENERATED_STATIC_GET_SOURCE_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_SOURCE_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_SOURCE_API_URL_AFTER_END

  }

  /**
   * SetSourceWebsocketUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetSourceWebsocketUrl(event) {

    //GENERATED_STATIC_SET_SOURCE_WEBSOCKET_URL_START
    //GENERATED_STATIC_SET_SOURCE_WEBSOCKET_URL_END

    //CUSTOM_STATIC_SET_SOURCE_WEBSOCKET_URL_START
    //CUSTOM_STATIC_SET_SOURCE_WEBSOCKET_URL_END

    //GENERATED_STATIC_SET_SOURCE_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_SET_SOURCE_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_SOURCE_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_SOURCE_WEBSOCKET_URL_AFTER_END

  }

  /**
   * GetSourceWebsocketUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetSourceWebsocketUrl(event) {

    //GENERATED_STATIC_GET_SOURCE_WEBSOCKET_URL_START
    //GENERATED_STATIC_GET_SOURCE_WEBSOCKET_URL_END

    //CUSTOM_STATIC_GET_SOURCE_WEBSOCKET_URL_START
    //CUSTOM_STATIC_GET_SOURCE_WEBSOCKET_URL_END

    //GENERATED_STATIC_GET_SOURCE_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_GET_SOURCE_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_SOURCE_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_SOURCE_WEBSOCKET_URL_AFTER_END

  }

  /**
   * SetTargetApiUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetTargetApiUrl(event) {

    //GENERATED_STATIC_SET_TARGET_API_URL_START
    //GENERATED_STATIC_SET_TARGET_API_URL_END

    //CUSTOM_STATIC_SET_TARGET_API_URL_START
    //CUSTOM_STATIC_SET_TARGET_API_URL_END

    //GENERATED_STATIC_SET_TARGET_API_URL_AFTER_START
    //GENERATED_STATIC_SET_TARGET_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_TARGET_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_TARGET_API_URL_AFTER_END

  }

  /**
   * GetTargetApiUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetTargetApiUrl(event) {

    //GENERATED_STATIC_GET_TARGET_API_URL_START
    //GENERATED_STATIC_GET_TARGET_API_URL_END

    //CUSTOM_STATIC_GET_TARGET_API_URL_START
    //CUSTOM_STATIC_GET_TARGET_API_URL_END

    //GENERATED_STATIC_GET_TARGET_API_URL_AFTER_START
    //GENERATED_STATIC_GET_TARGET_API_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_TARGET_API_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_TARGET_API_URL_AFTER_END

  }

  /**
   * SetTargetWebsocketUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static SetTargetWebsocketUrl(event) {

    //GENERATED_STATIC_SET_TARGET_WEBSOCKET_URL_START
    //GENERATED_STATIC_SET_TARGET_WEBSOCKET_URL_END

    //CUSTOM_STATIC_SET_TARGET_WEBSOCKET_URL_START
    //CUSTOM_STATIC_SET_TARGET_WEBSOCKET_URL_END

    //GENERATED_STATIC_SET_TARGET_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_SET_TARGET_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_TARGET_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_TARGET_WEBSOCKET_URL_AFTER_END

  }

  /**
   * GetTargetWebsocketUrl()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetTargetWebsocketUrl(event) {

    //GENERATED_STATIC_GET_TARGET_WEBSOCKET_URL_START
    //GENERATED_STATIC_GET_TARGET_WEBSOCKET_URL_END

    //CUSTOM_STATIC_GET_TARGET_WEBSOCKET_URL_START
    //CUSTOM_STATIC_GET_TARGET_WEBSOCKET_URL_END

    //GENERATED_STATIC_GET_TARGET_WEBSOCKET_URL_AFTER_START
    //GENERATED_STATIC_GET_TARGET_WEBSOCKET_URL_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_TARGET_WEBSOCKET_URL_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_TARGET_WEBSOCKET_URL_AFTER_END

  }

  /**
   * DeployProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async DeployProject(event) {

    //GENERATED_STATIC_DEPLOY_PROJECT_START
    //GENERATED_STATIC_DEPLOY_PROJECT_END

    //CUSTOM_STATIC_DEPLOY_PROJECT_START
    /**
     * Stop all systems not required for deploying
     */
    System.Gui.Stop();
    System.Code.Stop();
    System.Entity.Stop();
    System.Physics.Stop();
    System.Audio.Stop();
    System.Graphics.Stop();
    System.Input.Stop();
    System.Maths.Stop();
    System.Websocket.Stop();

    let {projectId, target} = event;

    let currentApiUrl = Utils.GetApiUrl();

    if (currentApiUrl === target.api) {
      throw new Error(`Current and target API is the same`);
    }

    let project = Utils.GetObjectById(projectId);

    if (project) {
      if (!confirm("The project is currently open and will be loaded again. You will lose any unsaved work, are you sure?")) {
        throw new Error(`User cancelled deploy to save work`);
      }
    }

    if (!project) {
      project = new Project(
        {id : projectId}
      );
    }

    await project.load();

    Utils.SetApiUrl(target.api);

    await project.save();

    Utils.SetApiUrl(currentApiUrl);
    //CUSTOM_STATIC_DEPLOY_PROJECT_END

    //GENERATED_STATIC_DEPLOY_PROJECT_AFTER_START
    //GENERATED_STATIC_DEPLOY_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DEPLOY_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DEPLOY_PROJECT_AFTER_END

  }

  /**
   * GetList()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async GetList(event) {

    //GENERATED_STATIC_GET_LIST_START
    //GENERATED_STATIC_GET_LIST_END

    //CUSTOM_STATIC_GET_LIST_START
    let {type, offset, count, keywords} = event;

    let items = [];

    for (let runtime of Storage.Runtimes) {
      items = [...items, ...await runtime.getList(type, offset, count, keywords)]
    }

    return items;
    //CUSTOM_STATIC_GET_LIST_END

    //GENERATED_STATIC_GET_LIST_AFTER_START
    //GENERATED_STATIC_GET_LIST_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_LIST_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_LIST_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  /**
   * CloneUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async CloneUser(event) {

    //GENERATED_STATIC_CLONE_USER_START
    //CUSTOM_USER_MODE_CLONE_USER_INSTANCE_HANDLER_START
    let {object} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('clone', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method clone`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.clone(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_CLONE_USER_INSTANCE_HANDLER_END
    //GENERATED_STATIC_CLONE_USER_END

    //CUSTOM_STATIC_CLONE_USER_START
    //CUSTOM_STATIC_CLONE_USER_END

    //GENERATED_STATIC_CLONE_USER_AFTER_START
    //GENERATED_STATIC_CLONE_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_CLONE_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_CLONE_USER_AFTER_END

  }

  /**
   * LoadUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async LoadUser(event) {

    //GENERATED_STATIC_LOAD_USER_START
    //CUSTOM_USER_MODE_LOAD_USER_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_LOAD_USER_INSTANCE_HANDLER_END
    //GENERATED_STATIC_LOAD_USER_END

    //CUSTOM_STATIC_LOAD_USER_START
    let {object: user} = event;

   for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('load', user);
      if (!valid) {
        throw new Error(`${user.uniqueName} failed to validate for method load`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.load(user);
      user.instances[runtime.type].object = instance;
    }

    /**
     * Create referenced components
     */
    let instance = user.getInstance(Runtime.KEY_STORAGE);

    /**
     * Create our standard generic references (Math components, Numbers, Strings etc)
     */
    await Storage.ProcessGenericReferences(user, instance);

    /**
     * Create or load or group
     */
    if (instance.group && instance.group.id) {

      let group = Storage.Groups[instance.group.id];

      let oldGroup = Storage.CurrentGroup;

      if (!group) {
        group = new Group({id : instance.group.id});
      }

      if (oldGroup && Storage.CurrentGroup !== oldGroup) {
        oldGroup.dispose();
      }

      if (!group.loaded) {
        await group.load();
      }

      user.group = group;
    }

    /**
     * Check if we have children
     */
    if (!instance.children || !instance.children.length) {
      return;
    }

    /**
     * Create empty project objects ready to load
     */
    for (let child of instance.children) {

      Event.Emit(
        Event.ADD_PROJECT,
        {project:child}
      )
      //
      // let object = Storage.GetObjectById(child.id);
      // if (!object) {
      //   let Constructor = Utils.GetConstructor(child.type);
      //   object = new Constructor(
      //     {
      //       id : child.id,
      //       name : child.name
      //     }
      //   );
      // }
      //
      // if (object instanceof Project) {
      //   object.user = user;
      // } else {
      //   throw new Error(`The user has other children which are not projects - use case for this?`);
      // }

    }
    //CUSTOM_STATIC_LOAD_USER_END

    //GENERATED_STATIC_LOAD_USER_AFTER_START
    //GENERATED_STATIC_LOAD_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_LOAD_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_LOAD_USER_AFTER_END

  }

  /**
   * RemoveUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async RemoveUser(event) {

    //GENERATED_STATIC_REMOVE_USER_START
    //CUSTOM_USER_MODE_REMOVE_USER_INSTANCE_HANDLER_START
    let {object} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('remove', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method remove`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.remove(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_REMOVE_USER_INSTANCE_HANDLER_END
    //GENERATED_STATIC_REMOVE_USER_END

    //CUSTOM_STATIC_REMOVE_USER_START
    //CUSTOM_STATIC_REMOVE_USER_END

    //GENERATED_STATIC_REMOVE_USER_AFTER_START
    //GENERATED_STATIC_REMOVE_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_REMOVE_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_REMOVE_USER_AFTER_END

  }

  /**
   * SaveUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async SaveUser(event) {

    //GENERATED_STATIC_SAVE_USER_START
    //CUSTOM_USER_MODE_SAVE_USER_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_SAVE_USER_INSTANCE_HANDLER_END
    //GENERATED_STATIC_SAVE_USER_END

    //CUSTOM_STATIC_SAVE_USER_START
    let {object: user} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('save', user);
      if (!valid) {
        throw new Error(`The user failed to validate for saving`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.save(user);
      user.instances[runtime.type].object = instance;
    }

    Utils.Status(0, `Saved user ${user.username}`);
    //CUSTOM_STATIC_SAVE_USER_END

    //GENERATED_STATIC_SAVE_USER_AFTER_START
    //GENERATED_STATIC_SAVE_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_SAVE_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_SAVE_USER_AFTER_END

  }

  /**
   * CloneProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async CloneProject(event) {

    //GENERATED_STATIC_CLONE_PROJECT_START
    //CUSTOM_USER_MODE_CLONE_PROJECT_INSTANCE_HANDLER_START
    let {object} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('clone', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method clone`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.clone(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_CLONE_PROJECT_INSTANCE_HANDLER_END
    //GENERATED_STATIC_CLONE_PROJECT_END

    //CUSTOM_STATIC_CLONE_PROJECT_START
    //CUSTOM_STATIC_CLONE_PROJECT_END

    //GENERATED_STATIC_CLONE_PROJECT_AFTER_START
    //GENERATED_STATIC_CLONE_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_CLONE_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_CLONE_PROJECT_AFTER_END

  }

  /**
   * LoadProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async LoadProject(event) {

    //GENERATED_STATIC_LOAD_PROJECT_START
    //CUSTOM_USER_MODE_LOAD_PROJECT_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_LOAD_PROJECT_INSTANCE_HANDLER_END
    //GENERATED_STATIC_LOAD_PROJECT_END

    //CUSTOM_STATIC_LOAD_PROJECT_START
    let {object: project} = event;

    Event.Emit(
      Event.SET_CURRENT_PROJECT,
      event
    );

    project.mode = Obj.MODE_LIVE;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('load', project);
      if (!valid) {
        throw new Error(`${project.uniqueName} failed to validate for method load`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.load(project);
      project.instances[runtime.type].object = instance;
    }

    /**
     * Get our storage instance only
     */
    let instance = project.getInstance(Runtime.KEY_STORAGE);

    /**
     * Create our standard generic references (Math components, Numbers, Strings etc)
     */
    await Storage.ProcessGenericReferences(project, instance);

    /**
     * Create and load all the project children
     */
    await Storage.ProcessProjectChildren(project, instance);

    Event.Emit(
      Event.WINDOW_RESIZE
    );
    //CUSTOM_STATIC_LOAD_PROJECT_END

    //GENERATED_STATIC_LOAD_PROJECT_AFTER_START
    //GENERATED_STATIC_LOAD_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_LOAD_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_LOAD_PROJECT_AFTER_END

  }

  /**
   * RemoveProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async RemoveProject(event) {

    //GENERATED_STATIC_REMOVE_PROJECT_START
    //CUSTOM_USER_MODE_REMOVE_PROJECT_INSTANCE_HANDLER_START
    let {object} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('remove', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method remove`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.remove(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_REMOVE_PROJECT_INSTANCE_HANDLER_END
    //GENERATED_STATIC_REMOVE_PROJECT_END

    //CUSTOM_STATIC_REMOVE_PROJECT_START
    //CUSTOM_STATIC_REMOVE_PROJECT_END

    //GENERATED_STATIC_REMOVE_PROJECT_AFTER_START
    //GENERATED_STATIC_REMOVE_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_REMOVE_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_REMOVE_PROJECT_AFTER_END

  }

  /**
   * SaveProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async SaveProject(event) {

    //GENERATED_STATIC_SAVE_PROJECT_START
    //CUSTOM_USER_MODE_SAVE_PROJECT_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_SAVE_PROJECT_INSTANCE_HANDLER_END
    //GENERATED_STATIC_SAVE_PROJECT_END

    //CUSTOM_STATIC_SAVE_PROJECT_START
    let {object: project} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('save', project);
      if (!valid) {
        throw new Error(`${project.uniqueName} failed to validate for method save`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.save(project);
      project.instances[runtime.type].object = instance;
    }

    let instance = project.getInstance(Runtime.KEY_STORAGE);

    await Storage.ProcessGenericReferences(project, instance);

    let children = [...project.children].filter(
      (child) => {
        return (child.mode === Obj.MODE_LIVE)
      }
    )

    for (let child of children) {
      Utils.Status(0, `Saving ${child.name}...`);
      await child.save();
    }

    Utils.Status(0, `Saved ${project.name}.`);

    Event.Emit(
      Event.ADD_PROJECT,
      {
        project
      }
    )
    //CUSTOM_STATIC_SAVE_PROJECT_END

    //GENERATED_STATIC_SAVE_PROJECT_AFTER_START
    //GENERATED_STATIC_SAVE_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_SAVE_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_SAVE_PROJECT_AFTER_END

  }

  /**
   * CloneGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async CloneGroup(event) {

    //GENERATED_STATIC_CLONE_GROUP_START
    //CUSTOM_USER_MODE_CLONE_GROUP_INSTANCE_HANDLER_START
    let {object} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('clone', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method clone`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.clone(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_CLONE_GROUP_INSTANCE_HANDLER_END
    //GENERATED_STATIC_CLONE_GROUP_END

    //CUSTOM_STATIC_CLONE_GROUP_START
    //CUSTOM_STATIC_CLONE_GROUP_END

    //GENERATED_STATIC_CLONE_GROUP_AFTER_START
    //GENERATED_STATIC_CLONE_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_CLONE_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_CLONE_GROUP_AFTER_END

  }

  /**
   * LoadGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async LoadGroup(event) {

    //GENERATED_STATIC_LOAD_GROUP_START
    //CUSTOM_USER_MODE_LOAD_GROUP_INSTANCE_HANDLER_START
    let {object: group} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('load', group);
      if (!valid) {
        throw new Error(`${group.uniqueName} failed to validate for method load`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.load(group);
      delete instance.parents;
      delete instance.children;
      group.instances[runtime.type].object = instance;
    }

    let instance = group.getInstance(Runtime.KEY_STORAGE);

    await Storage.ProcessGenericReferences(group, instance);
    //CUSTOM_USER_MODE_LOAD_GROUP_INSTANCE_HANDLER_END
    //GENERATED_STATIC_LOAD_GROUP_END

    //CUSTOM_STATIC_LOAD_GROUP_START
    //CUSTOM_STATIC_LOAD_GROUP_END

    //GENERATED_STATIC_LOAD_GROUP_AFTER_START
    //GENERATED_STATIC_LOAD_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_LOAD_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_LOAD_GROUP_AFTER_END

  }

  /**
   * RemoveGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async RemoveGroup(event) {

    //GENERATED_STATIC_REMOVE_GROUP_START
    //CUSTOM_USER_MODE_REMOVE_GROUP_INSTANCE_HANDLER_START
    let {object} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('remove', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method remove`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.remove(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_REMOVE_GROUP_INSTANCE_HANDLER_END
    //GENERATED_STATIC_REMOVE_GROUP_END

    //CUSTOM_STATIC_REMOVE_GROUP_START
    //CUSTOM_STATIC_REMOVE_GROUP_END

    //GENERATED_STATIC_REMOVE_GROUP_AFTER_START
    //GENERATED_STATIC_REMOVE_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_REMOVE_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_REMOVE_GROUP_AFTER_END

  }

  /**
   * SaveGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async SaveGroup(event) {

    //GENERATED_STATIC_SAVE_GROUP_START
    //CUSTOM_USER_MODE_SAVE_GROUP_INSTANCE_HANDLER_START
    let {object} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('save', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method save`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.save(object);
      object.instances[runtime.type].object = instance;
    }

    Utils.Status(0, `Saved group ${object.name}`);
    //CUSTOM_USER_MODE_SAVE_GROUP_INSTANCE_HANDLER_END
    //GENERATED_STATIC_SAVE_GROUP_END

    //CUSTOM_STATIC_SAVE_GROUP_START
    //CUSTOM_STATIC_SAVE_GROUP_END

    //GENERATED_STATIC_SAVE_GROUP_AFTER_START
    //GENERATED_STATIC_SAVE_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_SAVE_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_SAVE_GROUP_AFTER_END

  }

  /**
   * CloneFile()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async CloneFile(event) {

    //GENERATED_STATIC_CLONE_FILE_START
    //CUSTOM_USER_MODE_CLONE_FILE_INSTANCE_HANDLER_START
    let {object} = event;
    
    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('clone', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method clone`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.clone(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_CLONE_FILE_INSTANCE_HANDLER_END
    //GENERATED_STATIC_CLONE_FILE_END

    //CUSTOM_STATIC_CLONE_FILE_START
    //CUSTOM_STATIC_CLONE_FILE_END

    //GENERATED_STATIC_CLONE_FILE_AFTER_START
    //GENERATED_STATIC_CLONE_FILE_AFTER_END

    //CUSTOM_STATIC_GENERATED_CLONE_FILE_AFTER_START
    //CUSTOM_STATIC_GENERATED_CLONE_FILE_AFTER_END

  }

  /**
   * LoadFile()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async LoadFile(event) {

    //GENERATED_STATIC_LOAD_FILE_START
    //CUSTOM_USER_MODE_LOAD_FILE_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_LOAD_FILE_INSTANCE_HANDLER_END
    //GENERATED_STATIC_LOAD_FILE_END

    //CUSTOM_STATIC_LOAD_FILE_START
    let {object: file} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('load', file);
      if (!valid) {
        throw new Error(`${file.uniqueName} failed to validate for method load`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.load(file);
      file.instances[runtime.type].object = instance;
    }

    /**
     * Get our storage instance only
     */
    let instance = file.getInstance(Runtime.KEY_STORAGE);

    /**
     * Create our standard generic references (Math components, Numbers, Strings etc)
     */
    await Storage.ProcessGenericReferences(file, instance);
    //CUSTOM_STATIC_LOAD_FILE_END

    //GENERATED_STATIC_LOAD_FILE_AFTER_START
    //GENERATED_STATIC_LOAD_FILE_AFTER_END

    //CUSTOM_STATIC_GENERATED_LOAD_FILE_AFTER_START
    //CUSTOM_STATIC_GENERATED_LOAD_FILE_AFTER_END

  }

  /**
   * RemoveFile()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async RemoveFile(event) {

    //GENERATED_STATIC_REMOVE_FILE_START
    //CUSTOM_USER_MODE_REMOVE_FILE_INSTANCE_HANDLER_START
    let {object} = event;
    
    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('remove', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method remove`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.remove(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_REMOVE_FILE_INSTANCE_HANDLER_END
    //GENERATED_STATIC_REMOVE_FILE_END

    //CUSTOM_STATIC_REMOVE_FILE_START
    //CUSTOM_STATIC_REMOVE_FILE_END

    //GENERATED_STATIC_REMOVE_FILE_AFTER_START
    //GENERATED_STATIC_REMOVE_FILE_AFTER_END

    //CUSTOM_STATIC_GENERATED_REMOVE_FILE_AFTER_START
    //CUSTOM_STATIC_GENERATED_REMOVE_FILE_AFTER_END

  }

  /**
   * SaveFile()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async SaveFile(event) {

    //GENERATED_STATIC_SAVE_FILE_START
    //CUSTOM_USER_MODE_SAVE_FILE_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_SAVE_FILE_INSTANCE_HANDLER_END
    //GENERATED_STATIC_SAVE_FILE_END

    //CUSTOM_STATIC_SAVE_FILE_START
    let {object: file} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('save', file);
      if (!valid) {
        throw new Error(`${file.uniqueName} failed to validate for method save`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.save(file);
      file.instances[runtime.type].object = instance;
    }

    let instance = file.getInstance(Runtime.KEY_STORAGE);

    /**
     * Create our standard generic references (Math components, Numbers, Strings etc)
     */
    await Storage.ProcessGenericReferences(file, instance);

    if (file.fileType === 'blender') {
      await Storage.ProcessBlenderChildren(instance);
    }

    Utils.Status(0, `Saved file ${file.name}`);
    //CUSTOM_STATIC_SAVE_FILE_END

    //GENERATED_STATIC_SAVE_FILE_AFTER_START
    //GENERATED_STATIC_SAVE_FILE_AFTER_END

    //CUSTOM_STATIC_GENERATED_SAVE_FILE_AFTER_START
    //CUSTOM_STATIC_GENERATED_SAVE_FILE_AFTER_END

  }

  /**
   * CloneEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async CloneEntity(event) {

    //GENERATED_STATIC_CLONE_ENTITY_START
    //CUSTOM_USER_MODE_CLONE_ENTITY_INSTANCE_HANDLER_START
    let {object} = event;
    
    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('clone', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method clone`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.clone(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_CLONE_ENTITY_INSTANCE_HANDLER_END
    //GENERATED_STATIC_CLONE_ENTITY_END

    //CUSTOM_STATIC_CLONE_ENTITY_START
    //CUSTOM_STATIC_CLONE_ENTITY_END

    //GENERATED_STATIC_CLONE_ENTITY_AFTER_START
    //GENERATED_STATIC_CLONE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_CLONE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_CLONE_ENTITY_AFTER_END

  }

  /**
   * LoadEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async LoadEntity(event) {

    //GENERATED_STATIC_LOAD_ENTITY_START
    //CUSTOM_USER_MODE_LOAD_ENTITY_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_LOAD_ENTITY_INSTANCE_HANDLER_END
    //GENERATED_STATIC_LOAD_ENTITY_END

    //CUSTOM_STATIC_LOAD_ENTITY_START
    let {object: entity} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('load', entity);
      if (!valid) {
        throw new Error(`${entity.uniqueName} failed to validate for method load`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.load(entity);
      entity.instances[runtime.type].object = instance;
    }

    /**
     * Get our storage instance only
     */
    let instance = entity.getInstance(Runtime.KEY_STORAGE);

    /**
     * Create and load our referenced objects (except Projects and other generic references)
     */
    await Storage.ProcessEntityReferences(entity, instance);

    /**
     * Create our standard generic references (Math components, Numbers, Strings etc)
     */
    await Storage.ProcessGenericReferences(entity, instance);
    //CUSTOM_STATIC_LOAD_ENTITY_END

    //GENERATED_STATIC_LOAD_ENTITY_AFTER_START
    //GENERATED_STATIC_LOAD_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_LOAD_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_LOAD_ENTITY_AFTER_END

  }

  /**
   * RemoveEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async RemoveEntity(event) {

    //GENERATED_STATIC_REMOVE_ENTITY_START
    //CUSTOM_USER_MODE_REMOVE_ENTITY_INSTANCE_HANDLER_START
    let {object} = event;
    
    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('remove', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method remove`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.remove(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_REMOVE_ENTITY_INSTANCE_HANDLER_END
    //GENERATED_STATIC_REMOVE_ENTITY_END

    //CUSTOM_STATIC_REMOVE_ENTITY_START
    //CUSTOM_STATIC_REMOVE_ENTITY_END

    //GENERATED_STATIC_REMOVE_ENTITY_AFTER_START
    //GENERATED_STATIC_REMOVE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_REMOVE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_REMOVE_ENTITY_AFTER_END

  }

  /**
   * SaveEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async SaveEntity(event) {

    //GENERATED_STATIC_SAVE_ENTITY_START
    //CUSTOM_USER_MODE_SAVE_ENTITY_INSTANCE_HANDLER_START
    let {object} = event;
    
    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('save', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method save`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.save(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_SAVE_ENTITY_INSTANCE_HANDLER_END
    //GENERATED_STATIC_SAVE_ENTITY_END

    //CUSTOM_STATIC_SAVE_ENTITY_START
    Utils.Status(0, `Saved entity ${object.name}`);
    //CUSTOM_STATIC_SAVE_ENTITY_END

    //GENERATED_STATIC_SAVE_ENTITY_AFTER_START
    //GENERATED_STATIC_SAVE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_SAVE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_SAVE_ENTITY_AFTER_END

  }

  /**
   * CloneComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async CloneComponent(event) {

    //GENERATED_STATIC_CLONE_COMPONENT_START
    //CUSTOM_USER_MODE_CLONE_COMPONENT_INSTANCE_HANDLER_START
    let {object} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('clone', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method clone`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.clone(object);
      object.instances[runtime.type].object = instance;
    }
    //CUSTOM_USER_MODE_CLONE_COMPONENT_INSTANCE_HANDLER_END
    //GENERATED_STATIC_CLONE_COMPONENT_END

    //CUSTOM_STATIC_CLONE_COMPONENT_START
    //CUSTOM_STATIC_CLONE_COMPONENT_END

    //GENERATED_STATIC_CLONE_COMPONENT_AFTER_START
    //GENERATED_STATIC_CLONE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_CLONE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_CLONE_COMPONENT_AFTER_END

  }

  /**
   * LoadComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async LoadComponent(event) {

    //GENERATED_STATIC_LOAD_COMPONENT_START
    //CUSTOM_USER_MODE_LOAD_COMPONENT_INSTANCE_HANDLER_START
    /**
     * Override
     */
    //CUSTOM_USER_MODE_LOAD_COMPONENT_INSTANCE_HANDLER_END
    //GENERATED_STATIC_LOAD_COMPONENT_END

    //CUSTOM_STATIC_LOAD_COMPONENT_START
    let {object: component} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('load', component);
      if (!valid) {
        throw new Error(`${component.uniqueName} failed to validate for method load`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.load(component);
      component.instances[runtime.type].object = instance;
    }

    let instance = component.getInstance(Runtime.KEY_STORAGE);

    /**
     * Create and load our referenced objects (except Projects and other generic references)
     */
    await Storage.ProcessComponentReferences(component, instance);

    /**
     * Create our standard generic references (Math components, Numbers, Strings etc)
     */
    await Storage.ProcessGenericReferences(component, instance);

    component.exists = instance.exists;

    if (component instanceof Image) {
      Event.Emit(
        Event.IMAGE_LOADED,
        {
          image : component
        }
      );
    }
    //CUSTOM_STATIC_LOAD_COMPONENT_END

    //GENERATED_STATIC_LOAD_COMPONENT_AFTER_START
    //GENERATED_STATIC_LOAD_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_LOAD_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_LOAD_COMPONENT_AFTER_END

  }

  /**
   * RemoveComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async RemoveComponent(event) {

    //GENERATED_STATIC_REMOVE_COMPONENT_START
    //CUSTOM_USER_MODE_REMOVE_COMPONENT_INSTANCE_HANDLER_START
    let {object} = event;

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('remove', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method remove`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      await runtime.remove(object);
      object.setInstance(runtime, null);
    }

    object.exists = false;
    //CUSTOM_USER_MODE_REMOVE_COMPONENT_INSTANCE_HANDLER_END
    //GENERATED_STATIC_REMOVE_COMPONENT_END

    //CUSTOM_STATIC_REMOVE_COMPONENT_START
    //CUSTOM_STATIC_REMOVE_COMPONENT_END

    //GENERATED_STATIC_REMOVE_COMPONENT_AFTER_START
    //GENERATED_STATIC_REMOVE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_REMOVE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_REMOVE_COMPONENT_AFTER_END

  }

  /**
   * SaveComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static async SaveComponent(event) {

    //GENERATED_STATIC_SAVE_COMPONENT_START
    //CUSTOM_USER_MODE_SAVE_COMPONENT_INSTANCE_HANDLER_START
    let {object} = event;

    if (object.mode === Obj.MODE_EDIT) {
      return;
    }

    for (let runtime of Storage.Runtimes) {
      let valid = runtime.validate('save', object);
      if (!valid) {
        throw new Error(`${object.uniqueName} failed to validate for method save`);
      }
    }

    for (let runtime of Storage.Runtimes) {
      let instance = await runtime.save(object);
      object.instances[runtime.type].object = instance;
    }

    Utils.Status(0, `Saved component ${object.name}`);
    //CUSTOM_USER_MODE_SAVE_COMPONENT_INSTANCE_HANDLER_END
    //GENERATED_STATIC_SAVE_COMPONENT_END

    //CUSTOM_STATIC_SAVE_COMPONENT_START
    //CUSTOM_STATIC_SAVE_COMPONENT_END

    //GENERATED_STATIC_SAVE_COMPONENT_AFTER_START
    //GENERATED_STATIC_SAVE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_SAVE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_SAVE_COMPONENT_AFTER_END

  }
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  /**
   * GetRuntimeStorageAxios()
   * - Gets the Axios runtime implementation of R3RuntimeStorage
   * @param {Object} event
   * - No returns
   */
  static GetRuntimeStorageAxios(event) {

    //GENERATED_STATIC_GET_RUNTIME_STORAGE_AXIOS_START
    event.results = [Storage.RuntimeStorageAxios];
    //GENERATED_STATIC_GET_RUNTIME_STORAGE_AXIOS_END

    //CUSTOM_STATIC_GET_RUNTIME_STORAGE_AXIOS_START
    //CUSTOM_STATIC_GET_RUNTIME_STORAGE_AXIOS_END

    //GENERATED_STATIC_GET_RUNTIME_STORAGE_AXIOS_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_STORAGE_AXIOS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_STORAGE_AXIOS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_STORAGE_AXIOS_AFTER_END

  }
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
/**
 * @param Storage.RuntimeStorageAxios
 * - Runtime implementation of R3RuntimeStorage
 */
Storage.RuntimeStorageAxios = new AxiosRuntime();
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Storage.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Storage.IsStarting = false;

/**
 * @param Storage.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Storage.IsStopping = false;

/**
 * @param Storage.Started
 * - Indicates whether this system is running
 */
Storage.Started = false;

/**
 * @param Storage.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Storage.Subscriptions = {};

/**
 * @param Storage.Runtimes
 * - A set of runtimes which the system manages
 */
Storage.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Storage.Groups
 * - No comment
 */
Storage.Groups = {};

/**
 * @param Storage.Users
 * - No comment
 */
Storage.Users = {};

/**
 * @param Storage.Projects
 * - No comment
 */
Storage.Projects = {};

/**
 * @param Storage.Components
 * - No comment
 */
Storage.Components = {};

/**
 * @param Storage.Entities
 * - No comment
 */
Storage.Entities = {};

/**
 * @param Storage.Files
 * - No comment
 */
Storage.Files = {};

/**
 * @param Storage.CurrentUser
 * - No comment
 */
Storage.CurrentUser = null;

/**
 * @param Storage.CurrentGroup
 * - No comment
 */
Storage.CurrentGroup = null;

/**
 * @param Storage.ApiUrl
 * - No comment
 */
Storage.ApiUrl = null;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Storage as default};
