//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as Fixed} from './Fixed.js';
import {default as Dynamic} from './Dynamic.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
//CUSTOM_ASSIGN_TO_BASE_END

//GENERATED_EXPORTS_START
export {
  Fixed,
  Dynamic,
//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
