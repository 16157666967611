//GENERATED_IMPORTS_START

//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START
import {default as TweakPaneRuntime} from '../runtime/gui/TweakPane.js';
//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
import {default as MathsComponent} from '../event/obj/component/Maths.js';
import {default as ImageComponent} from '../event/obj/component/graphics/Image.js';
import Utils from '../Utils.js';
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START
  RuntimeGuiTweakPane = new TweakPaneRuntime() - Runtime implementation of R3RuntimeGui
 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Objects=[] - An array of all objects which require a GUI object
  Selected=[] - An array of all selected meshes
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.INITIALIZE_GROUP(80)
  Event.INITIALIZE_USER(80)
  Event.INITIALIZE_PROJECT(80)
  Event.INITIALIZE_COMPONENT(100)
  Event.INITIALIZE_ENTITY(80)
  Event.DISPOSE_GROUP(80)
  Event.DISPOSE_USER(80)
  Event.DISPOSE_PROJECT(80)
  Event.DISPOSE_COMPONENT(80)
  Event.DISPOSE_ENTITY(80)
  Event.AFTER_ASSIGN_PROPERTY(80)
  Event.MESH_SELECTED(81)
  Event.MESH_DESELECTED(81)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START
  Event.CREATE_GUI_OBJ(80) [R3EventObj]
  Event.DELETE_GUI_OBJ(80) [R3EventObj]
  Event.UPDATE_GUI_OBJ(80) [R3EventObj]
 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  Event.GET_RUNTIME_GUI_TWEAK_PANE - Gets the TweakPane runtime implementation of R3RuntimeGui
 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options) - Starts the system by registering subscriptions to events
  stop(options) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  UpdateChildReferences(object) - Creates or updates the image div
  DeleteImageDiv(component) - Deletes the image div
  BuildSelected(mesh) - Builds guis for parents, mesh and children of mesh
 CUSTOM_STATIC_METHODS_END

 **/

export class Gui extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Gui already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Gui');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Gui already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Gui');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Gui.IsStarting) {
      console.log('client Gui system is already starting...');
      return;
    }

    Gui.IsStarting = true;

    if (Gui.Started === true) {
      Gui.IsStarting = false;
      console.log('client Gui system already started');
      return;
    }

    Gui.Runtimes = new Set();
    Gui.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Gui.Subscriptions['INITIALIZE_GROUP'] = Event.On(
      Event.INITIALIZE_GROUP,
      Gui.InitializeGroup,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['INITIALIZE_USER'] = Event.On(
      Event.INITIALIZE_USER,
      Gui.InitializeUser,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['INITIALIZE_PROJECT'] = Event.On(
      Event.INITIALIZE_PROJECT,
      Gui.InitializeProject,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['INITIALIZE_COMPONENT'] = Event.On(
      Event.INITIALIZE_COMPONENT,
      Gui.InitializeComponent,
      {priority: 100}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['INITIALIZE_ENTITY'] = Event.On(
      Event.INITIALIZE_ENTITY,
      Gui.InitializeEntity,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['DISPOSE_GROUP'] = Event.On(
      Event.DISPOSE_GROUP,
      Gui.DisposeGroup,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['DISPOSE_USER'] = Event.On(
      Event.DISPOSE_USER,
      Gui.DisposeUser,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['DISPOSE_PROJECT'] = Event.On(
      Event.DISPOSE_PROJECT,
      Gui.DisposeProject,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['DISPOSE_COMPONENT'] = Event.On(
      Event.DISPOSE_COMPONENT,
      Gui.DisposeComponent,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['DISPOSE_ENTITY'] = Event.On(
      Event.DISPOSE_ENTITY,
      Gui.DisposeEntity,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['AFTER_ASSIGN_PROPERTY'] = Event.On(
      Event.AFTER_ASSIGN_PROPERTY,
      Gui.AfterAssignProperty,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['MESH_SELECTED'] = Event.On(
      Event.MESH_SELECTED,
      Gui.MeshSelected,
      {priority: 81}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['MESH_DESELECTED'] = Event.On(
      Event.MESH_DESELECTED,
      Gui.MeshDeselected,
      {priority: 81}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Gui.Subscriptions['CREATE_GUI_OBJ'] = Event.On(
      Event.CREATE_GUI_OBJ,
      Gui.CreateGuiObj,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['DELETE_GUI_OBJ'] = Event.On(
      Event.DELETE_GUI_OBJ,
      Gui.DeleteGuiObj,
      {priority: 80}
    );

    /**
     * No comment
     */
    Gui.Subscriptions['UPDATE_GUI_OBJ'] = Event.On(
      Event.UPDATE_GUI_OBJ,
      Gui.UpdateGuiObj,
      {priority: 80}
    );
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    /**
     * Gets the TweakPane runtime implementation of R3RuntimeGui
     */
    Gui.Subscriptions['GET_RUNTIME_GUI_TWEAK_PANE'] = Event.On(
      Event.GET_RUNTIME_GUI_TWEAK_PANE,
      Gui.GetRuntimeGuiTweakPane
    );
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    Gui.Objects = Utils.GetObjects();

    if (Gui.Selected.length) {
      /**
       * If we have selected meshes, build the GUI for those objects only
       */
      for (let selectedMesh of Gui.Selected) {
        Gui.BuildSelected(selectedMesh);
      }
    } else {
      /**
       * If we have no selected meshes, build the GUI for all objects
       */
      for (let object of Gui.Objects) {
        object.createGui();
      }
    }

    Gui.Started = true;
    Gui.IsStarting = false;

    Event.Emit(Event.GUI_SYSTEM_STARTED);

    console.log('Started client system Gui');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Gui.IsStopping) {
      console.log('client Gui system is already stopping');
      return;
    }

    Gui.IsStopping = true;

    if (Gui.Started === false) {
      Gui.IsStopping = false;
      console.log('client Gui system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    Gui.Subscriptions['INITIALIZE_GROUP'].remove();
    delete Gui.Subscriptions['INITIALIZE_GROUP'];

    Gui.Subscriptions['INITIALIZE_USER'].remove();
    delete Gui.Subscriptions['INITIALIZE_USER'];

    Gui.Subscriptions['INITIALIZE_PROJECT'].remove();
    delete Gui.Subscriptions['INITIALIZE_PROJECT'];

    Gui.Subscriptions['INITIALIZE_COMPONENT'].remove();
    delete Gui.Subscriptions['INITIALIZE_COMPONENT'];

    Gui.Subscriptions['INITIALIZE_ENTITY'].remove();
    delete Gui.Subscriptions['INITIALIZE_ENTITY'];

    Gui.Subscriptions['DISPOSE_GROUP'].remove();
    delete Gui.Subscriptions['DISPOSE_GROUP'];

    Gui.Subscriptions['DISPOSE_USER'].remove();
    delete Gui.Subscriptions['DISPOSE_USER'];

    Gui.Subscriptions['DISPOSE_PROJECT'].remove();
    delete Gui.Subscriptions['DISPOSE_PROJECT'];

    Gui.Subscriptions['DISPOSE_COMPONENT'].remove();
    delete Gui.Subscriptions['DISPOSE_COMPONENT'];

    Gui.Subscriptions['DISPOSE_ENTITY'].remove();
    delete Gui.Subscriptions['DISPOSE_ENTITY'];

    Gui.Subscriptions['AFTER_ASSIGN_PROPERTY'].remove();
    delete Gui.Subscriptions['AFTER_ASSIGN_PROPERTY'];

    Gui.Subscriptions['MESH_SELECTED'].remove();
    delete Gui.Subscriptions['MESH_SELECTED'];

    Gui.Subscriptions['MESH_DESELECTED'].remove();
    delete Gui.Subscriptions['MESH_DESELECTED'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    Gui.Subscriptions['CREATE_GUI_OBJ'].remove();
    delete Gui.Subscriptions['CREATE_GUI_OBJ'];

    Gui.Subscriptions['DELETE_GUI_OBJ'].remove();
    delete Gui.Subscriptions['DELETE_GUI_OBJ'];

    Gui.Subscriptions['UPDATE_GUI_OBJ'].remove();
    delete Gui.Subscriptions['UPDATE_GUI_OBJ'];
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    Gui.Subscriptions['GET_RUNTIME_GUI_TWEAK_PANE'].remove();
    delete Gui.Subscriptions['GET_RUNTIME_GUI_TWEAK_PANE'];
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    for (let object of Gui.Objects) {

      for (let runtime of [...Gui.Runtimes]) {
        runtime.deleteGui(object);
        object.setInstance(runtime, null);
      }

      // Gui.DeleteImageDiv(object);
    }

    Gui.Objects = [];

    Gui.Runtimes.clear();
    Gui.Started = false;
    Gui.IsStopping = false;

    Event.Emit(Event.GUI_SYSTEM_STOPPED);

    console.log('Stopped client system Gui');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START
    Gui.Runtimes.add(Gui.RuntimeGuiTweakPane);
    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * UpdateChildReferences()
   * - Creates or updates the image div
   * @param object
   * - No returns
   */
  static UpdateChildReferences(object) {

    //GENERATED_STATIC_UPDATE_CHILD_REFERENCES_START
    //GENERATED_STATIC_UPDATE_CHILD_REFERENCES_END

    //CUSTOM_STATIC_UPDATE_CHILD_REFERENCES_START
    for (let _object of Gui.Objects) {

      if (_object === object) {
        continue;
      }

      for (let [property, reference] of Object.entries(_object.references)) {
        let {Constructor} = reference;
        if (Constructor instanceof Array) {
          Constructor = Constructor[0];
        }
        if (object instanceof Constructor) {
          _object.updateGui(property, _object[property]);
        }
      }
    }
    //CUSTOM_STATIC_UPDATE_CHILD_REFERENCES_END

    //GENERATED_STATIC_UPDATE_CHILD_REFERENCES_AFTER_START
    //GENERATED_STATIC_UPDATE_CHILD_REFERENCES_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPDATE_CHILD_REFERENCES_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPDATE_CHILD_REFERENCES_AFTER_END

  }
  /**
   * DeleteImageDiv()
   * - Deletes the image div
   * @param component
   * - No returns
   */
  static DeleteImageDiv(component) {

    //GENERATED_STATIC_DELETE_IMAGE_DIV_START
    //GENERATED_STATIC_DELETE_IMAGE_DIV_END

    //CUSTOM_STATIC_DELETE_IMAGE_DIV_START
    if (!(component instanceof ImageComponent)) {
      return;
    }

    let canvas = document.getElementById(`canvas_image_${component.id}`);
    if (canvas) {
      canvas.parentElement.removeChild(canvas);
    }
    //CUSTOM_STATIC_DELETE_IMAGE_DIV_END

    //GENERATED_STATIC_DELETE_IMAGE_DIV_AFTER_START
    //GENERATED_STATIC_DELETE_IMAGE_DIV_AFTER_END

    //CUSTOM_STATIC_GENERATED_DELETE_IMAGE_DIV_AFTER_START
    //CUSTOM_STATIC_GENERATED_DELETE_IMAGE_DIV_AFTER_END

  }
  /**
   * BuildSelected()
   * - Builds guis for parents, mesh and children of mesh
   * @param mesh
   * - No returns
   */
  static BuildSelected(mesh) {

    //GENERATED_STATIC_BUILD_SELECTED_START
    //GENERATED_STATIC_BUILD_SELECTED_END

    //CUSTOM_STATIC_BUILD_SELECTED_START
    let parents = Utils.GetParents(mesh);

    for (let parent of parents) {

      if (parent instanceof MathsComponent) {
        continue;
      }

      parent.createGui();
    }

    mesh.createGui();

    let children = Utils.GetChildren(mesh);

    for (let child of children) {

      if (child instanceof MathsComponent) {
        continue;
      }

      child.createGui();
    }
    //CUSTOM_STATIC_BUILD_SELECTED_END

    //GENERATED_STATIC_BUILD_SELECTED_AFTER_START
    //GENERATED_STATIC_BUILD_SELECTED_AFTER_END

    //CUSTOM_STATIC_GENERATED_BUILD_SELECTED_AFTER_START
    //CUSTOM_STATIC_GENERATED_BUILD_SELECTED_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * InitializeGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeGroup(event) {

    //GENERATED_STATIC_INITIALIZE_GROUP_START
    //GENERATED_STATIC_INITIALIZE_GROUP_END

    //CUSTOM_STATIC_INITIALIZE_GROUP_START
    let {object : group} = event;
    Gui.Objects.push(group);
    group.createGui();

    Gui.UpdateChildReferences(group);
    //CUSTOM_STATIC_INITIALIZE_GROUP_END

    //GENERATED_STATIC_INITIALIZE_GROUP_AFTER_START
    //GENERATED_STATIC_INITIALIZE_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_GROUP_AFTER_END

  }

  /**
   * InitializeUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeUser(event) {

    //GENERATED_STATIC_INITIALIZE_USER_START
    //GENERATED_STATIC_INITIALIZE_USER_END

    //CUSTOM_STATIC_INITIALIZE_USER_START
    let {object : user} = event;
    Gui.Objects.push(user);
    user.createGui();

    Gui.UpdateChildReferences(user);
    //CUSTOM_STATIC_INITIALIZE_USER_END

    //GENERATED_STATIC_INITIALIZE_USER_AFTER_START
    //GENERATED_STATIC_INITIALIZE_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_USER_AFTER_END

  }

  /**
   * InitializeProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeProject(event) {

    //GENERATED_STATIC_INITIALIZE_PROJECT_START
    //GENERATED_STATIC_INITIALIZE_PROJECT_END

    //CUSTOM_STATIC_INITIALIZE_PROJECT_START
    let {object : project} = event;
    Gui.Objects.push(project);
    project.createGui();

    Gui.UpdateChildReferences(project);
    //CUSTOM_STATIC_INITIALIZE_PROJECT_END

    //GENERATED_STATIC_INITIALIZE_PROJECT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_PROJECT_AFTER_END

  }

  /**
   * InitializeComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeComponent(event) {

    //GENERATED_STATIC_INITIALIZE_COMPONENT_START
    //GENERATED_STATIC_INITIALIZE_COMPONENT_END

    //CUSTOM_STATIC_INITIALIZE_COMPONENT_START
    let {object : component} = event;

    if (component instanceof MathsComponent) {
      return;
    }

    Gui.Objects.push(component);

    component.createGui();

    Gui.UpdateChildReferences(component);
    //CUSTOM_STATIC_INITIALIZE_COMPONENT_END

    //GENERATED_STATIC_INITIALIZE_COMPONENT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_COMPONENT_AFTER_END

  }

  /**
   * InitializeEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeEntity(event) {

    //GENERATED_STATIC_INITIALIZE_ENTITY_START
    //GENERATED_STATIC_INITIALIZE_ENTITY_END

    //CUSTOM_STATIC_INITIALIZE_ENTITY_START
    let {object : entity} = event;
    Gui.Objects.push(entity);
    entity.createGui();

    Gui.UpdateChildReferences(entity);
    //CUSTOM_STATIC_INITIALIZE_ENTITY_END

    //GENERATED_STATIC_INITIALIZE_ENTITY_AFTER_START
    //GENERATED_STATIC_INITIALIZE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_ENTITY_AFTER_END

  }

  /**
   * DisposeGroup()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeGroup(event) {

    //GENERATED_STATIC_DISPOSE_GROUP_START
    //GENERATED_STATIC_DISPOSE_GROUP_END

    //CUSTOM_STATIC_DISPOSE_GROUP_START
    let {object : group} = event;
    Gui.Objects = Gui.Objects.filter((item) => {return item !== group});
    group.deleteGui();
    //CUSTOM_STATIC_DISPOSE_GROUP_END

    //GENERATED_STATIC_DISPOSE_GROUP_AFTER_START
    //GENERATED_STATIC_DISPOSE_GROUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_GROUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_GROUP_AFTER_END

  }

  /**
   * DisposeUser()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeUser(event) {

    //GENERATED_STATIC_DISPOSE_USER_START
    //GENERATED_STATIC_DISPOSE_USER_END

    //CUSTOM_STATIC_DISPOSE_USER_START
    let {object : user} = event;
    Gui.Objects = Gui.Objects.filter((item) => {return item !== user});
    user.deleteGui();
    //CUSTOM_STATIC_DISPOSE_USER_END

    //GENERATED_STATIC_DISPOSE_USER_AFTER_START
    //GENERATED_STATIC_DISPOSE_USER_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_USER_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_USER_AFTER_END

  }

  /**
   * DisposeProject()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeProject(event) {

    //GENERATED_STATIC_DISPOSE_PROJECT_START
    //GENERATED_STATIC_DISPOSE_PROJECT_END

    //CUSTOM_STATIC_DISPOSE_PROJECT_START
    let {object : project} = event;
    Gui.Objects = Gui.Objects.filter((item) => {return item !== project});
    project.deleteGui();
    //CUSTOM_STATIC_DISPOSE_PROJECT_END

    //GENERATED_STATIC_DISPOSE_PROJECT_AFTER_START
    //GENERATED_STATIC_DISPOSE_PROJECT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_PROJECT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_PROJECT_AFTER_END

  }

  /**
   * DisposeComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeComponent(event) {

    //GENERATED_STATIC_DISPOSE_COMPONENT_START
    //GENERATED_STATIC_DISPOSE_COMPONENT_END

    //CUSTOM_STATIC_DISPOSE_COMPONENT_START
    let {object : component} = event;

    if (component instanceof MathsComponent) {
      return;
    }

    Gui.Objects = Gui.Objects.filter((item) => {return item !== component});

    component.deleteGui();

    // Gui.DeleteImageDiv(component);
    //CUSTOM_STATIC_DISPOSE_COMPONENT_END

    //GENERATED_STATIC_DISPOSE_COMPONENT_AFTER_START
    //GENERATED_STATIC_DISPOSE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_COMPONENT_AFTER_END

  }

  /**
   * DisposeEntity()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeEntity(event) {

    //GENERATED_STATIC_DISPOSE_ENTITY_START
    //GENERATED_STATIC_DISPOSE_ENTITY_END

    //CUSTOM_STATIC_DISPOSE_ENTITY_START
    let {object : entity} = event;
    Gui.Objects = Gui.Objects.filter((item) => {return item !== entity});
    entity.deleteGui();
    //CUSTOM_STATIC_DISPOSE_ENTITY_END

    //GENERATED_STATIC_DISPOSE_ENTITY_AFTER_START
    //GENERATED_STATIC_DISPOSE_ENTITY_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_ENTITY_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_ENTITY_AFTER_END

  }

  /**
   * AfterAssignProperty()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static AfterAssignProperty(event) {

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    /**
     * Override
     */
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_START
    let {property, value} = event;

    if (value === null) {
      return;
    }

    let {object: component} = event;

    for (let runtime of [...Gui.Runtimes]) {

      let instance = component.getInstance(runtime);

      if (instance) {
        runtime.updateGui(component, property, value);
      }

      for (let child of component.children) {

        let instance = child.getInstance(runtime);

        if (!instance) {
          continue;
        }

        for (let ref of Object.keys(child.references)) {
          if (child[ref] === component) {
            runtime.updateGui(child, ref, component);
          }
        }
      }
    }

    if (property === 'name') {

      for (let object of Gui.Objects) {

        if (object === component) {
          continue;
        }

        for (let [property, reference] of Object.entries(object.references)) {

          let {Constructor} = reference;
          if (Constructor instanceof Array) {
            Constructor = Constructor[0];
          }

          if (component instanceof Constructor) {
            object.updateGui(property, object[property]);
          }
        }

      }
      return;
    }

    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_END

  }

  /**
   * MeshSelected()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static MeshSelected(event) {

    //GENERATED_STATIC_MESH_SELECTED_START
    //GENERATED_STATIC_MESH_SELECTED_END

    //CUSTOM_STATIC_MESH_SELECTED_START
    let {mesh} = event;
    Gui.Selected = Gui.Selected.filter((selected) => {return (selected !== mesh)});
    Gui.Selected.push(mesh);

    for (let object of Gui.Objects) {
      object.deleteGui();
    }

    for (let selectedMesh of Gui.Selected) {
      Gui.BuildSelected(selectedMesh);
    }
    //CUSTOM_STATIC_MESH_SELECTED_END

    //GENERATED_STATIC_MESH_SELECTED_AFTER_START
    //GENERATED_STATIC_MESH_SELECTED_AFTER_END

    //CUSTOM_STATIC_GENERATED_MESH_SELECTED_AFTER_START
    //CUSTOM_STATIC_GENERATED_MESH_SELECTED_AFTER_END

  }

  /**
   * MeshDeselected()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static MeshDeselected(event) {

    //GENERATED_STATIC_MESH_DESELECTED_START
    //GENERATED_STATIC_MESH_DESELECTED_END

    //CUSTOM_STATIC_MESH_DESELECTED_START
    for (let object of Gui.Objects) {
      object.deleteGui();
    }

    let {mesh} = event;
    Gui.Selected = Gui.Selected.filter((selected) => {return (selected !== mesh)});

    if (Gui.Selected.length) {
      /**
       * If we have selected meshes, build the GUI for those objects only
       */
      for (let selectedMesh of Gui.Selected) {
        Gui.BuildSelected(selectedMesh);
      }
    } else {
      /**
       * If we have no selected meshes, build the GUI for all objects
       */
      for (let object of Gui.Objects) {
        object.createGui();
      }

    }
    //CUSTOM_STATIC_MESH_DESELECTED_END

    //GENERATED_STATIC_MESH_DESELECTED_AFTER_START
    //GENERATED_STATIC_MESH_DESELECTED_AFTER_END

    //CUSTOM_STATIC_GENERATED_MESH_DESELECTED_AFTER_START
    //CUSTOM_STATIC_GENERATED_MESH_DESELECTED_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  /**
   * CreateGuiObj()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static CreateGuiObj(event) {

    //GENERATED_STATIC_CREATE_GUI_OBJ_START
    //CUSTOM_USER_MODE_CREATE_GUI_OBJ_INSTANCE_HANDLER_START
    let {object} = event;

    for (let runtime of Gui.Runtimes) {

      let instance = object.getInstance(runtime);

      if (instance) {
        continue;
      }

      instance = runtime.createGui(object);

      if (!instance) {
        throw new Error(`No valid GUI instance was created for ${object.uniqueName} (${object.id})`);
      }

      object.setInstance(runtime, instance);
    }

    //CUSTOM_USER_MODE_CREATE_GUI_OBJ_INSTANCE_HANDLER_END
    //GENERATED_STATIC_CREATE_GUI_OBJ_END

    //CUSTOM_STATIC_CREATE_GUI_OBJ_START
    //CUSTOM_STATIC_CREATE_GUI_OBJ_END

    //GENERATED_STATIC_CREATE_GUI_OBJ_AFTER_START
    //GENERATED_STATIC_CREATE_GUI_OBJ_AFTER_END

    //CUSTOM_STATIC_GENERATED_CREATE_GUI_OBJ_AFTER_START
    //CUSTOM_STATIC_GENERATED_CREATE_GUI_OBJ_AFTER_END

  }

  /**
   * DeleteGuiObj()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DeleteGuiObj(event) {

    //GENERATED_STATIC_DELETE_GUI_OBJ_START
    //CUSTOM_USER_MODE_DELETE_GUI_OBJ_INSTANCE_HANDLER_START
    let {object} = event;
    
    for (let runtime of Gui.Runtimes) {

      let instance = object.getInstance(runtime);

      if (!instance) {
        continue;
      }

      runtime.deleteGui(object);

      object.setInstance(runtime, null);
    }

    // Gui.DeleteImageDiv(object);
    //CUSTOM_USER_MODE_DELETE_GUI_OBJ_INSTANCE_HANDLER_END
    //GENERATED_STATIC_DELETE_GUI_OBJ_END

    //CUSTOM_STATIC_DELETE_GUI_OBJ_START
    //CUSTOM_STATIC_DELETE_GUI_OBJ_END

    //GENERATED_STATIC_DELETE_GUI_OBJ_AFTER_START
    //GENERATED_STATIC_DELETE_GUI_OBJ_AFTER_END

    //CUSTOM_STATIC_GENERATED_DELETE_GUI_OBJ_AFTER_START
    //CUSTOM_STATIC_GENERATED_DELETE_GUI_OBJ_AFTER_END

  }

  /**
   * UpdateGuiObj()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static UpdateGuiObj(event) {

    //GENERATED_STATIC_UPDATE_GUI_OBJ_START
    //CUSTOM_USER_MODE_UPDATE_GUI_OBJ_INSTANCE_HANDLER_START
    let {object} = event;
    
    let {options} = event;
    let {property, value} = options;

    for (let runtime of Gui.Runtimes) {
      let instance = object.getInstance(runtime);
      if (!instance) {
        continue;
      }
      runtime.updateGui(object, property, value);
    }
    //CUSTOM_USER_MODE_UPDATE_GUI_OBJ_INSTANCE_HANDLER_END
    //GENERATED_STATIC_UPDATE_GUI_OBJ_END

    //CUSTOM_STATIC_UPDATE_GUI_OBJ_START
    //CUSTOM_STATIC_UPDATE_GUI_OBJ_END

    //GENERATED_STATIC_UPDATE_GUI_OBJ_AFTER_START
    //GENERATED_STATIC_UPDATE_GUI_OBJ_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPDATE_GUI_OBJ_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPDATE_GUI_OBJ_AFTER_END

  }
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  /**
   * GetRuntimeGuiTweakPane()
   * - Gets the TweakPane runtime implementation of R3RuntimeGui
   * @param {Object} event
   * - No returns
   */
  static GetRuntimeGuiTweakPane(event) {

    //GENERATED_STATIC_GET_RUNTIME_GUI_TWEAK_PANE_START
    event.results = [Gui.RuntimeGuiTweakPane];
    //GENERATED_STATIC_GET_RUNTIME_GUI_TWEAK_PANE_END

    //CUSTOM_STATIC_GET_RUNTIME_GUI_TWEAK_PANE_START
    //CUSTOM_STATIC_GET_RUNTIME_GUI_TWEAK_PANE_END

    //GENERATED_STATIC_GET_RUNTIME_GUI_TWEAK_PANE_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_GUI_TWEAK_PANE_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_GUI_TWEAK_PANE_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_GUI_TWEAK_PANE_AFTER_END

  }
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
/**
 * @param Gui.RuntimeGuiTweakPane
 * - Runtime implementation of R3RuntimeGui
 */
Gui.RuntimeGuiTweakPane = new TweakPaneRuntime();
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Gui.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Gui.IsStarting = false;

/**
 * @param Gui.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Gui.IsStopping = false;

/**
 * @param Gui.Started
 * - Indicates whether this system is running
 */
Gui.Started = false;

/**
 * @param Gui.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Gui.Subscriptions = {};

/**
 * @param Gui.Runtimes
 * - A set of runtimes which the system manages
 */
Gui.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Gui.Objects
 * - An array of all objects which require a GUI object
 */
Gui.Objects = [];

/**
 * @param Gui.Selected
 * - An array of all selected meshes
 */
Gui.Selected = [];
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Gui as default};
