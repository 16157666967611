//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
import * as textureImport from './texture/index.js';
import * as shadowImport from './shadow/index.js';
import * as rendererImport from './renderer/index.js';
import * as materialImport from './material/index.js';
import * as lightImport from './light/index.js';
import * as geometryImport from './geometry/index.js';
import * as cameraImport from './camera/index.js';
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as Video} from './Video.js';
import {default as Texture} from './Texture.js';
import {default as Sprite} from './Sprite.js';
import {default as Skeleton} from './Skeleton.js';
import {default as Shadow} from './Shadow.js';
import {default as Shader} from './Shader.js';
import {default as Scene} from './Scene.js';
import {default as Renderer} from './Renderer.js';
import {default as Raycaster} from './Raycaster.js';
import {default as Mesh} from './Mesh.js';
import {default as MaterialGroup} from './MaterialGroup.js';
import {default as Material} from './Material.js';
import {default as Light} from './Light.js';
import {default as Image} from './Image.js';
import {default as Geometry} from './Geometry.js';
import {default as Fog} from './Fog.js';
import {default as Canvas} from './Canvas.js';
import {default as Camera} from './Camera.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
const VideoTexture = textureImport.Video;
const ImageTexture = textureImport.Image;
const CubeTexture = textureImport.Cube;
const CanvasTexture = textureImport.Canvas;
const SpotShadow = shadowImport.Spot;
const PointShadow = shadowImport.Point;
const DirectionalShadow = shadowImport.Directional;
const Target = rendererImport.Target;
const Gl = rendererImport.Gl;
const CubeRenderer = rendererImport.Cube;
const Toon = materialImport.Toon;
const Standard = materialImport.Standard;
const Points = materialImport.Points;
const Phong = materialImport.Phong;
const Lambert = materialImport.Lambert;
const Basic = materialImport.Basic;
const SpotLight = lightImport.Spot;
const PointLight = lightImport.Point;
const DirectionalLight = lightImport.Directional;
const Ambient = lightImport.Ambient;
const Torus = geometryImport.Torus;
const SphereGeometry = geometryImport.Sphere;
const PlaneGeometry = geometryImport.Plane;
const Buffer = geometryImport.Buffer;
const Box = geometryImport.Box;
const Perspective = cameraImport.Perspective;
const Orthographic = cameraImport.Orthographic;
const CubeCamera = cameraImport.Cube;
Texture.Video = VideoTexture;
Texture.Image = ImageTexture;
Texture.Cube = CubeTexture;
Texture.Canvas = CanvasTexture;
Shadow.Spot = SpotShadow;
Shadow.Point = PointShadow;
Shadow.Directional = DirectionalShadow;
Renderer.Target = Target;
Renderer.Gl = Gl;
Renderer.Cube = CubeRenderer;
Material.Toon = Toon;
Material.Standard = Standard;
Material.Points = Points;
Material.Phong = Phong;
Material.Lambert = Lambert;
Material.Basic = Basic;
Light.Spot = SpotLight;
Light.Point = PointLight;
Light.Directional = DirectionalLight;
Light.Ambient = Ambient;
Geometry.Torus = Torus;
Geometry.Sphere = SphereGeometry;
Geometry.Plane = PlaneGeometry;
Geometry.Buffer = Buffer;
Geometry.Box = Box;
Camera.Perspective = Perspective;
Camera.Orthographic = Orthographic;
Camera.Cube = CubeCamera;
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
//CUSTOM_ASSIGN_TO_BASE_END

//GENERATED_EXPORTS_START
export {
  Video,
  Texture,
  Sprite,
  Skeleton,
  Shadow,
  Shader,
  Scene,
  Renderer,
  Raycaster,
  Mesh,
  MaterialGroup,
  Material,
  Light,
  Image,
  Geometry,
  Fog,
  Canvas,
  Camera,
  VideoTexture,
  ImageTexture,
  CubeTexture,
  CanvasTexture,
  SpotShadow,
  PointShadow,
  DirectionalShadow,
  Target,
  Gl,
  CubeRenderer,
  Toon,
  Standard,
  Points,
  Phong,
  Lambert,
  Basic,
  SpotLight,
  PointLight,
  DirectionalLight,
  Ambient,
  Torus,
  SphereGeometry,
  PlaneGeometry,
  Buffer,
  Box,
  Perspective,
  Orthographic,
  CubeCamera,
//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
