//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Runtime from '../Runtime.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  containerId = 'gui-items'
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  createGui(object) - Creates a gui runtime instance for this runtime based on the R3 Object
  deleteGui(object) - Deletes a gui runtime instance for this runtime based on the R3 Object
  updateGui(object, property, value) - Updates the gui runtime instance of the R3 Object based on the options
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  createGui(object) - Creates a gui runtime instance for this runtime based on the R3 Object 
  deleteGui(object) - Deletes a gui runtime instance for this runtime based on the R3 Object 
  updateGui(object, property, value) - Updates the gui runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Gui extends Runtime {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeGui';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeGui';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'GuiRuntime';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_GUI;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param containerId
     * - items'
     */
    if (typeof options.containerId === 'undefined') {
      options.containerId = 'gui-items';
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * createGui()
   * - Creates a gui runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  createGui(object) {

    //CUSTOM_CREATE_GUI_BEFORE_START
    //CUSTOM_CREATE_GUI_BEFORE_END

    //GENERATED_CREATE_GUI_BEFORE_START
    //GENERATED_CREATE_GUI_BEFORE_END

    //CUSTOM_CREATE_GUI_BEFORE_GENERATED_START
    //CUSTOM_CREATE_GUI_BEFORE_GENERATED_END

    //GENERATED_CREATE_GUI_START
    //GENERATED_CREATE_GUI_END

    //CUSTOM_CREATE_GUI_START
    //CUSTOM_CREATE_GUI_END

    //GENERATED_CREATE_GUI_AFTER_START
    //GENERATED_CREATE_GUI_AFTER_END

  }

  /**
   * deleteGui()
   * - Deletes a gui runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  deleteGui(object) {

    //CUSTOM_DELETE_GUI_BEFORE_START
    //CUSTOM_DELETE_GUI_BEFORE_END

    //GENERATED_DELETE_GUI_BEFORE_START
    //GENERATED_DELETE_GUI_BEFORE_END

    //CUSTOM_DELETE_GUI_BEFORE_GENERATED_START
    //CUSTOM_DELETE_GUI_BEFORE_GENERATED_END

    //GENERATED_DELETE_GUI_START
    //GENERATED_DELETE_GUI_END

    //CUSTOM_DELETE_GUI_START
    //CUSTOM_DELETE_GUI_END

    //GENERATED_DELETE_GUI_AFTER_START
    //GENERATED_DELETE_GUI_AFTER_END

  }

  /**
   * updateGui()
   * - Updates the gui runtime instance of the R3 Object based on the options
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateGui(object, property, value) {

    //CUSTOM_UPDATE_GUI_BEFORE_START
    //CUSTOM_UPDATE_GUI_BEFORE_END

    //GENERATED_UPDATE_GUI_BEFORE_START
    //GENERATED_UPDATE_GUI_BEFORE_END

    //CUSTOM_UPDATE_GUI_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_GUI_BEFORE_GENERATED_END

    //GENERATED_UPDATE_GUI_START
    //GENERATED_UPDATE_GUI_END

    //CUSTOM_UPDATE_GUI_START
    //CUSTOM_UPDATE_GUI_END

    //GENERATED_UPDATE_GUI_AFTER_START
    //GENERATED_UPDATE_GUI_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Gui.Type = 'R3RuntimeGui';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Gui as default};
