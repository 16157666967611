//GENERATED_IMPORTS_START
import {default as MathsComponent} from '../event/obj/component/Maths.js';
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START
import {default as ThreeRuntime} from '../runtime/maths/Three.js';
//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START
  RuntimeMathsThree = new ThreeRuntime() - Runtime implementation of R3RuntimeMaths
 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.INITIALIZE_MATHS_COMPONENT(40)
  Event.DISPOSE_MATHS_COMPONENT(60)
  Event.AFTER_ASSIGN_PROPERTY(10)
  Event.CREATE_INSTANCE(20)
  Event.UPDATE_INSTANCE(20)
  Event.DELETE_INSTANCE(30)
  Event.CLONE_INSTANCE(30)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START

 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  Event.GET_RUNTIME_MATHS_THREE - Gets the Three runtime implementation of R3RuntimeMaths
 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options = {}) - Starts the system by registering subscriptions to events
  stop(options = {}) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Maths extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Maths already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Maths');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Maths already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Maths');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Maths.IsStarting) {
      console.log('client Maths system is already starting...');
      return;
    }

    Maths.IsStarting = true;

    if (Maths.Started === true) {
      Maths.IsStarting = false;
      console.log('client Maths system already started');
      return;
    }

    Maths.Runtimes = new Set();
    Maths.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Maths.Subscriptions['INITIALIZE_MATHS_COMPONENT'] = Event.On(
      Event.INITIALIZE_MATHS_COMPONENT,
      Maths.InitializeMathsComponent,
      {priority: 40}
    );

    /**
     * No comment
     */
    Maths.Subscriptions['DISPOSE_MATHS_COMPONENT'] = Event.On(
      Event.DISPOSE_MATHS_COMPONENT,
      Maths.DisposeMathsComponent,
      {priority: 60}
    );

    /**
     * No comment
     */
    Maths.Subscriptions['AFTER_ASSIGN_PROPERTY'] = Event.On(
      Event.AFTER_ASSIGN_PROPERTY,
      Maths.AfterAssignProperty,
      {priority: 10}
    );

    /**
     * No comment
     */
    Maths.Subscriptions['CREATE_INSTANCE'] = Event.On(
      Event.CREATE_INSTANCE,
      Maths.CreateInstance,
      {priority: 20}
    );

    /**
     * No comment
     */
    Maths.Subscriptions['UPDATE_INSTANCE'] = Event.On(
      Event.UPDATE_INSTANCE,
      Maths.UpdateInstance,
      {priority: 20}
    );

    /**
     * No comment
     */
    Maths.Subscriptions['DELETE_INSTANCE'] = Event.On(
      Event.DELETE_INSTANCE,
      Maths.DeleteInstance,
      {priority: 30}
    );

    /**
     * No comment
     */
    Maths.Subscriptions['CLONE_INSTANCE'] = Event.On(
      Event.CLONE_INSTANCE,
      Maths.CloneInstance,
      {priority: 30}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    /**
     * Gets the Three runtime implementation of R3RuntimeMaths
     */
    Maths.Subscriptions['GET_RUNTIME_MATHS_THREE'] = Event.On(
      Event.GET_RUNTIME_MATHS_THREE,
      Maths.GetRuntimeMathsThree
    );
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    Maths.Started = true;
    Maths.IsStarting = false;
    console.log('Started client system Maths');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Maths.IsStopping) {
      console.log('client Maths system is already stopping');
      return;
    }

    Maths.IsStopping = true;

    if (Maths.Started === false) {
      Maths.IsStopping = false;
      console.log('client Maths system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    Maths.Subscriptions['INITIALIZE_MATHS_COMPONENT'].remove();
    delete Maths.Subscriptions['INITIALIZE_MATHS_COMPONENT'];

    Maths.Subscriptions['DISPOSE_MATHS_COMPONENT'].remove();
    delete Maths.Subscriptions['DISPOSE_MATHS_COMPONENT'];

    Maths.Subscriptions['AFTER_ASSIGN_PROPERTY'].remove();
    delete Maths.Subscriptions['AFTER_ASSIGN_PROPERTY'];

    Maths.Subscriptions['CREATE_INSTANCE'].remove();
    delete Maths.Subscriptions['CREATE_INSTANCE'];

    Maths.Subscriptions['UPDATE_INSTANCE'].remove();
    delete Maths.Subscriptions['UPDATE_INSTANCE'];

    Maths.Subscriptions['DELETE_INSTANCE'].remove();
    delete Maths.Subscriptions['DELETE_INSTANCE'];

    Maths.Subscriptions['CLONE_INSTANCE'].remove();
    delete Maths.Subscriptions['CLONE_INSTANCE'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    Maths.Subscriptions['GET_RUNTIME_MATHS_THREE'].remove();
    delete Maths.Subscriptions['GET_RUNTIME_MATHS_THREE'];
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    Maths.Runtimes.clear();
    Maths.Started = false;
    Maths.IsStopping = false;
    console.log('Stopped client system Maths');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START
    Maths.Runtimes.add(Maths.RuntimeMathsThree);
    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * InitializeMathsComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeMathsComponent(event) {

    //GENERATED_STATIC_INITIALIZE_MATHS_COMPONENT_START
    //GENERATED_STATIC_INITIALIZE_MATHS_COMPONENT_END

    //CUSTOM_STATIC_INITIALIZE_MATHS_COMPONENT_START
    let {object: component} = event;
    component.createInstance();
    //CUSTOM_STATIC_INITIALIZE_MATHS_COMPONENT_END

    //GENERATED_STATIC_INITIALIZE_MATHS_COMPONENT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_MATHS_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_MATHS_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_MATHS_COMPONENT_AFTER_END

  }

  /**
   * DisposeMathsComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeMathsComponent(event) {

    //GENERATED_STATIC_DISPOSE_MATHS_COMPONENT_START
    //GENERATED_STATIC_DISPOSE_MATHS_COMPONENT_END

    //CUSTOM_STATIC_DISPOSE_MATHS_COMPONENT_START
    let {object: component} = event;

    for (let runtime of [...Maths.Runtimes]) {
      System.DeleteRuntimeInstance(component, runtime, MathsComponent);
      delete runtime.validObjects[component.id];
    }
    //CUSTOM_STATIC_DISPOSE_MATHS_COMPONENT_END

    //GENERATED_STATIC_DISPOSE_MATHS_COMPONENT_AFTER_START
    //GENERATED_STATIC_DISPOSE_MATHS_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_MATHS_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_MATHS_COMPONENT_AFTER_END

  }

  /**
   * AfterAssignProperty()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static AfterAssignProperty(event) {

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    let {object: component} = event;

    if (!(component instanceof MathsComponent)) {
      return;
    }

    let {property, value} = event;

    for (let runtime of [...Maths.Runtimes]) {

      let wasValid;
      let isValid;

      if (!runtime.validObjects.hasOwnProperty(component.id)) {
        wasValid = false;
        isValid = runtime.validate('createInstance', component);
      } else {
        wasValid = runtime.validObjects[component.id];
        isValid = runtime.validate("createInstance", component);
      }

      if (!wasValid && !isValid) {
        /**
         * Do nothing
         */
        return;
      }

      if (!wasValid && isValid) {
        System.CreateRuntimeInstance(component, runtime, MathsComponent);
        return;
      }

      if (wasValid && !isValid) {
        //CUSTOM_CREATE_OR_DELETE_INSTANCES_START
        // /**
        //  * Maths are important - so we try to create something to visualize even though
        //  * it may not be perfect. This is maybe a bit less important for abstract Math components
        //  */
        // let instance = component.getInstance(runtime);
        //
        // if (!instance) {
        //   System.CreateRuntimeChildrenInstances(component, runtime, MathsComponent);
        // } else {
          System.DeleteRuntimeInstance(component, runtime, MathsComponent);
        // }
        //CUSTOM_CREATE_OR_DELETE_INSTANCES_END
        return;
      }

      if (wasValid && isValid) {
        System.UpdateRuntimeInstance(component, runtime, MathsComponent, property, value);
      }
    }
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_END

  }

  /**
   * CreateInstance()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static CreateInstance(event) {

    //GENERATED_STATIC_CREATE_INSTANCE_START
    //CUSTOM_GENERATED_STATIC_CREATE_INSTANCE_START
    let {object: component} = event;

    for (let runtime of [...Maths.Runtimes]) {
      System.CreateRuntimeInstance(component, runtime, MathsComponent);
    }
    //CUSTOM_GENERATED_STATIC_CREATE_INSTANCE_END
    //GENERATED_STATIC_CREATE_INSTANCE_END

    //CUSTOM_STATIC_CREATE_INSTANCE_START
    //CUSTOM_STATIC_CREATE_INSTANCE_END

    //GENERATED_STATIC_CREATE_INSTANCE_AFTER_START
    //GENERATED_STATIC_CREATE_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_CREATE_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * UpdateInstance()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static UpdateInstance(event) {

    //GENERATED_STATIC_UPDATE_INSTANCE_START
    //CUSTOM_GENERATED_STATIC_UPDATE_INSTANCE_START
    let {object: component, options} = event;

    let {property, value} = options;

    for (let runtime of [...Maths.Runtimes]) {
      System.UpdateRuntimeInstance(component, runtime, MathsComponent, property, value);
    }
    //CUSTOM_GENERATED_STATIC_UPDATE_INSTANCE_END
    //GENERATED_STATIC_UPDATE_INSTANCE_END

    //CUSTOM_STATIC_UPDATE_INSTANCE_START
    //CUSTOM_STATIC_UPDATE_INSTANCE_END

    //GENERATED_STATIC_UPDATE_INSTANCE_AFTER_START
    //GENERATED_STATIC_UPDATE_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPDATE_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  /**
   * DeleteInstance()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DeleteInstance(event) {

    //GENERATED_STATIC_DELETE_INSTANCE_START
    //CUSTOM_GENERATED_STATIC_DELETE_INSTANCE_START
    let {object: component} = event;

    for (let runtime of [...Maths.Runtimes]) {
      System.DeleteRuntimeInstance(component, runtime, MathsComponent);
    }
    //CUSTOM_GENERATED_STATIC_DELETE_INSTANCE_END
    //GENERATED_STATIC_DELETE_INSTANCE_END

    //CUSTOM_STATIC_DELETE_INSTANCE_START
    //CUSTOM_STATIC_DELETE_INSTANCE_END

    //GENERATED_STATIC_DELETE_INSTANCE_AFTER_START
    //GENERATED_STATIC_DELETE_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_DELETE_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * CloneInstance()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static CloneInstance(event) {

    //GENERATED_STATIC_CLONE_INSTANCE_START
    //GENERATED_STATIC_CLONE_INSTANCE_END

    //CUSTOM_STATIC_CLONE_INSTANCE_START
    //CUSTOM_STATIC_CLONE_INSTANCE_END

    //GENERATED_STATIC_CLONE_INSTANCE_AFTER_START
    //GENERATED_STATIC_CLONE_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_CLONE_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_CLONE_INSTANCE_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  /**
   * GetRuntimeMathsThree()
   * - Gets the Three runtime implementation of R3RuntimeMaths
   * @param {Object} event
   * - No returns
   */
  static GetRuntimeMathsThree(event) {

    //GENERATED_STATIC_GET_RUNTIME_MATHS_THREE_START
    event.results = [Maths.RuntimeMathsThree];
    //GENERATED_STATIC_GET_RUNTIME_MATHS_THREE_END

    //CUSTOM_STATIC_GET_RUNTIME_MATHS_THREE_START
    //CUSTOM_STATIC_GET_RUNTIME_MATHS_THREE_END

    //GENERATED_STATIC_GET_RUNTIME_MATHS_THREE_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_MATHS_THREE_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_MATHS_THREE_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_MATHS_THREE_AFTER_END

  }
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
/**
 * @param Maths.RuntimeMathsThree
 * - Runtime implementation of R3RuntimeMaths
 */
Maths.RuntimeMathsThree = new ThreeRuntime();
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Maths.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Maths.IsStarting = false;

/**
 * @param Maths.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Maths.IsStopping = false;

/**
 * @param Maths.Started
 * - Indicates whether this system is running
 */
Maths.Started = false;

/**
 * @param Maths.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Maths.Subscriptions = {};

/**
 * @param Maths.Runtimes
 * - A set of runtimes which the system manages
 */
Maths.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Maths as default};
