//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Vector2 from '../maths/Vector2.js';
//CUSTOM_IMPORTS_END

import Graphics from '../Graphics.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  camera = null - The light's view of the world. This is used to generate a depth map of the scene; objects behind other objects from the light's perspective will be in shadow
  bias = 0 - Shadow map bias, how much to add or subtract from the normalized depth when deciding whether a surface is in shadow
  normalBias = 0 - Defines how much the position used to query the shadow map is offset along the object normal. The default is 0. Increasing this value can be used to reduce shadow acne especially in large scenes where light shines onto geometry at a shallow angle. The cost is that shadows may appear distorted.
  radius = 1 - Setting this to values greater than 1 will blur the edges of the shadow. High values will cause unwanted banding effects in the shadows - a greater mapSize will allow for a higher value to be used here before these effects become visible. If WebGLRenderer.shadowMap.type is set to PCFSoftShadowMap, radius has no effect, and it is recommended to increase softness by decreasing mapSize instead
  blurSamples = 8 - The amount of samples to use when blurring a VSM shadow map.
  mapSize = new Vector2( {x : 512, y : 512} ) - Higher values give better quality shadows at the cost of computation time. Values must be powers of 2, up to the WebGLRenderer.capabilities.maxTextureSize for a given device, although the width and height don't have to be the same (so, for example, (512, 1024) is valid).
  autoUpdate = true - Enables automatic updates of the light's shadow. Default is true. If you do not require dynamic lighting / shadows, you may set this to false
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeGraphicsThree" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "camera",
            "type" : "R3EventObjComponentGraphicsCamera"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "camera",
            "type" : "R3EventObjComponentGraphicsCamera"
          },
          {
            "property" : "bias",
            "type" : "Number",
            "min" : 0,
            "max" : 10,
            "step" : 0.001
          },
          {
            "property" : "normalBias",
            "type" : "Number",
            "min" : 0,
            "max" : 10,
            "step" : 0.001
          },
          {
            "property" : "radius",
            "type" : "Number",
            "min" : 1,
            "max" : 50,
            "step" : 0.001
          },
          {
            "property" : "blurSamples",
            "type" : "Number",
            "min" : 1,
            "max" : 64,
            "step" : 1
          },
          {
            "property" : "mapSize",
            "type" : "R3EventObjComponentMathsVector2",
            "min" : 64,
            "max" : 8192,
            "step" : 64
          },
          {
            "property" : "autoUpdate",
            "type" : "Boolean"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Shadow extends Graphics {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphicsShadow';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Shadow';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param camera
     * - The light's view of the world. This is used to generate a depth map of the scene; objects behind other objects
     *   from the light's perspective will be in shadow
     */
    if (typeof options.camera === 'undefined') {
      options.camera = null;
    }

    /**
     * @param bias
     * - Shadow map bias, how much to add or subtract from the normalized depth when deciding whether a surface is in
     *   shadow
     */
    if (typeof options.bias === 'undefined') {
      options.bias = 0;
    }

    /**
     * @param normalBias
     * - Defines how much the position used to query the shadow map is offset along the object normal. The default is 0.
     *   Increasing this value can be used to reduce shadow acne especially in large scenes where light shines onto
     *   geometry at a shallow angle. The cost is that shadows may appear distorted.
     */
    if (typeof options.normalBias === 'undefined') {
      options.normalBias = 0;
    }

    /**
     * @param radius
     * - Setting this to values greater than 1 will blur the edges of the shadow. High values will cause unwanted banding
     *   effects in the shadows - a greater mapSize will allow for a higher value to be used here before these effects
     *   become visible. If WebGLRenderer.shadowMap.type is set to PCFSoftShadowMap, radius has no effect, and it is
     *   recommended to increase softness by decreasing mapSize instead
     */
    if (typeof options.radius === 'undefined') {
      options.radius = 1;
    }

    /**
     * @param blurSamples
     * - The amount of samples to use when blurring a VSM shadow map.
     */
    if (typeof options.blurSamples === 'undefined') {
      options.blurSamples = 8;
    }

    /**
     * @param mapSize
     * - Higher values give better quality shadows at the cost of computation time. Values must be powers of 2, up to the
     *   WebGLRenderer.capabilities.maxTextureSize for a given device, although the width and height don't have to be the
     *   same (so, for example, (512, 1024) is valid).
     */
    if (typeof options.mapSize === 'undefined') {
      options.mapSize = new Vector2( {x : 512, y : 512} );
    }

    /**
     * @param autoUpdate
     * - Enables automatic updates of the light's shadow. Default is true. If you do not require dynamic lighting /
     *   shadows, you may set this to false
     */
    if (typeof options.autoUpdate === 'undefined') {
      options.autoUpdate = true;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_GRAPHICS_THREE)) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_GRAPHICS_THREE]['requirements']) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "camera",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCamera")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "camera",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCamera")
              },
              {
                "property": "bias",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 10,
                "step": 0.001
              },
              {
                "property": "normalBias",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 10,
                "step": 0.001
              },
              {
                "property": "radius",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 1,
                "max": 50,
                "step": 0.001
              },
              {
                "property": "blurSamples",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 1,
                "max": 64,
                "step": 1
              },
              {
                "property": "mapSize",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector2"),
                "min": 64,
                "max": 8192,
                "step": 64
              },
              {
                "property": "autoUpdate",
                "Constructor" : this.getClassConstructor("Boolean")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "camera",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsCamera"),
        "type": "R3EventObjComponentGraphicsCamera"
      },
      {
        "property": "bias",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 10,
        "step": 0.001
      },
      {
        "property": "normalBias",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 10,
        "step": 0.001
      },
      {
        "property": "radius",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 1,
        "max": 50,
        "step": 0.001
      },
      {
        "property": "blurSamples",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 1,
        "max": 64,
        "step": 1
      },
      {
        "property": "mapSize",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector2"),
        "type": "R3EventObjComponentMathsVector2",
        "min": 64,
        "max": 8192,
        "step": 64
      },
      {
        "property": "autoUpdate",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Shadow.Type = 'R3EventObjComponentGraphicsShadow';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Shadow as default};
