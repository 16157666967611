//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Vector3 from '../maths/Vector3.js';
import Quaternion from '../maths/Quaternion.js';
import Runtime from '../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Physics from '../Physics.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  world=null - The world that this cuboid will belong to
  mesh=null - The mesh parent of this collider
  rigidBody=null - The rigid body of this collider
  position=new Vector3() - The position of this collider relative to its parent
  rotation=new Quaternion() - The rotation of this collider in quaternion
  restitution=0.5 - The bounciness of this collider
  density=1 - The density is a measure of how much the volume of the shape contributes to its mass - High density object is heavier than a low density object
  friction=0.5 - The friction coefficient of this collider
  sensor=false - If this collider is a sensor it only emits events when collisions occur instead of creating forces when objects collide
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimePhysicsRapier" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "world",
            "type" : "R3EventObjComponentPhysicsWorld"
          },
          {
            "property" : "position",
            "type" : "R3EventObjComponentMathsVector3"
          },
          {
            "property" : "rotation",
            "type" : "R3EventObjComponentMathsQuaternion"
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimePhysicsRapier" :
    [
      {
        "methods" : ["createInstance"],
        "mode" : "Runtime.OPTIONAL_MODE_EXACTLY_ONE",
        "properties" : [
          {
            "property" : "mesh",
            "type" : "R3EventObjComponentGraphicsMesh"
          },
          {
            "property" : "rigidBody",
            "type" : "R3EventObjComponentPhysicsRigidBody",
            "deep" : true
          }
        ]
      },
      {
        "methods" : ["createInstance"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "restitution",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.0001
          },
          {
            "property" : "density",
            "type" : "Number",
            "min" : 0,
            "max" : 100,
            "step" : 0.001
          },
          {
            "property" : "friction",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.0001
          },
          {
            "property" : "sensor",
            "type" : "Boolean"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Collider extends Physics {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentPhysicsCollider';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Collider';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param world
     * - The world that this cuboid will belong to
     */
    if (typeof options.world === 'undefined') {
      options.world = null;
    }

    /**
     * @param mesh
     * - The mesh parent of this collider
     */
    if (typeof options.mesh === 'undefined') {
      options.mesh = null;
    }

    /**
     * @param rigidBody
     * - The rigid body of this collider
     */
    if (typeof options.rigidBody === 'undefined') {
      options.rigidBody = null;
    }

    /**
     * @param position
     * - The position of this collider relative to its parent
     */
    if (typeof options.position === 'undefined') {
      options.position = new Vector3();
    }

    /**
     * @param rotation
     * - The rotation of this collider in quaternion
     */
    if (typeof options.rotation === 'undefined') {
      options.rotation = new Quaternion();
    }

    /**
     * @param restitution
     * - The bounciness of this collider
     */
    if (typeof options.restitution === 'undefined') {
      options.restitution = 0.5;
    }

    /**
     * @param density
     * - The density is a measure of how much the volume of the shape contributes to its mass - High density object is
     *   heavier than a low density object
     */
    if (typeof options.density === 'undefined') {
      options.density = 1;
    }

    /**
     * @param friction
     * - The friction coefficient of this collider
     */
    if (typeof options.friction === 'undefined') {
      options.friction = 0.5;
    }

    /**
     * @param sensor
     * - If this collider is a sensor it only emits events when collisions occur instead of creating forces when objects
     *   collide
     */
    if (typeof options.sensor === 'undefined') {
      options.sensor = false;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_PHYSICS_RAPIER)) {
      options.runtimes[Runtime.KEY_PHYSICS_RAPIER] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_PHYSICS_RAPIER]['requirements']) {
      options.runtimes[Runtime.KEY_PHYSICS_RAPIER].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_PHYSICS_RAPIER].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "world",
                "Constructor" : this.getClassConstructor("R3EventObjComponentPhysicsWorld")
              },
              {
                "property": "position",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3")
              },
              {
                "property": "rotation",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsQuaternion")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_PHYSICS_RAPIER].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "mode": Runtime.OPTIONAL_MODE_EXACTLY_ONE,
            "properties": [
              {
                "property": "mesh",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMesh")
              },
              {
                "property": "rigidBody",
                "Constructor" : this.getClassConstructor("R3EventObjComponentPhysicsRigidBody"),
                "deep": true
              }
            ]
          },
          {
            "methods": [
              "createInstance"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "restitution",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.0001
              },
              {
                "property": "density",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 100,
                "step": 0.001
              },
              {
                "property": "friction",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.0001
              },
              {
                "property": "sensor",
                "Constructor" : this.getClassConstructor("Boolean")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "world",
        "Constructor" : this.getClassConstructor("R3EventObjComponentPhysicsWorld"),
        "type": "R3EventObjComponentPhysicsWorld"
      },
      {
        "property": "position",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3"
      },
      {
        "property": "rotation",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsQuaternion"),
        "type": "R3EventObjComponentMathsQuaternion"
      },
      {
        "property": "mesh",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMesh"),
        "type": "R3EventObjComponentGraphicsMesh"
      },
      {
        "property": "rigidBody",
        "Constructor" : this.getClassConstructor("R3EventObjComponentPhysicsRigidBody"),
        "type": "R3EventObjComponentPhysicsRigidBody"
      },
      {
        "property": "restitution",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.0001
      },
      {
        "property": "density",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 100,
        "step": 0.001
      },
      {
        "property": "friction",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.0001
      },
      {
        "property": "sensor",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Collider.Type = 'R3EventObjComponentPhysicsCollider';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Collider as default};
