//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//CUSTOM_IMPORTS_START
import Event from './Event.js';
//CUSTOM_IMPORTS_END

import R3 from '../R3.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
  name=this.constructor.name - Name of the runtime
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  validObjects = {}
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  OPTIONAL_MODE_NORMAL = 'normal' - The properties listed are simply optional
  OPTIONAL_MODE_EXACTLY_ONE = 'exactly_one' - Exactly one of the properties listed must have a value
  OPTIONAL_MODE_AT_LEAST_ONE = 'at_least_one' - At least one of the properties listed must have a value
  OPTIONAL_MODE_AT_LEAST_ALL = 'at_least_all' - All the properties listed must have a value
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
  validate(method, object) - Validates the object based on the requirements for this runtime
  validateGroup(group, object, method) - Validates the grouped items of the requirements for this runtime
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  GetThreeInstance()
 CUSTOM_STATIC_METHODS_END

 **/

export class Runtime extends R3 {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    /**
     * @param name
     * - Name of the runtime
     */
    if (typeof options.name === 'undefined') {
      options.name = this.constructor.name;
    }
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param validObjects
     * - No comment
     */
    if (typeof options.validObjects === 'undefined') {
      options.validObjects = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * validate()
   * - Validates the object based on the requirements for this runtime
   * @param method
   * @param object
   * - No returns
   */
  validate(method, object) {

    //CUSTOM_VALIDATE_BEFORE_START
    //CUSTOM_VALIDATE_BEFORE_END

    //GENERATED_VALIDATE_BEFORE_START
    //GENERATED_VALIDATE_BEFORE_END

    //CUSTOM_VALIDATE_BEFORE_GENERATED_START
    //CUSTOM_VALIDATE_BEFORE_GENERATED_END

    //GENERATED_VALIDATE_START
    //CUSTOM_VALIDATE_B_START
    if (!object.runtimes.hasOwnProperty(this.type)) {
      this.validObjects[object.id] = false;
      return false;
    }

    let {requirements} = object.runtimes[this.type];

    if (requirements === null) {
      this.validObjects[object.id] = true;
      return true;
    }

    /**
     * We validate the optional options first, then we validate the absolute requirements
     */
    // console.log(`Validating ${requirements.absolute.group.size} absolute and ${requirements.optional.group.size} optional requirements for ${object.uniqueName} for runtime ${this.type}`);

    let {group: absoluteGroup} = requirements.absolute;

    for (let absoluteInfo of absoluteGroup) {
      let {properties, methods} = absoluteInfo;
      if (methods.includes(method)) {
        let count = this.validateGroup(properties, object, method);
        if (count !== properties.length) {
          this.validObjects[object.id] = false;
          return false;
        }
      }
    }

    let {group: optionalGroup} = requirements.optional;

    for (let optionalInfo of optionalGroup) {

      let {mode} = optionalInfo;

      if (mode === Runtime.OPTIONAL_MODE_EXACTLY_ONE) {
        let {properties, methods} = optionalInfo;
        if (methods.includes(method)) {
          let count = this.validateGroup(properties, object, method);
          if (count !== 1) {
            this.validObjects[object.id] = false;
            return false;
          }
        }
      }

      if (mode === Runtime.OPTIONAL_MODE_AT_LEAST_ONE) {
        let {properties, methods} = optionalInfo;
        if (methods.includes(method)) {
          let count = this.validateGroup(properties, object, method);
          if (count < 1) {
            this.validObjects[object.id] = false;
            return false;
          }
        }
      }

      if (mode === Runtime.OPTIONAL_MODE_AT_LEAST_ALL) {
        let {properties, methods} = optionalInfo;
        if (methods.includes(method)) {
          let count = this.validateGroup(properties, object, method);
          if (count !== properties.length) {
            this.validObjects[object.id] = false;
            return false;
          }
        }
      }
    }

    this.validObjects[object.id] = true;
    return true;
    //CUSTOM_VALIDATE_B_END
    //GENERATED_VALIDATE_END

    //CUSTOM_VALIDATE_START
    //CUSTOM_VALIDATE_END

    //GENERATED_VALIDATE_AFTER_START
    //GENERATED_VALIDATE_AFTER_END

  }

  /**
   * validateGroup()
   * - Validates the grouped items of the requirements for this runtime
   * @param group
   * @param object
   * @param method
   * - No returns
   */
  validateGroup(group, object, method) {

    //CUSTOM_VALIDATE_GROUP_BEFORE_START
    //CUSTOM_VALIDATE_GROUP_BEFORE_END

    //GENERATED_VALIDATE_GROUP_BEFORE_START
    //GENERATED_VALIDATE_GROUP_BEFORE_END

    //CUSTOM_VALIDATE_GROUP_BEFORE_GENERATED_START
    //CUSTOM_VALIDATE_GROUP_BEFORE_GENERATED_END

    //GENERATED_VALIDATE_GROUP_START
    let count = 0;

    for (let item of [...group]) {

      let {property, Constructor, deep} = item;

      let toCompare = object[property];

      let isArray = false;

      if (Constructor instanceof Array) {
        Constructor = Constructor[0];
        isArray = true;
      }

      if (isArray) {

        if (!(toCompare instanceof Array)) {
          continue;
        }

        if (item.minLength && !(toCompare.length >= item.minLength)) {
          continue;
        }

        if (item.maxLength && !(toCompare.length <= item.maxLength)) {
          continue;
        }

        if (toCompare.length === 0) {
          continue;
        }

        let failed = false;

        for (let member of toCompare) {
          if (!(member instanceof Constructor || Object(member) instanceof Constructor)) {
            failed = true;
            break;
          }

          if (deep && member) {
            if (!this.validate(method, member)) {
              failed = true;
              break;
            }
          }
        }

        if (!failed) {
          count++;
        }

      } else {

        if (toCompare === null || !(toCompare instanceof Constructor || Object(toCompare) instanceof Constructor)) {
          continue;
        }

        if (deep && toCompare) {
          if (!this.validate(method, toCompare)) {
            continue;
          }
        }

        count++;
      }

    }

    return count;
    //GENERATED_VALIDATE_GROUP_END

    //CUSTOM_VALIDATE_GROUP_START
    //CUSTOM_VALIDATE_GROUP_END

    //GENERATED_VALIDATE_GROUP_AFTER_START
    //GENERATED_VALIDATE_GROUP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * GetThreeInstance()
   * - No comment
   * - No parameters
   * - No returns
   */
  static GetThreeInstance() {

    //GENERATED_STATIC_GET_THREE_INSTANCE_START
    //GENERATED_STATIC_GET_THREE_INSTANCE_END

    //CUSTOM_STATIC_GET_THREE_INSTANCE_START
    let payload = {};
    Event.Emit(
      Event.GET_THREE_INSTANCE,
      payload
    );
    return payload.results[0];
    //CUSTOM_STATIC_GET_THREE_INSTANCE_END

    //GENERATED_STATIC_GET_THREE_INSTANCE_AFTER_START
    //GENERATED_STATIC_GET_THREE_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_THREE_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_THREE_INSTANCE_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Runtime.Type = 'R3Runtime';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Runtime.OPTIONAL_MODE_NORMAL
 * - The properties listed are simply optional
 */
Runtime.OPTIONAL_MODE_NORMAL = 'normal';

/**
 * @param Runtime.OPTIONAL_MODE_EXACTLY_ONE
 * - Exactly one of the properties listed must have a value
 */
Runtime.OPTIONAL_MODE_EXACTLY_ONE = 'exactly_one';

/**
 * @param Runtime.OPTIONAL_MODE_AT_LEAST_ONE
 * - At least one of the properties listed must have a value
 */
Runtime.OPTIONAL_MODE_AT_LEAST_ONE = 'at_least_one';

/**
 * @param Runtime.OPTIONAL_MODE_AT_LEAST_ALL
 * - All the properties listed must have a value
 */
Runtime.OPTIONAL_MODE_AT_LEAST_ALL = 'at_least_all';
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
Runtime.KEY_WEBSOCKET = 'R3RuntimeWebsocket'
Runtime.KEY_STORAGE = 'R3RuntimeStorage'
Runtime.KEY_PHYSICS = 'R3RuntimePhysics'
Runtime.KEY_MATHS = 'R3RuntimeMaths'
Runtime.KEY_INPUT = 'R3RuntimeInput'
Runtime.KEY_GUI = 'R3RuntimeGui'
Runtime.KEY_GRAPHICS = 'R3RuntimeGraphics'
Runtime.KEY_ENTITY = 'R3RuntimeEntity'
Runtime.KEY_CODE = 'R3RuntimeCode'
Runtime.KEY_AUTHENTICATION = 'R3RuntimeAuthentication'
Runtime.KEY_AUDIO = 'R3RuntimeAudio'
Runtime.KEY_WEBSOCKET_SOCKET_I_O = 'R3RuntimeWebsocketSocketIO'
Runtime.KEY_STORAGE_AXIOS = 'R3RuntimeStorageAxios'
Runtime.KEY_PHYSICS_RAPIER = 'R3RuntimePhysicsRapier'
Runtime.KEY_MATHS_THREE = 'R3RuntimeMathsThree'
Runtime.KEY_INPUT_THREE = 'R3RuntimeInputThree'
Runtime.KEY_INPUT_CUSTOM = 'R3RuntimeInputCustom'
Runtime.KEY_GUI_TWEAK_PANE = 'R3RuntimeGuiTweakPane'
Runtime.KEY_GRAPHICS_THREE = 'R3RuntimeGraphicsThree'
Runtime.KEY_ENTITY_DEFAULT = 'R3RuntimeEntityDefault'
Runtime.KEY_CODE_ACE = 'R3RuntimeCodeAce'
Runtime.KEY_AUTHENTICATION_AXIOS = 'R3RuntimeAuthenticationAxios'
Runtime.KEY_AUDIO_THREE = 'R3RuntimeAudioThree'
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Runtime as default};
