//GENERATED_IMPORTS_START

//GENERATED_IMPORTS_END

//CUSTOM_IMPORTS_START
import Utils from './Utils.js';
import Project from './event/obj/Project.js';
import Obj from './event/Obj.js';
import Mesh from "./event/obj/component/graphics/Mesh.js";
import OrbitControls from "./event/obj/component/input/OrbitControls.js";
import Canvas from "./event/obj/component/graphics/Canvas.js";
import Perspective from "./event/obj/component/graphics/camera/Perspective.js";
import Raycaster from "./event/obj/component/graphics/Raycaster.js";
import Gl from "./event/obj/component/graphics/renderer/Gl.js";
//CUSTOM_IMPORTS_END

import R3 from '../R3.js';
import Event from './Event.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Verbose=false
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.STATUS_INFO(0)
  Event.AFTER_ASSIGN_PROPERTY(1)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  CreateRuntimeInstance(component, runtime, Constructor) - Creates an instance for a runtime for parent objects, it and its children of type Constructor
  CreateRuntimeChildrenInstances(component, runtime, Constructor) - Creates the children instances of a component for a runtime for objects of type Constructor
  DeleteRuntimeInstance(component, runtime, Constructor) - Deletes the instance of a component for a runtime for it and its children objects of type Constructor
  DeleteRuntimeChildrenInstances(component, runtime, Constructor) - Deletes the instance of a component for a runtime for it and its children objects of type Constructor
  UpdateRuntimeInstance(component, runtime, Constructor, property, value) - Updates the instance of a component for a runtime for it and its children objects of type Constructor
  UpdateControl(component, property) - Checks if a position property of a Mesh changed and if it has a control as a child, updates the target
 CUSTOM_STATIC_METHODS_END

 **/

export class System extends R3 {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    //GENERATED_TEMPLATE_OPTIONS_START
    super(options);
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    Object.assign(this, options);

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (System.IsStarting) {
      console.log('client System system is already starting...');
      return;
    }

    System.IsStarting = true;

    if (System.Started === true) {
      System.IsStarting = false;
      console.log('client System system already started');
      return;
    }

    System.Runtimes = new Set();
    System.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    System.Subscriptions['STATUS_INFO'] = Event.On(
      Event.STATUS_INFO,
      System.StatusInfo
    );

    /**
     * No comment
     */
    System.Subscriptions['AFTER_ASSIGN_PROPERTY'] = Event.On(
      Event.AFTER_ASSIGN_PROPERTY,
      System.AfterAssignProperty,
      {priority: 1}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    System.Linking.Start();
    System.Storage.Start();
    System.Authentication.Start();
    System.Websocket.Start();
    System.Maths.Start();
    System.Graphics.Start();
    System.Audio.Start();
    System.Input.Start();
    System.Entity.Start();

    Event.Once(
      Event.PHYSICS_SYSTEM_STARTED,
      () => {
        System.Started = true;
        System.IsStarting = false;
        console.log('Started client system System');
        Event.Emit(Event.R3_STARTED);
      }
    );

    System.Physics.Start();

    /**
     * We don't start the GUI and Code system - they can be started manually
     * in the workspace if required
     */
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (System.IsStopping) {
      console.log('client System system is already stopping');
      return;
    }

    System.IsStopping = true;

    if (System.Started === false) {
      System.IsStopping = false;
      console.log('client System system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    System.Entity.Stop();
    System.Physics.Stop();
    System.Audio.Stop();
    System.Graphics.Stop();
    System.Input.Stop();
    System.Maths.Stop();
    System.Websocket.Stop();
    System.Authentication.Stop();
    System.Storage.Stop();
    System.Linking.Stop();
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    System.Subscriptions['STATUS_INFO'].remove();
    delete System.Subscriptions['STATUS_INFO'];

    System.Subscriptions['AFTER_ASSIGN_PROPERTY'].remove();
    delete System.Subscriptions['AFTER_ASSIGN_PROPERTY'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    System.Runtimes.clear();
    System.Started = false;
    System.IsStopping = false;
    console.log('Stopped client system System');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START

    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * CreateRuntimeInstance()
   * - Creates an instance for a runtime for parent objects, it and its children of type Constructor
   * @param component
   * @param runtime
   * @param Constructor
   * - No returns
   */
  static CreateRuntimeInstance(component, runtime, Constructor) {

    //GENERATED_STATIC_CREATE_RUNTIME_INSTANCE_START
    //GENERATED_STATIC_CREATE_RUNTIME_INSTANCE_END

    //CUSTOM_STATIC_CREATE_RUNTIME_INSTANCE_START
    // let parents = Utils.GetParents(component).filter(
    //   (parent) => {
    //     return (parent instanceof Constructor);
    //   }
    // );
    //
    // /**
    //  * Process parents starting from highest
    //  */
    // for (let parent of parents) {
    //
    //   let instance = parent.getInstance(runtime);
    //
    //   if (instance) {
    //     continue;
    //   }
    //
    //   let valid;
    //
    //   if (runtime.validObjects.hasOwnProperty(parent.id)) {
    //     valid = runtime.validObjects[parent.id];
    //   } else {
    //     valid = runtime.validate("createInstance", parent);
    //   }
    //
    //   if (!valid) {
    //     /**
    //      * One of our parents are not valid - terminate early to save on processing
    //      */
    //     continue;
    //   }
    //
    //   parent.setInstance(runtime, runtime.createInstance(parent));
    //   Utils.Status(0, `The instance of ${parent.name} was created.`);
    // }

    let instance = component.getInstance(runtime);

    /**
     * Process current component
     */
    if (!instance && component instanceof Constructor) {

      let valid;

      if (runtime.validObjects.hasOwnProperty(component.id)) {
        valid = runtime.validObjects[component.id];
      } else {
        valid = runtime.validate("createInstance", component);
      }

      if (valid) {
        component.setInstance(runtime, runtime.createInstance(component));
        Utils.Status(0, `The instance of ${component.name} was created.`);
      }

    }

    System.CreateRuntimeChildrenInstances(component, runtime, Constructor);
    //CUSTOM_STATIC_CREATE_RUNTIME_INSTANCE_END

    //GENERATED_STATIC_CREATE_RUNTIME_INSTANCE_AFTER_START
    //GENERATED_STATIC_CREATE_RUNTIME_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_CREATE_RUNTIME_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_CREATE_RUNTIME_INSTANCE_AFTER_END

  }
  /**
   * CreateRuntimeChildrenInstances()
   * - Creates the children instances of a component for a runtime for objects of type Constructor
   * @param component
   * @param runtime
   * @param Constructor
   * - No returns
   */
  static CreateRuntimeChildrenInstances(component, runtime, Constructor) {

    //GENERATED_STATIC_CREATE_RUNTIME_CHILDREN_INSTANCES_START
    //GENERATED_STATIC_CREATE_RUNTIME_CHILDREN_INSTANCES_END

    //CUSTOM_STATIC_CREATE_RUNTIME_CHILDREN_INSTANCES_START
    /**
     * Process children, starting from the highest child
     */
    let children = Utils.GetChildren(component).filter(
      (child) => {
        return (child instanceof Constructor)
      }
    ).reverse();

    for (let child of children) {

      let instance = child.getInstance(runtime);

      if (instance) {
        continue;
      }

      /**
       * Force a new validation
       */
      let valid = runtime.validate("createInstance", child);

      if (!valid) {
        continue;
      }

      child.setInstance(runtime, runtime.createInstance(child));
      Utils.Status(0, `The instance of ${child.name} was created.`);

    }
    //CUSTOM_STATIC_CREATE_RUNTIME_CHILDREN_INSTANCES_END

    //GENERATED_STATIC_CREATE_RUNTIME_CHILDREN_INSTANCES_AFTER_START
    //GENERATED_STATIC_CREATE_RUNTIME_CHILDREN_INSTANCES_AFTER_END

    //CUSTOM_STATIC_GENERATED_CREATE_RUNTIME_CHILDREN_INSTANCES_AFTER_START
    //CUSTOM_STATIC_GENERATED_CREATE_RUNTIME_CHILDREN_INSTANCES_AFTER_END

  }
  /**
   * DeleteRuntimeInstance()
   * - Deletes the instance of a component for a runtime for it and its children objects of type Constructor
   * @param component
   * @param runtime
   * @param Constructor
   * - No returns
   */
  static DeleteRuntimeInstance(component, runtime, Constructor) {

    //GENERATED_STATIC_DELETE_RUNTIME_INSTANCE_START
    //GENERATED_STATIC_DELETE_RUNTIME_INSTANCE_END

    //CUSTOM_STATIC_DELETE_RUNTIME_INSTANCE_START
    /**
     * Process children first, starting from the lowest children
     */
    System.DeleteRuntimeChildrenInstances(component, runtime, Constructor);

    /**
     * Now process the current component
     */
    let instance = component.getInstance(runtime);
    if (!instance) {
      return;
    }

    if (component instanceof Constructor) {
      runtime.deleteInstance(component);
      component.setInstance(runtime, null);
      Utils.Status(0, `The instance of ${component.name} was deleted.`);
    }
    //CUSTOM_STATIC_DELETE_RUNTIME_INSTANCE_END

    //GENERATED_STATIC_DELETE_RUNTIME_INSTANCE_AFTER_START
    //GENERATED_STATIC_DELETE_RUNTIME_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_DELETE_RUNTIME_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_DELETE_RUNTIME_INSTANCE_AFTER_END

  }
  /**
   * DeleteRuntimeChildrenInstances()
   * - Deletes the instance of a component for a runtime for it and its children objects of type Constructor
   * @param component
   * @param runtime
   * @param Constructor
   * - No returns
   */
  static DeleteRuntimeChildrenInstances(component, runtime, Constructor) {

    //GENERATED_STATIC_DELETE_RUNTIME_CHILDREN_INSTANCES_START
    //GENERATED_STATIC_DELETE_RUNTIME_CHILDREN_INSTANCES_END

    //CUSTOM_STATIC_DELETE_RUNTIME_CHILDREN_INSTANCES_START
    let children = Utils.GetChildren(component).filter(
      (child) => {
        return (child instanceof Constructor);
      }
    );

    for (let child of children) {

      let instance = child.getInstance(runtime);

      if (!instance) {
        continue;
      }

      runtime.deleteInstance(child);
      child.setInstance(runtime, null);
      Utils.Status(0, `The instance of ${child.name} was deleted.`);
    }
    //CUSTOM_STATIC_DELETE_RUNTIME_CHILDREN_INSTANCES_END

    //GENERATED_STATIC_DELETE_RUNTIME_CHILDREN_INSTANCES_AFTER_START
    //GENERATED_STATIC_DELETE_RUNTIME_CHILDREN_INSTANCES_AFTER_END

    //CUSTOM_STATIC_GENERATED_DELETE_RUNTIME_CHILDREN_INSTANCES_AFTER_START
    //CUSTOM_STATIC_GENERATED_DELETE_RUNTIME_CHILDREN_INSTANCES_AFTER_END

  }
  /**
   * UpdateRuntimeInstance()
   * - Updates the instance of a component for a runtime for it and its children objects of type Constructor
   * @param component
   * @param runtime
   * @param Constructor
   * @param property
   * @param value
   * - No returns
   */
  static UpdateRuntimeInstance(component, runtime, Constructor, property, value) {

    //GENERATED_STATIC_UPDATE_RUNTIME_INSTANCE_START
    //GENERATED_STATIC_UPDATE_RUNTIME_INSTANCE_END

    //CUSTOM_STATIC_UPDATE_RUNTIME_INSTANCE_START
    /**
     * Update the current instance first
     */
    if (component instanceof Constructor) {
      let instance = component.getInstance(runtime);
      if (instance) {
        runtime.updateInstance(component, property, value);
      }
    }

    /**
     * Process all children starting from the highest child
     * @type {[]}
     */
    let children = Utils.GetChildren(component).filter(
      (child) => {
        return (child instanceof Constructor);
      }
    ).reverse();

    for (let child of children) {

      let instance = component.getInstance(runtime);

      if (!instance) {
        continue;
      }

      for (let [property, reference] of Object.entries(child.references)) {

        if (reference.skipParentUpdate) {
          continue;
        }

        if (child[property] instanceof Array) {
          if (Utils.ArrayContainsById(child[property], component.id)) {
            runtime.updateInstance(child, property, child[property]);
          }
          continue;
        }

        if (child[property] === component) {
          let instance = child.getInstance(runtime);
          if (instance) {
            runtime.updateInstance(child, property, component);
          }
        }
      }
    }
    //CUSTOM_STATIC_UPDATE_RUNTIME_INSTANCE_END

    //GENERATED_STATIC_UPDATE_RUNTIME_INSTANCE_AFTER_START
    //GENERATED_STATIC_UPDATE_RUNTIME_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPDATE_RUNTIME_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPDATE_RUNTIME_INSTANCE_AFTER_END

  }
  /**
   * UpdateControl()
   * - Checks if a position property of a Mesh changed and if it has a control as a child, updates the target
   * @param component
   * @param property
   * - No returns
   */
  static UpdateControl(component, property) {

    //GENERATED_STATIC_UPDATE_CONTROL_START
    //GENERATED_STATIC_UPDATE_CONTROL_END

    //CUSTOM_STATIC_UPDATE_CONTROL_START
    if (
      component instanceof Mesh &&
      property === 'position'
    ) {

      let children = component.children.filter(
        (child) => {
          return (child instanceof OrbitControls);
        }
      );

      for (let child of children) {
        if (child.target === component) {
          child.updateControl('target', component);
        }
      }
    }
    //CUSTOM_STATIC_UPDATE_CONTROL_END

    //GENERATED_STATIC_UPDATE_CONTROL_AFTER_START
    //GENERATED_STATIC_UPDATE_CONTROL_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPDATE_CONTROL_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPDATE_CONTROL_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * StatusInfo()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static StatusInfo(event) {

    //GENERATED_STATIC_STATUS_INFO_START
    let code, message, error;

    if (
      event.object.hasOwnProperty('code') &&
      event.object.hasOwnProperty('message')
    ) {
      code = event.object.code;
      message = event.object.message;
      error = event.object.error;
    }

    if (
      event.hasOwnProperty('code') &&
      event.hasOwnProperty('message')
    ) {
      code = event.code;
      message = event.message;
    }

    if (event.hasOwnProperty('error')) {
      error = event.error;
    }

    if (code === 0) {
      if (System.Verbose) {
        console.log(`${message}`);
      }
    } else {
      console.error(`Error (${code}) : ${message}`);
      if (error) {
        console.error(error.stack);
      }
    }
    //GENERATED_STATIC_STATUS_INFO_END

    //CUSTOM_STATIC_STATUS_INFO_START
    //CUSTOM_STATIC_STATUS_INFO_END

    //GENERATED_STATIC_STATUS_INFO_AFTER_START
    //GENERATED_STATIC_STATUS_INFO_AFTER_END

    //CUSTOM_STATIC_GENERATED_STATUS_INFO_AFTER_START
    //CUSTOM_STATIC_GENERATED_STATUS_INFO_AFTER_END

  }

  /**
   * AfterAssignProperty()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static AfterAssignProperty(event) {

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    /**
     * Override
     */
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_START
    let {object: project} = event;

    if (!(project instanceof Project)) {
      return;
    }

    let {property, value : mode} = event;

    if (property === 'mode') {

      if (mode === Obj.MODE_LIVE) {
        Event.Emit(Event.DO_NOT_RENDER);
        let {editorControls} = project;

        if (editorControls) {

          let {camera, rayCaster} = editorControls;

          if (camera) {
            camera.dispose();
          }

          if (rayCaster) {
            rayCaster.dispose();
          }

          editorControls.dispose();

          /**
           * Project does not have an explicit reference to editorControls - so set it null manually
           */
          project.editorControls = null;
        }

        Event.Emit(Event.RENDER_OK);
        return;
      }

      if (mode === Obj.MODE_EDIT) {

        let canvasses = project.children.filter(
          (child) => {
            return (child instanceof Canvas);
          }
        );

        let renderers = project.children.filter(
          (child) => {
            return (child instanceof Gl);
          }
        );

        if (!canvasses.length) {
          Utils.Status(-1, `This project ${project.name} has no canvas associated to it`);
          return;
        }

        if (!renderers.length) {
          Utils.Status(-1, `This project ${project.name} has no renderers associated to it`);
          return;
        }

        let canvas = canvasses[0];
        let renderer = renderers[0];

        if (!renderer.camera) {
          Utils.Status(-1, `This project ${project.name} has no camera associated to it's renderer`);
          return;
        }

        Event.Emit(Event.DO_NOT_RENDER);
        project.editorControls = new OrbitControls(
          {
            name: 'Edit Controls',
            camera: new Perspective(
              {
                name : 'Edit Camera',
                canvas : canvas,
                position : renderer.camera.position.copy(),
                mode
              }
            ),
            domElement: canvas,
            rayCaster: new Raycaster(
              {
                name: 'Editor Raycaster',
                mode
              }
            ),
            mode
          }
        );
        // Event.Emit(Event.WINDOW_RESIZE);
        Event.Emit(Event.RENDER_OK);
      }

    }
    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param System.IsStarting
 * - Indicates whether this system is in a starting phase
 */
System.IsStarting = false;

/**
 * @param System.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
System.IsStopping = false;

/**
 * @param System.Started
 * - Indicates whether this system is running
 */
System.Started = false;

/**
 * @param System.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
System.Subscriptions = {};
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param System.Verbose
 * - No comment
 */
System.Verbose = false;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {System as default};
