//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Vector3 from '../maths/Vector3.js';
import Quaternion from '../maths/Quaternion.js';
import Runtime from '../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Graphics from '../Graphics.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  scene=this.getCurrentScene()
  geometry=null
  material=null
  materials=[]
  position = new Vector3()
  rotation = new Vector3({spin:true})
  quaternion = new Quaternion()
  scale = new Vector3({x:1,y:1,z:1})
  skeleton = null
  parentMesh = null
  selected = false
  helper = null
  width = 0
  height = 0
  depth = 0
  useQuaternion = false
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
  {
    "R3RuntimeGraphicsThree" :
    [
      {
        "methods" : ["createInstance"],
        "properties" : [
          {
            "property" : "scene",
            "type" : "R3EventObjComponentGraphicsScene",
            "deep" : true
          },
          {
            "property" : "geometry",
            "type" : "R3EventObjComponentGraphicsGeometry",
            "deep" : true
          },
          {
            "property" : "position",
            "type" : "R3EventObjComponentMathsVector3",
            "deep" : true
          },
          {
            "property" : "scale",
            "type" : "R3EventObjComponentMathsVector3",
            "deep" : true
          }
        ]
      }
    ],
    "R3RuntimeEntityDefault" :
    [
      {
        "methods" : ["activate"],
        "properties" : [
          {
            "property" : "scene",
            "type" : "R3EventObjComponentGraphicsScene"
          },
          {
            "property" : "geometry",
            "type" : "R3EventObjComponentGraphicsGeometry"
          },
          {
            "property" : "position",
            "type" : "R3EventObjComponentMathsVector3",
            "deep" : true
          },
          {
            "property" : "scale",
            "type" : "R3EventObjComponentMathsVector3",
            "deep" : true
          }
        ]
      }
    ]
  }
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeGraphicsThree" :
    [
      {
        "methods" : ["createInstance"],
        "mode" : "Runtime.OPTIONAL_MODE_AT_LEAST_ONE",
        "properties" : [
          {
            "property" : "material",
            "type" : "R3EventObjComponentGraphicsMaterial",
            "deep" : true
          },
          {
            "property" : "materials",
            "type" : ["R3EventObjComponentGraphicsMaterial"],
            "deep" : true,
            "minLength" : 1,
            "maxLength" : 6
          }
        ]
      },
      {
        "methods" : ["createInstance"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "selected",
            "type" : "Boolean"
          }
        ]
      }
    ],
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "scene",
            "type" : "R3EventObjComponentGraphicsScene"
          },
          {
            "property" : "geometry",
            "type" : "R3EventObjComponentGraphicsGeometry"
          },
          {
            "property" : "material",
            "type" : "R3EventObjComponentGraphicsMaterial"
          },
          {
            "property" : "materials",
            "type" : ["R3EventObjComponentGraphicsMaterial"],
            "minLength" : 1,
            "maxLength" : 6
          },
          {
            "property" : "position",
            "type" : "R3EventObjComponentMathsVector3",
            "min" : -1000,
            "max" : 1000,
            "step" : 0.01
          },
          {
            "property" : "rotation",
            "type" : "R3EventObjComponentMathsVector3",
            "min": "0",
            "max": "2 * Math.PI",
            "step" : 0.00001
          },
          {
            "property" : "scale",
            "type" : "R3EventObjComponentMathsVector3",
            "min" : -100,
            "max" : 100,
            "step" : 0.001
          },
          {
            "property" : "skeleton",
            "type" : "R3EventObjComponentGraphicsSkeleton"
          },
          {
            "property" : "parentMesh",
            "type" : "R3EventObjComponentGraphicsMesh"
          },
          {
            "property" : "quaternion",
            "type" : "R3EventObjComponentMathsQuaternion"
          },
          {
            "property" : "useQuaternion",
            "type" : "Boolean"
          },
          {
            "property" : "width",
            "type" : "Number"
          },
          {
            "property" : "height",
            "type" : "Number"
          },
          {
            "property" : "depth",
            "type" : "Number"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  cloneInstance(propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance() - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance() - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Mesh extends Graphics {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphicsMesh';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Mesh';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param scene
     * - No comment
     */
    if (typeof options.scene === 'undefined') {
      options.scene = this.getCurrentScene();
    }

    /**
     * @param geometry
     * - No comment
     */
    if (typeof options.geometry === 'undefined') {
      options.geometry = null;
    }

    /**
     * @param material
     * - No comment
     */
    if (typeof options.material === 'undefined') {
      options.material = null;
    }

    /**
     * @param materials
     * - No comment
     */
    if (typeof options.materials === 'undefined') {
      options.materials = [];
    }

    /**
     * @param position
     * - No comment
     */
    if (typeof options.position === 'undefined') {
      options.position = new Vector3();
    }

    /**
     * @param rotation
     * - No comment
     */
    if (typeof options.rotation === 'undefined') {
      options.rotation = new Vector3({spin:true});
    }

    /**
     * @param quaternion
     * - No comment
     */
    if (typeof options.quaternion === 'undefined') {
      options.quaternion = new Quaternion();
    }

    /**
     * @param scale
     * - No comment
     */
    if (typeof options.scale === 'undefined') {
      options.scale = new Vector3({x:1,y:1,z:1});
    }

    /**
     * @param skeleton
     * - No comment
     */
    if (typeof options.skeleton === 'undefined') {
      options.skeleton = null;
    }

    /**
     * @param parentMesh
     * - No comment
     */
    if (typeof options.parentMesh === 'undefined') {
      options.parentMesh = null;
    }

    /**
     * @param selected
     * - No comment
     */
    if (typeof options.selected === 'undefined') {
      options.selected = false;
    }

    /**
     * @param helper
     * - No comment
     */
    if (typeof options.helper === 'undefined') {
      options.helper = null;
    }

    /**
     * @param width
     * - No comment
     */
    if (typeof options.width === 'undefined') {
      options.width = 0;
    }

    /**
     * @param height
     * - No comment
     */
    if (typeof options.height === 'undefined') {
      options.height = 0;
    }

    /**
     * @param depth
     * - No comment
     */
    if (typeof options.depth === 'undefined') {
      options.depth = 0;
    }

    /**
     * @param useQuaternion
     * - No comment
     */
    if (typeof options.useQuaternion === 'undefined') {
      options.useQuaternion = false;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_GRAPHICS_THREE)) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_GRAPHICS_THREE]['requirements']) {
      options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_ENTITY_DEFAULT)) {
      options.runtimes[Runtime.KEY_ENTITY_DEFAULT] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_ENTITY_DEFAULT]['requirements']) {
      options.runtimes[Runtime.KEY_ENTITY_DEFAULT].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "properties": [
              {
                "property": "scene",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsScene"),
                "deep": true
              },
              {
                "property": "geometry",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsGeometry"),
                "deep": true
              },
              {
                "property": "position",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "deep": true
              },
              {
                "property": "scale",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "deep": true
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_ENTITY_DEFAULT].requirements;

    group = [];
    if (requirements.absolute) {
      group = [...requirements.absolute.group];
    }

    requirements.absolute = {
      group: new Set(
        [
          {
            "methods": [
              "activate"
            ],
            "properties": [
              {
                "property": "scene",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsScene")
              },
              {
                "property": "geometry",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsGeometry")
              },
              {
                "property": "position",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "deep": true
              },
              {
                "property": "scale",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "deep": true
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_GRAPHICS_THREE].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "createInstance"
            ],
            "mode": Runtime.OPTIONAL_MODE_AT_LEAST_ONE,
            "properties": [
              {
                "property": "material",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMaterial"),
                "deep": true
              },
              {
                "property": "materials",
                "Constructor" : [this.getClassConstructor("R3EventObjComponentGraphicsMaterial")],
                "deep": true,
                "minLength": 1,
                "maxLength": 6
              }
            ]
          },
          {
            "methods": [
              "createInstance"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "selected",
                "Constructor" : this.getClassConstructor("Boolean")
              }
            ]
          },
          ...group
        ]
      )
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "scene",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsScene")
              },
              {
                "property": "geometry",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsGeometry")
              },
              {
                "property": "material",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMaterial")
              },
              {
                "property": "materials",
                "Constructor" : [this.getClassConstructor("R3EventObjComponentGraphicsMaterial")],
                "minLength": 1,
                "maxLength": 6
              },
              {
                "property": "position",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "min": -1000,
                "max": 1000,
                "step": 0.01
              },
              {
                "property": "rotation",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "min" : 0,
                "max" : 2 * Math.PI,
                "step": 0.00001
              },
              {
                "property": "scale",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
                "min": -100,
                "max": 100,
                "step": 0.001
              },
              {
                "property": "skeleton",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsSkeleton")
              },
              {
                "property": "parentMesh",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMesh")
              },
              {
                "property": "quaternion",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsQuaternion")
              },
              {
                "property": "useQuaternion",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "width",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "height",
                "Constructor" : this.getClassConstructor("Number")
              },
              {
                "property": "depth",
                "Constructor" : this.getClassConstructor("Number")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_GRAPHICS_THREE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "scene",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsScene"),
        "type": "R3EventObjComponentGraphicsScene"
      },
      {
        "property": "geometry",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsGeometry"),
        "type": "R3EventObjComponentGraphicsGeometry"
      },
      {
        "property": "position",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3",
        "min": -1000,
        "max": 1000,
        "step": 0.01
      },
      {
        "property": "scale",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3",
        "min": -100,
        "max": 100,
        "step": 0.001
      },
      {
        "property": "material",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMaterial"),
        "type": "R3EventObjComponentGraphicsMaterial"
      },
      {
        "property": "materials",
        "Constructor" : [this.getClassConstructor("R3EventObjComponentGraphicsMaterial")],
        "type": [
          "R3EventObjComponentGraphicsMaterial"
        ],
        "minLength": 1,
        "maxLength": 6
      },
      {
        "property": "selected",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "rotation",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3",
        "min": "0",
        "max": "2 * Math.PI",
        "step": 0.00001
      },
      {
        "property": "skeleton",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsSkeleton"),
        "type": "R3EventObjComponentGraphicsSkeleton"
      },
      {
        "property": "parentMesh",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMesh"),
        "type": "R3EventObjComponentGraphicsMesh"
      },
      {
        "property": "quaternion",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsQuaternion"),
        "type": "R3EventObjComponentMathsQuaternion"
      },
      {
        "property": "useQuaternion",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "width",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "height",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      },
      {
        "property": "depth",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * cloneInstance()
   * - Clones an instance, when propagate is true, also clones the instance children
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstance(propagate = true) {

    //CUSTOM_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BEFORE_END

    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CLONE_INSTANCE_START
    /**
     * Override Template
     */
    //GENERATED_CLONE_INSTANCE_END

    //CUSTOM_CLONE_INSTANCE_START
    //CUSTOM_CLONE_INSTANCE_END

    //GENERATED_CLONE_INSTANCE_AFTER_START
    //GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  createInstance() {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  deleteInstance() {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Mesh.Type = 'R3EventObjComponentGraphicsMesh';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Mesh as default};
