//GENERATED_IMPORTS_START
import {default as GraphicsComponent} from '../event/obj/component/Graphics.js';
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START
import {default as ThreeRuntime} from '../runtime/graphics/Three.js';
//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
import Renderer from '../event/obj/component/graphics/Renderer.js';
import Scene from '../event/obj/component/graphics/Scene.js';
import Gl from '../event/obj/component/graphics/renderer/Gl.js';
import Camera from '../event/obj/component/graphics/Camera.js';
import Light from '../event/obj/component/graphics/Light.js';
import Canvas from '../event/obj/component/graphics/Canvas.js';
import {default as MathsComponent} from '../event/obj/component/Maths.js';
import Mesh from '../event/obj/component/graphics/Mesh.js';
import Utils from '../Utils.js';
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START
  RuntimeGraphicsThree = new ThreeRuntime() - Runtime implementation of R3RuntimeGraphics
 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Renderers = []
  Gls = []
  Scenes = []
  Cameras = []
  Lights = []
  Canvases = []
  Meshes = []
  RequestId = null
  LastRender = Date.now()
  CanRender = true
  Components = [] - A dictionary of Components which the system manages
  CurrentRenderer=null
  CurrentScene=null
  CurrentCamera=null
  CurrentLight=null
  CurrentCanvas=null
  ResizeListener=null
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.DO_NOT_RENDER
  Event.RENDER_OK
  Event.INITIALIZE_GRAPHICS_COMPONENT(70)
  Event.DISPOSE_GRAPHICS_COMPONENT(30)
  Event.BEFORE_ASSIGN_PROPERTY(40)
  Event.AFTER_ASSIGN_PROPERTY(40)
  Event.CREATE_INSTANCE(40)
  Event.UPDATE_INSTANCE(40)
  Event.DELETE_INSTANCE(10)
  Event.CLONE_INSTANCE(110)
  Event.GET_CURRENT_RENDERER(110)
  Event.GET_CURRENT_SCENE(110)
  Event.GET_CURRENT_CAMERA(110)
  Event.GET_CURRENT_LIGHT(110)
  Event.GET_CURRENT_CANVAS(110)
  Event.GET_THREE_INSTANCE(110)
  Event.WINDOW_RESIZE(10)
  Event.CANVAS_RESIZE(10)
  Event.GET_MESHES(40)
  Event.GET_MESH_INSTANCES(40)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START

 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  Event.GET_RUNTIME_GRAPHICS_THREE - Gets the Three runtime implementation of R3RuntimeGraphics
 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options) - Starts the system by registering subscriptions to events
  stop(options) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  Render() - Emits the RENDER_BEFORE and RENDER_AFTER events and will continue rendering until Stop() is called
 CUSTOM_STATIC_METHODS_END

 **/

export class Graphics extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Graphics already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Graphics');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Graphics already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Graphics');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Graphics.IsStarting) {
      console.log('client Graphics system is already starting...');
      return;
    }

    Graphics.IsStarting = true;

    if (Graphics.Started === true) {
      Graphics.IsStarting = false;
      console.log('client Graphics system already started');
      return;
    }

    Graphics.Runtimes = new Set();
    Graphics.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Graphics.Subscriptions['DO_NOT_RENDER'] = Event.On(
      Event.DO_NOT_RENDER,
      Graphics.DoNotRender
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['RENDER_OK'] = Event.On(
      Event.RENDER_OK,
      Graphics.RenderOk
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['INITIALIZE_GRAPHICS_COMPONENT'] = Event.On(
      Event.INITIALIZE_GRAPHICS_COMPONENT,
      Graphics.InitializeGraphicsComponent,
      {priority: 70}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['DISPOSE_GRAPHICS_COMPONENT'] = Event.On(
      Event.DISPOSE_GRAPHICS_COMPONENT,
      Graphics.DisposeGraphicsComponent,
      {priority: 30}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['BEFORE_ASSIGN_PROPERTY'] = Event.On(
      Event.BEFORE_ASSIGN_PROPERTY,
      Graphics.BeforeAssignProperty,
      {priority: 40}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['AFTER_ASSIGN_PROPERTY'] = Event.On(
      Event.AFTER_ASSIGN_PROPERTY,
      Graphics.AfterAssignProperty,
      {priority: 40}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['CREATE_INSTANCE'] = Event.On(
      Event.CREATE_INSTANCE,
      Graphics.CreateInstance,
      {priority: 40}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['UPDATE_INSTANCE'] = Event.On(
      Event.UPDATE_INSTANCE,
      Graphics.UpdateInstance,
      {priority: 40}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['DELETE_INSTANCE'] = Event.On(
      Event.DELETE_INSTANCE,
      Graphics.DeleteInstance,
      {priority: 10}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['CLONE_INSTANCE'] = Event.On(
      Event.CLONE_INSTANCE,
      Graphics.CloneInstance,
      {priority: 110}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['GET_CURRENT_RENDERER'] = Event.On(
      Event.GET_CURRENT_RENDERER,
      Graphics.GetCurrentRenderer,
      {priority: 110}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['GET_CURRENT_SCENE'] = Event.On(
      Event.GET_CURRENT_SCENE,
      Graphics.GetCurrentScene,
      {priority: 110}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['GET_CURRENT_CAMERA'] = Event.On(
      Event.GET_CURRENT_CAMERA,
      Graphics.GetCurrentCamera,
      {priority: 110}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['GET_CURRENT_LIGHT'] = Event.On(
      Event.GET_CURRENT_LIGHT,
      Graphics.GetCurrentLight,
      {priority: 110}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['GET_CURRENT_CANVAS'] = Event.On(
      Event.GET_CURRENT_CANVAS,
      Graphics.GetCurrentCanvas,
      {priority: 110}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['GET_THREE_INSTANCE'] = Event.On(
      Event.GET_THREE_INSTANCE,
      Graphics.GetThreeInstance,
      {priority: 110}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['WINDOW_RESIZE'] = Event.On(
      Event.WINDOW_RESIZE,
      Graphics.WindowResize,
      {priority: 10}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['CANVAS_RESIZE'] = Event.On(
      Event.CANVAS_RESIZE,
      Graphics.CanvasResize,
      {priority: 10}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['GET_MESHES'] = Event.On(
      Event.GET_MESHES,
      Graphics.GetMeshes,
      {priority: 40}
    );

    /**
     * No comment
     */
    Graphics.Subscriptions['GET_MESH_INSTANCES'] = Event.On(
      Event.GET_MESH_INSTANCES,
      Graphics.GetMeshInstances,
      {priority: 40}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    /**
     * Gets the Three runtime implementation of R3RuntimeGraphics
     */
    Graphics.Subscriptions['GET_RUNTIME_GRAPHICS_THREE'] = Event.On(
      Event.GET_RUNTIME_GRAPHICS_THREE,
      Graphics.GetRuntimeGraphicsThree
    );
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    /**
     * In case objects got initialized before we could warm the cache
     * @type {*[]}
     */
    Graphics.Renderers = [
      ...Graphics.Renderers,
      ...Utils.GetObjectsByConstructor(Renderer)
    ];

    Graphics.Gls = [
      ...Graphics.Gls,
      ...Utils.GetObjectsByConstructor(Gl)
    ];

    Graphics.Scenes = [
      ...Graphics.Scenes,
      ...Utils.GetObjectsByConstructor(Scene)
    ];

    Graphics.Cameras = [
      ...Graphics.Cameras,
      ...Utils.GetObjectsByConstructor(Camera)
    ];

    Graphics.Lights = [
      ...Graphics.Lights,
      ...Utils.GetObjectsByConstructor(Light)
    ];

    Graphics.Canvases = [
      ...Graphics.Canvases,
      ...Utils.GetObjectsByConstructor(Canvas)
    ];

    Graphics.Meshes = [
      ...Graphics.Meshes,
      ...Utils.GetObjectsByConstructor(Mesh)
    ];

    Graphics.Components = [
      ...Graphics.Components,
      ...Utils.GetObjectsByConstructor(GraphicsComponent)
    ];

    Graphics.ResizeListener = function(event) {

      let {target : window} = event;

      if (!window) {
        console.warn(`Could not determine window size`);
        return;
      }

      let {screen} = window;

      if (!screen) {
        console.warn(`Could not determine window size`);
        return;
      }

      Event.Emit(
        Event.WINDOW_RESIZE,
        {
          width : event.target.screen.innerWidth,
          height : event.target.screen.innerHeight
        }
      );
    };

    window.addEventListener("resize", Graphics.ResizeListener);
    window.addEventListener("visibilityChange", Graphics.ResizeListener);

    Graphics.Started = true;
    Graphics.IsStarting = false;

    Event.Emit(Event.WINDOW_RESIZE);

    console.log('Started client system Graphics');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    Graphics.Render();
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Graphics.IsStopping) {
      console.log('client Graphics system is already stopping');
      return;
    }

    Graphics.IsStopping = true;

    if (Graphics.Started === false) {
      Graphics.IsStopping = false;
      console.log('client Graphics system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    if (Graphics.RequestId) {
      window.cancelAnimationFrame(Graphics.RequestId);
      Graphics.RequestId = null;
    }

    if (Graphics.ResizeListener) {
      window.removeEventListener('resize', Graphics.ResizeListener);
      Graphics.ResizeListener = null;
    }
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    Graphics.Subscriptions['DO_NOT_RENDER'].remove();
    delete Graphics.Subscriptions['DO_NOT_RENDER'];

    Graphics.Subscriptions['RENDER_OK'].remove();
    delete Graphics.Subscriptions['RENDER_OK'];

    Graphics.Subscriptions['INITIALIZE_GRAPHICS_COMPONENT'].remove();
    delete Graphics.Subscriptions['INITIALIZE_GRAPHICS_COMPONENT'];

    Graphics.Subscriptions['DISPOSE_GRAPHICS_COMPONENT'].remove();
    delete Graphics.Subscriptions['DISPOSE_GRAPHICS_COMPONENT'];

    Graphics.Subscriptions['BEFORE_ASSIGN_PROPERTY'].remove();
    delete Graphics.Subscriptions['BEFORE_ASSIGN_PROPERTY'];

    Graphics.Subscriptions['AFTER_ASSIGN_PROPERTY'].remove();
    delete Graphics.Subscriptions['AFTER_ASSIGN_PROPERTY'];

    Graphics.Subscriptions['CREATE_INSTANCE'].remove();
    delete Graphics.Subscriptions['CREATE_INSTANCE'];

    Graphics.Subscriptions['UPDATE_INSTANCE'].remove();
    delete Graphics.Subscriptions['UPDATE_INSTANCE'];

    Graphics.Subscriptions['DELETE_INSTANCE'].remove();
    delete Graphics.Subscriptions['DELETE_INSTANCE'];

    Graphics.Subscriptions['CLONE_INSTANCE'].remove();
    delete Graphics.Subscriptions['CLONE_INSTANCE'];

    Graphics.Subscriptions['GET_CURRENT_RENDERER'].remove();
    delete Graphics.Subscriptions['GET_CURRENT_RENDERER'];

    Graphics.Subscriptions['GET_CURRENT_SCENE'].remove();
    delete Graphics.Subscriptions['GET_CURRENT_SCENE'];

    Graphics.Subscriptions['GET_CURRENT_CAMERA'].remove();
    delete Graphics.Subscriptions['GET_CURRENT_CAMERA'];

    Graphics.Subscriptions['GET_CURRENT_LIGHT'].remove();
    delete Graphics.Subscriptions['GET_CURRENT_LIGHT'];

    Graphics.Subscriptions['GET_CURRENT_CANVAS'].remove();
    delete Graphics.Subscriptions['GET_CURRENT_CANVAS'];

    Graphics.Subscriptions['GET_THREE_INSTANCE'].remove();
    delete Graphics.Subscriptions['GET_THREE_INSTANCE'];

    Graphics.Subscriptions['WINDOW_RESIZE'].remove();
    delete Graphics.Subscriptions['WINDOW_RESIZE'];

    Graphics.Subscriptions['CANVAS_RESIZE'].remove();
    delete Graphics.Subscriptions['CANVAS_RESIZE'];

    Graphics.Subscriptions['GET_MESHES'].remove();
    delete Graphics.Subscriptions['GET_MESHES'];

    Graphics.Subscriptions['GET_MESH_INSTANCES'].remove();
    delete Graphics.Subscriptions['GET_MESH_INSTANCES'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    Graphics.Subscriptions['GET_RUNTIME_GRAPHICS_THREE'].remove();
    delete Graphics.Subscriptions['GET_RUNTIME_GRAPHICS_THREE'];
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    for (let runtime of [...Graphics.Runtimes]) {
      for (let component of Graphics.Components) {
        delete runtime.validObjects[component.id];
      }
    }

    Graphics.Renderers = [];
    Graphics.Gls = [];
    Graphics.Scenes = [];
    Graphics.Cameras = [];
    Graphics.Lights = [];
    Graphics.Canvases = [];
    Graphics.Meshes = [];
    Graphics.Components = [];

    Graphics.Runtimes.clear();
    Graphics.Started = false;
    Graphics.IsStopping = false;
    console.log('Stopped client system Graphics');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START
    Graphics.Runtimes.add(Graphics.RuntimeGraphicsThree);
    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * Render()
   * - Emits the RENDER_BEFORE and RENDER_AFTER events and will continue rendering until Stop() is called
   * - No parameters
   * - No returns
   */
  static Render() {

    //GENERATED_STATIC_RENDER_START
    //GENERATED_STATIC_RENDER_END

    //CUSTOM_STATIC_RENDER_START
    if (!Graphics.CanRender) {
      window.cancelAnimationFrame(Graphics.RequestId);
      Graphics.RequestId = window.requestAnimationFrame( Graphics.Render );
      return;
    }

    let now = Date.now();

    let payload = {
      delta : now - Graphics.LastRender
    }

    Event.Emit(
      Event.RENDER_BEFORE,
      payload
    )

    Graphics.Runtimes.forEach(
      (runtime) => {
        for (let renderer of Graphics.Renderers) {
          runtime.render(renderer);
        }
      }
    )

    Graphics.RequestId = window.requestAnimationFrame( Graphics.Render );

    let diff = Date.now() - now;

    payload.diff = diff;

    Event.Emit(
      Event.RENDER_AFTER,
      payload
    );

    Graphics.LastRender = Date.now();
    //CUSTOM_STATIC_RENDER_END

    //GENERATED_STATIC_RENDER_AFTER_START
    //GENERATED_STATIC_RENDER_AFTER_END

    //CUSTOM_STATIC_GENERATED_RENDER_AFTER_START
    //CUSTOM_STATIC_GENERATED_RENDER_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * DoNotRender()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DoNotRender(event) {

    //GENERATED_STATIC_DO_NOT_RENDER_START
    //GENERATED_STATIC_DO_NOT_RENDER_END

    //CUSTOM_STATIC_DO_NOT_RENDER_START
    console.log('Request to disable rendering');
    Graphics.CanRender = false;
    //CUSTOM_STATIC_DO_NOT_RENDER_END

    //GENERATED_STATIC_DO_NOT_RENDER_AFTER_START
    //GENERATED_STATIC_DO_NOT_RENDER_AFTER_END

    //CUSTOM_STATIC_GENERATED_DO_NOT_RENDER_AFTER_START
    //CUSTOM_STATIC_GENERATED_DO_NOT_RENDER_AFTER_END

  }

  /**
   * RenderOk()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static RenderOk(event) {

    //GENERATED_STATIC_RENDER_OK_START
    //GENERATED_STATIC_RENDER_OK_END

    //CUSTOM_STATIC_RENDER_OK_START
    console.log('Request to enable rendering');
    Graphics.CanRender = true;
    //CUSTOM_STATIC_RENDER_OK_END

    //GENERATED_STATIC_RENDER_OK_AFTER_START
    //GENERATED_STATIC_RENDER_OK_AFTER_END

    //CUSTOM_STATIC_GENERATED_RENDER_OK_AFTER_START
    //CUSTOM_STATIC_GENERATED_RENDER_OK_AFTER_END

  }

  /**
   * InitializeGraphicsComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeGraphicsComponent(event) {

    //GENERATED_STATIC_INITIALIZE_GRAPHICS_COMPONENT_START
    //GENERATED_STATIC_INITIALIZE_GRAPHICS_COMPONENT_END

    //CUSTOM_STATIC_INITIALIZE_GRAPHICS_COMPONENT_START
    let {object: component} = event;

    if (component instanceof Renderer) {
      Graphics.Renderers.push(component);
    }

    if (component instanceof Gl) {
      Graphics.Gls.push(component);
      Graphics.CurrentRenderer = component;
    }

    if (component instanceof Scene) {
      Graphics.Scenes.push(component);
      Graphics.CurrentScene = component;
    }

    if (component instanceof Camera) {
      Graphics.Cameras.push(component);
      Graphics.CurrentCamera = component;
    }

    if (component instanceof Light) {
      Graphics.Lights.push(component);
      Graphics.CurrentLight = component;
    }

    if (component instanceof Canvas) {
      Graphics.Canvases.push(component);
      component.tabIndex = Graphics.Canvases.length;
      Graphics.CurrentCanvas = component;
    }

    if (component instanceof Mesh) {
      Graphics.Meshes.push(component);
    }

    if (component instanceof GraphicsComponent) {
      Graphics.Components.push(component);
      component.createInstance();
    }
    //CUSTOM_STATIC_INITIALIZE_GRAPHICS_COMPONENT_END

    //GENERATED_STATIC_INITIALIZE_GRAPHICS_COMPONENT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_GRAPHICS_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_GRAPHICS_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_GRAPHICS_COMPONENT_AFTER_END

  }

  /**
   * DisposeGraphicsComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeGraphicsComponent(event) {

    //GENERATED_STATIC_DISPOSE_GRAPHICS_COMPONENT_START
    //GENERATED_STATIC_DISPOSE_GRAPHICS_COMPONENT_END

    //CUSTOM_STATIC_DISPOSE_GRAPHICS_COMPONENT_START
    let {object: component} = event;

    if (component instanceof Renderer) {
      Graphics.Renderers = Graphics.Renderers.filter((renderer) => {return renderer !== component});
    }

    if (component instanceof Gl) {
      Graphics.Gls = Graphics.Gls.filter((gl) => {return gl !== component});
      if (Graphics.Gls.length) {
        Graphics.CurrentRenderer = Graphics.Gls[Graphics.Gls.length - 1];
      } else {
        Graphics.CurrentRenderer = null;
      }
    }

    if (component instanceof Scene) {
      Graphics.Scenes = Graphics.Scenes.filter((scene) => {return scene !== component});
      if (Graphics.Scenes.length) {
        Graphics.CurrentScene = Graphics.Scenes[Graphics.Scenes.length - 1];
      } else {
        Graphics.CurrentScene = null;
      }
    }

    if (component instanceof Camera) {
      Graphics.Cameras = Graphics.Cameras.filter((camera) => {return camera !== component});
      if (Graphics.Cameras.length) {
        Graphics.CurrentCamera = Graphics.Cameras[Graphics.Cameras.length - 1];
      } else {
        Graphics.CurrentCamera = null;
      }
    }

    if (component instanceof Light) {
      Graphics.Lights = Graphics.Lights.filter((light) => {return light !== component});
      if (Graphics.Lights.length) {
        Graphics.CurrentLight = Graphics.Lights[Graphics.Lights.length - 1];
      } else {
        Graphics.CurrentLight = null;
      }
    }

    if (component instanceof Canvas) {
      Graphics.Canvases = Graphics.Canvases.filter((canvas) => {return canvas !== component});
      if (Graphics.Canvases.length) {
        Graphics.CurrentCanvas = Graphics.Canvases[Graphics.Canvases.length - 1];
      } else {
        Graphics.CurrentCanvas = null;
      }
    }

    if (component instanceof Mesh) {
      Graphics.Meshes = Graphics.Meshes.filter((mesh) => {return mesh !== component});
    }

    Graphics.Components = Graphics.Components.filter((graphicsComponent) => {return graphicsComponent !== component});

    for (let runtime of [...Graphics.Runtimes]) {

      if (component instanceof GraphicsComponent) {
        System.DeleteRuntimeInstance(component, runtime, GraphicsComponent);
      }

      delete runtime.validObjects[component.id];
    }
    //CUSTOM_STATIC_DISPOSE_GRAPHICS_COMPONENT_END

    //GENERATED_STATIC_DISPOSE_GRAPHICS_COMPONENT_AFTER_START
    //GENERATED_STATIC_DISPOSE_GRAPHICS_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_GRAPHICS_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_GRAPHICS_COMPONENT_AFTER_END

  }

  /**
   * BeforeAssignProperty()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static BeforeAssignProperty(event) {

    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_START
    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_BEFORE_ASSIGN_PROPERTY_START
    let {object: component} = event;
    let {property, value} = event;

    if (
      (component instanceof Mesh) &&
      property === 'scene' &&
      value === null
    ) {
      component.deleteInstance();
    }

    if (
      (component instanceof Canvas) &&
      property === 'parentDomElementId' &&
      value === null
    ) {
      component.deleteInstance();
    }

    //
    // if (
    //   (component instanceof Camera) &&
    //   property === 'canvas' &&
    //   value === null
    // ) {
    //   component.deleteInstance();
    // }
    //CUSTOM_STATIC_BEFORE_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_BEFORE_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_BEFORE_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_BEFORE_ASSIGN_PROPERTY_AFTER_END

  }

  /**
   * AfterAssignProperty()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static AfterAssignProperty(event) {

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    let {object: component} = event;
    let {property, value} = event;

    let mathComponent = null;

    let graphicsComponent = null;

    if (component instanceof GraphicsComponent) {
      graphicsComponent = component;
    }

    if (component instanceof MathsComponent) {
      mathComponent = component;
    }

    let toProcess = [];

    if (graphicsComponent) {
      toProcess.push(
        {
          component : graphicsComponent,
          property,
          value
        }
      );
    }

    if (mathComponent) {
      for (let child of mathComponent.children) {
        if (child instanceof GraphicsComponent) {
          graphicsComponent = child;
          for (let tmpProperty of Object.keys(child.references)) {
            if (child[tmpProperty] === component) {
              property = tmpProperty;
              value = component;
              toProcess.push(
                {
                  component : child,
                  property : tmpProperty,
                  value : component
                }
              );
            }
          }
        }
      }
    }

    for (let item of toProcess) {

      component = item.component;
      property = item.property;
      value = item.value;

      for (let runtime of [...Graphics.Runtimes]) {

        let wasValid;
        let isValid;

        if (!runtime.validObjects.hasOwnProperty(component.id)) {
          wasValid = false;
          isValid = runtime.validate('createInstance', component);
        } else {
          wasValid = runtime.validObjects[component.id];
          isValid = runtime.validate("createInstance", component);
        }

        if (!wasValid && !isValid) {
          /**
           * Do nothing
           */
          return;
        }

        if (!wasValid && isValid) {
          System.CreateRuntimeInstance(component, runtime, GraphicsComponent);
          return;
        }

        if (wasValid && !isValid) {
          //CUSTOM_CREATE_OR_DELETE_INSTANCES_START
          /**
           * Graphics are important - so we try to create something to visualize even though
           * it may not be perfect. This is maybe a bit less important for abstract Math components
           */
          let instance = component.getInstance(runtime);

          if (!instance) {
            /**
             * This is for when an image gets an incorrect URL or gets disposed, the image is no longer valid,
             * it will cascade a 'delete instance' event down to the material. The material has to try to recover
             * because it does not need an image to be valid
             */
            System.CreateRuntimeChildrenInstances(component, runtime, GraphicsComponent);
          } else {
            System.DeleteRuntimeInstance(component, runtime, GraphicsComponent);
          }
          //CUSTOM_CREATE_OR_DELETE_INSTANCES_END
          return;
        }

        if (wasValid && isValid) {
          System.UpdateRuntimeInstance(component, runtime, GraphicsComponent, property, value);
        }
      }

      System.UpdateControl(component, property);
    }
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_END

  }

  /**
   * CreateInstance()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static CreateInstance(event) {

    //GENERATED_STATIC_CREATE_INSTANCE_START
    //CUSTOM_GENERATED_STATIC_CREATE_INSTANCE_START
    let {object: component} = event;

    for (let runtime of [...Graphics.Runtimes]) {
      System.CreateRuntimeInstance(component, runtime, GraphicsComponent);
    }
    //CUSTOM_GENERATED_STATIC_CREATE_INSTANCE_END
    //GENERATED_STATIC_CREATE_INSTANCE_END

    //CUSTOM_STATIC_CREATE_INSTANCE_START
    //CUSTOM_STATIC_CREATE_INSTANCE_END

    //GENERATED_STATIC_CREATE_INSTANCE_AFTER_START
    //GENERATED_STATIC_CREATE_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_CREATE_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * UpdateInstance()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static UpdateInstance(event) {

    //GENERATED_STATIC_UPDATE_INSTANCE_START
    //CUSTOM_GENERATED_STATIC_UPDATE_INSTANCE_START
    let {object: component, options} = event;

    let {property, value} = options;

    for (let runtime of [...Graphics.Runtimes]) {
      System.UpdateRuntimeInstance(component, runtime, GraphicsComponent, property, value);
    }

    System.UpdateControl(component, property);
    //CUSTOM_GENERATED_STATIC_UPDATE_INSTANCE_END
    //GENERATED_STATIC_UPDATE_INSTANCE_END

    //CUSTOM_STATIC_UPDATE_INSTANCE_START
    //CUSTOM_STATIC_UPDATE_INSTANCE_END

    //GENERATED_STATIC_UPDATE_INSTANCE_AFTER_START
    //GENERATED_STATIC_UPDATE_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPDATE_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  /**
   * DeleteInstance()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DeleteInstance(event) {

    //GENERATED_STATIC_DELETE_INSTANCE_START
    //CUSTOM_GENERATED_STATIC_DELETE_INSTANCE_START
    let {object: component} = event;

    for (let runtime of [...Graphics.Runtimes]) {
      System.DeleteRuntimeInstance(component, runtime, GraphicsComponent);
    }
    //CUSTOM_GENERATED_STATIC_DELETE_INSTANCE_END
    //GENERATED_STATIC_DELETE_INSTANCE_END

    //CUSTOM_STATIC_DELETE_INSTANCE_START
    //CUSTOM_STATIC_DELETE_INSTANCE_END

    //GENERATED_STATIC_DELETE_INSTANCE_AFTER_START
    //GENERATED_STATIC_DELETE_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_DELETE_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * CloneInstance()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static CloneInstance(event) {

    //GENERATED_STATIC_CLONE_INSTANCE_START
    //GENERATED_STATIC_CLONE_INSTANCE_END

    //CUSTOM_STATIC_CLONE_INSTANCE_START
    //CUSTOM_STATIC_CLONE_INSTANCE_END

    //GENERATED_STATIC_CLONE_INSTANCE_AFTER_START
    //GENERATED_STATIC_CLONE_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_CLONE_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * GetCurrentRenderer()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetCurrentRenderer(event) {

    //GENERATED_STATIC_GET_CURRENT_RENDERER_START
    //GENERATED_STATIC_GET_CURRENT_RENDERER_END

    //CUSTOM_STATIC_GET_CURRENT_RENDERER_START
    event.results = [Graphics.CurrentRenderer];
    //CUSTOM_STATIC_GET_CURRENT_RENDERER_END

    //GENERATED_STATIC_GET_CURRENT_RENDERER_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_RENDERER_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_RENDERER_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_RENDERER_AFTER_END

  }

  /**
   * GetCurrentScene()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetCurrentScene(event) {

    //GENERATED_STATIC_GET_CURRENT_SCENE_START
    //GENERATED_STATIC_GET_CURRENT_SCENE_END

    //CUSTOM_STATIC_GET_CURRENT_SCENE_START
    event.results = [Graphics.CurrentScene];
    //CUSTOM_STATIC_GET_CURRENT_SCENE_END

    //GENERATED_STATIC_GET_CURRENT_SCENE_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_SCENE_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_SCENE_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_SCENE_AFTER_END

  }

  /**
   * GetCurrentCamera()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetCurrentCamera(event) {

    //GENERATED_STATIC_GET_CURRENT_CAMERA_START
    //GENERATED_STATIC_GET_CURRENT_CAMERA_END

    //CUSTOM_STATIC_GET_CURRENT_CAMERA_START
    event.results = [Graphics.CurrentCamera];
    //CUSTOM_STATIC_GET_CURRENT_CAMERA_END

    //GENERATED_STATIC_GET_CURRENT_CAMERA_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_CAMERA_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_CAMERA_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_CAMERA_AFTER_END

  }

  /**
   * GetCurrentLight()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetCurrentLight(event) {

    //GENERATED_STATIC_GET_CURRENT_LIGHT_START
    //GENERATED_STATIC_GET_CURRENT_LIGHT_END

    //CUSTOM_STATIC_GET_CURRENT_LIGHT_START
    event.results = [Graphics.CurrentLight];
    //CUSTOM_STATIC_GET_CURRENT_LIGHT_END

    //GENERATED_STATIC_GET_CURRENT_LIGHT_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_LIGHT_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_LIGHT_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_LIGHT_AFTER_END

  }

  /**
   * GetCurrentCanvas()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetCurrentCanvas(event) {

    //GENERATED_STATIC_GET_CURRENT_CANVAS_START
    //GENERATED_STATIC_GET_CURRENT_CANVAS_END

    //CUSTOM_STATIC_GET_CURRENT_CANVAS_START
    event.results = [Graphics.CurrentCanvas];
    //CUSTOM_STATIC_GET_CURRENT_CANVAS_END

    //GENERATED_STATIC_GET_CURRENT_CANVAS_AFTER_START
    //GENERATED_STATIC_GET_CURRENT_CANVAS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_CURRENT_CANVAS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_CURRENT_CANVAS_AFTER_END

  }

  /**
   * GetThreeInstance()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetThreeInstance(event) {

    //GENERATED_STATIC_GET_THREE_INSTANCE_START
    //GENERATED_STATIC_GET_THREE_INSTANCE_END

    //CUSTOM_STATIC_GET_THREE_INSTANCE_START
    for (let runtime of [...Graphics.Runtimes]) {
      if (runtime instanceof ThreeRuntime) {
        event.results = [runtime.instance];
        return;
      }
    }
    event.results = [null];
    //CUSTOM_STATIC_GET_THREE_INSTANCE_END

    //GENERATED_STATIC_GET_THREE_INSTANCE_AFTER_START
    //GENERATED_STATIC_GET_THREE_INSTANCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_THREE_INSTANCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_THREE_INSTANCE_AFTER_END

  }

  /**
   * WindowResize()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static WindowResize(event) {

    //GENERATED_STATIC_WINDOW_RESIZE_START
    //GENERATED_STATIC_WINDOW_RESIZE_END

    //CUSTOM_STATIC_WINDOW_RESIZE_START
    console.log('window resize event');

    let {width, height} = event;

    if (typeof width === 'undefined') {
      width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      event.width = width;
    }

    if (typeof height === 'undefined') {
      height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      event.height = height;
    }

    for (let canvas of Graphics.Canvases) {
      if (canvas.fullScreen) {
        canvas.width = width;
        canvas.height = height;
      }
    }
    // if (window.matchMedia('(spanning: single-fold-vertical)').matches ||
    //   window.matchMedia('(spanning: single-fold-horizontal)').matches) {

    /**
     * Of course m$ wants to bring out dual displays but can't get their
     * f*n story straight - the code below never executes - lets wait for
     * m$ to f*n wake up one day
     */
    // if (window.matchMedia('(display-mode: dual-screen)').matches) {
    //   Event.Emit(
    //     Event.DUAL_SCREEN_MODE,
    //     {
    //       width,
    //       height
    //     }
    //   )
    // }
    //CUSTOM_STATIC_WINDOW_RESIZE_END

    //GENERATED_STATIC_WINDOW_RESIZE_AFTER_START
    //GENERATED_STATIC_WINDOW_RESIZE_AFTER_END

    //CUSTOM_STATIC_GENERATED_WINDOW_RESIZE_AFTER_START
    //CUSTOM_STATIC_GENERATED_WINDOW_RESIZE_AFTER_END

  }

  /**
   * CanvasResize()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static CanvasResize(event) {

    //GENERATED_STATIC_CANVAS_RESIZE_START
    //GENERATED_STATIC_CANVAS_RESIZE_END

    //CUSTOM_STATIC_CANVAS_RESIZE_START
    //CUSTOM_STATIC_CANVAS_RESIZE_END

    //GENERATED_STATIC_CANVAS_RESIZE_AFTER_START
    //GENERATED_STATIC_CANVAS_RESIZE_AFTER_END

    //CUSTOM_STATIC_GENERATED_CANVAS_RESIZE_AFTER_START
    //CUSTOM_STATIC_GENERATED_CANVAS_RESIZE_AFTER_END

  }

  /**
   * GetMeshes()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetMeshes(event) {

    //GENERATED_STATIC_GET_MESHES_START
    //GENERATED_STATIC_GET_MESHES_END

    //CUSTOM_STATIC_GET_MESHES_START
    event.results = [...Graphics.Meshes];
    //CUSTOM_STATIC_GET_MESHES_END

    //GENERATED_STATIC_GET_MESHES_AFTER_START
    //GENERATED_STATIC_GET_MESHES_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_MESHES_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_MESHES_AFTER_END

  }

  /**
   * GetMeshInstances()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static GetMeshInstances(event) {

    //GENERATED_STATIC_GET_MESH_INSTANCES_START
    //GENERATED_STATIC_GET_MESH_INSTANCES_END

    //CUSTOM_STATIC_GET_MESH_INSTANCES_START
    let instances = [];

    [...Graphics.Meshes].map(
      (mesh) => {
        let instance = mesh.getInstance(this);
        if (instance) {
          instances.push(instance);
        }
      }
    );

    event.results = instances;
    //CUSTOM_STATIC_GET_MESH_INSTANCES_END

    //GENERATED_STATIC_GET_MESH_INSTANCES_AFTER_START
    //GENERATED_STATIC_GET_MESH_INSTANCES_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_MESH_INSTANCES_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_MESH_INSTANCES_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  /**
   * GetRuntimeGraphicsThree()
   * - Gets the Three runtime implementation of R3RuntimeGraphics
   * @param {Object} event
   * - No returns
   */
  static GetRuntimeGraphicsThree(event) {

    //GENERATED_STATIC_GET_RUNTIME_GRAPHICS_THREE_START
    event.results = [Graphics.RuntimeGraphicsThree];
    //GENERATED_STATIC_GET_RUNTIME_GRAPHICS_THREE_END

    //CUSTOM_STATIC_GET_RUNTIME_GRAPHICS_THREE_START
    //CUSTOM_STATIC_GET_RUNTIME_GRAPHICS_THREE_END

    //GENERATED_STATIC_GET_RUNTIME_GRAPHICS_THREE_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_GRAPHICS_THREE_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_GRAPHICS_THREE_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_GRAPHICS_THREE_AFTER_END

  }
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
/**
 * @param Graphics.RuntimeGraphicsThree
 * - Runtime implementation of R3RuntimeGraphics
 */
Graphics.RuntimeGraphicsThree = new ThreeRuntime();
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Graphics.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Graphics.IsStarting = false;

/**
 * @param Graphics.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Graphics.IsStopping = false;

/**
 * @param Graphics.Started
 * - Indicates whether this system is running
 */
Graphics.Started = false;

/**
 * @param Graphics.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Graphics.Subscriptions = {};

/**
 * @param Graphics.Runtimes
 * - A set of runtimes which the system manages
 */
Graphics.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Graphics.Renderers
 * - No comment
 */
Graphics.Renderers = [];

/**
 * @param Graphics.Gls
 * - No comment
 */
Graphics.Gls = [];

/**
 * @param Graphics.Scenes
 * - No comment
 */
Graphics.Scenes = [];

/**
 * @param Graphics.Cameras
 * - No comment
 */
Graphics.Cameras = [];

/**
 * @param Graphics.Lights
 * - No comment
 */
Graphics.Lights = [];

/**
 * @param Graphics.Canvases
 * - No comment
 */
Graphics.Canvases = [];

/**
 * @param Graphics.Meshes
 * - No comment
 */
Graphics.Meshes = [];

/**
 * @param Graphics.RequestId
 * - No comment
 */
Graphics.RequestId = null;

/**
 * @param Graphics.LastRender
 * - No comment
 */
Graphics.LastRender = Date.now();

/**
 * @param Graphics.CanRender
 * - No comment
 */
Graphics.CanRender = true;

/**
 * @param Graphics.Components
 * - A dictionary of Components which the system manages
 */
Graphics.Components = [];

/**
 * @param Graphics.CurrentRenderer
 * - No comment
 */
Graphics.CurrentRenderer = null;

/**
 * @param Graphics.CurrentScene
 * - No comment
 */
Graphics.CurrentScene = null;

/**
 * @param Graphics.CurrentCamera
 * - No comment
 */
Graphics.CurrentCamera = null;

/**
 * @param Graphics.CurrentLight
 * - No comment
 */
Graphics.CurrentLight = null;

/**
 * @param Graphics.CurrentCanvas
 * - No comment
 */
Graphics.CurrentCanvas = null;

/**
 * @param Graphics.ResizeListener
 * - No comment
 */
Graphics.ResizeListener = null;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Graphics as default};
