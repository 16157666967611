//GENERATED_IMPORTS_START
import {default as CodeComponent} from '../event/obj/component/Code.js';
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_INTERFACE_IMPORTS_START
import {default as AceRuntime} from '../runtime/code/Ace.js';
//GENERATED_RUNTIME_INTERFACE_IMPORTS_END

//CUSTOM_IMPORTS_START
import Utils from '../Utils.js';
//CUSTOM_IMPORTS_END

import Event from '../Event.js';
import System from '../System.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
  started=false - Indicates whether this system is running
  subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
 CUSTOM_OPTIONS_END

 RUNTIME_STATIC_OPTIONS_START
  RuntimeCodeAce = new AceRuntime() - Runtime implementation of R3RuntimeCode
 RUNTIME_STATIC_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
  IsStarting=false - Indicates whether this system is in a starting phase
  IsStopping=false - Indicates whether this system is in a stopping phase
  Started=false - Indicates whether this system is running
  Subscriptions={} - An association object which hold the subscription handles for Events this system is listening to. The system can stop receiving events by calling remove() on a handle.
  Runtimes = new Set() - A set of runtimes which the system manages
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Components = [] - A List of code components for which there are editors
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_EVENT_LISTENERS_START
 TEMPLATE_EVENT_LISTENERS_END

 CUSTOM_EVENT_LISTENERS_START
 CUSTOM_EVENT_LISTENERS_END

 TEMPLATE_STATIC_EVENT_LISTENERS_START
 TEMPLATE_STATIC_EVENT_LISTENERS_END

 CUSTOM_STATIC_EVENT_LISTENERS_START
  Event.INITIALIZE_CODE_COMPONENT(30)
  Event.DISPOSE_CODE_COMPONENT(70)
  Event.AFTER_ASSIGN_PROPERTY(70)
  Event.CREATE_EDITOR_CODE(40)
  Event.UPDATE_EDITOR_CODE(40)
  Event.DELETE_EDITOR_CODE(40)
 CUSTOM_STATIC_EVENT_LISTENERS_END

 INSTANCE_STATIC_EVENT_LISTENERS_START

 INSTANCE_STATIC_EVENT_LISTENERS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  Event.GET_RUNTIME_CODE_ACE - Gets the Ace runtime implementation of R3RuntimeCode
 RUNTIME_STATIC_GET_EVENT_LISTENERS_END

 CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
 CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

 CUSTOM_METHODS_START
  start(options = {}) - Starts the system by registering subscriptions to events
  stop(options = {}) - Stops the system by removing these subscriptions to events
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
  Start(options = {}) - Starts the system by registering subscriptions to events
  Stop(options = {}) - Stops the system by removing these subscriptions to events
  SetupRuntimes() - Sets up the runtimes for storage to start them during startup
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Code extends System {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param started
     * - Indicates whether this system is running
     */
    if (typeof options.started === 'undefined') {
      options.started = false;
    }

    /**
     * @param subscriptions
     * - An association object which hold the subscription handles for Events this system is listening to. The system can
     *   stop receiving events by calling remove() on a handle.
     */
    if (typeof options.subscriptions === 'undefined') {
      options.subscriptions = {};
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  start(options = {}) {

    //CUSTOM_START_BEFORE_START
    //CUSTOM_START_BEFORE_END

    //GENERATED_START_BEFORE_START
    //GENERATED_START_BEFORE_END

    //CUSTOM_START_BEFORE_GENERATED_START
    //CUSTOM_START_BEFORE_GENERATED_END

    //GENERATED_START_START
    if (this.started === true) {
      console.log('Code already started');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_START_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_START_END

    //CUSTOM_BEFORE_SYSTEM_START_START
    //CUSTOM_BEFORE_SYSTEM_START_END

    this.started = true;

    console.log('Started transient system: Code');
    //GENERATED_START_END

    //CUSTOM_START_START
    //CUSTOM_START_END

    //GENERATED_START_AFTER_START
    //GENERATED_START_AFTER_END

  }

  /**
   * stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  stop(options = {}) {

    //CUSTOM_STOP_BEFORE_START
    //CUSTOM_STOP_BEFORE_END

    //GENERATED_STOP_BEFORE_START
    //GENERATED_STOP_BEFORE_END

    //CUSTOM_STOP_BEFORE_GENERATED_START
    //CUSTOM_STOP_BEFORE_GENERATED_END

    //GENERATED_STOP_START
    if (this.started === false) {
      console.log('Code already stopped');
      return;
    }

    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_START
    //GENERATED_CUSTOM_EVENT_LISTENERS_STOP_END

    //CUSTOM_BEFORE_SYSTEM_STOP_START
    //CUSTOM_BEFORE_SYSTEM_STOP_END

    this.started = false;

    console.log('Stopped transient system: Code');
    //GENERATED_STOP_END

    //CUSTOM_STOP_START
    //CUSTOM_STOP_END

    //GENERATED_STOP_AFTER_START
    //GENERATED_STOP_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  /**
   * Start()
   * - Starts the system by registering subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Start(options = {}) {

    //GENERATED_STATIC_START_START
    if (Code.IsStarting) {
      console.log('client Code system is already starting...');
      return;
    }

    Code.IsStarting = true;

    if (Code.Started === true) {
      Code.IsStarting = false;
      console.log('client Code system already started');
      return;
    }

    Code.Runtimes = new Set();
    Code.SetupRuntimes();

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_START
    /**
     * No comment
     */
    Code.Subscriptions['INITIALIZE_CODE_COMPONENT'] = Event.On(
      Event.INITIALIZE_CODE_COMPONENT,
      Code.InitializeCodeComponent,
      {priority: 30}
    );

    /**
     * No comment
     */
    Code.Subscriptions['DISPOSE_CODE_COMPONENT'] = Event.On(
      Event.DISPOSE_CODE_COMPONENT,
      Code.DisposeCodeComponent,
      {priority: 70}
    );

    /**
     * No comment
     */
    Code.Subscriptions['AFTER_ASSIGN_PROPERTY'] = Event.On(
      Event.AFTER_ASSIGN_PROPERTY,
      Code.AfterAssignProperty,
      {priority: 70}
    );

    /**
     * No comment
     */
    Code.Subscriptions['CREATE_EDITOR_CODE'] = Event.On(
      Event.CREATE_EDITOR_CODE,
      Code.CreateEditorCode,
      {priority: 40}
    );

    /**
     * No comment
     */
    Code.Subscriptions['UPDATE_EDITOR_CODE'] = Event.On(
      Event.UPDATE_EDITOR_CODE,
      Code.UpdateEditorCode,
      {priority: 40}
    );

    /**
     * No comment
     */
    Code.Subscriptions['DELETE_EDITOR_CODE'] = Event.On(
      Event.DELETE_EDITOR_CODE,
      Code.DeleteEditorCode,
      {priority: 40}
    );
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_START
    /**
     * Gets the Ace runtime implementation of R3RuntimeCode
     */
    Code.Subscriptions['GET_RUNTIME_CODE_ACE'] = Event.On(
      Event.GET_RUNTIME_CODE_ACE,
      Code.GetRuntimeCodeAce
    );
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_START
    Code.Components = Utils.GetObjectsByConstructor(CodeComponent);

    for (let component of Code.Components) {
      component.createEditor();
    }

    Code.Started = true;
    Code.IsStarting = false;

    Event.Emit(Event.CODE_SYSTEM_STARTED);

    console.log('Started client system Code');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_START_END

    //CUSTOM_BEFORE_STATIC_SYSTEM_START_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_START_END
    //GENERATED_STATIC_START_END

    //CUSTOM_STATIC_START_START
    //CUSTOM_STATIC_START_END

    //GENERATED_STATIC_START_AFTER_START
    //GENERATED_STATIC_START_AFTER_END

    //CUSTOM_STATIC_GENERATED_START_AFTER_START
    //CUSTOM_STATIC_GENERATED_START_AFTER_END

  }
  /**
   * Stop()
   * - Stops the system by removing these subscriptions to events
   * @param {Object} [options={}]
   * - No returns
   */
  static Stop(options = {}) {

    //GENERATED_STATIC_STOP_START
    if (Code.IsStopping) {
      console.log('client Code system is already stopping');
      return;
    }

    Code.IsStopping = true;

    if (Code.Started === false) {
      Code.IsStopping = false;
      console.log('client Code system already stopped');
      return;
    }

    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_START
    //CUSTOM_BEFORE_STATIC_SYSTEM_STOP_END

    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_START
    Code.Subscriptions['INITIALIZE_CODE_COMPONENT'].remove();
    delete Code.Subscriptions['INITIALIZE_CODE_COMPONENT'];

    Code.Subscriptions['DISPOSE_CODE_COMPONENT'].remove();
    delete Code.Subscriptions['DISPOSE_CODE_COMPONENT'];

    Code.Subscriptions['AFTER_ASSIGN_PROPERTY'].remove();
    delete Code.Subscriptions['AFTER_ASSIGN_PROPERTY'];

    Code.Subscriptions['CREATE_EDITOR_CODE'].remove();
    delete Code.Subscriptions['CREATE_EDITOR_CODE'];

    Code.Subscriptions['UPDATE_EDITOR_CODE'].remove();
    delete Code.Subscriptions['UPDATE_EDITOR_CODE'];

    Code.Subscriptions['DELETE_EDITOR_CODE'].remove();
    delete Code.Subscriptions['DELETE_EDITOR_CODE'];
    //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_STOP_END

    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_START
    Code.Subscriptions['GET_RUNTIME_CODE_ACE'].remove();
    delete Code.Subscriptions['GET_RUNTIME_CODE_ACE'];
    //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_STOP_END

    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_START
    //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_STOP_END

    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_START
    for (let component of Code.Components) {
      for (let runtime of [...Code.Runtimes]) {
        runtime.deleteEditor(component);
        component.setInstance(runtime, null);
      }
    }

    Code.Components = [];

    Code.Runtimes.clear();
    Code.Started = false;
    Code.IsStopping = false;

    Event.Emit(Event.CODE_SYSTEM_STOPPED);

    console.log('Stopped client system Code');
    //CUSTOM_DEFAULT_STATIC_SYSTEM_STOP_END

    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_START
    //CUSTOM_AFTER_STATIC_SYSTEM_STOP_END

    //GENERATED_STATIC_STOP_END

    //CUSTOM_STATIC_STOP_START
    //CUSTOM_STATIC_STOP_END

    //GENERATED_STATIC_STOP_AFTER_START
    //GENERATED_STATIC_STOP_AFTER_END

    //CUSTOM_STATIC_GENERATED_STOP_AFTER_START
    //CUSTOM_STATIC_GENERATED_STOP_AFTER_END

  }
  /**
   * SetupRuntimes()
   * - Sets up the runtimes for storage to start them during startup
   * - No parameters
   * - No returns
   */
  static SetupRuntimes() {

    //GENERATED_STATIC_SETUP_RUNTIMES_START
    Code.Runtimes.add(Code.RuntimeCodeAce);
    //GENERATED_STATIC_SETUP_RUNTIMES_END

    //CUSTOM_STATIC_SETUP_RUNTIMES_START
    //CUSTOM_STATIC_SETUP_RUNTIMES_END

    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_START
    //GENERATED_STATIC_SETUP_RUNTIMES_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_RUNTIMES_AFTER_END

  }
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //GENERATED_CUSTOM_EVENT_LISTENERS_START
  //GENERATED_CUSTOM_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_EVENT_LISTENERS_END

  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_START
  //GENERATED_TEMPLATE_STATIC_EVENT_LISTENERS_END

  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_START
  /**
   * InitializeCodeComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static InitializeCodeComponent(event) {

    //GENERATED_STATIC_INITIALIZE_CODE_COMPONENT_START
    //GENERATED_STATIC_INITIALIZE_CODE_COMPONENT_END

    //CUSTOM_STATIC_INITIALIZE_CODE_COMPONENT_START
    let {object: component} = event;

    Code.Components.push(component);

    component.createEditor();
    //CUSTOM_STATIC_INITIALIZE_CODE_COMPONENT_END

    //GENERATED_STATIC_INITIALIZE_CODE_COMPONENT_AFTER_START
    //GENERATED_STATIC_INITIALIZE_CODE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_INITIALIZE_CODE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_INITIALIZE_CODE_COMPONENT_AFTER_END

  }

  /**
   * DisposeCodeComponent()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DisposeCodeComponent(event) {

    //GENERATED_STATIC_DISPOSE_CODE_COMPONENT_START
    //GENERATED_STATIC_DISPOSE_CODE_COMPONENT_END

    //CUSTOM_STATIC_DISPOSE_CODE_COMPONENT_START
    let {object: component} = event;

    Code.Components = Code.Components.filter((code) => {return code !== component});

    component.deleteEditor();
    //CUSTOM_STATIC_DISPOSE_CODE_COMPONENT_END

    //GENERATED_STATIC_DISPOSE_CODE_COMPONENT_AFTER_START
    //GENERATED_STATIC_DISPOSE_CODE_COMPONENT_AFTER_END

    //CUSTOM_STATIC_GENERATED_DISPOSE_CODE_COMPONENT_AFTER_START
    //CUSTOM_STATIC_GENERATED_DISPOSE_CODE_COMPONENT_AFTER_END

  }

  /**
   * AfterAssignProperty()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static AfterAssignProperty(event) {

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_START
    let {object: component} = event;

    if (!(component instanceof CodeComponent)) {
      return;
    }

    let {property, value} = event;

    if (property === 'name' && value) {
      let name = `//@ sourceURL=${Utils.LowerSnakeCase(value)}.js`
      component.code = component.code.replace(/\/\/@\s*sourceURL\s*=.*/, name);
      component.updateEditor(property, value);
      return;
    }

    if (!component.fromUser) {
      /**
       * Update the editor because the changes are coming from outside the editor
       */
      component.updateEditor(property, value);
    }

    delete component.fromUser;
    //CUSTOM_GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_END

    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_START
    //CUSTOM_STATIC_AFTER_ASSIGN_PROPERTY_END

    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_START
    //GENERATED_STATIC_AFTER_ASSIGN_PROPERTY_AFTER_END

    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_START
    //CUSTOM_STATIC_GENERATED_AFTER_ASSIGN_PROPERTY_AFTER_END

  }

  /**
   * CreateEditorCode()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static CreateEditorCode(event) {

    //GENERATED_STATIC_CREATE_EDITOR_CODE_START
    //GENERATED_STATIC_CREATE_EDITOR_CODE_END

    //CUSTOM_STATIC_CREATE_EDITOR_CODE_START
    let {object: component} = event;

    if (!(component instanceof CodeComponent)) {
      return;
    }

    for (let runtime of [...Code.Runtimes]) {
      let instance = runtime.createEditor(component);
      component.setInstance(runtime, instance);
    }
    //CUSTOM_STATIC_CREATE_EDITOR_CODE_END

    //GENERATED_STATIC_CREATE_EDITOR_CODE_AFTER_START
    //GENERATED_STATIC_CREATE_EDITOR_CODE_AFTER_END

    //CUSTOM_STATIC_GENERATED_CREATE_EDITOR_CODE_AFTER_START
    //CUSTOM_STATIC_GENERATED_CREATE_EDITOR_CODE_AFTER_END

  }

  /**
   * UpdateEditorCode()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static UpdateEditorCode(event) {

    //GENERATED_STATIC_UPDATE_EDITOR_CODE_START
    //GENERATED_STATIC_UPDATE_EDITOR_CODE_END

    //CUSTOM_STATIC_UPDATE_EDITOR_CODE_START
    let {object: component, options} = event;

    if (!(component instanceof CodeComponent)) {
      return;
    }

    let {property, value} = options;

    for (let runtime of [...Code.Runtimes]) {
      let instance = component.getInstance(runtime);
      if (!instance) {
        continue;
      }
      runtime.updateEditor(component, property, value);
    }
    //CUSTOM_STATIC_UPDATE_EDITOR_CODE_END

    //GENERATED_STATIC_UPDATE_EDITOR_CODE_AFTER_START
    //GENERATED_STATIC_UPDATE_EDITOR_CODE_AFTER_END

    //CUSTOM_STATIC_GENERATED_UPDATE_EDITOR_CODE_AFTER_START
    //CUSTOM_STATIC_GENERATED_UPDATE_EDITOR_CODE_AFTER_END

  }

  /**
   * DeleteEditorCode()
   * - No comment
   * @param {Object} event
   * - No returns
   */
  static DeleteEditorCode(event) {

    //GENERATED_STATIC_DELETE_EDITOR_CODE_START
    //GENERATED_STATIC_DELETE_EDITOR_CODE_END

    //CUSTOM_STATIC_DELETE_EDITOR_CODE_START
    let {object: component} = event;

    if (!(component instanceof CodeComponent)) {
      return;
    }

    for (let runtime of [...Code.Runtimes]) {

      let instance = component.getInstance(runtime);
      if (!instance) {
        continue;
      }

      runtime.deleteEditor(component);
      component.setInstance(runtime, null);
    }
    //CUSTOM_STATIC_DELETE_EDITOR_CODE_END

    //GENERATED_STATIC_DELETE_EDITOR_CODE_AFTER_START
    //GENERATED_STATIC_DELETE_EDITOR_CODE_AFTER_END

    //CUSTOM_STATIC_GENERATED_DELETE_EDITOR_CODE_AFTER_START
    //CUSTOM_STATIC_GENERATED_DELETE_EDITOR_CODE_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_EVENT_LISTENERS_END

  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_START
  //GENERATED_INSTANCE_STATIC_EVENT_LISTENERS_END

  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_START
  /**
   * GetRuntimeCodeAce()
   * - Gets the Ace runtime implementation of R3RuntimeCode
   * @param {Object} event
   * - No returns
   */
  static GetRuntimeCodeAce(event) {

    //GENERATED_STATIC_GET_RUNTIME_CODE_ACE_START
    event.results = [Code.RuntimeCodeAce];
    //GENERATED_STATIC_GET_RUNTIME_CODE_ACE_END

    //CUSTOM_STATIC_GET_RUNTIME_CODE_ACE_START
    //CUSTOM_STATIC_GET_RUNTIME_CODE_ACE_END

    //GENERATED_STATIC_GET_RUNTIME_CODE_ACE_AFTER_START
    //GENERATED_STATIC_GET_RUNTIME_CODE_ACE_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_CODE_ACE_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_RUNTIME_CODE_ACE_AFTER_END

  }
  //GENERATED_RUNTIME_STATIC_GET_EVENT_LISTENERS_END

  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_START
  //GENERATED_CONSTRUCTOR_STATIC_EVENT_LISTENERS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_RUNTIME_STATIC_OPTIONS_START
/**
 * @param Code.RuntimeCodeAce
 * - Runtime implementation of R3RuntimeCode
 */
Code.RuntimeCodeAce = new AceRuntime();
//GENERATED_RUNTIME_STATIC_OPTIONS_END

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
/**
 * @param Code.IsStarting
 * - Indicates whether this system is in a starting phase
 */
Code.IsStarting = false;

/**
 * @param Code.IsStopping
 * - Indicates whether this system is in a stopping phase
 */
Code.IsStopping = false;

/**
 * @param Code.Started
 * - Indicates whether this system is running
 */
Code.Started = false;

/**
 * @param Code.Subscriptions
 * - An association object which hold the subscription handles for Events this system is listening to. The system can
 *   stop receiving events by calling remove() on a handle.
 */
Code.Subscriptions = {};

/**
 * @param Code.Runtimes
 * - A set of runtimes which the system manages
 */
Code.Runtimes = new Set();
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Code.Components
 * - A List of code components for which there are editors
 */
Code.Components = [];
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Code as default};
