//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_CLIENT_IMPORTS_START
import Runtime from '../../Runtime.js';
//GENERATED_CLIENT_IMPORTS_END

//CUSTOM_IMPORTS_START
import * as THREE from 'three';
//CUSTOM_IMPORTS_END

import Maths from '../Maths.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 INHERITED_METHODS_START
 INHERITED_METHODS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_INTERFACE_METHODS_START
  cloneInstance(object, propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance(object, _child = null, _parent = null) - Creates a math runtime instance for this runtime based on the R3 Object 
  deleteInstance(object, _child = null, _parent = null) - Deletes a math runtime instance for this runtime based on the R3 Object 
  updateInstance(object, property, value) - Updates the math runtime instance of the R3 Object based on the options 
 CUSTOM_INTERFACE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  cloneInstance(object, propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance(object) - Creates a math runtime instance for this runtime based on the R3 Object 
  deleteInstance(object) - Deletes a math runtime instance for this runtime based on the R3 Object 
  updateInstance(object, property, value) - Updates the math runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 INSTANCE_METHODS_START
  cloneInstanceColor(object, propagate = true) - Three implementation for [R3EventObjComponentMathsColor] 
  createInstanceColor(object) - Three implementation for [R3EventObjComponentMathsColor] 
  deleteInstanceColor(object) - Three implementation for [R3EventObjComponentMathsColor] 
  updateInstanceColor(object, property, value) - Three implementation for [R3EventObjComponentMathsColor] 
  cloneInstanceFace(object, propagate = true) - Three implementation for [R3EventObjComponentMathsFace] 
  createInstanceFace(object) - Three implementation for [R3EventObjComponentMathsFace] 
  deleteInstanceFace(object) - Three implementation for [R3EventObjComponentMathsFace] 
  updateInstanceFace(object, property, value) - Three implementation for [R3EventObjComponentMathsFace] 
  cloneInstanceMatrix3(object, propagate = true) - Three implementation for [R3EventObjComponentMathsMatrix3] 
  createInstanceMatrix3(object) - Three implementation for [R3EventObjComponentMathsMatrix3] 
  deleteInstanceMatrix3(object) - Three implementation for [R3EventObjComponentMathsMatrix3] 
  updateInstanceMatrix3(object, property, value) - Three implementation for [R3EventObjComponentMathsMatrix3] 
  cloneInstanceMatrix4(object, propagate = true) - Three implementation for [R3EventObjComponentMathsMatrix4] 
  createInstanceMatrix4(object) - Three implementation for [R3EventObjComponentMathsMatrix4] 
  deleteInstanceMatrix4(object) - Three implementation for [R3EventObjComponentMathsMatrix4] 
  updateInstanceMatrix4(object, property, value) - Three implementation for [R3EventObjComponentMathsMatrix4] 
  cloneInstancePlaneMaths(object, propagate = true) - Three implementation for [R3EventObjComponentMathsPlane] 
  createInstancePlaneMaths(object) - Three implementation for [R3EventObjComponentMathsPlane] 
  deleteInstancePlaneMaths(object) - Three implementation for [R3EventObjComponentMathsPlane] 
  updateInstancePlaneMaths(object, property, value) - Three implementation for [R3EventObjComponentMathsPlane] 
  cloneInstanceQuaternion(object, propagate = true) - Three implementation for [R3EventObjComponentMathsQuaternion] 
  createInstanceQuaternion(object) - Three implementation for [R3EventObjComponentMathsQuaternion] 
  deleteInstanceQuaternion(object) - Three implementation for [R3EventObjComponentMathsQuaternion] 
  updateInstanceQuaternion(object, property, value) - Three implementation for [R3EventObjComponentMathsQuaternion] 
  cloneInstanceRay(object, propagate = true) - Three implementation for [R3EventObjComponentMathsRay] 
  createInstanceRay(object) - Three implementation for [R3EventObjComponentMathsRay] 
  deleteInstanceRay(object) - Three implementation for [R3EventObjComponentMathsRay] 
  updateInstanceRay(object, property, value) - Three implementation for [R3EventObjComponentMathsRay] 
  cloneInstanceSphereMaths(object, propagate = true) - Three implementation for [R3EventObjComponentMathsSphere] 
  createInstanceSphereMaths(object) - Three implementation for [R3EventObjComponentMathsSphere] 
  deleteInstanceSphereMaths(object) - Three implementation for [R3EventObjComponentMathsSphere] 
  updateInstanceSphereMaths(object, property, value) - Three implementation for [R3EventObjComponentMathsSphere] 
  cloneInstanceUV(object, propagate = true) - Three implementation for [R3EventObjComponentMathsUV] 
  createInstanceUV(object) - Three implementation for [R3EventObjComponentMathsUV] 
  deleteInstanceUV(object) - Three implementation for [R3EventObjComponentMathsUV] 
  updateInstanceUV(object, property, value) - Three implementation for [R3EventObjComponentMathsUV] 
  cloneInstanceVector2(object, propagate = true) - Three implementation for [R3EventObjComponentMathsVector2] 
  createInstanceVector2(object) - Three implementation for [R3EventObjComponentMathsVector2] 
  deleteInstanceVector2(object) - Three implementation for [R3EventObjComponentMathsVector2] 
  updateInstanceVector2(object, property, value) - Three implementation for [R3EventObjComponentMathsVector2] 
  cloneInstanceVector3(object, propagate = true) - Three implementation for [R3EventObjComponentMathsVector3] 
  createInstanceVector3(object) - Three implementation for [R3EventObjComponentMathsVector3] 
  deleteInstanceVector3(object) - Three implementation for [R3EventObjComponentMathsVector3] 
  updateInstanceVector3(object, property, value) - Three implementation for [R3EventObjComponentMathsVector3] 
  cloneInstanceVector4(object, propagate = true) - Three implementation for [R3EventObjComponentMathsVector4] 
  createInstanceVector4(object) - Three implementation for [R3EventObjComponentMathsVector4] 
  deleteInstanceVector4(object) - Three implementation for [R3EventObjComponentMathsVector4] 
  updateInstanceVector4(object, property, value) - Three implementation for [R3EventObjComponentMathsVector4] 
  cloneInstanceVertex(object, propagate = true) - Three implementation for [R3EventObjComponentMathsVertex] 
  createInstanceVertex(object) - Three implementation for [R3EventObjComponentMathsVertex] 
  deleteInstanceVertex(object) - Three implementation for [R3EventObjComponentMathsVertex] 
  updateInstanceVertex(object, property, value) - Three implementation for [R3EventObjComponentMathsVertex] 
 INSTANCE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Three extends Maths {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    /**
     * @param interfaceName
     * - The direct parent of this runtime extends from
     */
    if (typeof options.interfaceName === 'undefined') {
      options.interfaceName = 'R3RuntimeMaths';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3RuntimeMathsThree';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'ThreeMaths';
    }

    /**
     * @param type
     * - The type of this runtime
     */
    if (typeof options.key === 'undefined') {
      options.key = Runtime.KEY_MATHS;
    }

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_CONSTRUCTOR_START
    THREE.ColorManagement.enabled = true;
    //CUSTOM_CONSTRUCTOR_END

    if (options.callDepth === 0) {

      delete options.callDepth;

      Object.assign(this, options);

    } else {
      options.callDepth--;
    }

    //CUSTOM_CONSTRUCTOR_AFTER_START
    //CUSTOM_CONSTRUCTOR_AFTER_END

  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_INHERITED_METHODS_START
  //GENERATED_INHERITED_METHODS_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * cloneInstance()
   * - Clones an instance, when propagate is true, also clones the instance children
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstance(object, propagate = true) {

    //CUSTOM_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_CLONE_INSTANCE_START
    super.cloneInstance(object, propagate);
    //CUSTOM_RETURN_GENERATED_CLONE_INSTANCE_END
    //GENERATED_CLONE_INSTANCE_BEFORE_END

    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CLONE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentMathsColor'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_COLOR_START
      return this.cloneInstanceColor(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_COLOR_END
    }

    if (
      object.type === 'R3EventObjComponentMathsFace'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_FACE_START
      return this.cloneInstanceFace(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_FACE_END
    }

    if (
      object.type === 'R3EventObjComponentMathsMatrix3'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_MATRIX3_START
      return this.cloneInstanceMatrix3(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_MATRIX3_END
    }

    if (
      object.type === 'R3EventObjComponentMathsMatrix4'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_MATRIX4_START
      return this.cloneInstanceMatrix4(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_MATRIX4_END
    }

    if (
      object.type === 'R3EventObjComponentMathsPlane'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_PLANE_MATHS_START
      return this.cloneInstancePlaneMaths(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_PLANE_MATHS_END
    }

    if (
      object.type === 'R3EventObjComponentMathsQuaternion'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_QUATERNION_START
      return this.cloneInstanceQuaternion(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_QUATERNION_END
    }

    if (
      object.type === 'R3EventObjComponentMathsRay'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_RAY_START
      return this.cloneInstanceRay(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_RAY_END
    }

    if (
      object.type === 'R3EventObjComponentMathsSphere'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SPHERE_MATHS_START
      return this.cloneInstanceSphereMaths(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_SPHERE_MATHS_END
    }

    if (
      object.type === 'R3EventObjComponentMathsUV'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_U_V_START
      return this.cloneInstanceUV(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_U_V_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector2'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VECTOR2_START
      return this.cloneInstanceVector2(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VECTOR2_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector3'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VECTOR3_START
      return this.cloneInstanceVector3(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VECTOR3_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector4'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VECTOR4_START
      return this.cloneInstanceVector4(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VECTOR4_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVertex'
    ) {
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VERTEX_START
      return this.cloneInstanceVertex(object, propagate);
      //CUSTOM_RETURN_OPTION_CLONE_INSTANCE_VERTEX_END
    }
    //GENERATED_CLONE_INSTANCE_END

    //CUSTOM_CLONE_INSTANCE_START
    //CUSTOM_CLONE_INSTANCE_END

    //GENERATED_CLONE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in cloneInstance() in runtime R3RuntimeMathsThree`);
    //GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * createInstance()
   * - Creates a math runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  createInstance(object) {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_CREATE_INSTANCE_START
    super.createInstance(object);
    //CUSTOM_RETURN_GENERATED_CREATE_INSTANCE_END
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentMathsColor'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_COLOR_START
      return this.createInstanceColor(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_COLOR_END
    }

    if (
      object.type === 'R3EventObjComponentMathsFace'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_FACE_START
      return this.createInstanceFace(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_FACE_END
    }

    if (
      object.type === 'R3EventObjComponentMathsMatrix3'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_MATRIX3_START
      return this.createInstanceMatrix3(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_MATRIX3_END
    }

    if (
      object.type === 'R3EventObjComponentMathsMatrix4'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_MATRIX4_START
      return this.createInstanceMatrix4(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_MATRIX4_END
    }

    if (
      object.type === 'R3EventObjComponentMathsPlane'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_PLANE_MATHS_START
      return this.createInstancePlaneMaths(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_PLANE_MATHS_END
    }

    if (
      object.type === 'R3EventObjComponentMathsQuaternion'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_QUATERNION_START
      return this.createInstanceQuaternion(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_QUATERNION_END
    }

    if (
      object.type === 'R3EventObjComponentMathsRay'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_RAY_START
      return this.createInstanceRay(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_RAY_END
    }

    if (
      object.type === 'R3EventObjComponentMathsSphere'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SPHERE_MATHS_START
      return this.createInstanceSphereMaths(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_SPHERE_MATHS_END
    }

    if (
      object.type === 'R3EventObjComponentMathsUV'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_U_V_START
      return this.createInstanceUV(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_U_V_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector2'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VECTOR2_START
      return this.createInstanceVector2(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VECTOR2_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector3'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VECTOR3_START
      return this.createInstanceVector3(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VECTOR3_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector4'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VECTOR4_START
      return this.createInstanceVector4(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VECTOR4_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVertex'
    ) {
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VERTEX_START
      return this.createInstanceVertex(object);
      //CUSTOM_RETURN_OPTION_CREATE_INSTANCE_VERTEX_END
    }
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in createInstance() in runtime R3RuntimeMathsThree`);
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a math runtime instance for this runtime based on the R3 Object
   * @param object
   * - No returns
   */
  deleteInstance(object) {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_DELETE_INSTANCE_START
    super.deleteInstance(object);
    //CUSTOM_RETURN_GENERATED_DELETE_INSTANCE_END
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentMathsColor'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_COLOR_START
      return this.deleteInstanceColor(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_COLOR_END
    }

    if (
      object.type === 'R3EventObjComponentMathsFace'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_FACE_START
      return this.deleteInstanceFace(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_FACE_END
    }

    if (
      object.type === 'R3EventObjComponentMathsMatrix3'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_MATRIX3_START
      return this.deleteInstanceMatrix3(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_MATRIX3_END
    }

    if (
      object.type === 'R3EventObjComponentMathsMatrix4'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_MATRIX4_START
      return this.deleteInstanceMatrix4(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_MATRIX4_END
    }

    if (
      object.type === 'R3EventObjComponentMathsPlane'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_PLANE_MATHS_START
      return this.deleteInstancePlaneMaths(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_PLANE_MATHS_END
    }

    if (
      object.type === 'R3EventObjComponentMathsQuaternion'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_QUATERNION_START
      return this.deleteInstanceQuaternion(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_QUATERNION_END
    }

    if (
      object.type === 'R3EventObjComponentMathsRay'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_RAY_START
      return this.deleteInstanceRay(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_RAY_END
    }

    if (
      object.type === 'R3EventObjComponentMathsSphere'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SPHERE_MATHS_START
      return this.deleteInstanceSphereMaths(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_SPHERE_MATHS_END
    }

    if (
      object.type === 'R3EventObjComponentMathsUV'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_U_V_START
      return this.deleteInstanceUV(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_U_V_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector2'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VECTOR2_START
      return this.deleteInstanceVector2(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VECTOR2_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector3'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VECTOR3_START
      return this.deleteInstanceVector3(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VECTOR3_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector4'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VECTOR4_START
      return this.deleteInstanceVector4(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VECTOR4_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVertex'
    ) {
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VERTEX_START
      return this.deleteInstanceVertex(object);
      //CUSTOM_RETURN_OPTION_DELETE_INSTANCE_VERTEX_END
    }
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in deleteInstance() in runtime R3RuntimeMathsThree`);
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the math runtime instance of the R3 Object based on the options
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(object, property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_RETURN_GENERATED_UPDATE_INSTANCE_START
    super.updateInstance(object, property, value);
    //CUSTOM_RETURN_GENERATED_UPDATE_INSTANCE_END
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    if (
      object.type === 'R3EventObjComponentMathsColor'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_COLOR_START
      return this.updateInstanceColor(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_COLOR_END
    }

    if (
      object.type === 'R3EventObjComponentMathsFace'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_FACE_START
      return this.updateInstanceFace(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_FACE_END
    }

    if (
      object.type === 'R3EventObjComponentMathsMatrix3'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_MATRIX3_START
      return this.updateInstanceMatrix3(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_MATRIX3_END
    }

    if (
      object.type === 'R3EventObjComponentMathsMatrix4'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_MATRIX4_START
      return this.updateInstanceMatrix4(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_MATRIX4_END
    }

    if (
      object.type === 'R3EventObjComponentMathsPlane'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_PLANE_MATHS_START
      return this.updateInstancePlaneMaths(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_PLANE_MATHS_END
    }

    if (
      object.type === 'R3EventObjComponentMathsQuaternion'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_QUATERNION_START
      return this.updateInstanceQuaternion(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_QUATERNION_END
    }

    if (
      object.type === 'R3EventObjComponentMathsRay'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_RAY_START
      return this.updateInstanceRay(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_RAY_END
    }

    if (
      object.type === 'R3EventObjComponentMathsSphere'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SPHERE_MATHS_START
      return this.updateInstanceSphereMaths(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_SPHERE_MATHS_END
    }

    if (
      object.type === 'R3EventObjComponentMathsUV'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_U_V_START
      return this.updateInstanceUV(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_U_V_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector2'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VECTOR2_START
      return this.updateInstanceVector2(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VECTOR2_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector3'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VECTOR3_START
      return this.updateInstanceVector3(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VECTOR3_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVector4'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VECTOR4_START
      return this.updateInstanceVector4(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VECTOR4_END
    }

    if (
      object.type === 'R3EventObjComponentMathsVertex'
    ) {
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VERTEX_START
      return this.updateInstanceVertex(object, property, value);
      //CUSTOM_RETURN_OPTION_UPDATE_INSTANCE_VERTEX_END
    }
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    throw new Error(`No suitable method found in updateInstance() in runtime R3RuntimeMathsThree`);
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_INSTANCE_METHODS_START
  /**
   * cloneInstanceColor()
   * - Three implementation for [R3EventObjComponentMathsColor]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceColor(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_COLOR_BEFORE_START
    //GENERATED_CLONE_INSTANCE_COLOR_BEFORE_END

    //GENERATED_CLONE_INSTANCE_COLOR_START
    //GENERATED_CLONE_INSTANCE_COLOR_END

    //CUSTOM_CLONE_INSTANCE_COLOR_START
    throw new Error(`Please implement Three.cloneInstanceColor(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_COLOR_END

    //GENERATED_CLONE_INSTANCE_COLOR_AFTER_START
    //GENERATED_CLONE_INSTANCE_COLOR_AFTER_END

  }

  /**
   * createInstanceColor()
   * - Three implementation for [R3EventObjComponentMathsColor]
   * @param object
   * - No returns
   */
  createInstanceColor(object) {

    //GENERATED_CREATE_INSTANCE_COLOR_BEFORE_START
    //GENERATED_CREATE_INSTANCE_COLOR_BEFORE_END

    //GENERATED_CREATE_INSTANCE_COLOR_START
    //GENERATED_CREATE_INSTANCE_COLOR_END

    //CUSTOM_CREATE_INSTANCE_COLOR_START
    return new THREE.Color(Number(object.getHex()));
    //CUSTOM_CREATE_INSTANCE_COLOR_END

    //GENERATED_CREATE_INSTANCE_COLOR_AFTER_START
    //GENERATED_CREATE_INSTANCE_COLOR_AFTER_END

  }

  /**
   * deleteInstanceColor()
   * - Three implementation for [R3EventObjComponentMathsColor]
   * @param object
   * - No returns
   */
  deleteInstanceColor(object) {

    //GENERATED_DELETE_INSTANCE_COLOR_BEFORE_START
    //GENERATED_DELETE_INSTANCE_COLOR_BEFORE_END

    //GENERATED_DELETE_INSTANCE_COLOR_START
    //GENERATED_DELETE_INSTANCE_COLOR_END

    //CUSTOM_DELETE_INSTANCE_COLOR_START;
    return null;
    //CUSTOM_DELETE_INSTANCE_COLOR_END

    //GENERATED_DELETE_INSTANCE_COLOR_AFTER_START
    //GENERATED_DELETE_INSTANCE_COLOR_AFTER_END

  }

  /**
   * updateInstanceColor()
   * - Three implementation for [R3EventObjComponentMathsColor]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceColor(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_COLOR_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_COLOR_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_COLOR_START
    //GENERATED_UPDATE_INSTANCE_COLOR_END

    //CUSTOM_UPDATE_INSTANCE_COLOR_START
    let instance = object.getInstance(this);
    let hex = object.getHex();
    instance.setHex(hex);
    //CUSTOM_UPDATE_INSTANCE_COLOR_END

    //GENERATED_UPDATE_INSTANCE_COLOR_AFTER_START
    //GENERATED_UPDATE_INSTANCE_COLOR_AFTER_END

  }

  /**
   * cloneInstanceFace()
   * - Three implementation for [R3EventObjComponentMathsFace]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceFace(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_FACE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_FACE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_FACE_START
    //GENERATED_CLONE_INSTANCE_FACE_END

    //CUSTOM_CLONE_INSTANCE_FACE_START
    throw new Error(`Please implement Three.cloneInstanceFace(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_FACE_END

    //GENERATED_CLONE_INSTANCE_FACE_AFTER_START
    //GENERATED_CLONE_INSTANCE_FACE_AFTER_END

  }

  /**
   * createInstanceFace()
   * - Three implementation for [R3EventObjComponentMathsFace]
   * @param object
   * - No returns
   */
  createInstanceFace(object) {

    //GENERATED_CREATE_INSTANCE_FACE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_FACE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_FACE_START
    //GENERATED_CREATE_INSTANCE_FACE_END

    //CUSTOM_CREATE_INSTANCE_FACE_START
    return [object.v0, object.v1, object.v2];
    //CUSTOM_CREATE_INSTANCE_FACE_END

    //GENERATED_CREATE_INSTANCE_FACE_AFTER_START
    //GENERATED_CREATE_INSTANCE_FACE_AFTER_END

  }

  /**
   * deleteInstanceFace()
   * - Three implementation for [R3EventObjComponentMathsFace]
   * @param object
   * - No returns
   */
  deleteInstanceFace(object) {

    //GENERATED_DELETE_INSTANCE_FACE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_FACE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_FACE_START
    //GENERATED_DELETE_INSTANCE_FACE_END

    //CUSTOM_DELETE_INSTANCE_FACE_START
    return null;
    //CUSTOM_DELETE_INSTANCE_FACE_END

    //GENERATED_DELETE_INSTANCE_FACE_AFTER_START
    //GENERATED_DELETE_INSTANCE_FACE_AFTER_END

  }

  /**
   * updateInstanceFace()
   * - Three implementation for [R3EventObjComponentMathsFace]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceFace(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_FACE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_FACE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_FACE_START
    //GENERATED_UPDATE_INSTANCE_FACE_END

    //CUSTOM_UPDATE_INSTANCE_FACE_START
    let instance = object.getInstance(this);
    instance[0] = object.v0;
    instance[1] = object.v1;
    instance[2] = object.v2;
    return instance;
    //CUSTOM_UPDATE_INSTANCE_FACE_END

    //GENERATED_UPDATE_INSTANCE_FACE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_FACE_AFTER_END

  }

  /**
   * cloneInstanceMatrix3()
   * - Three implementation for [R3EventObjComponentMathsMatrix3]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceMatrix3(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_MATRIX3_BEFORE_START
    //GENERATED_CLONE_INSTANCE_MATRIX3_BEFORE_END

    //GENERATED_CLONE_INSTANCE_MATRIX3_START
    //GENERATED_CLONE_INSTANCE_MATRIX3_END

    //CUSTOM_CLONE_INSTANCE_MATRIX3_START
    throw new Error(`Please implement Three.cloneInstanceMatrix3(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_MATRIX3_END

    //GENERATED_CLONE_INSTANCE_MATRIX3_AFTER_START
    //GENERATED_CLONE_INSTANCE_MATRIX3_AFTER_END

  }

  /**
   * createInstanceMatrix3()
   * - Three implementation for [R3EventObjComponentMathsMatrix3]
   * @param object
   * - No returns
   */
  createInstanceMatrix3(object) {

    //GENERATED_CREATE_INSTANCE_MATRIX3_BEFORE_START
    //GENERATED_CREATE_INSTANCE_MATRIX3_BEFORE_END

    //GENERATED_CREATE_INSTANCE_MATRIX3_START
    //GENERATED_CREATE_INSTANCE_MATRIX3_END

    //CUSTOM_CREATE_INSTANCE_MATRIX3_START
    throw new Error(`Please implement Three.createInstanceMatrix3(object) in R3RuntimeMathsThree`);
    //CUSTOM_CREATE_INSTANCE_MATRIX3_END

    //GENERATED_CREATE_INSTANCE_MATRIX3_AFTER_START
    //GENERATED_CREATE_INSTANCE_MATRIX3_AFTER_END

  }

  /**
   * deleteInstanceMatrix3()
   * - Three implementation for [R3EventObjComponentMathsMatrix3]
   * @param object
   * - No returns
   */
  deleteInstanceMatrix3(object) {

    //GENERATED_DELETE_INSTANCE_MATRIX3_BEFORE_START
    //GENERATED_DELETE_INSTANCE_MATRIX3_BEFORE_END

    //GENERATED_DELETE_INSTANCE_MATRIX3_START
    //GENERATED_DELETE_INSTANCE_MATRIX3_END

    //CUSTOM_DELETE_INSTANCE_MATRIX3_START
    throw new Error(`Please implement Three.deleteInstanceMatrix3(object) in R3RuntimeMathsThree`);
    //CUSTOM_DELETE_INSTANCE_MATRIX3_END

    //GENERATED_DELETE_INSTANCE_MATRIX3_AFTER_START
    //GENERATED_DELETE_INSTANCE_MATRIX3_AFTER_END

  }

  /**
   * updateInstanceMatrix3()
   * - Three implementation for [R3EventObjComponentMathsMatrix3]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceMatrix3(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_MATRIX3_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_MATRIX3_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_MATRIX3_START
    //GENERATED_UPDATE_INSTANCE_MATRIX3_END

    //CUSTOM_UPDATE_INSTANCE_MATRIX3_START
    throw new Error(`Please implement Three.updateInstanceMatrix3(object, property, value) in R3RuntimeMathsThree`);
    //CUSTOM_UPDATE_INSTANCE_MATRIX3_END

    //GENERATED_UPDATE_INSTANCE_MATRIX3_AFTER_START
    //GENERATED_UPDATE_INSTANCE_MATRIX3_AFTER_END

  }

  /**
   * cloneInstanceMatrix4()
   * - Three implementation for [R3EventObjComponentMathsMatrix4]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceMatrix4(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_MATRIX4_BEFORE_START
    //GENERATED_CLONE_INSTANCE_MATRIX4_BEFORE_END

    //GENERATED_CLONE_INSTANCE_MATRIX4_START
    //GENERATED_CLONE_INSTANCE_MATRIX4_END

    //CUSTOM_CLONE_INSTANCE_MATRIX4_START
    throw new Error(`Please implement Three.cloneInstanceMatrix4(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_MATRIX4_END

    //GENERATED_CLONE_INSTANCE_MATRIX4_AFTER_START
    //GENERATED_CLONE_INSTANCE_MATRIX4_AFTER_END

  }

  /**
   * createInstanceMatrix4()
   * - Three implementation for [R3EventObjComponentMathsMatrix4]
   * @param object
   * - No returns
   */
  createInstanceMatrix4(object) {

    //GENERATED_CREATE_INSTANCE_MATRIX4_BEFORE_START
    //GENERATED_CREATE_INSTANCE_MATRIX4_BEFORE_END

    //GENERATED_CREATE_INSTANCE_MATRIX4_START
    //GENERATED_CREATE_INSTANCE_MATRIX4_END

    //CUSTOM_CREATE_INSTANCE_MATRIX4_START
    throw new Error(`Please implement Three.createInstanceMatrix4(object) in R3RuntimeMathsThree`);
    //CUSTOM_CREATE_INSTANCE_MATRIX4_END

    //GENERATED_CREATE_INSTANCE_MATRIX4_AFTER_START
    //GENERATED_CREATE_INSTANCE_MATRIX4_AFTER_END

  }

  /**
   * deleteInstanceMatrix4()
   * - Three implementation for [R3EventObjComponentMathsMatrix4]
   * @param object
   * - No returns
   */
  deleteInstanceMatrix4(object) {

    //GENERATED_DELETE_INSTANCE_MATRIX4_BEFORE_START
    //GENERATED_DELETE_INSTANCE_MATRIX4_BEFORE_END

    //GENERATED_DELETE_INSTANCE_MATRIX4_START
    //GENERATED_DELETE_INSTANCE_MATRIX4_END

    //CUSTOM_DELETE_INSTANCE_MATRIX4_START
    throw new Error(`Please implement Three.deleteInstanceMatrix4(object) in R3RuntimeMathsThree`);
    //CUSTOM_DELETE_INSTANCE_MATRIX4_END

    //GENERATED_DELETE_INSTANCE_MATRIX4_AFTER_START
    //GENERATED_DELETE_INSTANCE_MATRIX4_AFTER_END

  }

  /**
   * updateInstanceMatrix4()
   * - Three implementation for [R3EventObjComponentMathsMatrix4]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceMatrix4(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_MATRIX4_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_MATRIX4_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_MATRIX4_START
    //GENERATED_UPDATE_INSTANCE_MATRIX4_END

    //CUSTOM_UPDATE_INSTANCE_MATRIX4_START
    throw new Error(`Please implement Three.updateInstanceMatrix4(object, property, value) in R3RuntimeMathsThree`);
    //CUSTOM_UPDATE_INSTANCE_MATRIX4_END

    //GENERATED_UPDATE_INSTANCE_MATRIX4_AFTER_START
    //GENERATED_UPDATE_INSTANCE_MATRIX4_AFTER_END

  }

  /**
   * cloneInstancePlaneMaths()
   * - Three implementation for [R3EventObjComponentMathsPlane]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstancePlaneMaths(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_PLANE_MATHS_BEFORE_START
    //GENERATED_CLONE_INSTANCE_PLANE_MATHS_BEFORE_END

    //GENERATED_CLONE_INSTANCE_PLANE_MATHS_START
    //GENERATED_CLONE_INSTANCE_PLANE_MATHS_END

    //CUSTOM_CLONE_INSTANCE_PLANE_MATHS_START
    throw new Error(`Please implement Three.cloneInstancePlaneMaths(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_PLANE_MATHS_END

    //GENERATED_CLONE_INSTANCE_PLANE_MATHS_AFTER_START
    //GENERATED_CLONE_INSTANCE_PLANE_MATHS_AFTER_END

  }

  /**
   * createInstancePlaneMaths()
   * - Three implementation for [R3EventObjComponentMathsPlane]
   * @param object
   * - No returns
   */
  createInstancePlaneMaths(object) {

    //GENERATED_CREATE_INSTANCE_PLANE_MATHS_BEFORE_START
    //GENERATED_CREATE_INSTANCE_PLANE_MATHS_BEFORE_END

    //GENERATED_CREATE_INSTANCE_PLANE_MATHS_START
    //GENERATED_CREATE_INSTANCE_PLANE_MATHS_END

    //CUSTOM_CREATE_INSTANCE_PLANE_MATHS_START
    throw new Error(`Please implement Three.createInstancePlaneMaths(object) in R3RuntimeMathsThree`);
    //CUSTOM_CREATE_INSTANCE_PLANE_MATHS_END

    //GENERATED_CREATE_INSTANCE_PLANE_MATHS_AFTER_START
    //GENERATED_CREATE_INSTANCE_PLANE_MATHS_AFTER_END

  }

  /**
   * deleteInstancePlaneMaths()
   * - Three implementation for [R3EventObjComponentMathsPlane]
   * @param object
   * - No returns
   */
  deleteInstancePlaneMaths(object) {

    //GENERATED_DELETE_INSTANCE_PLANE_MATHS_BEFORE_START
    //GENERATED_DELETE_INSTANCE_PLANE_MATHS_BEFORE_END

    //GENERATED_DELETE_INSTANCE_PLANE_MATHS_START
    //GENERATED_DELETE_INSTANCE_PLANE_MATHS_END

    //CUSTOM_DELETE_INSTANCE_PLANE_MATHS_START
    throw new Error(`Please implement Three.deleteInstancePlaneMaths(object) in R3RuntimeMathsThree`);
    //CUSTOM_DELETE_INSTANCE_PLANE_MATHS_END

    //GENERATED_DELETE_INSTANCE_PLANE_MATHS_AFTER_START
    //GENERATED_DELETE_INSTANCE_PLANE_MATHS_AFTER_END

  }

  /**
   * updateInstancePlaneMaths()
   * - Three implementation for [R3EventObjComponentMathsPlane]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstancePlaneMaths(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_PLANE_MATHS_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_PLANE_MATHS_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_PLANE_MATHS_START
    //GENERATED_UPDATE_INSTANCE_PLANE_MATHS_END

    //CUSTOM_UPDATE_INSTANCE_PLANE_MATHS_START
    throw new Error(`Please implement Three.updateInstancePlaneMaths(object, property, value) in R3RuntimeMathsThree`);
    //CUSTOM_UPDATE_INSTANCE_PLANE_MATHS_END

    //GENERATED_UPDATE_INSTANCE_PLANE_MATHS_AFTER_START
    //GENERATED_UPDATE_INSTANCE_PLANE_MATHS_AFTER_END

  }

  /**
   * cloneInstanceQuaternion()
   * - Three implementation for [R3EventObjComponentMathsQuaternion]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceQuaternion(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_QUATERNION_BEFORE_START
    //GENERATED_CLONE_INSTANCE_QUATERNION_BEFORE_END

    //GENERATED_CLONE_INSTANCE_QUATERNION_START
    //GENERATED_CLONE_INSTANCE_QUATERNION_END

    //CUSTOM_CLONE_INSTANCE_QUATERNION_START
    throw new Error(`Please implement Three.cloneInstanceQuaternion(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_QUATERNION_END

    //GENERATED_CLONE_INSTANCE_QUATERNION_AFTER_START
    //GENERATED_CLONE_INSTANCE_QUATERNION_AFTER_END

  }

  /**
   * createInstanceQuaternion()
   * - Three implementation for [R3EventObjComponentMathsQuaternion]
   * @param object
   * - No returns
   */
  createInstanceQuaternion(object) {

    //GENERATED_CREATE_INSTANCE_QUATERNION_BEFORE_START
    //GENERATED_CREATE_INSTANCE_QUATERNION_BEFORE_END

    //GENERATED_CREATE_INSTANCE_QUATERNION_START
    //GENERATED_CREATE_INSTANCE_QUATERNION_END

    //CUSTOM_CREATE_INSTANCE_QUATERNION_START
    return new THREE.Quaternion(object.x, object.y, object.z, object.w);
    //CUSTOM_CREATE_INSTANCE_QUATERNION_END

    //GENERATED_CREATE_INSTANCE_QUATERNION_AFTER_START
    //GENERATED_CREATE_INSTANCE_QUATERNION_AFTER_END

  }

  /**
   * deleteInstanceQuaternion()
   * - Three implementation for [R3EventObjComponentMathsQuaternion]
   * @param object
   * - No returns
   */
  deleteInstanceQuaternion(object) {

    //GENERATED_DELETE_INSTANCE_QUATERNION_BEFORE_START
    //GENERATED_DELETE_INSTANCE_QUATERNION_BEFORE_END

    //GENERATED_DELETE_INSTANCE_QUATERNION_START
    //GENERATED_DELETE_INSTANCE_QUATERNION_END

    //CUSTOM_DELETE_INSTANCE_QUATERNION_START
    return null;
    //CUSTOM_DELETE_INSTANCE_QUATERNION_END

    //GENERATED_DELETE_INSTANCE_QUATERNION_AFTER_START
    //GENERATED_DELETE_INSTANCE_QUATERNION_AFTER_END

  }

  /**
   * updateInstanceQuaternion()
   * - Three implementation for [R3EventObjComponentMathsQuaternion]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceQuaternion(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_QUATERNION_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_QUATERNION_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_QUATERNION_START
    //GENERATED_UPDATE_INSTANCE_QUATERNION_END

    //CUSTOM_UPDATE_INSTANCE_QUATERNION_START
    let instance = object.getInstance(this);
    instance[property] = value;
    //CUSTOM_UPDATE_INSTANCE_QUATERNION_END

    //GENERATED_UPDATE_INSTANCE_QUATERNION_AFTER_START
    //GENERATED_UPDATE_INSTANCE_QUATERNION_AFTER_END

  }

  /**
   * cloneInstanceRay()
   * - Three implementation for [R3EventObjComponentMathsRay]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceRay(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_RAY_BEFORE_START
    //GENERATED_CLONE_INSTANCE_RAY_BEFORE_END

    //GENERATED_CLONE_INSTANCE_RAY_START
    //GENERATED_CLONE_INSTANCE_RAY_END

    //CUSTOM_CLONE_INSTANCE_RAY_START
    throw new Error(`Please implement Three.cloneInstanceRay(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_RAY_END

    //GENERATED_CLONE_INSTANCE_RAY_AFTER_START
    //GENERATED_CLONE_INSTANCE_RAY_AFTER_END

  }

  /**
   * createInstanceRay()
   * - Three implementation for [R3EventObjComponentMathsRay]
   * @param object
   * - No returns
   */
  createInstanceRay(object) {

    //GENERATED_CREATE_INSTANCE_RAY_BEFORE_START
    //GENERATED_CREATE_INSTANCE_RAY_BEFORE_END

    //GENERATED_CREATE_INSTANCE_RAY_START
    //GENERATED_CREATE_INSTANCE_RAY_END

    //CUSTOM_CREATE_INSTANCE_RAY_START
    throw new Error(`Please implement Three.createInstanceRay(object) in R3RuntimeMathsThree`);
    //CUSTOM_CREATE_INSTANCE_RAY_END

    //GENERATED_CREATE_INSTANCE_RAY_AFTER_START
    //GENERATED_CREATE_INSTANCE_RAY_AFTER_END

  }

  /**
   * deleteInstanceRay()
   * - Three implementation for [R3EventObjComponentMathsRay]
   * @param object
   * - No returns
   */
  deleteInstanceRay(object) {

    //GENERATED_DELETE_INSTANCE_RAY_BEFORE_START
    //GENERATED_DELETE_INSTANCE_RAY_BEFORE_END

    //GENERATED_DELETE_INSTANCE_RAY_START
    //GENERATED_DELETE_INSTANCE_RAY_END

    //CUSTOM_DELETE_INSTANCE_RAY_START
    throw new Error(`Please implement Three.deleteInstanceRay(object) in R3RuntimeMathsThree`);
    //CUSTOM_DELETE_INSTANCE_RAY_END

    //GENERATED_DELETE_INSTANCE_RAY_AFTER_START
    //GENERATED_DELETE_INSTANCE_RAY_AFTER_END

  }

  /**
   * updateInstanceRay()
   * - Three implementation for [R3EventObjComponentMathsRay]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceRay(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_RAY_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_RAY_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_RAY_START
    //GENERATED_UPDATE_INSTANCE_RAY_END

    //CUSTOM_UPDATE_INSTANCE_RAY_START
    throw new Error(`Please implement Three.updateInstanceRay(object, property, value) in R3RuntimeMathsThree`);
    //CUSTOM_UPDATE_INSTANCE_RAY_END

    //GENERATED_UPDATE_INSTANCE_RAY_AFTER_START
    //GENERATED_UPDATE_INSTANCE_RAY_AFTER_END

  }

  /**
   * cloneInstanceSphereMaths()
   * - Three implementation for [R3EventObjComponentMathsSphere]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceSphereMaths(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_SPHERE_MATHS_BEFORE_START
    //GENERATED_CLONE_INSTANCE_SPHERE_MATHS_BEFORE_END

    //GENERATED_CLONE_INSTANCE_SPHERE_MATHS_START
    //GENERATED_CLONE_INSTANCE_SPHERE_MATHS_END

    //CUSTOM_CLONE_INSTANCE_SPHERE_MATHS_START
    throw new Error(`Please implement Three.cloneInstanceSphereMaths(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_SPHERE_MATHS_END

    //GENERATED_CLONE_INSTANCE_SPHERE_MATHS_AFTER_START
    //GENERATED_CLONE_INSTANCE_SPHERE_MATHS_AFTER_END

  }

  /**
   * createInstanceSphereMaths()
   * - Three implementation for [R3EventObjComponentMathsSphere]
   * @param object
   * - No returns
   */
  createInstanceSphereMaths(object) {

    //GENERATED_CREATE_INSTANCE_SPHERE_MATHS_BEFORE_START
    //GENERATED_CREATE_INSTANCE_SPHERE_MATHS_BEFORE_END

    //GENERATED_CREATE_INSTANCE_SPHERE_MATHS_START
    //GENERATED_CREATE_INSTANCE_SPHERE_MATHS_END

    //CUSTOM_CREATE_INSTANCE_SPHERE_MATHS_START
    throw new Error(`Please implement Three.createInstanceSphereMaths(object) in R3RuntimeMathsThree`);
    //CUSTOM_CREATE_INSTANCE_SPHERE_MATHS_END

    //GENERATED_CREATE_INSTANCE_SPHERE_MATHS_AFTER_START
    //GENERATED_CREATE_INSTANCE_SPHERE_MATHS_AFTER_END

  }

  /**
   * deleteInstanceSphereMaths()
   * - Three implementation for [R3EventObjComponentMathsSphere]
   * @param object
   * - No returns
   */
  deleteInstanceSphereMaths(object) {

    //GENERATED_DELETE_INSTANCE_SPHERE_MATHS_BEFORE_START
    //GENERATED_DELETE_INSTANCE_SPHERE_MATHS_BEFORE_END

    //GENERATED_DELETE_INSTANCE_SPHERE_MATHS_START
    //GENERATED_DELETE_INSTANCE_SPHERE_MATHS_END

    //CUSTOM_DELETE_INSTANCE_SPHERE_MATHS_START
    throw new Error(`Please implement Three.deleteInstanceSphereMaths(object) in R3RuntimeMathsThree`);
    //CUSTOM_DELETE_INSTANCE_SPHERE_MATHS_END

    //GENERATED_DELETE_INSTANCE_SPHERE_MATHS_AFTER_START
    //GENERATED_DELETE_INSTANCE_SPHERE_MATHS_AFTER_END

  }

  /**
   * updateInstanceSphereMaths()
   * - Three implementation for [R3EventObjComponentMathsSphere]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceSphereMaths(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_SPHERE_MATHS_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_SPHERE_MATHS_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_SPHERE_MATHS_START
    //GENERATED_UPDATE_INSTANCE_SPHERE_MATHS_END

    //CUSTOM_UPDATE_INSTANCE_SPHERE_MATHS_START
    throw new Error(`Please implement Three.updateInstanceSphereMaths(object, property, value) in R3RuntimeMathsThree`);
    //CUSTOM_UPDATE_INSTANCE_SPHERE_MATHS_END

    //GENERATED_UPDATE_INSTANCE_SPHERE_MATHS_AFTER_START
    //GENERATED_UPDATE_INSTANCE_SPHERE_MATHS_AFTER_END

  }

  /**
   * cloneInstanceUV()
   * - Three implementation for [R3EventObjComponentMathsUV]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceUV(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_U_V_BEFORE_START
    //GENERATED_CLONE_INSTANCE_U_V_BEFORE_END

    //GENERATED_CLONE_INSTANCE_U_V_START
    //GENERATED_CLONE_INSTANCE_U_V_END

    //CUSTOM_CLONE_INSTANCE_U_V_START
    throw new Error(`Please implement Three.cloneInstanceUV(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_U_V_END

    //GENERATED_CLONE_INSTANCE_U_V_AFTER_START
    //GENERATED_CLONE_INSTANCE_U_V_AFTER_END

  }

  /**
   * createInstanceUV()
   * - Three implementation for [R3EventObjComponentMathsUV]
   * @param object
   * - No returns
   */
  createInstanceUV(object) {

    //GENERATED_CREATE_INSTANCE_U_V_BEFORE_START
    //GENERATED_CREATE_INSTANCE_U_V_BEFORE_END

    //GENERATED_CREATE_INSTANCE_U_V_START
    //GENERATED_CREATE_INSTANCE_U_V_END

    //CUSTOM_CREATE_INSTANCE_U_V_START
    return {};
    //CUSTOM_CREATE_INSTANCE_U_V_END

    //GENERATED_CREATE_INSTANCE_U_V_AFTER_START
    //GENERATED_CREATE_INSTANCE_U_V_AFTER_END

  }

  /**
   * deleteInstanceUV()
   * - Three implementation for [R3EventObjComponentMathsUV]
   * @param object
   * - No returns
   */
  deleteInstanceUV(object) {

    //GENERATED_DELETE_INSTANCE_U_V_BEFORE_START
    //GENERATED_DELETE_INSTANCE_U_V_BEFORE_END

    //GENERATED_DELETE_INSTANCE_U_V_START
    //GENERATED_DELETE_INSTANCE_U_V_END

    //CUSTOM_DELETE_INSTANCE_U_V_START
    return null;
    //CUSTOM_DELETE_INSTANCE_U_V_END

    //GENERATED_DELETE_INSTANCE_U_V_AFTER_START
    //GENERATED_DELETE_INSTANCE_U_V_AFTER_END

  }

  /**
   * updateInstanceUV()
   * - Three implementation for [R3EventObjComponentMathsUV]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceUV(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_U_V_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_U_V_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_U_V_START
    //GENERATED_UPDATE_INSTANCE_U_V_END

    //CUSTOM_UPDATE_INSTANCE_U_V_START
    let instance = object.getInstance(this);
    instance[property] = value;
    //CUSTOM_UPDATE_INSTANCE_U_V_END

    //GENERATED_UPDATE_INSTANCE_U_V_AFTER_START
    //GENERATED_UPDATE_INSTANCE_U_V_AFTER_END

  }

  /**
   * cloneInstanceVector2()
   * - Three implementation for [R3EventObjComponentMathsVector2]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceVector2(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_VECTOR2_BEFORE_START
    //GENERATED_CLONE_INSTANCE_VECTOR2_BEFORE_END

    //GENERATED_CLONE_INSTANCE_VECTOR2_START
    //GENERATED_CLONE_INSTANCE_VECTOR2_END

    //CUSTOM_CLONE_INSTANCE_VECTOR2_START
    throw new Error(`Please implement Three.cloneInstanceVector2(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_VECTOR2_END

    //GENERATED_CLONE_INSTANCE_VECTOR2_AFTER_START
    //GENERATED_CLONE_INSTANCE_VECTOR2_AFTER_END

  }

  /**
   * createInstanceVector2()
   * - Three implementation for [R3EventObjComponentMathsVector2]
   * @param object
   * - No returns
   */
  createInstanceVector2(object) {

    //GENERATED_CREATE_INSTANCE_VECTOR2_BEFORE_START
    //GENERATED_CREATE_INSTANCE_VECTOR2_BEFORE_END

    //GENERATED_CREATE_INSTANCE_VECTOR2_START
    //GENERATED_CREATE_INSTANCE_VECTOR2_END

    //CUSTOM_CREATE_INSTANCE_VECTOR2_START
    return new THREE.Vector2(object.x, object.y);
    //CUSTOM_CREATE_INSTANCE_VECTOR2_END

    //GENERATED_CREATE_INSTANCE_VECTOR2_AFTER_START
    //GENERATED_CREATE_INSTANCE_VECTOR2_AFTER_END

  }

  /**
   * deleteInstanceVector2()
   * - Three implementation for [R3EventObjComponentMathsVector2]
   * @param object
   * - No returns
   */
  deleteInstanceVector2(object) {

    //GENERATED_DELETE_INSTANCE_VECTOR2_BEFORE_START
    //GENERATED_DELETE_INSTANCE_VECTOR2_BEFORE_END

    //GENERATED_DELETE_INSTANCE_VECTOR2_START
    //GENERATED_DELETE_INSTANCE_VECTOR2_END

    //CUSTOM_DELETE_INSTANCE_VECTOR2_START
    return null;
    //CUSTOM_DELETE_INSTANCE_VECTOR2_END

    //GENERATED_DELETE_INSTANCE_VECTOR2_AFTER_START
    //GENERATED_DELETE_INSTANCE_VECTOR2_AFTER_END

  }

  /**
   * updateInstanceVector2()
   * - Three implementation for [R3EventObjComponentMathsVector2]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceVector2(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_VECTOR2_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_VECTOR2_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_VECTOR2_START
    //GENERATED_UPDATE_INSTANCE_VECTOR2_END

    //CUSTOM_UPDATE_INSTANCE_VECTOR2_START
    let instance = object.getInstance(this);
    instance[property] = value;
    //CUSTOM_UPDATE_INSTANCE_VECTOR2_END

    //GENERATED_UPDATE_INSTANCE_VECTOR2_AFTER_START
    //GENERATED_UPDATE_INSTANCE_VECTOR2_AFTER_END

  }

  /**
   * cloneInstanceVector3()
   * - Three implementation for [R3EventObjComponentMathsVector3]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceVector3(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_VECTOR3_BEFORE_START
    //GENERATED_CLONE_INSTANCE_VECTOR3_BEFORE_END

    //GENERATED_CLONE_INSTANCE_VECTOR3_START
    //GENERATED_CLONE_INSTANCE_VECTOR3_END

    //CUSTOM_CLONE_INSTANCE_VECTOR3_START
    throw new Error(`Please implement Three.cloneInstanceVector3(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_VECTOR3_END

    //GENERATED_CLONE_INSTANCE_VECTOR3_AFTER_START
    //GENERATED_CLONE_INSTANCE_VECTOR3_AFTER_END

  }

  /**
   * createInstanceVector3()
   * - Three implementation for [R3EventObjComponentMathsVector3]
   * @param object
   * - No returns
   */
  createInstanceVector3(object) {

    //GENERATED_CREATE_INSTANCE_VECTOR3_BEFORE_START
    //GENERATED_CREATE_INSTANCE_VECTOR3_BEFORE_END

    //GENERATED_CREATE_INSTANCE_VECTOR3_START
    //GENERATED_CREATE_INSTANCE_VECTOR3_END

    //CUSTOM_CREATE_INSTANCE_VECTOR3_START
    return new THREE.Vector3(object.x, object.y, object.z);
    //CUSTOM_CREATE_INSTANCE_VECTOR3_END

    //GENERATED_CREATE_INSTANCE_VECTOR3_AFTER_START
    //GENERATED_CREATE_INSTANCE_VECTOR3_AFTER_END

  }

  /**
   * deleteInstanceVector3()
   * - Three implementation for [R3EventObjComponentMathsVector3]
   * @param object
   * - No returns
   */
  deleteInstanceVector3(object) {

    //GENERATED_DELETE_INSTANCE_VECTOR3_BEFORE_START
    //GENERATED_DELETE_INSTANCE_VECTOR3_BEFORE_END

    //GENERATED_DELETE_INSTANCE_VECTOR3_START
    //GENERATED_DELETE_INSTANCE_VECTOR3_END

    //CUSTOM_DELETE_INSTANCE_VECTOR3_START
    return null;
    //CUSTOM_DELETE_INSTANCE_VECTOR3_END

    //GENERATED_DELETE_INSTANCE_VECTOR3_AFTER_START
    //GENERATED_DELETE_INSTANCE_VECTOR3_AFTER_END

  }

  /**
   * updateInstanceVector3()
   * - Three implementation for [R3EventObjComponentMathsVector3]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceVector3(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_VECTOR3_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_VECTOR3_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_VECTOR3_START
    //GENERATED_UPDATE_INSTANCE_VECTOR3_END

    //CUSTOM_UPDATE_INSTANCE_VECTOR3_START
    let instance = object.getInstance(this);
    instance[property] = value;
    //CUSTOM_UPDATE_INSTANCE_VECTOR3_END

    //GENERATED_UPDATE_INSTANCE_VECTOR3_AFTER_START
    //GENERATED_UPDATE_INSTANCE_VECTOR3_AFTER_END

  }

  /**
   * cloneInstanceVector4()
   * - Three implementation for [R3EventObjComponentMathsVector4]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceVector4(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_VECTOR4_BEFORE_START
    //GENERATED_CLONE_INSTANCE_VECTOR4_BEFORE_END

    //GENERATED_CLONE_INSTANCE_VECTOR4_START
    //GENERATED_CLONE_INSTANCE_VECTOR4_END

    //CUSTOM_CLONE_INSTANCE_VECTOR4_START
    throw new Error(`Please implement Three.cloneInstanceVector4(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_VECTOR4_END

    //GENERATED_CLONE_INSTANCE_VECTOR4_AFTER_START
    //GENERATED_CLONE_INSTANCE_VECTOR4_AFTER_END

  }

  /**
   * createInstanceVector4()
   * - Three implementation for [R3EventObjComponentMathsVector4]
   * @param object
   * - No returns
   */
  createInstanceVector4(object) {

    //GENERATED_CREATE_INSTANCE_VECTOR4_BEFORE_START
    //GENERATED_CREATE_INSTANCE_VECTOR4_BEFORE_END

    //GENERATED_CREATE_INSTANCE_VECTOR4_START
    //GENERATED_CREATE_INSTANCE_VECTOR4_END

    //CUSTOM_CREATE_INSTANCE_VECTOR4_START
    throw new Error(`Please implement Three.createInstanceVector4(object) in R3RuntimeMathsThree`);
    //CUSTOM_CREATE_INSTANCE_VECTOR4_END

    //GENERATED_CREATE_INSTANCE_VECTOR4_AFTER_START
    //GENERATED_CREATE_INSTANCE_VECTOR4_AFTER_END

  }

  /**
   * deleteInstanceVector4()
   * - Three implementation for [R3EventObjComponentMathsVector4]
   * @param object
   * - No returns
   */
  deleteInstanceVector4(object) {

    //GENERATED_DELETE_INSTANCE_VECTOR4_BEFORE_START
    //GENERATED_DELETE_INSTANCE_VECTOR4_BEFORE_END

    //GENERATED_DELETE_INSTANCE_VECTOR4_START
    //GENERATED_DELETE_INSTANCE_VECTOR4_END

    //CUSTOM_DELETE_INSTANCE_VECTOR4_START
    throw new Error(`Please implement Three.deleteInstanceVector4(object) in R3RuntimeMathsThree`);
    //CUSTOM_DELETE_INSTANCE_VECTOR4_END

    //GENERATED_DELETE_INSTANCE_VECTOR4_AFTER_START
    //GENERATED_DELETE_INSTANCE_VECTOR4_AFTER_END

  }

  /**
   * updateInstanceVector4()
   * - Three implementation for [R3EventObjComponentMathsVector4]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceVector4(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_VECTOR4_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_VECTOR4_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_VECTOR4_START
    //GENERATED_UPDATE_INSTANCE_VECTOR4_END

    //CUSTOM_UPDATE_INSTANCE_VECTOR4_START
    throw new Error(`Please implement Three.updateInstanceVector4(object, property, value) in R3RuntimeMathsThree`);
    //CUSTOM_UPDATE_INSTANCE_VECTOR4_END

    //GENERATED_UPDATE_INSTANCE_VECTOR4_AFTER_START
    //GENERATED_UPDATE_INSTANCE_VECTOR4_AFTER_END

  }

  /**
   * cloneInstanceVertex()
   * - Three implementation for [R3EventObjComponentMathsVertex]
   * @param object
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstanceVertex(object, propagate = true) {

    //GENERATED_CLONE_INSTANCE_VERTEX_BEFORE_START
    //GENERATED_CLONE_INSTANCE_VERTEX_BEFORE_END

    //GENERATED_CLONE_INSTANCE_VERTEX_START
    //GENERATED_CLONE_INSTANCE_VERTEX_END

    //CUSTOM_CLONE_INSTANCE_VERTEX_START
    throw new Error(`Please implement Three.cloneInstanceVertex(object, propagate = true) in R3RuntimeMathsThree`);
    //CUSTOM_CLONE_INSTANCE_VERTEX_END

    //GENERATED_CLONE_INSTANCE_VERTEX_AFTER_START
    //GENERATED_CLONE_INSTANCE_VERTEX_AFTER_END

  }

  /**
   * createInstanceVertex()
   * - Three implementation for [R3EventObjComponentMathsVertex]
   * @param object
   * - No returns
   */
  createInstanceVertex(object) {

    //GENERATED_CREATE_INSTANCE_VERTEX_BEFORE_START
    //GENERATED_CREATE_INSTANCE_VERTEX_BEFORE_END

    //GENERATED_CREATE_INSTANCE_VERTEX_START
    //GENERATED_CREATE_INSTANCE_VERTEX_END

    //CUSTOM_CREATE_INSTANCE_VERTEX_START
    return new Float32Array([object.position.x, object.position.y, object.position.z]);
    //CUSTOM_CREATE_INSTANCE_VERTEX_END

    //GENERATED_CREATE_INSTANCE_VERTEX_AFTER_START
    //GENERATED_CREATE_INSTANCE_VERTEX_AFTER_END

  }

  /**
   * deleteInstanceVertex()
   * - Three implementation for [R3EventObjComponentMathsVertex]
   * @param object
   * - No returns
   */
  deleteInstanceVertex(object) {

    //GENERATED_DELETE_INSTANCE_VERTEX_BEFORE_START
    //GENERATED_DELETE_INSTANCE_VERTEX_BEFORE_END

    //GENERATED_DELETE_INSTANCE_VERTEX_START
    //GENERATED_DELETE_INSTANCE_VERTEX_END

    //CUSTOM_DELETE_INSTANCE_VERTEX_START
    return null;
    //CUSTOM_DELETE_INSTANCE_VERTEX_END

    //GENERATED_DELETE_INSTANCE_VERTEX_AFTER_START
    //GENERATED_DELETE_INSTANCE_VERTEX_AFTER_END

  }

  /**
   * updateInstanceVertex()
   * - Three implementation for [R3EventObjComponentMathsVertex]
   * @param object
   * @param property
   * @param value
   * - No returns
   */
  updateInstanceVertex(object, property, value) {

    //GENERATED_UPDATE_INSTANCE_VERTEX_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_VERTEX_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_VERTEX_START
    //GENERATED_UPDATE_INSTANCE_VERTEX_END

    //CUSTOM_UPDATE_INSTANCE_VERTEX_START
    let instance = object.getInstance(this);
    instance[0] = object.x;
    instance[1] = object.y;
    instance[2] = object.z;
    return instance;
    //CUSTOM_UPDATE_INSTANCE_VERTEX_END

    //GENERATED_UPDATE_INSTANCE_VERTEX_AFTER_START
    //GENERATED_UPDATE_INSTANCE_VERTEX_AFTER_END

  }

  //GENERATED_INSTANCE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END

}

Three.Type = 'R3RuntimeMathsThree';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Three as default};
