//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Vector2 from '../maths/Vector2.js';
import Runtime from '../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Graphics from '../Graphics.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  mapping=Texture.UVMapping - UVMapping is the default, and maps the texture using the mesh's UV coordinates. CubeReflectionMapping and CubeRefractionMapping are for use with a CubeTexture, which is made up of six textures, one for each face of the cube. CubeReflectionMapping is the default for a CubeTexture. EquirectangularReflectionMapping and EquirectangularRefractionMapping are for use with an equirectangular environment map. Also called a lat-long map, an equirectangular texture represents a 360-degree view along the horizontal centerline, and a 180-degree view along the vertical axis, with the top and bottom edges of the image corresponding to the north and south poles of a mapped sphere.
  wrapS=Texture.RepeatWrapping
  wrapT=Texture.RepeatWrapping
  offset=new Vector2()
  repeat=new Vector2({x:1, y:1})
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  RepeatWrapping=1000 - With RepeatWrapping the texture will simply repeat to infinity
  ClampToEdgeWrapping=1001 - The last pixel of the texture stretches to the edge of the mesh.
  MirroredRepeatWrapping=1002 - With MirroredRepeatWrapping the texture will repeats to infinity, mirroring on each repeat.
  UVMapping=300 - This maps the texture using the mesh's UV coordinates.
  CubeReflectionMapping=301 - CubeReflectionMapping and CubeRefractionMapping are for use with a CubeTexture, which is made up of six textures, one for each face of the cube. CubeReflectionMapping is the default for a CubeTexture.
  CubeRefractionMapping=302 - CubeReflectionMapping and CubeRefractionMapping are for use with a CubeTexture, which is made up of six textures, one for each face of the cube. CubeReflectionMapping is the default for a CubeTexture.
  EquirectangularReflectionMapping=303 - EquirectangularReflectionMapping and EquirectangularRefractionMapping are for use with an equirectangular environment map. Also called a lat-long map, an equirectangular texture represents a 360-degree view along the horizontal centerline, and a 180-degree view along the vertical axis, with the top and bottom edges of the image corresponding to the north and south poles of a mapped sphere.
  EquirectangularRefractionMapping=304 - EquirectangularReflectionMapping and EquirectangularRefractionMapping are for use with an equirectangular environment map. Also called a lat-long map, an equirectangular texture represents a 360-degree view along the horizontal centerline, and a 180-degree view along the vertical axis, with the top and bottom edges of the image corresponding to the north and south poles of a mapped sphere.
  CubeUVReflectionMapping=306
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "wrapS",
            "type" : "Number",
            "possibleValues" : [
              "Texture.RepeatWrapping",
              "Texture.ClampToEdgeWrapping",
              "Texture.MirroredRepeatWrapping"
            ],
            "possibleLabels" : [
              "Repeat",
              "Clamp To Edge",
              "Mirrored Repeat"
            ]
          },
          {
            "property" : "wrapT",
            "type" : "Number",
            "possibleValues" : [
              "Texture.RepeatWrapping",
              "Texture.ClampToEdgeWrapping",
              "Texture.MirroredRepeatWrapping"
            ],
            "possibleLabels" : [
              "Repeat",
              "Clamp To Edge",
              "Mirrored Repeat"
            ]
          },
          {
            "property" : "mapping",
            "type" : "Number",
            "possibleValues" : [
              "Texture.UVMapping",
              "Texture.CubeReflectionMapping",
              "Texture.CubeRefractionMapping",
              "Texture.EquirectangularReflectionMapping",
              "Texture.EquirectangularRefractionMapping",
              "Texture.CubeUVReflectionMapping"
            ],
            "possibleLabels" : [
              "UV Mapping",
              "Cube Reflection",
              "Cube Refraction",
              "Equirectangular Reflection",
              "Equirectangular Refraction",
              "Cube UV Reflection"
            ]
          },
          {
            "property" : "offset",
            "type" : "R3EventObjComponentMathsVector2",
            "min" : -10,
            "max" : 10,
            "step" : 0.001
          },
          {
            "property" : "repeat",
            "type" : "R3EventObjComponentMathsVector2",
            "min" : 0.001,
            "max" : 1000,
            "step" : 0.001
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Texture extends Graphics {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphicsTexture';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'Texture';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param mapping
     * - UVMapping is the default, and maps the texture using the mesh's UV coordinates. CubeReflectionMapping and
     *   CubeRefractionMapping are for use with a CubeTexture, which is made up of six textures, one for each face of the
     *   cube. CubeReflectionMapping is the default for a CubeTexture. EquirectangularReflectionMapping and
     *   EquirectangularRefractionMapping are for use with an equirectangular environment map. Also called a lat-long
     *   map, an equirectangular texture represents a 360-degree view along the horizontal centerline, and a 180-degree
     *   view along the vertical axis, with the top and bottom edges of the image corresponding to the north and south
     *   poles of a mapped sphere.
     */
    if (typeof options.mapping === 'undefined') {
      options.mapping = Texture.UVMapping;
    }

    /**
     * @param wrapS
     * - No comment
     */
    if (typeof options.wrapS === 'undefined') {
      options.wrapS = Texture.RepeatWrapping;
    }

    /**
     * @param wrapT
     * - No comment
     */
    if (typeof options.wrapT === 'undefined') {
      options.wrapT = Texture.RepeatWrapping;
    }

    /**
     * @param offset
     * - No comment
     */
    if (typeof options.offset === 'undefined') {
      options.offset = new Vector2();
    }

    /**
     * @param repeat
     * - No comment
     */
    if (typeof options.repeat === 'undefined') {
      options.repeat = new Vector2({x:1, y:1});
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "wrapS",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Texture.RepeatWrapping,
                  Texture.ClampToEdgeWrapping,
                  Texture.MirroredRepeatWrapping
                ],
                "possibleLabels": [
                  "Repeat",
                  "Clamp To Edge",
                  "Mirrored Repeat"
                ]
              },
              {
                "property": "wrapT",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Texture.RepeatWrapping,
                  Texture.ClampToEdgeWrapping,
                  Texture.MirroredRepeatWrapping
                ],
                "possibleLabels": [
                  "Repeat",
                  "Clamp To Edge",
                  "Mirrored Repeat"
                ]
              },
              {
                "property": "mapping",
                "Constructor" : this.getClassConstructor("Number"),
                "possibleValues": [
                  Texture.UVMapping,
                  Texture.CubeReflectionMapping,
                  Texture.CubeRefractionMapping,
                  Texture.EquirectangularReflectionMapping,
                  Texture.EquirectangularRefractionMapping,
                  Texture.CubeUVReflectionMapping
                ],
                "possibleLabels": [
                  "UV Mapping",
                  "Cube Reflection",
                  "Cube Refraction",
                  "Equirectangular Reflection",
                  "Equirectangular Refraction",
                  "Cube UV Reflection"
                ]
              },
              {
                "property": "offset",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector2"),
                "min": -10,
                "max": 10,
                "step": 0.001
              },
              {
                "property": "repeat",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector2"),
                "min": 0.001,
                "max": 1000,
                "step": 0.001
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "wrapS",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Texture.RepeatWrapping,
          Texture.ClampToEdgeWrapping,
          Texture.MirroredRepeatWrapping
        ],
        "possibleLabels": [
          "Repeat",
          "Clamp To Edge",
          "Mirrored Repeat"
        ]
      },
      {
        "property": "wrapT",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Texture.RepeatWrapping,
          Texture.ClampToEdgeWrapping,
          Texture.MirroredRepeatWrapping
        ],
        "possibleLabels": [
          "Repeat",
          "Clamp To Edge",
          "Mirrored Repeat"
        ]
      },
      {
        "property": "mapping",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "possibleValues": [
          Texture.UVMapping,
          Texture.CubeReflectionMapping,
          Texture.CubeRefractionMapping,
          Texture.EquirectangularReflectionMapping,
          Texture.EquirectangularRefractionMapping,
          Texture.CubeUVReflectionMapping
        ],
        "possibleLabels": [
          "UV Mapping",
          "Cube Reflection",
          "Cube Refraction",
          "Equirectangular Reflection",
          "Equirectangular Refraction",
          "Cube UV Reflection"
        ]
      },
      {
        "property": "offset",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector2"),
        "type": "R3EventObjComponentMathsVector2",
        "min": -10,
        "max": 10,
        "step": 0.001
      },
      {
        "property": "repeat",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector2"),
        "type": "R3EventObjComponentMathsVector2",
        "min": 0.001,
        "max": 1000,
        "step": 0.001
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Texture.Type = 'R3EventObjComponentGraphicsTexture';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Texture.RepeatWrapping
 * - With RepeatWrapping the texture will simply repeat to infinity
 */
Texture.RepeatWrapping = 1000;

/**
 * @param Texture.ClampToEdgeWrapping
 * - The last pixel of the texture stretches to the edge of the mesh.
 */
Texture.ClampToEdgeWrapping = 1001;

/**
 * @param Texture.MirroredRepeatWrapping
 * - With MirroredRepeatWrapping the texture will repeats to infinity, mirroring on each repeat.
 */
Texture.MirroredRepeatWrapping = 1002;

/**
 * @param Texture.UVMapping
 * - This maps the texture using the mesh's UV coordinates.
 */
Texture.UVMapping = 300;

/**
 * @param Texture.CubeReflectionMapping
 * - CubeReflectionMapping and CubeRefractionMapping are for use with a CubeTexture, which is made up of six
 *   textures, one for each face of the cube. CubeReflectionMapping is the default for a CubeTexture.
 */
Texture.CubeReflectionMapping = 301;

/**
 * @param Texture.CubeRefractionMapping
 * - CubeReflectionMapping and CubeRefractionMapping are for use with a CubeTexture, which is made up of six
 *   textures, one for each face of the cube. CubeReflectionMapping is the default for a CubeTexture.
 */
Texture.CubeRefractionMapping = 302;

/**
 * @param Texture.EquirectangularReflectionMapping
 * - EquirectangularReflectionMapping and EquirectangularRefractionMapping are for use with an equirectangular
 *   environment map. Also called a lat-long map, an equirectangular texture represents a 360-degree view along the
 *   horizontal centerline, and a 180-degree view along the vertical axis, with the top and bottom edges of the image
 *   corresponding to the north and south poles of a mapped sphere.
 */
Texture.EquirectangularReflectionMapping = 303;

/**
 * @param Texture.EquirectangularRefractionMapping
 * - EquirectangularReflectionMapping and EquirectangularRefractionMapping are for use with an equirectangular
 *   environment map. Also called a lat-long map, an equirectangular texture represents a 360-degree view along the
 *   horizontal centerline, and a 180-degree view along the vertical axis, with the top and bottom edges of the image
 *   corresponding to the north and south poles of a mapped sphere.
 */
Texture.EquirectangularRefractionMapping = 304;

/**
 * @param Texture.CubeUVReflectionMapping
 * - No comment
 */
Texture.CubeUVReflectionMapping = 306;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Texture as default};
