//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//CUSTOM_IMPORTS_START
//CUSTOM_IMPORTS_END

import R3 from '../R3.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_OPTIONS_START
 CUSTOM_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
  Subscriptions={} - Holds a reference to each subscription for Events.*
  Key={}
	Key.KEY_CANCEL = 3
	Key.KEY_HELP = 6
	Key.KEY_BACK_SPACE = 8
	Key.KEY_TAB = 9
	Key.KEY_CLEAR = 12
	Key.KEY_RETURN = 13
	Key.KEY_ENTER = 14
	Key.KEY_SHIFT = 16
	Key.KEY_CONTROL = 17
	Key.KEY_ALT = 18
	Key.KEY_PAUSE = 19
	Key.KEY_CAPS_LOCK = 20
	Key.KEY_ESCAPE = 27
	Key.KEY_SPACE = 32
	Key.KEY_PAGE_UP = 33
	Key.KEY_PAGE_DOWN = 34
	Key.KEY_END = 35
	Key.KEY_HOME = 36
	Key.KEY_LEFT = 37
	Key.KEY_UP = 38
	Key.KEY_RIGHT = 39
	Key.KEY_DOWN = 40
	Key.KEY_PRINT_SCREEN = 44
	Key.KEY_INSERT = 45
	Key.KEY_DELETE = 46
	Key.KEY_0 = 48
	Key.KEY_1 = 49
	Key.KEY_2 = 50
	Key.KEY_3 = 51
	Key.KEY_4 = 52
	Key.KEY_5 = 53
	Key.KEY_6 = 54
	Key.KEY_7 = 55
	Key.KEY_8 = 56
	Key.KEY_9 = 57
	Key.KEY_SEMICOLON = 59
	Key.KEY_EQUALS = 61
	Key.KEY_A = 65
	Key.KEY_B = 66
	Key.KEY_C = 67
	Key.KEY_D = 68
	Key.KEY_E = 69
	Key.KEY_F = 70
	Key.KEY_G = 71
	Key.KEY_H = 72
	Key.KEY_I = 73
	Key.KEY_J = 74
	Key.KEY_K = 75
	Key.KEY_L = 76
	Key.KEY_M = 77
	Key.KEY_N = 78
	Key.KEY_O = 79
	Key.KEY_P = 80
	Key.KEY_Q = 81
	Key.KEY_R = 82
	Key.KEY_S = 83
	Key.KEY_T = 84
	Key.KEY_U = 85
	Key.KEY_V = 86
	Key.KEY_W = 87
	Key.KEY_X = 88
	Key.KEY_Y = 89
	Key.KEY_Z = 90
	Key.KEY_CONTEXT_MENU = 93
	Key.KEY_NUMPAD0 = 96
	Key.KEY_NUMPAD1 = 97
	Key.KEY_NUMPAD2 = 98
	Key.KEY_NUMPAD3 = 99
	Key.KEY_NUMPAD4 = 100
	Key.KEY_NUMPAD5 = 101
	Key.KEY_NUMPAD6 = 102
	Key.KEY_NUMPAD7 = 103
	Key.KEY_NUMPAD8 = 104
	Key.KEY_NUMPAD9 = 105
	Key.KEY_MULTIPLY = 106
	Key.KEY_ADD = 107
	Key.KEY_SEPARATOR = 108
	Key.KEY_SUBTRACT = 109
	Key.KEY_DECIMAL = 110
	Key.KEY_DIVIDE = 111
	Key.KEY_F1 = 112
	Key.KEY_F2 = 113
	Key.KEY_F3 = 114
	Key.KEY_F4 = 115
	Key.KEY_F5 = 116
	Key.KEY_F6 = 117
	Key.KEY_F7 = 118
	Key.KEY_F8 = 119
	Key.KEY_F9 = 120
	Key.KEY_F10 = 121
	Key.KEY_F11 = 122
	Key.KEY_F12 = 123
	Key.KEY_F13 = 124
	Key.KEY_F14 = 125
	Key.KEY_F15 = 126
	Key.KEY_F16 = 127
	Key.KEY_F17 = 128
	Key.KEY_F18 = 129
	Key.KEY_F19 = 130
	Key.KEY_F20 = 131
	Key.KEY_F21 = 132
	Key.KEY_F22 = 133
	Key.KEY_F23 = 134
	Key.KEY_F24 = 135
	Key.KEY_NUM_LOCK = 144
	Key.KEY_SCROLL_LOCK = 145
	Key.KEY_COMMA = 188
	Key.KEY_PERIOD = 190
	Key.KEY_SLASH = 191
	Key.KEY_BACK_QUOTE = 192
	Key.KEY_OPEN_BRACKET = 219
	Key.KEY_BACK_SLASH = 220
	Key.KEY_CLOSE_BRACKET = 221
	Key.KEY_QUOTE = 222
	Key.KEY_META = 224
	Key.KEY_ALT_RIGHT = 225
  Mouse = {}
  Mouse.LEFT_BUTTON = 1
  Mouse.MIDDLE_BUTTON = 2
  Mouse.RIGHT_BUTTON = 3
 CUSTOM_STATIC_OPTIONS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
  async serialize(eventId, payload) - Calls Event.Serialize()
  emit(eventId, payload) - Calls Event.Emit()
  on(eventId, callback, options = {priority:10}) - Calls Event.On()
  once(eventId, callback, options = {priority:10}) - Calls Event.Once()
 CUSTOM_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
  async Serialize(eventId, payload) - Similar to Event.Emit however it serializes the whole event chain by 'await'-ing the entire event chain.
  Emit(eventId, payload) - Calls all subscription functions registered to eventId with payload {object, options, cb, errorCb, immediateCb} as arg. Calls 'cb' after subscription has executed. If an exception occurs during execution, the errorCb is called with the error as argument.
  On(eventId, callback, options = {priority:10}) - Subscribes to 'eventName', ex. Event.* and executes 'callback()' when eventName is raised @returns {{fn: function, remove: function}} - A handle to the subscription callback and a remove() function to remove the subscription
  Once(eventId, callback, options = {priority:10}) - Subscribes to 'eventName', ex. Event.* and executes 'callback()' when eventName is raised - Removes the handle to this event when done
  Setup(eventId, callback, once = false, options) - Set up the event info
  GetEventName(eventId) - Returns the name of the event as a string @returns string
  GetKeyName(keyId) - Returns the name of the key event
  GetMouseButtonName(keyId) - Returns the name of the mouse event
  SetPayloadDefaults(eventId, payload = {}) - Set up the default payload options
  GetEvents() - Returns a list of all events
 CUSTOM_STATIC_METHODS_END

 **/

export class Event extends R3 {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    /**
     * Event.INITIALIZE_COMPONENT(10)  = Linking  -> Stores a reference to it
     * Event.INITIALIZE_COMPONENT(20)  = Storage  -> Stores a reference to it
     * Event.INITIALIZE_COMPONENT(30)  = Code     -> calls createEditor()
     * Event.INITIALIZE_COMPONENT(40)  = Maths    -> calls createInstance()
     * Event.INITIALIZE_COMPONENT(50)  = Audio    -> calls createInstance()
     * Event.INITIALIZE_COMPONENT(60)  = Physics  -> calls createInstance()
     * Event.INITIALIZE_COMPONENT(70)  = Graphics -> Stores a reference to it, calls createInstance()
     * Event.INITIALIZE_COMPONENT(80)  = Entity   -> Finds the deepest entity child(ren) of the component - calls activate()
     * Event.INITIALIZE_COMPONENT(90)  = Input    -> Stores a reference to it, calls createGui(), updates GUI for all objects which can have this type of component assigned to it
     * Event.INITIALIZE_COMPONENT(100)  = Gui      -> Stores a reference to it, calls createGui(), updates GUI for all objects which can have this type of component assigned to it
     *
     * Event.DISPOSE_COMPONENT(10) = Input     -> If input component -> calls deRegister()
     * Event.DISPOSE_COMPONENT(20) = Entity    -> Finds the deepest entity child(ren) of the component - calls deActivate()
     * Event.DISPOSE_COMPONENT(30) = Graphics  -> Removes references to it, if graphics component -> calls deleteInstance(), clears validObjects cache for that component
     * Event.DISPOSE_COMPONENT(40) = Physics   -> If physics component -> calls deleteInstance(), clears validObjects cache for that component
     * Event.DISPOSE_COMPONENT(50) = Audio     -> If audio component -> calls deleteInstance(), clears validObjects cache for that component
     * Event.DISPOSE_COMPONENT(60) = Maths     -> If maths component -> calls deleteInstance(), clears validObjects cache for that component
     * Event.DISPOSE_COMPONENT(70) = Code      -> If code component  -> calls deleteEditor()
     * Event.DISPOSE_COMPONENT(80) = Gui       -> Removes a reference to it, if not maths -> calls deleteGui()
     * Event.DISPOSE_COMPONENT(90) = Storage   -> Removes a reference to it
     * Event.DISPOSE_COMPONENT(100) = Linking   -> removes object from Objects (if not maths) - unsets all references to the object from children to null - sets project to null
     *
     * Event.AFTER_ASSIGN_PROPERTY(10) = Maths     -> Revalidates math component -> calls createInstance, deleteInstance or updateInstance
     * Event.AFTER_ASSIGN_PROPERTY(20) = Audio     -> Revalidates audio component -> calls createInstance, deleteInstance, or updateInstance
     * Event.AFTER_ASSIGN_PROPERTY(30) = Physics   -> Revalidates physics component -> calls createInstance, deleteInstance, or updateInstance
     * Event.AFTER_ASSIGN_PROPERTY(40) = Graphics  -> Revalidates graphics component -> calls createInstance, deleteInstance or updateInstance
     * Event.AFTER_ASSIGN_PROPERTY(50) = Input     -> Revalidates input component -> calls createInstance, deleteInstance or updateInstance
     * Event.AFTER_ASSIGN_PROPERTY(60) = Entity    -> Revalidates the entity -> calls activate() or deActivate(), or both (de- then activate) if code changed, if code component changed, finds entities belonging to that code and revalidates them, calls activate/deActive, or both if event property changed
     * Event.AFTER_ASSIGN_PROPERTY(70) = Code      -> If update not from outside editor, calls updateEditor() -> recompiles code
     * Event.AFTER_ASSIGN_PROPERTY(80) = Gui       -> updates the GUI for that object, updates the GUI for all children referencing that object
     *
     * Event.CREATE_INSTANCE(20) = Maths -> Looks at first math parents - if the parents have no instance but is valid, creates instances, then creates its own instance, then re-validates all children and calls createInstance
     * Event.CREATE_INSTANCE(40) = Graphics -> Looks at first graphics parents -> does same as math, but if parent invalid returns right away
     *
     * Event.UPDATE_INSTANCE(20) = Maths -> Same as audio but for math components
     * Event.UPDATE_INSTANCE(40) = Graphics -> If graphics component, Updates the current instance, updates first children of the component
     *
     * Event.DELETE_INSTANCE(10) = Graphics -> looks at first graphics children, if forceDelete removes reference to this component which triggers AFTER_ASSIGN, otherwise forces revalidation, if invalid, deletes the instance, then deletes its own instance
     * Event.DELETE_INSTANCE(30) = Maths -> does same as graphics system
     */
    //CUSTOM_OPTIONS_INIT_END

    Object.assign(this, options);

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  /**
   * serialize()
   * - Calls Event.Serialize()
   * @param eventId
   * @param payload
   * - No returns
   */
  async serialize(eventId, payload) {

    //CUSTOM_SERIALIZE_BEFORE_START
    //CUSTOM_SERIALIZE_BEFORE_END

    //GENERATED_SERIALIZE_BEFORE_START
    //GENERATED_SERIALIZE_BEFORE_END

    //CUSTOM_SERIALIZE_BEFORE_GENERATED_START
    //CUSTOM_SERIALIZE_BEFORE_GENERATED_END

    //GENERATED_SERIALIZE_START
    //GENERATED_SERIALIZE_END

    //CUSTOM_SERIALIZE_START
    return await Event.Serialize(eventId, payload);
    //CUSTOM_SERIALIZE_END

    //GENERATED_SERIALIZE_AFTER_START
    //GENERATED_SERIALIZE_AFTER_END

  }

  /**
   * emit()
   * - Calls Event.Emit()
   * @param eventId
   * @param payload
   * - No returns
   */
  emit(eventId, payload) {

    //CUSTOM_EMIT_BEFORE_START
    //CUSTOM_EMIT_BEFORE_END

    //GENERATED_EMIT_BEFORE_START
    //GENERATED_EMIT_BEFORE_END

    //CUSTOM_EMIT_BEFORE_GENERATED_START
    //CUSTOM_EMIT_BEFORE_GENERATED_END

    //GENERATED_EMIT_START
    //GENERATED_EMIT_END

    //CUSTOM_EMIT_START
    return Event.Emit(
      eventId,
      payload
    );
    //CUSTOM_EMIT_END

    //GENERATED_EMIT_AFTER_START
    //GENERATED_EMIT_AFTER_END

  }

  /**
   * on()
   * - Calls Event.On()
   * @param eventId
   * @param callback
   * @param {Object} [options={priority:10}]
   * - No returns
   */
  on(eventId, callback, options = {priority:10}) {

    //CUSTOM_ON_BEFORE_START
    //CUSTOM_ON_BEFORE_END

    //GENERATED_ON_BEFORE_START
    //GENERATED_ON_BEFORE_END

    //CUSTOM_ON_BEFORE_GENERATED_START
    //CUSTOM_ON_BEFORE_GENERATED_END

    //GENERATED_ON_START
    //GENERATED_ON_END

    //CUSTOM_ON_START
    return Event.On(
      eventId,
      callback,
      options
    );
    //CUSTOM_ON_END

    //GENERATED_ON_AFTER_START
    //GENERATED_ON_AFTER_END

  }

  /**
   * once()
   * - Calls Event.Once()
   * @param eventId
   * @param callback
   * @param {Object} [options={priority:10}]
   * - No returns
   */
  once(eventId, callback, options = {priority:10}) {

    //CUSTOM_ONCE_BEFORE_START
    //CUSTOM_ONCE_BEFORE_END

    //GENERATED_ONCE_BEFORE_START
    //GENERATED_ONCE_BEFORE_END

    //CUSTOM_ONCE_BEFORE_GENERATED_START
    //CUSTOM_ONCE_BEFORE_GENERATED_END

    //GENERATED_ONCE_START
    //GENERATED_ONCE_END

    //CUSTOM_ONCE_START
    return Event.Once(
      eventId,
      callback,
      options
    );
    //CUSTOM_ONCE_END

    //GENERATED_ONCE_AFTER_START
    //GENERATED_ONCE_AFTER_END

  }

  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  /**
   * Serialize()
   * - Similar to Event.Emit however it serializes the whole event chain by 'await'-ing the entire event chain.
   * @param eventId
   * @param payload
   * - No returns
   */
  static async Serialize(eventId, payload) {

    //GENERATED_STATIC_SERIALIZE_START
    //GENERATED_STATIC_SERIALIZE_END

    //CUSTOM_STATIC_SERIALIZE_START
    payload = Event.SetPayloadDefaults(eventId, payload);

    if (Event.Subscriptions.hasOwnProperty(eventId)) {

      let subscriptionIds = Object.keys(Event.Subscriptions[eventId]);

      subscriptionIds.sort();

      for (let s = 0; s < subscriptionIds.length; s++) {

        let subscription = Event.Subscriptions[eventId][subscriptionIds[s]];

        try {

          let results = await subscription.fn(payload);

          if (results) {
            payload.results = [...payload.results, ...results];
          }

          /**
           * Here the client callback executes
           */
          if (typeof payload.cb === 'function') {
            payload.cb(payload.results);
            delete payload.cb;
          }

          /**
           * If this is a 'execute once' event - remove this listener
           */
          if (subscription.once) {
            subscription.remove();
          }

        } catch (error) {

          /**
           * Delete the callback on error
           */
          if (typeof payload.cb === 'function') {
            delete payload.cb;
          }

          /**
           * If this is a 'execute once' event - remove this listener
           */
          if (subscription.once) {
            subscription.remove();
          }

          /**
           * Execute the client error callback
           */
          if (payload.errorCb) {
            payload.errorCb(error);
          } else {
            console.error(error);
            throw error;
          }

        }

      }

    } else {

      try {
        /**
         * Here the client callback executes
         */
        if (typeof payload.cb === 'function') {
          payload.cb([payload.results]);
          delete payload.cb;
        }
      } catch(error) {

        /**
         * Delete the callback on error
         */
        if (typeof payload.cb === 'function') {
          delete payload.cb;
        }

        if (payload.errorCb) {
          payload.errorCb(error);
        } else {
          console.error(error);
          throw error;
        }

      }
    }
    //CUSTOM_STATIC_SERIALIZE_END

    //GENERATED_STATIC_SERIALIZE_AFTER_START
    //GENERATED_STATIC_SERIALIZE_AFTER_END

    //CUSTOM_STATIC_GENERATED_SERIALIZE_AFTER_START
    //CUSTOM_STATIC_GENERATED_SERIALIZE_AFTER_END

  }
  /**
   * Emit()
   * - Calls all subscription functions registered to eventId with payload {object, options, cb, errorCb, immediateCb}
   *   as arg. Calls 'cb' after subscription has executed. If an exception occurs during execution, the errorCb is
   *   called with the error as argument.
   * @param eventId
   * @param payload
   * - No returns
   */
  static Emit(eventId, payload) {

    //GENERATED_STATIC_EMIT_START
    //GENERATED_STATIC_EMIT_END

    //CUSTOM_STATIC_EMIT_START
    payload = Event.SetPayloadDefaults(eventId, payload);

    // console.log(Event.GetEventName(eventId));

    if (Event.Subscriptions.hasOwnProperty(eventId)) {

      let subscriptionIds = Object.keys(Event.Subscriptions[eventId]).map((id)=>{return Number(id)});

      subscriptionIds.sort((a, b) => {
        if (a > b) {
          return 1;
        }
        return -1;
      });

      for (let s = 0; s < subscriptionIds.length; s++) {

        let subscription = Event.Subscriptions[eventId][subscriptionIds[s]];

        try {

          let results = subscription.fn(payload);

          if (results) {
            payload.results = [...payload.results, ...results];
          }

          /**
           * Here the client callback executes
           */
          if (typeof payload.cb === 'function') {
            payload.cb(payload.results);
            delete payload.cb;
          }

          /**
           * If this is a 'execute once' event - remove this listener
           */
          if (subscription.once) {
            subscription.remove();
          }

        } catch (error) {

          /**
           * Delete the callback on error
           */
          if (typeof payload.cb === 'function') {
            delete payload.cb;
          }

          /**
           * If this is a 'execute once' event - remove this listener
           */
          if (subscription.once) {
            subscription.remove();
          }

          if (payload.errorCb) {
            payload.errorCb(error);
          } else {
            console.error(error);
            throw error;
          }

        }
      }

    } else {

      try {

        /**
         * Here the client callback executes
         */
        if (typeof payload.cb === 'function') {
          payload.cb([payload.results]);
          delete payload.cb;
        }

      } catch(error) {

        /**
         * Delete the callback on error
         */
        if (typeof payload.cb === 'function') {
          delete payload.cb;
        }

        if (payload.errorCb) {
          payload.errorCb(error);
        } else {
          console.error(error);
          throw error;
        }

      }
    }
    //CUSTOM_STATIC_EMIT_END

    //GENERATED_STATIC_EMIT_AFTER_START
    //GENERATED_STATIC_EMIT_AFTER_END

    //CUSTOM_STATIC_GENERATED_EMIT_AFTER_START
    //CUSTOM_STATIC_GENERATED_EMIT_AFTER_END

  }
  /**
   * On()
   * - Subscribes to 'eventName', ex. Event.* and executes 'callback()' when eventName is raised
   * @param eventId
   * @param callback
   * @param {Object} [options={priority:10}]
   * @returns {{fn: function, remove: function}} - A handle to the subscription callback and a remove() function to remove the subscription
   */
  static On(eventId, callback, options = {priority:10}) {

    //GENERATED_STATIC_ON_START
    //GENERATED_STATIC_ON_END

    //CUSTOM_STATIC_ON_START
    return Event.Setup(eventId, callback, false, options);
    //CUSTOM_STATIC_ON_END

    //GENERATED_STATIC_ON_AFTER_START
    //GENERATED_STATIC_ON_AFTER_END

    //CUSTOM_STATIC_GENERATED_ON_AFTER_START
    //CUSTOM_STATIC_GENERATED_ON_AFTER_END

  }
  /**
   * Once()
   * - Subscribes to 'eventName', ex. Event.* and executes 'callback()' when eventName is raised - Removes the handle
   *   to this event when done
   * @param eventId
   * @param callback
   * @param {Object} [options={priority:10}]
   * - No returns
   */
  static Once(eventId, callback, options = {priority:10}) {

    //GENERATED_STATIC_ONCE_START
    //GENERATED_STATIC_ONCE_END

    //CUSTOM_STATIC_ONCE_START
    return Event.Setup(eventId, callback, true, options);
    //CUSTOM_STATIC_ONCE_END

    //GENERATED_STATIC_ONCE_AFTER_START
    //GENERATED_STATIC_ONCE_AFTER_END

    //CUSTOM_STATIC_GENERATED_ONCE_AFTER_START
    //CUSTOM_STATIC_GENERATED_ONCE_AFTER_END

  }
  /**
   * Setup()
   * - Set up the event info
   * @param eventId
   * @param callback
   * @param {Boolean} [once=false]
   * @param options
   * - No returns
   */
  static Setup(eventId, callback, once = false, options) {

    //GENERATED_STATIC_SETUP_START
    //GENERATED_STATIC_SETUP_END

    //CUSTOM_STATIC_SETUP_START
    let {priority, allowMultiple} = options;

    priority = Number(priority);

    if (!Event.Subscriptions.hasOwnProperty(eventId)) {
      Event.Subscriptions[eventId] = {};
    }

    if (allowMultiple) {

      while (Event.Subscriptions[eventId][priority]) {
        priority++;
      }

    } else {
      if (Event.Subscriptions[eventId][priority]) {
        throw new Error(`Multiple ${Event.GetEventName(eventId)} with the same priority: ${priority}`);
      }
    }

    let subscription = {
      fn: callback,
      remove: function (_eventId, _priority) {
        return function () {
          /**
           * Stop listening for this event from this component
           */
          delete Event.Subscriptions[_eventId][_priority];

          /**
           * If the length of listeners is 0, stop referencing this event
           * @type {string[]}
           */
          let listeners = Object.keys(Event.Subscriptions[_eventId]);

          if (listeners.length === 0) {
            delete Event.Subscriptions[_eventId];
          }

          return true;
        }
      }(eventId, priority),
      once,
      name: Event.GetEventName(eventId)
    };

    Event.Subscriptions[eventId][priority] = subscription;

    return subscription;
    //CUSTOM_STATIC_SETUP_END

    //GENERATED_STATIC_SETUP_AFTER_START
    //GENERATED_STATIC_SETUP_AFTER_END

    //CUSTOM_STATIC_GENERATED_SETUP_AFTER_START
    //CUSTOM_STATIC_GENERATED_SETUP_AFTER_END

  }
  /**
   * GetEventName()
   * - Returns the name of the event as a string
   * @param eventId
   * @returns string
   */
  static GetEventName(eventId) {

    //GENERATED_STATIC_GET_EVENT_NAME_START
    switch(eventId) {
      case 160519483 : return 'Event.ACTIVATE_ENTITY';
      case 1702201046 : return 'Event.ACTIVATE_ROTATION';
      case 1708768199 : return 'Event.ADD_PROJECT';
      case 1468311086 : return 'Event.AFTER_ASSIGN_PROPERTY';
      case 143958310 : return 'Event.APPLY_FORCE';
      case 568562447 : return 'Event.APPLY_TORQUE';
      case 1227260620 : return 'Event.APP_CREATE_COMPONENT';
      case 261607335 : return 'Event.BEFORE_ASSIGN_PROPERTY';
      case 1788170503 : return 'Event.CANVAS_RESIZE';
      case 1583909145 : return 'Event.CHANGE_ROLE_USER';
      case 160759018 : return 'Event.CHANGE_ROLE_USER_AFTER';
      case 2051641575 : return 'Event.CLONE_COMPONENT';
      case 1032947612 : return 'Event.CLONE_COMPONENT_AFTER';
      case 625606631 : return 'Event.CLONE_ENTITY';
      case 1250114070 : return 'Event.CLONE_ENTITY_AFTER';
      case 1457607886 : return 'Event.CLONE_FILE';
      case 1627689361 : return 'Event.CLONE_FILE_AFTER';
      case 2059990889 : return 'Event.CLONE_GROUP';
      case 1070369434 : return 'Event.CLONE_GROUP_AFTER';
      case 1550289077 : return 'Event.CLONE_INSTANCE';
      case 931416701 : return 'Event.CLONE_PROJECT';
      case 1077823488 : return 'Event.CLONE_PROJECT_AFTER';
      case 1457151615 : return 'Event.CLONE_USER';
      case 1837224578 : return 'Event.CLONE_USER_AFTER';
      case 1986056559 : return 'Event.CODE_SYSTEM_STARTED';
      case 1998922427 : return 'Event.CODE_SYSTEM_STOPPED';
      case 1992878834 : return 'Event.COMPONENT_CREATED';
      case 2121400794 : return 'Event.CREATE_AMBIENT_AFTER';
      case 1164761980 : return 'Event.CREATE_BASIC_AFTER';
      case 214370640 : return 'Event.CREATE_EDITOR_CODE';
      case 492962148 : return 'Event.CREATE_GUI_OBJ';
      case 54253308 : return 'Event.CREATE_INSTANCE';
      case 190420822 : return 'Event.CREATE_PROJECT';
      case 831868127 : return 'Event.DELETE_EDITOR_CODE';
      case 1812383507 : return 'Event.DELETE_GUI_OBJ';
      case 2101864139 : return 'Event.DELETE_INSTANCE';
      case 1144790421 : return 'Event.DEPLOY_PROJECT';
      case 1594449125 : return 'Event.DE_ACTIVATE_ENTITY';
      case 1024084992 : return 'Event.DE_ACTIVATE_ROTATION';
      case 1079664773 : return 'Event.DE_REGISTER_CONTROL_KEYBOARD';
      case 910634639 : return 'Event.DE_REGISTER_CONTROL_MOUSE';
      case 37639233 : return 'Event.DE_REGISTER_CONTROL_ORBIT_CONTROLS';
      case 904170485 : return 'Event.DE_REGISTER_CONTROL_TOUCH';
      case 183496622 : return 'Event.DISPOSE_ANIMATION_ENTITY';
      case 821635552 : return 'Event.DISPOSE_AUDIO_COMPONENT';
      case 440892097 : return 'Event.DISPOSE_CODE_COMPONENT';
      case 1341267927 : return 'Event.DISPOSE_COMPONENT';
      case 1706805271 : return 'Event.DISPOSE_ENTITY';
      case 1303225680 : return 'Event.DISPOSE_FILE';
      case 1207725475 : return 'Event.DISPOSE_GRAPHICS_COMPONENT';
      case 1744095317 : return 'Event.DISPOSE_GROUP';
      case 1169392852 : return 'Event.DISPOSE_INPUT_COMPONENT';
      case 167775691 : return 'Event.DISPOSE_MATHS_COMPONENT';
      case 1981712243 : return 'Event.DISPOSE_OBJECT';
      case 628491247 : return 'Event.DISPOSE_PHYSICS_COMPONENT';
      case 1641091771 : return 'Event.DISPOSE_PROJECT';
      case 1302769409 : return 'Event.DISPOSE_USER';
      case 186564206 : return 'Event.DOUBLE_CLICK';
      case 1991205790 : return 'Event.DO_NOT_RENDER';
      case 1501940921 : return 'Event.DUAL_SCREEN_MODE';
      case 33879968 : return 'Event.ENTITY_CREATED';
      case 184584295 : return 'Event.FORGOT_PASSWORD_SUCCESS';
      case 1382838624 : return 'Event.GET_ACTIVE_GRAPHICS_RUNTIME';
      case 2146424780 : return 'Event.GET_ALL_EVENTS';
      case 23830515 : return 'Event.GET_API_URL';
      case 2072787533 : return 'Event.GET_COMPONENTS';
      case 856120088 : return 'Event.GET_CURRENT_CAMERA';
      case 856074469 : return 'Event.GET_CURRENT_CANVAS';
      case 115133500 : return 'Event.GET_CURRENT_GROUP';
      case 119474899 : return 'Event.GET_CURRENT_LIGHT';
      case 1628593770 : return 'Event.GET_CURRENT_PROJECT';
      case 1557051846 : return 'Event.GET_CURRENT_RENDERER';
      case 125759049 : return 'Event.GET_CURRENT_SCENE';
      case 1381341618 : return 'Event.GET_CURRENT_USER';
      case 1755799755 : return 'Event.GET_EDITOR_CONTROLS';
      case 457085342 : return 'Event.GET_ENTITIES';
      case 104072463 : return 'Event.GET_GROUPS';
      case 799966981 : return 'Event.GET_LIST';
      case 55803000 : return 'Event.GET_MESHES';
      case 189076137 : return 'Event.GET_MESH_INSTANCES';
      case 884357257 : return 'Event.GET_OBJECTS';
      case 745016902 : return 'Event.GET_OBJECTS_BY_CONSTRUCTOR';
      case 1377487692 : return 'Event.GET_OBJECT_COUNT';
      case 1313085009 : return 'Event.GET_OBJECT_WITH_ID';
      case 1560502871 : return 'Event.GET_PROJECTS';
      case 322953556 : return 'Event.GET_R3_EVENT_CONSTRUCTOR';
      case 1623470335 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR';
      case 800021080 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR';
      case 771511208 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR';
      case 432860662 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR';
      case 355994148 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR';
      case 455122630 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR';
      case 257238081 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR';
      case 1395667583 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR';
      case 1277687849 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR';
      case 1051051018 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR';
      case 1798324181 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR';
      case 1298402013 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR';
      case 1408643228 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR';
      case 536594703 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR';
      case 718868526 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR';
      case 347293839 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR';
      case 1107496583 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR';
      case 993183218 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR';
      case 536639206 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR';
      case 1650945747 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR';
      case 1675030456 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR';
      case 762714782 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR';
      case 102596266 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR';
      case 654334069 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR';
      case 464966618 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR';
      case 10520902 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR';
      case 1958395356 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR';
      case 2000350903 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR';
      case 1897434958 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR';
      case 743406220 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR';
      case 783801367 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR';
      case 1253163540 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR';
      case 510720749 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR';
      case 2015461950 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR';
      case 1423458840 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR';
      case 957912936 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR';
      case 180279108 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR';
      case 648082339 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR';
      case 79273700 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR';
      case 2146607007 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR';
      case 270212310 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR';
      case 1978397808 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR';
      case 845457272 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR';
      case 1442761140 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR';
      case 1700321732 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR';
      case 10651237 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR';
      case 1197244750 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR';
      case 955395336 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR';
      case 202771374 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR';
      case 962840270 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR';
      case 1753252114 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR';
      case 1858813173 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR';
      case 2138228935 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR';
      case 1567768581 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR';
      case 821148085 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR';
      case 2065740907 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR';
      case 572390886 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR';
      case 1035064854 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR';
      case 596537284 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR';
      case 960944617 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR';
      case 667541610 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR';
      case 1993998227 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR';
      case 1844976765 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR';
      case 21771105 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR';
      case 527021228 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR';
      case 1152188605 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR';
      case 808022746 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR';
      case 1101425753 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR';
      case 1394828760 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR';
      case 1154937859 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR';
      case 1879260317 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR';
      case 797048517 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR';
      case 598661879 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR';
      case 1752410872 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR';
      case 1747527968 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR';
      case 2030099905 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR';
      case 1856352242 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR';
      case 646275231 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR';
      case 216341917 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR';
      case 72061198 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR';
      case 1481318988 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR';
      case 1940647582 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR';
      case 489208539 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR';
      case 1464198489 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR';
      case 1226241479 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR';
      case 818447954 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR';
      case 1571806043 : return 'Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR';
      case 656107284 : return 'Event.GET_R3_EVENT_OBJ_CONSTRUCTOR';
      case 2099254609 : return 'Event.GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR';
      case 708872450 : return 'Event.GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR';
      case 1629634604 : return 'Event.GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR';
      case 1451455547 : return 'Event.GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR';
      case 536032884 : return 'Event.GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR';
      case 153096538 : return 'Event.GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR';
      case 1395753876 : return 'Event.GET_R3_EVENT_OBJ_USER_CONSTRUCTOR';
      case 1551221256 : return 'Event.GET_R3_GRAPH_CONSTRUCTOR';
      case 1812520188 : return 'Event.GET_R3_MOCK_RAPIER_CONSTRUCTOR';
      case 1494680958 : return 'Event.GET_R3_NODE_CONSTRUCTOR';
      case 2139576119 : return 'Event.GET_R3_RUNTIME_AUDIO_CONSTRUCTOR';
      case 1445152696 : return 'Event.GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR';
      case 386694030 : return 'Event.GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR';
      case 1717925787 : return 'Event.GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR';
      case 580226228 : return 'Event.GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR';
      case 1012955536 : return 'Event.GET_R3_RUNTIME_CODE_CONSTRUCTOR';
      case 1164501458 : return 'Event.GET_R3_RUNTIME_CONSTRUCTOR';
      case 1091258502 : return 'Event.GET_R3_RUNTIME_ENTITY_CONSTRUCTOR';
      case 48860312 : return 'Event.GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR';
      case 375542482 : return 'Event.GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR';
      case 1526996205 : return 'Event.GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR';
      case 611006290 : return 'Event.GET_R3_RUNTIME_GUI_CONSTRUCTOR';
      case 1708898750 : return 'Event.GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR';
      case 1342707389 : return 'Event.GET_R3_RUNTIME_INPUT_CONSTRUCTOR';
      case 46037047 : return 'Event.GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR';
      case 992551108 : return 'Event.GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR';
      case 518959070 : return 'Event.GET_R3_RUNTIME_MATHS_CONSTRUCTOR';
      case 1168876643 : return 'Event.GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR';
      case 200067142 : return 'Event.GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR';
      case 570736080 : return 'Event.GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR';
      case 1076975483 : return 'Event.GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR';
      case 958785006 : return 'Event.GET_R3_RUNTIME_STORAGE_CONSTRUCTOR';
      case 1972245306 : return 'Event.GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR';
      case 1545472584 : return 'Event.GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR';
      case 1603410398 : return 'Event.GET_R3_SYSTEM_AUDIO_CONSTRUCTOR';
      case 678613406 : return 'Event.GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR';
      case 493769449 : return 'Event.GET_R3_SYSTEM_CODE_CONSTRUCTOR';
      case 1611224555 : return 'Event.GET_R3_SYSTEM_CONSTRUCTOR';
      case 532526669 : return 'Event.GET_R3_SYSTEM_ENTITY_CONSTRUCTOR';
      case 445921995 : return 'Event.GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR';
      case 171673671 : return 'Event.GET_R3_SYSTEM_GUI_CONSTRUCTOR';
      case 1878873110 : return 'Event.GET_R3_SYSTEM_INPUT_CONSTRUCTOR';
      case 1055124791 : return 'Event.GET_R3_SYSTEM_MATHS_CONSTRUCTOR';
      case 340884781 : return 'Event.GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR';
      case 817967367 : return 'Event.GET_R3_SYSTEM_STORAGE_CONSTRUCTOR';
      case 209519597 : return 'Event.GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR';
      case 266933483 : return 'Event.GET_R3_UTILS_CONSTRUCTOR';
      case 60996687 : return 'Event.GET_RUNTIME_AUDIO_THREE';
      case 2125074259 : return 'Event.GET_RUNTIME_AUTHENTICATION_AXIOS';
      case 223593621 : return 'Event.GET_RUNTIME_CODE_ACE';
      case 1057737801 : return 'Event.GET_RUNTIME_ENTITY_DEFAULT';
      case 1865405774 : return 'Event.GET_RUNTIME_GRAPHICS_THREE';
      case 1226074589 : return 'Event.GET_RUNTIME_GUI_TWEAK_PANE';
      case 207182122 : return 'Event.GET_RUNTIME_INPUT_CUSTOM';
      case 809289563 : return 'Event.GET_RUNTIME_INPUT_THREE';
      case 1856536954 : return 'Event.GET_RUNTIME_MATHS_THREE';
      case 494374223 : return 'Event.GET_RUNTIME_PHYSICS_RAPIER';
      case 1208579694 : return 'Event.GET_RUNTIME_STORAGE_AXIOS';
      case 1270752809 : return 'Event.GET_RUNTIME_WEBSOCKET_SOCKET_I_O';
      case 1506319997 : return 'Event.GET_SOURCE_API_URL';
      case 1098978187 : return 'Event.GET_SOURCE_URLS';
      case 1979624976 : return 'Event.GET_SOURCE_WEBSOCKET_URL';
      case 1513340612 : return 'Event.GET_STORAGE_OBJECT_BY_ID';
      case 1734896391 : return 'Event.GET_TARGET_API_URL';
      case 900955179 : return 'Event.GET_TARGET_URLS';
      case 1951306982 : return 'Event.GET_TARGET_WEBSOCKET_URL';
      case 458692179 : return 'Event.GET_THREE_INSTANCE';
      case 799689688 : return 'Event.GET_USER';
      case 979423531 : return 'Event.GET_USERS';
      case 892900146 : return 'Event.GET_USERS_LIST';
      case 1208575717 : return 'Event.GET_USER_AFTER';
      case 541311226 : return 'Event.GET_WEBSOCKET_URL';
      case 1269280279 : return 'Event.GUI_SYSTEM_STARTED';
      case 1256414411 : return 'Event.GUI_SYSTEM_STOPPED';
      case 1071163005 : return 'Event.IMAGE_LOADED';
      case 1718955815 : return 'Event.INITIALIZE_AUDIO_COMPONENT';
      case 1354130650 : return 'Event.INITIALIZE_CODE_COMPONENT';
      case 885159394 : return 'Event.INITIALIZE_COMPONENT';
      case 1959217922 : return 'Event.INITIALIZE_ENTITY';
      case 136533335 : return 'Event.INITIALIZE_FILE';
      case 2051449660 : return 'Event.INITIALIZE_GRAPHICS_COMPONENT';
      case 61238812 : return 'Event.INITIALIZE_GROUP';
      case 1371198515 : return 'Event.INITIALIZE_INPUT_COMPONENT';
      case 1586600238 : return 'Event.INITIALIZE_MATHS_COMPONENT';
      case 965418596 : return 'Event.INITIALIZE_OBJ';
      case 1684310950 : return 'Event.INITIALIZE_OBJECT';
      case 1699596490 : return 'Event.INITIALIZE_PHYSICS_COMPONENT';
      case 676306238 : return 'Event.INITIALIZE_PROJECT';
      case 136989606 : return 'Event.INITIALIZE_USER';
      case 710653491 : return 'Event.INVITE_USER';
      case 1279759158 : return 'Event.INVITE_USER_AFTER';
      case 2019220374 : return 'Event.KEY_DOWN';
      case 502925681 : return 'Event.KEY_UP';
      case 264073544 : return 'Event.LOAD_COMPONENT';
      case 1762912907 : return 'Event.LOAD_COMPONENT_AFTER';
      case 1668465688 : return 'Event.LOAD_ENTITY';
      case 454287707 : return 'Event.LOAD_ENTITY_AFTER';
      case 333483713 : return 'Event.LOAD_FILE';
      case 400414018 : return 'Event.LOAD_FILE_AFTER';
      case 1749255610 : return 'Event.LOAD_GROUP';
      case 1679539465 : return 'Event.LOAD_GROUP_AFTER';
      case 1099690900 : return 'Event.LOAD_PROJECT';
      case 1920296273 : return 'Event.LOAD_PROJECT_AFTER';
      case 333939984 : return 'Event.LOAD_USER';
      case 609949235 : return 'Event.LOAD_USER_AFTER';
      case 1000671499 : return 'Event.LOGIN_USER';
      case 468884466 : return 'Event.LOGIN_USER_AFTER';
      case 1772139540 : return 'Event.LOGOUT_USER';
      case 259318871 : return 'Event.LOGOUT_USER_AFTER';
      case 883930666 : return 'Event.MENU_GROUP_ITEM_OPEN';
      case 1483624554 : return 'Event.MENU_GROUP_OPEN';
      case 1712378550 : return 'Event.MENU_OPEN';
      case 794867654 : return 'Event.MESH_DESELECTED';
      case 1565278265 : return 'Event.MESH_SELECTED';
      case 1754253328 : return 'Event.MOUSE_DOWN';
      case 1754521407 : return 'Event.MOUSE_MOVE';
      case 1369422217 : return 'Event.MOUSE_UP';
      case 1435400819 : return 'Event.MOUSE_WHEEL';
      case 1385754507 : return 'Event.PASSWORD_RESET_USER';
      case 1354583048 : return 'Event.PASSWORD_RESET_USER_AFTER';
      case 199970457 : return 'Event.PAUSE_AUDIO';
      case 945711599 : return 'Event.PAUSE_POSITIONAL';
      case 111714653 : return 'Event.PHYSICS_SYSTEM_STARTED';
      case 752124193 : return 'Event.PLAY_AUDIO';
      case 395140725 : return 'Event.PLAY_POSITIONAL';
      case 491190327 : return 'Event.R3_STARTED';
      case 447208838 : return 'Event.RAPIER_RUNTIME_READY';
      case 1083464623 : return 'Event.REGISTER_CONTROL_KEYBOARD';
      case 1838224293 : return 'Event.REGISTER_CONTROL_MOUSE';
      case 1651128341 : return 'Event.REGISTER_CONTROL_ORBIT_CONTROLS';
      case 1831760139 : return 'Event.REGISTER_CONTROL_TOUCH';
      case 2144078707 : return 'Event.REGISTER_USER';
      case 996812304 : return 'Event.REGISTER_USER_AFTER';
      case 393716694 : return 'Event.REMOVE_COMPONENT';
      case 667395053 : return 'Event.REMOVE_COMPONENT_AFTER';
      case 1514181750 : return 'Event.REMOVE_ENTITY';
      case 2133888199 : return 'Event.REMOVE_ENTITY_AFTER';
      case 499007203 : return 'Event.REMOVE_FILE';
      case 1483753120 : return 'Event.REMOVE_FILE_AFTER';
      case 1709450792 : return 'Event.REMOVE_GROUP';
      case 903313259 : return 'Event.REMOVE_GROUP_AFTER';
      case 1587525682 : return 'Event.REMOVE_PROJECT';
      case 549370735 : return 'Event.REMOVE_PROJECT_AFTER';
      case 499463474 : return 'Event.REMOVE_USER';
      case 1693288337 : return 'Event.REMOVE_USER_AFTER';
      case 120087353 : return 'Event.RENDER_AFTER';
      case 599557588 : return 'Event.RENDER_BEFORE';
      case 754437809 : return 'Event.RENDER_OK';
      case 1218443919 : return 'Event.SAVE_COMPONENT';
      case 497615884 : return 'Event.SAVE_COMPONENT_AFTER';
      case 465029263 : return 'Event.SAVE_ENTITY';
      case 74747758 : return 'Event.SAVE_ENTITY_AFTER';
      case 871966582 : return 'Event.SAVE_FILE';
      case 1687012921 : return 'Event.SAVE_FILE_AFTER';
      case 1259965167 : return 'Event.SAVE_GROUP';
      case 1385567502 : return 'Event.SAVE_GROUP_AFTER';
      case 248485589 : return 'Event.SAVE_PROJECT';
      case 1140526504 : return 'Event.SAVE_PROJECT_AFTER';
      case 871510311 : return 'Event.SAVE_USER';
      case 1896548138 : return 'Event.SAVE_USER_AFTER';
      case 1557218156 : return 'Event.SEND_OBJ';
      case 1461546577 : return 'Event.SEND_OBJ_AFTER';
      case 112410343 : return 'Event.SET_API_URL';
      case 825019320 : return 'Event.SET_CURRENT_GROUP';
      case 1081940634 : return 'Event.SET_CURRENT_MODE';
      case 1122645666 : return 'Event.SET_CURRENT_PROJECT';
      case 1082182850 : return 'Event.SET_CURRENT_USER';
      case 586286345 : return 'Event.SET_SOURCE_API_URL';
      case 1511261284 : return 'Event.SET_SOURCE_WEBSOCKET_URL';
      case 814862739 : return 'Event.SET_TARGET_API_URL';
      case 1147225946 : return 'Event.SET_TARGET_WEBSOCKET_URL';
      case 398841594 : return 'Event.SET_WEBSOCKET_URL';
      case 98546841 : return 'Event.STATUS_INFO';
      case 610511149 : return 'Event.STOP_AUDIO';
      case 891373571 : return 'Event.STOP_POSITIONAL';
      case 1459755252 : return 'Event.STOP_WEBSOCKET_SERVICE';
      case 1434990962 : return 'Event.TOUCH_CANCEL';
      case 720174809 : return 'Event.TOUCH_END';
      case 850342683 : return 'Event.TOUCH_MOVE';
      case 585149010 : return 'Event.TOUCH_START';
      case 1018035643 : return 'Event.UPDATE_CONTROL';
      case 1117078269 : return 'Event.UPDATE_EDITOR_CODE';
      case 440564785 : return 'Event.UPDATE_GUI_OBJ';
      case 1678571561 : return 'Event.UPDATE_INSTANCE';
      case 663236753 : return 'Event.WINDOW_RESIZE';
      default:
        throw new Error(`Event type not defined: ${eventId}`);
    }
    //GENERATED_STATIC_GET_EVENT_NAME_END

    //CUSTOM_STATIC_GET_EVENT_NAME_START
    //CUSTOM_STATIC_GET_EVENT_NAME_END

    //GENERATED_STATIC_GET_EVENT_NAME_AFTER_START
    //GENERATED_STATIC_GET_EVENT_NAME_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_EVENT_NAME_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_EVENT_NAME_AFTER_END

  }
  /**
   * GetKeyName()
   * - Returns the name of the key event
   * @param keyId
   * - No returns
   */
  static GetKeyName(keyId) {

    //GENERATED_STATIC_GET_KEY_NAME_START
    //GENERATED_STATIC_GET_KEY_NAME_END

    //CUSTOM_STATIC_GET_KEY_NAME_START
    switch (keyId) {
      case Event.Key.KEY_CANCEL : return 'KEY_CANCEL';
      case Event.Key.KEY_HELP : return 'KEY_HELP';
      case Event.Key.KEY_BACK_SPACE : return 'KEY_BACK_SPACE';
      case Event.Key.KEY_TAB : return 'KEY_TAB';
      case Event.Key.KEY_CLEAR : return 'KEY_CLEAR';
      case Event.Key.KEY_RETURN : return 'KEY_RETURN';
      case Event.Key.KEY_ENTER : return 'KEY_ENTER';
      case Event.Key.KEY_SHIFT : return 'KEY_SHIFT';
      case Event.Key.KEY_CONTROL : return 'KEY_CONTROL';
      case Event.Key.KEY_ALT : return 'KEY_ALT';
      case Event.Key.KEY_PAUSE : return 'KEY_PAUSE';
      case Event.Key.KEY_CAPS_LOCK : return 'KEY_CAPS_LOCK';
      case Event.Key.KEY_ESCAPE : return 'KEY_ESCAPE';
      case Event.Key.KEY_SPACE : return 'KEY_SPACE';
      case Event.Key.KEY_PAGE_UP : return 'KEY_PAGE_UP';
      case Event.Key.KEY_PAGE_DOWN : return 'KEY_PAGE_DOWN';
      case Event.Key.KEY_END : return 'KEY_END';
      case Event.Key.KEY_HOME : return 'KEY_HOME';
      case Event.Key.KEY_LEFT : return 'KEY_LEFT';
      case Event.Key.KEY_UP : return 'KEY_UP';
      case Event.Key.KEY_RIGHT : return 'KEY_RIGHT';
      case Event.Key.KEY_DOWN : return 'KEY_DOWN';
      case Event.Key.KEY_PRINT_SCREEN : return 'KEY_PRINT_SCREEN';
      case Event.Key.KEY_INSERT : return 'KEY_INSERT';
      case Event.Key.KEY_DELETE : return 'KEY_DELETE';
      case Event.Key.KEY_0 : return 'KEY_0';
      case Event.Key.KEY_1 : return 'KEY_1';
      case Event.Key.KEY_2 : return 'KEY_2';
      case Event.Key.KEY_3 : return 'KEY_3';
      case Event.Key.KEY_4 : return 'KEY_4';
      case Event.Key.KEY_5 : return 'KEY_5';
      case Event.Key.KEY_6 : return 'KEY_6';
      case Event.Key.KEY_7 : return 'KEY_7';
      case Event.Key.KEY_8 : return 'KEY_8';
      case Event.Key.KEY_9 : return 'KEY_9';
      case Event.Key.KEY_SEMICOLON : return 'KEY_SEMICOLON';
      case Event.Key.KEY_EQUALS : return 'KEY_EQUALS';
      case Event.Key.KEY_A : return 'KEY_A';
      case Event.Key.KEY_B : return 'KEY_B';
      case Event.Key.KEY_C : return 'KEY_C';
      case Event.Key.KEY_D : return 'KEY_D';
      case Event.Key.KEY_E : return 'KEY_E';
      case Event.Key.KEY_F : return 'KEY_F';
      case Event.Key.KEY_G : return 'KEY_G';
      case Event.Key.KEY_H : return 'KEY_H';
      case Event.Key.KEY_I : return 'KEY_I';
      case Event.Key.KEY_J : return 'KEY_J';
      case Event.Key.KEY_K : return 'KEY_K';
      case Event.Key.KEY_L : return 'KEY_L';
      case Event.Key.KEY_M : return 'KEY_M';
      case Event.Key.KEY_N : return 'KEY_N';
      case Event.Key.KEY_O : return 'KEY_O';
      case Event.Key.KEY_P : return 'KEY_P';
      case Event.Key.KEY_Q : return 'KEY_Q';
      case Event.Key.KEY_R : return 'KEY_R';
      case Event.Key.KEY_S : return 'KEY_S';
      case Event.Key.KEY_T : return 'KEY_T';
      case Event.Key.KEY_U : return 'KEY_U';
      case Event.Key.KEY_V : return 'KEY_V';
      case Event.Key.KEY_W : return 'KEY_W';
      case Event.Key.KEY_X : return 'KEY_X';
      case Event.Key.KEY_Y : return 'KEY_Y';
      case Event.Key.KEY_Z : return 'KEY_Z';
      case Event.Key.KEY_CONTEXT_MENU : return 'KEY_CONTEXT_MENU';
      case Event.Key.KEY_NUMPAD0 : return 'KEY_NUMPAD0';
      case Event.Key.KEY_NUMPAD1 : return 'KEY_NUMPAD1';
      case Event.Key.KEY_NUMPAD2 : return 'KEY_NUMPAD2';
      case Event.Key.KEY_NUMPAD3 : return 'KEY_NUMPAD3';
      case Event.Key.KEY_NUMPAD4 : return 'KEY_NUMPAD4';
      case Event.Key.KEY_NUMPAD5 : return 'KEY_NUMPAD5';
      case Event.Key.KEY_NUMPAD6 : return 'KEY_NUMPAD6';
      case Event.Key.KEY_NUMPAD7 : return 'KEY_NUMPAD7';
      case Event.Key.KEY_NUMPAD8 : return 'KEY_NUMPAD8';
      case Event.Key.KEY_NUMPAD9 : return 'KEY_NUMPAD9';
      case Event.Key.KEY_MULTIPLY : return 'KEY_MULTIPLY';
      case Event.Key.KEY_ADD : return 'KEY_ADD';
      case Event.Key.KEY_SEPARATOR : return 'KEY_SEPARATOR';
      case Event.Key.KEY_SUBTRACT : return 'KEY_SUBTRACT';
      case Event.Key.KEY_DECIMAL : return 'KEY_DECIMAL';
      case Event.Key.KEY_DIVIDE : return 'KEY_DIVIDE';
      case Event.Key.KEY_F1 : return 'KEY_F1';
      case Event.Key.KEY_F2 : return 'KEY_F2';
      case Event.Key.KEY_F3 : return 'KEY_F3';
      case Event.Key.KEY_F4 : return 'KEY_F4';
      case Event.Key.KEY_F5 : return 'KEY_F5';
      case Event.Key.KEY_F6 : return 'KEY_F6';
      case Event.Key.KEY_F7 : return 'KEY_F7';
      case Event.Key.KEY_F8 : return 'KEY_F8';
      case Event.Key.KEY_F9 : return 'KEY_F9';
      case Event.Key.KEY_F10 : return 'KEY_F10';
      case Event.Key.KEY_F11 : return 'KEY_F11';
      case Event.Key.KEY_F12 : return 'KEY_F12';
      case Event.Key.KEY_F13 : return 'KEY_F13';
      case Event.Key.KEY_F14 : return 'KEY_F14';
      case Event.Key.KEY_F15 : return 'KEY_F15';
      case Event.Key.KEY_F16 : return 'KEY_F16';
      case Event.Key.KEY_F17 : return 'KEY_F17';
      case Event.Key.KEY_F18 : return 'KEY_F18';
      case Event.Key.KEY_F19 : return 'KEY_F19';
      case Event.Key.KEY_F20 : return 'KEY_F20';
      case Event.Key.KEY_F21 : return 'KEY_F21';
      case Event.Key.KEY_F22 : return 'KEY_F22';
      case Event.Key.KEY_F23 : return 'KEY_F23';
      case Event.Key.KEY_F24 : return 'KEY_F24';
      case Event.Key.KEY_NUM_LOCK : return 'KEY_NUM_LOCK';
      case Event.Key.KEY_SCROLL_LOCK : return 'KEY_SCROLL_LOCK';
      case Event.Key.KEY_COMMA : return 'KEY_COMMA';
      case Event.Key.KEY_PERIOD : return 'KEY_PERIOD';
      case Event.Key.KEY_SLASH : return 'KEY_SLASH';
      case Event.Key.KEY_BACK_QUOTE : return 'KEY_BACK_QUOTE';
      case Event.Key.KEY_OPEN_BRACKET : return 'KEY_OPEN_BRACKET';
      case Event.Key.KEY_BACK_SLASH : return 'KEY_BACK_SLASH';
      case Event.Key.KEY_CLOSE_BRACKET : return 'KEY_CLOSE_BRACKET';
      case Event.Key.KEY_QUOTE : return 'KEY_QUOTE';
      case Event.Key.KEY_META : return 'KEY_META';
      case Event.Key.KEY_ALT_RIGHT : return 'KEY_ALT_RIGHT';
      default :
        return 'UNKNOWN';
    }
    //CUSTOM_STATIC_GET_KEY_NAME_END

    //GENERATED_STATIC_GET_KEY_NAME_AFTER_START
    //GENERATED_STATIC_GET_KEY_NAME_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_KEY_NAME_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_KEY_NAME_AFTER_END

  }
  /**
   * GetMouseButtonName()
   * - Returns the name of the mouse event
   * @param keyId
   * - No returns
   */
  static GetMouseButtonName(keyId) {

    //GENERATED_STATIC_GET_MOUSE_BUTTON_NAME_START
    //GENERATED_STATIC_GET_MOUSE_BUTTON_NAME_END

    //CUSTOM_STATIC_GET_MOUSE_BUTTON_NAME_START
    switch (keyId) {
      case Event.Mouse.LEFT_BUTTON :
        return 'LEFT_BUTTON';
      case Event.Mouse.MIDDLE_BUTTON :
        return 'MIDDLE_BUTTON';
      case Event.Mouse.RIGHT_BUTTON :
        return 'RIGHT_BUTTON';
      default :
        return 'UNKNOWN';
    }
    //CUSTOM_STATIC_GET_MOUSE_BUTTON_NAME_END

    //GENERATED_STATIC_GET_MOUSE_BUTTON_NAME_AFTER_START
    //GENERATED_STATIC_GET_MOUSE_BUTTON_NAME_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_MOUSE_BUTTON_NAME_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_MOUSE_BUTTON_NAME_AFTER_END

  }
  /**
   * SetPayloadDefaults()
   * - Set up the default payload options
   * @param eventId
   * @param {Object} [payload={}]
   * - No returns
   */
  static SetPayloadDefaults(eventId, payload = {}) {

    //GENERATED_STATIC_SET_PAYLOAD_DEFAULTS_START
    //GENERATED_STATIC_SET_PAYLOAD_DEFAULTS_END

    //CUSTOM_STATIC_SET_PAYLOAD_DEFAULTS_START
    payload.eventName = Event.GetEventName(eventId);

    if (typeof payload.results === 'undefined') {
      payload.results = [];
    }

    if (typeof payload.options === 'undefined') {
      payload.options = {};
    }

    if (typeof payload.object === 'undefined') {
      payload.object = {};
    }

    if (typeof payload.errorCb === 'undefined') {
      payload.errorCb = (error) => {
        Event.Emit(
          Event.STATUS_INFO,
          {
            code : -1,
            message : error.message
          }
        )
        console.error(error.stack);
        throw error;
      };
    }

    return payload;
    //CUSTOM_STATIC_SET_PAYLOAD_DEFAULTS_END

    //GENERATED_STATIC_SET_PAYLOAD_DEFAULTS_AFTER_START
    //GENERATED_STATIC_SET_PAYLOAD_DEFAULTS_AFTER_END

    //CUSTOM_STATIC_GENERATED_SET_PAYLOAD_DEFAULTS_AFTER_START
    //CUSTOM_STATIC_GENERATED_SET_PAYLOAD_DEFAULTS_AFTER_END

  }
  /**
   * GetEvents()
   * - Returns a list of all events
   * - No parameters
   * - No returns
   */
  static GetEvents() {

    //GENERATED_STATIC_GET_EVENTS_START
    return [
      Event.ACTIVATE_ENTITY,
      Event.ACTIVATE_ROTATION,
      Event.ADD_PROJECT,
      Event.AFTER_ASSIGN_PROPERTY,
      Event.APPLY_FORCE,
      Event.APPLY_TORQUE,
      Event.APP_CREATE_COMPONENT,
      Event.BEFORE_ASSIGN_PROPERTY,
      Event.CANVAS_RESIZE,
      Event.CHANGE_ROLE_USER,
      Event.CHANGE_ROLE_USER_AFTER,
      Event.CLONE_COMPONENT,
      Event.CLONE_COMPONENT_AFTER,
      Event.CLONE_ENTITY,
      Event.CLONE_ENTITY_AFTER,
      Event.CLONE_FILE,
      Event.CLONE_FILE_AFTER,
      Event.CLONE_GROUP,
      Event.CLONE_GROUP_AFTER,
      Event.CLONE_INSTANCE,
      Event.CLONE_PROJECT,
      Event.CLONE_PROJECT_AFTER,
      Event.CLONE_USER,
      Event.CLONE_USER_AFTER,
      Event.CODE_SYSTEM_STARTED,
      Event.CODE_SYSTEM_STOPPED,
      Event.COMPONENT_CREATED,
      Event.CREATE_AMBIENT_AFTER,
      Event.CREATE_BASIC_AFTER,
      Event.CREATE_EDITOR_CODE,
      Event.CREATE_GUI_OBJ,
      Event.CREATE_INSTANCE,
      Event.CREATE_PROJECT,
      Event.DELETE_EDITOR_CODE,
      Event.DELETE_GUI_OBJ,
      Event.DELETE_INSTANCE,
      Event.DEPLOY_PROJECT,
      Event.DE_ACTIVATE_ENTITY,
      Event.DE_ACTIVATE_ROTATION,
      Event.DE_REGISTER_CONTROL_KEYBOARD,
      Event.DE_REGISTER_CONTROL_MOUSE,
      Event.DE_REGISTER_CONTROL_ORBIT_CONTROLS,
      Event.DE_REGISTER_CONTROL_TOUCH,
      Event.DISPOSE_ANIMATION_ENTITY,
      Event.DISPOSE_AUDIO_COMPONENT,
      Event.DISPOSE_CODE_COMPONENT,
      Event.DISPOSE_COMPONENT,
      Event.DISPOSE_ENTITY,
      Event.DISPOSE_FILE,
      Event.DISPOSE_GRAPHICS_COMPONENT,
      Event.DISPOSE_GROUP,
      Event.DISPOSE_INPUT_COMPONENT,
      Event.DISPOSE_MATHS_COMPONENT,
      Event.DISPOSE_OBJECT,
      Event.DISPOSE_PHYSICS_COMPONENT,
      Event.DISPOSE_PROJECT,
      Event.DISPOSE_USER,
      Event.DOUBLE_CLICK,
      Event.DO_NOT_RENDER,
      Event.DUAL_SCREEN_MODE,
      Event.ENTITY_CREATED,
      Event.FORGOT_PASSWORD_SUCCESS,
      Event.GET_ACTIVE_GRAPHICS_RUNTIME,
      Event.GET_ALL_EVENTS,
      Event.GET_API_URL,
      Event.GET_COMPONENTS,
      Event.GET_CURRENT_CAMERA,
      Event.GET_CURRENT_CANVAS,
      Event.GET_CURRENT_GROUP,
      Event.GET_CURRENT_LIGHT,
      Event.GET_CURRENT_PROJECT,
      Event.GET_CURRENT_RENDERER,
      Event.GET_CURRENT_SCENE,
      Event.GET_CURRENT_USER,
      Event.GET_EDITOR_CONTROLS,
      Event.GET_ENTITIES,
      Event.GET_GROUPS,
      Event.GET_LIST,
      Event.GET_MESHES,
      Event.GET_MESH_INSTANCES,
      Event.GET_OBJECTS,
      Event.GET_OBJECTS_BY_CONSTRUCTOR,
      Event.GET_OBJECT_COUNT,
      Event.GET_OBJECT_WITH_ID,
      Event.GET_PROJECTS,
      Event.GET_R3_EVENT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR,
      Event.GET_R3_EVENT_OBJ_USER_CONSTRUCTOR,
      Event.GET_R3_GRAPH_CONSTRUCTOR,
      Event.GET_R3_MOCK_RAPIER_CONSTRUCTOR,
      Event.GET_R3_NODE_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_AUDIO_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_CODE_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_ENTITY_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_GUI_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_INPUT_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_MATHS_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_STORAGE_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR,
      Event.GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_AUDIO_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_CODE_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_ENTITY_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_GUI_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_INPUT_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_MATHS_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_STORAGE_CONSTRUCTOR,
      Event.GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR,
      Event.GET_R3_UTILS_CONSTRUCTOR,
      Event.GET_RUNTIME_AUDIO_THREE,
      Event.GET_RUNTIME_AUTHENTICATION_AXIOS,
      Event.GET_RUNTIME_CODE_ACE,
      Event.GET_RUNTIME_ENTITY_DEFAULT,
      Event.GET_RUNTIME_GRAPHICS_THREE,
      Event.GET_RUNTIME_GUI_TWEAK_PANE,
      Event.GET_RUNTIME_INPUT_CUSTOM,
      Event.GET_RUNTIME_INPUT_THREE,
      Event.GET_RUNTIME_MATHS_THREE,
      Event.GET_RUNTIME_PHYSICS_RAPIER,
      Event.GET_RUNTIME_STORAGE_AXIOS,
      Event.GET_RUNTIME_WEBSOCKET_SOCKET_I_O,
      Event.GET_SOURCE_API_URL,
      Event.GET_SOURCE_URLS,
      Event.GET_SOURCE_WEBSOCKET_URL,
      Event.GET_STORAGE_OBJECT_BY_ID,
      Event.GET_TARGET_API_URL,
      Event.GET_TARGET_URLS,
      Event.GET_TARGET_WEBSOCKET_URL,
      Event.GET_THREE_INSTANCE,
      Event.GET_USER,
      Event.GET_USERS,
      Event.GET_USERS_LIST,
      Event.GET_USER_AFTER,
      Event.GET_WEBSOCKET_URL,
      Event.GUI_SYSTEM_STARTED,
      Event.GUI_SYSTEM_STOPPED,
      Event.IMAGE_LOADED,
      Event.INITIALIZE_AUDIO_COMPONENT,
      Event.INITIALIZE_CODE_COMPONENT,
      Event.INITIALIZE_COMPONENT,
      Event.INITIALIZE_ENTITY,
      Event.INITIALIZE_FILE,
      Event.INITIALIZE_GRAPHICS_COMPONENT,
      Event.INITIALIZE_GROUP,
      Event.INITIALIZE_INPUT_COMPONENT,
      Event.INITIALIZE_MATHS_COMPONENT,
      Event.INITIALIZE_OBJ,
      Event.INITIALIZE_OBJECT,
      Event.INITIALIZE_PHYSICS_COMPONENT,
      Event.INITIALIZE_PROJECT,
      Event.INITIALIZE_USER,
      Event.INVITE_USER,
      Event.INVITE_USER_AFTER,
      Event.KEY_DOWN,
      Event.KEY_UP,
      Event.LOAD_COMPONENT,
      Event.LOAD_COMPONENT_AFTER,
      Event.LOAD_ENTITY,
      Event.LOAD_ENTITY_AFTER,
      Event.LOAD_FILE,
      Event.LOAD_FILE_AFTER,
      Event.LOAD_GROUP,
      Event.LOAD_GROUP_AFTER,
      Event.LOAD_PROJECT,
      Event.LOAD_PROJECT_AFTER,
      Event.LOAD_USER,
      Event.LOAD_USER_AFTER,
      Event.LOGIN_USER,
      Event.LOGIN_USER_AFTER,
      Event.LOGOUT_USER,
      Event.LOGOUT_USER_AFTER,
      Event.MENU_GROUP_ITEM_OPEN,
      Event.MENU_GROUP_OPEN,
      Event.MENU_OPEN,
      Event.MESH_DESELECTED,
      Event.MESH_SELECTED,
      Event.MOUSE_DOWN,
      Event.MOUSE_MOVE,
      Event.MOUSE_UP,
      Event.MOUSE_WHEEL,
      Event.PASSWORD_RESET_USER,
      Event.PASSWORD_RESET_USER_AFTER,
      Event.PAUSE_AUDIO,
      Event.PAUSE_POSITIONAL,
      Event.PHYSICS_SYSTEM_STARTED,
      Event.PLAY_AUDIO,
      Event.PLAY_POSITIONAL,
      Event.R3_STARTED,
      Event.RAPIER_RUNTIME_READY,
      Event.REGISTER_CONTROL_KEYBOARD,
      Event.REGISTER_CONTROL_MOUSE,
      Event.REGISTER_CONTROL_ORBIT_CONTROLS,
      Event.REGISTER_CONTROL_TOUCH,
      Event.REGISTER_USER,
      Event.REGISTER_USER_AFTER,
      Event.REMOVE_COMPONENT,
      Event.REMOVE_COMPONENT_AFTER,
      Event.REMOVE_ENTITY,
      Event.REMOVE_ENTITY_AFTER,
      Event.REMOVE_FILE,
      Event.REMOVE_FILE_AFTER,
      Event.REMOVE_GROUP,
      Event.REMOVE_GROUP_AFTER,
      Event.REMOVE_PROJECT,
      Event.REMOVE_PROJECT_AFTER,
      Event.REMOVE_USER,
      Event.REMOVE_USER_AFTER,
      Event.RENDER_AFTER,
      Event.RENDER_BEFORE,
      Event.RENDER_OK,
      Event.SAVE_COMPONENT,
      Event.SAVE_COMPONENT_AFTER,
      Event.SAVE_ENTITY,
      Event.SAVE_ENTITY_AFTER,
      Event.SAVE_FILE,
      Event.SAVE_FILE_AFTER,
      Event.SAVE_GROUP,
      Event.SAVE_GROUP_AFTER,
      Event.SAVE_PROJECT,
      Event.SAVE_PROJECT_AFTER,
      Event.SAVE_USER,
      Event.SAVE_USER_AFTER,
      Event.SEND_OBJ,
      Event.SEND_OBJ_AFTER,
      Event.SET_API_URL,
      Event.SET_CURRENT_GROUP,
      Event.SET_CURRENT_MODE,
      Event.SET_CURRENT_PROJECT,
      Event.SET_CURRENT_USER,
      Event.SET_SOURCE_API_URL,
      Event.SET_SOURCE_WEBSOCKET_URL,
      Event.SET_TARGET_API_URL,
      Event.SET_TARGET_WEBSOCKET_URL,
      Event.SET_WEBSOCKET_URL,
      Event.STATUS_INFO,
      Event.STOP_AUDIO,
      Event.STOP_POSITIONAL,
      Event.STOP_WEBSOCKET_SERVICE,
      Event.TOUCH_CANCEL,
      Event.TOUCH_END,
      Event.TOUCH_MOVE,
      Event.TOUCH_START,
      Event.UPDATE_CONTROL,
      Event.UPDATE_EDITOR_CODE,
      Event.UPDATE_GUI_OBJ,
      Event.UPDATE_INSTANCE,
      Event.WINDOW_RESIZE
    ];
    //GENERATED_STATIC_GET_EVENTS_END

    //CUSTOM_STATIC_GET_EVENTS_START
    //CUSTOM_STATIC_GET_EVENTS_END

    //GENERATED_STATIC_GET_EVENTS_AFTER_START
    //GENERATED_STATIC_GET_EVENTS_AFTER_END

    //CUSTOM_STATIC_GENERATED_GET_EVENTS_AFTER_START
    //CUSTOM_STATIC_GENERATED_GET_EVENTS_AFTER_END

  }
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
/**
 * @param Event.Subscriptions
 * - Holds a reference to each subscription for Events.*
 */
Event.Subscriptions = {};

/**
 * @param Event.Key
 * - No comment
 */
Event.Key = {};

/**
 * @param Event.Key.KEY_CANCEL
 * - No comment
 */
Event.Key.KEY_CANCEL = 3;

/**
 * @param Event.Key.KEY_HELP
 * - No comment
 */
Event.Key.KEY_HELP = 6;

/**
 * @param Event.Key.KEY_BACK_SPACE
 * - No comment
 */
Event.Key.KEY_BACK_SPACE = 8;

/**
 * @param Event.Key.KEY_TAB
 * - No comment
 */
Event.Key.KEY_TAB = 9;

/**
 * @param Event.Key.KEY_CLEAR
 * - No comment
 */
Event.Key.KEY_CLEAR = 12;

/**
 * @param Event.Key.KEY_RETURN
 * - No comment
 */
Event.Key.KEY_RETURN = 13;

/**
 * @param Event.Key.KEY_ENTER
 * - No comment
 */
Event.Key.KEY_ENTER = 14;

/**
 * @param Event.Key.KEY_SHIFT
 * - No comment
 */
Event.Key.KEY_SHIFT = 16;

/**
 * @param Event.Key.KEY_CONTROL
 * - No comment
 */
Event.Key.KEY_CONTROL = 17;

/**
 * @param Event.Key.KEY_ALT
 * - No comment
 */
Event.Key.KEY_ALT = 18;

/**
 * @param Event.Key.KEY_PAUSE
 * - No comment
 */
Event.Key.KEY_PAUSE = 19;

/**
 * @param Event.Key.KEY_CAPS_LOCK
 * - No comment
 */
Event.Key.KEY_CAPS_LOCK = 20;

/**
 * @param Event.Key.KEY_ESCAPE
 * - No comment
 */
Event.Key.KEY_ESCAPE = 27;

/**
 * @param Event.Key.KEY_SPACE
 * - No comment
 */
Event.Key.KEY_SPACE = 32;

/**
 * @param Event.Key.KEY_PAGE_UP
 * - No comment
 */
Event.Key.KEY_PAGE_UP = 33;

/**
 * @param Event.Key.KEY_PAGE_DOWN
 * - No comment
 */
Event.Key.KEY_PAGE_DOWN = 34;

/**
 * @param Event.Key.KEY_END
 * - No comment
 */
Event.Key.KEY_END = 35;

/**
 * @param Event.Key.KEY_HOME
 * - No comment
 */
Event.Key.KEY_HOME = 36;

/**
 * @param Event.Key.KEY_LEFT
 * - No comment
 */
Event.Key.KEY_LEFT = 37;

/**
 * @param Event.Key.KEY_UP
 * - No comment
 */
Event.Key.KEY_UP = 38;

/**
 * @param Event.Key.KEY_RIGHT
 * - No comment
 */
Event.Key.KEY_RIGHT = 39;

/**
 * @param Event.Key.KEY_DOWN
 * - No comment
 */
Event.Key.KEY_DOWN = 40;

/**
 * @param Event.Key.KEY_PRINT_SCREEN
 * - No comment
 */
Event.Key.KEY_PRINT_SCREEN = 44;

/**
 * @param Event.Key.KEY_INSERT
 * - No comment
 */
Event.Key.KEY_INSERT = 45;

/**
 * @param Event.Key.KEY_DELETE
 * - No comment
 */
Event.Key.KEY_DELETE = 46;

/**
 * @param Event.Key.KEY_0
 * - No comment
 */
Event.Key.KEY_0 = 48;

/**
 * @param Event.Key.KEY_1
 * - No comment
 */
Event.Key.KEY_1 = 49;

/**
 * @param Event.Key.KEY_2
 * - No comment
 */
Event.Key.KEY_2 = 50;

/**
 * @param Event.Key.KEY_3
 * - No comment
 */
Event.Key.KEY_3 = 51;

/**
 * @param Event.Key.KEY_4
 * - No comment
 */
Event.Key.KEY_4 = 52;

/**
 * @param Event.Key.KEY_5
 * - No comment
 */
Event.Key.KEY_5 = 53;

/**
 * @param Event.Key.KEY_6
 * - No comment
 */
Event.Key.KEY_6 = 54;

/**
 * @param Event.Key.KEY_7
 * - No comment
 */
Event.Key.KEY_7 = 55;

/**
 * @param Event.Key.KEY_8
 * - No comment
 */
Event.Key.KEY_8 = 56;

/**
 * @param Event.Key.KEY_9
 * - No comment
 */
Event.Key.KEY_9 = 57;

/**
 * @param Event.Key.KEY_SEMICOLON
 * - No comment
 */
Event.Key.KEY_SEMICOLON = 59;

/**
 * @param Event.Key.KEY_EQUALS
 * - No comment
 */
Event.Key.KEY_EQUALS = 61;

/**
 * @param Event.Key.KEY_A
 * - No comment
 */
Event.Key.KEY_A = 65;

/**
 * @param Event.Key.KEY_B
 * - No comment
 */
Event.Key.KEY_B = 66;

/**
 * @param Event.Key.KEY_C
 * - No comment
 */
Event.Key.KEY_C = 67;

/**
 * @param Event.Key.KEY_D
 * - No comment
 */
Event.Key.KEY_D = 68;

/**
 * @param Event.Key.KEY_E
 * - No comment
 */
Event.Key.KEY_E = 69;

/**
 * @param Event.Key.KEY_F
 * - No comment
 */
Event.Key.KEY_F = 70;

/**
 * @param Event.Key.KEY_G
 * - No comment
 */
Event.Key.KEY_G = 71;

/**
 * @param Event.Key.KEY_H
 * - No comment
 */
Event.Key.KEY_H = 72;

/**
 * @param Event.Key.KEY_I
 * - No comment
 */
Event.Key.KEY_I = 73;

/**
 * @param Event.Key.KEY_J
 * - No comment
 */
Event.Key.KEY_J = 74;

/**
 * @param Event.Key.KEY_K
 * - No comment
 */
Event.Key.KEY_K = 75;

/**
 * @param Event.Key.KEY_L
 * - No comment
 */
Event.Key.KEY_L = 76;

/**
 * @param Event.Key.KEY_M
 * - No comment
 */
Event.Key.KEY_M = 77;

/**
 * @param Event.Key.KEY_N
 * - No comment
 */
Event.Key.KEY_N = 78;

/**
 * @param Event.Key.KEY_O
 * - No comment
 */
Event.Key.KEY_O = 79;

/**
 * @param Event.Key.KEY_P
 * - No comment
 */
Event.Key.KEY_P = 80;

/**
 * @param Event.Key.KEY_Q
 * - No comment
 */
Event.Key.KEY_Q = 81;

/**
 * @param Event.Key.KEY_R
 * - No comment
 */
Event.Key.KEY_R = 82;

/**
 * @param Event.Key.KEY_S
 * - No comment
 */
Event.Key.KEY_S = 83;

/**
 * @param Event.Key.KEY_T
 * - No comment
 */
Event.Key.KEY_T = 84;

/**
 * @param Event.Key.KEY_U
 * - No comment
 */
Event.Key.KEY_U = 85;

/**
 * @param Event.Key.KEY_V
 * - No comment
 */
Event.Key.KEY_V = 86;

/**
 * @param Event.Key.KEY_W
 * - No comment
 */
Event.Key.KEY_W = 87;

/**
 * @param Event.Key.KEY_X
 * - No comment
 */
Event.Key.KEY_X = 88;

/**
 * @param Event.Key.KEY_Y
 * - No comment
 */
Event.Key.KEY_Y = 89;

/**
 * @param Event.Key.KEY_Z
 * - No comment
 */
Event.Key.KEY_Z = 90;

/**
 * @param Event.Key.KEY_CONTEXT_MENU
 * - No comment
 */
Event.Key.KEY_CONTEXT_MENU = 93;

/**
 * @param Event.Key.KEY_NUMPAD0
 * - No comment
 */
Event.Key.KEY_NUMPAD0 = 96;

/**
 * @param Event.Key.KEY_NUMPAD1
 * - No comment
 */
Event.Key.KEY_NUMPAD1 = 97;

/**
 * @param Event.Key.KEY_NUMPAD2
 * - No comment
 */
Event.Key.KEY_NUMPAD2 = 98;

/**
 * @param Event.Key.KEY_NUMPAD3
 * - No comment
 */
Event.Key.KEY_NUMPAD3 = 99;

/**
 * @param Event.Key.KEY_NUMPAD4
 * - No comment
 */
Event.Key.KEY_NUMPAD4 = 100;

/**
 * @param Event.Key.KEY_NUMPAD5
 * - No comment
 */
Event.Key.KEY_NUMPAD5 = 101;

/**
 * @param Event.Key.KEY_NUMPAD6
 * - No comment
 */
Event.Key.KEY_NUMPAD6 = 102;

/**
 * @param Event.Key.KEY_NUMPAD7
 * - No comment
 */
Event.Key.KEY_NUMPAD7 = 103;

/**
 * @param Event.Key.KEY_NUMPAD8
 * - No comment
 */
Event.Key.KEY_NUMPAD8 = 104;

/**
 * @param Event.Key.KEY_NUMPAD9
 * - No comment
 */
Event.Key.KEY_NUMPAD9 = 105;

/**
 * @param Event.Key.KEY_MULTIPLY
 * - No comment
 */
Event.Key.KEY_MULTIPLY = 106;

/**
 * @param Event.Key.KEY_ADD
 * - No comment
 */
Event.Key.KEY_ADD = 107;

/**
 * @param Event.Key.KEY_SEPARATOR
 * - No comment
 */
Event.Key.KEY_SEPARATOR = 108;

/**
 * @param Event.Key.KEY_SUBTRACT
 * - No comment
 */
Event.Key.KEY_SUBTRACT = 109;

/**
 * @param Event.Key.KEY_DECIMAL
 * - No comment
 */
Event.Key.KEY_DECIMAL = 110;

/**
 * @param Event.Key.KEY_DIVIDE
 * - No comment
 */
Event.Key.KEY_DIVIDE = 111;

/**
 * @param Event.Key.KEY_F1
 * - No comment
 */
Event.Key.KEY_F1 = 112;

/**
 * @param Event.Key.KEY_F2
 * - No comment
 */
Event.Key.KEY_F2 = 113;

/**
 * @param Event.Key.KEY_F3
 * - No comment
 */
Event.Key.KEY_F3 = 114;

/**
 * @param Event.Key.KEY_F4
 * - No comment
 */
Event.Key.KEY_F4 = 115;

/**
 * @param Event.Key.KEY_F5
 * - No comment
 */
Event.Key.KEY_F5 = 116;

/**
 * @param Event.Key.KEY_F6
 * - No comment
 */
Event.Key.KEY_F6 = 117;

/**
 * @param Event.Key.KEY_F7
 * - No comment
 */
Event.Key.KEY_F7 = 118;

/**
 * @param Event.Key.KEY_F8
 * - No comment
 */
Event.Key.KEY_F8 = 119;

/**
 * @param Event.Key.KEY_F9
 * - No comment
 */
Event.Key.KEY_F9 = 120;

/**
 * @param Event.Key.KEY_F10
 * - No comment
 */
Event.Key.KEY_F10 = 121;

/**
 * @param Event.Key.KEY_F11
 * - No comment
 */
Event.Key.KEY_F11 = 122;

/**
 * @param Event.Key.KEY_F12
 * - No comment
 */
Event.Key.KEY_F12 = 123;

/**
 * @param Event.Key.KEY_F13
 * - No comment
 */
Event.Key.KEY_F13 = 124;

/**
 * @param Event.Key.KEY_F14
 * - No comment
 */
Event.Key.KEY_F14 = 125;

/**
 * @param Event.Key.KEY_F15
 * - No comment
 */
Event.Key.KEY_F15 = 126;

/**
 * @param Event.Key.KEY_F16
 * - No comment
 */
Event.Key.KEY_F16 = 127;

/**
 * @param Event.Key.KEY_F17
 * - No comment
 */
Event.Key.KEY_F17 = 128;

/**
 * @param Event.Key.KEY_F18
 * - No comment
 */
Event.Key.KEY_F18 = 129;

/**
 * @param Event.Key.KEY_F19
 * - No comment
 */
Event.Key.KEY_F19 = 130;

/**
 * @param Event.Key.KEY_F20
 * - No comment
 */
Event.Key.KEY_F20 = 131;

/**
 * @param Event.Key.KEY_F21
 * - No comment
 */
Event.Key.KEY_F21 = 132;

/**
 * @param Event.Key.KEY_F22
 * - No comment
 */
Event.Key.KEY_F22 = 133;

/**
 * @param Event.Key.KEY_F23
 * - No comment
 */
Event.Key.KEY_F23 = 134;

/**
 * @param Event.Key.KEY_F24
 * - No comment
 */
Event.Key.KEY_F24 = 135;

/**
 * @param Event.Key.KEY_NUM_LOCK
 * - No comment
 */
Event.Key.KEY_NUM_LOCK = 144;

/**
 * @param Event.Key.KEY_SCROLL_LOCK
 * - No comment
 */
Event.Key.KEY_SCROLL_LOCK = 145;

/**
 * @param Event.Key.KEY_COMMA
 * - No comment
 */
Event.Key.KEY_COMMA = 188;

/**
 * @param Event.Key.KEY_PERIOD
 * - No comment
 */
Event.Key.KEY_PERIOD = 190;

/**
 * @param Event.Key.KEY_SLASH
 * - No comment
 */
Event.Key.KEY_SLASH = 191;

/**
 * @param Event.Key.KEY_BACK_QUOTE
 * - No comment
 */
Event.Key.KEY_BACK_QUOTE = 192;

/**
 * @param Event.Key.KEY_OPEN_BRACKET
 * - No comment
 */
Event.Key.KEY_OPEN_BRACKET = 219;

/**
 * @param Event.Key.KEY_BACK_SLASH
 * - No comment
 */
Event.Key.KEY_BACK_SLASH = 220;

/**
 * @param Event.Key.KEY_CLOSE_BRACKET
 * - No comment
 */
Event.Key.KEY_CLOSE_BRACKET = 221;

/**
 * @param Event.Key.KEY_QUOTE
 * - No comment
 */
Event.Key.KEY_QUOTE = 222;

/**
 * @param Event.Key.KEY_META
 * - No comment
 */
Event.Key.KEY_META = 224;

/**
 * @param Event.Key.KEY_ALT_RIGHT
 * - No comment
 */
Event.Key.KEY_ALT_RIGHT = 225;

/**
 * @param Event.Mouse
 * - No comment
 */
Event.Mouse = {};

/**
 * @param Event.Mouse.LEFT_BUTTON
 * - No comment
 */
Event.Mouse.LEFT_BUTTON = 1;

/**
 * @param Event.Mouse.MIDDLE_BUTTON
 * - No comment
 */
Event.Mouse.MIDDLE_BUTTON = 2;

/**
 * @param Event.Mouse.RIGHT_BUTTON
 * - No comment
 */
Event.Mouse.RIGHT_BUTTON = 3;
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
Event.ACTIVATE_ENTITY = 160519483;
Event.ACTIVATE_ROTATION = 1702201046;
Event.ADD_PROJECT = 1708768199;
Event.AFTER_ASSIGN_PROPERTY = 1468311086;
Event.APPLY_FORCE = 143958310;
Event.APPLY_TORQUE = 568562447;
Event.APP_CREATE_COMPONENT = 1227260620;
Event.BEFORE_ASSIGN_PROPERTY = 261607335;
Event.CANVAS_RESIZE = 1788170503;
Event.CHANGE_ROLE_USER = 1583909145;
Event.CHANGE_ROLE_USER_AFTER = 160759018;
Event.CLONE_COMPONENT = 2051641575;
Event.CLONE_COMPONENT_AFTER = 1032947612;
Event.CLONE_ENTITY = 625606631;
Event.CLONE_ENTITY_AFTER = 1250114070;
Event.CLONE_FILE = 1457607886;
Event.CLONE_FILE_AFTER = 1627689361;
Event.CLONE_GROUP = 2059990889;
Event.CLONE_GROUP_AFTER = 1070369434;
Event.CLONE_INSTANCE = 1550289077;
Event.CLONE_PROJECT = 931416701;
Event.CLONE_PROJECT_AFTER = 1077823488;
Event.CLONE_USER = 1457151615;
Event.CLONE_USER_AFTER = 1837224578;
Event.CODE_SYSTEM_STARTED = 1986056559;
Event.CODE_SYSTEM_STOPPED = 1998922427;
Event.COMPONENT_CREATED = 1992878834;
Event.CREATE_AMBIENT_AFTER = 2121400794;
Event.CREATE_BASIC_AFTER = 1164761980;
Event.CREATE_EDITOR_CODE = 214370640;
Event.CREATE_GUI_OBJ = 492962148;
Event.CREATE_INSTANCE = 54253308;
Event.CREATE_PROJECT = 190420822;
Event.DELETE_EDITOR_CODE = 831868127;
Event.DELETE_GUI_OBJ = 1812383507;
Event.DELETE_INSTANCE = 2101864139;
Event.DEPLOY_PROJECT = 1144790421;
Event.DE_ACTIVATE_ENTITY = 1594449125;
Event.DE_ACTIVATE_ROTATION = 1024084992;
Event.DE_REGISTER_CONTROL_KEYBOARD = 1079664773;
Event.DE_REGISTER_CONTROL_MOUSE = 910634639;
Event.DE_REGISTER_CONTROL_ORBIT_CONTROLS = 37639233;
Event.DE_REGISTER_CONTROL_TOUCH = 904170485;
Event.DISPOSE_ANIMATION_ENTITY = 183496622;
Event.DISPOSE_AUDIO_COMPONENT = 821635552;
Event.DISPOSE_CODE_COMPONENT = 440892097;
Event.DISPOSE_COMPONENT = 1341267927;
Event.DISPOSE_ENTITY = 1706805271;
Event.DISPOSE_FILE = 1303225680;
Event.DISPOSE_GRAPHICS_COMPONENT = 1207725475;
Event.DISPOSE_GROUP = 1744095317;
Event.DISPOSE_INPUT_COMPONENT = 1169392852;
Event.DISPOSE_MATHS_COMPONENT = 167775691;
Event.DISPOSE_OBJECT = 1981712243;
Event.DISPOSE_PHYSICS_COMPONENT = 628491247;
Event.DISPOSE_PROJECT = 1641091771;
Event.DISPOSE_USER = 1302769409;
Event.DOUBLE_CLICK = 186564206;
Event.DO_NOT_RENDER = 1991205790;
Event.DUAL_SCREEN_MODE = 1501940921;
Event.ENTITY_CREATED = 33879968;
Event.FORGOT_PASSWORD_SUCCESS = 184584295;
Event.GET_ACTIVE_GRAPHICS_RUNTIME = 1382838624;
Event.GET_ALL_EVENTS = 2146424780;
Event.GET_API_URL = 23830515;
Event.GET_COMPONENTS = 2072787533;
Event.GET_CURRENT_CAMERA = 856120088;
Event.GET_CURRENT_CANVAS = 856074469;
Event.GET_CURRENT_GROUP = 115133500;
Event.GET_CURRENT_LIGHT = 119474899;
Event.GET_CURRENT_PROJECT = 1628593770;
Event.GET_CURRENT_RENDERER = 1557051846;
Event.GET_CURRENT_SCENE = 125759049;
Event.GET_CURRENT_USER = 1381341618;
Event.GET_EDITOR_CONTROLS = 1755799755;
Event.GET_ENTITIES = 457085342;
Event.GET_GROUPS = 104072463;
Event.GET_LIST = 799966981;
Event.GET_MESHES = 55803000;
Event.GET_MESH_INSTANCES = 189076137;
Event.GET_OBJECTS = 884357257;
Event.GET_OBJECTS_BY_CONSTRUCTOR = 745016902;
Event.GET_OBJECT_COUNT = 1377487692;
Event.GET_OBJECT_WITH_ID = 1313085009;
Event.GET_PROJECTS = 1560502871;
Event.GET_R3_EVENT_CONSTRUCTOR = 322953556;
Event.GET_R3_EVENT_OBJ_COMPONENT_AUDIO_CONSTRUCTOR = 1623470335;
Event.GET_R3_EVENT_OBJ_COMPONENT_AUDIO_POSITIONAL_CONSTRUCTOR = 800021080;
Event.GET_R3_EVENT_OBJ_COMPONENT_CODE_CONSTRUCTOR = 771511208;
Event.GET_R3_EVENT_OBJ_COMPONENT_CONSTRUCTOR = 432860662;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CONSTRUCTOR = 355994148;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_CUBE_CONSTRUCTOR = 455122630;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_ORTHOGRAPHIC_CONSTRUCTOR = 257238081;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CAMERA_PERSPECTIVE_CONSTRUCTOR = 1395667583;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CANVAS_CONSTRUCTOR = 1277687849;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_CONSTRUCTOR = 1051051018;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_FOG_CONSTRUCTOR = 1798324181;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BOX_CONSTRUCTOR = 1298402013;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_BUFFER_CONSTRUCTOR = 1408643228;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_CONSTRUCTOR = 536594703;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_PLANE_CONSTRUCTOR = 718868526;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_SPHERE_CONSTRUCTOR = 347293839;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_GEOMETRY_TORUS_CONSTRUCTOR = 1107496583;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_IMAGE_CONSTRUCTOR = 993183218;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_AMBIENT_CONSTRUCTOR = 536639206;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_CONSTRUCTOR = 1650945747;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_DIRECTIONAL_CONSTRUCTOR = 1675030456;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_POINT_CONSTRUCTOR = 762714782;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_LIGHT_SPOT_CONSTRUCTOR = 102596266;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_BASIC_CONSTRUCTOR = 654334069;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_CONSTRUCTOR = 464966618;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_GROUP_CONSTRUCTOR = 10520902;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_LAMBERT_CONSTRUCTOR = 1958395356;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_PHONG_CONSTRUCTOR = 2000350903;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_POINTS_CONSTRUCTOR = 1897434958;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_STANDARD_CONSTRUCTOR = 743406220;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MATERIAL_TOON_CONSTRUCTOR = 783801367;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_MESH_CONSTRUCTOR = 1253163540;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RAYCASTER_CONSTRUCTOR = 510720749;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CONSTRUCTOR = 2015461950;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_CUBE_CONSTRUCTOR = 1423458840;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_GL_CONSTRUCTOR = 957912936;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_RENDERER_TARGET_CONSTRUCTOR = 180279108;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SCENE_CONSTRUCTOR = 648082339;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADER_CONSTRUCTOR = 79273700;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_CONSTRUCTOR = 2146607007;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_DIRECTIONAL_CONSTRUCTOR = 270212310;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_POINT_CONSTRUCTOR = 1978397808;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SHADOW_SPOT_CONSTRUCTOR = 845457272;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SKELETON_CONSTRUCTOR = 1442761140;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_SPRITE_CONSTRUCTOR = 1700321732;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CANVAS_CONSTRUCTOR = 10651237;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CONSTRUCTOR = 1197244750;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_CUBE_CONSTRUCTOR = 955395336;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_IMAGE_CONSTRUCTOR = 202771374;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_TEXTURE_VIDEO_CONSTRUCTOR = 962840270;
Event.GET_R3_EVENT_OBJ_COMPONENT_GRAPHICS_VIDEO_CONSTRUCTOR = 1753252114;
Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_CONSTRUCTOR = 1858813173;
Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_KEYBOARD_CONSTRUCTOR = 2138228935;
Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_MOUSE_CONSTRUCTOR = 1567768581;
Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_ORBIT_CONTROLS_CONSTRUCTOR = 821148085;
Event.GET_R3_EVENT_OBJ_COMPONENT_INPUT_TOUCH_CONSTRUCTOR = 2065740907;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_COLOR_CONSTRUCTOR = 572390886;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_CONSTRUCTOR = 1035064854;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_FACE_CONSTRUCTOR = 596537284;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX3_CONSTRUCTOR = 960944617;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_MATRIX4_CONSTRUCTOR = 667541610;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_PLANE_CONSTRUCTOR = 1993998227;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_QUATERNION_CONSTRUCTOR = 1844976765;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_RAY_CONSTRUCTOR = 21771105;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_SPHERE_CONSTRUCTOR = 527021228;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_U_V_CONSTRUCTOR = 1152188605;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR2_CONSTRUCTOR = 808022746;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR3_CONSTRUCTOR = 1101425753;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VECTOR4_CONSTRUCTOR = 1394828760;
Event.GET_R3_EVENT_OBJ_COMPONENT_MATHS_VERTEX_CONSTRUCTOR = 1154937859;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_BALL_CONSTRUCTOR = 1879260317;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CAPSULE_CONSTRUCTOR = 797048517;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CONSTRUCTOR = 598661879;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_CUBOID_CONSTRUCTOR = 1752410872;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_HEIGHT_FIELD_CONSTRUCTOR = 1747527968;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_COLLIDER_TRI_MESH_CONSTRUCTOR = 2030099905;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_CONSTRUCTOR = 1856352242;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_GRAVITY_CONSTRUCTOR = 646275231;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_CONSTRUCTOR = 216341917;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_FIXED_CONSTRUCTOR = 72061198;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_PRISMATIC_CONSTRUCTOR = 1481318988;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_REVOLUTE_CONSTRUCTOR = 1940647582;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_JOINT_SPHERICAL_CONSTRUCTOR = 489208539;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_CONSTRUCTOR = 1464198489;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_DYNAMIC_CONSTRUCTOR = 1226241479;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_RIGID_BODY_FIXED_CONSTRUCTOR = 818447954;
Event.GET_R3_EVENT_OBJ_COMPONENT_PHYSICS_WORLD_CONSTRUCTOR = 1571806043;
Event.GET_R3_EVENT_OBJ_CONSTRUCTOR = 656107284;
Event.GET_R3_EVENT_OBJ_ENTITY_ANIMATION_CONSTRUCTOR = 2099254609;
Event.GET_R3_EVENT_OBJ_ENTITY_ANIMATION_ROTATION_CONSTRUCTOR = 708872450;
Event.GET_R3_EVENT_OBJ_ENTITY_CONSTRUCTOR = 1629634604;
Event.GET_R3_EVENT_OBJ_FILE_CONSTRUCTOR = 1451455547;
Event.GET_R3_EVENT_OBJ_GROUP_CONSTRUCTOR = 536032884;
Event.GET_R3_EVENT_OBJ_PROJECT_CONSTRUCTOR = 153096538;
Event.GET_R3_EVENT_OBJ_USER_CONSTRUCTOR = 1395753876;
Event.GET_R3_GRAPH_CONSTRUCTOR = 1551221256;
Event.GET_R3_MOCK_RAPIER_CONSTRUCTOR = 1812520188;
Event.GET_R3_NODE_CONSTRUCTOR = 1494680958;
Event.GET_R3_RUNTIME_AUDIO_CONSTRUCTOR = 2139576119;
Event.GET_R3_RUNTIME_AUDIO_THREE_CONSTRUCTOR = 1445152696;
Event.GET_R3_RUNTIME_AUTHENTICATION_AXIOS_CONSTRUCTOR = 386694030;
Event.GET_R3_RUNTIME_AUTHENTICATION_CONSTRUCTOR = 1717925787;
Event.GET_R3_RUNTIME_CODE_ACE_CONSTRUCTOR = 580226228;
Event.GET_R3_RUNTIME_CODE_CONSTRUCTOR = 1012955536;
Event.GET_R3_RUNTIME_CONSTRUCTOR = 1164501458;
Event.GET_R3_RUNTIME_ENTITY_CONSTRUCTOR = 1091258502;
Event.GET_R3_RUNTIME_ENTITY_DEFAULT_CONSTRUCTOR = 48860312;
Event.GET_R3_RUNTIME_GRAPHICS_CONSTRUCTOR = 375542482;
Event.GET_R3_RUNTIME_GRAPHICS_THREE_CONSTRUCTOR = 1526996205;
Event.GET_R3_RUNTIME_GUI_CONSTRUCTOR = 611006290;
Event.GET_R3_RUNTIME_GUI_TWEAK_PANE_CONSTRUCTOR = 1708898750;
Event.GET_R3_RUNTIME_INPUT_CONSTRUCTOR = 1342707389;
Event.GET_R3_RUNTIME_INPUT_CUSTOM_CONSTRUCTOR = 46037047;
Event.GET_R3_RUNTIME_INPUT_THREE_CONSTRUCTOR = 992551108;
Event.GET_R3_RUNTIME_MATHS_CONSTRUCTOR = 518959070;
Event.GET_R3_RUNTIME_MATHS_THREE_CONSTRUCTOR = 1168876643;
Event.GET_R3_RUNTIME_PHYSICS_CONSTRUCTOR = 200067142;
Event.GET_R3_RUNTIME_PHYSICS_RAPIER_CONSTRUCTOR = 570736080;
Event.GET_R3_RUNTIME_STORAGE_AXIOS_CONSTRUCTOR = 1076975483;
Event.GET_R3_RUNTIME_STORAGE_CONSTRUCTOR = 958785006;
Event.GET_R3_RUNTIME_WEBSOCKET_CONSTRUCTOR = 1972245306;
Event.GET_R3_RUNTIME_WEBSOCKET_SOCKET_I_O_CONSTRUCTOR = 1545472584;
Event.GET_R3_SYSTEM_AUDIO_CONSTRUCTOR = 1603410398;
Event.GET_R3_SYSTEM_AUTHENTICATION_CONSTRUCTOR = 678613406;
Event.GET_R3_SYSTEM_CODE_CONSTRUCTOR = 493769449;
Event.GET_R3_SYSTEM_CONSTRUCTOR = 1611224555;
Event.GET_R3_SYSTEM_ENTITY_CONSTRUCTOR = 532526669;
Event.GET_R3_SYSTEM_GRAPHICS_CONSTRUCTOR = 445921995;
Event.GET_R3_SYSTEM_GUI_CONSTRUCTOR = 171673671;
Event.GET_R3_SYSTEM_INPUT_CONSTRUCTOR = 1878873110;
Event.GET_R3_SYSTEM_MATHS_CONSTRUCTOR = 1055124791;
Event.GET_R3_SYSTEM_PHYSICS_CONSTRUCTOR = 340884781;
Event.GET_R3_SYSTEM_STORAGE_CONSTRUCTOR = 817967367;
Event.GET_R3_SYSTEM_WEBSOCKET_CONSTRUCTOR = 209519597;
Event.GET_R3_UTILS_CONSTRUCTOR = 266933483;
Event.GET_RUNTIME_AUDIO_THREE = 60996687;
Event.GET_RUNTIME_AUTHENTICATION_AXIOS = 2125074259;
Event.GET_RUNTIME_CODE_ACE = 223593621;
Event.GET_RUNTIME_ENTITY_DEFAULT = 1057737801;
Event.GET_RUNTIME_GRAPHICS_THREE = 1865405774;
Event.GET_RUNTIME_GUI_TWEAK_PANE = 1226074589;
Event.GET_RUNTIME_INPUT_CUSTOM = 207182122;
Event.GET_RUNTIME_INPUT_THREE = 809289563;
Event.GET_RUNTIME_MATHS_THREE = 1856536954;
Event.GET_RUNTIME_PHYSICS_RAPIER = 494374223;
Event.GET_RUNTIME_STORAGE_AXIOS = 1208579694;
Event.GET_RUNTIME_WEBSOCKET_SOCKET_I_O = 1270752809;
Event.GET_SOURCE_API_URL = 1506319997;
Event.GET_SOURCE_URLS = 1098978187;
Event.GET_SOURCE_WEBSOCKET_URL = 1979624976;
Event.GET_STORAGE_OBJECT_BY_ID = 1513340612;
Event.GET_TARGET_API_URL = 1734896391;
Event.GET_TARGET_URLS = 900955179;
Event.GET_TARGET_WEBSOCKET_URL = 1951306982;
Event.GET_THREE_INSTANCE = 458692179;
Event.GET_USER = 799689688;
Event.GET_USERS = 979423531;
Event.GET_USERS_LIST = 892900146;
Event.GET_USER_AFTER = 1208575717;
Event.GET_WEBSOCKET_URL = 541311226;
Event.GUI_SYSTEM_STARTED = 1269280279;
Event.GUI_SYSTEM_STOPPED = 1256414411;
Event.IMAGE_LOADED = 1071163005;
Event.INITIALIZE_AUDIO_COMPONENT = 1718955815;
Event.INITIALIZE_CODE_COMPONENT = 1354130650;
Event.INITIALIZE_COMPONENT = 885159394;
Event.INITIALIZE_ENTITY = 1959217922;
Event.INITIALIZE_FILE = 136533335;
Event.INITIALIZE_GRAPHICS_COMPONENT = 2051449660;
Event.INITIALIZE_GROUP = 61238812;
Event.INITIALIZE_INPUT_COMPONENT = 1371198515;
Event.INITIALIZE_MATHS_COMPONENT = 1586600238;
Event.INITIALIZE_OBJ = 965418596;
Event.INITIALIZE_OBJECT = 1684310950;
Event.INITIALIZE_PHYSICS_COMPONENT = 1699596490;
Event.INITIALIZE_PROJECT = 676306238;
Event.INITIALIZE_USER = 136989606;
Event.INVITE_USER = 710653491;
Event.INVITE_USER_AFTER = 1279759158;
Event.KEY_DOWN = 2019220374;
Event.KEY_UP = 502925681;
Event.LOAD_COMPONENT = 264073544;
Event.LOAD_COMPONENT_AFTER = 1762912907;
Event.LOAD_ENTITY = 1668465688;
Event.LOAD_ENTITY_AFTER = 454287707;
Event.LOAD_FILE = 333483713;
Event.LOAD_FILE_AFTER = 400414018;
Event.LOAD_GROUP = 1749255610;
Event.LOAD_GROUP_AFTER = 1679539465;
Event.LOAD_PROJECT = 1099690900;
Event.LOAD_PROJECT_AFTER = 1920296273;
Event.LOAD_USER = 333939984;
Event.LOAD_USER_AFTER = 609949235;
Event.LOGIN_USER = 1000671499;
Event.LOGIN_USER_AFTER = 468884466;
Event.LOGOUT_USER = 1772139540;
Event.LOGOUT_USER_AFTER = 259318871;
Event.MENU_GROUP_ITEM_OPEN = 883930666;
Event.MENU_GROUP_OPEN = 1483624554;
Event.MENU_OPEN = 1712378550;
Event.MESH_DESELECTED = 794867654;
Event.MESH_SELECTED = 1565278265;
Event.MOUSE_DOWN = 1754253328;
Event.MOUSE_MOVE = 1754521407;
Event.MOUSE_UP = 1369422217;
Event.MOUSE_WHEEL = 1435400819;
Event.PASSWORD_RESET_USER = 1385754507;
Event.PASSWORD_RESET_USER_AFTER = 1354583048;
Event.PAUSE_AUDIO = 199970457;
Event.PAUSE_POSITIONAL = 945711599;
Event.PHYSICS_SYSTEM_STARTED = 111714653;
Event.PLAY_AUDIO = 752124193;
Event.PLAY_POSITIONAL = 395140725;
Event.R3_STARTED = 491190327;
Event.RAPIER_RUNTIME_READY = 447208838;
Event.REGISTER_CONTROL_KEYBOARD = 1083464623;
Event.REGISTER_CONTROL_MOUSE = 1838224293;
Event.REGISTER_CONTROL_ORBIT_CONTROLS = 1651128341;
Event.REGISTER_CONTROL_TOUCH = 1831760139;
Event.REGISTER_USER = 2144078707;
Event.REGISTER_USER_AFTER = 996812304;
Event.REMOVE_COMPONENT = 393716694;
Event.REMOVE_COMPONENT_AFTER = 667395053;
Event.REMOVE_ENTITY = 1514181750;
Event.REMOVE_ENTITY_AFTER = 2133888199;
Event.REMOVE_FILE = 499007203;
Event.REMOVE_FILE_AFTER = 1483753120;
Event.REMOVE_GROUP = 1709450792;
Event.REMOVE_GROUP_AFTER = 903313259;
Event.REMOVE_PROJECT = 1587525682;
Event.REMOVE_PROJECT_AFTER = 549370735;
Event.REMOVE_USER = 499463474;
Event.REMOVE_USER_AFTER = 1693288337;
Event.RENDER_AFTER = 120087353;
Event.RENDER_BEFORE = 599557588;
Event.RENDER_OK = 754437809;
Event.SAVE_COMPONENT = 1218443919;
Event.SAVE_COMPONENT_AFTER = 497615884;
Event.SAVE_ENTITY = 465029263;
Event.SAVE_ENTITY_AFTER = 74747758;
Event.SAVE_FILE = 871966582;
Event.SAVE_FILE_AFTER = 1687012921;
Event.SAVE_GROUP = 1259965167;
Event.SAVE_GROUP_AFTER = 1385567502;
Event.SAVE_PROJECT = 248485589;
Event.SAVE_PROJECT_AFTER = 1140526504;
Event.SAVE_USER = 871510311;
Event.SAVE_USER_AFTER = 1896548138;
Event.SEND_OBJ = 1557218156;
Event.SEND_OBJ_AFTER = 1461546577;
Event.SET_API_URL = 112410343;
Event.SET_CURRENT_GROUP = 825019320;
Event.SET_CURRENT_MODE = 1081940634;
Event.SET_CURRENT_PROJECT = 1122645666;
Event.SET_CURRENT_USER = 1082182850;
Event.SET_SOURCE_API_URL = 586286345;
Event.SET_SOURCE_WEBSOCKET_URL = 1511261284;
Event.SET_TARGET_API_URL = 814862739;
Event.SET_TARGET_WEBSOCKET_URL = 1147225946;
Event.SET_WEBSOCKET_URL = 398841594;
Event.STATUS_INFO = 98546841;
Event.STOP_AUDIO = 610511149;
Event.STOP_POSITIONAL = 891373571;
Event.STOP_WEBSOCKET_SERVICE = 1459755252;
Event.TOUCH_CANCEL = 1434990962;
Event.TOUCH_END = 720174809;
Event.TOUCH_MOVE = 850342683;
Event.TOUCH_START = 585149010;
Event.UPDATE_CONTROL = 1018035643;
Event.UPDATE_EDITOR_CODE = 1117078269;
Event.UPDATE_GUI_OBJ = 440564785;
Event.UPDATE_INSTANCE = 1678571561;
Event.WINDOW_RESIZE = 663236753;
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

//GENERATED_EXPORTS_START
//GENERATED_EXPORTS_END

//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END

export {Event as default};
