//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_START
import * as entityImport from './entity/index.js';
import * as componentImport from './component/index.js';
//GENERATED_IMPORT_IMPLEMENTATION_CLASSES_END

//GENERATED_IMPORT_BASE_CLASSES_START
import {default as User} from './User.js';
import {default as Project} from './Project.js';
import {default as Group} from './Group.js';
import {default as File} from './File.js';
import {default as Entity} from './Entity.js';
import {default as Component} from './Component.js';
//GENERATED_IMPORT_BASE_CLASSES_END

//GENERATED_ASSIGN_TO_BASE_START
const Animation = entityImport.Animation;
const Physics = componentImport.Physics;
const Maths = componentImport.Maths;
const Input = componentImport.Input;
const Graphics = componentImport.Graphics;
const Code = componentImport.Code;
const Audio = componentImport.Audio;
Entity.Animation = Animation;
Component.Physics = Physics;
Component.Maths = Maths;
Component.Input = Input;
Component.Graphics = Graphics;
Component.Code = Code;
Component.Audio = Audio;
//GENERATED_ASSIGN_TO_BASE_END

//CUSTOM_ASSIGN_TO_BASE_START
//CUSTOM_ASSIGN_TO_BASE_END

//GENERATED_EXPORTS_START
export {
  User,
  Project,
  Group,
  File,
  Entity,
  Component,
  Animation,
  Physics,
  Maths,
  Input,
  Graphics,
  Code,
  Audio,
//CUSTOM_EXPORTS_START
//CUSTOM_EXPORTS_END
};
//GENERATED_EXPORTS_END