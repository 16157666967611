//GENERATED_IMPORTS_START
//GENERATED_IMPORTS_END

//GENERATED_RUNTIME_IMPORTS_START
import Event from '../../../../../Event.js';
//GENERATED_RUNTIME_IMPORTS_END

//CUSTOM_IMPORTS_START
import Vector3 from '../../maths/Vector3.js';
import Runtime from '../../../../../Runtime.js';
//CUSTOM_IMPORTS_END

import Light from '../Light.js';

/**

 GENERATED_INHERITED_START
 GENERATED_INHERITED_END

 TEMPLATE_OPTIONS_START
 TEMPLATE_OPTIONS_END

 CUSTOM_BEFORE_OPTIONS_START
  position = new Vector3({x : 0, y : 1, z : 0})
 CUSTOM_BEFORE_OPTIONS_END

 CUSTOM_OPTIONS_START
  angle = Math.PI/3 - Maximum extent of the spotlight, in radians, from its direction. Should be no more than Math.PI/2. The default is Math.PI/3
  castShadow = false - If set to true light will cast dynamic shadows. Warning: This is expensive and requires tweaking to get shadows looking right. See the SpotLightShadow for details. The default is false.
  decay = 2 - The amount the light dims along the distance of the light. Default is 2. In context of physically-correct rendering the default value should not be changed
  distance = 0 - When distance is zero, light will attenuate according to inverse-square law to infinite distance. When distance is non-zero, light will attenuate according to inverse-square law until near the distance cutoff, where it will then attenuate quickly and smoothly to 0. Inherently, cutoffs are not physically correct.
  map = null - A Texture used to modulate the color of the light. The light color is mixed with the RGB value of this texture, with a ratio corresponding to its alpha value. The cookie-like masking effect is reproduced using pixel values (0, 0, 0, 1 minus cookie_value). Warning: .map is disabled if .castShadow is false.
  penumbra = 0 - Percent of the spotlight cone that is attenuated due to penumbra, between 0 and 1
  power = 1 - Power is the luminous power of the light measured in lumen (lm). A change in power affects the intensity of this light
  shadow = null - A SpotLightShadow used to calculate shadows for this light.
  target = null - The Spotlight points from its position to target.position. The default position of the target is (0, 0, 0). For the target's position to be changed to anything other than the default, it must be added to the scene
 CUSTOM_OPTIONS_END

 RUNTIME_OPTIONS_START
 RUNTIME_OPTIONS_END

 TEMPLATE_STATIC_OPTIONS_START
 TEMPLATE_STATIC_OPTIONS_END

 CUSTOM_STATIC_OPTIONS_START
 CUSTOM_STATIC_OPTIONS_END

 CUSTOM_ABSOLUTE_REQUIREMENTS_START
 CUSTOM_ABSOLUTE_REQUIREMENTS_END

 CUSTOM_OPTIONAL_REQUIREMENTS_START
  {
    "R3RuntimeStorageAxios" :
    [
      {
        "methods" : ["save"],
        "mode" : "Runtime.OPTIONAL_MODE_NORMAL",
        "properties" : [
          {
            "property" : "angle",
            "type" : "Number",
            "min" : 0,
            "max" : "Math.PI/2",
            "step" : 0.0001
          },
          {
            "property" : "castShadow",
            "type" : "Boolean"
          },
          {
            "property" : "decay",
            "type" : "Number",
            "min" : 0,
            "max" : 10,
            "step" : 0.001
          },
          {
            "property" : "distance",
            "type" : "Number",
            "min" : -50,
            "max" : 50,
            "step" : 0.01
          },
          {
            "property" : "map",
            "type" : "R3EventObjComponentGraphicsTexture"
          },
          {
            "property" : "penumbra",
            "type" : "Number",
            "min" : 0,
            "max" : 1,
            "step" : 0.0001
          },
          {
            "property" : "position",
            "type" : "R3EventObjComponentMathsVector3"
          },
          {
            "property" : "power",
            "type" : "Number",
            "min" : 0,
            "max" : 50,
            "step" : 0.01
          },
          {
            "property" : "shadow",
            "type" : "R3EventObjComponentGraphicsShadowSpot"
          },
          {
            "property" : "target",
            "type" : "R3EventObjComponentGraphicsMesh"
          }
        ]
      }
    ]
  }
 CUSTOM_OPTIONAL_REQUIREMENTS_END

 TEMPLATE_METHODS_START
 TEMPLATE_METHODS_END

 CUSTOM_METHODS_START
 CUSTOM_METHODS_END

 OVERRIDE_METHODS_START
  cloneInstance(propagate = true) - Clones an instance, when propagate is true, also clones the instance children 
  createInstance() - Creates a graphics runtime instance for this runtime based on the R3 Object 
  deleteInstance() - Deletes a graphics runtime instance for this runtime based on the R3 Object 
  updateInstance(property, value) - Updates the graphics runtime instance of the R3 Object based on the options 
 OVERRIDE_METHODS_END

 TEMPLATE_STATIC_METHODS_START
 TEMPLATE_STATIC_METHODS_END

 CUSTOM_STATIC_METHODS_START
 CUSTOM_STATIC_METHODS_END

 **/

export class Spot extends Light {

  //GENERATED_CONSTRUCTOR_START
  constructor(options = {}) {

    if (typeof options.maxDepth === 'undefined') {
      options.maxDepth = 0;
    }

    if (typeof options.callDepth === 'undefined') {
      options.callDepth = 0;
    } else {
      options.callDepth++;
    }

    options.maxDepth = options.callDepth;

    /**
     * @param type
     * - The type of this Component
     */
    if (typeof options.type === 'undefined') {
      options.type = 'R3EventObjComponentGraphicsLightSpot';
    }

    if (typeof options.uniqueName === 'undefined') {
      options.uniqueName = 'SpotLight';
    }

    //GENERATED_CUSTOM_BEFORE_OPTIONS_START
    /**
     * @param position
     * - No comment
     */
    if (typeof options.position === 'undefined') {
      options.position = new Vector3({x : 0, y : 1, z : 0});
    }
    //GENERATED_CUSTOM_BEFORE_OPTIONS_END

    super(options);

    //GENERATED_TEMPLATE_OPTIONS_START
    //GENERATED_TEMPLATE_OPTIONS_END

    //GENERATED_CUSTOM_OPTIONS_START
    /**
     * @param angle
     * - Maximum extent of the spotlight, in radians, from its direction. Should be no more than Math.PI/2. The default
     *   is Math.PI/3
     */
    if (typeof options.angle === 'undefined') {
      options.angle = Math.PI/3;
    }

    /**
     * @param castShadow
     * - If set to true light will cast dynamic shadows. Warning: This is expensive and requires tweaking to get shadows
     *   looking right. See the SpotLightShadow for details. The default is false.
     */
    if (typeof options.castShadow === 'undefined') {
      options.castShadow = false;
    }

    /**
     * @param decay
     * - The amount the light dims along the distance of the light. Default is 2. In context of physically-correct
     *   rendering the default value should not be changed
     */
    if (typeof options.decay === 'undefined') {
      options.decay = 2;
    }

    /**
     * @param distance
     * - When distance is zero, light will attenuate according to inverse-square law to infinite distance. When distance
     *   is non-zero, light will attenuate according to inverse-square law until near the distance cutoff, where it will
     *   then attenuate quickly and smoothly to 0. Inherently, cutoffs are not physically correct.
     */
    if (typeof options.distance === 'undefined') {
      options.distance = 0;
    }

    /**
     * @param map
     * - A Texture used to modulate the color of the light. The light color is mixed with the RGB value of this texture,
     *   with a ratio corresponding to its alpha value. The cookie-like masking effect is reproduced using pixel values
     *   (0, 0, 0, 1 minus cookie_value). Warning: .map is disabled if .castShadow is false.
     */
    if (typeof options.map === 'undefined') {
      options.map = null;
    }

    /**
     * @param penumbra
     * - Percent of the spotlight cone that is attenuated due to penumbra, between 0 and 1
     */
    if (typeof options.penumbra === 'undefined') {
      options.penumbra = 0;
    }

    /**
     * @param power
     * - Power is the luminous power of the light measured in lumen (lm). A change in power affects the intensity of this
     *   light
     */
    if (typeof options.power === 'undefined') {
      options.power = 1;
    }

    /**
     * @param shadow
     * - A SpotLightShadow used to calculate shadows for this light.
     */
    if (typeof options.shadow === 'undefined') {
      options.shadow = null;
    }

    /**
     * @param target
     * - The Spotlight points from its position to target.position. The default position of the target is (0, 0, 0). For
     *   the target's position to be changed to anything other than the default, it must be added to the scene
     */
    if (typeof options.target === 'undefined') {
      options.target = null;
    }
    //GENERATED_CUSTOM_OPTIONS_END

    //CUSTOM_MODIFY_OPTIONS_START
    //CUSTOM_MODIFY_OPTIONS_END

    //GENERATED_RUNTIME_OPTIONS_START
    //GENERATED_RUNTIME_OPTIONS_END

    //GENERATED_REQUIREMENTS_START
    let requirements;
    let group;

    if (!options.runtimes.hasOwnProperty(Runtime.KEY_STORAGE_AXIOS)) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS] = {runtime:null};
    }

    if (!options.runtimes[Runtime.KEY_STORAGE_AXIOS]['requirements']) {
      options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements = {
        absolute: {group: new Set()},
        optional: {group: new Set()}
      };
    }

    requirements = options.runtimes[Runtime.KEY_STORAGE_AXIOS].requirements;

    group = [];
    if (requirements.optional) {
      group = [...requirements.optional.group];
    }

    requirements.optional = {
      group: new Set(
        [
          {
            "methods": [
              "save"
            ],
            "mode": Runtime.OPTIONAL_MODE_NORMAL,
            "properties": [
              {
                "property": "angle",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": Math.PI/2,
                "step": 0.0001
              },
              {
                "property": "castShadow",
                "Constructor" : this.getClassConstructor("Boolean")
              },
              {
                "property": "decay",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 10,
                "step": 0.001
              },
              {
                "property": "distance",
                "Constructor" : this.getClassConstructor("Number"),
                "min": -50,
                "max": 50,
                "step": 0.01
              },
              {
                "property": "map",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture")
              },
              {
                "property": "penumbra",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 1,
                "step": 0.0001
              },
              {
                "property": "position",
                "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3")
              },
              {
                "property": "power",
                "Constructor" : this.getClassConstructor("Number"),
                "min": 0,
                "max": 50,
                "step": 0.01
              },
              {
                "property": "shadow",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsShadowSpot")
              },
              {
                "property": "target",
                "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMesh")
              }
            ]
          },
          ...group
        ]
      )
    }
    //GENERATED_REQUIREMENTS_END

    //GENERATED_GET_RUNTIME_START
    let runtime;
    let payload;

    //GENERATED_GET_RUNTIME_SNIPPETS_START
    payload = {};
    runtime = null;

    Event.Emit(
      Event.GET_RUNTIME_GRAPHICS_THREE,
      payload
    );

    if (payload.results[0]) {
      runtime = payload.results[0];
    }

    /**
     * We assign the runtime directly before the Object.assign() call to allow the Linking System to find the runtimes
     * during assignment. Also - we need to know all the requirements of this runtime in advance
     */
    if (runtime) {

      if (!options.runtimes.hasOwnProperty(runtime.type)) {
        options.runtimes[runtime.type] = {requirements: null};
      }

      options.runtimes[runtime.type].runtime = runtime;
    }
    //GENERATED_GET_RUNTIME_SNIPPETS_END

    //GENERATED_GET_RUNTIME_END

    //GENERATED_REFERENCES_START
    if (typeof this.references === 'undefined') {
      this.references = {};
    }

    let generate_references = [
      {
        "property": "angle",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": Math.PI/2,
        "step": 0.0001
      },
      {
        "property": "castShadow",
        "Constructor" : this.getClassConstructor("Boolean"),
        "type": "Boolean"
      },
      {
        "property": "decay",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 10,
        "step": 0.001
      },
      {
        "property": "distance",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": -50,
        "max": 50,
        "step": 0.01
      },
      {
        "property": "map",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsTexture"),
        "type": "R3EventObjComponentGraphicsTexture"
      },
      {
        "property": "penumbra",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 1,
        "step": 0.0001
      },
      {
        "property": "position",
        "Constructor" : this.getClassConstructor("R3EventObjComponentMathsVector3"),
        "type": "R3EventObjComponentMathsVector3"
      },
      {
        "property": "power",
        "Constructor" : this.getClassConstructor("Number"),
        "type": "Number",
        "min": 0,
        "max": 50,
        "step": 0.01
      },
      {
        "property": "shadow",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsShadowSpot"),
        "type": "R3EventObjComponentGraphicsShadowSpot"
      },
      {
        "property": "target",
        "Constructor" : this.getClassConstructor("R3EventObjComponentGraphicsMesh"),
        "type": "R3EventObjComponentGraphicsMesh"
      }
    ];

    for (let reference of generate_references) {
      this.references[reference.property] = reference;
    }
    //GENERATED_REFERENCES_END

    //GENERATED_ACTIVE_OPTIONS_BEFORE_START
    //GENERATED_ACTIVE_OPTIONS_BEFORE_END

    //GENERATED_ACTIVE_OPTIONS_START
    //GENERATED_ACTIVE_OPTIONS_END

    //GENERATED_CUSTOM_GUI_OPTIONS_START
    //GENERATED_CUSTOM_GUI_OPTIONS_END

    //CUSTOM_OPTIONS_INIT_START
    //CUSTOM_OPTIONS_INIT_END

    //CUSTOM_BEFORE_INIT_START
    //CUSTOM_BEFORE_INIT_END

    //GENERATED_CALL_DEPTH_START
    if (options.callDepth === 0) {

      this.initialize(options);

      this.emitInitializeEvent(options);

    } else {
      options.callDepth--;
    }
    //GENERATED_CALL_DEPTH_END

    //CUSTOM_AFTER_INIT_START
    //CUSTOM_AFTER_INIT_END
  }
  //GENERATED_CONSTRUCTOR_END

  //GENERATED_TEMPLATE_METHODS_START
  //GENERATED_TEMPLATE_METHODS_END

  //GENERATED_CUSTOM_METHODS_START
  //GENERATED_CUSTOM_METHODS_END

  //GENERATED_OVERRIDE_METHODS_START
  /**
   * cloneInstance()
   * - Clones an instance, when propagate is true, also clones the instance children
   * @param {Boolean} [propagate=true]
   * - No returns
   */
  cloneInstance(propagate = true) {

    //CUSTOM_CLONE_INSTANCE_BEFORE_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_END

    //GENERATED_CLONE_INSTANCE_BEFORE_START
    //GENERATED_CLONE_INSTANCE_BEFORE_END

    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CLONE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CLONE_INSTANCE_START
    /**
     * Override Template
     */
    //GENERATED_CLONE_INSTANCE_END

    //CUSTOM_CLONE_INSTANCE_START
    //CUSTOM_CLONE_INSTANCE_END

    //GENERATED_CLONE_INSTANCE_AFTER_START
    //GENERATED_CLONE_INSTANCE_AFTER_END

  }

  /**
   * createInstance()
   * - Creates a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  createInstance() {

    //CUSTOM_CREATE_INSTANCE_BEFORE_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_END

    //GENERATED_CREATE_INSTANCE_BEFORE_START
    //GENERATED_CREATE_INSTANCE_BEFORE_END

    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_CREATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_CREATE_INSTANCE_START
    Event.Emit(
      Event.CREATE_INSTANCE,
      {object : this}
    );
    //GENERATED_CREATE_INSTANCE_END

    //CUSTOM_CREATE_INSTANCE_START
    //CUSTOM_CREATE_INSTANCE_END

    //GENERATED_CREATE_INSTANCE_AFTER_START
    //GENERATED_CREATE_INSTANCE_AFTER_END

  }

  /**
   * deleteInstance()
   * - Deletes a graphics runtime instance for this runtime based on the R3 Object
   * - No parameters
   * - No returns
   */
  deleteInstance() {

    //CUSTOM_DELETE_INSTANCE_BEFORE_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_END

    //GENERATED_DELETE_INSTANCE_BEFORE_START
    //GENERATED_DELETE_INSTANCE_BEFORE_END

    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_DELETE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_DELETE_INSTANCE_START
    Event.Emit(
      Event.DELETE_INSTANCE,
      {object : this}
    );
    //GENERATED_DELETE_INSTANCE_END

    //CUSTOM_DELETE_INSTANCE_START
    //CUSTOM_DELETE_INSTANCE_END

    //GENERATED_DELETE_INSTANCE_AFTER_START
    //GENERATED_DELETE_INSTANCE_AFTER_END

  }

  /**
   * updateInstance()
   * - Updates the graphics runtime instance of the R3 Object based on the options
   * @param property
   * @param value
   * - No returns
   */
  updateInstance(property, value) {

    //CUSTOM_UPDATE_INSTANCE_BEFORE_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_END

    //GENERATED_UPDATE_INSTANCE_BEFORE_START
    //GENERATED_UPDATE_INSTANCE_BEFORE_END

    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_START
    //CUSTOM_UPDATE_INSTANCE_BEFORE_GENERATED_END

    //GENERATED_UPDATE_INSTANCE_START
    Event.Emit(
      Event.UPDATE_INSTANCE,
      {
        object : this,
        options : {
          property,
          value
        }
      }
    );
    //GENERATED_UPDATE_INSTANCE_END

    //CUSTOM_UPDATE_INSTANCE_START
    //CUSTOM_UPDATE_INSTANCE_END

    //GENERATED_UPDATE_INSTANCE_AFTER_START
    //GENERATED_UPDATE_INSTANCE_AFTER_END

  }

  //GENERATED_OVERRIDE_METHODS_END

  //GENERATED_TEMPLATE_STATIC_METHODS_START
  //GENERATED_TEMPLATE_STATIC_METHODS_END

  //GENERATED_CUSTOM_STATIC_METHODS_START
  //GENERATED_CUSTOM_STATIC_METHODS_END

  //CUSTOM_IMPLEMENTATION_START
  //CUSTOM_IMPLEMENTATION_END
}

Spot.Type = 'R3EventObjComponentGraphicsLightSpot';

//GENERATED_TEMPLATE_STATIC_OPTIONS_START
//GENERATED_TEMPLATE_STATIC_OPTIONS_END

//GENERATED_CUSTOM_STATIC_OPTIONS_START
//GENERATED_CUSTOM_STATIC_OPTIONS_END

//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_START
//GENERATED_OUT_OF_CLASS_IMPLEMENTATION_END

//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_START
//CUSTOM_OUT_OF_CLASS_IMPLEMENTATION_END

export {Spot as default};
